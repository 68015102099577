/* eslint-disable camelcase */
// @flow
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Card, Row, Col, Switch,
  Tooltip,
  Modal,
  Pagination,
} from 'antd';
import { API_END_POINTS, axiosAuthInstance } from 'api';
import { SpH5, SpButton } from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import { customerSupportActions, inventoryShopsDetailsActions, notificationActions } from 'store/actions';
import debounce from 'lodash.debounce';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import Loading from 'components/Loading';
import { NOTIFICATION } from 'store/actionTypes';
import ImagePreview from '../../../../imgs/imagePreview.svg';
import AddDeals from './AddDeals';
import UpdateDeal from './UpdateDeal';


type Props = {
  fetchShops: Function,
  selectedAccount: Object,
  test: Boolean,
  setNotification: Function,
  paymentSlug: Array,

};

const Slider = (props: Props) => {
  const {
    fetchShops,
    // loading,
    selectedAccount,
    test,
    setNotification,
    paymentSlug,
  } = props;
  const location = useLocation();
  const [deals, setDeals] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0); // Total number of deals
  const [addDealModal, setAddDealModal] = useState(false);
  const [editDealModal, setEditDealModal] = useState(false);
  const [strapiToken, setStrapiToken] = useState('');


  const apiUrl = process.env.REACT_APP_API_STRAPI_ENDPOINT;
  // eslint-disable-next-line max-len

  const simpleValidator = useRef(new SimpleReactValidator());
  simpleValidator.current.purgeFields();
  const debouncedFetchShops = debounce(fetchShops, 2000, { leading: true });
  const getData = (query) => {
    const parsed = queryString.parse(query);
    // eslint-disable-next-line no-shadow
    const currentPage = parsed.page || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = '';
    debouncedFetchShops({ currentPage, sortParams, filterParams });
  };
  // Strapi token is managed via backend and stored securely in AWS Secrets Manager.
  const getStrapToken = async () => {
    setLoading(true);
    try {
      const { data: { entity: { token } } } = await axiosAuthInstance.get(`${API_END_POINTS.GET_STRAPI_TOKEN}`);
      setStrapiToken(token); // Directly set the token
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Erorr in Fetching Token',
      });
    } finally {
      setLoading(false);
    }
  };
  const getDataDeals = async (currentPagedeal, pageSizedeal) => {
    try {
      const response = await axios.get(
        // eslint-disable-next-line max-len
        `${apiUrl}/api/app-deals?pagination[page]=${currentPagedeal}&pagination[pageSize]=${pageSizedeal}&populate=*&sort[createdAt]=desc&filters[accountGid][$eq]=${selectedAccount?.gid}`,
        {
          headers: {
            Authorization: `Bearer ${strapiToken}`,
          },
        },
      );
      // Handle successful response
      console.log('Data:', response.data);
      setTotal(response?.data?.meta?.pagination?.total);
      setDeals(response.data);
    } catch (e) {
      // Handle errors
      console.error('Error fetching data:', e);
    }
  };

  // Call getDataDeals when component mounts and when page or pageSize changes
  useEffect(() => {
    getStrapToken();
  }, [currentPage, pageSize]);

  // Handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle page size change
  const onPageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when page size changes
  };
  useEffect(() => {
    if (strapiToken) {
      getData(location.search);
      getDataDeals(currentPage, pageSize);
    }
  }, [selectedAccount, test, strapiToken, currentPage, pageSize]);


  const divStyle = {
    padding: '4px 14px',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    fontSize: '14px',
    boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: '#DCE0E4',

  };
  const maindivStyle = {
    padding: '10px',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    fontSize: '14px',
    boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
    width: '100%',
    boxSizing: 'border-box',
    marginTop: '1rem',

  };

  const handleDeleteDeal = async () => {
    if (selectedDeal) {
      try {
        await axios.delete(`${apiUrl}/api/app-deals/${selectedDeal.id}`, {
          headers: {
            Authorization: `Bearer ${strapiToken}`,
          },
        });
        console.log('Deal deleted:', selectedDeal);
        // Optionally, you might want to update your state to reflect the deletion
        setDeleteModal(false);
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: 'Deal Deleted Succesfully',
        });
        getDataDeals(currentPage, pageSize);
      } catch (e) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: 'Something Went Wrong',
        });
        console.error('Error deleting deal:', e);
        // Handle error (e.g., show a message to the user)
      }
    }
  };

  const handleImageUrl = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }


  return (
    <>
      <Card>
        <SpButton
          type="secondary"
          shape="round"
          className="mr-3  mb-2"
          style={{ float: 'right' }}
          ghost
          onClick={() => setAddDealModal(true)}
        >
          {'\u002B'}&nbsp;Add New
        </SpButton><br />

        {addDealModal && (
          <AddDeals
            selectedAccount={selectedAccount}
            visible={addDealModal}
            paymentSlug={paymentSlug}
            close={() => setAddDealModal(false)}
            getDataDeals={getDataDeals}
            currentPage={currentPage}
            pageSize={pageSize}
            strapiToken={strapiToken}
          />
        )}

        {editDealModal && (
        <UpdateDeal
          selectedAccount={selectedAccount}
          selectedDeal={selectedDeal}
          visible={editDealModal}
          paymentSlug={paymentSlug}
          close={() => setEditDealModal(false)}
          getDataDeals={getDataDeals}
          currentPage={currentPage}
          pageSize={pageSize}
          strapiToken={strapiToken}
        />

        )}

        {/* -----------------------------------------list of Deals------------------------------------------- */}
        {deals?.data?.length === 0 && <SpH5>No Deals Found</SpH5>}
        {deals?.data?.map((deal) => {
          const {
            // eslint-disable-next-line camelcase
            name, street, city, zipCode, state, country, deal_url, image, business_id, isEnabled,
          } = deal.attributes;
          return (
            <div key={deal.id} style={maindivStyle}>
              <Row type="flex" justify="between" gutter={10}>
                <Col span={8}>
                  <SpH5>Restaurant Name</SpH5>
                  <div style={divStyle} className="mr-4 mt-3">
                    {business_id?.data?.attributes?.name}
                  </div>
                </Col>
                <Col span={8}>
                  <SpH5>Location</SpH5>
                  <div style={divStyle} className="mr-4 mt-3">
                    {street} {city} {zipCode} {state} {country}
                  </div>
                </Col>
                <Col span={8}>
                  <SpH5>Deal Name</SpH5>
                  <div style={divStyle} className="mr-4 mt-3">
                    {name}
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="mt-4">
                <Col span={8}>
                  <SpH5>Image</SpH5>
                  <div className="d-flex mt-1 justify-between">

                    <div>
                      <img src={ImagePreview} alt="imagepreview" />

                    </div>
                    <div style={{ flex: 1, wordBreak: 'break-word' }} className="ml-2">
                      {image}
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <SpH5>Deal Url</SpH5>
                  <div className="d-flex mt-1 justify-between">
                    <div>
                      <img src={ImagePreview} alt="imagepreview" />
                    </div>
                    <div style={{ flex: 1, wordBreak: 'break-word' }} className="ml-2">
                      {deal_url ?? 'NA'}
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                    <EyeOutlined
                      style={{ fontSize: '20px', color: '#08c', marginRight: '8px' }}
                      onClick={() => handleImageUrl(image)}
                    />
                    <EditOutlined
                      style={{ fontSize: '20px' }}
                      onClick={() => { setEditDealModal(true); setSelectedDeal(deal); }}
                    />
                    <DeleteOutlined
                      style={{ fontSize: '20px', marginLeft: '8px' }}
                      onClick={() => { setDeleteModal(true); setSelectedDeal(deal); }}
                    />
                    <Tooltip title={isEnabled ? 'Deal Enabled' : 'Deal Disabled'}>
                      <Switch
                        className="ml-2"
                        checked={isEnabled}
                        disabled
                      />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
        {/* ----------------------------------------DELETE MODAL----------------------------------------------- */}
        <Modal
          centered
          closable={false}
          visible={deleteModal}
          footer={[
            <SpButton onClick={() => setDeleteModal(false)} type="secondary">Cancel</SpButton>,
            <SpButton onClick={() => handleDeleteDeal()} type="danger">Delete</SpButton>,
          ]}
          title="Confirm Deletion"
        >
          <div className="px-4">
            <div className="mt-1 mb-2">Are you sure you want to delete this deal slider image? This action cannot be undone.</div>
            <SpH5 className="pb-3">
              <div>{selectedDeal ? selectedDeal?.attributes?.name : <>&#8211;</>}</div>
            </SpH5>
          </div>
        </Modal>
        {deals?.data?.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={total}
              onChange={onPageChange}
              showSizeChanger
              onShowSizeChange={onPageSizeChange}
              pageSizeOptions={[3, 5, 10, 20]} // Adjust page size options as needed
            />
          </div>
        )}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  customdashboard: state.customdashboard.content,
  totalElements: state.customdashboard.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  shops: state.customerSupport.shops,
  paymentSlug: state.inventoryShopsDetails.shopsSlug,


});

const mapDispatchToProps = (dispatch) => ({
  fetchShops: param => dispatch(customerSupportActions.fetchShops({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchShopsSlug: param => dispatch(inventoryShopsDetailsActions.fetchShopsSlug({
    payload: param,
  })),

});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Slider);
