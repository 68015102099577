// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Modal, Icon, message, Upload,
} from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';
import { API_END_POINTS, axiosAuthInstance, axiosEmptyInstance } from 'api';
import { NOTIFICATION } from 'store/actionTypes';
import { MESSAGES } from 'appconstants';
import { notificationActions } from 'store/actions';
import File from '../../../../imgs/file.svg';

type Props = {
  close: Function,
  visible: boolean,
  setNotification: Function,
};

const BulkUploadModal = (props: Props) => {
  const { visible, setNotification, close } = props;
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const downloadTemplate = async () => {
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.DOWNLOAD_FUNDING_SOURCE_TEMPLATE}`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });
  const beforeUpload = (file) => {
    setUploadLoading(true);
    const isLt5M = file.size / 1024 / 1024 < 5;
    const IMAGE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const pattern = file.type.match(IMAGE);
    if (!pattern) {
      message.error('Please selecte valid file');
      setUploadLoading(false);
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      setUploadLoading(false);
      return false;
    }
    setUploadLoading(false);
    return true;
  };

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    try {
      setUploadLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        setUploadedFile(file);
        setUploadLoading(false);
        setUploadSuccess(true);
      }
    } catch (e) {
      setUploadSuccess(false);
      setUploadedFile(null);
    } finally {
      setUploadLoading(false);
    }
  };

  let multi = false;
  const storeFile = async () => {
    try {
      setLoading(true);
      if (uploadedFile && !multi) {
        multi = true;
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.BULK_CONTACT_GET}`);
        await axiosEmptyInstance.put(entity, await fileReader(uploadedFile), { headers: { 'Content-Type': uploadedFile.type } });
        // eslint-disable-next-line max-len
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.BULK_CONTACT_STORE}`, { fileUrl: entity, keyType: 'BULK_CONTACT' });
        const { data } = await axiosAuthInstance.post(`${API_END_POINTS.BULK_CONTACT_ADD}`, { fileUrl });
        console.log(data);
        setLoading(false);
        setUploadSuccess(true);
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: MESSAGES.FILE_UPLOAD.SUCCESS,
        });
        close();
        setUploadedFile(null);
        multi = false;
      }
    } catch (e) {
      if (e.response && e.response.status === 417) {
        setLoading(false);
        const msgType = Object.keys(e.response.data)[0];
        setUploadSuccess(false);
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: `${msgType}: ${e.response.data[msgType]}`,
        });
      } else if (e.response && e.response.status === 412) {
        setLoading(false);
        const msgType = e.response?.data?.message;
        setUploadSuccess(false);
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: msgType,
        });
      } else {
        setLoading(false);
        setUploadSuccess(false);
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: e.response.data ? e.response.data : MESSAGES.FILE_UPLOAD.ERROR,
        });
      }
    } finally {
      setLoading(false);
      multi = false;
    }
  };

  return (
    <Modal
      centered
      closable={false}
      width="600px"
      visible={visible}
      footer={[
        <SpButton disabled={loading} onClick={() => { close(); setUploadedFile(null); }} type="secondary">
          Cancel
        </SpButton>,
        <SpButton
          onClick={() => storeFile()}
          disabled={!uploadSuccess}
          loading={loading}
        >
          Done
        </SpButton>,
      ]}
      title="Bulk Upload"
    >
      <Row
        type="flex"
        justify="space-between"
        style={{
          border: '1px solid #CBCBCB',
          marginTop: '10px',
          marginBottom: '25px',
          margin: '2rem',
        }}
      >
        <Col
          span={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            borderRight: '1px solid #CBCBCB',
            cursor: 'pointer',
          }}
          onClick={() => downloadTemplate(true)}
        >
          <img src={File} alt="file" style={{ marginRight: '8px' }} />
          <span>Download Template</span>
          <Icon
            type="download"
            style={{ color: 'rgba(0, 0, 0, 0.8)', marginTop: '4px', fontSize: '22px' }}
          />
        </Col>
        <Col span={16} className="p-2">
          <div className="ml-2">
            <SpH5>Template</SpH5>
            <ol>
              <li>
                <span className="font-weight-bold">Download the template:</span>{' '}
                Click the link provided to download the template file to your
                device.
              </li>
              <li>
                <span className="font-weight-bold">Fill in the details:</span>{' '}
                Open the template and complete all required fields with your
                information.
              </li>
              <li>
                <span className="font-weight-bold">
                  Upload the completed file:
                </span>{' '}
                Once you’ve filled in the details, save the file and upload it
                here using the upload button.
              </li>
            </ol>
          </div>
        </Col>
      </Row>
      <div className="d-flex align-items-center gap-3 ml-3">
        <SpH5>Upload .xlsx file</SpH5>
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={fileUpload}
        >
          <SpButton
            shape="round"
            ghost
            className="ml-3"
          >
            <Icon
              type="upload"
              style={{ color: 'rgba(0, 0, 0, 0.8)' }}
            /> Upload
          </SpButton>
        </Upload>
        { uploadLoading && <span className="spinner-border text-muted mt-2 ml-2"> </span>}
        {uploadSuccess && (
          <div className="d-flex align-items-center ml-3">
            <Icon type="check-circle" className="mr-1 text-success" style={{ fontSize: '18px' }} />
            <span className="text-success" style={{ fontSize: '13px' }}>File Uploaded</span>
          </div>
        )}
      </div>
      <span className="mt-1" style={{ color: '#999999', fontSize: '12px', marginLeft: '8rem' }}>
        File size should be within 2 MB
      </span>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadModal);
