// @flow
import React, { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
// import toSentenceCase from 'utils/sentencecase';
import queryString from 'query-string';
import formatNumber from 'utils/formatNumber';
import getPaymentStatus from 'utils/getPaymentStatus';
import getPaymentBackground from 'utils/getPaymentBackground';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

// import {
//   CardSchemeIcon,
// } from 'components/DesignKit';
import {
  customerDetailsAction,
} from 'store/actions';
import {
  PAGES,
  ACQUIRER_TYPES,
} from 'appconstants';

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: '#434343',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  fetchPayments: Function,
  payments: Array<Object>,
  // test: boolean,
  history: {
    push: Function,
  };
  totalElements: number,
  selectedAccount: Object,
  match: {
    params: Object,
  },
}

const CustomerRegisteredCardList = (props: Props) => {
  const {
    fetchPayments,
    payments,
    // test,
    history,
    totalElements,
    selectedAccount,
    match,
  } = props;

  const { gid: customerGid } = match.params;

  const [pagination, setPagination] = useState({
    total: totalElements,
    current: 1,
    show: total => `There are ${total} registered card(s)`,
  });
  const currencyType = selectedAccount && selectedAccount.currency;
  const acquirerName = (selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);
  const location = useLocation();

  const getPaymentData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `sortBy=${sortBy}&direction=${direction}`;
    console.log('get payment data triggered');
    fetchPayments({ customerGid, currentPage, sortParams });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.PAYMENTS}/${searchParams}`,
      state: location.search,
    });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getPaymentData(location.search);
  }, []);

  useEffect(() => {
    getPaymentData(location.search);
  }, [selectedAccount]);

  useEffect(() => {
    getPaymentData(location.search);
  }, [location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  // TODO: check if filter fields directly correlate with
  // field name in the backend, such that they can be passed
  // directly to the backend.
  const handleTableChange = (tableChange, sorter, filters) => {
    const currentPage = tableChange.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.CUSTOMER_PAYMENTS}/${customerGid}`,
      search: `?pageNum=${currentPage}${tempParams}`,
    });
  };

  const columns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '18%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '15%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.googlePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.googlePayUS.paymentMethodInfo && record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails)
                  ? (`**** ${record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails}`)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.applePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.applePayUS && record.paymentMethod.applePayUS.applePayDynamicLast4
                  && record.paymentMethod.applePayUS.cardBrand)
                  ? (`${record.paymentMethod.applePayUS.cardBrand} **** ${record.paymentMethod.applePayUS.applePayDynamicLast4}`)
                  : (record.paymentMethod.applePayUS && record.paymentMethod.applePayUS.cardBrand)
                    ? (`${record.paymentMethod.applePayUS.cardBrand}`)
                    : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.instantPayment && record.paymentMethod.instantPayment.walletAlias) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.instantPayment.walletAlias.alias ? (record.paymentMethod.instantPayment.walletAlias.alias)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType === 'QR') {
          const QrName = (record.paymentMethod.paymentType && record.paymentMethod.paymentType.name);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(QrName === 'QR') ? QrName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType.name === 'APPLE_PAY_US') {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                APPLE PAY
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'RECEIPT NUMBER',
      dataIndex: 'receiptNumber',
      width: '20%',
      align: 'left',
      render: (receiptNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {receiptNumber || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: false,
    },
    {
      title: 'MRN',
      dataIndex: 'mrn',
      width: '16%',
      align: 'left',
      render: (mrn) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {mrn || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      render: (text, record) => {
        const parsed = { 'status.IN': 'ALL_PAYMENTS' };
        let sortBy;
        const searchParam = parsed['status.IN'];
        if (searchParam === 'SUCCEEDED') {
          sortBy = record && record.paymentDate;
        } else {
          sortBy = record && record.createdAt;
        }
        if (sortBy && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(sortBy)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const cloverColumns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '18%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '15%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.googlePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.googlePayUS.paymentMethodInfo && record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails)
                  ? (`**** ${record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails}`)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.instantPayment && record.paymentMethod.instantPayment.walletAlias) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.instantPayment.walletAlias.alias ? (record.paymentMethod.instantPayment.walletAlias.alias)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType === 'QR') {
          const QrName = (record.paymentMethod.paymentType && record.paymentMethod.paymentType.name);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(QrName === 'QR') ? QrName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType.name === 'APPLE_PAY_US') {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                APPLE PAY
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'RECEIPT NUMBER',
      dataIndex: 'receiptNumber',
      width: '20%',
      align: 'left',
      render: (receiptNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {receiptNumber || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: false,
    },
    {
      title: 'ORDER ID',
      dataIndex: 'mrn',
      width: '16%',
      align: 'left',
      render: (mrn) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {mrn || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      render: (text, record) => {
        const parsed = { 'status.IN': 'ALL_PAYMENTS' };
        let sortBy;
        const searchParam = parsed['status.IN'];
        if (searchParam === 'SUCCEEDED') {
          sortBy = record && record.paymentDate;
        } else {
          sortBy = record && record.createdAt;
        }
        if (sortBy && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(sortBy)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const terminalColumns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '12%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net, record) => {
        const total = (net / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '14%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '15%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? (record.paymentMethod.upi.vpa || 'UPI')
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.googlePayUS) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.googlePayUS.paymentMethodInfo && record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails)
                  ? (`**** ${record.paymentMethod.googlePayUS.paymentMethodInfo.cardDetails}`)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.instantPayment && record.paymentMethod.instantPayment.walletAlias) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.instantPayment.walletAlias.alias ? (record.paymentMethod.instantPayment.walletAlias.alias)
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType === 'QR') {
          const QrName = (record.paymentMethod.paymentType && record.paymentMethod.paymentType.name);
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(QrName === 'QR') ? QrName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.paymentType.name === 'APPLE_PAY_US') {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                APPLE PAY
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'RECEIPT NUMBER',
      dataIndex: 'receiptNumber',
      width: '15%',
      align: 'left',
      render: (receiptNumber) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {receiptNumber || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: false,
    },
    {
      title: 'MRN',
      dataIndex: 'mrn',
      width: '15%',
      align: 'left',
      render: (mrn) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {mrn || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'TERMINAL NAME',
      dataIndex: 'terminal',
      width: '15%',
      align: 'left',
      render: (terminal) => {
        if (terminal && terminal.deviceName) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {terminal && terminal.deviceName}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
      sorter: false,
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      render: (text, record) => {
        const parsed = { 'status.IN': 'ALL_PAYMENTS' };
        let sortBy;
        const searchParam = parsed['status.IN'];
        if (searchParam === 'SUCCEEDED') {
          sortBy = record && record.paymentDate;
        } else {
          sortBy = record && record.createdAt;
        }
        if (sortBy && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(sortBy)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  return (
    <Card className="pl-2">
      <Table
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        rowClassName={() => 'ant-table-clickable-row'}
        columns={
          (acquirerName && acquirerName === ACQUIRER_TYPES.IMSPOS) ? terminalColumns
            : (acquirerName && acquirerName === ACQUIRER_TYPES.CLOVERPOS) ? cloverColumns : columns
        }
        rowKey={(record) => record.gid}
        dataSource={payments}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  payments: state.customerDetails.paymentDetails.content,
  totalElements: state.customerDetails.paymentDetails ? state.customerDetails.paymentDetails.totalElements : '',
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPayments: param => dispatch(customerDetailsAction.fetchPaymentDetails({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomerRegisteredCardList);
