import { PAYMENTSDETAILS } from '../actionTypes';

export const fetchPaymentDetails = (action) => ({
  type: PAYMENTSDETAILS.FETCH,
  payload: action.payload,
});

export const fetchPaymentTimeline = (action) => ({
  type: PAYMENTSDETAILS.TIMELINE_FETCH,
  payload: action.payload,
});

export const refundPaymentDetails = (action) => ({
  type: PAYMENTSDETAILS.REFUND,
  payload: action.payload,
});

export const completeRefundPayment = (action) => ({
  type: PAYMENTSDETAILS.REFUND_COMPLETE,
  payload: action.payload,
});

export const notifyPaymentDetails = (action) => ({
  type: PAYMENTSDETAILS.NOTIFY,
  payload: action.payload,
});

export const setReceiptSelected = (action) => ({
  type: PAYMENTSDETAILS.SELECTED,
  payload: action.payload,
});

export const acceptDisputeEvidence = (action) => ({
  type: PAYMENTSDETAILS.ACCEPT_EVIDENCE,
  payload: action.payload,
});

export const resolveDisputeEvidence = (action) => ({
  type: PAYMENTSDETAILS.RESOLVE_EVIDENCE,
  payload: action.payload,
});

export const fetchPaymentDisputeDetails = (action) => ({
  type: PAYMENTSDETAILS.DISPUTE_FETCH,
  payload: action.payload,
});

export const raiseDispute = (action) => ({
  type: PAYMENTSDETAILS.RAISE_DISPUTE,
  payload: action.payload,
});

export const notifyPaymentPageDetails = (action) => ({
  type: PAYMENTSDETAILS.PAGE_NOTIFY,
  payload: action.payload,
});

export const notifyEventPageOrderDetails = (action) => ({
  type: PAYMENTSDETAILS.EVENT_PAGE_NOTIFY,
  payload: action.payload,
});
