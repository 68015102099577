import { call, put } from 'redux-saga/effects';

import generateDetailsQuery from 'utils/generateDetailsQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  CUSTOMERSDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCustomerDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMERS}/${gid}`);

const fetchCardDetails = async (payload) => {
  const { gid } = payload;
  const { currentPage } = payload;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const query = generateDetailsQuery(currentPage);
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CUSTOMER_CARDS}=${gid}&${query}${sorting}`);
};

const fetchPaymentDetails = async (payload) => {
  const {
    customerGid,
    currentPage,
  } = payload;
  const sorting = payload.sortParams ? payload.sortParams : 'sortBy=createdAt&direction=DESC';
  const query = `size=10&page=${currentPage}`;
  return axiosAuthInstance.get(`${API_END_POINTS.CUSTOMER_PAYMENTS}=${customerGid}&${query}&${sorting}`);
};

const patchCustomer = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_CUSTOMERS}/${gid}`, params);

export function* getCustomerDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCustomerDetails, action.payload);
    yield put({ type: CUSTOMERSDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CUSTOMERSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getCardDetails(action) {
  try {
    const { data: { entity } } = yield call(fetchCardDetails, action.payload);
    yield put({ type: CUSTOMERSDETAILS.CARDS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: CUSTOMERSDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getCustomerPayments(action) {
  try {
    const { data: { entity } } = yield call(fetchPaymentDetails, action.payload);
    yield put({ type: CUSTOMERSDETAILS.PAYMENTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
      yield put({ type: CUSTOMERSDETAILS.FAILED, payload: e.response.data });
    }
  }
}

export function* updateCustomers(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCustomer, action.payload);
    const { data: { entity } } = yield call(fetchCustomerDetails, action.payload.gid);
    yield put({ type: CUSTOMERSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
