// @flow
import React, {
  Children, isValidElement, createElement, useState, useEffect,
} from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import { Select, Radio } from 'antd';
import routes from 'config/routes';
import capitalize from 'utils/capitalize';

import CONSTANTS from 'store/constants';

import { dashboardActions } from 'store/actions';
import { PAGES, LARGE_VOLUME_MERCHANTS } from 'appconstants';
import {
  SpBcSpan,
  SpBcLink,
  SpBcText,
} from 'components/DesignKit';
import unionImg from 'imgs/Union.png';

const { Option } = Select;

type Props = ContextRouter & {
  history: {
    push: Function,
  },
  location: {
    pathname: string,
  }
};

const BreadCrumbSwitchComponent = (props: Props) => {
  const { location: { pathname }, children } = props;
  let element;
  let matched = false;
  Children
    .forEach(children, (child) => {
      if (!matched && isValidElement(child)) {
        element = child;
        const { path } = child.props;
        matched = path && matchPath(pathname, { ...child.props });
      }
    });
  return matched
    ? createElement(element.props.component)
    : (
      <DefaultBreadCrumb />
    );
};

const DefaultBreadCrumb = withRouter((props: Props) => {
  const { location: { pathname } } = props;
  const activeRoute = routes.find((route) => route.path === pathname);
  if (!activeRoute) {
    props.history.push(PAGES.NO_MATCH);
    return <div />;
  }
  const crumbs = pathname
    .split('/')
    .filter(x => !!x);
  return (
    <div>
      {
        crumbs
          .map((crumb, index) => (
            (index !== crumbs.length - 1)
              ? (
                <SpBcLink
                  key={index}
                  to={`/${crumbs.slice(0, index + 1).join('/')}`}
                >
                  {capitalize(crumb)}
                  &nbsp;
                  -
                  &nbsp;
                </SpBcLink>
              )
              : (
                <SpBcSpan
                  key={index}
                >
                  {capitalize(crumb)}
                </SpBcSpan>
              )
          ))
      }
    </div>
  );
});

const BreadCrumbSwitch = withRouter(BreadCrumbSwitchComponent);

const BreadCrumbRoute = () => (<></>);

type DashboardProps = {
  dashboard: Object,
  account: Object,
  setDuration: Function,
  setSummaryType: Function,
};

const DashboardComponent = ({
  dashboard: { duration },
  account: { selectedAccount, test },
  setDuration,
  setSummaryType,
}: DashboardProps) => {
  const [summaryType, setSummary] = useState('Payments');
  const [largeMerchants, setLargeMerchant] = useState(false);

  useEffect(() => {
    const gid = selectedAccount && selectedAccount.gid;
    const largeMerchant = LARGE_VOLUME_MERCHANTS.includes(gid);
    setLargeMerchant(largeMerchant);
    if (selectedAccount && selectedAccount.timezone && selectedAccount.currency) {
      setDuration(duration, selectedAccount.timezone, selectedAccount.currency.name, summaryType);
      setSummaryType(summaryType);
    }
  }, [selectedAccount, test]);

  const updateDuration = (value) => {
    setDuration(value, selectedAccount.timezone, selectedAccount.currency.name, summaryType);
  };

  const updatePaymentType = (e) => {
    const type = e.target.value;
    if (type === 'Transfers') {
      setSummary(type);
    } else if (type === 'Payments') {
      setSummary(type);
    }
    setDuration(duration, selectedAccount.timezone, selectedAccount.currency.name, type);
    setSummaryType(type);
  };

  return (
    <>
      <SpBcSpan>
        Dashboard
      </SpBcSpan>
      {
        selectedAccount && selectedAccount.timezone && selectedAccount.currency
          ? (
            <>
              <Select
                className="ml-4"
                defaultValue={duration}
                style={{ width: 160 }}
                bordered
                onChange={(value) => updateDuration(value)}
                disabled={largeMerchants}
              >
                <Option value={CONSTANTS.DASHBOARD.LAST_1_DAYS}>Today</Option>
                <Option value={CONSTANTS.DASHBOARD.LAST_7_DAYS}>Last 7 days</Option>
                <Option value={CONSTANTS.DASHBOARD.LAST_30_DAYS}>Last 30 days</Option>
                <Option value={CONSTANTS.DASHBOARD.LAST_3_MONTHS}>Last 3 months</Option>
                <Option value={CONSTANTS.DASHBOARD.LAST_12_MONTHS}>Last 12 months</Option>
                <Option value={CONSTANTS.DASHBOARD.YEAR_TO_DATE}>Year to date</Option>
              </Select>
              <Radio.Group
                defaultValue={summaryType}
                onChange={value => updatePaymentType(value)}
                className="ml-4"
                buttonStyle="solid"
              >
                <Radio.Button value="Payments">Payments</Radio.Button>
                <Radio.Button value="Transfers">Transfers</Radio.Button>
              </Radio.Group>
            </>
          )
          : null
      }
    </>
  );
};

const mapStateToProps = ({ dashboard, account }) => ({ dashboard, account });

const mapDispatchToProps = (dispatch) => ({
  setDuration: (...params) => dispatch(
    dashboardActions.setDuration(...params),
  ),
  setSummaryType: param => dispatch(dashboardActions.setSummaryType({
    payload: param,
  })),
});

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);


const WalletComponent = ({
  account: { selectedAccount },
}: WalletProps) => (
  <>
    <SpBcSpan>
      Wallet <img src={unionImg} alt="duration" className="ml-2" />
    </SpBcSpan>
    {
      selectedAccount && selectedAccount.timezone && selectedAccount.currency
        ? (
          <>
            <Select
              className="ml-2"
              style={{ width: 160 }}
              prefix={unionImg}
            >
              <Option value={CONSTANTS.DASHBOARD.LAST_1_DAYS}>Today</Option>
              <Option value={CONSTANTS.DASHBOARD.LAST_7_DAYS}>Last 7 days</Option>
              <Option value={CONSTANTS.DASHBOARD.LAST_30_DAYS}>Last 30 days</Option>
              <Option value={CONSTANTS.DASHBOARD.LAST_3_MONTHS}>Last 3 months</Option>
              <Option value={CONSTANTS.DASHBOARD.LAST_12_MONTHS}>Last 12 months</Option>
              <Option value={CONSTANTS.DASHBOARD.YEAR_TO_DATE}>Year to date</Option>
            </Select>
          </>
        )
        : null
    }
  </>
);

const mapStateToPropsWallet = ({ account }) => ({ account });

const Wallet = connect(mapStateToPropsWallet, null)(WalletComponent);

const PaymentDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payments${searchParams}` : '/payments?status.IN=SUCCEEDED'}
    >
      Payments
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const PaymentDisputes = () => (
  <>
    <SpBcLink
      to="/payments"
    >
      Payments
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Disputes
    </SpBcSpan>
  </>
);

const PaymentPayouts = () => (
  <>
    <SpBcLink
      to="/payments"
    >
      Payments
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Payouts
    </SpBcSpan>
  </>
);

const PaymentTransactions = () => (
  <>
    <SpBcLink
      to="/payments"
    >
      Payments
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      All transactions
    </SpBcSpan>
  </>
);

const PaymentCashTransactions = () => (
  <>
    <SpBcLink
      to="/payments"
    >
      Payments
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      All transactions
    </SpBcSpan>
  </>
);

const PaymentChequeTransactions = () => (
  <>
    <SpBcLink
      to="/payments"
    >
      Payments
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      All transactions
    </SpBcSpan>
  </>
);

const AccountSettlement = () => (
  <>
    <SpBcLink
      to="/payments"
    >
      Payments
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Settlement
    </SpBcSpan>
  </>
);
const PaymentPageEntries = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payment-pages${searchParams}` : '/payment-pages'}
    >
      Payments Page
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Payment Page Entries
    </SpBcSpan>
  </>
);

const CashTransactionDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/transaction/cash${searchParams}` : '/transaction/cash'}
    >
      Transaction
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Cash Details
    </SpBcSpan>
  </>
);

const ChequeTransactionDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/transaction/cheque${searchParams}` : '/transaction/cheque'}
    >
      Transaction
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Cheque Details
    </SpBcSpan>
  </>
);

const DisputeEvidence = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payments${searchParams}` : '/payments'}
    >
      Payments
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Dispute Details
    </SpBcSpan>
  </>
);

const PaymentsLink = () => (
  <>
    <SpBcSpan>
      Links
    </SpBcSpan>
  </>
);
const PayLinks = () => (
  <>
    <SpBcSpan>
      Payment Links
    </SpBcSpan>
  </>
);

const EventPages = () => (
  <>
    <SpBcSpan>
      Events
    </SpBcSpan>
  </>
);

const TransferLinks = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Transfer Links
    </SpBcSpan>
  </>
);

const PaymentPages = () => (
  <>
    <SpBcSpan>
      Pages
    </SpBcSpan>
  </>
);

const PaymentPagesDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payment-pages${searchParams}` : '/payment-pages'}
    >
      Pages
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const PaymentOrderDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payment-pages${searchParams}` : '/payment-pages'}
    >
      Pages
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Payment Order Details
    </SpBcSpan>
  </>
);

const PaymentsLinkDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payment-links${searchParams}` : '/payment-links'}
    >
      Links
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const PaymentsButton = () => (
  <SpBcSpan>
    Buttons
  </SpBcSpan>
);

const PaymentsButtonDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payment-buttons${searchParams}` : '/payment-buttons'}
    >
      Buttons
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const PaymentsWidgets = () => (
  <SpBcSpan>
    Widgets
  </SpBcSpan>
);

const PaymentsWidgetsDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payment-widgets${searchParams}` : '/payment-widgets'}
    >
      Widgets
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const SubscriptionButton = () => (
  <SpBcSpan>
    Subscription Buttons
  </SpBcSpan>
);

const SubscriptionButtonDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/subscription-button${searchParams}` : '/subscription-button'}
    >
      Subscription Buttons
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const Plans = () => (
  <>
    <SpBcSpan>
      Plans
    </SpBcSpan>
  </>
);

const SubscriptionDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/subscriptions${searchParams}` : '/subscriptions'}
    >
      Subscriptions
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const PlanDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/subscriptions/plans${searchParams}` : '/subscriptions/plans'}
    >
      Plans
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const UserBreadCrumb = () => (
  <>
    <SpBcSpan>
      User Profile
    </SpBcSpan>
  </>
);

const AccountSettings = () => (
  <>
    <SpBcText>
      Settings &nbsp;-
      &nbsp;
    </SpBcText>
    <SpBcSpan>
      Account
    </SpBcSpan>
  </>
);

const AchSettings = () => (
  <>
    <SpBcLink to="/settings">
      Settings &nbsp;-
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      ACH Payments
    </SpBcSpan>
  </>
);

const BankTransferSettings = () => (
  <>
    <SpBcLink to="/settings">
      Settings &nbsp;-
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Bank Transfers
    </SpBcSpan>
  </>
);

const DocumentSettings = () => (
  <>
    <SpBcLink to="/settings">
      Settings &nbsp;-
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Documents
    </SpBcSpan>
  </>
);

const TransferSettings = () => (
  <>
    <SpBcLink to="/settings">
      Settings &nbsp;-
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Transfer
    </SpBcSpan>
  </>
);

const DocumentSettingsDetails = () => (
  <>
    <SpBcLink to="/settings/customdocuments">
      Document Request
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const USOnboardingSetting = () => (
  <>
    <SpBcLink to="/settings/usonboard">
      Settings &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      US Onboarding
    </SpBcSpan>
  </>
);

const PayoutsDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payouts${searchParams}` : '/payouts'}
    >
      Payouts
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const CustomersDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/customers${searchParams}` : '/customers'}
    >
      Customers
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const TerminlaDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/terminals${searchParams}` : '/terminals'}
    >
      Terminals
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const TerminlaLocationDetails = (searchParams) => (
  <>
    <SpBcLink
      to="/terminals"
    >
      Terminals
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcLink
      to={searchParams ? `/locations${searchParams}` : '/locations'}
    >
      Locations
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const TerminlaLocation = () => (
  <>
    <SpBcLink
      to="/terminals"
    >
      Terminals
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Locations
    </SpBcSpan>
  </>
);

const TerminlaService = () => (
  <SpBcSpan>
    Service
  </SpBcSpan>
);

const binAdmin = () => (
  <SpBcSpan>
    Bin
  </SpBcSpan>
);

const staticQrAdmin = () => (
  <SpBcSpan>
    Static QR
  </SpBcSpan>
);

const staticQrAdminDetails = () => (
  <>
    <SpBcLink
      to="/staticqr-admin"
    >
      Static QR
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const reportTemplate = () => (
  <SpBcSpan>
    Report Templates
  </SpBcSpan>
);

const reportTemplateDetails = () => (
  <>
    <SpBcLink
      to="/reporttemplates"
    >
      Report Templates
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const customReport = () => (
  <SpBcSpan>
    Custom Report
  </SpBcSpan>
);

const customReportDetails = () => (
  <>
    <SpBcLink
      to="/customreport"
    >
      Custom Report
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);


const support = () => (
  <SpBcSpan>
    Support
  </SpBcSpan>
);

const TerminlaServiceDetails = (searchParams) => (
  <>
    <SpBcLink
      to="/terminals"
    >
      Terminals
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcLink
      to={searchParams ? `/services${searchParams}` : '/services'}
    >
      Service
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const NewAccount = () => (
  <SpBcSpan>
    New Account
  </SpBcSpan>
);

const InvoiceDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/invoices${searchParams}` : '/invoices'}
    >
      Invoices
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const NewInvoice = () => (
  <>
    <SpBcLink
      to="/invoices"
    >
      Invoices
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      New Invoice
    </SpBcSpan>
  </>
);

const Rates = () => (
  <>
    <SpBcSpan>
      Acquirer Rates
    </SpBcSpan>
  </>
);

const RatesDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/rate${searchParams}` : '/rate'}
    >
      Acquirer Rates
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const IssuerRates = () => (
  <>
    <SpBcSpan>
      Issuer Rates
    </SpBcSpan>
  </>
);

const ExchaneRates = () => (
  <>
    <SpBcSpan>
      Exchange Rates
    </SpBcSpan>
  </>
);

const IssuerRatesDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/rate/issuerrate${searchParams}` : '/rate/issuerrate'}
    >
      Issuer Rates
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const TaxRates = () => (
  <SpBcSpan>
    Tax Rates
  </SpBcSpan>
);

const CouponDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/coupons${searchParams}` : '/coupons'}
    >
      Coupons
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const OfferDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/offers${searchParams}` : '/offers'}
    >
      Offers
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const ConnectedAccounts = () => (
  <>
    <SpBcSpan>
      Affiliated Accounts
    </SpBcSpan>
  </>
);

const ConnectedTransactions = () => (
  <>
    <SpBcSpan>
      Affiliated Transactions
    </SpBcSpan>
  </>
);

const ConnectedEarnings = () => (
  <>
    <SpBcSpan>
      Partner Earnings
    </SpBcSpan>
  </>
);

const ConnectedSettlement = () => (
  <>
    <SpBcSpan>
      Partner Settlement
    </SpBcSpan>
  </>
);
const TransactionsDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/transactionsList${searchParams}` : '/transactionsList'}
    >
      Transactions
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);
const SalesForceDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/integrations${searchParams}` : '/integrations'}
    >
      Integrations
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Salesforce
    </SpBcSpan>
  </>
);

const AdminTerminals = () => (
  <>
    <SpBcLink
      to="/admin"
    >
      Admin
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Terminals
    </SpBcSpan>
  </>
);

const AdminReports = () => (
  <>
    <SpBcLink
      to="/admin"
    >
      Admin
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Reports
    </SpBcSpan>
  </>
);

const ScheduleReports = () => (
  <>
    <SpBcSpan>
      Schedule Reports
    </SpBcSpan>
  </>
);

const UpdateCredentials = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/integrations${searchParams}` : '/integrations'}
    >
      Integrations
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Update Credentials
    </SpBcSpan>
  </>
);

const AdminTerminalDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/admin${searchParams}` : '/admin'}
    >
      Admin
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcLink
      to="/terminal-admin"
    >
      Terminals
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const PosKeys = () => (
  <SpBcSpan>
    Pos Keys
  </SpBcSpan>
);

const GRKey = () => (
  <SpBcSpan>
    Global Restricted Key
  </SpBcSpan>
);

const SPField = () => (
  <SpBcSpan>
    SP Field
  </SpBcSpan>
);

const SPObject = () => (
  <SpBcSpan>
    SP Object
  </SpBcSpan>
);

const Issuer = () => (
  <SpBcSpan>
    Issuer
  </SpBcSpan>
);

const InternalUsers = () => (
  <SpBcSpan>
    Internal Users
  </SpBcSpan>
);

const Vendors = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Contacts
    </SpBcSpan>
  </>
);
const FundingSourceDetails = () => (
  <>
    <SpBcLink
      to="/funding-sources"
    >
      Funding Sources
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const VendorsDetails = () => (
  <>
    <SpBcLink
      to="/funding-sources/contacts"
    >
      Contact
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const TransfersDetails = () => (
  <>
    <SpBcLink
      to="/transfers"
    >
      View Activity
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const Transfers = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      View Activity
    </SpBcSpan>
  </>
);

const FundingSource = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Funding Sources
    </SpBcSpan>
  </>
);

const FundingSourceLink = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Funding Source Links
    </SpBcSpan>
  </>
);

const BulkTransfer = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Bulk Transfer
    </SpBcSpan>
  </>
);

const BulkTransferDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/bulk-transfer${searchParams}` : '/bulk-transfer'}
    >
      Bulk Transfer
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const PaymentCheckout = () => (
  <>
    <SpBcSpan>
      Checkout
    </SpBcSpan>
  </>
);

const PaymentCheckoutDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/checkout-page${searchParams}` : '/checkout-page'}
    >
      Checkout
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const AdminPayments = () => (
  <>
    <SpBcSpan>
      Payments
    </SpBcSpan>
  </>
);

const AdminPaymentDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/payment-admin${searchParams}` : '/payment-admin'}
    >
      Payments
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const OCRRequest = () => (
  <SpBcSpan>
    OCR Request
  </SpBcSpan>
);

const BankStatement = () => (
  <SpBcSpan>
    Bank Account Statement
  </SpBcSpan>
);

const BankStatementDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/bankstatement${searchParams}` : '/bankstatement'}
    >
      Bank Account Statement
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const AccountSlug = () => (
  <>
    <SpBcSpan>
      Account Slug
    </SpBcSpan>
  </>
);

const AccountSlugDetails = () => (
  <>
    <SpBcLink
      to="/accountslug"
    >
      Account Slug
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const BalanceDetails = () => (
  <>
    <SpBcLink
      to="/wallet"
    >
      Wallet
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Manage Balance
    </SpBcSpan>
  </>
);

const WalletTransactions = () => (
  <SpBcSpan>
    Transactions
  </SpBcSpan>
);

const VendorInvoiceDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/vendorinvoice${searchParams}` : '/vendorinvoice'}
    >
      Vendor Invoices
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const VendorInvoice = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Vendor Invoice
    </SpBcSpan>
  </>
);

const BeneficiaryDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/beneficiary${searchParams}` : '/beneficiary'}
    >
      Beneficiary
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const WalletVendorInvoice = () => (
  <>
    <SpBcSpan>
      Vendor
      &nbsp;
      -
      &nbsp; Invoices
    </SpBcSpan>
  </>
);

const AddWalletVendorInvoice = () => (
  <>
    <SpBcLink
      to="/vendorinvoice-wallet"
    >
      Vendor Invoices
    </SpBcLink>
    <SpBcSpan>
      &nbsp;
      -
      &nbsp;
      Add
      &nbsp;
      -
      &nbsp; New
    </SpBcSpan>
  </>
);
const AddVendorInvoice = () => (
  <>
    <SpBcLink
      to="/vendorinvoice"
    >
      Vendor Invoice
    </SpBcLink>
    <SpBcSpan>
      &nbsp;
      -
      &nbsp;
      Add
    </SpBcSpan>
  </>
);

const WalletInvoiceDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/vendorinvoice-wallet${searchParams}` : '/vendorinvoice-wallet'}
    >
      Vendor Invoices
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const WooCommerceOrderDetails = (searchParams) => (
  <>
    <SpBcLink
      to={searchParams ? `/integrations${searchParams}` : '/integrations'}
    >
      Integrations
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Order Details
    </SpBcSpan>
  </>
);

const adminCatalog = () => (
  <>
    <SpBcSpan>
      Products
    </SpBcSpan>
  </>
);

const OrderDetails = () => (
  <>
    <SpBcLink
      to="/orders"
    >
      Order
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const ItemDetails = () => (
  <>
    <SpBcLink
      to="/items"
    >
      Item
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const CategorieDetails = () => (
  <>
    <SpBcLink
      to="/categories"
    >
      Categories
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const Menus = () => (
  <SpBcSpan>
    Menu
  </SpBcSpan>
);

const Modifiers = () => (
  <>
    <SpBcSpan>
      Modifier Groups
    </SpBcSpan>
  </>
);

const ModifierDetails = () => (
  <>
    <SpBcLink
      to="/modifiers"
    >
      Modifier Group
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const EventsList = () => (
  <SpBcSpan>
    Seated Events
  </SpBcSpan>
);

const EventsDetails = () => (
  <>
    <SpBcLink
      to="/events"
    >
      Seated Events
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const NonSeatedEventPage = () => (
  <>
    <SpBcLink
      to="/event-page/non-seated"
    >
      Non Seated Events
    </SpBcLink>
  </>
);

const SeatedEventPage = () => (
  <>
    <SpBcLink
      to="/event-page/seated"
    >
      Seated Events
    </SpBcLink>
  </>
);

const EventPageDetails = () => (
  <>
    <SpBcLink
      to="/events-dashboard"
    >
      Event Page
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const OrderTypes = () => (
  <>
    <SpBcSpan>
      Order Types
    </SpBcSpan>
  </>
);

const AdminOverview = () => (
  <>
    <SpBcSpan>
      Admin Overview
    </SpBcSpan>
  </>
);

const PrinterLabel = () => (
  <>
    <SpBcSpan>
      Printer Labels
    </SpBcSpan>
  </>
);

const PrinterLabelDetails = () => (
  <>
    <SpBcLink
      to="/printerlabel"
    >
      Printer Labels
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const ShopsDetails = () => (
  <>
    <SpBcLink
      to="/shops"
    >
      Shops
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const CloverWebhook = () => (
  <>
    <SpBcLink
      to="/integrations"
    >
      Integrations
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Clover webhook
    </SpBcSpan>
  </>
);

const InternalWebhook = () => (
  <>
    <SpBcLink to="/settings">
      Settings &nbsp;-
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Clover Webhook
    </SpBcSpan>
  </>
);

const InternalDashboard = () => (
  <>
    <SpBcSpan>
      Custom Dashboard
    </SpBcSpan>
  </>
);
const DealsDashboard = () => (
  <>
    <SpBcSpan>
      Deals Slider
    </SpBcSpan>
  </>
);

const OperatingHours = () => (
  <>
    <SpBcSpan>
      Operating Hours
    </SpBcSpan>
  </>
);

const AdvanceFee = () => (
  <>
    <SpBcSpan>
      Advance Fees
    </SpBcSpan>
  </>
);

const CloverSync = () => (
  <>
    <SpBcLink to="/settings">
      Settings &nbsp;-
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Clover Sync
    </SpBcSpan>
  </>
);

const CustomDashboard = () => (
  <SpBcSpan>
    Custom Dashboard
  </SpBcSpan>
);

const KDSDashboard = () => (
  <SpBcSpan>
    KDS Dashboard
  </SpBcSpan>
);

const DiningComponent = () => (
  <SpBcSpan>
    Dining
  </SpBcSpan>
);

const DiningListComponent = () => (
  <SpBcSpan>
    Tables
  </SpBcSpan>
);

const DiningViewComponent = () => (
  <SpBcSpan>
    Table Details
  </SpBcSpan>
);

const ReservationComponent = () => (
  <>
    <SpBcLink
      to="/reservations"
    >
      Reservations
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const PreorderComponent = () => (
  <SpBcSpan>
    Pre Orders
  </SpBcSpan>
);

const CustomerSupport = () => (
  <SpBcSpan>
    Customer Support
  </SpBcSpan>
);

const Productsterminals = () => (
  <SpBcSpan>
    Catalog
  </SpBcSpan>
);

const Servicecharge = () => (
  <SpBcSpan>
    Service Charge
  </SpBcSpan>
);

const SendMoney = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Transfer Funds
    </SpBcSpan>
  </>
);

const AccountTransfers = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Account Transfers
    </SpBcSpan>
  </>
);

const ManageAccounts = () => (
  <>
    <SpBcLink
      to="/transfercenter"
    >
      Transfer Center
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Manage Accounts
    </SpBcSpan>
  </>
);

const ManageAccountDetails = () => (
  <>
    <SpBcLink
      to="/manageaccounts"
    >
      Manage Accounts
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const transferCenter = () => (
  <SpBcSpan>
    Transfer Center
  </SpBcSpan>
);

const donationPages = () => (
  <SpBcSpan>
    Donation Pages
  </SpBcSpan>
);

const DonationPagesDetails = () => (
  <>
    <SpBcLink
      to="/donation-pages"
    >
      Donation Pages
      &nbsp;
      -
      &nbsp;
    </SpBcLink>
    <SpBcSpan>
      Details
    </SpBcSpan>
  </>
);

const virtualTerminal = () => (
  <SpBcSpan>
    Virtual Terminal
  </SpBcSpan>
);


const Breadcrumb = (props: Props) => {
  const { search } = useSelector(state => state.filterParams.preserveFilters);
  const { pathname } = props.location;
  const searchParams = search;
  const activeRoute = routes.find((route) => matchPath(pathname, route));
  if (!activeRoute) {
    return <div />;
  }
  return (
    <BreadCrumbSwitch {...props}>
      <BreadCrumbRoute
        exact
        path="/payments/:gid"
        component={() => PaymentDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/disputes"
        component={PaymentDisputes}
      />
      <BreadCrumbRoute
        exact
        path="/payouts"
        component={PaymentPayouts}
      />
      <BreadCrumbRoute
        exact
        path="/transaction"
        component={PaymentTransactions}
      />
      <BreadCrumbRoute
        exact
        path="/transaction/cash"
        component={PaymentCashTransactions}
      />
      <BreadCrumbRoute
        exact
        path="/transaction/cheque"
        component={PaymentChequeTransactions}
      />
      <BreadCrumbRoute
        exact
        path="/accountsettlement"
        component={AccountSettlement}
      />
      <BreadCrumbRoute
        exact
        path="/payments/:gid/dispute"
        component={() => DisputeEvidence(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/payment-page/:gid"
        component={() => PaymentPageEntries(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/payment-links"
        component={PaymentsLink}
      />
      <BreadCrumbRoute
        exact
        path="/pay-links"
        component={PayLinks}
      />
      <BreadCrumbRoute
        exact
        path="/events-dashboard"
        component={EventPages}
      />
      <BreadCrumbRoute
        exact
        path="/payment-links/:gid"
        component={() => PaymentsLinkDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/payment-pages"
        component={PaymentPages}
      />
      <BreadCrumbRoute
        exact
        path="/payment-pages/:gid"
        component={() => PaymentPagesDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/payment-orders/:gid"
        component={() => PaymentOrderDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/beneficiary/:gid"
        component={() => BeneficiaryDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/payment-buttons"
        component={PaymentsButton}
      />
      <BreadCrumbRoute
        exact
        path="/payment-buttons/:gid"
        component={() => PaymentsButtonDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/payment-widgets"
        component={PaymentsWidgets}
      />
      <BreadCrumbRoute
        exact
        path="/payment-widgets/:gid"
        component={() => PaymentsWidgetsDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/subscription-button"
        component={SubscriptionButton}
      />
      <BreadCrumbRoute
        exact
        path="/subscription-button/:gid"
        component={() => SubscriptionButtonDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/subscriptions/plans"
        component={Plans}
      />
      <BreadCrumbRoute
        exact
        path="/subscriptions/:gid"
        component={() => SubscriptionDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/subscriptions/plans/:gid"
        component={() => PlanDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/user"
        component={UserBreadCrumb}
      />
      <BreadCrumbRoute
        exact
        path="/settings"
        component={AccountSettings}
      />
      <BreadCrumbRoute
        exact
        path="/settings/ach-payments"
        component={AchSettings}
      />
      <BreadCrumbRoute
        exact
        path="/settings/bank-transfer"
        component={BankTransferSettings}
      />
      <BreadCrumbRoute
        exact
        path="/settings/customdocuments"
        component={DocumentSettings}
      />
      <BreadCrumbRoute
        exact
        path="/settings/transfer"
        component={TransferSettings}
      />
      <BreadCrumbRoute
        exact
        path="/customdocuments/:gid"
        component={DocumentSettingsDetails}
      />
      <BreadCrumbRoute
        exact
        path="/settings/usonboard"
        component={USOnboardingSetting}
      />
      <BreadCrumbRoute
        exact
        path="/payouts/:gid"
        component={() => PayoutsDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/customers/:gid"
        component={() => CustomersDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/customer-payments/:gid"
        component={() => CustomersDetails(searchParams)}
      />
      {/* <BreadCrumbRoute
        exact
        path="/customer-banks/:gid"
        component={() => CustomersDetails(searchParams)}
      /> */}
      <BreadCrumbRoute
        exact
        path="/terminals/:gid"
        component={() => TerminlaDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/locations"
        component={TerminlaLocation}
      />
      <BreadCrumbRoute
        exact
        path="/locations/:gid"
        component={() => TerminlaLocationDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/dashboard"
        component={Dashboard}
      />
      <BreadCrumbRoute
        exact
        path="/newaccount"
        component={NewAccount}
      />
      <BreadCrumbRoute
        exact
        path="/invoices/:gid"
        component={() => InvoiceDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/invoices/add/new"
        component={NewInvoice}
      />
      <BreadCrumbRoute
        exact
        path="/rate"
        component={Rates}
      />
      <BreadCrumbRoute
        exact
        path="/rate/:gid/acquirer"
        component={() => RatesDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/tax-rates"
        component={TaxRates}
      />
      <BreadCrumbRoute
        exact
        path="/coupons/:gid"
        component={() => CouponDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/offers/:gid"
        component={() => OfferDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/partner"
        component={ConnectedAccounts}
      />
      <BreadCrumbRoute
        exact
        path="/transactions/partner"
        component={ConnectedTransactions}
      />
      <BreadCrumbRoute
        exact
        path="/earnings/partner"
        component={ConnectedEarnings}
      />
      <BreadCrumbRoute
        exact
        path="/settlement/partner"
        component={ConnectedSettlement}
      />
      <BreadCrumbRoute
        exact
        path="/integrations/salesforcedetails"
        component={() => SalesForceDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/integrations/updatecredentials"
        component={() => UpdateCredentials(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/scheduled-report-attempt"
        component={ScheduleReports}
      />
      <BreadCrumbRoute
        exact
        path="/report-admin"
        component={AdminReports}
      />
      <BreadCrumbRoute
        exact
        path="/terminal-admin"
        component={AdminTerminals}
      />
      <BreadCrumbRoute
        exact
        path="/terminal-admin/:gid"
        component={() => AdminTerminalDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/sp-object"
        component={SPObject}
      />
      <BreadCrumbRoute
        exact
        path="/sp-field"
        component={SPField}
      />
      <BreadCrumbRoute
        exact
        path="/pos-keys"
        component={PosKeys}
      />
      <BreadCrumbRoute
        exact
        path="/global-restricted-key"
        component={GRKey}
      />
      <BreadCrumbRoute
        exact
        path="/acquirer/issuer"
        component={Issuer}
      />
      <BreadCrumbRoute
        exact
        path="/rate/issuerrate"
        component={IssuerRates}
      />
      <BreadCrumbRoute
        exact
        path="/rate/exchangerate"
        component={ExchaneRates}
      />
      <BreadCrumbRoute
        exact
        path="/rate/:gid/issuer"
        component={() => IssuerRatesDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/transaction/cash/:gid"
        component={() => CashTransactionDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/transaction/cheque/:gid"
        component={() => ChequeTransactionDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/internaluser"
        component={InternalUsers}
      />
      <BreadCrumbRoute
        exact
        path="/services"
        component={TerminlaService}
      />
      <BreadCrumbRoute
        exact
        path="/services/:gid"
        component={() => TerminlaServiceDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/bin-admin"
        component={binAdmin}
      />
      <BreadCrumbRoute
        exact
        path="/reporttemplates"
        component={reportTemplate}
      />
      <BreadCrumbRoute
        exact
        path="/reporttemplates/:gid"
        component={reportTemplateDetails}
      />
      <BreadCrumbRoute
        exact
        path="/customreport"
        component={customReport}
      />
      <BreadCrumbRoute
        exact
        path="/customreport/:gid"
        component={customReportDetails}
      />
      <BreadCrumbRoute
        exact
        path="/staticqr-admin"
        component={staticQrAdmin}
      />
      <BreadCrumbRoute
        exact
        path="/staticqr-admin/:gid"
        component={staticQrAdminDetails}
      />
      <BreadCrumbRoute
        exact
        path="/services-support"
        component={support}
      />
      <BreadCrumbRoute
        exact
        path="/funding-sources/contacts"
        component={Vendors}
      />
      <BreadCrumbRoute
        exact
        path="/fundingsource-details/:gid"
        component={FundingSourceDetails}
      />
      <BreadCrumbRoute
        exact
        path="/contacts/:gid"
        component={VendorsDetails}
      />
      <BreadCrumbRoute
        exact
        path="/transfers"
        component={Transfers}
      />
      <BreadCrumbRoute
        exact
        path="/transfers/:gid"
        component={TransfersDetails}
      />
      <BreadCrumbRoute
        exact
        path="/transfer-links"
        component={TransferLinks}
      />
      <BreadCrumbRoute
        exact
        path="/funding-sources"
        component={FundingSource}
      />
      <BreadCrumbRoute
        exact
        path="/funding-sources/link"
        component={FundingSourceLink}
      />
      <BreadCrumbRoute
        exact
        path="/bulk-transfer"
        component={BulkTransfer}
      />
      <BreadCrumbRoute
        exact
        path="/bulk-transfer/:gid"
        component={() => BulkTransferDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/checkout-page"
        component={PaymentCheckout}
      />
      <BreadCrumbRoute
        exact
        path="/checkout-page/:gid"
        component={() => PaymentCheckoutDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/payment-admin"
        component={AdminPayments}
      />
      <BreadCrumbRoute
        exact
        path="/payment-admin/:gid"
        component={() => AdminPaymentDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/ocrrequest"
        component={OCRRequest}
      />
      <BreadCrumbRoute
        exact
        path="/bankstatement"
        component={BankStatement}
      />
      <BreadCrumbRoute
        exact
        path="/bankstatement/:gid"
        component={() => BankStatementDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/accountslug"
        component={AccountSlug}
      />
      <BreadCrumbRoute
        exact
        path="/accountslug/:gid"
        component={AccountSlugDetails}
      />
      <BreadCrumbRoute
        exact
        path="/walletdetails/:gid"
        component={BalanceDetails}
      />
      <BreadCrumbRoute
        exact
        path="/transactionsList"
        component={WalletTransactions}
      />
      <BreadCrumbRoute
        exact
        path="/transactionsList/:gid"
        component={() => TransactionsDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/wallet"
        component={Wallet}
      />
      <BreadCrumbRoute
        exact
        path="/vendorinvoice"
        component={VendorInvoice}
      />
      <BreadCrumbRoute
        exact
        path="/vendorinvoice/:gid"
        component={() => VendorInvoiceDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/vendorinvoice-wallet"
        component={WalletVendorInvoice}
      />
      <BreadCrumbRoute
        exact
        path="/vendorinvoice-wallet/add/new"
        component={AddWalletVendorInvoice}
      />
      <BreadCrumbRoute
        exact
        path="/vendorinvoice/add/new"
        component={AddVendorInvoice}
      />
      <BreadCrumbRoute
        exact
        path="/vendorinvoice-wallet/:gid"
        component={() => WalletInvoiceDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/woocommerce-orders/:gid"
        component={() => WooCommerceOrderDetails(searchParams)}
      />
      <BreadCrumbRoute
        exact
        path="/catalog"
        component={adminCatalog}
      />
      <BreadCrumbRoute
        exact
        path="/catalog/adminorder"
        component={adminCatalog}
      />
      <BreadCrumbRoute
        exact
        path="/orders/:gid"
        component={OrderDetails}
      />
      <BreadCrumbRoute
        exact
        path="/items/:gid"
        component={ItemDetails}
      />
      <BreadCrumbRoute
        exact
        path="/categories/:gid"
        component={CategorieDetails}
      />
      <BreadCrumbRoute
        exact
        path="/menus"
        component={Menus}
      />
      <BreadCrumbRoute
        exact
        path="/modifiers"
        component={Modifiers}
      />
      <BreadCrumbRoute
        exact
        path="/modifiers/:gid"
        component={ModifierDetails}
      />
      <BreadCrumbRoute
        exact
        path="/events"
        component={EventsList}
      />
      <BreadCrumbRoute
        exact
        path="/events/:gid"
        component={EventsDetails}
      />
      <BreadCrumbRoute
        exact
        path="/event-page/non-seated"
        component={NonSeatedEventPage}
      />
      <BreadCrumbRoute
        exact
        path="/event-page/seated"
        component={SeatedEventPage}
      />
      <BreadCrumbRoute
        exact
        path="/event-page/:gid"
        component={EventPageDetails}
      />
      <BreadCrumbRoute
        exact
        path="/ordertypes"
        component={OrderTypes}
      />
      <BreadCrumbRoute
        exact
        path="/overview"
        component={AdminOverview}
      />
      <BreadCrumbRoute
        exact
        path="/printerlabel"
        component={PrinterLabel}
      />
      <BreadCrumbRoute
        exact
        path="/printerlabel/:gid"
        component={PrinterLabelDetails}
      />
      <BreadCrumbRoute
        exact
        path="/shops/:gid"
        component={ShopsDetails}
      />
      <BreadCrumbRoute
        exact
        path="/integrations/cloverwebhook"
        component={CloverWebhook}
      />
      <BreadCrumbRoute
        exact
        path="/settings/cloverwebhook"
        component={InternalWebhook}
      />
      <BreadCrumbRoute
        exact
        path="/custom-dashboard"
        component={InternalDashboard}
      />
      <BreadCrumbRoute
        exact
        path="/deals-slider"
        component={DealsDashboard}
      />
      <BreadCrumbRoute
        exact
        path="/operatinghours"
        component={OperatingHours}
      />
      <BreadCrumbRoute
        exact
        path="/settings/advancefee"
        component={AdvanceFee}
      />
      <BreadCrumbRoute
        exact
        path="/customdashboard"
        component={CustomDashboard}
      />
      <BreadCrumbRoute
        exact
        path="/vieworders"
        component={KDSDashboard}
      />
      <BreadCrumbRoute
        exact
        path="/dining/:gid"
        component={DiningComponent}
      />
      <BreadCrumbRoute
        exact
        path="/settings/cloversync"
        component={CloverSync}
      />
      <BreadCrumbRoute
        exact
        path="/dininglist/:gid"
        component={DiningListComponent}
      />
      <BreadCrumbRoute
        exact
        path="/diningdetails/:gid"
        component={DiningViewComponent}
      />
      <BreadCrumbRoute
        exact
        path="/reservations/:gid"
        component={ReservationComponent}
      />
      <BreadCrumbRoute
        exact
        path="/preorder"
        component={PreorderComponent}
      />
      <BreadCrumbRoute
        exact
        path="/supportcustomer"
        component={CustomerSupport}
      />
      <BreadCrumbRoute
        exact
        path="/productsterminals"
        component={Productsterminals}
      />
      <BreadCrumbRoute
        exact
        path="/service-rates"
        component={Servicecharge}
      />
      <BreadCrumbRoute
        exact
        path="/sendmoney"
        component={SendMoney}
      />
      <BreadCrumbRoute
        exact
        path="/accounttransfers"
        component={AccountTransfers}
      />
      <BreadCrumbRoute
        exact
        path="/manageaccounts"
        component={ManageAccounts}
      />
      <BreadCrumbRoute
        exact
        path="/manageaccounts/:gid"
        component={ManageAccountDetails}
      />
      <BreadCrumbRoute
        exact
        path="/transfercenter"
        component={transferCenter}
      />
      <BreadCrumbRoute
        exact
        path="/donation-pages"
        component={donationPages}
      />
      <BreadCrumbRoute
        exact
        path="/donation-pages/:gid"
        component={() => DonationPagesDetails()}
      />
      <BreadCrumbRoute
        exact
        path="/virtual-terminal"
        component={() => virtualTerminal()}
      />
    </BreadCrumbSwitch>
  );
};

export default withRouter(Breadcrumb);
