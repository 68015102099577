export const getBarCodeLabel = (value) => {
  switch (value) {
    case 'BARCODE_TYPE_1':
      return 'BARCODE CODE 1';
    case 'BARCODE_TYPE_2':
      return 'BARCODE TYPE 2';
    case 'BARCODE_TYPE_3':
      return 'BARCODE TYPE 3';
    case 'BARCODE_TYPE_4':
      return 'BARCODE TYPE 4';
    default:
      return '';
  }
};
