import React, { useEffect, useState } from 'react';
import {
  Col,
  Card,
  Row,
  Skeleton,
  Icon,
} from 'antd';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import _get from 'lodash.get';
import moment from 'moment-timezone';
import { PAGES } from 'appconstants';

import { SpText, SpwarningBadge } from 'components/DesignKit';
import { dashboardActions } from 'store/actions';
import formatAmount from 'utils/formatAmount';
import formatNumber from 'utils/formatNumber';
import CONSTANTS from 'store/constants';
import arrow from 'imgs/arrow.png';

import {
  TransactionTable,
  ClientsTable,
  TransactionsCharts,
  GrowthIndicator,
} from './components';

type Props = {
  dashboard: Object,
  totalVolume: Object,
  capturedVolume: Object,
  monthlySummary: Array,
  dailySummary: Array,
  hourlySummary: Array,
  customers: Object,
  interval: String,
  account: Object,
  yearToDateSummary: Array,
  history: Function,
  business: Object,
  partner: boolean,
  isMobileView: Boolean,
};

const MONTHLY_SELECTORS = [
  CONSTANTS.DASHBOARD.LAST_1_MONTHS,
  CONSTANTS.DASHBOARD.LAST_3_MONTHS,
  CONSTANTS.DASHBOARD.LAST_12_MONTHS,
];

const HOURLY_SELECTORS = [
  CONSTANTS.DASHBOARD.LAST_1_DAYS,
];

const YEAR_TO_DATE_SELECTORS = [
  CONSTANTS.DASHBOARD.YEAR_TO_DATE,
];

const accountSummaryFilter = ({ dashboard: { accountSummary } }) => accountSummary;
const dailySummaryFilter = ({ dashboard: { dailySummary } }) => dailySummary;
const hourlySummaryFilter = ({ dashboard: { hourlySummary } }) => hourlySummary;
const durationFilter = ({ dashboard: { duration } }) => duration;
const customersFilter = ({ dashboard: { customers } }) => customers;
const selectedAccountFilter = ({ account: { selectedAccount } }) => selectedAccount;

const totalVolumeSelector = createSelector(
  [accountSummaryFilter],
  ({ current, previous }) => ({
    prefix: _get(current, 'currencyDto.prefix', null),
    value: _get(current, 'totalVolume', null) / 100,
    increase: (current && current.totalVolume && previous && previous.totalVolume)
      ? (((current.totalVolume - previous.totalVolume) / previous.totalVolume) * 100).toFixed(1)
      : null,
    average: current && current.totalVolume && current.count
      ? ((current.totalVolume / current.count) / 100)
      : 0.00,
  }),
);

const capturedVolumeSelector = createSelector(
  [accountSummaryFilter],
  ({ current, previous }) => ({
    value: _get(current, 'capturedVolume', null) / 100,
    increase: (current && current.capturedVolume && previous && previous.capturedVolume)
      ? (((current.capturedVolume - previous.capturedVolume) / previous.capturedVolume) * 100).toFixed(1)
      : null,
  }),
);

const customersSelector = createSelector(
  [customersFilter],
  ({ current, previous }) => ({
    value: _get(current, 'totalElements', null),
    increase: (current && current.totalElements && previous && previous.totalElements)
      ? (((current.totalElements - previous.totalElements) / previous.totalElements) * 100).toFixed(1)
      : null,
  }),
);

const monthlySummarySelector = createSelector(
  [dailySummaryFilter, selectedAccountFilter, durationFilter],
  ({ current }, selectedAccount, duration) => {
    const monthlyAxis = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let amountInfo = [];
    const amountData = [];
    if (current && MONTHLY_SELECTORS.includes(duration)) {
      const { content } = current;
      for (let j = 0; j <= content.length - 1; j += 1) {
        let date;
        if (content[j].paymentMonth) {
          const startYear = ((content[j].paymentMonth).toString()).slice(0, 4);
          const startMonth = ((content[j].paymentMonth).toString()).slice(4, 6);
          date = new Date(`${startYear}/${startMonth}`);
        } else if (content[j].transferMonth) {
          const startYear = ((content[j].transferMonth).toString()).slice(0, 4);
          const startMonth = ((content[j].transferMonth).toString()).slice(4, 6);
          date = new Date(`${startYear}/${startMonth}`);
        }
        const priorDate = moment(date).format('MMM');
        const amountList = content[j].totalVolume / 100;
        amountData.push({ date: priorDate, amount: amountList });
      }
      amountInfo = Array.from(amountData.reduce(
        (m, { date, amount }) => m.set(date, (m.get(date) || 0) + amount), new Map(),
      ), ([date, amount]) => ({ date, amount }));
    }
    const result = monthlyAxis.map((el) => {
      const obj = amountInfo.filter(item => (item.date === el));
      return (obj[0]) ? obj[0].amount : 0;
    });
    return result;
  },
);

const yearToDateSummarySelector = createSelector(
  [dailySummaryFilter, selectedAccountFilter, durationFilter],
  ({ current }, selectedAccount, duration) => {
    const monthlyAxis = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let amountInfo = [];
    const amountData = [];
    if (current && YEAR_TO_DATE_SELECTORS.includes(duration)) {
      const { content } = current;
      for (let j = 0; j <= content.length - 1; j += 1) {
        let date;
        if (content[j].paymentMonth) {
          const startYear = ((content[j].paymentMonth).toString()).slice(0, 4);
          const startMonth = ((content[j].paymentMonth).toString()).slice(4, 6);
          date = new Date(`${startYear}/${startMonth}`);
        } else if (content[j].transferMonth) {
          const startYear = ((content[j].transferMonth).toString()).slice(0, 4);
          const startMonth = ((content[j].transferMonth).toString()).slice(4, 6);
          date = new Date(`${startYear}/${startMonth}`);
        }
        const priorDate = moment(date).format('MMM');
        const amountList = content[j].totalVolume / 100;
        amountData.push({ date: priorDate, amount: amountList });
      }
      amountInfo = Array.from(amountData.reduce(
        (m, { date, amount }) => m.set(date, (m.get(date) || 0) + amount), new Map(),
      ), ([date, amount]) => ({ date, amount }));
    }
    const result = monthlyAxis.map((el) => {
      const obj = amountInfo.filter(item => (item.date === el));
      return (obj[0]) ? obj[0].amount : 0;
    });
    return result;
  },
);

const dailySummarySelector = createSelector(
  [dailySummaryFilter, selectedAccountFilter, durationFilter],
  ({ current }, selectedAccount, duration) => {
    const dailyAxis = [];
    const amountData = [];
    let amountInfo = [];
    const today = new Date();
    const currentDate = today.toLocaleString('en-US', { timeZone: selectedAccount && selectedAccount.timezone });
    if (duration === CONSTANTS.DASHBOARD.LAST_30_DAYS) {
      for (let i = 1; i <= 30; i += 1) {
        const priorDate = new Date().setDate(new Date(currentDate).getDate() - i);
        const date = moment(priorDate).format('MMM DD');
        dailyAxis.push(date);
      }
    } else if (duration !== CONSTANTS.DASHBOARD.LAST_30_DAYS) {
      for (let i = 0; i <= 6; i += 1) {
        const priorDate = new Date().setDate(new Date(currentDate).getDate() - i);
        const date = moment(priorDate).format('MMM DD');
        dailyAxis.push(date);
      }
    }
    if (current) {
      const { content } = current;
      for (let j = 0; j <= content.length - 1; j += 1) {
        const dateList = moment(content[j].paymentDate ? content[j].paymentDate : content[j].transferDate).format('MMM DD');
        const amountList = content[j].totalVolume / 100;
        amountData.push({ date: dateList, amount: amountList });
      }
      amountInfo = Array.from(amountData.reduce(
        (m, { date, amount }) => m.set(date, (m.get(date) || 0) + amount), new Map(),
      ), ([date, amount]) => ({ date, amount }));
    }
    const result = dailyAxis.reverse().map((el) => {
      const obj = amountInfo.filter(item => (item.date === el));
      return (obj[0]) ? obj[0].amount : 0;
    });
    return result;
  },
);

const hourlySummarySelector = createSelector(
  [hourlySummaryFilter, selectedAccountFilter, durationFilter],
  ({ current }, selectedAccount, duration) => {
    const hourlyAxis = [];
    const amountData = [];
    let amountInfo = [];
    for (let i = 0; i < 24; i += 1) {
      const fmt = i
        .toLocaleString(undefined, { minimumIntegerDigits: 2 });
      hourlyAxis.push(`${fmt}:00`);
    }
    if (current && HOURLY_SELECTORS.includes(duration)) {
      const { content } = current;
      for (let j = 0; j <= content.length - 1; j += 1) {
        let priorDate;
        if (content[j].rangeTo < 10) {
          priorDate = `0${content[j].rangeTo}:00`;
        } else if (content[j].rangeTo >= 10) {
          priorDate = `${content[j].rangeTo}:00`;
        }
        const amountList = content[j].totalVolume / 100;
        amountData.push({ date: priorDate, amount: amountList });
      }
      amountInfo = Array.from(amountData.reduce(
        (m, { date, amount }) => m.set(date, (m.get(date) || 0) + amount), new Map(),
      ), ([date, amount]) => ({ date, amount }));
    }
    const result = hourlyAxis.map((el) => {
      const obj = amountInfo.filter(item => (item.date === el));
      return (obj[0]) ? obj[0].amount : 0;
    });
    return result;
  },
);

const intervalSelector = createSelector(
  [durationFilter],
  (duration) => {
    if (MONTHLY_SELECTORS.includes(duration)) {
      return 'MONTHLY';
    }
    if (HOURLY_SELECTORS.includes(duration)) {
      return 'HOURLY';
    }
    if (YEAR_TO_DATE_SELECTORS.includes(duration)) {
      return 'YEAR_TO_DATE';
    }
    return 'DAILY';
  },
);

const DashboardView = ({
  dashboard: {
    topPayments,
    customerSummary,
    statusSummary,
    accountSummaryStatus,
    statusSummaryStatus,
    customerSummaryStatus,
    topPaymentsStatus,
    customersStatus,
    duration,
    summaryType,
  },
  account: {
    selectedAccount,
  },
  totalVolume,
  capturedVolume,
  monthlySummary,
  hourlySummary,
  dailySummary,
  customers,
  interval,
  yearToDateSummary,
  history,
  business,
  partner,
  isMobileView,
}: Props) => {
  const [successfulPayments, setSuccessfulPayments] = useState('Successful Payments');
  const [totalCustomers, setTotalCustomers] = useState('Total Customers');
  const [paymentsOverTime, setPaymentsOverTime] = useState('Payments Over Time');
  const [breakup, setBreakUp] = useState('Breakup Of Payment Sources');
  const [topPayment, setTopPayment] = useState('Top Payments');
  const [topCustomers, setTopCustomers] = useState('Top Customers');

  useEffect(() => {
    if (summaryType && summaryType === 'Transfers') {
      setSuccessfulPayments('Successful Transfers');
      setTotalCustomers('Total Vendors');
      setPaymentsOverTime('Transfers Over Time');
      setBreakUp('Breakup Of Transfers Sources');
      setTopPayment('Top Transfers');
      setTopCustomers('Top Vendors');
    } else if (summaryType && summaryType === 'Payments') {
      setSuccessfulPayments('Successful Payments');
      setTotalCustomers('Total Customers');
      setPaymentsOverTime('Payments Over Time');
      setBreakUp('Breakup Of Payment Sources');
      setTopPayment('Top Payments');
      setTopCustomers('Top Customers');
    }
  }, [summaryType]);

  const currencyType = selectedAccount && selectedAccount.currency;

  return (
    <div>
      {/*
        (selectedAccount && selectedAccount.contactGivenName && selectedAccount.contactEmail) ? (
          <Modal
            visible={isOpen}
            title="Docusign"
            width={1000}
            style={{ maxHeight: '100vh' }}
            onCancel={closeModal}
            onOk={closeModal}
          >
            <div style={{ width: '100%', height: '100vh' }}>
              <iframe
            // eslint-disable-next-line max-len
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                title="PowerForm"
              >
                Your browser does not support iframes.
              </iframe>
            </div>
          </Modal>
        ) : (
          <></>
        )
      */}

      {
        (!business && !partner) && (
          <Card className="mb-2" style={{ border: '2px solid #e7800f', backgroundColor: '#ffefde', paddingTop: '12px' }}>
            <Row type="flex" justify="space-between" className="my-2">
              <Col>
                <SpText fontSize="17px" color="#354052">
                  <Icon
                    type="warning"
                    style={{ fontSize: '20px', color: '#e7800f' }}
                    className="mr-2"
                  />Please provide business details to process live payments.
                </SpText>
              </Col>
              <Col>
                <SpwarningBadge onClick={() => { history.push(PAGES.BUSINESS); }}>
                  Submit Details
                  <img src={arrow} alt="arrow" className="ml-2" />
                </SpwarningBadge>
              </Col>
            </Row>
          </Card>
        )
      }
      {
        (selectedAccount && selectedAccount.status === 'PENDING_TO_SIGN') && (
        <Card className="mb-2" style={{ border: '2px solid #e7800f', backgroundColor: '#ffefde', paddingTop: '12px' }}>
          <Row type="flex" justify="space-between" className="my-2">
            <Col>
              <SpText fontSize="17px" color="#354052">
                <Icon
                  type="warning"
                  style={{ fontSize: '20px', color: '#e7800f' }}
                  className="mr-2"
                />Agreement is not Signed yet , are you want to Complete it.
              </SpText>
            </Col>
            <Col>
              <SpwarningBadge onClick={() => { history.push(PAGES.BUSINESS); }}>
                Submit Details
                <img src={arrow} alt="arrow" className="ml-2" />
              </SpwarningBadge>
            </Col>
          </Row>
        </Card>
        )
      }
      <Row>
        <Col span={isMobileView ? 24 : 8} className={isMobileView ? 'mb-2' : ''}>
          <Card bodyStyle={{ minHeight: '150px' }}>
            <SpText fontSize="20px" color="#354052">Total Volume</SpText>
            <Skeleton
              title={false}
              rows={2}
              active
              loading={accountSummaryStatus === CONSTANTS.API_STATUS.STARTED}
            >
              <h3 className="font-weight-light mr-2 my-0 text-center my-3">
                {totalVolume.prefix}
                {currencyType && currencyType.toFixed && (
                  <>{formatAmount(totalVolume.value ? totalVolume.value : 0, currencyType)}</>
                )}
              </h3>
              <div className="text-center">
                <GrowthIndicator value={totalVolume.increase} />
              </div>
            </Skeleton>
          </Card>
        </Col>
        <Col span={isMobileView ? 24 : 8} className={isMobileView ? 'mb-2' : ''}>
          <Card bodyStyle={{ minHeight: '150px' }}>
            <SpText fontSize="20px" color="#354052">{successfulPayments}</SpText>
            <Skeleton
              title={false}
              rows={2}
              active
              loading={accountSummaryStatus === CONSTANTS.API_STATUS.STARTED}
            >{
              summaryType === 'Payments' ? (
                <h3 className="font-weight-light mr-2 my-0 text-center my-3">
                  {totalVolume.prefix}
                  {currencyType && currencyType.toFixed && (
                  <>{formatAmount(capturedVolume.value ? capturedVolume.value : 0, currencyType)}</>
                  )}
                </h3>
              ) : (
                <h3 className="font-weight-light mr-2 my-0 text-center my-3">
                  {totalVolume.prefix}
                  {currencyType && currencyType.toFixed && (
                  <>{formatAmount(totalVolume.value ? totalVolume.value : 0, currencyType)}</>
                  )}
                </h3>
              )
            }

              <div className="text-center">
                <GrowthIndicator value={capturedVolume.increase} />
              </div>
            </Skeleton>
          </Card>
        </Col>
        <Col span={isMobileView ? 24 : 8} className={isMobileView ? 'mb-2' : ''}>
          <Card bodyStyle={{ minHeight: '150px' }}>
            <SpText fontSize="20px" color="#354052">{totalCustomers}</SpText>
            <Skeleton
              title={false}
              rows={2}
              active
              loading={customersStatus === 'STARTED'}
            >
              {
                summaryType === 'Payments' ? (
                  <h3 className="font-weight-light mr-2 my-0 text-center my-3">
                    {formatNumber(customers.value, currencyType)}
                  </h3>
                ) : (
                  <h3 className="font-weight-light mr-2 my-0 text-center my-3">
                    {customerSummary.numberOfElements}
                  </h3>
                )
              }
              <div className="text-center">
                <GrowthIndicator
                  value={customers.increase}
                  currency={customers.currency}
                />
              </div>
            </Skeleton>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={isMobileView ? 24 : 24} className={isMobileView ? 'mb-2' : ''}>
          <TransactionsCharts
            paymentsOverTime={paymentsOverTime}
            breakup={breakup}
            isMobileView={isMobileView}
            selectedAccount={selectedAccount}
            interval={interval}
            duration={duration}
            monthlySummary={monthlySummary}
            dailySummary={dailySummary}
            yearToDateSummary={yearToDateSummary}
            hourlySummary={hourlySummary}
            statusSummary={statusSummary.current || []}
            totalVolume={totalVolume}
            summaryType={summaryType}
            isPaymentSourcesLoading={
              accountSummaryStatus === CONSTANTS.API_STATUS.STARTED
              || statusSummaryStatus === CONSTANTS.API_STATUS.STARTED
            }
          />
        </Col>
      </Row>

      <Row>
        <Col span={isMobileView ? 24 : 12} className={isMobileView ? 'mb-2' : ''}>
          <Card bodyStyle={{ minHeight: '400px' }}>
            <SpText fontSize="20px" color="#354052">{topPayment}</SpText>
            <Skeleton
              title
              paragraph={{
                rows: 7,
              }}
              active
              loading={topPaymentsStatus === CONSTANTS.API_STATUS.STARTED}
            >
              <TransactionTable
                dataSource={topPayments.content}
                selectedAccount={selectedAccount}
                summaryType={summaryType}
              />
            </Skeleton>
          </Card>
        </Col>
        <Col span={isMobileView ? 24 : 12} className={isMobileView ? 'mb-2' : ''}>
          <Card bodyStyle={{ minHeight: '400px' }}>
            <SpText fontSize="20px" color="#354052">{topCustomers}</SpText>
            <Skeleton
              title
              paragraph={{
                rows: 7,
              }}
              active
              loading={customerSummaryStatus === CONSTANTS.API_STATUS.STARTED}
            >
              <ClientsTable
                dataSource={customerSummary.content}
                selectedAccount={selectedAccount}
                summaryType={summaryType}
              />
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ dashboard, account, mobileView }) => ({
  dashboard,
  account,
  totalVolume: totalVolumeSelector({ dashboard, account }),
  capturedVolume: capturedVolumeSelector({ dashboard, account }),
  totalCustomers: customersSelector({ dashboard, account }),
  monthlySummary: monthlySummarySelector({ dashboard, account }),
  dailySummary: dailySummarySelector({ dashboard, account }),
  hourlySummary: hourlySummarySelector({ dashboard, account }),
  yearToDateSummary: yearToDateSummarySelector({ dashboard, account }),
  customers: customersSelector({ dashboard, account }),
  interval: intervalSelector({ dashboard, account }),
  isMobileView: mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  setDuration: (...params) => dispatch(
    dashboardActions.setDuration(...params),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
