// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  Row,
  Col,
  Divider,
  Modal,
} from 'antd';
import styled from 'styled-components';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';

import getStatus from 'utils/getStatus';
import getBusinessAccountType from 'utils/getBusinessAccountType';

import {
  SpForm,
  SpButton,
  SpH5,
  SpText,
} from 'components/DesignKit';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import RBAC from 'components/rbac';
import {
  ROLES,
  MESSAGES,
  whenIsCardCharged,
  servicesDeliveredIn,
  refundPolicy,
  booleanToText,
} from 'appconstants';

import {
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';


const StatusTag = styled('div')({
  display: 'inline-block',
  paddingLeft: '10px',
  paddingRight: '10px',
  borderRadius: '10px',
  height: '21px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  business: Object,
  status: string,
  businessRepresentatives: Array<Object>,
  editBusiness: Function,
  selectedAccount: Object,
  setNotification: Function,
  internalRole: Object,
  isIndia: Boolean,
  businessTypes: Array<Object>,
  fetchAccountById:Funtion,
  debouncedfetchBusinessByAccountId:Funtion,
  fetchDocusignStatus: Function,
  docs: Array<Object>,
}

const View = (props: Props) => {
  const {
    business,
    status,
    businessRepresentatives,
    editBusiness,
    selectedAccount,
    setNotification,
    internalRole,
    isIndia,
    businessTypes,
    fetchAccountById,
    debouncedfetchBusinessByAccountId,
    docs,
    fetchDocusignStatus,
  } = props;

  const { text, color } = getStatus(docs.length === 0 ? status : docs.status);
  const [phoneFormate, setPhoneFormate] = useState('');
  const [businessphoneFormate, setBusinessPhoneFormate] = useState('');
  const [typesOfBusiness, setTypesOfBusiness] = useState([]);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [isOpen, setIsOpen] = useState(false);
  const [signBussinessRep, setSignBussinessRep] = useState('');

  useEffect(() => {
    if (businessRepresentatives && businessRepresentatives.length > 0) {
      const maxPercentage = Math.max(...businessRepresentatives.map(o => o.ownerShipPercentage));
      const tempBusiness = businessRepresentatives.filter((item) => item.ownerShipPercentage === JSON.stringify(maxPercentage));
      if (tempBusiness && tempBusiness.length > 0 && tempBusiness[0].givenName && tempBusiness[0].familyName) {
        setSignBussinessRep(`${tempBusiness[0].givenName} ${tempBusiness[0].familyName}`);
      }
    }
  }, [businessRepresentatives]);


  useEffect(() => {
    setTypesOfBusiness(businessTypes.filter((item) => item.value === business.type));
  }, []);

  useEffect(() => {
    if (businessRepresentatives[0]) {
      const cntryCode = businessRepresentatives[0].phone.substring(0, 2);
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      }
    }
  }, [businessRepresentatives]);

  useEffect(() => {
    if (business && business.businessPhoneNumber) {
      const cntryCode = business.businessPhoneNumber.substring(0, 2);
      if (cntryCode === '+1') {
        setBusinessPhoneFormate('+# (###) ###-####');
      } else if (cntryCode === '+9') {
        setBusinessPhoneFormate('+##-##### #####');
      }
    }
  }, [business]);

  const downloadKyc = async (record) => {
    try {
      const { data: { entity: { outputFileUrl } } } = await axiosAuthInstance
        .post(`${API_END_POINTS.DOWNLOAD_KYC}`, {
          inputFileUrl: record,
          documentType: 'KYC',
        });
      window.open(outputFileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };


  /* const onMyFrameLoad = () => {
    setTimeout(() => {
      setIsOpen(false);
      debouncedfetchBusinessByAccountId(selectedAccount.gid);
    }, 5000);
  }; */


  const closeModal = (data) => {
    fetchDocusignStatus(data);
    setIsOpen(false);
    fetchAccountById(data);
    debouncedfetchBusinessByAccountId(data);
  };

  const getWhenIsCardCharged = (cardChargedType) => (cardChargedType === whenIsCardCharged.WHEN_SERVICE_PROVIDED ? 'Yes' : 'No');


  return (

    <>
      {
        (selectedAccount && selectedAccount.name && selectedAccount.contactEmail) ? (
          <Modal
            centered
            visible={isOpen}
            title="Docusign"
            width={1000}
            style={{ maxHeight: '100vh' }}
            onCancel={() => closeModal(selectedAccount.gid)}
            onOk={() => closeModal(selectedAccount.gid)}
          >
            <div style={{ width: '100%', height: '100vh' }}>
              <iframe
                // eslint-disable-next-line max-len
                src={`${process.env.REACT_APP_POWERFORM}&Authorized%20Signatory_UserName=${signBussinessRep}&Authorized%20Signatory_Email=${selectedAccount.contactEmail}&EnvelopeField_AccountGID=${selectedAccount.gid}`}
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                title="PowerForm"
                id="PowerForm"
              // onLoad={() => onMyFrameLoad()}
              >
                Your browser does not support iframes.
              </iframe>
            </div>
          </Modal>
        ) : (
          <></>
        )
      }
      <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
        <Col className="d-flex align-items-center">
          <SpText className="mr-4 text-uppercase" fontSize="20px">Manage Business Settings</SpText>
          <StatusTag style={{ background: color }}>
            {text}
          </StatusTag>
        </Col>
        <Col>
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.ONBOARDING, ROLES.SUPERUSER]}>
            <SpButton
              type="secondary"
              shape="round"
              onClick={() => editBusiness(true)}
            >
              Edit Business Settings
            </SpButton>
          </RBAC>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Document Verification</span>
          </Col>
          <Col span={4}>
            <SpH5>
              {docs.length === 0
                ? selectedAccount.status.replace(/_/g, ' ')
                : docs.status.replace(/_/g, ' ')}
            </SpH5>
          </Col>
          {((docs.length === 0) && (selectedAccount.status === 'PENDING_TO_SIGN')) ? (
            <Col span={3}>
              <SpButton
                type="secondary"
                shape="round"
                onClick={() => setIsOpen(true)}
              >
                Click to Complete
              </SpButton>
            </Col>
          )
            : (docs.status === 'PENDING_TO_SIGN') ? (
              <Col span={3}>
                <SpButton
                  type="secondary"
                  shape="round"
                  onClick={() => setIsOpen(true)}
                >
                  Click to Complete
                </SpButton>
              </Col>
            ) : ''}
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Business Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{business.name}</SpH5>
          </Col>
        </Row>
        {
          internalRole && (
            <>
              <Row type="flex" justify="start">
                <Col span={4}>
                  <span>Business Type</span>
                </Col>
                <Col span={9}>
                  <SpH5>{typesOfBusiness[0] ? typesOfBusiness[0].display : '-'}</SpH5>
                </Col>
              </Row>
              <Row type="flex" justify="start">
                <Col span={4}>
                  <span>Business Category</span>
                </Col>
                <Col span={9}>
                  <SpH5>{business.category.name}</SpH5>
                </Col>
              </Row>
            </>
          )
        }

        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Business Website</span>
          </Col>
          <Col span={9}>
            <SpH5>{business.url}</SpH5>
          </Col>
        </Row>

        {!isIndia && (
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Company Start Date</span>
          </Col>
          <Col span={9}>
            <SpH5>{
              business.companyStartDate ? moment
                .utc(business.companyStartDate)
                .format('MMM DD, YYYY') : '-'
            }
            </SpH5>
          </Col>
        </Row>
        )}

        {
          business.address && (
            <>
              <Row type="flex" justify="start">
                <Col span={4}>
                  <span>Street Address</span>
                </Col>
                <Col span={9}>
                  <SpH5>
                    {business.address.street}, {business.address.city}, {business.address.state}, {business.address.postalCode}
                  </SpH5>
                </Col>
              </Row>
              <Row type="flex" justify="start">
                <Col span={4}>
                  <span>Country</span>
                </Col>
                <Col span={9}>
                  <SpH5>{business.address.country.name}</SpH5>
                </Col>
              </Row>
              <Row type="flex" justify="start">
                <Col span={4}>
                  <span>Statement Descriptor</span>
                </Col>
                <Col span={9}>
                  <SpH5>{business.statementDescriptor}</SpH5>
                </Col>
              </Row>

              {
                isIndia && (
                  <>
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Account Type</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>{getBusinessAccountType(business.accountType)}</SpH5>
                      </Col>
                    </Row>
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Estimated Annual Turn Over</span>
                      </Col>
                      <Col span={9}>
                        {
                          (business.annualTurnOver && currencyType) ? (
                            <SpH5>
                              {`${currencyType.prefix}${formatNumber((business.annualTurnOver / 100).toFixed(currencyType.toFixed), currencyType)}`}
                            </SpH5>
                          ) : (business.annualTurnOver === 0) ? <SpH5>{`${currencyType.prefix}0.00`}</SpH5> : <>&#8211;</>
                        }
                      </Col>
                    </Row>
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Estimated Annual Card Volume</span>
                      </Col>
                      <Col span={9}>
                        {
                          (business.annualCardVolume && currencyType) ? (
                            <SpH5>
                              {`${currencyType.prefix}${formatNumber((business.annualCardVolume / 100).toFixed(currencyType.toFixed), currencyType)}`}
                            </SpH5>
                          ) : (business.annualCardVolume === 0) ? <SpH5>{`${currencyType.prefix}0.00`}</SpH5> : <>&#8211;</>
                        }
                      </Col>
                    </Row>
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Estimated Annual UPI Volume</span>
                      </Col>
                      <Col span={9}>
                        {
                          (business.annualUpiVolume && currencyType) ? (
                            <SpH5>
                              {`${currencyType.prefix}${formatNumber((business.annualUpiVolume / 100).toFixed(currencyType.toFixed), currencyType)}`}
                            </SpH5>
                          ) : (business.annualUpiVolume === 0) ? <SpH5>{`${currencyType.prefix}0.00`}</SpH5> : <>&#8211;</>
                        }
                      </Col>
                    </Row>
                  </>
                )
              }

              {
                internalRole && (
                  <>
                    {
                      isIndia ? (
                        <>
                          <Row type="flex" justify="start">
                            <Col span={4}>
                              <span>Business PAN</span>
                            </Col>
                            <Col span={9}>
                              <SpH5>{business.businessPan}</SpH5>
                            </Col>
                          </Row>
                          <Row type="flex" justify="start">
                            {
                              business.type !== 'LIMITED_LIABILITY_PARTNERSHIP' ? (
                                <>
                                  <Col span={4}>
                                    <span>Company Identification Number (CIN)</span>
                                  </Col>
                                  <Col span={9}>
                                    <SpH5>{business.cin}</SpH5>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col span={4}>
                                    <span>LLP ID</span>
                                  </Col>
                                  <Col span={9}>
                                    <SpH5>{business.llpin}</SpH5>
                                  </Col>
                                </>
                              )
                            }
                          </Row>
                          <Row type="flex" justify="start">
                            <Col span={4}>
                              <span>GST identification number</span>
                            </Col>
                            <Col span={9}>
                              {
                                business.gst ? (
                                  <SpH5>{business.gst}</SpH5>
                                ) : (
                                  <SpH5>&#8211;</SpH5>
                                )
                              }
                            </Col>
                          </Row>
                          <Row type="flex" justify="start">
                            <Col span={4}>
                              <span>Tax deduction account number (TAN)</span>
                            </Col>
                            <Col span={9}>
                              {
                                business.tan ? (
                                  <SpH5>{business.tan}</SpH5>
                                ) : (
                                  <SpH5>&#8211;</SpH5>
                                )
                              }
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row type="flex" justify="start">
                          <Col span={4}>
                            <span>Employer Identification No.</span>
                          </Col>
                          <Col span={9}>
                            <SpH5>{business.ein}</SpH5>
                          </Col>
                        </Row>
                      )
                    }

                  </>
                )
              }

              {!isIndia && (
              <>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Business Phone Number</span>
                  </Col>
                  <Col span={9}>
                    <NumberFormat
                      className="numFormate"
                      value={business.businessPhoneNumber}
                      format={businessphoneFormate}
                      mask="_"
                      disabled
                    />
                  </Col>
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Is Business Tax Exempted Organisation</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{booleanToText[business.isTaxExemptOrganisation]}</SpH5>
                  </Col>
                </Row>
              </>
              )}
            </>
          )
        }
        {
          business.kycRegistrationProofUrl && (
            <RBAC permit={[ROLES.SUPERUSER]}>
              <Row type="flex" justify="start">
                <Col span={12}>
                  <div style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => downloadKyc(business.kycRegistrationProofUrl)}
                    >
                      Business Registration Proof
                    </SpText>
                  </div>
                </Col>
              </Row>
            </RBAC>
          )
        }
        {
          business.kycCompanyPanUrl && (
            <RBAC permit={[ROLES.SUPERUSER]}>
              <Row type="flex" justify="start">
                <Col span={12}>
                  <div style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => downloadKyc(business.kycCompanyPanUrl)}
                    >
                      Business PAN Proof
                    </SpText>
                  </div>
                </Col>
              </Row>
            </RBAC>
          )
        }
        {
          business.currentAddressProofUrl && (
            <RBAC permit={[ROLES.SUPERUSER]}>
              <Row type="flex" justify="start">
                <Col span={12}>
                  <div style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => downloadKyc(business.currentAddressProofUrl)}
                    >
                      Current Address Proof
                    </SpText>
                  </div>
                </Col>
              </Row>
            </RBAC>
          )
        }
        {
          business.boardResolutionUrl && (
            <RBAC permit={[ROLES.SUPERUSER]}>
              <Row type="flex" justify="start">
                <Col span={12}>
                  <div style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => downloadKyc(business.boardResolutionUrl)}
                    >
                      Board Resolution
                    </SpText>
                  </div>
                </Col>
              </Row>
            </RBAC>
          )
        }
        {
          business.gstCertificateUrl && (
            <RBAC permit={[ROLES.SUPERUSER]}>
              <Row type="flex" justify="start">
                <Col span={12}>
                  <div style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => downloadKyc(business.gstCertificateUrl)}
                    >
                      GST Certificate
                    </SpText>
                  </div>
                </Col>
              </Row>
            </RBAC>
          )
        }
        {
          business.eightyGAndTwelveACertificate && (
            <RBAC permit={[ROLES.SUPERUSER]}>
              <Row type="flex" justify="start">
                <Col span={12}>
                  <div style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => downloadKyc(business.eightyGAndTwelveACertificate)}
                    >
                      80G and 12A Certficate
                    </SpText>
                  </div>
                </Col>
              </Row>
            </RBAC>
          )
        }
      </SpForm>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">Manage Bank Details</SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Account Holder Name</span>
          </Col>
          <Col span={9}>
            <SpH5>{business.bankBeneficiaryName}</SpH5>
          </Col>
        </Row>
        {
          internalRole ? (
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Account Number</span>
              </Col>
              <Col span={9}>
                <SpH5>{business.bankAccountNumber}</SpH5>
              </Col>
            </Row>
          ) : (
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Account Number</span>
              </Col>
              <Col span={9}>
                <SpH5>************{business.bankAccountLastFour}</SpH5>
              </Col>
            </Row>
          )
        }
        {
          internalRole && (
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Bank Name</span>
              </Col>
              <Col span={9}>
                <SpH5>{business.bankName}</SpH5>
              </Col>
            </Row>
          )
        }
        {
          Boolean(business.bankIfscCode) && (
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>IFSC Number</span>
              </Col>
              <Col span={9}>
                <SpH5>{business.bankIfscCode}</SpH5>
              </Col>
            </Row>
          )
        }

        {
          Boolean(business.bankRoutingNumber) && (
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Bank Routing Number</span>
              </Col>
              <Col span={9}>
                <SpH5>{business.bankRoutingNumber}</SpH5>
              </Col>
            </Row>
          )
        }

        {(!isIndia && internalRole && business.voidCheckUrl) && (
        <Row type="flex" justify="start">
          <Col span={12}>
            <div style={{ cursor: 'pointer' }}>
              <SpText
                fontWeight="600"
                color="#279dfe"
                onClick={() => downloadKyc(business.voidCheckUrl)}
              >
                Void Check / Bank Letter
              </SpText>
            </div>
          </Col>
        </Row>
        )}

        {
          business.kycBankStatementUrl && (
            <RBAC permit={[ROLES.SUPERUSER]}>
              <Row type="flex" justify="start">
                <Col span={12}>
                  <div style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => downloadKyc(business.kycBankStatementUrl)}
                    >
                      Bank Account Statement
                    </SpText>
                  </div>
                </Col>
              </Row>
            </RBAC>
          )
        }
      </SpForm>

      { !isIndia && (
        <>
          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
            <Col className="d-flex align-items-center">
              <SpText className="mr-4 text-uppercase" fontSize="20px">Processing Information</SpText>
            </Col>
          </Row>

          <SpForm>
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Average Monthly Volume ($)</span>
              </Col>
              <Col span={9}>
                <SpH5>{business.averageMonthlyVolume ? (business.averageMonthlyVolume / 100).toFixed(2) : '-' }</SpH5>
              </Col>
            </Row>

            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Average Ticket Amount ($)</span>
              </Col>
              <Col span={9}>
                <SpH5>{business.averageTicketAmount ? (business.averageTicketAmount / 100).toFixed(2) : '-'}</SpH5>
              </Col>
            </Row>

            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Do Customers Typically Receive Goods/Services at the Time of Purchase?</span>
              </Col>
              <Col span={9}>
                <SpH5>{getWhenIsCardCharged(business.whenIsCardCharged)}</SpH5>
              </Col>
            </Row>

            <Row type="flex" justify="start">
              <Col span={4}>
                <span>When Are Goods and Services Typically Delivered?</span>
              </Col>
              <Col span={9}>
                <SpH5>{servicesDeliveredIn[business.servicesDeliveredIn]}</SpH5>
              </Col>
            </Row>

            <Row type="flex" justify="start">
              <Col span={4}>
                <span>What is Your Current Refund Policy?</span>
              </Col>
              <Col span={9}>
                <SpH5>{refundPolicy[business.refundPolicy]}</SpH5>
              </Col>
            </Row>

            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Is Your Business Seasonal or do you Process Payments Throughout the Year?</span>
              </Col>
              <Col span={9}>
                <SpH5>{booleanToText[business.seasonalBusiness]}</SpH5>
              </Col>
            </Row>

            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Mode of Transaction In Person %</span>
              </Col>
              <Col span={9}>
                <SpH5>{business.modeOfTransactionPerson ? `${business.modeOfTransactionPerson}%` : '-'}</SpH5>
              </Col>
            </Row>

            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Mode of Transaction by Online %</span>
              </Col>
              <Col span={9}>
                <SpH5>{business.modeOfTransactionOnline ? `${business.modeOfTransactionOnline}%` : '-'}</SpH5>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Is Service Date Flexible</span>
              </Col>
              <Col span={9}>
                <SpH5>{booleanToText[business.isServiceDateFlexible]}</SpH5>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Requested Service Date</span>
              </Col>
              <Col span={9}>
                <SpH5>{
                  business.requestedServiceDate ? moment
                    .utc(business.requestedServiceDate)
                    .format('MMM DD, YYYY') : '-'
            }
                </SpH5>
              </Col>
            </Row>
          </SpForm>
        </>
      )}

      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">Business Representatives Details</SpText>
        </Col>
      </Row>
      {
        businessRepresentatives.map((representative, index) => (
          <SpForm key={index}>
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Given Name</span>
              </Col>
              <Col span={4}>
                <SpH5>{representative.givenName}</SpH5>
              </Col>
              <Col span={3}>
                <span>Family Name</span>
              </Col>
              <Col span={4}>
                <SpH5>{representative.familyName}</SpH5>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Contact Email</span>
              </Col>
              <Col span={9}>
                <SpH5>{representative.email}</SpH5>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Phone Number</span>
              </Col>
              <Col span={9}>
                <NumberFormat
                  className="numFormate"
                  value={representative.phone}
                  format={phoneFormate}
                  mask="_"
                  disabled
                />
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Title</span>
              </Col>
              <Col span={9}>
                <SpH5>{representative.title}</SpH5>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Legal Address of the Representative</span>
              </Col>
              <Col span={9}>
                <SpH5>
                  {representative.address.street}, {representative.address.city}
                  , {representative.address.state}, {representative.address.postalCode}
                </SpH5>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Country</span>
              </Col>
              <Col span={9}>
                <SpH5>{representative.address.country.name}</SpH5>
              </Col>
            </Row>
            {
              internalRole && isIndia && (
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Representative&apos;s PAN</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{representative.pan}</SpH5>
                  </Col>
                </Row>
              )
            }
            {
              internalRole && !isIndia && (
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>SSN</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{representative.ssn}</SpH5>
                  </Col>
                </Row>
              )
            }
            <Row type="flex" justify="start">
              <Col span={4}>
                <span>Date of Birth</span>
              </Col>
              <Col span={9}>
                <SpH5>
                  {
                    representative.dateofbirth && selectedAccount
                      ? (
                        moment
                          .utc(representative.dateofbirth)
                          .format('MMM DD, YYYY')
                      )
                      : <>&#8211;</>
                  }
                </SpH5>
              </Col>
            </Row>

            {!isIndia && (
              <>
                {internalRole && (representative.governmentIdUrl) && (
                <Row type="flex" justify="start">
                  <Col span={12}>
                    <div style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={() => downloadKyc(representative.governmentIdUrl)}
                      >
                        Govt. ID (Driver License, Passport etc.)
                      </SpText>
                    </div>
                  </Col>
                </Row>
                )}
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Percentage Ownership</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{representative.ownerShipPercentage ? `${representative.ownerShipPercentage}%` : '-'}</SpH5>
                  </Col>
                </Row>
              </>
            )}
            {
              representative.kycAddressProofUrl && (
                <RBAC permit={[ROLES.SUPERUSER]}>
                  <Row type="flex" justify="start">
                    <Col span={12}>
                      <div style={{ cursor: 'pointer' }}>
                        <SpText
                          fontWeight="600"
                          color="#279dfe"
                          onClick={() => downloadKyc(representative.kycAddressProofUrl)}
                        >
                          Address Proof
                        </SpText>
                      </div>
                    </Col>
                  </Row>
                </RBAC>
              )
            }
            {
              representative.businessRepPanUrl && (
                <RBAC permit={[ROLES.SUPERUSER]}>
                  <Row type="flex" justify="start">
                    <Col span={12}>
                      <div style={{ cursor: 'pointer' }}>
                        <SpText
                          fontWeight="600"
                          color="#279dfe"
                          onClick={() => downloadKyc(representative.businessRepPanUrl)}
                        >
                          PAN Proof
                        </SpText>
                      </div>
                    </Col>
                  </Row>
                </RBAC>
              )
            }
            {
              (index !== businessRepresentatives.length - 1) && (
                <Row>
                  <Col>
                    <Divider className="my-0" />
                  </Col>
                </Row>
              )
            }
          </SpForm>
        ))
      }
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(null, mapDispatchToProps)(View);
