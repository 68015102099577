import React, { useState, useRef, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import styled from 'styled-components';

import {
  SpButton,
  SpForm,
  SpError,
  SpText,
  SpH5,
} from 'components/DesignKit';
import moment from 'moment-timezone';
import { API_END_POINTS, axiosAuthInstance, axiosEmptyInstance } from 'api';
import ImageUpload from 'components/ImageUpload';
import { validators } from 'utils/validationMessages';
import {
  COUNTRY_PHONE_CODE,
  TIME_ZONES,
  DATE_FORMATS,
  ROLES,
} from 'appconstants';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import BarCodeImg from 'assets/imgs/barcode(2).png';
import { getBarCodeValue } from 'utils/getBarCodeValue';

const { Option } = Select;
const StyledDiv = styled.div`
  cursor: pointer;
  &: before {
    font-size: 12px;
    content: "+ Add";
    opacity: 0;
    color: #29b1cc;
    position: absolute; 
    top: 50%;
    left: 50%;
    font-weight: 500;
    border: 1px solid #29b1cc;
    padding: 2px 10px;
    border-radius: 20px;
    background-color: #fff;
    transform: translateX(-50%) translateY(-50%);
 }
 &: hover: before {
  opacity: 1;
 }
`;

type Props = {
  selectedAccount: Object,
  updateAccount: Function,
  setEdit: Function,
  allSequence: Object,
  role: Object,
  allPOSSequence: Array,
  updateCrossBorder: Function,
};

const Edit = (props: Props) => {
  const {
    name,
    contactGivenName,
    contactFamilyName,
    contactEmail,
    contactNumber,
    largeLogo,
    smallLogo,
    banner,
    timezone,
    shouldNotify,
    businessActive,
    kdsFilterTimeInMinutes,
    allowCrossBorderTransfer,
  } = props.selectedAccount;
  const {
    selectedAccount,
    allSequence,
    role,
    allPOSSequence,
    updateCrossBorder,
  } = props;

  const { enableHoursOfOperation } = selectedAccount;
  const [, forceUpdate] = useState();
  const [userGivenName, setUserGivenName] = useState(contactGivenName);
  const [userFamilyName, setUserFamilyName] = useState(contactFamilyName);
  const [accountName, setAccountName] = useState(name);
  const [accountPhone, setAccountPhone] = useState(contactNumber);
  const [accountLargeLogo, setAccountLargeLogo] = useState(largeLogo || '');
  const [accountSmallLogo, setAccountSmallLogo] = useState(smallLogo || '');
  const [accountLargeLogoFile, setAccountLargeLogoFile] = useState(null);
  const [accountSmallLogoFile, setAccountSmallLogoFile] = useState(null);
  const [accountShopBanner, setAccountShopBanner] = useState(banner || '');
  const [accountShopBannerFile, setAccountShopBannerFile] = useState(null);
  const [phoneFormate, setPhoneFormate] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [timeZone, setTimeZone] = useState(timezone);
  const [notify, setNotify] = useState(shouldNotify);
  const [bussinessStatus, setBussinessStatus] = useState(businessActive || null);
  const [operatingHours, setOperatingHours] = useState([]);
  const [enableOperationHours, setEnableOperationHours] = useState(enableHoursOfOperation);
  const [kdsTime, setKdsTime] = useState(kdsFilterTimeInMinutes || null);
  const [prefix, setPrefix] = useState(allSequence && allSequence[0]?.prefix);
  const [currentSequence, setCurrentSequence] = useState(allSequence && allSequence[0]?.currentSequence);
  const [dateFormat, setDateFormat] = useState(allSequence && allSequence[0]?.dateFormat);
  const [configureSequence, setConfigureSequence] = useState(false);
  const [configurePOSSequence, setConfigurePOSSequence] = useState(false);
  const [currentPOSSequence, setCurrentPOSSequence] = useState(allPOSSequence && allPOSSequence[0]?.currentSequence);
  const [maxCount, setMaxCount] = useState(allPOSSequence && allPOSSequence[0]?.maxCount);
  const [barCodeType, setBarCodeType] = useState(selectedAccount?.barCodeType && selectedAccount?.barCodeType);
  const [closeOutTime, setCloseOutTime] = useState(allPOSSequence && allPOSSequence[0]?.closeOutTime);
  const [selectedTime, setSelectedTime] = useState(null);
  // eslint-disable-next-line
  const [disableCloseOutTime, setDisableCloseOutTime] = useState(!(((allPOSSequence && allPOSSequence[0]?.closeOutTime) === null) || ((allPOSSequence && allPOSSequence[0]?.closeOutTime) === 0)));

  useEffect(() => {
    const { hoursOfOperation } = selectedAccount;
    if (hoursOfOperation) {
      setOperatingHours([...hoursOfOperation]);
    } else if (!hoursOfOperation) {
      const list = [
        {
          dayOfWeek: 'MONDAY',
          openingTime: '',
          closingTime: '',
          open: false,
        },
        {
          dayOfWeek: 'TUESDAY',
          openingTime: '',
          closingTime: '',
          open: false,
        },
        {
          dayOfWeek: 'WEDNESDAY',
          openingTime: '',
          closingTime: '',
          open: false,
        },
        {
          dayOfWeek: 'THURSDAY',
          openingTime: '',
          closingTime: '',
          open: false,
        },
        {
          dayOfWeek: 'FRIDAY',
          openingTime: '',
          closingTime: '',
          open: false,
        },
        {
          dayOfWeek: 'SATURDAY',
          openingTime: '',
          closingTime: '',
          open: false,
        },
        {
          dayOfWeek: 'SUNDAY',
          openingTime: '',
          closingTime: '',
          open: false,
        },
      ];
      setOperatingHours([...list]);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (allPOSSequence && allPOSSequence[0]?.closeOutTime) {
      const validMinutes = Math.min(allPOSSequence && allPOSSequence[0]?.closeOutTime, 1439); // Ensure max is 23:59
      const time = moment().startOf('day').add(validMinutes, 'minutes');
      setCloseOutTime(allPOSSequence && allPOSSequence[0]?.closeOutTime);
      setSelectedTime(time);
    }
  }, [allPOSSequence]);

  const handleChange = (time) => {
    if (time) {
      // Ensure selected time is only within today’s range
      const selectedMinutes = time.hours() * 60 + time.minutes();
      // Prevent exceeding 1440 (24:00)
      const validMinutes = Math.min(selectedMinutes, 1439);
      setCloseOutTime(validMinutes);
      setSelectedTime(moment().startOf('day').add(validMinutes, 'minutes'));
    }
  };

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      validname: {
        message: 'Please enter a valid Account Name',
        // eslint-disable-next-line no-useless-escape
        rule: val => (new RegExp(/^[^<>'\"\\\\`*]{4,}$/g).exec(val) !== null),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      orderSequenceValidation: {
        message: 'Please enter a number greater than zero.',
        rule: (val) => val >= 0,
      },
      orderSequenceValidation1: {
        message: 'Close out time required.',
        rule: (val) => val > 0,
      },
    },
  }));

  useEffect(() => {
    if (selectedAccount && selectedAccount.contactNumber) {
      const phone = selectedAccount.contactNumber;
      const phoneResult = phone ? findPhoneNumbersInText(phone) : null;
      if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
        setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
        setAccountPhone(phoneResult[0].number.nationalNumber);
        const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
        const formater = data.substr(data.indexOf(' ') + 1);
        setPhoneFormate(formater);
      }
    }
  }, [contactNumber]);

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${accountPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const uploadLogo = async (file, type) => {
    if (type === 'shopbanner') {
      const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPDATE_LOGO}/small`);
      await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.SHOP_LOGO}`, { fileUrl: entity });
      return fileUrl;
    }
    const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPDATE_LOGO}/${type}`);
    await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
    const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPDATE_LOGO}/${type}`, { fileUrl: entity });
    return fileUrl;
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setAccountPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const selectOperatingHours = (e, type, i) => {
    const list = [...operatingHours];
    const time = e.format('hh:mm a');
    const timeStamp = time.split(' ')[1];
    const actualTime = time.split(' ')[0];
    const a = actualTime.split(':');
    let minutes;
    if (a[0] === '12' && timeStamp === 'am') {
      minutes = +a[1];
    } else {
      minutes = (+a[0] + (timeStamp === 'pm' ? 12 : 0)) * 60 + (+a[1]);
    }
    if (type === 'From') {
      list[i].openingTime = minutes;
    } else if (type === 'To') {
      list[i].closingTime = minutes;
    }
    setOperatingHours([...list]);
  };

  const updateAccount = async () => {
    setAccountPhone(accountPhone.replace(/[^0-9+]/g, ''));
    const phoneNumber = accountPhone.replace(/[^0-9+]/g, '');
    const { userLimit, contactName } = props.selectedAccount;
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let largeLogoUrl; let smallLogoUrl; let shopBannerUrl;
    if (accountLargeLogoFile) {
      largeLogoUrl = await uploadLogo(accountLargeLogoFile, 'large');
    }
    if (accountSmallLogoFile) {
      smallLogoUrl = await uploadLogo(accountSmallLogoFile, 'small');
    }
    if (accountShopBannerFile) {
      shopBannerUrl = await uploadLogo(accountShopBannerFile, 'shopbanner');
    }
    props.updateAccount({
      active: true,
      contactEmail,
      contactGivenName: userGivenName,
      contactFamilyName: userFamilyName,
      contactNumber: `${phoneCode}${phoneNumber}`,
      largeLogo: largeLogoUrl,
      name: accountName,
      smallLogo: smallLogoUrl,
      banner: shopBannerUrl,
      timezone: timeZone,
      contactName,
      userLimit,
      shouldNotify: notify,
      businessActive: bussinessStatus,
      hoursOfOperation: operatingHours,
      enableHoursOfOperation: enableOperationHours,
      kdsFilterTimeInMinutes: kdsTime,
      configureSequence,
      prefix,
      currentSequence,
      dateFormat,
      configurePOSSequence,
      currentPOSSequence,
      maxCount,
      closeOutTime,
      disableCloseOutTime,
      barCodeType,
    });
  };

  const updateCrossBorderTransfer = (val) => {
    const params = {
      allowCrossBorderTransfer: val,
    };
    updateCrossBorder(params);
  };

  const updateStatus = (val, i) => {
    const list = [...operatingHours];
    list[i].open = val;
    setOperatingHours([...list]);
  };

  const getTimeVal = (index, type) => {
    if (type === 'From') {
      const fromTime = operatingHours[index].openingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          const hr = 0;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    } if (type === 'To') {
      const fromTime = operatingHours[index].closingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          const hr = 0;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    }
    return null;
  };

  const handleConfigureSequence = (value) => {
    setConfigureSequence(value);
  };

  const handleConfigurePOSSequence = (value) => {
    setConfigurePOSSequence(value);
  };

  const handleConfigurePOSSequence1 = (value) => {
    setDisableCloseOutTime(value);
  };

  const handleReset = () => {
    setCurrentSequence(0);
  };

  const handleResetPOS = () => {
    setCurrentPOSSequence(0);
    setMaxCount(0);
    setCloseOutTime(0);
  };

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Account Name</span>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={name}
              onChange={e => setAccountName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Account Name', accountName, 'required|validname')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase mb-4" fontSize="20px">
            Primary Contact Information
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Given Name</span>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={userGivenName}
              onChange={e => setUserGivenName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Given Name', userGivenName, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Family Name</span>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={userFamilyName}
              onChange={e => setUserFamilyName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Family Name', userFamilyName, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Email</span>
          </Col>
          <Col span={6}>
            <Input
              defaultValue={contactEmail}
              disabled
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={3}>
            <span>Phone</span>
          </Col>
          <Col span={6}>
            <div className="d-flex">
              <Select
                value={phoneCode}
                style={{ width: '30%' }}
                onChange={onCountryCodeChange}
                showSearch
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <div style={{ width: '70%' }}>
                <Input
                  className="InputnumFormate"
                  placeholder="Phone Number"
                  value={phoneFormate}
                  onChange={onPhoneNumberChange}
                  onBlur={e => {
                    if (e.currentTarget.value) {
                      setAccountPhone(e.currentTarget.value);
                      const value = formatNumber(`${phoneCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                      const formater = value.substr(value.indexOf(' ') + 1);
                      setPhoneFormate(formater);
                    }
                  }}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('phone number', accountPhone, `required|phoneNumValidation:${phoneCode}${accountPhone}`)}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Branding Images
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" gutter={[32, 16]}>
          <Col>
            <div>
              <div style={{ fontSize: '12px' }}>Company Logo</div>
              <label>
                <StyledDiv>
                  <ImageUpload
                    name="Company Logo"
                    accept="image/*"
                    endPoint={`${API_END_POINTS.UPDATE_LOGO}/large`}
                    url={accountLargeLogo}
                    setUrl={value => setAccountLargeLogo(value)}
                    setFiles={value => setAccountLargeLogoFile(value)}
                  />
                </StyledDiv>
              </label>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ fontSize: '12px' }}>Company Icon</div>
              <label>
                <StyledDiv>
                  <ImageUpload
                    name="Company Icon"
                    accept="image/*"
                    endPoint={`${API_END_POINTS.UPDATE_LOGO}/small`}
                    url={accountSmallLogo}
                    setUrl={value => setAccountSmallLogo(value)}
                    setFiles={value => setAccountSmallLogoFile(value)}
                  />
                </StyledDiv>
              </label>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ fontSize: '12px' }}>Company Banner</div>
              <label>
                <StyledDiv>
                  <ImageUpload
                    name="Company Icon"
                    accept="image/*"
                    endPoint={`${API_END_POINTS.UPDATE_LOGO}/small`}
                    url={accountShopBanner}
                    shopBanner="validate"
                    setUrl={value => setAccountShopBanner(value)}
                    setFiles={value => setAccountShopBannerFile(value)}
                  />
                </StyledDiv>
              </label>
            </div>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px',
            }}
          >
            <div>Banner dimensions between<br /> 1024x550 and 1920x1080.</div>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Timezone
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row>
          <Col span={3}>
            <span>Timezone</span>
          </Col>
          <Col span={6}>
            <Select
              value={timeZone}
              placeholder="Please select"
              className="w-100"
              onChange={value => setTimeZone(value)}
              showSearch
            >
              {
                TIME_ZONES.map(s => (
                  <Option
                    key={s}
                    value={s}
                  >
                    {s.replace(/_/g, ' ')}
                  </Option>
                ))
              }
            </Select>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Customer Emails
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row>
          <Col span={3}>
            <span>Email Customers</span>
          </Col>
          <Col span={6}>
            <Switch
              className="mx-3"
              defaultChecked={notify}
              onChange={val => setNotify(val)}
            /> Successful Payments
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            BUSINESS STATUS
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row>
          <Col span={6}>
            <Switch
              className="mx-3"
              defaultChecked={bussinessStatus}
              onChange={val => setBussinessStatus(val)}
            /> {bussinessStatus ? 'Active' : 'Disabled'}
          </Col>
        </Row>
      </SpForm>
      {
        role && role.name === ROLES.SUPERUSER && (
          <>
            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col>
                <SpText className="text-uppercase" fontSize="20px">
                  ALLOW CROSS BORDER TRANSFER
                </SpText>
              </Col>
            </Row>
            <SpForm>
              <Row>
                <Col span={6}>
                  <Switch
                    className="mx-3"
                    defaultChecked={allowCrossBorderTransfer}
                    onChange={val => updateCrossBorderTransfer(val)}
                  /> {allowCrossBorderTransfer ? 'Active' : 'Disabled'}
                </Col>
              </Row>
            </SpForm>
          </>
        )
      }
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            ENABLE HOURS OF OPERATION
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row>
          <Col span={6}>
            <Switch
              className="mx-3"
              defaultChecked={enableOperationHours}
              onChange={val => setEnableOperationHours(val)}
            /> {enableOperationHours ? 'Active' : 'Disabled'}
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            KDS FILTER TIME
          </SpText>
        </Col>
      </Row>
      <Row>
        <Col span={3}>
          <Input
            className="mx-2"
            type="number"
            placeholder="Minutes"
            maxLength={4}
            defaultValue={kdsTime}
            onChange={e => setKdsTime(e.currentTarget.value)}
          />
        </Col>
      </Row>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            OPERATING HOURS
          </SpText>
        </Col>
      </Row>
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col span={3}>
          <span className="text-muted">Days</span>
        </Col>
        <Col span={3}>
          <span className="text-muted">From</span>
        </Col>
        <Col span={3}>
          <span className="text-muted">To</span>
        </Col>
      </Row>
      {
        operatingHours[0] && (
          <>
            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col span={3}>
                <span>Monday</span>
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(0, 'From')}
                  onChange={(e) => selectOperatingHours(e, 'From', 0)}
                />
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(0, 'To')}
                  onChange={(e) => selectOperatingHours(e, 'To', 0)}
                />
              </Col>
              <Col span={3}>
                <Switch
                  className="mx-3"
                  defaultChecked={operatingHours[0].open}
                  onChange={val => updateStatus(val, 0)}
                /> Open
              </Col>
            </Row>

            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col span={3}>
                <span>Tuesday</span>
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(1, 'From')}
                  onChange={(e) => selectOperatingHours(e, 'From', 1)}
                />
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(1, 'To')}
                  onChange={(e) => selectOperatingHours(e, 'To', 1)}
                />
              </Col>
              <Col span={3}>
                <Switch
                  className="mx-3"
                  defaultChecked={operatingHours[1].open}
                  onChange={val => updateStatus(val, 1)}
                /> Open
              </Col>
            </Row>
            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col span={3}>
                <span>Wednesday</span>
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(2, 'From')}
                  onChange={(e) => selectOperatingHours(e, 'From', 2)}
                />
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(2, 'To')}
                  onChange={(e) => selectOperatingHours(e, 'To', 2)}
                />
              </Col>
              <Col span={3}>
                <Switch
                  className="mx-3"
                  defaultChecked={operatingHours[2].open}
                  onChange={val => updateStatus(val, 2)}
                /> Open
              </Col>
            </Row>
            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col span={3}>
                <span>Thursday</span>
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(3, 'From')}
                  onChange={(e) => selectOperatingHours(e, 'From', 3)}
                />
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(3, 'To')}
                  onChange={(e) => selectOperatingHours(e, 'To', 3)}
                />
              </Col>
              <Col span={3}>
                <Switch
                  className="mx-3"
                  defaultChecked={operatingHours[3].open}
                  onChange={val => updateStatus(val, 3)}
                /> Open
              </Col>
            </Row>
            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col span={3}>
                <span>Friday</span>
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(4, 'From')}
                  onChange={(e) => selectOperatingHours(e, 'From', 4)}
                />
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(4, 'To')}
                  onChange={(e) => selectOperatingHours(e, 'To', 4)}
                />
              </Col>
              <Col span={3}>
                <Switch
                  className="mx-3"
                  defaultChecked={operatingHours[4].open}
                  onChange={val => updateStatus(val, 4)}
                /> Open
              </Col>
            </Row>
            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col span={3}>
                <span>Saturday</span>
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(5, 'From')}
                  onChange={(e) => selectOperatingHours(e, 'From', 5)}
                />
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(5, 'To')}
                  onChange={(e) => selectOperatingHours(e, 'To', 5)}
                />
              </Col>
              <Col span={3}>
                <Switch
                  className="mx-3"
                  defaultChecked={operatingHours[5].open}
                  onChange={val => updateStatus(val, 5)}
                /> Open
              </Col>
            </Row>
            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col span={3}>
                <span>Sunday</span>
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(6, 'From')}
                  onChange={(e) => selectOperatingHours(e, 'From', 6)}
                />
              </Col>
              <Col span={3}>
                <TimePicker
                  allowClear={false}
                  use12Hours
                  format="h:mm a"
                  defaultValue={getTimeVal(6, 'To')}
                  onChange={(e) => selectOperatingHours(e, 'To', 6)}
                />
              </Col>
              <Col span={3}>
                <Switch
                  className="mx-3"
                  defaultChecked={operatingHours[6].open}
                  onChange={val => updateStatus(val, 6)}
                /> Open
              </Col>
            </Row>
          </>
        )
      }
      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Generate Order No
          </SpText>
        </Col>
      </Row>
      <Switch
        className="mx-3"
        defaultChecked={configureSequence}
        onChange={handleConfigureSequence}
      />
      Enable or disable the configuration for generating order numbers

      <div className="mt-2 d-flex">

        <div>
          <label className="ml-1" style={{ fontWeight: 600 }}>Prefix</label>
          <Input
            className="me-2"
            // maxLength={4}
            placeholder="A2BPL"
            value={prefix}
            disabled={!configureSequence}
            onChange={e => setPrefix(e.currentTarget.value)}
          />
          <span className="text-danger ">
            {configureSequence && simpleValidator.current.message('prefix', prefix, 'required')}
          </span>
        </div>
        <div>
          <label className="ml-2 ms-2" style={{ fontWeight: 600 }}>Date Format</label>
          <br />
          <Select
            value={dateFormat}
            placeholder="MM/DD/YYYY"
            disabled={!configureSequence}
            style={{ marginLeft: '10px', width: 180, textTransform: 'uppercase' }}
            onChange={(value) => setDateFormat(value)}
            showSearch
          >
            {DATE_FORMATS.map(s => (
              <Option style={{ textTransform: 'uppercase' }} key={s} value={s}>{s}</Option>
            ))}
          </Select>
          <span className="text-danger ml-2 ">
            {configureSequence && simpleValidator.current.message('dateFormat', dateFormat, 'required')}
          </span>
        </div>
        <div>
          <label className="ml-4" style={{ fontWeight: 600 }}>Order sequence</label>
          <br />
          <Input
            style={{ width: 180 }}
            type="number"
            placeholder="1000"
            disabled={!configureSequence}
            // maxLength={4}
            min={0}
            value={currentSequence}
            onChange={e => setCurrentSequence(e.currentTarget.value)}
          />

          <span className="text-danger ">
            {configureSequence && simpleValidator.current.message('currentSequence', currentSequence, 'required|orderSequenceValidation')}
          </span>

        </div>
        <SpButton
          type="primary"
          shape="round"
          className="ml-5"
          style={{ marginTop: '27px' }}
          disabled={!configureSequence}
          onClick={handleReset}
        >
          Reset
        </SpButton>
      </div>


      <p className="mt-2">e.g., A2BPL-07/02/2024-1000</p>

      <Row className="my-2 mb-3" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Generate POS Order No
          </SpText>
        </Col>
      </Row>
      <Switch
        className="mx-3"
        defaultChecked={configurePOSSequence}
        onChange={handleConfigurePOSSequence}
      />
      Enable or disable the configuration for POS generating order numbers

      <div className="mt-2 d-flex">
        <div className="mr-2">
          <label className="ms-2" style={{ fontWeight: 600 }}>Current Sequence</label>
          <br />
          <Input
            style={{ width: 180 }}
            type="number"
            placeholder="10"
            disabled={!configurePOSSequence}
            // maxLength={4}
            min={0}
            value={currentPOSSequence}
            onChange={e => setCurrentPOSSequence(e.currentTarget.value)}
          />

          <span className="text-danger ">
            {configurePOSSequence && simpleValidator.current.message('current sequence', currentPOSSequence, 'required|orderSequenceValidation')}
          </span>
        </div>
        <div className="mr-2">
          <label style={{ fontWeight: 600 }}>Max Count</label>
          <br />
          <Input
            style={{ width: 180 }}
            type="number"
            placeholder="1000"
            disabled={!configurePOSSequence}
            // maxLength={4}
            min={0}
            value={maxCount}
            onChange={e => setMaxCount(e.currentTarget.value)}
          />

          <span className="text-danger ">
            {configurePOSSequence && simpleValidator.current.message('max count', maxCount, 'orderSequenceValidation')}
          </span>
        </div>
        <div className="mr-2">
          <label style={{ fontWeight: 600 }}>Close Out Time</label>
          <br />
          <TimePicker
            value={selectedTime}
            onChange={handleChange}
            format="HH:mm"
            minuteStep={1}
            disabled={(!configurePOSSequence) || (disableCloseOutTime === false)}
          />
          {/* <Input
            style={{ width: 180 }}
            type="number"
            placeholder="1000"
            disabled={!configurePOSSequence}
            // maxLength={4}
            min={0}
            value={closeOutTime}
            onChange={e => setCloseOutTime(e.currentTarget.value)}
          /> */}
          {
            disableCloseOutTime === true ? (
              <span className="text-danger ">
                {configurePOSSequence && simpleValidator.current.message('close out time', closeOutTime, 'required|orderSequenceValidation1')}
              </span>
            ) : (
              <span className="text-danger ">
                {configurePOSSequence && simpleValidator.current.message('close out time', closeOutTime, 'orderSequenceValidation')}
              </span>
            )
          }
        </div>
        <div>
          <br />
          <Switch
            className="mx-3 mt-3"
            disabled={(!configurePOSSequence)}
            defaultChecked={disableCloseOutTime}
            onChange={handleConfigurePOSSequence1}
          />
        </div>
        <SpButton
          type="primary"
          shape="round"
          className="ml-2"
          style={{ marginTop: '27px' }}
          disabled={!configurePOSSequence}
          onClick={handleResetPOS}
        >
          Reset
        </SpButton>
      </div>

      <p className="mt-2">e.g., 1-100-11</p>

      <Row className="my-2 mb-2" type="flex" justify="start">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            BAR CODE
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle" className="border p-3" style={{ borderRadius: '4px' }}>
          <Col span={3}>
            <span>Bar Code Type</span>
          </Col>
          <Col span={4}>
            <Select
              value={barCodeType}
              className="w-100"
              onChange={value => {
                setBarCodeType(value);
              }}
              showSearch
            >
              <Option key={1} value="BARCODE_TYPE_1">BARCODE TYPE 1</Option>
              <Option key={2} value="BARCODE_TYPE_2">BARCODE TYPE 2</Option>
              <Option key={3} value="BARCODE_TYPE_3">BARCODE TYPE 3</Option>
              <Option key={4} value="BARCODE_TYPE_4">BARCODE TYPE 4</Option>
            </Select>
          </Col>
          <Col span={2} className="ml-4">
            <span>Preview</span>
          </Col>
          <Col span={6} className="d-flex flex-column align-items-center col-6 border rounded p-2 bg-white">
            <img src={BarCodeImg} alt="barcode" className="img-fluid" />
            <div className="d-flex justify-content-center" style={{ letterSpacing: '9px', fontWeight: 'bold', fontFamily: 'monospace' }}>
              <SpH5>{getBarCodeValue(barCodeType)}</SpH5>
            </div>
          </Col>
          <Col span={5} className="ml-4" style={{ fontSize: '12px' }}>
            <span>
              P - Price (Before decimal point),
              <br key={1} /> p - Price (After decimal point),
              <br key={2} /> C - Check digit (Ignored),
              <br key={3} /> I - Item code (Product code)
            </span>
          </Col>
        </Row>
      </SpForm>

      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="secondary"
            shape="round"
            onClick={() => props.setEdit(false)}
          >
            Cancel
          </SpButton>
        </Col>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={updateAccount}
          >
            Save
          </SpButton>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Edit);
