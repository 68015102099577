// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Switch,
  Checkbox,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5,
  SpError,
  SpButton,
  SpText,
  SpTextMute,
} from 'components/DesignKit';
import {
  WEBHOOK_EVENTS,
} from 'appconstants';
import HyperLinkIcon from 'components/NavIcons/hyperlinkIcon';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  webhookEvents: Object,
};

const AddWebhook = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    webhookEvents,
  } = props;

  const [, forceUpdate] = useState();
  const [url, setUrl] = useState('');
  const [email, setEmail] = useState('');
  const [events, setEvents] = useState([]);
  const [testMode, setTestMode] = useState(true);
  const [testModeText, setTestModeText] = useState('Test');
  const [webhookEnabled, setWebhookEnabled] = useState(true);
  const [paymentsList, setPaymentsList] = useState([]);
  const [payoutsList, setPayoutsList] = useState([]);
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [invoicesList, setInvoicesList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));
  const [checkPayments, setCheckPayments] = useState(false);
  const [paymentIndeterminate, setPaymentIndeterminate] = useState(false);
  const [checkPayouts, setCheckPayouts] = useState(false);
  const [payoutsIndeterminate, setpayoutsIndeterminate] = useState(false);
  const [checkSubscriptions, setCheckSubscriptions] = useState(false);
  const [subscriptionsIndeterminate, setSubscriptionsIndeterminate] = useState(false);
  const [checkInvoice, setCheckInvoice] = useState(false);
  const [checkInventory, setCheckInventory] = useState(false);
  const [invoiceIndeterminate, setInvoiceIndeterminate] = useState(false);
  const [inventoryIndeterminate, setInventoryIndeterminate] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [contactIndeterminate, setContactIndeterminate] = useState(false);
  const [checkContact, setCheckContact] = useState(false);
  const [fundingSourceList, setFundingSourceList] = useState([]);
  const [fundingSourceIndeterminate, setFundingSourceIndeterminate] = useState(false);
  const [checkFundingSource, setCheckFundingSource] = useState(false);
  const [transferList, setTransferList] = useState([]);
  const [transferIndeterminate, setTransferIndeterminate] = useState(false);
  const [checkTransfer, setCheckTransfer] = useState(false);

  const [secret, setSecret] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    for (let i = 0; i < WEBHOOK_EVENTS.PAYMENT.length; i += 1) {
      WEBHOOK_EVENTS.PAYMENT[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.PAYOUT.length; i += 1) {
      WEBHOOK_EVENTS.PAYOUT[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.SUBSCRIPTION.length; i += 1) {
      WEBHOOK_EVENTS.SUBSCRIPTION[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.INVOICE.length; i += 1) {
      WEBHOOK_EVENTS.INVOICE[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.INVENTORY.length; i += 1) {
      WEBHOOK_EVENTS.INVENTORY[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.CONTACT.length; i += 1) {
      WEBHOOK_EVENTS.CONTACT[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.FUNDING_SOURCE.length; i += 1) {
      WEBHOOK_EVENTS.FUNDING_SOURCE[i].checked = false;
    }
    for (let i = 0; i < WEBHOOK_EVENTS.TRANSFER.length; i += 1) {
      WEBHOOK_EVENTS.TRANSFER[i].checked = false;
    }
    setPaymentsList(WEBHOOK_EVENTS.PAYMENT);
    setPayoutsList(WEBHOOK_EVENTS.PAYOUT);
    setSubscriptionsList(WEBHOOK_EVENTS.SUBSCRIPTION);
    setInvoicesList(WEBHOOK_EVENTS.INVOICE);
    setInventoryList(WEBHOOK_EVENTS.INVENTORY);
    setContactList(WEBHOOK_EVENTS.CONTACT);
    setFundingSourceList(WEBHOOK_EVENTS.FUNDING_SOURCE);
    setTransferList(WEBHOOK_EVENTS.TRANSFER);
  }, []);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const eventChanges = (e, name) => {
    if (name === 'PAYMENT' && e.target.checked) {
      setCheckPayments(e.target.checked);
      setPaymentIndeterminate(false);
      const listItems = [...events];
      const list = [...paymentsList];
      const paymentList = webhookEvents.PAYMENT;
      const allListEvents = listItems.concat(paymentList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setPaymentsList(list);
      setEvents(allListEvents);
    } else if (name === 'PAYMENT' && !e.target.checked) {
      setCheckPayments(e.target.checked);
      setPaymentIndeterminate(false);
      const listItems = [...events];
      const list = [...paymentsList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setPaymentsList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'PAYOUT' && e.target.checked) {
      setCheckPayouts(e.target.checked);
      setpayoutsIndeterminate(false);
      const listItems = [...events];
      const list = [...payoutsList];
      const payoutList = webhookEvents.PAYOUT;
      const allListEvents = listItems.concat(payoutList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setPayoutsList(list);
      setEvents(allListEvents);
    } else if (name === 'PAYOUT' && !e.target.checked) {
      setCheckPayouts(e.target.checked);
      setCheckPayouts(false);
      const listItems = [...events];
      const list = [...payoutsList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setPayoutsList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'SUBSCRIPTION' && e.target.checked) {
      setCheckSubscriptions(e.target.checked);
      setSubscriptionsIndeterminate(false);
      const listItems = [...events];
      const list = [...subscriptionsList];
      const subscriptionList = webhookEvents.SUBSCRIPTION;
      const allListEvents = listItems.concat(subscriptionList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setSubscriptionsList(list);
      setEvents(allListEvents);
    } else if (name === 'SUBSCRIPTION' && !e.target.checked) {
      setCheckSubscriptions(e.target.checked);
      setSubscriptionsIndeterminate(false);
      const listItems = [...events];
      const list = [...subscriptionsList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setSubscriptionsList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'INVOICE' && e.target.checked) {
      setCheckInvoice(e.target.checked);
      setInvoiceIndeterminate(false);
      const listItems = [...events];
      const list = [...invoicesList];
      const invoiceList = webhookEvents.INVOICE;
      const allListEvents = listItems.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setInvoicesList(list);
      setEvents(allListEvents);
    } else if (name === 'INVOICE' && !e.target.checked) {
      setCheckInvoice(e.target.checked);
      setInvoiceIndeterminate(false);
      const listItems = [...events];
      const list = [...invoicesList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setInvoicesList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'INVENTORY' && e.target.checked) {
      setCheckInventory(e.target.checked);
      setInventoryIndeterminate(false);
      const listItems = [...events];
      const list = [...inventoryList];
      const invoiceList = webhookEvents.INVENTORY;
      const allListEvents = listItems.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setInventoryList(list);
      setEvents(allListEvents);
    } else if (name === 'INVENTORY' && !e.target.checked) {
      setCheckInventory(e.target.checked);
      setInventoryIndeterminate(false);
      const listItems = [...events];
      const list = [...inventoryList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setInventoryList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'CONTACT' && e.target.checked) {
      setCheckContact(e.target.checked);
      setContactIndeterminate(false);
      const listItems = [...events];
      const list = [...contactList];
      const invoiceList = webhookEvents.CONTACT;
      const allListEvents = listItems.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setContactList(list);
      setEvents(allListEvents);
    } else if (name === 'CONTACT' && !e.target.checked) {
      setCheckContact(e.target.checked);
      setContactIndeterminate(false);
      const listItems = [...events];
      const list = [...contactList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setContactList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'FUNDING_SOURCE' && e.target.checked) {
      setCheckFundingSource(e.target.checked);
      setFundingSourceIndeterminate(false);
      const listItems = [...events];
      const list = [...fundingSourceList];
      const invoiceList = webhookEvents.FUNDING_SOURCE;
      const allListEvents = listItems.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setFundingSourceList(list);
      setEvents(allListEvents);
    } else if (name === 'FUNDING_SOURCE' && !e.target.checked) {
      setCheckFundingSource(e.target.checked);
      setFundingSourceIndeterminate(false);
      const listItems = [...events];
      const list = [...fundingSourceList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setFundingSourceList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    } else if (name === 'TRANSFER' && e.target.checked) {
      setCheckTransfer(e.target.checked);
      setTransferIndeterminate(false);
      const listItems = [...events];
      const list = [...transferList];
      const invoiceList = webhookEvents.TRANSFER;
      const allListEvents = listItems.concat(invoiceList);
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = true;
      }
      setTransferList(list);
      setEvents(allListEvents);
    } else if (name === 'TRANSFER' && !e.target.checked) {
      setCheckTransfer(e.target.checked);
      setTransferIndeterminate(false);
      const listItems = [...events];
      const list = [...transferList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].checked = false;
      }
      setTransferList(list);
      setEvents(listItems.filter((item) => item.category !== name));
    }
  };

  const onCheck = (checked, category, code, index) => {
    const eventList = [...events];
    if (checked) {
      if (category === 'PAYMENT') {
        const listItems = [...paymentsList];
        const lineItem = webhookEvents.PAYMENT.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setPaymentIndeterminate(!!list.length && list.length < listItems.length);
        setCheckPayments(list.length === listItems.length);
        setPaymentsList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'PAYOUT') {
        const listItems = [...payoutsList];
        const lineItem = webhookEvents.PAYOUT.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setpayoutsIndeterminate(!!list.length && list.length < listItems.length);
        setCheckPayouts(list.length === listItems.length);
        setPayoutsList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'SUBSCRIPTION') {
        const listItems = [...subscriptionsList];
        const lineItem = webhookEvents.SUBSCRIPTION.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setSubscriptionsIndeterminate(!!list.length && list.length < listItems.length);
        setCheckSubscriptions(list.length === listItems.length);
        setSubscriptionsList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'INVOICE') {
        const listItems = [...invoicesList];
        const lineItem = webhookEvents.INVOICE.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setInvoiceIndeterminate(!!list.length && list.length < listItems.length);
        setCheckInvoice(list.length === invoicesList.length);
        setInvoicesList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'INVENTORY') {
        const listItems = [...inventoryList];
        const lineItem = webhookEvents.INVENTORY.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setInventoryIndeterminate(!!list.length && list.length < listItems.length);
        setCheckInventory(list.length === inventoryList.length);
        setInventoryList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'CONTACT') {
        const listItems = [...contactList];
        const lineItem = webhookEvents.CONTACT.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setContactIndeterminate(!!list.length && list.length < listItems.length);
        setCheckContact(list.length === inventoryList.length);
        setContactList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'FUNDING_SOURCE') {
        const listItems = [...fundingSourceList];
        const lineItem = webhookEvents.FUNDING_SOURCE.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setFundingSourceIndeterminate(!!list.length && list.length < listItems.length);
        setCheckFundingSource(list.length === inventoryList.length);
        setFundingSourceList(listItems);
        setEvents(eventList.concat(lineItem));
      } else if (category === 'TRANSFER') {
        const listItems = [...transferList];
        const lineItem = webhookEvents.TRANSFER.filter((item) => item.code === code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = true;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setTransferIndeterminate(!!list.length && list.length < listItems.length);
        setCheckTransfer(list.length === inventoryList.length);
        setTransferList(listItems);
        setEvents(eventList.concat(lineItem));
      }
    } else if (!checked) {
      if (category === 'PAYMENT') {
        const listItems = [...paymentsList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setPaymentIndeterminate(!!list.length && list.length < listItems.length);
        setCheckPayments(list.length === listItems.length);
        setPaymentsList(listItems);
        setEvents(lineItem);
      } else if (category === 'PAYOUT') {
        const listItems = [...payoutsList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setpayoutsIndeterminate(!!list.length && list.length < listItems.length);
        setCheckPayouts(list.length === listItems.length);
        setPayoutsList(listItems);
        setEvents(lineItem);
      } else if (category === 'SUBSCRIPTION') {
        const listItems = [...subscriptionsList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setSubscriptionsIndeterminate(!!list.length && list.length < listItems.length);
        setCheckSubscriptions(list.length === listItems.length);
        setSubscriptionsList(listItems);
        setEvents(lineItem);
      } else if (category === 'INVOICE') {
        const listItems = [...invoicesList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setInvoiceIndeterminate(!!list.length && list.length < listItems.length);
        setCheckInvoice(list.length === listItems.length);
        setInvoicesList(listItems);
        setEvents(lineItem);
      } else if (category === 'INVENTORY') {
        const listItems = [...inventoryList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setInventoryIndeterminate(!!list.length && list.length < listItems.length);
        setCheckInventory(list.length === listItems.length);
        setInventoryList(listItems);
        setEvents(lineItem);
      } else if (category === 'CONTACT') {
        const listItems = [...contactList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setContactIndeterminate(!!list.length && list.length < listItems.length);
        setCheckContact(list.length === listItems.length);
        setContactList(listItems);
        setEvents(lineItem);
      } else if (category === 'FUNDING_SOURCE') {
        const listItems = [...fundingSourceList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setFundingSourceIndeterminate(!!list.length && list.length < listItems.length);
        setCheckFundingSource(list.length === listItems.length);
        setFundingSourceList(listItems);
        setEvents(lineItem);
      } else if (category === 'TRANSFER') {
        const listItems = [...transferList];
        const lineItem = eventList.filter((item) => item.code !== code);
        for (let i = 0; i < listItems.length; i += 1) {
          if (listItems[i].id === index) {
            listItems[i].checked = false;
          }
        }
        const list = listItems.filter((item) => item.checked === true);
        setTransferIndeterminate(!!list.length && list.length < listItems.length);
        setCheckTransfer(list.length === listItems.length);
        setTransferList(listItems);
        setEvents(lineItem);
      }
    }
  };

  const validate = (event) => {
    setDisableButton(true);
    let status;
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    if (webhookEnabled) {
      status = 'ACTIVE';
    } else if (!webhookEnabled) {
      status = 'DEACTIVATED';
    }
    const eventList = [...events];
    for (let i = 0; i < eventList.length; i += 1) {
      eventList[i] = eventList[i].gid;
    }
    submit({
      url,
      email,
      eventList,
      testMode,
      status,
      secret,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Webhook"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Webhook URL</SpH5></label>
          <Input
            placeholder="HTTPS URL is recommended"
            value={url}
            onChange={(e) => setUrl(e.currentTarget.value.trim())}
          />
          <SpError>
            {simpleValidator.current.message('webhook url', url, 'required|url')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Alert Email</SpH5></label>
          <Input
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value.trim())}
          />
          <SpError>
            {simpleValidator.current.message('Alert email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Secret</SpH5></label>
          <Input.Password
            value={secret}
            autoComplete="new-password"
            placeholder="Enter secret"
            onChange={(e) => setSecret(e.currentTarget.value)}
          />
          <div style={{ textAlign: 'end' }}>
            <SpTextMute className="mr-2">Recommended to add secrets for security</SpTextMute>
            <HyperLinkIcon />
          </div>
          <SpError>
            {simpleValidator.current.message('secret', secret, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Active Events</SpH5></label>
          <div
            style={{
              background: '#fff',
              padding: '5px',
              overflow: 'auto',
              height: '200px',
            }}
          >
            <div>
              <Checkbox
                className="border-light mr-2"
                indeterminate={paymentIndeterminate}
                onChange={(e) => eventChanges(e, 'PAYMENT')}
                checked={checkPayments}
              />
              <SpText fontSize="14px">Payments</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {paymentsList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'PAYMENT', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={payoutsIndeterminate}
                onChange={(e) => eventChanges(e, 'PAYOUT')}
                checked={checkPayouts}
              />
              <SpText fontSize="14px">Payout</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {payoutsList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'PAYOUT', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={subscriptionsIndeterminate}
                onChange={(e) => eventChanges(e, 'SUBSCRIPTION')}
                checked={checkSubscriptions}
              />
              <SpText fontSize="14px">Subscription</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {subscriptionsList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'SUBSCRIPTION', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={invoiceIndeterminate}
                onChange={(e) => eventChanges(e, 'INVOICE')}
                checked={checkInvoice}
              />
              <SpText fontSize="14px">Invoice</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {invoicesList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'INVOICE', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={inventoryIndeterminate}
                onChange={(e) => eventChanges(e, 'INVENTORY')}
                checked={checkInventory}
              />
              <SpText fontSize="14px">Inventory</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {inventoryList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'INVENTORY', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            {/* --------------------------contact------------------------------------- */}
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={contactIndeterminate}
                onChange={(e) => eventChanges(e, 'CONTACT')}
                checked={checkContact}
              />
              <SpText fontSize="14px">Contact</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {contactList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'CONTACT', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>

            {/* --------------------------funding-source------------------------------------- */}
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={fundingSourceIndeterminate}
                onChange={(e) => eventChanges(e, 'FUNDING_SOURCE')}
                checked={checkFundingSource}
              />
              <SpText fontSize="14px">Funding Source</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {fundingSourceList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'FUNDING_SOURCE', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
            {/* --------------------------Transfer------------------------------------- */}
            <div className="mb-2">
              <Checkbox
                className="border-light mr-2"
                indeterminate={transferIndeterminate}
                onChange={(e) => eventChanges(e, 'TRANSFER')}
                checked={checkTransfer}
              />
              <SpText fontSize="14px">Transfer</SpText>
            </div>
            <div style={{ padding: '5px 15px' }}>
              {transferList.map((item) => (
                <div className="mb-2" key={item.id}>
                  <Checkbox
                    className="border-light mr-2"
                    checked={item.checked}
                    onChange={({ target: { checked } }) => {
                      onCheck(checked, 'TRANSFER', item.value, item.id);
                    }}
                  />
                  <SpText fontSize="14px">{item.display}</SpText>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Mode</SpH5></label>
          <div className="d-flex">
            <Switch
              checked={testMode}
              onChange={(checked) => {
                setTestMode(!testMode);
                if (checked) {
                  setTestModeText('Test');
                } else if (!checked) {
                  setTestModeText('Live');
                }
              }}
              className="border-light mr-3"
            />
            <SpText>Webhook for {testModeText} Mode</SpText>
          </div>
        </div>
        <div>
          <label htmlFor=""><SpH5>Enable</SpH5></label>
          <div className="d-flex">
            <Switch
              checked={webhookEnabled}
              onChange={() => setWebhookEnabled(!webhookEnabled)}
              className="border-light mr-3"
            />
            <SpText>Webhook is Enabled</SpText>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddWebhook);
