/* eslint-disable max-len */
// @flow
import React, { useRef, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import {
  Input,
  Select,
  Modal,
  Checkbox,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpError, SpButton,
} from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';
import {
  validators,
  validationRegex,
} from 'utils/validationMessages';
import {
  COUNTRY_CODES,
  OfflinePaymentTypeIn,
  OfflinePaymentTypeUs,
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';
import moment from 'moment-timezone';

const { Option } = Select;
const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  phoneCode: string,
  id: Number,
  paymentPagesDetails: Object,
  selectedAccount: Object,
};

const OfflineDonation = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    phoneCode,
    id,
    paymentPagesDetails,
    selectedAccount,
  } = props;

  const [, forceUpdate] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [phoneNumber, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [phoneFormate, setPhoneFormate] = useState();
  const [paymentType, setPaymentType] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [paymentPageMeta, setPaymentPageMeta] = useState([]);
  const prefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [OfflinePaymentType, setOfflinePaymentType] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [checkLabel, setCheckLabel] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [comboIndex, setComboIndex] = useState(null);
  const [email, setEmail] = useState('');
  const [email1, setEmail1] = useState(true);
  const [name1, setName1] = useState(true);
  const [phone1, setPhone1] = useState(true);
  const [address, setAddress] = useState(true);
  const qntAmtRegex = /^[0-9]+$/;
  const amountList = paymentPagesDetails?.donationPageAmounts;
  const [amountvalidator, setAmountValidator] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.new_email.test(val),
      },
      name: {
        message: 'Name should be minimum 4 characters',
        rule: (val) => val.length >= 4,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minQuantity: {
        message: 'Please enter valid Quantity',
        rule: (val, params) => (
          params[1] ? val && (Number(params[0] && params[0]) <= Number(val && val)) && (Number(params[1] && params[1]) >= Number(val && val))
            : val && (Number(params[0] && params[0]) <= Number(val && val))),
      },
      maxQuantity: {
        message: 'Please enter valid Amount',
        rule: (val, params) => (
          params[1] ? val && (Number(params[0] && params[0]) <= Number(val && val)) && (Number(params[1] && params[1]) >= Number(val && val))
            : val && (Number(params[0] && params[0]) <= Number(val && val))),
      },
      largeText: {
        message: 'Maximum 255 Characters Only',
        rule: (val) => val.length <= 255,
      },
      onlyInteger: {
        message: 'Decimal format is not accepted',
        rule: val => (qntAmtRegex.test(val)),
      },
    },
  }));

  useEffect(() => {
    setPaymentPageMeta([]);
  }, [paymentPagesDetails]);

  useEffect(() => {
    if (isIndia) {
      setOfflinePaymentType(OfflinePaymentTypeIn);
      setCheckLabel('Cheque');
    } else if (!isIndia) {
      setOfflinePaymentType(OfflinePaymentTypeUs);
      setCheckLabel('Check');
    }
  }, [selectedAccount]);


  useEffect(() => {
    if (phoneCode === '+1') {
      setPhoneFormate('(###) ###-####');
    } else if (phoneCode === '+91') {
      setPhoneFormate('##### #####');
    } else {
      setPhoneFormate('##########');
    }
  }, []);

  useEffect(() => {
    if (id === 1) {
      setPhoneFormate('(###) ###-####');
    } else if (id === 2) {
      setPhoneFormate('##### #####');
    } else {
      setPhoneFormate('##########');
    }
  }, []);

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone);
  };

  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setCountryCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setCountryCode(value);
    } else {
      setPhoneFormate('##########');
      setCountryCode(value);
    }
  };

  const onPaymentTypeChange = (val) => {
    setPaymentType(val);
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    if (amountvalidator) {
      return;
    }
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const payload = {
      donationPageMetaGid: paymentPagesDetails?.gid,
      customerDetails: {
        name: issuedBy,
        email,
        phoneNumber: `${countryCode}${phoneNumber.replace(/[^0-9+]/g, '')}`,
      },
      amount: Number(paymentAmount.replace('.', '')),
      notes: description,
      shareEmail: email1,
      sharePhoneNumber: phone1,
      shareAddress: address,
      shareName: name1,
      donationPageEntryDetails: [],
      offlinePaymentType: paymentType,
      transactionDate: moment.utc(new Date()).tz(selectedAccount.timezone).format(MOMENT_FORMAT),
      offlinePaymentNote: description,
    };
    submit(payload);
  };

  const resetValues = () => {
    // const list = [...paymentPagesDetails.paymentPageMetaFields];
    // for (let i = 0; i < list.length; i += 1) {
    //   if (list[i].type === 'PRICE_AMOUNT_FIXED') {
    //     list[i].checked = false;
    //     list[i].comboBoxValue = null;
    //   }
    //   delete list[i].value;
    // }
    setPaymentPageMeta([]);
    close();
  };

  const updateSelectedAmount = (item) => {
    setAmountValidator(false);
    setPaymentAmount(formatAmount(item?.amount / 100, currencyType));
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={resetValues} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate}>Add Offline Donation</SpButton>,
      ]}
      title={paymentPagesDetails.title}
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Issued By</SpH5></label>
          <Input
            placeholder="Issued By"
            value={issuedBy}
            onChange={(e) => setIssuedBy(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('issued by', issuedBy, 'required|name')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Email', email, 'required|email')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone</SpH5></label>
          <div className="d-flex">
            <Select
              style={{ width: '25%' }}
              defaultValue={phoneCode || countryCode}
              onChange={onCountryCodeChange}
            >
              {COUNTRY_CODES.map((item) => (
                <Option key={item.ID} value={item.PHONE_CODE}>
                  {item.PHONE_CODE}
                </Option>
              ))}
            </Select>
            <div style={{ width: '75%' }}>
              <NumberFormat
                className="InputnumFormate"
                value={phoneNumber}
                format={phoneFormate}
                mask="_"
                allowEmptyFormatting
                onChange={onPhoneNumberChange}
              />
              <SpError>
                {simpleValidator.current.message('Phone', phoneNumber, 'required|phone')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Payment Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={paymentType || 'Select Payment Type'}
            onChange={onPaymentTypeChange}
          >
            {OfflinePaymentType.map((item) => (
              <Option key={item.key} value={item.value}>
                {item.key}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('Payment type', paymentType, 'required')}
          </SpError>
        </div>
        <div className="payment-page-form-elements py-2 pr-2">
          <div className="mt-3">
            <div className="mb-3">
              {
                amountList.map((item) => (
                  <span
                    className="mr-3 mb-3"
                    onClick={() => updateSelectedAmount(item)}
                    style={{
                      minWidth: '25%',
                      maxWidth: '50%',
                      backgroundColor: '#f2f2f6',
                      borderRadius: '10px',
                      padding: '5px 10px',
                      marginBottom: '5px',
                      border: '1px solid #e5e5e5',
                      cursor: 'pointer',
                    }}
                  >
                    {currencyType?.prefix} {formatAmount((item?.amount / 100), currencyType)}
                  </span>
                ))
              }
            </div>
          </div>

        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Custom Amount</SpH5></label>
          <Input
            value={paymentAmount}
            prefix={prefix}
            placeholder="0.00"
            disabled={!paymentPagesDetails?.showCustomAmount}
            onChange={(e) => {
              setAmountValidator(false);
              const regex = /^\d*\.?\d*$/;
              const { value } = e.currentTarget;
              if (regex.test(value) || value === '') {
                if (paymentPagesDetails?.showCustomAmount && (paymentPagesDetails?.maxAmount < (Number(value) * 100))) {
                  setAmountValidator(true);
                }
                if (paymentPagesDetails?.showCustomAmount && (paymentPagesDetails?.minAmount > (Number(value) * 100))) {
                  setAmountValidator(true);
                }
                setPaymentAmount(e.currentTarget.value);
              } else if (!regex.test(value)) {
                setPaymentAmount(0.00);
              }
            }}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
            onBlur={e => {
              if (e.currentTarget.value) {
                const value = formatAmount(e.currentTarget.value, currencyType);
                setPaymentAmount(value);
              }
            }}
          />
          <SpError>
            {simpleValidator.current.message('amount', paymentAmount, 'required')}
          </SpError>
          {
            amountvalidator && (
              <SpError>
                custom amount should be inbetween{prefix} {formatAmount((paymentPagesDetails?.maxAmount / 100), currencyType)} - {prefix} {formatAmount((paymentPagesDetails?.minAmount / 100), currencyType)}
              </SpError>
            )
          }
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Offline Payment Note</SpH5></label>
          <Input
            placeholder="Offline Payment Note"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Offline payment note', description, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Share your details with this charity</SpH5></label>
          <div className="payment-page-form-elements py-2 pr-2">
            <span className="mr-2">
              <Checkbox
                className="mr-2"
                checked={name1}
                onChange={({ target: { checked } }) => {
                  setName1(checked);
                }}
              />
              Name
            </span>
            <span className="mr-2">
              <Checkbox
                className="mr-2"
                checked={email1}
                onChange={({ target: { checked } }) => {
                  setEmail1(checked);
                }}
              />
              Email
            </span>
            <span className="mr-2">
              <Checkbox
                className="mr-2"
                checked={phone1}
                onChange={({ target: { checked } }) => {
                  setPhone1(checked);
                }}
              />
              Phone
            </span>
            <span className="mr-2">
              <Checkbox
                className="mr-2"
                checked={address}
                onChange={({ target: { checked } }) => {
                  setAddress(checked);
                }}
              />
              Address
            </span>
          </div>
          <SpError>
            {simpleValidator.current.message('Offline payment note', description, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default OfflineDonation;
