// @flow
import React, { useEffect, useState, useRef } from 'react';
import { Icon } from 'antd';
import { GoogleApiWrapper } from 'google-maps-react';
import moment from 'moment-timezone';
import supportEmailLogo from '../../../imgs/SupportEmail.svg';
import supportPhoneLogo from '../../../imgs/SupportPhone.svg';
import info from '../../../imgs/info.svg';

type Props = {
  pageData: Object,
};

const ReviewEventPage = (props: Props) => {
  const {
    pageData,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    }
  }, [pageData?.description]);

  const toggleReadMore = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };


  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      setMobileView(true);
    }
  }, []);

  function getRGB(hex) {
    let r; let g; let b;
    if (hex.startsWith('#')) {
      const value = hex.length === 7 ? hex.slice(1) : hex.slice(1).repeat(2); // Handle short `#RGB`
      r = parseInt(value.substring(0, 2), 16);
      g = parseInt(value.substring(2, 4), 16);
      b = parseInt(value.substring(4, 6), 16);
    } else {
      [r, g, b] = hex.match(/\d+/g).map(Number); // Handle `rgb(r, g, b)`
    }
    return { r, g, b };
  }

  function isDark(color) {
    const rgb = getRGB(color);
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    return brightness < 128; // Dark if brightness is low
  }


  useEffect(() => {
    if (pageData?.primaryColor) {
      document.documentElement.style.setProperty('--primary-color', pageData.primaryColor);
      if (isDark(pageData.primaryColor)) {
        document.documentElement.style.setProperty('--text-color', '#0C0C0CFF');
      } else {
        document.documentElement.style.setProperty('--text-color', '#000000');
      }
    }
  }, [pageData]);

  const getOperatingTime = (time) => {
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  return (
    <>
      <div
        className="background"
        style={{
          minHeight: 0,
          backgroundColor: '#fff',
          marginBottom: '20px',
        }}
      >
        {/* Fixed Top Section */}
        <div className="fixed-top-section position-relative">
          {/* Heading (optional) */}
          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
            {/* Add any text or heading if needed */}
          </div>
          {/* Email and Phone Section */}
          <div className="d-flex align-items-center gap-20">
            {pageData?.contactUsEmail && (
              <div className="d-flex align-items-center gap-8">
                <img
                  alt="Email Icon"
                  src={supportEmailLogo}
                  className="icon-height"
                />
                <span>{pageData.contactUsEmail}</span>
              </div>
                      )}
            {pageData?.contactPhone && (
              <div className="d-flex align-items-center gap-8">
                <img
                  alt="Phone Icon"
                  src={supportPhoneLogo}
                  className="icon-height"
                />
                <span>{pageData.contactPhone}</span>
              </div>
                      )}
            {pageData && (
              <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  alt="More Info"
                  src={info}
                  className="icon-height"
                  onClick={() => setIsExpanded(!isExpanded)}
                />
              </div>
            )}
          </div>
          {/* Event Page Support List */}
          {isExpanded && (
          <div className="support-list">
            {/* Support Details */}
            <div className="support-item">
              { pageData.contactName ? <div style={{ fontWeight: 'bold' }}>{pageData.contactName}</div> : '' }
              { pageData.contactUsEmail ? <div>{pageData.contactUsEmail}</div> : '' }
              { pageData.contactPhone ? <div>{pageData.contactPhone}</div> : '' }
              { pageData.designation ? <div>{pageData.designation}</div> : '' }
            </div>
          </div>
          )}
        </div>
        <div className="fixed-logo-section  position-relative">
          <div className="d-flex align-items-center" style={{ gap: '15px' }}>
            {/* Logo */}
            <img
              alt=""
              src={pageData?.logo}
              className="logo"
              style={{ borderRadius: '10%' }}
            />
            {/* Text */}
            <div style={{ fontSize: mobileView ? '10px' : '' }}>
              {/* eslint-disable-next-line max-len */}
              {pageData ? (pageData.bannerText ? <div dangerouslySetInnerHTML={{ __html: pageData.bannerText.replaceAll('<br>', '') }} /> : pageData.title) : ''}
            </div>
          </div>
        </div>

        <div className="content-section position-relative">
          {/* Left side: Image */}
          <div className="banner-image">
            <img
              id="dynamic-img"
              src={pageData?.banner}
              alt=""
              style={{
                maxWidth: '100%',
                maxHeight: '64vh',
                objectFit: 'contain',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.5)',
              }}
            />
          </div>

          {/* Right side: Event details */}
          <div className="details">
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <h2 className="header-title">
                {pageData?.title}
              </h2>
            </div>
            <p style={{ marginTop: '1rem', fontSize: '1.0rem', lineHeight: '1.25' }}>
              {pageData?.description && (
              <div>
                <div
                  ref={contentRef}
                  style={{
                    maxHeight: isDescriptionExpanded ? 'none' : '7.5rem',
                    overflowY: isDescriptionExpanded && mobileView ? 'auto' : 'hidden',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    position: 'relative',
                    color: 'var(--text-color)',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: pageData.description.replaceAll('<br>', ''),
                  }}
                />

                {isOverflowing && (
                <button type="button" className="read-button" onClick={toggleReadMore}>
                  <u>{isDescriptionExpanded ? '...Read Less' : '...Read More'}</u>
                </button>
                )}
              </div>
              )}
            </p>
            <div style={{ marginBottom: '10px' }}>
              {
                pageData && pageData.eventDate && (
                <>
                  <br />
                  <div className="mr-1 mt-1">
                    <h1 className="header-title">
                      <Icon type="calendar" className="icon-design" />
                      Date and time
                    </h1>
                    {
                      pageData && pageData.endTime ? (
                      // eslint-disable-next-line max-len
                        <p style={{ fontSize: '1.25rem', marginLeft: '30px', fontWeight: '550' }}>{pageData && moment.utc(pageData.eventDate).format('MMM DD, YYYY')}, {getOperatingTime(pageData && pageData.startTime)} - {getOperatingTime(pageData && pageData.endTime)}</p>
                      ) : (
                      // eslint-disable-next-line max-len
                        <p style={{ fontSize: '1.25rem', marginLeft: '30px', fontWeight: '550' }}>{pageData && moment.utc(pageData.eventDate).format('MMM DD, YYYY')}, {getOperatingTime(pageData && pageData.startTime)}</p>
                      )
                    }
                  </div>
                </>
                )
              }
              {
                pageData && pageData.street && pageData.city && pageData.state && pageData.zipcode && pageData.country && (
                <>
                  <br />
                  <div className="mr-1 mt-1">
                    <h1 className="header-title">
                      <Icon type="pushpin" className="icon-design" />
                      Location
                    </h1>
                    <p style={{ fontSize: '1.25rem', marginLeft: '30px', fontWeight: '550' }}>{(pageData)
                      ? `${pageData.street}, 
              ${pageData.city}, 
              ${pageData.state}, 
              ${pageData.zipcode}, 
              ${pageData.country === 1 ? 'USA' : pageData.country === 2 ? 'IN' : <>&#8211;</>}`
                      : <>&#8211;</>}
                    </p>
                  </div>
                </>
                )
              }
            </div>
          </div>
        </div>


        {/* Fixed Footer */}
        <div className="fixed-footer position-relative">
          <button type="button" className="book-now-btn">
            Book Now
          </button>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds',
})(ReviewEventPage);
