import React, { useEffect, useRef, useState } from 'react';
import '../../../../../assets/css/eventPageStyle.css';
import { connect } from 'react-redux';
import {
  Input,
  Modal, Select,
} from 'antd';
import type { ContextRouter } from 'react-router-dom';
import {
  eventPageDetailsActions,
  notificationActions,
} from 'store/actions';
import {
  findPhoneNumbersInText,
  formatNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import SimpleReactValidator from 'simple-react-validator';
import { axiosAuthInstance } from 'api';
import supportEmailLogo from '../../../../../imgs/SupportEmail.svg';
import supportPhoneLogo from '../../../../../imgs/SupportPhone.svg';
import info from '../../../../../imgs/info.svg';
import backArrow from '../../../../../imgs/backArrowBlack.svg';
import ProductReview from './components/productReview';
import AddProducts from './components/addProducts';
import { AmountDivideByHundred } from '../../../../../helper';
import { SpError, SpH5 } from '../../../../../components/DesignKit';
import { COUNTRY_PHONE_CODE, PAGES } from '../../../../../appconstants';
import { NOTIFICATION } from '../../../../../store/actionTypes';
import Loading from '../../../../../components/Loading';
import { countryCodeValidation, validationRegex, validators } from '../../../../../utils/validationMessages';
import getCountryPhoneCode from '../../../../../utils/getCountryPhoneCode';

const { Option } = Select;

type Props = ContextRouter & {
  gid: String,
  phoneCode: String,
  selectedAccount: Object,
  eventDetails: Object,
  eventPageOrderDetails: Object,
  eventPageOrderDetailsStatus: boolean,
  eventPageOrderSplitUp: Object,
  fetchEventPageSecureDetails: Function,
  createEventPageOfflineOrder: Function,
  fetchEventPageSplitUp: Function,
  history: {
    push: Function,
  },
  setNotification: Function,
  loading: boolean,
}


const EventPageOfflinePayment = (props: Props) => {
  const {
    phoneCode,
    selectedAccount,
    eventDetails,
    eventPageOrderDetails,
    eventPageOrderDetailsStatus,
    eventPageOrderSplitUp,
    fetchEventPageSecureDetails,
    createEventPageOfflineOrder,
    fetchEventPageSplitUp,
    history,
    setNotification,
    loading,
  } = props;
  const { gid } = props.match.params;
  const [mobileView, setMobileView] = useState(false);
  const [payload, setPayload] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [current, setCurrent] = useState(0);
  const [matchedProduct, setMatchedProduct] = useState({});
  const [orderItemIndex, setOrderItemIndex] = useState(0);
  const [payNow, setPayNow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [steps, setSteps] = useState([]);

  const [orderItems, setOrderItems] = useState(() => {
    const storedItems = sessionStorage.getItem(`placedItems-${gid.replace('eventpage-', '')}-offline`);
    return storedItems ? JSON.parse(storedItems) : [];
  });
  const [stockItems, setStockItems] = useState([]);
  const [customer, setCustomer] = useState(() => {
    const customerObject = sessionStorage.getItem(`placedItems-${gid.replace('eventpage-', '')}-customer`);
    return customerObject ? JSON.parse(customerObject) : {};
  });
  const [phoneNumberCode, setPhoneCode] = useState(phoneCode || getCountryPhoneCode(selectedAccount?.country?.id));
  const [phoneFormate, setPhoneFormate] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const phoneResult = ((customer?.phoneNumber)) ? findPhoneNumbersInText(customer.phoneNumber) : null;

  if (!contactPhone && (phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
    setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
    setContactPhone(phoneResult[0].number.nationalNumber);
    const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  }

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: 'The phone number is invalid',
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.email.test(val),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const [showButton, setShowButton] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(true);
  const containerRef = useRef(null);
  const [isWrapped, setIsWrapped] = useState(false);

  const [expiryTime, setExpiryTime] = useState(() => {
    const storedTime = sessionStorage.getItem('expiryTime');
    return storedTime ? new Date(parseInt(storedTime, 10)) : null;
  });
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    let secs = seconds % 60;
    if (secs < 10) {
      secs = `0${secs}`;
    }
    return showWarning ? `${minutes}m ${secs}s` : `${minutes}:${secs}`;
  };

  const getTimerColor = () => {
    if (count > 360) return '#2db300'; // First 9 mins (Green)
    if (count > 180) return '#e67300'; // Next 3 mins (Orange)
    return '#cc0000'; // Last 2 mins (Red)
  };

  const showWarningToUser = () => {
    setShowWarning(true);
    const warningTimer = setTimeout(() => setShowWarning(false), 5000);
    return () => clearTimeout(warningTimer);
  };

  const clearSession = () => {
    const seats = sessionStorage.getItem('seatsio') ? JSON.parse(sessionStorage.getItem('seatsio')) : undefined;
    if (seats?.holdToken) {
      axiosAuthInstance.post(seats.holdToken);
    }
    sessionStorage.removeItem('seatsio');
    sessionStorage.removeItem('expiryTime');
    sessionStorage.removeItem(`placedItems-${gid.replace('eventpage-', '')}-offline`);
    sessionStorage.removeItem(`placedItems-${gid.replace('eventpage-', '')}-customer`);

    const prefix = `placedLineItems-${gid.replace('eventpage-', '')}`;
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith(prefix)) {
        sessionStorage.removeItem(key);
      }
    });
  };

  const close = () => {
    if (!gid) {
      history.push(PAGES.EVENT_PAGE_DASHBOARD);
      return;
    }
    clearSession();
    history.push(`${PAGES.EVENT_PAGES}/${gid}`);
  };

  useEffect(() => {
    const checkWrap = () => {
      if (containerRef.current) {
        const children = Array.from(containerRef.current.children);
        if (children.length >= 2) {
          // Compare first and last item's top positions
          setIsWrapped(children[0].getBoundingClientRect().top !== children[children.length - 1].getBoundingClientRect().top);
        }
      }
    };

    checkWrap();
    window.addEventListener('resize', checkWrap);
    return () => window.removeEventListener('resize', checkWrap);
  }, [current, orderItems, mobileView]);

  useEffect(() => {
    document.documentElement.style.setProperty('--bottom-length', isWrapped ? '13%' : '11%');
  }, [isWrapped]);

  useEffect(() => {
    if (expiryTime) {
      const interval = setInterval(() => {
        setCount(() => {
          const timeLeft = Math.trunc((expiryTime.getTime() - new Date().getTime()) / 1000);
          if (timeLeft <= 0) {
            clearInterval(interval);
            setTimer(true);
            close();
            return 0;
          }
          return timeLeft;
        });
      }, 1000);

      return () => clearInterval(interval);
    }

    if (eventDetails?.isSeatedEvent && timer) {
      const seconds = 600;
      const now = new Date();
      const newExpiryTime = new Date(now.getTime() + seconds * 1000);
      setExpiryTime(newExpiryTime);
      sessionStorage.setItem('expiryTime', newExpiryTime.getTime().toString());
      setCount(seconds);
      setTimer(false);
      showWarningToUser();
    }

    return undefined;
  }, [expiryTime, eventDetails?.isSeatedEvent]);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      setMobileView(true);
    }
    const storedTime = sessionStorage.getItem('expiryTime');
    const timeLeft = storedTime ? Math.trunc((new Date(parseInt(storedTime, 10)).getTime() - new Date().getTime()) / 1000) : null;
    if (timeLeft && timeLeft <= 0) {
      close();
    } else if (expiryTime) {
      showWarningToUser();
    }
  }, []);

  useEffect(() => {
    fetchEventPageSecureDetails(gid);
  }, [gid]);

  const notify = (error) => {
    setNotification({
      type: NOTIFICATION.RESET,
    });
    setNotification({
      type: NOTIFICATION.ERROR,
      payload: error,
    });
  };

  useEffect(() => {
    if (errorMessage) {
      notify(errorMessage);
      setErrorMessage('');
    }
  }, [errorMessage]);

  const productEntry = (product, index) => {
    orderItems.push({
      index,
      productGid: product.gid,
      name: product.eventPageProductName,
      title: product.eventPageProductType === 'TICKET'
        ? 'Ticket' : product.eventPageProductType === 'FOOD'
          ? 'Food' : product.eventPageProductName,
      lineItems: [],
      totalAmount: 0,
      totalCount: 0,
    });
  };

  const stockEntry = (product) => {
    const enabledStockItems = product.items.filter((item) => item.enableStock);

    const customEnabledItems = product.customItems?.flatMap((customItem) => customItem.items?.filter((item) => item.enableStock) || []
    ) || [];

    const updatedStockItems = [...stockItems, ...enabledStockItems, ...customEnabledItems].reduce(
      (unique, item) => {
        if (!unique.some((existingItem) => existingItem.gid === item.gid)) {
          unique.push(item);
        }
        return unique;
      },
      [],
    );

    setStockItems(updatedStockItems);
  };


  function getRGB(hex) {
    let r;
    let g;
    let b;
    if (hex.startsWith('#')) {
      const value = hex.length === 7 ? hex.slice(1) : hex.slice(1).repeat(2); // Handle short `#RGB`
      r = parseInt(value.substring(0, 2), 16);
      g = parseInt(value.substring(2, 4), 16);
      b = parseInt(value.substring(4, 6), 16);
    } else {
      [r, g, b] = hex.match(/\d+/g).map(Number); // Handle `rgb(r, g, b)`
    }
    return { r, g, b };
  }

  function isDark(color) {
    const rgb = getRGB(color);
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    return brightness < 128; // Dark if brightness is low
  }

  useEffect(() => {
    if (eventDetails?.primaryColor) {
      document.documentElement.style.setProperty('--primary-color', eventDetails.primaryColor);
      if (isDark(eventDetails.primaryColor)) {
        document.documentElement.style.setProperty('--text-color', 'rgba(255, 255, 255, 0.8)');
      } else {
        document.documentElement.style.setProperty('--text-color', '#000000');
      }
    }
    if (eventDetails?.gid === gid && eventDetails?.eventPageProducts) {
      const newSteps = [];
      let index = 0;

      newSteps.push({ index, title: 'Customer Info', productGid: '' });
      index += 1;

      // Check for TICKET and add it to steps
      const ticketProduct = eventDetails.eventPageProducts.find(
        (product) => product.eventPageProductType === 'TICKET',
      );
      if (ticketProduct) {
        newSteps.push({ title: 'Tickets', productGid: ticketProduct.gid });
        const ticketIndex = orderItems.findIndex((update) => update.productGid === ticketProduct.gid);
        if (ticketIndex === -1) {
          productEntry(ticketProduct, index);
        }
        stockEntry(ticketProduct);
        index += 1;
      }

      // Check for FOOD and add it to steps
      const foodProduct = eventDetails.eventPageProducts.find(
        (product) => product.eventPageProductType === 'FOOD',
      );
      if (foodProduct) {
        newSteps.push({ title: 'Foods', productGid: foodProduct.gid });
        const foodIndex = orderItems.findIndex((update) => update.productGid === foodProduct.gid);
        if (foodIndex === -1) {
          productEntry(foodProduct, index);
        }
        stockEntry(foodProduct);
        index += 1;
      }

      // Check for CUSTOM and add it to steps
      const customProduct = eventDetails.eventPageProducts.find(
        (product) => product.eventPageProductType === 'CUSTOM',
      );
      if (customProduct) {
        newSteps.push({ title: customProduct.eventPageProductName, productGid: customProduct.gid });
        const customIndex = orderItems.findIndex((update) => update.productGid === customProduct.gid);
        if (customIndex === -1) {
          productEntry(customProduct, index);
        }
        stockEntry(customProduct);
        index += 1;
      }

      // Add the final step
      newSteps.push({ index, title: 'Review & Proceed', productGid: '' });

      // Update steps and set the first matched product
      setSteps(newSteps);
      setMatchedProduct(
        eventDetails.eventPageProducts.find((product) => product.gid === newSteps[0]?.productGid),
      );
      setOrderItemIndex(orderItems.findIndex((orderItem) => orderItem.productGid === newSteps[0]?.productGid));
    }
  }, [eventDetails]);

  const updateCustomerInfo = (value, type) => {
    if (type === 'NAME') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        name: value,
      }));
    } else if (type === 'EMAIL') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        email: value,
      }));
    } else {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        phoneNumber: `${phoneNumberCode}${value}`,
      }));
      setContactPhone(value.replace(/[^0-9+]/g, ''));
      setPhoneFormate(value.replace(/[^0-9+]/g, ''));
    }
  };

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${contactPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
    updateCustomerInfo(contactPhone, 'PHONE');
  };

  const handleNext = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      updateCustomerInfo(contactPhone, 'PHONE');
      return;
    }
    if (orderItems?.length > 0) {
      const orderItem = orderItems.find((orderItem1) => orderItem1.productGid === steps[current].productGid);
      if (orderItem?.errors?.length > 0) {
        orderItem.errors.forEach((error) => {
          notify(error);
        });
        return;
      }
    }

    if ((current + 1 === steps.length - 1
      && orderItems.flatMap(item => item.lineItems).length === 0)) {
      setErrorMessage('Add Items To Proceed...');
    } else if (current < steps.length - 1) {
      if (stockItems.some((stockItem) => (stockItem.stockUsed ? stockItem.stockUsed > stockItem.onlineStock : false))) {
        stockItems.filter((stockItem) => (stockItem.stockUsed ? stockItem.stockUsed > stockItem.onlineStock : false))
          .forEach((stockItem) => {
            // eslint-disable-next-line max-len
            notify(<span>Available stock for Item : <strong>{stockItem.displayName}</strong> is : <strong>{stockItem.onlineStock}</strong>, but placed : <strong>{stockItem.stockUsed}</strong></span>);
          });
        return;
      }
      setCurrent(current + 1);
      setMatchedProduct(eventDetails?.eventPageProducts.find((product) => product.gid === (steps[current + 1].productGid)));
      setOrderItemIndex(orderItems.findIndex((orderItem) => orderItem.productGid === steps[current + 1]?.productGid));
      sessionStorage.setItem(`placedItems-${gid.replace('eventpage-', '')}-offline`, JSON.stringify(orderItems));
      sessionStorage.setItem(`placedItems-${gid.replace('eventpage-', '')}-customer`, JSON.stringify(customer));
    }
  };

  const handleBack = () => {
    if (current > 0) {
      setCurrent(current - 1);
      setMatchedProduct(eventDetails?.eventPageProducts.find((product) => product.gid === (steps[current - 1].productGid)));
      setOrderItemIndex(orderItems.findIndex((orderItem) => orderItem.productGid === steps[current - 1]?.productGid));
    } else {
      close();
    }
  };

  const handlePayNow = () => {
    setPayNow(true);
  };

  const createOrderAndPay = async (orderPayload) => {
    try {
      createEventPageOfflineOrder(orderPayload);
    } catch (e) {
      setPayNow(false);
      if (e?.data?.message) {
        setErrorMessage(e.data.message);
      }
    }
  };

  useEffect(() => {
    if (eventPageOrderDetails?.paymentStatus === 'PAID') {
      setPayNow(false);
      close();
    }
  }, [eventPageOrderDetails]);

  useEffect(() => {
    if (eventPageOrderDetailsStatus
      && eventPageOrderDetailsStatus === 'FAILED') {
      setPayNow(false);
    }
  }, [eventPageOrderDetailsStatus]);

  useEffect(() => {
    if (payNow && payload.total !== 0) {
      createOrderAndPay(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (orderItems?.length > 0 && stockItems?.length > 0) {
      let updatedStockItems = [...stockItems];
      let mergedLineItems = [];
      orderItems.forEach((orderItem) => {
        if (orderItem.lineItems?.length > 0) {
          orderItem.lineItems
            .filter((lineItem) => lineItem.itemGid)
            .forEach((lineItem) => {
              const mergedItemIndex = mergedLineItems.findIndex((mergeLineItem) => mergeLineItem.itemGid === lineItem.itemGid);
              if (mergedItemIndex === -1) {
                mergedLineItems.push(lineItem);
              } else {
                mergedLineItems = mergedLineItems.map((item, index) => (index === mergedItemIndex
                  ? { ...item, quantity: item.quantity + lineItem.quantity }
                  : item));
              }
            });

          orderItem.lineItems
            .filter((lineItem) => lineItem.lineItemItems?.length > 0)
            .forEach((lineItem) => lineItem.lineItemItems.forEach((lineItemItem) => {
              const mergedItemIndex = mergedLineItems.findIndex((mergeLineItem) => mergeLineItem.itemGid === lineItemItem.itemGid);
              if (mergedItemIndex === -1) {
                mergedLineItems.push(lineItemItem);
              } else {
                mergedLineItems = mergedLineItems.map((item, index) => (index === mergedItemIndex
                  ? { ...item, quantity: item.quantity + (lineItem.quantity * lineItemItem.quantity) }
                  : item));
              }
            }));
        }
      });
      mergedLineItems.forEach((lineItem) => {
        const stockIndex = updatedStockItems.findIndex((item) => item.gid === lineItem.itemGid);
        if (stockIndex !== -1) {
          updatedStockItems[stockIndex] = {
            ...updatedStockItems[stockIndex],
            stockUsed: lineItem.quantity,
          };
        }
      });

      updatedStockItems = updatedStockItems.map((stockItem) => ({
        ...stockItem,
        stockUsed: mergedLineItems.some((lineItem) => lineItem.itemGid === stockItem.gid)
          ? stockItem.stockUsed
          : 0,
      }));

      setStockItems(updatedStockItems);
    }
  }, [orderItems]);

  const getOperatingTime = (time) => {
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  if (loading && (!eventDetails || eventDetails.gid !== gid)) {
    return <Loading />;
  }

  return (
    <>
      <div className="background">
        {!fullScreen && (
          <>
            {/* Fixed Top Section */}
            <div className="fixed-top-section product-top">
              {(() => {
                const primarySupport = eventDetails?.eventPageSupports?.find(
                  (list) => list.isPrimary
                );

                return (
                  <>
                    {/* Heading (optional) */}
                    <div
                      className="d-flex align-items-center"
                      style={{ fontWeight: 'bold', fontSize: '12px', marginBottom: mobileView ? '4px' : '' }}
                    >
                      {eventDetails?.title.length > (eventDetails?.coupon ? 35 : 45) && mobileView
                        ? `${eventDetails.title.substring(0, eventDetails?.coupon ? 35 : 45)}...`
                        : eventDetails?.title}

                      {eventDetails?.coupon && (
                        <span
                          className="sc-fbJfA uhGua discount-badge"
                        >
                          {eventDetails.coupon.percentageOff
                            ? `${eventDetails.coupon.percentageOff}%`
                            : `$ ${AmountDivideByHundred(eventDetails.coupon.amountOff)}`} Off
                        </span>
                      )}
                    </div>
                    {/* Email and Phone Section */}
                    <div className="d-flex align-items-center justify-content-end gap-20">
                      {primarySupport?.email && (
                        <div className="d-flex align-items-center gap-8">
                          <img
                            alt="Email Icon"
                            src={supportEmailLogo}
                            className="icon-height"
                          />
                          <span>{primarySupport.email}</span>
                        </div>
                      )}
                      {primarySupport?.phoneNumber && (
                        <div className="d-flex align-items-center gap-8">
                          <img
                            alt="Phone Icon"
                            src={supportPhoneLogo}
                            className="icon-height"
                          />
                          <span>{primarySupport.phoneNumber}</span>
                        </div>
                      )}
                      {eventDetails?.eventPageSupports?.length > 0 && (
                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                          <img
                            alt="More Info"
                            src={info}
                            className="icon-height"
                            onClick={() => setIsExpanded(!isExpanded)}
                          />
                        </div>
                      )}
                    </div>
                    {/* Event Page Support List */}
                    {isExpanded && (
                      <div className="support-list" style={{ top: mobileView ? '55px' : '' }}>
                        {eventDetails?.eventPageSupports?.map((support, index) => (
                          <div key={index}>
                            {/* Support Details */}
                            <div className="support-item">
                              {support.name ? <div style={{ fontWeight: 'bold' }}>{support.name}</div> : ''}
                              {support.email ? <div>{support.email}</div> : ''}
                              {support.phoneNumber ? <div>{support.phoneNumber}</div> : ''}
                              {support.designation ? <div>{support.designation}</div> : ''}
                            </div>

                            {/* Separator Line */}
                            {index < eventDetails.eventPageSupports.length - 1 && (
                              <div className="support-separator" />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                );
              })()}
            </div>
            <div className="fixed-logo-section product-logo">
              <div className="back-button" onClick={handleBack}>
                <img
                  alt="Back Icon"
                  src={backArrow}
                  style={{ height: '15px', width: '25px', cursor: 'pointer' }}
                />
              </div>
              { mobileView ? (
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="d-flex">
                    <div style={{
                      color: '#000000',
                      fontWeight: 'bold',
                      margin: '0 10px',
                    }}
                    >
                      Step {current + 1} of {steps.length}
                    </div>
                    <div style={{ margin: '0 5px' }}>
                      {steps[current].title}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    {steps.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center"
                        style={{ margin: '0 5px' }}
                      >
                        {/* Step Circle */}
                        <div
                          className="stepper"
                          style={{
                            fontSize: '8px',
                            backgroundColor: current === index ? '#1890ff' : current > index ? '#52c41a' : '#d9d9d9',
                            color: current === index || current > index ? '#fff' : '#000',
                          }}
                        >
                          {current > index ? '✔' : ''}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center" style={{ width: '80%' }}>
                  {steps.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center"
                      style={{
                        gap: '10px',
                        flex: `${index < steps.length - 1 ? 1 : ''}`,
                      }}
                    >
                      {/* Step Circle */}
                      <div
                        className="stepper"
                        style={{
                          backgroundColor: current === index ? '#1890ff' : current > index ? '#52c41a' : '#d9d9d9',
                          color: current === index || current > index ? '#fff' : '#000',
                        }}
                      >
                        {current > index ? '✔' : index + 1}
                      </div>
                      {/* Step Title */}
                      <div
                        style={{
                          color: current === index ? '#1890ff' : current > index ? '#52c41a' : '#8c8c8c',
                          fontWeight: current === index ? 'bold' : 'normal',
                        }}
                      >
                        {item.title}
                      </div>
                      {/* Connector Line */}
                      {index < steps.length - 1 && (
                        <div
                          style={{
                            flex: 1,
                            height: '2px',
                            backgroundColor: current > index ? '#52c41a' : '#d9d9d9',
                            margin: '0 10px',
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}

        <div style={{ overflowX: 'hidden' }} className="scrollable-content product-section">
          {expiryTime && (
            <div
              className="warning-popup"
              style={{
                borderTopLeftRadius: showWarning ? '12px' : '30px',
                borderBottomLeftRadius: showWarning ? '12px' : '30px',
                transform: showWarning ? 'scale(1.05)' : 'scale(1)',
              }}
            >
              {showWarning ? (
                <div
                  className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-md z-20 flex justify-end transition-opacity duration-300"
                  style={{ flexDirection: 'column' }}
                >
                  <div className="d-flex justify-content-center" style={{ fontSize: '20px', padding: '10px' }}>
                    <span role="img" aria-label="Time">⏳</span> Time Left: {formatTime(count)}
                  </div>

                  {/* Progress Bar Container */}
                  <div
                    style={{
                      width: '90%',
                      height: '8px',
                      backgroundColor: '#ddd',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      margin: '0 auto 10px auto',
                    }}
                  >
                    <div
                      style={{
                        width: `${(count / 600) * 100}%`,
                        height: '100%',
                        backgroundColor: getTimerColor(),
                        transition: 'width 1s linear',
                      }}
                    />
                  </div>

                  <div
                    className="w-96 h-full bg-white shadow-xl transition-transform duration-500 ease-in-out transform"
                    style={{
                      position: 'absolute',
                      left: '5px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: '0',
                      height: '0',
                      fontSize: '12px',
                      borderLeft: mobileView ? '10px solid #333' : '12px solid #333',
                      borderTop: mobileView ? '7px solid transparent' : '9px solid transparent',
                      borderBottom: mobileView ? '7px solid transparent' : '9px solid transparent',
                      cursor: 'pointer',
                      zIndex: '1000', // Ensure it's above other elements
                    }}
                    onClick={() => setShowWarning(false)}
                  />

                  <div
                    className="w-96 h-full bg-white shadow-xl p-5 transition-transform duration-500 ease-in-out transform"
                    style={{
                      opacity: showWarning ? '1' : '0',
                      pointerEvents: showWarning ? 'auto' : 'none',
                      transform: showWarning ? 'translateX(0)' : 'translateX(100%)',
                      borderTopLeftRadius: '12px',
                      borderBottomLeftRadius: '12px',
                      padding: mobileView ? '10px' : '20px',
                    }}
                  >
                    <h2
                      className="d-flex justify-content-center align-content-center important-text"
                      style={{
                        fontWeight: 'bold',
                        marginTop: '6px',
                        marginBottom: '12px',
                        color: '#222',
                      }}
                    >
                      <span role="img" aria-label="Warning" style={{ height: '16px' }}>⚠️</span>Important Notice:
                    </h2>
                    <ul
                      style={{
                        paddingLeft: '12px',
                        lineHeight: '1.4',
                        fontSize: '14px',
                        color: '#444',
                      }}
                    >
                      {/* eslint-disable-next-line max-len */}
                      <span role="img" aria-label="Time">⏱️</span> Your selected items are reserved for a limited time! If the timer runs out or you close the browser, your selection will be <b>cleared</b>, and your seats will be <b>released for others to book.</b>
                      Be sure to complete your booking in time to secure your spot! <span role="img" aria-label="Glow">🎟️✨</span>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="base-timer" style={{ position: 'relative' }}>
                  {/* Left Arrow Outside the Circle */}
                  <div
                    style={{
                      position: 'absolute',
                      left: '-20px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: '14px',
                      height: '14px',
                      borderRight: mobileView ? '5px solid #333' : '8px solid #333',
                      borderTop: mobileView ? '7px solid transparent' : '8px solid transparent',
                      borderBottom: mobileView ? '7px solid transparent' : '8px solid transparent',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowWarning(true)}
                  />
                  <svg
                    className="base-timer__svg"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: 'scale(1.1)' }}
                  >
                    <g className="base-timer__circle">
                      {/* Background Circle */}
                      <circle
                        className="base-timer__path-elapsed"
                        cx="50"
                        cy="50"
                        r="45"
                        stroke="rgba(255, 255, 255, 0.2)"
                        strokeWidth="7"
                        fill="none"
                      />
                      {/* Progress Arc */}
                      <path
                        className="base-timer__path-remaining"
                        strokeDasharray={`${((count / 600) * 283).toFixed(0)} 283`}
                        stroke={getTimerColor()}
                        strokeLinecap="round"
                        strokeWidth="3"
                        fill="none"
                        d="
                            M 50, 50
                            m -45, 0
                            a 45,45 0 1,0 90,0
                            a 45,45 0 1,0 -90,0
                        "
                      />
                    </g>
                  </svg>

                  {/* Timer Label */}
                  <span
                    className="base-timer__label"
                    style={{
                      fontWeight: 'bold',
                      color: '#333',
                      textShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {formatTime(count)}
                  </span>
                </div>
              )}
            </div>
          )}

          {current === steps.length - 1
            ? (
              <>
                <ProductReview
                  eventDetails={eventDetails}
                  customer={customer}
                  orderItems={orderItems}
                  setPayload={setPayload}
                  setPayNow={setPayNow}
                  payNow={payNow}
                  setErrorMessage={setErrorMessage}
                  splitUpApi={fetchEventPageSplitUp}
                  splitUpResponse={eventPageOrderSplitUp}
                />
                {payNow && (
                  <Modal
                    centered
                    closable={false}
                    visible
                    footer={[]}
                    open
                  >
                    <>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          flexDirection: 'column',
                          textAlign: 'center',
                          padding: '20px',
                          marginTop: '20px',
                        }}
                      >
                        {/* Loader */}
                        <div className="spinner-border loader" />

                        {/* Loading Message */}
                        <div className="loader-message">
                          Your order is being processed... <br />
                          Please wait and avoid closing or reloading this page. <br />
                          Thank you for your patience!
                        </div>
                      </div>
                    </>
                  </Modal>
                )}
              </>
            ) : current === 0 ? (
              <div className="product-screen">
                <h4 style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
                  Customer Details
                </h4>
                <div
                  className="mb-2"
                  style={{
                    padding: '0 12.5%',
                  }}
                >
                  <label htmlFor=""><SpH5>Name</SpH5></label>
                  <Input
                    type="text"
                    className="input-box d-flex align-items-center"
                    style={{ width: '100%' }}
                    placeholder="Name"
                    value={customer?.name}
                    onChange={(e) => updateCustomerInfo((e.target.value), 'NAME')}
                  />
                  <SpError>
                    {simpleValidator.current.message('name', customer?.name, 'required')}
                  </SpError>
                </div>
                <div
                  className="mb-2"
                  style={{
                    padding: '0 12.5%',
                  }}
                >
                  <label htmlFor=""><SpH5>Email</SpH5></label>
                  <Input
                    type="email"
                    className="input-box d-flex align-items-center"
                    style={{ width: '100%' }}
                    placeholder="Email"
                    value={customer?.email}
                    onChange={(e) => updateCustomerInfo((e.target.value), 'EMAIL')}
                  />
                  <SpError>
                    {simpleValidator.current.message('email', customer?.email, 'required|emailId|checkLowerCase')}
                  </SpError>
                </div>
                <div
                  style={{
                    padding: '0 12.5%',
                    marginBottom: '20px',
                  }}
                >
                  <label htmlFor=""><SpH5>Phone Number</SpH5></label>
                  <div className="d-flex">
                    <div
                      style={{ width: '20%' }}
                      onKeyPress={e => countryCodeValidation(e)}
                    >
                      <Select
                        className="w-100"
                        showSearch
                        value={phoneNumberCode}
                        onChange={onCountryCodeChange}
                      >
                        {COUNTRY_PHONE_CODE.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div style={{ width: '80%' }}>
                      <Input
                        className="InputnumFormate"
                        placeholder="Phone Number"
                        value={phoneFormate}
                        onChange={(e) => updateCustomerInfo(e.currentTarget.value, 'PHONE')}
                        onBlur={e => {
                          if (e.currentTarget.value) {
                            updateCustomerInfo(e.currentTarget.value, 'PHONE');
                            const value = formatNumber(`${phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                            const formater = value.substr(value.indexOf(' ') + 1);
                            setPhoneFormate(formater);
                          }
                        }}
                      />
                      <SpError>
                        {/* eslint-disable-next-line max-len */}
                        {simpleValidator.current.message('phone number', contactPhone, `required|phoneNumValidation:${phoneNumberCode}${contactPhone}`)}
                      </SpError>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <AddProducts
                eventDetails={eventDetails}
                eventPageProduct={matchedProduct}
                orderItemIndex={orderItemIndex}
                lineItems={orderItems[orderItemIndex] ? orderItems[orderItemIndex].lineItems : []}
                setOrderItems={setOrderItems}
                setErrorMessage={setErrorMessage}
                splitUpApi={fetchEventPageSplitUp}
                splitUpResponse={eventPageOrderSplitUp}
                getOperatingTime={getOperatingTime}
                notify={notify}
                setShowButton={setShowButton}
                setFullScreen={setFullScreen}
                mobileView={mobileView}
              />
            )}
        </div>

        {!fullScreen && (
          <>
            {/* Fixed Footer */}
            <div className="fixed-footer">
              {showButton && current < steps.length - 1 && (
              <div
                className={`d-flex align-items-center ${current === 0 ? 'justify-content-center' : 'justify-content-between'}`}
                style={{ width: '100%', padding: mobileView ? '0 8%' : '0 16%' }}
              >
                {current !== 0 && (
                // eslint-disable-next-line max-len
                <div
                  ref={containerRef}
                  className="d-flex align-items-center gap-8"
                  style={{
                    color: '#fff',
                    marginRight: '10px',
                    display: 'flex',
                    flexWrap: mobileView ? 'wrap' : 'nowrap',
                  }}
                >
                  {orderItems.map((orderItem, index) => {
                    if (index > current - 1) return null;

                    return (
                      <React.Fragment key={index}>
                        <div style={{ fontSize: mobileView ? '16px' : '22px', fontWeight: 'bold' }}>
                          ${parseFloat((orderItem.totalAmount ? orderItem.totalAmount : 0) / 100)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                          <div style={{ fontSize: mobileView ? '8px' : '10px' }}>
                            {orderItem.totalCount} {orderItem.title}{orderItem.totalCount > 1 ? 's' : ''}
                          </div>
                        </div>

                        {/* Add "+" separator between items, but not after the last one */}
                        {index !== current - 1
                        && <div style={{ fontSize: mobileView ? '14px' : '22px', padding: mobileView ? '1.5px' : '3px' }}>+</div>}
                      </React.Fragment>
                    );
                  })}
                </div>
                )}
                {/* eslint-disable-next-line react/button-has-type */}
                <button
                  type="button"
                  className="book-now-btn product-bottom-button"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
              )}
              {showButton && current === steps.length - 1 && (
                // eslint-disable-next-line react/button-has-type
                <button
                  className="book-now-btn"
                  disabled={(current + 1 === steps.length - 1 && orderItems.flatMap(item => item.lineItems).length === 0) && payNow}
                  onClick={handlePayNow}
                >
                  Add Payment
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.eventPageDetails.eventPageOfflineDetails,
  eventPageOrderSplitUp: state.eventPageDetails.eventPageOrderSplitUp,
  eventPageOrderDetails: state.eventPageDetails.eventPageOrderDetails,
  eventPageOrderDetailsStatus: state.eventPageDetails.eventPageOrderDetailsStatus,
  eventDetailsStatus: state.eventPageDetails.eventDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventPageSecureDetails: gid => dispatch(eventPageDetailsActions.fetchEventPageSecureDetails({
    payload: gid,
  })),
  createEventPageOfflineOrder: gid => dispatch(eventPageDetailsActions.createOrderForOfflinePayment({
    payload: gid,
  })),
  fetchEventPageSplitUp: param => dispatch(eventPageDetailsActions.fetchEventPageSplitUp({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventPageOfflinePayment);
