import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { Menu } from 'antd';
import queryString from 'query-string';

import { SpTab } from 'components/DesignKit';
import { transferSettingsActions } from 'store/actions';
import { connect } from 'react-redux';
import AllInvoices from './AllInvoices';

type Props = {
  location: {
    pathname: string,
    search: string,
  },
  history: {
    push: Function,
  },
  fetchTransferSetting: Function,
  transferSettings: object,
};

const Vendorinvoices = (props: Props) => {
  const [current, setCurrent] = useState('invoices');
  const [showActiveInvoice, setShowActiveInvoice] = useState(true);
  const { path } = useRouteMatch();
  const {
    location,
    history,
    fetchTransferSetting,
    transferSettings,
  } = props;

  useEffect(() => { fetchTransferSetting({}); }, []);
  useEffect(() => {
    if (transferSettings) {
      setShowActiveInvoice(transferSettings?.showActiveInvoice);
    }
  }, [transferSettings]);

  const setMenu = (query) => {
    const parsed = queryString.parse(query);
    if (parsed['status.IN']) {
      const value = parsed['status.IN'];
      switch (value) {
        case 'DRAFT': {
          setCurrent('draft');
          break;
        }
        case 'ACTIVE': {
          setCurrent('Active');
          break;
        }
        case 'PAID': {
          setCurrent('paid');
          break;
        }
        case 'ALL': {
          setCurrent('invoices');
          break;
        }
        case 'PROCESSING': {
          setCurrent('Processing');
          break;
        }
        case 'SCHEDULED': {
          setCurrent('Scheduled');
          break;
        }
        case 'CANCELLED': {
          setCurrent('Cancelled');
          break;
        }
        default:
          setCurrent('invoices');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setMenu(location.search);
    } else {
      history.push(`${path}?status.IN=ALL`);
    }
  }, [location]);

  return (
    <div>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
        <Menu.Item key="invoices">
          <SpTab
            to={`${path}?status.IN=ALL`}
            selected={current === 'invoices'}
          >
            All Invoices
          </SpTab>
        </Menu.Item>
        <Menu.Item key="draft">
          <SpTab
            to={`${path}?status.IN=DRAFT`}
            selected={current === 'draft'}
          >
            Draft
          </SpTab>
        </Menu.Item>
        {showActiveInvoice
        && (
        <Menu.Item key="Active">
          <SpTab
            to={`${path}?status.IN=ACTIVE`}
            selected={current === 'Active'}
          >
            Active
          </SpTab>
        </Menu.Item>
        )}
        <Menu.Item key="Scheduled">
          <SpTab
            to={`${path}?status.IN=SCHEDULED`}
            selected={current === 'Scheduled'}
          >
            Scheduled
          </SpTab>
        </Menu.Item>
        <Menu.Item key="Processing">
          <SpTab
            to={`${path}?status.IN=PROCESSING`}
            selected={current === 'Processing'}
          >
            Processing
          </SpTab>
        </Menu.Item>
        <Menu.Item key="paid">
          <SpTab
            to={`${path}?status.IN=PAID`}
            selected={current === 'paid'}
          >
            Paid
          </SpTab>
        </Menu.Item>
        <Menu.Item key="Cancelled">
          <SpTab
            to={`${path}?status.IN=CANCELLED`}
            selected={current === 'Cancelled'}
          >
            Cancelled
          </SpTab>
        </Menu.Item>

      </Menu>
      <AllInvoices {...props} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  transferSettings: state.transferSettings.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransferSetting: param => dispatch(transferSettingsActions.fetchTransferSetting({
    payload: param,
  })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Vendorinvoices));
