/* eslint-disable max-len */
import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  FEEDBACK,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

// const fetchInventoryQuickSupport = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_QUICKSUPPORT}?shopGid.EQ=${gid}`);
const fetchInventoryOrderFeedback = async (payload) => {
  const query = generateQuery(payload.currentPage) || 1;
  const queryParam = payload.shopGid ? `&shopGid.EQ=${payload.shopGid}` : `&shopGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_ORDER_INVENTORY_FEEDBACK}${query}${queryParam}${sorting}`);
};
const fetchInventoryFeedback = async (payload) => {
  const query = generateQuery(payload.currentPage) || 1;
  const queryParam = payload.shopGid ? `&shopGid=${payload.shopGid}` : `&shopGid=${payload.gid}`;
  const feedbackEnabled = payload.enabled ? `&enabled=${payload.enabled}` : '';
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_FEEDBACK}${query}${queryParam}${sorting}${feedbackEnabled}`);
};

const postInventoryFeedback = async ({ params }) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVENTORY_FEEDBACK}`, params);
// eslint-disable-next-line
const patchInventoryFeedback = async (params) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_FEEDBACK}/${params.feedbackQuestionGid}`, params);

const deleteSelectedFeedback = async ({ feedbackQuestionGid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_FEEDBACK}/${feedbackQuestionGid}`);


export function* getInventoryOrderFeedack(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryOrderFeedback, action.payload);
      yield put({ type: FEEDBACK.ORDER_FEEDBACK_FETCH_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryOrderFeedback, action);
      yield put({ type: FEEDBACK.ORDER_FEEDBACK_FETCH_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: FEEDBACK.ORDER_FEEDBACK_FETCH_SUCCESS, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getInventoryFeedack(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryFeedback, action.payload);
      yield put({ type: FEEDBACK.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryFeedback, action);
      yield put({ type: FEEDBACK.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: FEEDBACK.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addInventoryFeedback(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInventoryFeedback, action.payload);
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryFeedback, action.payload);
      yield put({ type: FEEDBACK.SUCCESS, payload: entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Feedback added sucessfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateInventoryFeedback(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryFeedback, action.payload);
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryFeedback, action.payload);
      yield put({ type: FEEDBACK.SUCCESS, payload: entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Feedback updated sucessfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInventoryFeedback(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteSelectedFeedback, action.payload);
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryFeedback, action.payload);
      yield put({ type: FEEDBACK.SUCCESS, payload: entity });
    }
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Feedback deleted sucessfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
