import React, {
  useEffect,
  useState,
  useRef,
  createRef,
} from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';

import ReactDragListView from 'react-drag-listview';

import {
  Row,
  Col,
  Steps,
  Layout,
  Checkbox,
  Input,
  Select,
  Card,
} from 'antd';
import {
  SpButton,
  SpH5,
  SpText,
  SpError,
  PaymentWrapper,
  PaymentFormWrapper,
} from 'components/DesignKit';
import imageOnError from 'utils/imageOnError';
import { formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';

import {
  eventPageDetailsActions,
  notificationActions,
  eventPageActions,
  accountSlugActions,
  inventoryItemsActions,
  inventoryCategoriesActions,
} from 'store/actions';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { NOTIFICATION } from 'store/actionTypes';

import {
  PAGES,
  COUNTRIES,
  MAX_AMOUNT,
  MESSAGES,
  API_STATUS,
} from 'appconstants';
import SimpleReactValidator from 'simple-react-validator';
import PaymentClose from 'components/NavIcons/paymentClose';

import getPlaceholder from 'utils/getPaymentPagePlaceholder';
import Loading from 'components/Loading';

import TextArea from 'antd/es/input/TextArea';
import reorderIcon from '../../assets/imgs/reorder-icon.png';
import arrowUp from '../../assets/imgs/arrow-upward.png';
import arrowDown from '../../assets/imgs/arrow-down.png';
import arrowUpDisabled from '../../assets/imgs/arrow-upward-disabled.png';
import arrowDownDisabled from '../../assets/imgs/arrow-down-disabled.png';

import PageDetails from './components/PageDetails';
import AccontSlug from './components/AccontSlug';
import ReviewEventPage from './components/ReviewEventPage';
import formatAmount from '../../utils/formatAmount';

const { Step } = Steps;
const { Header } = Layout;
const { Option } = Select;

type Props = ContextRouter & {
  selectedAccount: Object,
  addEventPage: Function,
  fetchEventPageDetails: Function,
  eventDetailsStatus: string,
  eventDetails: Object,
  validator: boolean,
  submitting: boolean,
  loading: boolean,
  history: {
    replace: Function,
  },
  setNotification: Function,
  accountSlug: Array<Object>,
  fetchAccountSlug: Function,
  fetchInventorySortItems: Function,
  fetchModifierItemsList: Function,
  inventoryItems: Array,
  modifierItemsList: Array,
  fetchInventorySortCategory: Function,
  sortCategoryList: Array,
};

const AddEvents = (props: Props) => {
  const {
    selectedAccount,
    history,
    addEventPage,
    validator,
    submitting,
    loading,
    eventDetails,
    fetchEventPageDetails,
    eventDetailsStatus,
    setNotification,
    accountSlug,
    fetchAccountSlug,
    fetchInventorySortItems,
    inventoryItems,
    fetchModifierItemsList,
    modifierItemsList,
    fetchInventorySortCategory,
    sortCategoryList,
    location: { search },
  } = props;
  const { gid } = props.match.params;
  const [, forceUpdate] = useState();
  const pageDetailsRef = createRef();
  const [refArray, setRefArray] = useState([pageDetailsRef]);
  const [current, setCurrent] = useState(0);
  const [isSeatedEvent, setIsSeatedEvent] = useState(new URLSearchParams(search).get('seated') === 'true' || false);
  const [title, setTitle] = useState();
  const [logo, setLogo] = useState();
  // eslint-disable-next-line no-unused-vars
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState();
  const [banner, setBanner] = useState();
  const [preview1, setPreview1] = useState();
  const [fileName1, setFileName1] = useState();
  const [contactName, setContactName] = useState();
  const [contactUsEmail, setEmail] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [phoneNumberCode, setPhoneCode] = useState();
  const [street, setStreet] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipcode] = useState();
  const [country, setCountry] = useState(selectedAccount?.country?.id);
  const currencyType = selectedAccount && selectedAccount.currency;
  const prefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [description, setDescription] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [primaryColor, setPrimaryColor] = useState('#007bff');
  const [bannerText, setBannerText] = useState('');
  const [supportOfflinePayment, setSupportOfflinePayment] = useState(false);
  const [designation, setDesignation] = useState('');

  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [venueDetails, setVenueDetails] = useState();
  const [selectedVenue, setSelectedVenue] = useState('');
  const [layout, setLayout] = useState('');
  const [venueName, setVenueName] = useState('');
  const [coordinate, setCoordinates] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [duration, setDuration] = useState('');
  const [slugGid, setSlugGid] = useState(null);
  const [slugLink, setSlugLink] = useState('');
  const [slugFlag, setSlugFlag] = useState(false);
  const [qrGeneration, setqrGeneration] = useState('ORDER_BASED');
  const [productArrays, setProductArrays] = useState([
    {
      eventPageProductType: isSeatedEvent ? 'TICKET' : '',
      eventPageProductName: '',
      itemGids: [],
      inventoryCategoryGids: [],
      customItems: [],
    },
  ]);

  const pageData = {
    isSeatedEvent,
    title,
    description,
    logo,
    preview,
    fileName,
    banner,
    bannerText,
    primaryColor,
    preview1,
    fileName1,
    street,
    state,
    city,
    zipcode,
    country,
    contactName,
    contactUsEmail,
    contactPhone,
    phoneFormate,
    phoneNumberCode,
    eventDate,
    startTime,
    endTime,
    duration,
    slugGid,
    slugLink,
    qrGeneration,
    designation,
    supportOfflinePayment,
    layout,
    coordinate,
    venueName,
    selectedVenue,
  };

  const newSlug = () => {
    const currentPage = 1;
    const size = 1000;
    const params = '&sortBy=createdAt&direction=DESC';
    fetchAccountSlug({ currentPage, params, size });
    const sortParam = '&sortBy=name&direction=ASC';
    fetchInventorySortCategory({ sortParam });
    fetchModifierItemsList({ sortParam });
  };

  useEffect(() => {
    if (gid) {
      fetchEventPageDetails(gid);
    }
    newSlug();
    const currentPage = 1;
    const sortBy = 'sortOrder';
    const direction = 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = '';
    fetchInventorySortItems({ currentPage, sortParams, filterParams });
  }, []);

  const getVenueList = async () => {
    try {
      const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.SEATED_VENUE}?size=1000`);
      setVenueDetails(entity.content);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Failed to load venue details',
      });
    }
  };

  useEffect(() => {
    getVenueList();
  }, []);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      minimumTicket: {
        message: 'Minimum 1 ticket should be selected',
        rule: (val) => {
          const numericValue = typeof val === 'string' ? val.replace(/,/g, '') : val;
          const numberValue = Number(numericValue);
          return MAX_AMOUNT.LIMIT_USD_MIN <= numberValue;
        },
      },
      minLimit: {
        message: 'Stock limit should be greater than zero',
        rule: val => val >= 1,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          // eslint-disable-next-line max-len
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) && (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) <= MAX_AMOUNT.LIMIT_INR
            // eslint-disable-next-line max-len
            : MAX_AMOUNT.LIMIT_USD_MIN <= (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) && (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) <= MAX_AMOUNT.LIMIT_USD),
      },
      minAmount: {
        message: 'Min amount should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 1,
      },
      optionalMinAmount: {
        message: 'Min amount should be greater than or equal to zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      minValidAmount: {
        message: 'Max amount should be greater than zero',
        rule: val => val && Number(val) >= 1,
      },
      maxAmount: {
        message: 'Max amount should be greater than Min amount',
        rule: (val, params) => val && (Number(val) > Number(params[0])),
      },
      tipAmount: {
        message: 'Tip should be > 0 and <= to 100',
        rule: val => Number(val && val.replace(/,/g, '')) > 0 && Number(val && val.replace(/,/g, '')) <= 100,
      },
      tipValidation1: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
      tipValidation: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const [customerDetailsArray, setCustomerDetailsArray] = useState([{
    type: 'INPUT_EMAIL',
    label: 'Email',
    required: false,
    disableRequired: false,
  },
  {
    type: 'INPUT_PHONE',
    label: 'Phone',
    required: false,
    disableRequired: false,
  },
  ]);

  const addCustomerDetails = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setCustomerDetailsArray([...customerDetailsArray, {
      type: '',
      label: '',
      required: false,
      name: '',
      category: '',
    }]);
  };

  const removeCustomerDetails = (j) => {
    const list = [...customerDetailsArray];
    list.splice(j, 1);
    setCustomerDetailsArray(list);
  };

  useEffect(() => {
    if (!validator && redirect) {
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.EVENTS.ADD_SUCCESS,
      });
      history.push(isSeatedEvent ? PAGES.SEATED_EVENTS : PAGES.NON_SEATED_EVENTS);
    }
  }, [validator]);

  useEffect(() => {
    if (gid && (eventDetails.gid)) {
      setIsSeatedEvent(eventDetails?.isSeatedEvent);
      setTitle(eventDetails.title);
      setDescription(eventDetails && eventDetails.description ? Buffer.from(eventDetails.description, 'base64').toString('utf8') : '');
      setBannerText(eventDetails && eventDetails.bannerText ? Buffer.from(eventDetails.bannerText, 'base64').toString('utf8') : '');
      setPrimaryColor(eventDetails.primaryColor || primaryColor);

      setStreet(eventDetails?.address?.street);
      setCity(eventDetails?.address?.city);
      setState(eventDetails?.address?.state);
      setCountry(eventDetails?.address?.country?.id);
      setZipcode(eventDetails?.address?.postalCode);

      if (eventDetails?.seatedVenue) {
        setLayout(eventDetails?.seatedVenue.gid);
        setVenueName(eventDetails?.seatedVenue.venueName);
        setSelectedVenue(eventDetails?.seatedVenue);
        setCoordinates(eventDetails?.seatedVenue.coordinate);
      }

      setEventDate(eventDetails?.eventDate);
      setStartTime(eventDetails?.eventStartTime);
      setEndTime(eventDetails?.eventEndTime);
      setDuration(eventDetails?.eventDuration);

      setLogo(eventDetails.logo);
      setFileName(eventDetails.logo);
      setPreview(
        <img
          src={`${eventDetails.logo}?time=${new Date().getTime()}`}
          alt=""
          onError={event => imageOnError(event)}
          style={{ width: '100%' }}
        />,
      );
      setBanner(eventDetails.banner);
      setFileName1(eventDetails.banner);
      setPreview1(
        <img
          src={`${eventDetails.banner}?time=${new Date().getTime()}`}
          alt=""
          onError={event => imageOnError(event)}
          style={{ width: '100%' }}
        />,
      );

      setqrGeneration(eventDetails?.qrGeneration);

      setContactName(eventDetails.eventPageSupports[0]?.name);
      setEmail(eventDetails.eventPageSupports[0]?.email);
      const phoneResult = ((eventDetails?.eventPageSupports[0]?.phoneNumber))
        ? findPhoneNumbersInText(eventDetails && eventDetails.eventPageSupports[0]?.phoneNumber) : null;
      if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
        setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
        setContactPhone(phoneResult[0].number.nationalNumber);
        const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
        const formater = data.substr(data.indexOf(' ') + 1);
        setPhoneFormate(formater);
      }
      setDesignation(eventDetails?.eventPageSupports[0]?.designation);

      setSupportOfflinePayment(eventDetails && eventDetails.supportOfflinePayment);
      const tempProducts = eventDetails?.eventPageProducts;
      const tempPrdList = [];
      let tempPrdObj = {};
      for (let i = 0; i < tempProducts.length; i += 1) {
        tempPrdObj.eventPageProductType = tempProducts[i].eventPageProductType;
        tempPrdObj.eventPageProductName = tempProducts[i].eventPageProductName;
        const inventoryCategoryGids = [];
        const itemGids = [];
        const customItems = tempProducts[i]?.customItems ? tempProducts[i]?.customItems : [];
        for (let j = 0; j < tempProducts[i]?.inventoryCategories?.length; j += 1) {
          inventoryCategoryGids.push(tempProducts[i].inventoryCategories[j].gid);
        }
        for (let k = 0; k < tempProducts[i]?.items?.length; k += 1) {
          itemGids.push(tempProducts[i].items[k].gid);
        }
        for (let l = 0; l < tempProducts[i]?.customItems?.length; l += 1) {
          const customItem = customItems[l];
          const customItemInventoryCategoryGids = [];
          const customItemItemGids = [];
          const customItemModifierGroupGids = [];
          customItem.amount = Number.isInteger(customItem.amount) ? formatAmount((customItem.amount / 100), currencyType) : customItem.amount;
          for (let j = 0; j < customItem?.inventoryCategories?.length; j += 1) {
            customItemInventoryCategoryGids.push(customItem.inventoryCategories[j].gid);
          }
          for (let k = 0; k < customItem?.items?.length; k += 1) {
            customItemItemGids.push(customItem.items[k].gid);
          }
          for (let k = 0; k < customItem?.modifierGroups?.length; k += 1) {
            customItemModifierGroupGids.push(customItem.modifierGroups[k].gid);
          }
          customItem.gid = null;
          customItem.itemGids = customItemItemGids;
          customItem.inventoryCategoryGids = customItemInventoryCategoryGids;
          customItem.modifierGroupGids = customItemModifierGroupGids;
          customItem.seatedVenueCategoryGid = customItem.seatedVenueCategory?.gid;
        }
        tempPrdObj.inventoryCategoryGids = inventoryCategoryGids;
        tempPrdObj.itemGids = itemGids;
        tempPrdObj.customItems = customItems;
        tempPrdList.push(tempPrdObj);
        tempPrdObj = {};
      }
      setProductArrays(tempPrdList);
      const custList = eventDetails?.eventPageCustomInfos;
      const tempCstList = [];
      let tempCstObj = {};
      for (let i = 0; i < custList.length; i += 1) {
        tempCstObj.type = custList[i].inputFieldType;
        tempCstObj.label = custList[i].inputFieldLabel;
        tempCstObj.required = !custList[i].isOptional;
        tempCstObj.disableRequired = false;
        tempCstList.push(tempCstObj);
        tempCstObj = {};
      }
      setCustomerDetailsArray(tempCstList);
    }
  }, [eventDetailsStatus, venueDetails]);

  useEffect(() => {
    if (current === 3 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const steps = [
    {
      title: 'Event Info',
    },
    {
      title: 'Product Info',
    },
    {
      title: 'Custom Info',
    },
    {
      title: 'Slug Creation',
    },
    {
      title: 'Review',
    },
  ];

  const next = (event) => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      setTitle(valuesArray[0].title);
      setIsSeatedEvent(valuesArray[0].isSeatedEvent);
      setDescription(valuesArray[0].description ? valuesArray[0].description : null);
      setLogo(valuesArray[0].logo ? valuesArray[0].logo : 'logo_test');
      setPreview(valuesArray[0].preview);
      setFileName(valuesArray[0].fileName);
      setBanner(valuesArray[0].banner ? valuesArray[0].banner : 'logo_test');
      setBannerText(valuesArray[0].bannerText);
      setPrimaryColor(valuesArray[0].primaryColor);
      setPreview1(valuesArray[0].preview1);
      setFileName1(valuesArray[0].fileName1);
      setStreet(valuesArray[0].street);
      setState(valuesArray[0].state);
      setCity(valuesArray[0].city);
      setZipcode(valuesArray[0].zipcode);
      setCountry(valuesArray[0].country);
      setContactName(valuesArray[0].contactName);
      setEmail(valuesArray[0].contactUsEmail);
      setContactPhone(valuesArray[0].contactPhone);
      setPhoneFormate(valuesArray[0].phoneFormate);
      setPhoneCode(valuesArray[0].phoneNumberCode);
      setEventDate(valuesArray[0].eventDate ? valuesArray[0].eventDate : null);
      setStartTime(valuesArray[0].startTime ? valuesArray[0].startTime : null);
      setEndTime(valuesArray[0].endTime ? valuesArray[0].endTime : null);
      setDuration(valuesArray[0].duration ? valuesArray[0].duration : null);
      setSupportOfflinePayment(valuesArray[0].supportOfflinePayment);
      setqrGeneration(valuesArray[0].qrGeneration);
      setDesignation(valuesArray[0].designation);
      setVenueName(valuesArray[0].venueName);
      setLayout(valuesArray[0].layout);
      setCoordinates(valuesArray[0].coordinate);
      setSelectedVenue(valuesArray[0].selectedVenue);
      simpleValidator.current.hideMessages();
    } else if (current === 1) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        const list = [...productArrays];
        setProductArrays(list);
        return;
      }
      setCurrent(current + 1);
      simpleValidator.current.hideMessages();
      forceUpdate();
    } else if (current === 2) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
    } else if (current === 3) {
      valuesArray.push(refArray[0].current.getValues());
      setSlugGid(valuesArray[0].slugGid);
      setSlugLink(valuesArray[0].slugLink);
      setCurrent(current + 1);
    } else if (current === 4) {
      setDisableButton(true);
      const contactNumber = `${pageData.phoneNumberCode}${pageData.contactPhone}`;
      const custList = [];
      let custObj = {};
      for (let i = 0; i < customerDetailsArray.length; i += 1) {
        custObj.inputFieldType = customerDetailsArray[i].type;
        custObj.inputFieldLabel = customerDetailsArray[i].label;
        custObj.isOptional = !customerDetailsArray[i].required;
        custList.push(custObj);
        custObj = {};
      }
      const payload = {
        title: pageData.title,
        isSeatedEvent,
        description: pageData.description ? Buffer.from(pageData.description).toString('base64') : '',
        logo: pageData.logo,
        banner: pageData.banner,
        bannerType: 'VERTICAL',
        bannerText: pageData.bannerText ? Buffer.from(pageData.bannerText).toString('base64') : '',
        primaryColor: pageData.primaryColor,
        eventDate: pageData.eventDate,
        eventStartTime: pageData.startTime,
        eventEndTime: pageData.endTime,
        eventDuration: pageData.eventDuration,
        qrGeneration: pageData.qrGeneration,
        supportOfflinePayment,
        seatedVenueGid: isSeatedEvent && selectedVenue ? selectedVenue.gid : null,
        address: {
          street: pageData.street,
          city: pageData.city,
          state: pageData.state,
          countryCode: selectedAccount?.country?.alpha2,
          postalCode: pageData.zipcode,
        },
        eventPageSupports: [
          {
            name: pageData.contactName,
            email: pageData.contactUsEmail,
            phoneNumber: contactNumber,
            designation,
            isPrimary: true,
          },
        ],
        eventPageProducts: productArrays.map(product => ({
          ...product,
          customItems: product.customItems?.map(item => ({
            ...item,
            amount: item.amount ? item.amount.replace(/[,.]/g, '') : item.amount,
          })) || [],
        })),
        eventPageCustomInfos: custList,
        taxRatesGids: [],
        serviceFeeGids: [],
        eventPageTips: [],
      };
      if (slugFlag === true) {
        payload.accountSlugGid = null;
        payload.slugGid = null;
        payload.slugLink = '';
      } else {
        payload.accountSlugGid = slugGid;
      }
      addEventPage(payload);
      setRedirect(true);
    }
  };

  const withoutSlug = () => {
    setSlugFlag(true);
    next();
  };

  const prev = (event) => {
    event.preventDefault();
    setDisableButton(false);
    setSlugFlag(false);
    setCurrent(current - 1);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const dragdata = [...customerDetailsArray];
      const item = dragdata.splice(fromIndex, 1)[0];
      dragdata.splice(toIndex, 0, item);
      setCustomerDetailsArray(dragdata);
    },
    nodeSelector: 'div',
    handleSelector: 'p',
    lineClassName: 'global-drag-line',
  };

  const onArrowUp = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex - 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };

  const onArrowDown = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex + 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };

  if (loading && redirect) {
    return <Loading />;
  }

  const updateLayout = (val) => {
    if (!gid) {
      if (val === 'noLayout') {
        setCustomerDetailsArray([{
          type: 'INPUT_EMAIL',
          label: '',
          required: false,
          disableRequired: false,
          name: '',
          category: '',
        },
        {
          type: 'INPUT_PHONE',
          label: '',
          required: false,
          disableRequired: false,
          name: '',
          category: '',
        }]);
      } else if (val !== 'noLayout') {
        setCustomerDetailsArray([]);
      }
    }
  };

  return (
    <>
      {
        ((gid && eventDetailsStatus === API_STATUS.SUCCESS) || !gid) && (
          <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
            <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
              <Row type="flex" justify="space-between">
                <Col className="d-flex align-items-center">
                  {(gid ? 'Clone a ' : 'Create a ') + (isSeatedEvent ? '' : 'Non')} Seated event
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <span style={{ cursor: 'pointer' }} onClick={() => history.push(isSeatedEvent ? PAGES.SEATED_EVENTS : PAGES.NON_SEATED_EVENTS)}>
                    <PaymentClose />
                  </span>
                </Col>
              </Row>
            </Header>
            <PaymentWrapper style={{ background: current === 3 ? primaryColor : '' }}>
              <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <PaymentFormWrapper style={{ width: current === 4 ? '98%' : current === 3 ? '964px' : '800px' }}>
                <div>
                  {current === 0 && (
                    <PageDetails
                      ref={refArray[0]}
                      venueDetails={venueDetails}
                      selectedAccount={selectedAccount}
                      pageData={pageData}
                      isIndia={isIndia}
                      updateLayout={updateLayout}
                    />
                  )}
                  {current === 1 && (
                    <div>
                      <Card className="mb-4 pt-4">
                        <Row>
                          <Col span={16} push={4} className="mb-3">
                            <SpText fontWeight="600" fontSize="18px">Product Info</SpText>
                          </Col>
                        </Row>
                        {
                          productArrays.map((item, i) => (
                            <div>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Product type</SpH5></label>
                                  <div>
                                    <Select
                                      className="w-100"
                                      placeholder="Select product type"
                                      value={item.eventPageProductType || 'Select product type'}
                                      disabled={isSeatedEvent && item.eventPageProductType === 'TICKET'}
                                      onChange={(value) => {
                                        const list = [...productArrays];
                                        list[i].eventPageProductType = value;
                                        setProductArrays(list);
                                      }}
                                    >
                                      {!productArrays.some((product) => product.eventPageProductType === 'TICKET') && (
                                        <Option key={1} value="TICKET">TICKET</Option>
                                      )}
                                      {!productArrays.some((product) => product.eventPageProductType === 'FOOD') && (
                                        <Option key={2} value="FOOD">FOOD</Option>
                                      )}
                                      {!productArrays.some((product) => product.eventPageProductType === 'CUSTOM') && (
                                        <Option key={3} value="CUSTOM">CUSTOM</Option>
                                      )}
                                    </Select>
                                  </div>
                                  <SpError>
                                    {simpleValidator.current.message('Product type', productArrays[i].eventPageProductType, 'required')}
                                  </SpError>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Product Name</SpH5></label>
                                  <Input
                                    placeholder="Enter Product Name"
                                    value={item.eventPageProductName}
                                    maxlength="100"
                                    onChange={(e) => {
                                      const list = [...productArrays];
                                      const { value } = e.currentTarget;
                                      list[i].eventPageProductName = value;
                                      setProductArrays(list);
                                    }}
                                  />
                                  <SpError>
                                    {simpleValidator.current.message('Product Name', productArrays[i].eventPageProductName, 'required')}
                                  </SpError>
                                </Col>
                              </Row>
                              {isSeatedEvent && item.eventPageProductType === 'TICKET' ? (<></>) : (
                                <>
                                  <Row>
                                    <Col span={16} push={4} className="mb-3">
                                      <label htmlFor=""><SpH5>Product Categories</SpH5></label>
                                      <Select
                                        className="w-100"
                                        mode="multiple"
                                        allowClear
                                        placeholder="Select category"
                                        onChange={(value) => {
                                          const list = [...productArrays];
                                          list[i].inventoryCategoryGids = value;
                                          setProductArrays(list);
                                        }}
                                        /* eslint-disable-next-line max-len */
                                        value={item.inventoryCategoryGids?.filter((categoryGid) => sortCategoryList.some((category) => category.gid === categoryGid)
                                        )}
                                        showSearch
                                        optionFilterProp="name"
                                        style={{ width: '100%' }}
                                      >
                                        {sortCategoryList.map((items) => (
                                          <Option key={items.gid} name={items.name} value={items.gid}>
                                            {items.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={16} push={4} className="mb-3">
                                      <label htmlFor=""><SpH5>Product Items</SpH5></label>
                                      <Select
                                        className="w-100"
                                        mode="multiple"
                                        allowClear
                                        placeholder="Select item"
                                        onChange={(value) => {
                                          const list = [...productArrays];
                                          list[i].itemGids = value;
                                          setProductArrays(list);
                                        }}
                                        value={item.itemGids?.filter((categoryGid) => inventoryItems.some((category) => category.gid === categoryGid)
                                        )}
                                        showSearch
                                        optionFilterProp="name"
                                        style={{ width: '100%' }}
                                      >
                                        {inventoryItems.map((items) => (
                                          <Option key={items.gid} name={items.name} value={items.gid}>
                                            {items.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Col>
                                  </Row>
                                </>
                              )}
                              {
                                item.customItems.map((customItem, j) => (
                                  <div
                                    style={{
                                      border: '1px solid #ccc',
                                      borderRadius: '8px',
                                      maxWidth: '450px',
                                      margin: '16px auto',
                                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    }}
                                  >
                                    <h4 style={{ textAlign: 'center', marginBottom: '16px', marginTop: '16px' }}>Custom Item</h4>
                                    <Row>
                                      <Col span={16} push={4} className="mb-3">
                                        <label htmlFor=""><SpH5>Item Name</SpH5></label>
                                        <Input
                                          placeholder="Enter Item Name"
                                          value={customItem.inputFieldLabel}
                                          maxlength="100"
                                          onChange={(e) => {
                                            const { value } = e.currentTarget;

                                            const updatedProductArrays = [...productArrays];
                                            const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                            updatedCustomItems[j] = {
                                              ...updatedCustomItems[j],
                                              inputFieldLabel: value,
                                            };
                                            updatedProductArrays[i] = {
                                              ...updatedProductArrays[i],
                                              customItems: updatedCustomItems,
                                            };

                                            setProductArrays(updatedProductArrays);
                                          }}
                                        />
                                        <SpError>
                                          {simpleValidator.current.message('Item Name', customItem.inputFieldLabel, 'required')}
                                        </SpError>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={16} push={4} className="mb-3">
                                        <label htmlFor=""><SpH5>Description</SpH5></label>
                                        <TextArea
                                          maxLength={255}
                                          rows={5}
                                          placeholder="Description"
                                          value={customItem.description}
                                          onChange={(e) => {
                                            const { value } = e.currentTarget;

                                            const updatedProductArrays = [...productArrays];
                                            const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                            updatedCustomItems[j] = {
                                              ...updatedCustomItems[j],
                                              description: value,
                                            };
                                            updatedProductArrays[i] = {
                                              ...updatedProductArrays[i],
                                              customItems: updatedCustomItems,
                                            };

                                            setProductArrays(updatedProductArrays);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={16} push={4} className="mb-3">
                                        <label htmlFor=""><SpH5>Amount</SpH5></label>
                                        <Input
                                          value={customItem.amount}
                                          prefix={prefix}
                                          placeholder="0.00"
                                          onChange={(e) => {
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            let amount = '';
                                            if (regex.test(value) || value === '') {
                                              amount = (e.currentTarget.value);
                                            } else if (!regex.test(value)) {
                                              amount = (0.00);
                                            }
                                            const updatedProductArrays = [...productArrays];
                                            const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                            updatedCustomItems[j] = {
                                              ...updatedCustomItems[j],
                                              amount,
                                            };
                                            updatedProductArrays[i] = {
                                              ...updatedProductArrays[i],
                                              customItems: updatedCustomItems,
                                            };

                                            setProductArrays(updatedProductArrays);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const value = parseFloat(e.currentTarget.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                                              const updatedProductArrays = [...productArrays];
                                              const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                              updatedCustomItems[j] = {
                                                ...updatedCustomItems[j],
                                                amount: value,
                                              };
                                              updatedProductArrays[i] = {
                                                ...updatedProductArrays[i],
                                                customItems: updatedCustomItems,
                                              };

                                              setProductArrays(updatedProductArrays);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {simpleValidator.current.message('Item Amount', customItem.amount, 'required')}
                                        </SpError>
                                      </Col>
                                    </Row>
                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                        <Checkbox
                                          className="ml-4"
                                          checked={(customItem.isOptional)}
                                          onChange={({ target: { checked } }) => {
                                            const updatedProductArrays = [...productArrays];
                                            const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                            updatedCustomItems[j] = {
                                              ...updatedCustomItems[j],
                                              isOptional: checked,
                                            };
                                            updatedProductArrays[i] = {
                                              ...updatedProductArrays[i],
                                              customItems: updatedCustomItems,
                                            };

                                            setProductArrays(updatedProductArrays);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={16} push={4} className="mb-3">
                                        <label htmlFor=""><SpH5>Custom Item - Categories</SpH5></label>
                                        <Select
                                          className="w-100"
                                          mode="multiple"
                                          allowClear
                                          placeholder="Select category"
                                          onChange={(value) => {
                                            const updatedProductArrays = [...productArrays];
                                            const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                            updatedCustomItems[j] = {
                                              ...updatedCustomItems[j],
                                              inventoryCategoryGids: value,
                                            };
                                            updatedProductArrays[i] = {
                                              ...updatedProductArrays[i],
                                              customItems: updatedCustomItems,
                                            };

                                            setProductArrays(updatedProductArrays);
                                          }}
                                          /* eslint-disable-next-line max-len */
                                          value={customItem.inventoryCategoryGids?.filter((categoryGid) => sortCategoryList.some((category) => category.gid === categoryGid)
                                          )}
                                          showSearch
                                          optionFilterProp="name"
                                          style={{ width: '100%' }}
                                        >
                                          {sortCategoryList.map((items) => (
                                            <Option key={items.gid} name={items.name} value={items.gid}>
                                              {items.name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={16} push={4} className="mb-3">
                                        <label htmlFor=""><SpH5>Custom Item - Items</SpH5></label>
                                        <Select
                                          className="w-100"
                                          mode="multiple"
                                          allowClear
                                          placeholder="Select item"
                                          onChange={(value) => {
                                            const updatedProductArrays = [...productArrays];
                                            const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                            updatedCustomItems[j] = {
                                              ...updatedCustomItems[j],
                                              itemGids: value,
                                            };
                                            updatedProductArrays[i] = {
                                              ...updatedProductArrays[i],
                                              customItems: updatedCustomItems,
                                            };

                                            setProductArrays(updatedProductArrays);
                                          }}
                                          /* eslint-disable-next-line max-len */
                                          value={customItem.itemGids?.filter((categoryGid) => inventoryItems.some((category) => category.gid === categoryGid)
                                          )}
                                          showSearch
                                          optionFilterProp="name"
                                          style={{ width: '100%' }}
                                        >
                                          {inventoryItems.map((items) => (
                                            <Option key={items.gid} name={items.name} value={items.gid}>
                                              {items.name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={16} push={4} className="mb-3">
                                        <label htmlFor=""><SpH5>Custom Item - Modifier Groups</SpH5></label>
                                        <Select
                                          className="w-100"
                                          mode="multiple"
                                          allowClear
                                          placeholder="Select Modifier Group"
                                          onChange={(value) => {
                                            const updatedProductArrays = [...productArrays];
                                            const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                            updatedCustomItems[j] = {
                                              ...updatedCustomItems[j],
                                              modifierGroupGids: value,
                                            };
                                            updatedProductArrays[i] = {
                                              ...updatedProductArrays[i],
                                              customItems: updatedCustomItems,
                                            };

                                            setProductArrays(updatedProductArrays);
                                          }}
                                          /* eslint-disable-next-line max-len */
                                          value={customItem.modifierGroupGids?.filter((categoryGid) => modifierItemsList.some((category) => category.gid === categoryGid)
                                          )}
                                          showSearch
                                          optionFilterProp="name"
                                          style={{ width: '100%' }}
                                        >
                                          {modifierItemsList.map((items) => (
                                            <Option key={items.gid} name={items.name} value={items.gid}>
                                              {items.name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Col>
                                    </Row>
                                    {(isSeatedEvent && item.eventPageProductType === 'TICKET') ? (
                                      <Row className="mt-4 mb-4">
                                        <Col span={16} push={4} className="mb-3">
                                          <SpButton
                                            type="dangerSecondary"
                                            onClick={() => {
                                              const updatedProductArrays = [...productArrays];
                                              const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                              updatedCustomItems.splice(j, 1);
                                              updatedProductArrays[i] = {
                                                ...updatedProductArrays[i],
                                                customItems: updatedCustomItems,
                                              };
                                              setProductArrays(updatedProductArrays);
                                            }}
                                          >
                                            {'\u002B'}&nbsp;Remove {customItem.inputFieldLabel}
                                          </SpButton>
                                        </Col>
                                      </Row>
                                    ) : (
                                      <Row className="mt-4 mb-4">
                                        <Col span={16} push={4} className="mb-3">
                                          <SpButton
                                            type="dangerSecondary"
                                            onClick={() => {
                                              const updatedProductArrays = [...productArrays];
                                              const updatedCustomItems = [...updatedProductArrays[i].customItems];
                                              updatedCustomItems.splice(j, 1);
                                              updatedProductArrays[i] = {
                                                ...updatedProductArrays[i],
                                                customItems: updatedCustomItems,
                                              };
                                              setProductArrays(updatedProductArrays);
                                            }}
                                          >
                                            {'\u002B'}&nbsp;Remove Custom Item
                                          </SpButton>
                                        </Col>
                                        <Col span={16} push={4}>
                                          <SpButton
                                            type="secondary"
                                            onClick={() => {
                                              const updatedProductArrays = [...productArrays]; // Clone the entire array
                                              updatedProductArrays[i].customItems = [
                                                ...updatedProductArrays[i].customItems,
                                                {
                                                  inputFieldType: updatedProductArrays[i].eventPageProductType,
                                                  inputFieldLabel: '',
                                                  description: '',
                                                  isOptional: true,
                                                  itemGids: [],
                                                  inventoryCategoryGids: [],
                                                  modifierGroupGids: [],
                                                },
                                              ];
                                              setProductArrays(updatedProductArrays); // Update state with a new array
                                            }}
                                          >
                                            {'\u002B'}&nbsp;Add Custom Item
                                          </SpButton>
                                        </Col>
                                      </Row>
                                    )}
                                  </div>
                                ))
                              }
                              {
                                item.customItems && item.customItems.length === 0
                                  && ((isSeatedEvent && item.eventPageProductType !== 'TICKET') || !isSeatedEvent) ? (
                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={() => {
                                            const updatedProductArrays = [...productArrays]; // Clone the entire array
                                            updatedProductArrays[i].customItems = [
                                              ...updatedProductArrays[i].customItems,
                                              {
                                                inputFieldType: updatedProductArrays[i].eventPageProductType,
                                                inputFieldLabel: '',
                                                description: '',
                                                isOptional: true,
                                                itemGids: [],
                                                inventoryCategoryGids: [],
                                                modifierGroupGids: [],
                                              },
                                            ];
                                            setProductArrays(updatedProductArrays); // Update state with a new array
                                          }}
                                        >
                                          {'\u002B'}&nbsp;Add Custom Item
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <></>
                                  )
                              }
                              {isSeatedEvent && item.eventPageProductType === 'TICKET' && selectedVenue?.seatedVenueCategories?.length > 0 ? (
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <div style={{ gap: '10px', flexWrap: 'wrap' }} className="mt-4 mb-4 d-flex ">
                                      {selectedVenue?.seatedVenueCategories.map((category) => {
                                        if (item.customItems?.some((customItem) => customItem.seatedVenueCategoryGid === category.gid)) {
                                          return (<></>);
                                        }

                                        return (
                                          <div key={category.gid}>
                                            <SpButton
                                              type="secondary"
                                              onClick={() => {
                                                const updatedProductArrays = [...productArrays]; // Clone the entire array
                                                updatedProductArrays[i].customItems = [
                                                  ...updatedProductArrays[i].customItems,
                                                  {
                                                    inputFieldType: updatedProductArrays[i].eventPageProductType,
                                                    inputFieldLabel: category.name,
                                                    description: '',
                                                    isOptional: true,
                                                    itemGids: [],
                                                    inventoryCategoryGids: [],
                                                    modifierGroupGids: [],
                                                    seatedVenueCategoryGid: category.gid,
                                                  },
                                                ];
                                                setProductArrays(updatedProductArrays);
                                              }}
                                            >
                                              {'\u002B'}&nbsp;Add {category.name}
                                            </SpButton>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <SpError>
                                      {simpleValidator.current.message('Tickets', item.customItems.length, 'required|minimumTicket')}
                                    </SpError>
                                  </Col>
                                </Row>
                              ) : (<></>)}
                              {
                                i > 0 ? (
                                  <Row className="mt-4 mb-4">
                                    <Col span={16} push={4}>
                                      <SpButton
                                        type="danger"
                                        style={{ width: '50%' }}
                                        onClick={() => {
                                          const list = [...productArrays];
                                          list.splice(i, 1);
                                          setProductArrays(list);
                                        }}
                                      >
                                        {'\u002B'}&nbsp;Remove Product
                                      </SpButton>
                                    </Col>
                                  </Row>
                                ) : (
                                  <></>
                                )
                              }
                            </div>
                          ))
                        }
                        {productArrays && productArrays.length <= 2 && (
                          <Row className="mt-4 mb-4">
                            <Col span={16} push={4}>
                              <SpButton
                                type="secondary"
                                style={{ width: '50%' }}
                                onClick={() => {
                                  const list = [...productArrays];
                                  list.push({
                                    productType: '',
                                    label: '',
                                    itemGids: [],
                                    inventoryCategoryGids: [],
                                    customItems: [],
                                  });
                                  setProductArrays(list);
                                }}
                              >
                                {'\u002B'}&nbsp;Add Product
                              </SpButton>
                            </Col>
                          </Row>
                        )}
                      </Card>
                    </div>
                  )}
                  {current === 2 && (
                    <div>
                      <ReactDragListView
                        {...dragProps}
                      >
                        {
                          customerDetailsArray.map((item, j) => (
                            <div
                              style={{
                                backgroundColor: '#fff',
                                padding: '10px',
                                margin: '25px 0',
                                borderRadius: '5px',
                                cursor: 'pointer',
                              }}
                            >
                              <p>
                                <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                                  <Col className="d-flex align-items-center">
                                    <div className="ml-2"><img src={reorderIcon} alt="" /></div>
                                  </Col>
                                  <Col className="d-flex align-items-center">
                                    {
                                      j === 0 ? (
                                        <div className="mr-2">
                                          <img src={arrowUpDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowUp(j)}
                                        >
                                          <img src={arrowUp} alt="" />
                                        </div>
                                      )
                                    }
                                    {
                                      j === (customerDetailsArray.length - 1) ? (
                                        <div
                                          className="mr-2"
                                        >
                                          <img src={arrowDownDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowDown(j)}
                                        >
                                          <img src={arrowDown} alt="" />
                                        </div>
                                      )
                                    }

                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                    <div>
                                      <Select
                                        style={{ width: '50%' }}
                                        placeholder="Select field type"
                                        value={item.type || undefined}
                                        disabled={item.disableRequired ? true : ''}
                                        onChange={(e) => {
                                          const list = [...customerDetailsArray];
                                          list[j].type = e;
                                          setCustomerDetailsArray(list);
                                        }}
                                      >
                                        <Option key={1} value="INPUT_EMAIL">Email</Option>
                                        <Option key={2} value="INPUT_PHONE">Phone</Option>
                                        <Option key={3} value="INPUT_NUMBER">Number</Option>
                                        <Option key={4} value="INPUT_ALPHA_NUMERIC">Alpha Numeric</Option>
                                        <Option key={5} value="INPUT_SINGLE_LINE_TEXT">Single Line Text</Option>
                                      </Select>
                                    </div>
                                    <SpError>
                                      {simpleValidator.current.message(`type${j + 1}`, customerDetailsArray[j].type, 'required',
                                        { messages: { required: 'Please select field type' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                    <Input
                                      placeholder={getPlaceholder(item.type || '')}
                                      value={item.label}
                                      maxlength="100"
                                      onChange={(e) => {
                                        const list = [...customerDetailsArray];
                                        list[j].label = e.currentTarget.value;
                                        list[j].name = e.currentTarget.value;
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                    <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                    <SpError>
                                      {simpleValidator.current.message(`Label${j + 1}`, customerDetailsArray[j].label, 'required',
                                        { messages: { required: 'The label field is required' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                    <Checkbox
                                      className="ml-4"
                                      disabled={item.disableRequired || false}
                                      checked={!(item.required)}
                                      onChange={({ target: { checked } }) => {
                                        const list = [...customerDetailsArray];
                                        list[j].required = !checked;
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                {
                                  (!item.disableRequired) ? (
                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={() => removeCustomerDetails(j)}
                                        >
                                          {'\u2212'}&nbsp;Remove Input Field
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  ) : ''
                                }
                              </p>
                            </div>
                          ))
                        }
                      </ReactDragListView>
                      <Row className="mt-4">
                        <Col span={16} push={8}>
                          <SpButton
                            type="secondary"
                            style={{ width: '50%' }}
                            onClick={addCustomerDetails}
                          >
                            {'\u002B'}&nbsp;Add Another Input Field
                          </SpButton>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {
                    current === 3 && (
                      <AccontSlug
                        ref={refArray[0]}
                        selectedAccount={selectedAccount}
                        pageData={pageData}
                        isIndia={isIndia}
                        accountSlug={accountSlug}
                        submitting={submitting}
                        newSlug={newSlug}
                        withoutSlug={withoutSlug}
                      />
                    )
                  }
                  {
                    current === 4 && (
                      <ReviewEventPage
                        pageData={pageData}
                        selectedAccount={selectedAccount}
                      />
                    )
                  }
                </div>
                <div className="d-flex justify-content-end mt-auto mr-4">
                  {current > 0 && (
                    <SpButton type="secondary" className="mr-4" onClick={prev}>
                      Back
                    </SpButton>
                  )}
                  {current < steps.length - 1 && (
                    <SpButton type="primary" onClick={next}>
                      Next
                    </SpButton>
                  )}
                  {current === steps.length - 1 && (
                    <SpButton type="primary" onClick={next} disabled={disableButton}>
                      Create
                    </SpButton>
                  )}
                </div>
              </PaymentFormWrapper>
            </PaymentWrapper>
          </Layout>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  eventDetails: state.eventPageDetails,
  addSeatedEvent: state.eventDetails.addSeatedEvent,
  eventDetailsStatus: state.eventPageDetails.eventDetailsStatus,
  accountSlug: state.accountSlug.content,
  inventoryItems: state.inventoryItems.sortItemsList.content ? state.inventoryItems.sortItemsList.content : [],
  sortCategoryList: state.inventoryCategories.sortCategoryList.content ? state.inventoryCategories.sortCategoryList.content : [],
  modifierItemsList: state.inventoryItems.modifierItemsList.content ? state.inventoryItems.modifierItemsList.content : [],
});

const mapDispatchToProps = (dispatch) => ({
  addEventPage: param => dispatch(eventPageActions.addEventPage({
    payload: param,
  })),
  fetchEventPageDetails: gid => dispatch(eventPageDetailsActions.fetchEventPageDetails({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
  fetchInventorySortCategory: param => dispatch(inventoryCategoriesActions.fetchInventorySortCategory({
    payload: param,
  })),
  fetchModifierItemsList: param => dispatch(inventoryItemsActions.fetchModifierItemsList({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEvents);
