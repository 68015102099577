// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';
import {
  NOTIFICATION,
} from 'store/actionTypes';
import { API_END_POINTS, axiosAuthInstance } from 'api';

import Loading from 'components/Loading';
import {
  fundingSourceActions,
  notificationActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import RBAC from 'components/rbac';
import getFundingSourceStatus from 'utils/getFundingSourceStatus';
import getFundingSourceBackground from 'utils/getFundingSourceBackground';
import DeleteFundingSourceModal from './components/DeleteFundingSource';
// import Deletevendor from './components/DeleteVendor';

type Props = {
  loading: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  fetchFundingSourceDetails: Function,
  fundingSourceDetails: Object,
  history: {
    push: Function,
  },
  DeleteFundingSource: Function,
  setNotification: Funcnction,
}

const FundingSourceDetails = (props: Props) => {
  const {
    loading,
    fetchFundingSourceDetails,
    fundingSourceDetails,
    history,
    DeleteFundingSource,
    setNotification,
  } = props;
  const { gid } = props.match.params;
  const [deleteFundingModal, setDeleteFundingModal] = useState(false);


  const getData = () => {
    if (gid) fetchFundingSourceDetails(gid);
  };


  useEffect(() => {
    getData();
  }, [gid]);


  const showDeleteFundingModal = () => {
    setDeleteFundingModal(true);
  };

  const deleteSelectedFundingSource = (fundingSourceGid: Object) => {
    DeleteFundingSource(fundingSourceGid);
    history.push(`${PAGES.FUNDINGSOURCE}`);
  };

  const reinitiateVerify = async () => {
    const payload = {
      contactGid: fundingSourceDetails?.contact?.gid,
      issuerBankGid: fundingSourceDetails?.issuerBank?.gid,
    };
    try {
      const { data: { entity } } = await axiosAuthInstance.post(API_END_POINTS.ORUM_vERIFICATION, payload);
      console.log(entity);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: 'Verifiction reintiated sucessfully.',
      });
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : '',
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        fundingSourceDetails?.gid && fundingSourceDetails?.issuerBank && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {fundingSourceDetails.contact.name ?? <>&#8211;</>}
                  </SpText>
                  <SpStatusTag style={{ backgroundColor: getFundingSourceBackground(fundingSourceDetails?.issuerBank.status) }}>
                    {getFundingSourceStatus(fundingSourceDetails?.issuerBank.status)}
                  </SpStatusTag>
                </Col>
                <>
                  <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <Col>
                      {
                      fundingSourceDetails?.issuerBank?.status !== 'VERIFIED' ? (
                        <SpButton
                          type="primary"
                          shape="round"
                          className="mr-2"
                          onClick={reinitiateVerify}
                        >
                          Reinitiate verify
                        </SpButton>
                      ) : (
                        <></>
                      )
                    }
                      <SpButton
                        type="danger"
                        shape="round"
                        className="ml-2"
                        onClick={showDeleteFundingModal}
                      >
                        Delete
                      </SpButton>
                    </Col>
                  </RBAC>
                </>
              </Row>
              <Row>
                <SpForm>
                  <Col span={6} className="mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                    <p>Bank Name</p>
                    <SpH5>{fundingSourceDetails?.issuerBank?.bankName ?? <>&#8211;</>}</SpH5>
                  </Col>
                  <Col span={6} className="pl-2 mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                    <p>Account Number</p>
                    <SpH5>{`**** ${fundingSourceDetails?.issuerBank?.lastFour}` ?? <>&#8211;</>}</SpH5>
                  </Col>

                  <Col span={6} className="pl-2 mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                    <p>Business Type</p>
                    <SpH5>{fundingSourceDetails?.issuerBank?.businessType ?? <>&#8211;</>}</SpH5>

                  </Col>
                  <Col span={6} className="pl-2 mb-0">
                    <p>Account Type</p>
                    <SpH5>{fundingSourceDetails?.issuerBank?.accountType ?? <>&#8211;</>}</SpH5>

                  </Col>
                </SpForm>
              </Row>
              {(fundingSourceDetails && fundingSourceDetails.contact.address)
                ? (
                  <>
                    <hr />
                    <Row>
                      <SpForm>
                        <Col span={6} className="pl-2 mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                          <p>Routing Number</p>
                          <SpH5>{fundingSourceDetails?.issuerBank?.routingNumber ?? <>&#8211;</>}</SpH5>
                        </Col>
                        <Col span={6} className="pl-2 mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                          <p>Nick Name</p>
                          <SpH5>{fundingSourceDetails?.nickName ?? <>&#8211;</>}</SpH5>
                        </Col>
                        <Col span={6} className="pl-2 mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                          <p>Account Status</p>
                          <SpH5>
                            {(fundingSourceDetails?.issuerBank?.accountStatus) ? (fundingSourceDetails?.issuerBank?.accountStatus) : <>&#8211;</>}
                          </SpH5>

                        </Col>
                        <Col span={6} className="pl-2 mb-0">
                          <p>Country</p>
                          <SpH5>
                            {(fundingSourceDetails?.contact.address?.country) ? (fundingSourceDetails.contact.address.country.name) : <>&#8211;</>}
                          </SpH5>

                        </Col>
                      </SpForm>
                    </Row>
                  </>
                )
                : ''}
              <Row className="d-flex justify-content-end" style={{ marginTop: '6rem' }}>
                <Col>
                  <SpH5 color="#7f7f7f">{gid}</SpH5>
                </Col>
              </Row>
              {/* DELETE VENDOR MODAL */}
              {deleteFundingModal && (
                <DeleteFundingSourceModal
                  visible={deleteFundingModal}
                  fundingSourceDetails={fundingSourceDetails}
                  close={() => setDeleteFundingModal(false)}
                  submit={deleteSelectedFundingSource}
                />
              )}
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  test: state.account.test,
  fundingSourceDetails: state.fundingSource.fundingSourceDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFundingSourceDetails: gid => dispatch(fundingSourceActions.fetchFundingSourceDetails({
    payload: gid,
  })),
  DeleteFundingSource: param => dispatch(fundingSourceActions.DeleteFundingSource({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(FundingSourceDetails);
