/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';

import {
  Row,
  Col,
  // Steps,
  Layout,
} from 'antd';
import {
  PaymentWrapper,
  PaymentFormWrapper,
} from 'components/DesignKit';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { NOTIFICATION } from 'store/actionTypes';

import {
  notificationActions,
  businessActions,
} from 'store/actions';

import {
  PAGES,
  TOKENS,
  MESSAGES,
} from 'appconstants';
import PaymentClose from 'components/NavIcons/paymentClose';
import LogoNew from 'components/NavIcons/LogoNew';

import PageDetails from './components/PageDetails';
// import ReviewPaymentPage from './components/ReviewPaymentPage';


// const { Step } = Steps;
const { Header } = Layout;

type Props = ContextRouter & {
  selectedAccount: Object,
  validator: boolean,
  loading: boolean,
  history: {
    replace: Function,
  },
  setNotification: Function,
};

const AccountSetup = (props: Props) => {
  const {
    selectedAccount,
    history,
    setNotification,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [current, setCurrent] = useState(0);
  const [pageData, setPageData] = useState({});

  // const steps = [
  //   {
  //     title: 'Business Info',
  //   },
  //   {
  //     title: 'Review',
  //   },
  // ];

  const addBusiness = async (gid, payload) => {
    try {
      const { data: { entity } } = await axiosAuthInstance.post(`${API_END_POINTS.USER_ACCOUNT}/${gid}/business`, payload);
      console.log(entity);
      localStorage.removeItem(TOKENS.ACCESS_TOKEN);
      localStorage.removeItem(TOKENS.REFERRAL_CODE);
      localStorage.removeItem('dynamicStaticQr');
      localStorage.removeItem('fcmToken');
      history.replace(PAGES.LOGIN);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.BUSINESS.ADD_SUCCESS,
      });
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : 'Unable to process the request',
      });
    }
  };

  const next = (Data) => {
    const payload = Data;
    setPageData(Data);
    addBusiness(selectedAccount.gid, payload);
    // if (current === 0) {
    //   setCurrent(1);
    //   setPageData(Data);
    // } else if (current === 1) {
    //   const payload = pageData;
    //   addBusiness(selectedAccount.gid, payload);
    // }
  };

  // const previous = () => {
  //   setCurrent(0);
  // };

  const backtoLogin = () => {
    localStorage.removeItem(TOKENS.ACCESS_TOKEN);
    localStorage.removeItem(TOKENS.REFERRAL_CODE);
    localStorage.removeItem('dynamicStaticQr');
    localStorage.removeItem('fcmToken');
    history.replace(PAGES.LOGIN);
  };

  return (
    <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
      <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
        <Row type="flex" justify="space-between">
          <Col className="d-flex align-items-center">
            <LogoNew />
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <span style={{ cursor: 'pointer' }} onClick={() => backtoLogin()}>
              <PaymentClose />
            </span>
          </Col>
        </Row>
      </Header>
      <PaymentWrapper>
        {/* <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps> */}
        <PaymentFormWrapper style={{ width: current === 3 ? '964px' : '800px' }} className="mt-3">
          <div>
            <PageDetails
              selectedAccount={selectedAccount}
              pageData={pageData}
              next={next}
              history={history}
            />
            {/* {current === 0 && (
              <PageDetails
                selectedAccount={selectedAccount}
                pageData={pageData}
                next={next}
              />
            )}
            {
              current === 1 && (
                <ReviewPaymentPage
                  pageData={pageData}
                  next={next}
                  selectedAccount={selectedAccount}
                  previous={previous}
                />
              )
            } */}
          </div>
        </PaymentFormWrapper>
      </PaymentWrapper>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.accounts,
  paymentPagesDetails: state.paymentPagesDetails,
  paymentPagesDetailsStatus: state.paymentPagesDetails.paymentPagesDetailsStatus,
  accountSlug: state.accountSlug.content,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  addBusinessSignUp: (gid, param) => dispatch(businessActions.addBusinessSignUp({
    gid,
    param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetup);
