// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import getStateList from 'utils/stateList';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getVendorType from 'utils/getVendorType';
import getTransferBackground from 'utils/getTransferBackground';
import getTransferStatus from 'utils/getTransferStatus';
import {
  Card,
  Table,
  Row,
  Col,
  Menu,
  Skeleton,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
  SpTab,
} from 'components/DesignKit';
import debounce from 'lodash.debounce';

import Loading from 'components/Loading';
import {
  fundingSourceActions,
  vendorsActions,
  vendorsDetailsActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
  COUNTRIES,
} from 'appconstants';
import RBAC from 'components/rbac';
import CONSTANTS from 'store/constants';
import getFundingSourceStatus from 'utils/getFundingSourceStatus';
import getFundingSourceBackground from 'utils/getFundingSourceBackground';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import BusinessIcon from '../../../assets/imgs/Business_icon.png';
import IndividualIcon from '../../../assets/imgs/Individual_icon.png';
import Deletevendor from './components/DeleteVendor';
import EditVendor from '../VendorsList/components/EditVendor';

type Props = {
  fetchVendorDetails: Function,
  fetchTransfers: Function,
  vendorsDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  transfers: Array<Object>,
  fundingSourceListByContactgid:Array<Object>,
  totalElements: number,
  totalElementsFundingSource: number,
  vendorsDetailsStatus: string,
  validator: boolean,
  DeleteVendor: Function,
  fetchFundingSourceListByContactGid: Function,
  skeltonLoading: boolean,
  updateVendor: Function,
}

const VendorsDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchVendorDetails,
    fetchTransfers,
    vendorsDetails,
    loading,
    submitting,
    history,
    transfers,
    totalElements,
    selectedAccount,
    vendorsDetailsStatus,
    validator,
    DeleteVendor,
    fetchFundingSourceListByContactGid,
    fundingSourceListByContactgid,
    skeltonLoading,
    totalElementsFundingSource,
    updateVendor,
  } = props;
  const { gid } = props.match.params;
  const path = props.match.url;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [pagination, setPagination] = useState({});
  const [paginationFundingSource, setPaginationFundingSource] = useState({});
  const [pageNoFundingSource, setPageNoFundingSource] = useState(1);
  const [deleteVendorModal, setDeleteVendorModal] = useState(false);
  const [redirect, SetRedirect] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [phoneFormate, setPhoneFormate] = useState('');
  const [current, setCurrent] = useState('transferSummary');
  const [tabChange, setTabChange] = useState(false);
  const [editVendorModal, setEditVendorModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [zipTag, setZipTag] = useState('');


  const debouncedFetchFundingSourceListByContactGid = debounce(fetchFundingSourceListByContactGid, 2000, { leading: true });

  const getData = (query) => {
    fetchVendorDetails(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchTransfers({ currentPage, sortParams, gid });
  };

  const selectedRecordTransfer = (record) => {
    const searchParams = record.gid;
    history.push(`${PAGES.TRANSFERS}/${searchParams}`);
  };

  const selectedRecordFundingSource = (record) => {
    const searchParams = record.gid;
    history.push(`${PAGES.FUNDINGSOURCE_DETAILS}/${searchParams}`);
  };
  /**
   * Get data on page load
   */

  useEffect(() => {
    if (current === 'fundingSource' && gid) {
      debouncedFetchFundingSourceListByContactGid({ contactGid: gid, pageNo: pageNoFundingSource });
    }
  }, [current]);
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.VENDORS);
      setTabChange(false);
    }
    if (!validator && !redirect) {
      setEditVendorModal(false);
      setTabChange(false);
      getData();
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    if (!tabChange) {
      getData(location.search);
    }
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [location, selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);


  useEffect(() => {
    if (vendorsDetails.gid) {
      const phone = vendorsDetails.phoneNumber ? vendorsDetails.phoneNumber : '';
      const cntryCode = vendorsDetails.phoneNumber ? vendorsDetails.phoneNumber.substring(0, 2) : '';
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (phone.length !== 14 && cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneFormate('+#-##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 14) {
        setPhoneFormate('+###-##########');
      }
    }
  }, [vendorsDetails]);


  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [transfers]);
  useEffect(() => {
    const currentPage = pageNoFundingSource || 1;
    setPaginationFundingSource(existingElements => ({
      ...existingElements,
      total: totalElementsFundingSource,
      current: parseInt(currentPage, 10),
      pageSize: 10,
      showTotal: total => `Total ${total} FundingSources`,
    }));
  }, [fundingSourceListByContactgid]);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.VENDORS}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChangeFundingSource = (page, sorter, filters) => {
    const tempPage = page.current;
    setPageNoFundingSource(page.current);
    debouncedFetchFundingSourceListByContactGid({ contactGid: gid, pageNo: tempPage });

    // let tempParams = '';
    // if (filters.order === 'ascend') {
    //   tempParams = `&sortBy=${filters.field}&direction=ASC`;
    // } else if (filters.order === 'descend') {
    //   tempParams = `&sortBy=${filters.field}&direction=DESC`;
    // } else {
    //   tempParams = '';
    // }
    // history.push({
    //   pathname: `${PAGES.VENDORS}/${gid}`,
    //   search: `?pageNum=${tempPage}${tempParams}`,
    // });
  };
  const showDeleteVendorModal = () => {
    setDeleteVendorModal(true);
  };

  const deleteSelectedVendor = async (vendorGid: string) => {
    DeleteVendor(vendorGid);
    SetRedirect(true);
  };

  const editSelectedVendor = (contact: Object) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    // `gid` is directly destructured from the `contact` object

    const {
      name,
      email,
      customerPhone,
      // eslint-disable-next-line no-shadow
      gid,
      countryCode,
      contactType,
      customType,
      streetName,
      cityName,
      stateName,
      zipCode,
      phoneNumberCode,
    } = contact;

    const params = {
      name,
      contactType,
      customType,
      email,
      phoneNumber: `${phoneNumberCode}${customerPhone}`,
      address: {
        street: streetName,
        city: cityName,
        state: stateName,
        postalCode: zipCode,
        countryCode,
      },
    };
    updateVendor({
      currentPage,
      sortParams,
      gid,
      params,
    });
  };

  const utrColumns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <span>
            {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferBackground(status) }}>
            {getTransferStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'FUNDING SOURCE',
      dataIndex: 'fundingSource',
      width: '15%',
      align: 'left',
      render: (fundingSource, record) => {
        const bankName = ((record.fundingSource) && (record.fundingSource.issuerBank) && (record.fundingSource.issuerBank.bankName));
        const lastFour = ((record.fundingSource) && (record.fundingSource.issuerBank) && (record.fundingSource.issuerBank.lastFour));
        if ((bankName) && (lastFour)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`${bankName} ****${lastFour}`}
            </span>
          );
        } if (lastFour) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`****${lastFour}`}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'UTR',
      dataIndex: 'uniqueReferenceNumber',
      width: '15%',
      align: 'left',
      render: (uniqueReferenceNumber, record) => {
        if ((record.neftTransfer) && (record.neftTransfer.utrNumber)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(record.neftTransfer.utrNumber)}
            </span>
          );
        } if ((record.rtgsTransfer) && (record.rtgsTransfer.utrNumber)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(record.rtgsTransfer.utrNumber)}
            </span>
          );
        } if ((record.impsTransfer) && (record.impsTransfer.utrNumber)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(record.impsTransfer.utrNumber)}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'TRANSFER DATE',
      dataIndex: 'transferDate',
      width: '15%',
      align: 'left',
      render: (transferDate) => {
        if (transferDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(transferDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <span>
            {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferBackground(status) }}>
            {getTransferStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'FUNDING SOURCE',
      dataIndex: 'fundingSource',
      width: '15%',
      align: 'left',
      render: (fundingSource, record) => {
        const bankName = ((record.fundingSource) && (record.fundingSource.issuerBank) && (record.fundingSource.issuerBank.bankName));
        const lastFour = ((record.fundingSource) && (record.fundingSource.issuerBank) && (record.fundingSource.issuerBank.lastFour));
        if ((bankName) && (lastFour)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`${bankName} ****${lastFour}`}
            </span>
          );
        } if (lastFour) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`****${lastFour}`}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'TRANSFER DATE',
      dataIndex: 'transferDate',
      width: '20%',
      align: 'left',
      render: (transferDate) => {
        if (transferDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(transferDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];
  const fundingSourceColumns = [
    {
      title: 'ACCOUNT NAME',
      dataIndex: 'name',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const name = record?.name ? record?.name : (record?.contact?.name ? record?.contact?.name : <>&#8211;</>);
        return (
          <span>
            {name}
          </span>
        );
      },
    },
    {
      title: 'FUNDING SOURCE',
      dataIndex: 'fundingSource',
      width: '15%',
      align: 'left',
      render: (fundingSource, record) => {
        const bankName = ((record.issuerBank) && (record.issuerBank.bankName));
        const lastFour = ((record.issuerBank) && (record.issuerBank.lastFour));
        const icon = record?.issuerBank?.businessType === 'INDIVIDUAL'
          ? <img src={IndividualIcon} alt="individual" style={{ width: '15px', height: '15px' }} />
          : <img src={BusinessIcon} alt="business" style={{ width: '15px', height: '15px' }} />;
        if ((bankName) && (lastFour)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {bankName} ****${lastFour} {icon}
            </span>
          );
        } if (lastFour) {
          return (
            <span style={{ cursor: 'pointer' }}>
              ****{lastFour} {icon}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'BUSINESS TYPE',
      dataIndex: 'contactType',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const contactType = record?.contactType ? record?.contactType : (record?.contact?.contactType ? record?.contact?.contactType : <>&#8211;</>);
        return (
          <span>
            {contactType}
          </span>
        );
      },
    },
    {
      title: 'ACCOUNT TYPE',
      dataIndex: 'issuerBank',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const accountType = record?.issuerBank?.accountType ?? <>&#8211;</>;
        return (
          <span>
            {accountType}
          </span>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        const status = record?.status ? record?.status : (record?.contact?.status ? record?.contact?.status : <>&#8211;</>);
        return (
          <span>
            <SpStatusTag style={{ backgroundColor: getFundingSourceBackground(status) }}>
              {getFundingSourceStatus(status)}
            </SpStatusTag>
          </span>
        );
      },
    },

  ];
  const balanceUpdate = (val) => {
    setCurrent(val);
    setTabChange(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        vendorsDetails.gid && vendorsDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {vendorsDetails.name || <>&#8211;</>}
                  </SpText>
                  <SpStatusTag style={{ backgroundColor: getFundingSourceBackground(vendorsDetails?.status) }}>
                    {getFundingSourceStatus(vendorsDetails?.status)}
                  </SpStatusTag>
                </Col>
                <>
                  <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <Col>
                      <SpButton
                        shape="round"
                        type="secondary"
                        onClick={() => setEditVendorModal(true)}
                      >
                        Edit
                      </SpButton>
                      <SpButton
                        type="danger"
                        shape="round"
                        className="ml-2"
                        onClick={showDeleteVendorModal}
                      >
                        Delete Contact
                      </SpButton>
                    </Col>
                  </RBAC>
                </>
              </Row>
              <Row>
                <SpForm>
                  <Col span={6} className="mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                    <p>Email</p>
                    <SpH5>{vendorsDetails.email || <>&#8211;</>}</SpH5>
                  </Col>
                  <Col span={6} className="pl-2 mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                    <p>Phone</p>
                    {vendorsDetails.phoneNumber ? (
                      <NumberFormat
                        className="numFormate"
                        value={vendorsDetails.phoneNumber}
                        format={phoneFormate}
                        mask="_"
                        disabled
                      />
                    ) : <>&#8211;</>}
                  </Col>

                  <Col span={6} className="pl-2 mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                    <p>Created on</p>
                    {
                      vendorsDetails.createdAt && selectedAccount.timezone ? (
                        <SpH5>
                          {moment
                            .utc(vendorsDetails.createdAt)
                            .tz(selectedAccount.timezone)
                            .format('MMM DD, YYYY')}
                        </SpH5>
                      ) : (
                        <>&#8211;</>
                      )
                    }
                  </Col>
                  <Col span={6} className="pl-2 mb-0">
                    <p>Type</p>
                    {vendorsDetails.contactType
                      ? <SpH5>{getVendorType(vendorsDetails.contactType)}</SpH5>
                      : <>&#8211;</>}
                    {vendorsDetails.customType && vendorsDetails.contactType === 'OTHER'
                      ? <p>{vendorsDetails.customType}</p>
                      : null}

                  </Col>
                </SpForm>
              </Row>
              {(vendorsDetails && vendorsDetails.address)
                ? (
                  <>
                    <hr />
                    <Row>
                      <SpForm>
                        <Col span={6} className="mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                          <p>Street</p>
                          <SpH5>
                            {(vendorsDetails.address && vendorsDetails.address.street) || <>&#8211;</>}
                          </SpH5>
                        </Col>
                        <Col span={6} className="pl-2 mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                          <p>City</p>
                          <SpH5>
                            {(vendorsDetails.address && vendorsDetails.address.city) || <>&#8211;</>}
                          </SpH5>
                        </Col>

                        <Col span={6} className="pl-2 mb-0" style={{ borderRight: '2px solid #DBDBDB' }}>
                          <p>State</p>
                          <SpH5>
                            {(vendorsDetails.address && vendorsDetails.address.state) || <>&#8211;</>}
                          </SpH5>
                        </Col>
                        <Col span={6} className="pl-2 mb-0">
                          <p>Country</p>
                          <SpH5>
                            {(vendorsDetails.address && vendorsDetails.address.country) ? (vendorsDetails.address.country.name) : <>&#8211;</>}
                          </SpH5>

                        </Col>
                      </SpForm>
                    </Row>
                  </>
                )
                : ''}
              <SpForm style={{ marginTop: '2rem' }}>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Payment Terms</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{vendorsDetails.paymentTermsDays || <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>TDS Category</span>
                  </Col>
                  <Col span={9}>
                    <SpH5><>&#8211;</></SpH5>
                  </Col>
                </Row>
              </SpForm>
              <Row className="d-flex justify-content-end">
                <Col>
                  <SpH5 color="#7f7f7f">{gid}</SpH5>
                </Col>
              </Row>
              {/* DELETE VENDOR MODAL */}
              {deleteVendorModal && (
                <Deletevendor
                  visible={deleteVendorModal}
                  vendorsDetails={vendorsDetails}
                  submitting={submitting}
                  close={() => setDeleteVendorModal(false)}
                  submit={deleteSelectedVendor}
                />
              )}
              {/* EDIT VENDOR MODAL */}
              {editVendorModal && (
              <EditVendor
                visible={editVendorModal}
                selectedVendor={vendorsDetails}
                phoneCode={phoneCode}
                submitting={submitting}
                country={selectedAccount.country.name}
                selectedAccount={selectedAccount}
                statesInCountry={statesInCountry}
                zipTag={zipTag}
                close={() => setEditVendorModal(false)}
                submit={editSelectedVendor}
              />
              )}
            </Card>
            <Card className="pl-2">
              <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '15px' }}>
                <Menu.Item key="transferSummary" className="pl-2">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'transferSummary'}
                    onClick={() => balanceUpdate('transferSummary')}
                  >
                    Transfer Summary
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="fundingSource">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'fundingSource'}
                    onClick={() => balanceUpdate('fundingSource')}
                  >
                    Funding Source
                  </SpTab>
                </Menu.Item>
              </Menu>
              {current === 'transferSummary' && (
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecordTransfer(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={isIndia ? utrColumns : columns}
                rowKey={(record) => record.gid}
                dataSource={transfers}
                pagination={pagination}
                onChange={handleTableChange}
              />
              )}
              {current === 'fundingSource' && (
                skeltonLoading ? (
                  <Skeleton active />
                ) : (
                  <Table
                    onRow={(record) => ({
                      onClick: () => {
                        selectedRecordFundingSource(record);
                      },
                    })}
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={fundingSourceColumns}
                    rowKey={(record) => record.gid}
                    dataSource={fundingSourceListByContactgid}
                    pagination={paginationFundingSource}
                    onChange={handleTableChangeFundingSource}
                  />
                )
              )}
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  vendorsDetails: state.vendorsDetails,
  vendorsDetailsStatus: state.vendorsDetails.vendorsDetailsStatus,
  transfers: state.vendorsDetails.transfers.content,
  totalElements: state.vendorsDetails.transfers ? state.vendorsDetails.transfers.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  fundingSourceListByContactgid: state.fundingSource.fundingSourceListByContactgid.content,
  skeltonLoading: state.loading.skeleton,
  totalElementsFundingSource: state.fundingSource.fundingSourceListByContactgid.totalElements,


});

const mapDispatchToProps = (dispatch) => ({
  fetchVendorDetails: gid => dispatch(vendorsDetailsActions.fetchVendorDetails({
    payload: gid,
  })),
  fetchFundingSourceListByContactGid: gid => dispatch(fundingSourceActions.fetchFundingSourceListByContactGid({
    payload: gid,
  })),
  fetchTransfers: param => dispatch(vendorsDetailsActions.fetchTransfers({
    payload: param,
  })),
  DeleteVendor: param => dispatch(vendorsDetailsActions.DeleteVendor({
    payload: param,
  })),
  updateVendor: param => dispatch(vendorsActions.updateVendor({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(VendorsDetails);
