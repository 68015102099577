// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Icon,
  AutoComplete,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import {
  TAX_RATE_TYPES,
  TAX_RATE_REGION,
  MAX_AMOUNT,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  allTaxes: Array<Object>,
  fetchTaxDetails: Function,
};

const AddTaxRate = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    allTaxes,
    fetchTaxDetails,
  } = props;

  const [, forceUpdate] = useState();
  const [displayName, setDisplayName] = useState('');
  const [jurisdiction, setJurisdiction] = useState('');
  const [percentage, setPercentage] = useState(null);
  const [inclusive, setInclusive] = useState();
  const [description, setDescription] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [isDefault, setDefault] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      taxrate: {
        message: MAX_AMOUNT.MESSAGE_TAX,
        rule: (val) => MAX_AMOUNT.LIMIT_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const updateTaxType = (e) => {
    setDisplayName(e);
  };

  const searchTax = (value) => {
    fetchTaxDetails(value);
    setDisplayName(value);
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      description,
      isDefault,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Tax Rate"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <AutoComplete
            placeholder="Tax name"
            showSearch
            className="w-100"
            dataSource={allTaxes.map((item) => (
              <Option key={item.id} value={item.type}>
                {item.type}
              </Option>
            ))}
            style={{ height: '40px' }}
            onSearch={searchTax}
            defaultValue={displayName}
            onSelect={(e) => updateTaxType(e)}
            prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.5)' }} />}
          >
            <Input.Search enterButton />
          </AutoComplete>
          <SpError>
            {simpleValidator.current.message('name', displayName, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Region</SpH5></label>
          <Select
            className="w-100"
            placeholder="Select Region"
            onChange={(e) => setJurisdiction(e)}
          >
            {
              TAX_RATE_REGION.map(i => (
                <Option
                  key={1}
                  value={i.value}
                  className="text-capitalize"
                >
                  {i.display}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('region', jurisdiction, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label className="d-block"><SpH5>Rate</SpH5></label>
          <div className="d-flex">
            <div className="w-50 mr-3">
              <Input
                value={percentage}
                onChange={(e) => setPercentage(e.currentTarget.value)}
                suffix="%"
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('rate', percentage, 'required|taxrate')}
              </SpError>
            </div>
            <div className="w-50">
              <Select
                className="w-100"
                placeholder="Select Type"
                onChange={(e) => setInclusive(e)}
              >
                {
                  TAX_RATE_TYPES.map(j => (
                    <Option
                      key={1}
                      value={j.value}
                      className="text-capitalize"
                    >
                      {j.display}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('type', inclusive, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <Input
            placeholder="description"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('description', description, 'required')}
          </SpError>
        </div>
        <div className="pb-3">
          <Switch
            className="mr-2"
            checked={isDefault}
            onChange={() => setDefault(!isDefault)}
          />
          <SpH5>POS Default</SpH5>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddTaxRate);
