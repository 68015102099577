/* eslint-disable no-unused-vars */
// @flow
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Icon,
  Select,
  Menu,
  notification,
  Collapse,
} from 'antd';
import { QRCode } from 'react-qrcode-logo';
// import QRCode from 'react-qr-code';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
// import { toPng } from 'html-to-image';

import styled from 'styled-components';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
  SpTab,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  eventPageDetailsActions,
  notificationActions,
  accountSlugActions,
  inventoryShopsActions,
  serviceRatesActions,
  inventoryItemsActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  API_STATUS,
  COUNTRIES,
  RATE_TYPES,
} from 'appconstants';

import RBAC from 'components/rbac';
import getEventsBackground from 'utils/getEventsBackground';
import getEventsStatus from 'utils/getEventsStatus';
import formatNumber from 'utils/formatNumber';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import Clipboard from 'components/NavIcons/copyclipboard';
import AddPromo from 'routes/InventoryOrders/components/ShopsDetails/components/AddPromo';
import EditPromoCode from 'routes/InventoryOrders/components/ShopsDetails/components/EditPromo';
import DeletePromoCode from 'routes/InventoryOrders/components/ShopsDetails/components/DeletePromoCode';
import AddRemoveTaxes from 'routes/InventoryOrders/components/ShopsDetails/components/AddRemoveTaxes'; // For Tax Rates
import AddRemoveTaxRates from 'routes/InventoryOrders/components/ShopsDetails/components/AddRemoveTaxRates'; // For Service Fee
import RemoveTaxRates from 'routes/InventoryOrders/components/ShopsDetails/components/RemoveTaxRates'; // For Service Fee
import AddNewTip from 'routes/InventoryOrders/components/ShopsDetails/components/AddNewTip';
import DeleteTip from 'routes/InventoryOrders/components/ShopsDetails/components/DeleteTip';
import CustomTemplateUpload from './components/CustomTemplateUploadModal';
import RemoveCustomTemplate from './components/RemoveCustomTemplate';
import Qrlogo from '../../../assets/imgs/SwirepayQrlogo.png';
import { API_END_POINTS, axiosAuthInstance } from '../../../api';
import { SpColor } from '../../../components/PaymentWidgetDesignKit';
import getOrdersPaymentBackground from '../../../utils/getOrdersPaymentBackground';
import getOrdersPaymentStatus from '../../../utils/getOrdersPaymentStatus';
import getRedeemStatus from '../../../utils/getRedeemStatus';
import getRedeemBackground from '../../../utils/getRedeemBackground';
import getRedeemFontColor from '../../../utils/getRedeemFontColor';

const { Panel } = Collapse;


const { Option } = Select;

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: '#434343',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  fetchEventPageDetails: Function,
  fetchEventPageProductItemDetails: Function,
  fetchEventPageOrderPaymentData: Function,
  fetchEventPageCustomTemplates: Function,
  removeCustomTemplateEventPage: Function,
  fetchAccountSlug: Function,
  setNotification: Function,
  eventDetails: Object,
  loading: boolean,
  submitting: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  eventPageOrderPayments: Array<Object>,
  eventPageProductItems: Array<Object>,
  eventPageCustomTemplates: Array<Object>,
  totalElements: number,
  eventDetailsStatus: string,
  soldOutEventPage: Function,
  deactivateEventPage: Function,
  resumeSalesNon: Function,
  validator: boolean,
  accountSlug: Array<Object>,
  eventPageSlug: Array,
  linkAccountSlug: Function,
  unLinkAccountSlug: Function,
  fetchOfflineEventPagesPayments: Function,
  fetchEventPagesSlug: Function,
  clearEventPageDetails: Function,
  eventPagePromosList: Array,
  isMobileView: Boolean,
  updateEventPage: Function,
  categoryList: Array<Object>,
  inventoryItems: Array,
  fetchPromosList: Function,
  fetchPromosByEventPage: Function,
  fetchAllPromosByEventPage: Function,
  promosList: Array,
  allEventPagePromosList: Array,
  addPromosByEventPage: Function,
  updatePromosByEventPage: Function,
  deletePromosByEventPage: Function,
  fetchShopRates: Function,
  taxRatesList: Array<Object>,
  fetchServicerates: Function,
  serviceFeeList: Array<Object>,
  fetchItemsList: Function,
  fetchInventorySortItems: Function,
}

const EventsDetails = (props: Props) => {
  const {
    fetchEventPageDetails,
    fetchEventPageProductItemDetails,
    fetchEventPageOrderPaymentData,
    fetchAccountSlug,
    setNotification,
    eventDetails,
    loading,
    submitting,
    history,
    eventPageOrderPayments,
    eventPageProductItems,
    totalElements,
    selectedAccount,
    eventDetailsStatus,
    soldOutEventPage,
    deactivateEventPage,
    resumeSalesNon,
    validator,
    accountSlug,
    eventPageSlug,
    linkAccountSlug,
    unLinkAccountSlug,
    fetchOfflineEventPagesPayments,
    eventPageCustomTemplates,
    fetchEventPageCustomTemplates,
    removeCustomTemplateEventPage,
    fetchEventPagesSlug,
    clearEventPageDetails,
    eventPagePromosList,
    isMobileView,
    updateEventPage,
    categoryList,
    inventoryItems,
    fetchPromosList,
    fetchPromosByEventPage,
    fetchAllPromosByEventPage,
    promosList,
    allEventPagePromosList,
    addPromosByEventPage,
    updatePromosByEventPage,
    deletePromosByEventPage,
    fetchShopRates,
    taxRatesList,
    fetchServicerates,
    serviceFeeList,
    fetchItemsList,
    fetchInventorySortItems,
  } = props;

  const env = process.env.REACT_APP_ENV;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const { gid } = props.match.params;
  const { id } = selectedAccount && selectedAccount.currency;
  const [pagination, setPagination] = useState({});
  const gidPath = gid.split('-')[1];
  const [phoneCode, setPhoneCode] = useState('');
  const path = props.match.url;
  const currencyType = selectedAccount && selectedAccount.currency;

  const [eventPageTab, setEventPageTab] = useState('Manage');

  const [current, setCurrent] = useState('ProductsTab');

  // Promos Tab
  const [addPromoModal, setAddPromoModal] = useState(false);
  const [editPromoModal, setEditPromoModal] = useState(false);
  const [deletePromoModal, setDeletePromoModal] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState({});

  // Tax Rates
  const [editTaxModal, setEditTaxModal] = useState(false);

  // Service Fees Tab
  const [editServiceFeeModal, setEditServiceFeeModal] = useState(false);
  const [deleteServiceFeeModal, setDeleteServiceFeeModal] = useState(false);
  const [selectedServiceFee, setSelectedServiceFee] = useState({});

  // Tips Tab
  const [addTipModal, setAddTipModal] = useState(false);
  const [deleteTip, setDeleteTip] = useState(false);
  const [deleteTipData, setDeleteTipData] = useState([]);
  const [tipType, setTipType] = useState('PERCENTAGE');
  const [tipAmount, setTipAmount] = useState(null);

  // Custom Template Tab
  const [addCustomTemplateModal, setAddCustomTemplateModal] = useState(false);
  const [deleteCustomTemplateModal, setDeleteCustomTemplateModal] = useState(false);
  const [deleteCustomTemplateModalData, setDeleteCustomTemplateModalData] = useState({});

  const [currentSales, setCurrentSales] = useState('payments');

  const qrCodeRef = useRef(null);
  const [slugLink, setSlugLink] = useState();
  const [slugGid, setSlugGid] = useState();
  const [enableUnlink, setEnableUnlink] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { prefix } = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [selectedPaymentType, setSelectedPaymentType] = useState('All');

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const direction = parsed.direction || 'DESC';
    const params = `&sortBy=${parsed.sortBy || 'createdAt'}&direction=${direction}`;
    const size = 1000;
    fetchEventPageDetails(gid);
    fetchEventPagesSlug(gid);
    fetchAccountSlug({ currentPage, params, size });
    fetchAllPromosByEventPage(gid);

    if (current === 'taxesTab') {
      fetchShopRates({ currentPage, params });
    }
    if (current === 'serviceTab') {
      fetchServicerates({ currentPage, params, pageSize: 'size=100' });
    }
    if (current === 'promosTab') {
      fetchPromosByEventPage({ gid, currentPage, params });
      fetchPromosList({ currentPage, params });
      fetchItemsList({ currentPage, params });
      fetchInventorySortItems({ currentPage, params });
    }
    if (current === 'customTemplateTab') {
      fetchEventPageCustomTemplates({ gid, currentPage, params });
    }
  };

  const getPaymentData = (query) => {
    const parsed = queryString.parse(query);
    let onlinePayment = '';
    let pageLimit;
    if (selectedPaymentType !== 'All') {
      onlinePayment = selectedPaymentType === 'Online';
      if (onlinePayment) {
        pageLimit = Math.max(1, Math.ceil(eventDetails.totalOnlineSales / 10));
      } else {
        pageLimit = Math.max(1, Math.ceil(eventDetails.totalOfflineSales / 10));
      }
    } else {
      pageLimit = Math.max(1, Math.ceil((eventDetails.totalOnlineSales + eventDetails.totalOfflineSales) / 10));
    }
    let currentPage = parsed.pageNum || 1;
    currentPage = pageLimit && currentPage < pageLimit ? currentPage : pageLimit;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchEventPageOrderPaymentData({
      gid, currentPage, sortParams, onlinePayment,
    });
  };

  const getOfflineData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchOfflineEventPagesPayments({ gid, currentPage, sortParams });
  };

  const copyCode = (record) => {
    let url;
    if (env === 'Production') {
      url = `https://secure.swirepay.com/event-page/${record.gid}`;
    } else if (env === 'Staging') {
      url = `https://staging-secure.swirepay.com/event-page/${record.gid}`;
    } else {
      url = `http://localhost:4000/event-page/${record.gid}`;
    }
    navigator.clipboard.writeText(url);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: 'Event url copied to clipboard!',
    });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData(gid);
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [current, selectedAccount]);

  useEffect(() => {
    if (current === 'ProductsTab'
        && eventDetails
        && eventDetails.eventPageProducts
        && eventDetails.eventPageProducts.length !== 0) {
      const payload = eventDetails.eventPageProducts.map((product) => ({
        gid: product.gid,
        eventPageGid: gid,
        available: [true, false],
        inventoryCategoryGids: [
          ...(product.inventoryCategories?.map((category) => category.gid) || []),
        ],
        itemGids: product.items?.map((item) => item.gid) || [],
        customItems: product.customItems?.map((customItem) => ({
          gid: customItem.gid,
          inputFieldLabel: customItem.inputFieldLabel,
          amount: customItem.amount,
          inventoryCategoryGids: [
            ...(customItem.inventoryCategories?.map((category) => category.gid) || []),
          ],
          itemGids: customItem.items?.map((item) => item.gid) || [],
        })) || [],
      }));
      fetchEventPageProductItemDetails(payload);
    }
  }, [eventDetails?.eventPageProducts]);


  useEffect(() => {
    if (currentSales === 'payments') {
      getPaymentData(location.search);
    } else if (currentSales === 'offlineData') {
      getOfflineData(location.search);
    }
  }, [selectedPaymentType, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [eventPageOrderPayments]);

  useEffect(() => {
    if (eventPageSlug && eventPageSlug.length > 0) {
      setEnableUnlink(true);
      setSlugLink(eventPageSlug[0].slugName);
      setSlugGid(eventPageSlug[0].gid);
    } else {
      setEnableUnlink(false);
      setSlugLink('');
      setSlugGid('');
    }
  }, [eventPageSlug]);

  const linkSlug = () => {
    const params = {
      type: 'EVENT_PAGE',
      spObjectGid: gid,
      forceSet: false,
    };
    linkAccountSlug({ slugGid, params });
    setRedirect(true);
  };

  const unlinkSlug = async () => {
    unLinkAccountSlug(slugGid);
    setRedirect(true);
  };

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.EVENT_PAGE_DASHBOARD);
    }
  }, []);

  useEffect(() => {
    if (redirect && !validator) {
      setEditTaxModal(false);
      setEditServiceFeeModal(false);
      setDeleteServiceFeeModal(false);
      setAddPromoModal(false);
      setDeletePromoModal(false);
      setEditPromoModal(false);
      setAddTipModal(false);
      setAddCustomTemplateModal(false);
      setDeleteTip(false);
    }
  }, [validator]);

  useEffect(() => {
    if (redirect && !submitting) {
      if (eventDetails) {
        if (eventDetails.isSeatedEvent) {
          history.push(PAGES.SEATED_EVENTS);
        } else {
          history.push(PAGES.NON_SEATED_EVENTS);
        }
      } else {
        history.push(PAGES.EVENT_PAGE_DASHBOARD);
      }
    }
  }, [submitting]);

  const offlinePayment = () => {
    history.push(`${PAGES.EVENT_PAGE_OFFLINE_PAYMENT}/${eventDetails.gid}`);
  };

  /**
   * Get data when selected account changes
   */
  // useEffect(() => {
  //   getData();
  //   getCardData(location.search);
  // }, [selectedAccount]);

  // useEffect(() => {
  //   getCardData(location.search);
  // }, [location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    let pageLimit;
    if (selectedPaymentType !== 'All') {
      const onlinePayment = selectedPaymentType === 'Online';
      if (onlinePayment) {
        pageLimit = Math.max(1, Math.ceil(eventDetails.totalOnlineSales / 10));
      } else {
        pageLimit = Math.max(1, Math.ceil(eventDetails.totalOfflineSales / 10));
      }
    } else {
      pageLimit = Math.max(1, Math.ceil((eventDetails.totalOnlineSales + eventDetails.totalOfflineSales) / 10));
    }
    let currentPage = parsed.pageNum || 1;
    currentPage = pageLimit && currentPage < pageLimit ? currentPage : pageLimit;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [selectedPaymentType, eventPageOrderPayments]);

  const onChangeCustomTemplate = () => {
    setAddCustomTemplateModal(false);
    fetchEventPageCustomTemplates({ gid });
  };

  const handleTableChange = (page, sorter) => {
    if (currentSales === 'payments') {
      let onlinePayment = '';
      let pageLimit;
      if (selectedPaymentType !== 'All') {
        onlinePayment = selectedPaymentType === 'Online';
        if (onlinePayment) {
          pageLimit = Math.max(1, Math.ceil(eventDetails.totalOnlineSales / 10));
        } else {
          pageLimit = Math.max(1, Math.ceil(eventDetails.totalOfflineSales / 10));
        }
      } else {
        pageLimit = Math.max(1, Math.ceil((eventDetails.totalOnlineSales + eventDetails.totalOfflineSales) / 10));
      }
      let currentPage = page.current || 1;
      currentPage = pageLimit && currentPage < pageLimit ? currentPage : pageLimit;
      const sortBy = sorter.sortBy || 'createdAt';
      const direction = sorter.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      fetchEventPageOrderPaymentData({
        gid, currentPage, sortParams, onlinePayment,
      });
      history.push({
        pathname: `${PAGES.EVENT_PAGES}/${gid}`,
        search: `?pageNum=${currentPage}`,
      });
    }
  };

  const selectedRecord = (record) => {
    if (record.paymentSessionGid) {
      history.push({
        pathname: `${PAGES.PAYMENTS}/${record.paymentSessionGid}`,
        state: location.search,
      });
    } else if (record.offlinePaymentType && record.offlinePaymentType === 'CASH') {
      history.push({
        pathname: `${PAGES.CASH_TRANSACTIONS}/${record.offlinePaymentGid}`,
        state: location.search,
      });
    } else if (record.offlinePaymentType && record.offlinePaymentType === 'CHECK') {
      history.push({
        pathname: `${PAGES.CHEQUE_TRANSACTIONS}/${record.offlinePaymentGid}`,
        state: location.search,
      });
    }
  };

  const copyReceiptCode = (record) => {
    const el = `${window.location.origin}${PAGES.EVENT_PAGE_RECEIPT}?orderGid=${record}`;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: 'Event Page Order receipt copied successfully',
    });
  };

  const showDeletePromoModal = (value: Object) => {
    setSelectedPromoCode(value);
    setDeletePromoModal(true);
  };

  const showEditPromoModal = (value: Object) => {
    setSelectedPromoCode(value);
    setEditPromoModal(true);
  };

  const getPromosList = (promosArray: Array) => {
    const activePromos = promosArray.filter(data => data.active === true);
    return activePromos;
  };

  const addSelectedPromos = (promos: Object) => {
    const {
      promocode,
      item,
      category,
      pausePromo,
      maximumRedemption,
    } = promos;
    let params;
    if (item.length > 0) {
      params = {
        promotionalCodeGid: promocode,
        itemGids: item,
        paused: pausePromo,
        maximumRedemption: maximumRedemption ? parseInt((maximumRedemption).replace(/[_\W]+/g, ''), 10) : null,
      };
    } else if (category.length > 0) {
      params = {
        promotionalCodeGid: promocode,
        inventoryCategoryGids: category,
        paused: pausePromo,
        maximumRedemption: maximumRedemption ? parseInt((maximumRedemption).replace(/[_\W]+/g, ''), 10) : null,
      };
    } else {
      params = {
        promotionalCodeGid: promocode,
        paused: pausePromo,
        maximumRedemption: maximumRedemption ? parseInt((maximumRedemption).replace(/[_\W]+/g, ''), 10) : null,
      };
    }
    addPromosByEventPage({
      gid,
      params,
    });
    setRedirect(true);
  };

  const deleteSelectedPromos = (promoGid: Object) => {
    deletePromosByEventPage({
      gid,
      promoGid,
    });
    setRedirect(true);
  };

  const editSelectedPromos = (editCodes: Object) => {
    const {
      item,
      category,
      pausePromo,
      shoppromoGid,
      maximumRedemption,
    } = editCodes;
    let params;
    const maximumRedemptionRequest = maximumRedemption ? parseInt((maximumRedemption).replace(/[_\W]+/g, ''), 10) : null;
    if (item.length > 0) {
      params = {
        itemGids: item,
        paused: pausePromo,
        maximumRedemption: maximumRedemptionRequest,
        removeMaximumRedemption: maximumRedemptionRequest === null,
      };
    } else if (category.length > 0) {
      params = {
        inventoryCategoryGids: category,
        paused: pausePromo,
        maximumRedemption: maximumRedemptionRequest,
        removeMaximumRedemption: maximumRedemptionRequest === null,
      };
    } else {
      params = {
        paused: pausePromo,
        inventoryCategoryGids: [],
        itemGids: [],
        maximumRedemption: maximumRedemptionRequest,
        removeMaximumRedemption: maximumRedemptionRequest === null,
      };
    }
    updatePromosByEventPage({
      gid,
      promoGid: shoppromoGid,
      params,
    });
    setRedirect(true);
  };

  const editSelectedTaxes = (taxrates: Object) => {
    const {
      categories,
    } = taxrates;
    let params;
    let type;
    if (current === 'taxesTab') {
      params = {
        taxRatesGids: categories || [],
      };
      type = 'Tax';
    }

    if (current === 'serviceTab') {
      params = {
        serviceFeeGids: [categories] || [],
      };
      type = 'Service';
    }

    updateEventPage({
      gid,
      params,
      type,
    });
    setRedirect(true);
  };

  const showDeleteServiceFeeModal = (value: Object) => {
    setSelectedServiceFee(value);
    setDeleteServiceFeeModal(true);
  };

  const deleteSelectedServiceFee = () => {
    const params = {
      serviceFeeGids: [],
    };
    updateEventPage({
      gid,
      params,
      type: 'Service Delete',
    });
    setRedirect(true);
  };

  const handleTipAmount = (value) => {
    setTipAmount(value);
  };

  const handleTipType = (value) => {
    setTipType(value || 'PERCENTAGE');
  };

  const AddTipseventPage = () => {
    let params = [];
    const eventPageTips = [];
    for (const tip of eventDetails.eventPageTips) {
      eventPageTips.push({ gid: tip.gid });
    }
    eventPageTips.push({
      tipType,
      tip: tipAmount,
    });
    params = {
      eventPageTips,
    };
    if (eventDetails.eventPageTips?.length >= 5) {
      notification.error({
        message: <span style={{ color: 'red', fontWeight: 100 }}>Only 5 Tips allowed</span>,
      });
    } else {
      updateEventPage({
        gid,
        params,
        type: 'tip',
      });
    }
    setRedirect(true);
  };

  const deleteTipseventPage = (tipGid) => {
    const filteredTips = [];

    for (const tip of eventDetails.eventPageTips) {
      if (tip.gid !== tipGid) {
        filteredTips.push({ gid: tip.gid });
      }
    }

    const params = {
      eventPageTips: filteredTips,
    };

    updateEventPage({
      gid,
      params,
      type: 'tip',
    });
    setRedirect(true);
  };

  // const downloadTemplate = async (fileUrl) => {
  //   try {
  //     window.open(fileUrl);
  //   } catch (e) {
  //     setNotification({
  //       type: NOTIFICATION.ERROR,
  //       payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
  //     });
  //   }
  // };

  const deleteCustomTemplateEventPage = (customTemplate) => {
    removeCustomTemplateEventPage({
      gid,
      customTemplateType: customTemplate.type,
      type: 'custom-template',
    });
    setRedirect(true);
  };

  const downloadReport = async () => {
    try {
      const { data: { entity } } = await axiosAuthInstance
        .post(`${API_END_POINTS.PAYMENTPAGES_API}/${gid}/generate-report`, { type: 'PAYMENT_PAGE_ONLINE' });
      console.log(entity, 'entity');
      setCurrent('downloadingReport');
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: ((e.response && e.response.data && e.response.data.messageCode) === '5002')
          ? MESSAGES.REPORT.NODATA : MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const downloadReports = async (record) => {
    const idx = record && record.gid;
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.GET_REPORTS}/${idx}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const productItemsColumns = [
    {
      title: 'Items',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // eslint-disable-next-line no-shadow
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (price) => {
        if (price) {
          const total = (price / 100).toFixed(2);
          return (
            <span> {`${prefix} ${formatNumber(total, currencyType)}`}</span>
          );
        }
        return (
          <span><>&#8211;</></span>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: '',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => (
        <span> Event Inventory Item</span>
      ),
    },
    {
      title: 'AVAILABLE',
      dataIndex: 'available',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (available) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {available ? 'Yes' : 'No'}
          </span>
        </Tooltip>
      ),
    },
  ];

  const productCustomItemsColumns = [
    {
      title: 'Items',
      dataIndex: 'inputFieldLabel',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // eslint-disable-next-line no-shadow
      render: (inputFieldLabel) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {inputFieldLabel || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'amount',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount) => {
        if (amount) {
          const total = (amount / 100).toFixed(2);
          return (
            <span> {`${prefix} ${formatNumber(total, currencyType)}`}</span>
          );
        }
        return (
          <span> <>&#8211;</></span>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'inputFieldLabel',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => (
        <span> Event Custom Item</span>
      ),
    },
    {
      title: 'AVAILABLE',
      dataIndex: 'available',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            Yes
          </span>
        </Tooltip>
      ),
    },
  ];

  const promoColumns = [
    {
      title: 'PROMO CODE',
      dataIndex: 'promocode',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (promocode, record) => {
        if (record && record.promotionalCodes) {
          return (<span>{record.promotionalCodes && record.promotionalCodes.code}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'promocode',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (promocode, record) => {
        if (record && record.items && (record.items.length > 0)) {
          return (<span>Items</span>);
        }
        if (record && record.inventoryCategories && (record.inventoryCategories.length > 0)) {
          return (<span>Categories</span>);
        }
        if (record && record.promotionalCodes.offer) {
          return (<span>Offer</span>);
        }
        return (
          <>All</>
        );
      },
    },
    {
      title: 'ITEM',
      dataIndex: 'promocode',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record && record.items.length > 0) {
          return (
            <>
              {record.items.map((item, index) => (
                <span key={index} style={{ cursor: 'pointer' }}>
                  {item.name}{index === record.items.length - 1 ? '.' : ', '}
                </span>
              ))}
            </>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'promocode',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record && record.inventoryCategories.length > 0) {
          return (
            <>
              {record.inventoryCategories.map((category, index) => (
                <span key={index} style={{ cursor: 'pointer' }}>
                  {category.name}{index === record.inventoryCategories.length - 1 ? '.' : ', '}
                </span>
              ))}
            </>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'Promo Pause',
      dataIndex: 'paused',
      width: '15%',
      align: 'left',
      render: (paused) => {
        let value = '';
        if (paused === true) {
          value = 'Yes';
        } else {
          value = 'No';
        }
        return value;
      },
    },
  ];

  const actionPromoColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => {
      if (record && record.promotionalCodes && (record.promotionalCodes.active) === true) {
        return (
          <span>
            <Tooltip placement="top" title="Edit">
              <Icon
                type="edit"
                className="ml-3"
                style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); showEditPromoModal(record); }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                className="ml-3"
                style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); showDeletePromoModal(record); }}
              />
            </Tooltip>
          </span>
        );
      }
      return (
        <span>
          <Tooltip placement="top" title="Edit">
            <Icon
              type="edit"
              className="ml-3"
              disabled
              style={{ fontSize: '20px', marginRight: '8px', cursor: 'not-allowed' }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Delete">
            <Icon
              type="delete"
              className="ml-3"
              disabled
              style={{ fontSize: '20px', marginRight: '8px', cursor: 'not-allowed' }}
            />
          </Tooltip>
        </span>
      );
    },
  };

  const taxColumns = [
    {
      title: 'NAME',
      dataIndex: 'displayName',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'REGION',
      dataIndex: 'jurisdiction',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '21%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'RATE',
      dataIndex: 'percentage',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (percentage) => `${percentage} %`,
    },
    {
      title: 'TYPE',
      dataIndex: 'inclusive',
      width: '15%',
      align: 'left',
      render: (inclusive) => {
        let value = '';
        if (inclusive) {
          value = RATE_TYPES.INCLUSIVE;
        } else {
          value = RATE_TYPES.EXCLUSIVE;
        }
        return value;
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const serviceColumns = [
    {
      title: 'NAME',
      dataIndex: 'displayName',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '21%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'PERCENTAGE',
      dataIndex: 'percentage',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (percentage) => `${percentage / 100} %`,
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const taxRateActionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            className="ml-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showDeleteServiceFeeModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  const tipColumns = [
    {
      title: 'TYPE',
      dataIndex: 'tipType',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'VALUE',
      dataIndex: 'tip',
      width: '21%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },

  ];

  const actionTipColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            className="ml-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); setDeleteTip(true); setDeleteTipData(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  const customTemplateColumn = [
    {
      title: 'Name',
      dataIndex: 'type',
      width: '50%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      width: '21%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },

  ];

  const actionCustomTemplateColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => {
      if (record) {
        return (
          <span>
            {/* <Tooltip placement="top" title="Edit"> */}
            {/*  <Icon */}
            {/*      type="download" */}
            {/*      className="ml-3" */}
            {/*      style={{fontSize: '20px', marginRight: '8px', cursor: 'pointer'}} */}
            {/*      onClick={(e) => { */}
            {/*        e.stopPropagation(); */}
            {/*        downloadTemplate(record.templateUrl); */}
            {/*      }} */}
            {/*  /> */}
            {/* </Tooltip> */}
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                className="ml-3"
                style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteCustomTemplateModal(true);
                  setDeleteCustomTemplateModalData(record);
                }}
              />
            </Tooltip>
          </span>
        );
      }
      return (
        <span>
          {/* <Tooltip placement="top" title="Edit"> */}
          {/*  <Icon */}
          {/*      type="download" */}
          {/*      className="ml-3" */}
          {/*      disabled */}
          {/*      style={{fontSize: '20px', marginRight: '8px', cursor: 'not-allowed'}} */}
          {/*  /> */}
          {/* </Tooltip> */}
          <Tooltip placement="top" title="Delete">
            <Icon
              type="delete"
              className="ml-3"
              disabled
              style={{ fontSize: '20px', marginRight: '8px', cursor: 'not-allowed' }}
            />
          </Tooltip>
        </span>
      );
    },
  };

  const paymentColumns = [
    {
      title: 'AMOUNT',
      dataIndex: 'paidAmount',
      width: '13%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (paidAmount) => {
        const netransaction = (paidAmount / 100).toFixed(2);
        if (netransaction < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {`(${prefix} ${formatNumber(netransaction, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${prefix} ${formatNumber(netransaction, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '15%',
      align: 'left',
      sorter: false,
    },
    {
      title: 'PHONE',
      dataIndex: 'phoneNumber',
      width: '15%',
      align: 'left',
      sorter: false,
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '11%',
      align: 'left',
      sorter: true,
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'PAYMENT STATUS',
      dataIndex: 'paymentStatus',
      width: '12%',
      align: 'left',
      sorter: false,
      render: (paymentStatus) => (
        <StatusTag style={{ backgroundColor: getOrdersPaymentBackground(paymentStatus), display: 'table' }}>
          {getOrdersPaymentStatus(paymentStatus)}
        </StatusTag>
      ),
    },
    {
      title: 'REDEEM STATUS',
      dataIndex: 'redeemedStatus',
      width: '14%',
      align: 'left',
      sorter: false,
      render: (redeemedStatus) => (
        <StatusTag style={{ color: getRedeemFontColor(redeemedStatus), backgroundColor: getRedeemBackground(redeemedStatus), display: 'table' }}>
          {getRedeemStatus(redeemedStatus)}
        </StatusTag>
      ),
    },
    {
      title: 'RECEIPT',
      dataIndex: 'eventPageOrderGid',
      width: '10%',
      align: 'left',
      render: (eventPageOrderGid) => {
        if (eventPageOrderGid) {
          return (
            <span>
              <SpButton
                type="secondary"
                borderRadius="50px"
                style={{ cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); copyReceiptCode(eventPageOrderGid); }}
              >
                <Clipboard />
              </SpButton>
            </span>
          );
        }
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              disabled
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      },
    },
  ];

  const reportColumns = [
    {
      title: 'REPORT TYPE',
      dataIndex: 'type',
      width: '25%',
      align: 'left',
    },
    {
      title: 'REQUESTED ON',
      dataIndex: 'generatedAt',
      width: '25%',
      align: 'left',
      render: (generatedAt) => {
        if (generatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(generatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DURATION',
      dataIndex: 'date',
      width: '25%',
      align: 'left',
      render: (date, record) => {
        if (record.startDate && record.endDate && selectedAccount && selectedAccount.timezone) {
          const fromDate = moment
            .utc(record.startDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          const toDate = moment
            .utc(record.endDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{fromDate} To {toDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DOWNLOAD',
      dataIndex: 'actions',
      width: '20%',
      align: 'center',
      render: (text, record) => (
        <>
          {
            record.status === 'GENERATED' ? (
              <Icon
                type="download"
                style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
                onClick={() => downloadReports(record)}
              />
            ) : (
              <span> {record.status ? record.status.replace('_', ' ') : '-'}</span>
            )
          }
        </>
      ),
    },
  ];

  const downloadQRCode = async () => {
    const qrCode = qrCodeRef.current.canvas.current;
    if (!qrCode) {
      console.error('QR code element not found.');
      return;
    }
    try {
      const dataUrl = qrCode.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'qrcode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.QR_Download,
      });
    } catch (error) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Error capturing QR code',
      });
    }
  };

  const cloneEventPage = () => {
    history.push(`${PAGES.ADDEVENTSPAGE}/${gid}?seated=${eventDetails?.isSeatedEvent}`);
    clearEventPageDetails(false);
  };
  const editEventPage = () => {
    history.push(`${PAGES.EDIT_EVENT_PAGE}/${gid}`);
    clearEventPageDetails(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        eventDetails.gid && eventDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Menu
              onClick={e => { setEventPageTab(e.key); }}
              selectedKeys={[eventPageTab]}
              mode="horizontal"
              style={{ fontSize: '20px' }}
            >
              <Menu.Item key="Manage">
                <SpTab
                  to={`${path}`}
                  selected={eventPageTab === 'Manage'}
                >
                  Manage
                </SpTab>
              </Menu.Item>
              <Menu.Item key="Sales">
                <SpTab
                  to={`${path}`}
                  selected={eventPageTab === 'Sales'}
                >
                  Sales
                </SpTab>
              </Menu.Item>
            </Menu>

            {
              eventPageTab === 'Manage' ? (
                <>
                  <Card className="mb-3">
                    <Row type="flex" justify="space-between" className="my-2 mb-3">
                      <Col className="d-flex align-items-center">
                        <SpText className="mr-5" fontSize="20px" fontWeight="600">
                          {eventDetails.title}
                        </SpText>
                        <SpStatusTag style={{ backgroundColor: getEventsBackground(eventDetails.status) }}>
                          {getEventsStatus(eventDetails.status)}
                        </SpStatusTag>
                      </Col>
                    </Row>
                    <Row type="flex" justify="end" className="my-3 mb-3">
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <Col className="mt-2">
                          {
                            eventDetails.supportOfflinePayment && (
                              <SpButton
                                className="mr-2"
                                type="secondary"
                                shape="round"
                                disabled={eventDetails.status !== 'ACTIVE'}
                                onClick={() => offlinePayment()}
                              >
                                Offline Payment
                              </SpButton>
                            )
                          }
                          <SpButton
                            type="secondary"
                            shape="round"
                            className="mr-2"
                            onClick={() => cloneEventPage()}
                          >
                            Clone
                          </SpButton>
                          <SpButton
                            type="secondary"
                            shape="round"
                            className="mr-2"
                            disabled={eventDetails.status !== 'ACTIVE'}
                            onClick={() => editEventPage()}
                          >
                            Edit
                          </SpButton>
                          <SpButton
                            type="secondary"
                            shape="round"
                            className="mr-2"
                            disabled={eventDetails.status !== 'SOLD_OUT'}
                            onClick={() => resumeSalesNon(gid)}
                          >
                            Resume Sales
                          </SpButton>
                          <SpButton
                            type="secondary"
                            shape="round"
                            className="mr-2"
                            disabled={eventDetails.status !== 'ACTIVE'}
                            onClick={() => soldOutEventPage(gid)}
                          >
                            Sold Out
                          </SpButton>
                          <SpButton
                            type="danger"
                            shape="round"
                            disabled={eventDetails.status !== 'ACTIVE'}
                            onClick={() => {
                              if (slugGid) unLinkAccountSlug(slugGid);
                              deactivateEventPage(gid);
                            }}
                          >
                            Deactivate
                          </SpButton>
                        </Col>
                      </RBAC>
                    </Row>
                    <Row type="flex" justify="space-between" className="my-2 mb-3">
                      <Col span={18}>
                        <SpForm>
                          <Row type="flex" justify="start" align="middle">
                            <Col span={4}>
                              <span>Banner Text</span>
                            </Col>
                            <Col span={12}>
                              <SpH5>
                                {(eventDetails && eventDetails.bannerText
                                    // eslint-disable-next-line max-len
                                    && <div dangerouslySetInnerHTML={{ __html: Buffer.from(eventDetails.bannerText, 'base64').toString('utf8').replaceAll('<br>', '') }} />) || <>&#8211;</>}
                              </SpH5>
                            </Col>
                          </Row>
                        </SpForm>
                        <SpForm>
                          <Row type="flex" justify="start" align="middle">
                            <Col span={4}>
                              <span>Color Theme</span>
                            </Col>
                            <Col span={12}>
                              <div className="d-flex">
                                {(eventDetails && eventDetails.primaryColor) ? (
                                  <>
                                    <SpColor
                                      className="color"
                                      style={{ backgroundColor: `${eventDetails.primaryColor}` }}
                                    />
                                    <SpH5 className="ml-1">
                                      {eventDetails.primaryColor || <>&#8211;</>}
                                    </SpH5>
                                  </>
                                ) : (
                                  <>
                                    <SpColor
                                      className="color"
                                      style={{ backgroundColor: '#007bff' }}
                                    />
                                    <SpH5 className="ml-1">
                                      #007bff
                                    </SpH5>
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </SpForm>
                        <SpForm>
                          <Row type="flex" justify="start" align="middle">
                            <Col span={4}>
                              <span>Created On</span>
                            </Col>
                            <Col span={12}>
                              <SpH5>{
                                eventDetails.createdAt && selectedAccount && selectedAccount.timezone
                                  ? (
                                    moment
                                      .utc(eventDetails.createdAt)
                                      .tz(selectedAccount.timezone)
                                      .format('MMM DD, YYYY')
                                  )
                                  : <>&#8211;</>
                              }
                              </SpH5>
                            </Col>
                          </Row>
                        </SpForm>
                        {eventDetails && eventDetails.isSeatedEvent ? (
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={4}>
                                <span>Venue Name</span>
                              </Col>
                              <Col span={18}>
                                <SpH5>
                                  {(eventDetails.seatedVenue && eventDetails.seatedVenue.venueName)
                                    ? eventDetails.seatedVenue.venueName : <>&#8211;</>}
                                </SpH5>
                              </Col>
                            </Row>
                          </SpForm>
                        ) : ''}
                        <SpForm>
                          <Row type="flex" justify="start" align="middle">
                            <Col span={4}>
                              <span>{eventDetails && eventDetails.isSeatedEvent ? 'Venue ' : '' }Address</span>
                            </Col>
                            <Col span={18}>
                              <SpH5>
                                {(eventDetails && eventDetails.address)
                                  ? `${eventDetails.address.street}, 
                            ${eventDetails.address.city}, 
                            ${eventDetails.address.state}, 
                            ${eventDetails.address.postalCode}, 
                            ${eventDetails.address.country && eventDetails.address.country.alpha3}`
                                  : <>&#8211;</>}
                              </SpH5>
                            </Col>
                          </Row>
                        </SpForm>
                        <SpForm>
                          <Row type="flex" justify="start" align="middle">
                            <Col span={4}>
                              <span>Link</span>
                            </Col>
                            <Col span={18}>
                              {eventDetails.status === 'ACTIVE'
                                ? (
                              // eslint-disable-next-line max-len
                                  <SpH5>{env === 'Production' ? `https://secure.swirepay.com/event-page/${eventDetails.gid}` : `https://staging-secure.swirepay.com/event-page/${eventDetails.gid}`}
                                    <SpButton
                                      type="secondary"
                                      className="ml-2"
                                      borderRadius="50px"
                                      disabled={eventDetails.status !== 'ACTIVE'}
                                      onClick={() => { copyCode(eventDetails); }}
                                    >
                                      <Clipboard />
                                    </SpButton>
                                  </SpH5>
                                )
                                : (
                                  <>&#8211;</>
                                )}
                            </Col>
                          </Row>
                        </SpForm>
                        <SpForm>
                          {
                            ((accountSlug.length > 0 && eventDetails.status !== 'DEACTIVATED')) ? (
                              <>
                                <Row type="flex" justify="start" align="middle">
                                  <Col span={6}>
                                    <SpText className="mr-3" fontSize="14px" fontWeight="600">
                                      Link Account Slug
                                    </SpText>
                                    <Select
                                      className="mt-2 w-100"
                                      showSearch
                                      disabled={enableUnlink}
                                      value={slugLink || 'Select slug'}
                                      onChange={(value) => {
                                        setSlugGid(value[0]);
                                        setSlugLink(value[1]);
                                      }}
                                    >
                                      {
                                        accountSlug.map(slug => (
                                          slug.status === 'UNLINKED' && (
                                            <Option
                                              key={1}
                                              value={[slug.gid, slug.slugName]}
                                            >
                                              {slug.slugName}
                                            </Option>
                                          )
                                        ))
                                      }
                                    </Select>
                                  </Col>
                                </Row>
                                <Row className="mt-4" type="flex" justify="start" align="middle">
                                  <Col span={3}>
                                    {
                                        enableUnlink ? (
                                          <SpButton
                                            type="primary"
                                            shape="round"
                                            onClick={unlinkSlug}
                                          >
                                            Unlink
                                          </SpButton>
                                        ) : slugLink ? (
                                          <SpButton
                                            type="primary"
                                            shape="round"
                                            onClick={linkSlug}
                                          >
                                            Link
                                          </SpButton>
                                        ) : (
                                          <SpButton
                                            type="primary"
                                            shape="round"
                                            disabled
                                          >
                                            Link
                                          </SpButton>
                                        )
                                      }

                                  </Col>
                                </Row>
                              </>
                            ) : ''
                          }
                        </SpForm>
                      </Col>
                      <Col span={6} style={{ textAlign: 'center' }}>
                        <QRCode
                          size="256"
                          // eslint-disable-next-line max-len
                          value={env === 'Production' ? `https://secure.swirepay.com/event-page/${eventDetails.gid}` : env === 'Staging' ? `https://staging-secure.swirepay.com/event-page/${eventDetails.gid}` : `http://localhost:4000/event-page/${eventDetails.gid}`}
                          fgColor="#000000"
                          ref={qrCodeRef}
                          logoPadding={5}
                          removeQrCodeBehindLogo
                          logoImage={Qrlogo}
                        />
                        <div className="mt-4">
                          <SpButton
                            type="secondary"
                            shape="round"
                            disabled={eventDetails.status !== 'ACTIVE'}
                            onClick={() => downloadQRCode()}
                          >
                            <Icon type="download" />
                          </SpButton>
                        </div>
                      </Col>
                    </Row>
                    <SpForm>
                      <Row>
                        <Col span={24} className="d-flex justify-content-end">
                          <SpH5 color="#7f7f7f">{eventDetails.gid}</SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </Card>
                  <Card className="pl-2">
                    <Menu
                      onClick={e => { setCurrent(e.key); }}
                      selectedKeys={[current]}
                      mode="horizontal"
                      style={{ fontSize: '20px' }}
                    >
                      <Menu.Item key="ProductsTab">
                        <SpTab
                          to={`${path}`}
                          selected={current === 'ProductsTab'}
                        >
                          Products
                        </SpTab>
                      </Menu.Item>
                      <Menu.Item key="promosTab">
                        <SpTab
                          to={`${path}`}
                          selected={current === 'promosTab'}
                        >
                          Promos
                        </SpTab>
                      </Menu.Item>
                      <Menu.Item key="taxesTab">
                        <SpTab
                          to={`${path}`}
                          selected={current === 'taxesTab'}
                        >
                          Taxes
                        </SpTab>
                      </Menu.Item>
                      <Menu.Item key="serviceTab">
                        <SpTab
                          to={`${path}`}
                          selected={current === 'serviceTab'}
                        >
                          Service Charge
                        </SpTab>
                      </Menu.Item>
                      <Menu.Item key="tipsTab">
                        <SpTab
                          to={`${path}`}
                          selected={current === 'tipsTab'}
                        >
                          Tips
                        </SpTab>
                      </Menu.Item>
                      <Menu.Item key="customTemplateTab">
                        <SpTab
                          to={`${path}`}
                          selected={current === 'customTemplateTab'}
                        >
                          Custom Template
                        </SpTab>
                      </Menu.Item>
                    </Menu>
                    {
                      current === 'ProductsTab' ? (
                        <>
                          <Collapse expandIconPosition="end" accordion style={{ border: 'none', paddingTop: '3px' }}>
                            {
                              eventDetails.eventPageProducts.map((product) => (
                                <Panel
                                  header={(
                                    <>
                                      <SpText fontSize="16px">
                                        {product.eventPageProductName}
                                      </SpText>
                                    </>
                                  )}
                                  style={{ borderBottom: 'none' }}
                                  key={product.gid}
                                >
                                  {(() => {
                                    const matchedItem = eventPageProductItems.find(
                                      (list) => list.gid === product.gid,
                                    );
                                    return (
                                      <>
                                        {matchedItem?.items?.length > 0 && (
                                          <Table
                                            rowClassName={() => 'ant-table-clickable-row'}
                                            columns={productItemsColumns}
                                            rowKey={(record) => record.gid}
                                            className="custom-table"
                                            dataSource={matchedItem.items}
                                            pagination={false}
                                          />
                                        )}
                                        {matchedItem?.customItems?.length > 0 && (
                                          <Table
                                            rowClassName={() => 'ant-table-clickable-row'}
                                            columns={productCustomItemsColumns}
                                            rowKey={(record) => record.gid}
                                            className="custom-table"
                                            dataSource={matchedItem.customItems}
                                            pagination={false}
                                          />
                                        )}
                                      </>
                                    );
                                  })()}
                                </Panel>
                              ))
                            }
                          </Collapse>
                        </>
                      ) : current === 'promosTab' ? (
                        <>
                          <Row type="flex" justify="space-between" className="my-2">
                            <Col />
                            {
                              (eventPagePromosList && eventPagePromosList.length !== 0 && eventDetails.status !== 'DEACTIVATED') && (
                                <Col>
                                  <SpButton
                                    type="secondary"
                                    shape="round"
                                    ghost
                                    className="mr-3"
                                    onClick={() => setAddPromoModal(true)}
                                  >
                                    {'\u002B'}&nbsp;Add Promo
                                  </SpButton>
                                </Col>
                              )
                            }
                          </Row>
                          {/* ADD PROMO MODAL */}
                          {addPromoModal && (
                            <AddPromo
                              visible={addPromoModal}
                              submitting={submitting}
                              selectedItemList={eventDetails}
                              promosList={getPromosList(promosList)}
                              categoryList={categoryList}
                              shopsItemsList={inventoryItems}
                              selectedAccount={selectedAccount}
                              shopPromosList={allEventPagePromosList}
                              close={() => setAddPromoModal(false)}
                              submit={addSelectedPromos}
                            />
                          )}
                          {/* EDIT PROMO MODAL */}
                          {editPromoModal && (
                            <EditPromoCode
                              visible={editPromoModal}
                              submitting={submitting}
                              selectedAccount={selectedAccount}
                              selectedPromoCode={selectedPromoCode}
                              promosList={getPromosList(promosList)}
                              categoryList={categoryList}
                              shopsItemsList={inventoryItems}
                              shopPromosList={eventPagePromosList}
                              close={() => setEditPromoModal(false)}
                              submit={editSelectedPromos}
                            />
                          )}
                          {/* DELETE PROMO MODAL */}
                          {deletePromoModal && (
                            <DeletePromoCode
                              visible={deletePromoModal}
                              submitting={submitting}
                              selectedAccount={selectedAccount}
                              selectedPromoCode={selectedPromoCode}
                              close={() => setDeletePromoModal(false)}
                              submit={deleteSelectedPromos}
                            />
                          )}
                          {
                            (eventPagePromosList && eventPagePromosList.length !== 0) ? (
                              <>
                                <Table
                                  rowClassName={() => 'ant-table-clickable-row'}
                                  columns={eventDetails.status === 'DEACTIVATED' ? [...promoColumns] : [...promoColumns, actionPromoColumn]}
                                  rowKey={(record) => record.gid}
                                  className="custom-table"
                                  dataSource={eventPagePromosList}
                                  pagination={pagination}
                                  onChange={handleTableChange}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    border: '1px solid #CBCBCB',
                                    minHeight: '253px',
                                    width: '1014 px',
                                    marginTop: '10px',
                                    marginBottom: '25px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>
                                    <div className="ml-4">
                                      <SpH5 color="#333333">
                                        No data
                                      </SpH5>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: '15px',
                                      }}
                                    >
                                      <SpButton
                                        type="secondary"
                                        disabled={eventDetails.status === 'DEACTIVATED'}
                                        onClick={() => setAddPromoModal(true)}
                                      >
                                        {'\u002B'}&nbsp;Add Promo
                                      </SpButton>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        </>
                      ) : current === 'taxesTab' ? (
                        <>
                          <Row type="flex" justify="space-between" className="my-2">
                            <Col />
                            {
                              // eslint-disable-next-line max-len
                              (eventDetails && eventDetails.taxRates && eventDetails.taxRates.length !== 0 && eventDetails.status !== 'DEACTIVATED') && (
                                <Col>
                                  <SpButton
                                    type="secondary"
                                    shape="round"
                                    ghost
                                    className="mr-3"
                                    onClick={() => setEditTaxModal(true)}
                                  >
                                    {'\u002B'}&nbsp;Add or Remove Taxes
                                  </SpButton>
                                </Col>
                              )
                            }
                          </Row>
                          {/* Add TAXES MODAL */}
                          {editTaxModal && (
                            <AddRemoveTaxes
                              visible={editTaxModal}
                              submitting={submitting}
                              selectedItemList={eventDetails}
                              ratesList={taxRatesList}
                              shopsRatesList={eventDetails && eventDetails.taxRates}
                              selectedAccount={selectedAccount}
                              close={() => setEditTaxModal(false)}
                              submit={editSelectedTaxes}
                            />
                          )}
                          {
                            (eventDetails && eventDetails.taxRates && eventDetails.taxRates.length !== 0) ? (
                              <>
                                <Table
                                  rowClassName={() => 'ant-table-clickable-row'}
                                  columns={taxColumns}
                                  rowKey={(record) => record.gid}
                                  className="custom-table"
                                  dataSource={eventDetails && eventDetails.taxRates}
                                  pagination={pagination}
                                  onChange={handleTableChange}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    border: '1px solid #CBCBCB',
                                    minHeight: '253px',
                                    width: '1014 px',
                                    marginTop: '10px',
                                    marginBottom: '25px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>
                                    <div className="ml-4">
                                      <SpH5 color="#333333">
                                        No data
                                      </SpH5>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: '15px',
                                      }}
                                    >
                                      <SpButton
                                        type="secondary"
                                        disabled={eventDetails.status === 'DEACTIVATED'}
                                        onClick={() => setEditTaxModal(true)}
                                      >
                                        {'\u002B'}&nbsp;Add Taxes
                                      </SpButton>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        </>
                      ) : current === 'serviceTab' ? (
                        <>
                          <Row type="flex" justify="space-between" className="my-2" />

                          {/* Add SERVICE TAXES MODAL */}
                          {editServiceFeeModal && (
                            <AddRemoveTaxRates
                              visible={editServiceFeeModal}
                              submitting={submitting}
                              selectedItemList={eventDetails}
                              ratesList={serviceFeeList}
                              eventPagesRatesList={eventDetails && eventDetails.serviceFees}
                              selectedAccount={selectedAccount}
                              close={() => setEditServiceFeeModal(false)}
                              submit={editSelectedTaxes}
                            />
                          )}
                          {/* DELETE SERVICE TAXES MODAL */}
                          {deleteServiceFeeModal && (
                            <RemoveTaxRates
                              visible={deleteServiceFeeModal}
                              submitting={submitting}
                              selectedItemList={eventDetails}
                              ratesList={serviceFeeList}
                              eventPagesRatesList={eventDetails && eventDetails.serviceFees}
                              selectedTaxRate={selectedServiceFee}
                              close={() => setDeleteServiceFeeModal(false)}
                              submit={deleteSelectedServiceFee}
                            />
                          )}
                          {
                            (eventDetails && eventDetails.serviceFees && eventDetails.serviceFees.length !== 0) ? (
                              <>
                                <Table
                                  rowClassName={() => 'ant-table-clickable-row'}
                                  columns={eventDetails.status === 'DEACTIVATED' ? [...serviceColumns] : [...serviceColumns, taxRateActionColumn]}
                                  rowKey={(record) => record.gid}
                                  className="custom-table"
                                  dataSource={eventDetails && eventDetails.serviceFees}
                                  pagination={pagination}
                                  onChange={handleTableChange}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    border: '1px solid #CBCBCB',
                                    minHeight: '253px',
                                    width: '1014 px',
                                    marginTop: '10px',
                                    marginBottom: '25px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>
                                    <div className="ml-4">
                                      <SpH5 color="#333333">
                                        No data
                                      </SpH5>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: '15px',
                                      }}
                                    >
                                      <SpButton
                                        type="secondary"
                                        disabled={eventDetails.status === 'DEACTIVATED'}
                                        onClick={() => setEditServiceFeeModal(true)}
                                      >
                                        {'\u002B'}&nbsp;Add Service Charge
                                      </SpButton>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        </>
                      ) : current === 'tipsTab' ? (
                        <>
                          <Row type="flex" justify="space-between" className="my-2">
                            <Col />
                            {
                              // eslint-disable-next-line max-len
                              (eventDetails && eventDetails.eventPageTips && eventDetails.eventPageTips.length !== 0 && eventDetails.status !== 'DEACTIVATED') && (
                                <Col>
                                  <SpButton
                                    type="secondary"
                                    onClick={() => setAddTipModal(true)}
                                  >
                                    {'\u002B'}&nbsp;Add Tip
                                  </SpButton>
                                </Col>
                              )
                            }
                          </Row>
                          {/* Add TIP MODAL */}
                          {addTipModal && (
                            <AddNewTip

                              handleTipAmount={handleTipAmount}
                              handleTipType={handleTipType}
                              visible={addTipModal}
                              submitting={submitting}
                              selectedItemList={eventDetails}
                              ratesList={taxRatesList}
                              eventPagesRatesList={eventDetails && eventDetails.eventPageTips}
                              selectedAccount={selectedAccount}
                              close={() => setAddTipModal(false)}
                              submit={AddTipseventPage}
                            />
                          )}
                          {/* DELETE TIP MODAL */}
                          {deleteTip && (
                            <DeleteTip
                              visible={deleteTip}
                              submitting={submitting}
                              selectedAccount={selectedAccount}
                              selectedPromoCode={deleteTipData.gid}
                              close={() => setDeleteTip(false)}
                              submit={deleteTipseventPage}
                            />
                          )}
                          {
                            (eventDetails && eventDetails.eventPageTips && eventDetails.eventPageTips.length !== 0) ? (
                              <>
                                <Table
                                  rowClassName={() => 'ant-table-clickable-row'}
                                  columns={eventDetails.status === 'DEACTIVATED' ? [...tipColumns] : [...tipColumns, actionTipColumn]}
                                  rowKey={(record) => record.gid}
                                  className="custom-table"
                                  dataSource={eventDetails && eventDetails.eventPageTips}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    border: '1px solid #CBCBCB',
                                    minHeight: '253px',
                                    width: '1014 px',
                                    marginTop: '10px',
                                    marginBottom: '25px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>
                                    <div className="ml-4">
                                      <SpH5 color="#333333">
                                        No data
                                      </SpH5>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: '15px',
                                      }}
                                    >
                                      <SpButton
                                        type="secondary"
                                        disabled={eventDetails.status === 'DEACTIVATED'}
                                        onClick={() => setAddTipModal(true)}
                                      >
                                        {'\u002B'}&nbsp;Add Tip
                                      </SpButton>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        </>
                      ) : current === 'customTemplateTab' ? (
                        <>
                          <Row type="flex" justify="space-between" className="my-2">
                            <Col />
                            {
                                (eventPageCustomTemplates && eventPageCustomTemplates.length !== 0 && eventDetails.status !== 'DEACTIVATED') && (
                                  <Col>
                                    <SpButton
                                      type="secondary"
                                      onClick={() => setAddCustomTemplateModal(true)}
                                    >
                                      {'\u002B'}&nbsp;Add Custom Template
                                    </SpButton>
                                  </Col>
                                )
                            }
                          </Row>
                          {/* Add Custom Template */}
                          {addCustomTemplateModal && (
                            <CustomTemplateUpload
                              selectedAccount={selectedAccount}
                              eventPageGid={gid}
                              visible={addCustomTemplateModal}
                              eventPageCustomTemplates={eventPageCustomTemplates}
                              close={onChangeCustomTemplate}
                            />
                          )}
                          {/* Delete Custom Template Modal */}
                          {deleteCustomTemplateModal && (
                            <RemoveCustomTemplate
                              visible={deleteCustomTemplateModal}
                              submitting={submitting}
                              selectedAccount={selectedAccount}
                              eventPageCustomTemplate={deleteCustomTemplateModalData}
                              close={() => setDeleteCustomTemplateModal(false)}
                              submit={deleteCustomTemplateEventPage}
                            />
                          )}
                          {
                            (eventPageCustomTemplates && eventPageCustomTemplates.length !== 0) ? (
                              <>
                                <Table
                                  rowClassName={() => 'ant-table-clickable-row'}
                                  // eslint-disable-next-line max-len
                                  columns={eventDetails.status === 'DEACTIVATED' ? [...customTemplateColumn] : [...customTemplateColumn, actionCustomTemplateColumn]}
                                  rowKey={(record) => record.gid}
                                  className="custom-table"
                                  dataSource={eventPageCustomTemplates}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    border: '1px solid #CBCBCB',
                                    minHeight: '253px',
                                    width: '1014 px',
                                    marginTop: '10px',
                                    marginBottom: '25px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>
                                    <div className="ml-4">
                                      <SpH5 color="#333333">
                                        No data
                                      </SpH5>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: '15px',
                                      }}
                                    >
                                      <SpButton
                                        type="secondary"
                                        disabled={eventDetails.status === 'DEACTIVATED'}
                                        onClick={() => setAddCustomTemplateModal(true)}
                                      >
                                        {'\u002B'}&nbsp;Add Custom Template
                                      </SpButton>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        </>
                      ) : ''
                    }
                  </Card>
                </>
              ) : eventPageTab === 'Sales' ? (
                <>
                  <Card className="mb-3">
                    <SpForm>
                      <Row className="mt-4" type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>Total {selectedPaymentType !== 'All' ? `${selectedPaymentType} ` : '' }Payments</span>
                        </Col>
                        <Col span={6}>
                          <SpH5>{
                            selectedPaymentType === 'All'
                              ? eventDetails.totalOnlineSales + eventDetails.totalOfflineSales
                              : selectedPaymentType === 'Online' ? eventDetails.totalOnlineSales
                                : selectedPaymentType === 'Offline' ? eventDetails.totalOfflineSales
                                  : <>&#8211;</>
                                }
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>Total {selectedPaymentType !== 'All' ? `${selectedPaymentType} ` : '' }Revenue</span>
                        </Col>
                        <Col span={6}>
                          <SpH5>
                            {selectedAccount.currency.prefix}{' '}
                            {formatNumber(
                              ((selectedPaymentType === 'All'
                                ? eventDetails.totalOnlineRevenue + eventDetails.totalOfflineRevenue
                                : selectedPaymentType === 'Online'
                                  ? eventDetails.totalOnlineRevenue
                                  : selectedPaymentType === 'Offline'
                                    ? eventDetails.totalOfflineRevenue
                                    : 0) / 100).toFixed(2), currencyType,
                            )}
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </Card>
                  <Card className="mb-3">
                    <>
                      <Menu onClick={e => setCurrentSales(e.key)} selectedKeys={[currentSales]} mode="horizontal" style={{ fontSize: '20px' }}>
                        <Menu.Item key="payments">
                          <SpTab
                            to={`${path}?status.IN=PAYMENTS`}
                            selected={current === 'payments'}
                          >
                            PAYMENTS
                          </SpTab>
                        </Menu.Item>
                        {/* <Menu.Item key="offlineData"> */}
                        {/*  <SpTab */}
                        {/*    to={`${path}?status.IN=OFFLINEPAYMENTS`} */}
                        {/*    selected={current === 'offlineData'} */}
                        {/*  > */}
                        {/*    DOWNLOAD REPORT */}
                        {/*  </SpTab> */}
                        {/* </Menu.Item> */}
                      </Menu>
                      {
                        currentSales === 'payments' ? (
                          <>
                            <Row className="my-2">
                              <Col span={16} className="d-flex justify-content-start">
                                <Select
                                  className="mr-4 mt-3"
                                  value={selectedPaymentType || 'All'}
                                  style={{ width: '350px' }}
                                  bordered
                                  onChange={(value) => setSelectedPaymentType(value)}
                                >
                                  <Option key={1} value="All">All</Option>
                                  <Option key={2} value="Online">Online</Option>
                                  <Option key={3} value="Offline">Offline</Option>
                                </Select>
                              </Col>
                              <Col span={8} className="d-flex justify-content-end">
                                {/* <SpButton */}
                                {/*  className="mr-4 mt-3" */}
                                {/*  type="secondary" */}
                                {/*  shape="round" */}
                                {/*  onClick={() => downloadReport()} */}
                                {/*  ghost */}
                                {/* > */}
                                {/*  Download Report */}
                                {/* </SpButton> */}
                              </Col>
                            </Row>
                            <Table
                              onRow={(record) => ({
                                onClick: () => {
                                  selectedRecord(record);
                                },
                              })}
                              rowClassName={() => 'ant-table-clickable-row'}
                              columns={paymentColumns}
                              rowKey={(record) => record.gid}
                              dataSource={eventPageOrderPayments}
                              pagination={pagination}
                              onChange={handleTableChange}
                            />
                          </>
                        ) : (
                          <>
                            <Table
                              columns={reportColumns}
                              rowKey={(record) => record.gid}
                              dataSource={[]}
                              pagination={pagination}
                              onChange={handleTableChange}
                            />
                          </>
                        )
                      }
                    </>
                  </Card>
                </>
              ) : ''
            }
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.eventPageDetails,
  eventDetailsStatus: state.eventPageDetails.eventDetailsStatus,
  eventPageOrderPayments: state.eventPageDetails.eventPageOrderPaymentDetails.content,
  eventPageProductItems: state.eventPageDetails.eventPageProductItems,
  eventPageCustomTemplates: state.eventPageDetails.eventPageCustomTemplateDetails,
  totalElements: state.eventPageDetails.eventPageOrderPaymentDetails ? state.eventPageDetails.eventPageOrderPaymentDetails.totalElements : '',
  loading: state.loading.loading,
  eventPageSlug: state.eventPageDetails.eventPageSlug,
  accountSlug: state.accountSlug.content,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  offlineEventPayments: state.eventPageDetails.offlineEventPayments.content,
  totalofflineElements: state.eventPageDetails.offlineEventPayments ? state.eventPageDetails.offlineEventPayments.totalElements : '',
  isMobileView: state.mobileView.isMobileView,
  categoryList: state.inventoryItems.itemsList.content,
  inventoryItems: state.inventoryItems.sortItemsList.content,
  eventPagePromosList: state.eventPageDetails.eventPagePromosList.content,
  totalPromoElements: state.eventPageDetails.eventPagePromosList ? state.eventPageDetails.eventPagePromosList.totalElements : '',
  promosList: state.inventoryShops.promosList.content,
  allEventPagePromosList: state.eventPageDetails.allEventPagePromosList.content,
  taxRatesList: state.inventoryShops.ratesList.content,
  serviceFeeList: state.serviceRates.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventPageDetails: gid => dispatch(eventPageDetailsActions.fetchEventPageDetails({
    payload: gid,
  })),
  fetchEventPageProductItemDetails: gid => dispatch(eventPageDetailsActions.fetchEventPageProductItemDetails({
    payload: gid,
  })),
  fetchEventPageOrderPaymentData: param => dispatch(eventPageDetailsActions.fetchEventPageOrderPaymentData({
    payload: param,
  })),
  fetchEventPageCustomTemplates: param => dispatch(eventPageDetailsActions.fetchEventPageCustomTemplates({
    payload: param,
  })),
  removeCustomTemplateEventPage: param => dispatch(eventPageDetailsActions.removeCustomTemplateEventPage({
    payload: param,
  })),
  updateEventPage: param => dispatch(eventPageDetailsActions.updateEventPage({
    payload: param,
  })),
  fetchEventPagesSlug: param => dispatch(eventPageDetailsActions.fetchEventPagesSlug({
    payload: param,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  linkAccountSlug: param => dispatch(accountSlugActions.linkAccountSlug({
    payload: param,
  })),
  unLinkAccountSlug: param => dispatch(accountSlugActions.unLinkAccountSlug({
    payload: param,
  })),
  deactivateEventPage: param => dispatch(eventPageDetailsActions.deactivateEventPage({
    payload: param,
  })),
  clearEventPageDetails: param => dispatch(eventPageDetailsActions.clearEventPageDetails({
    payload: param,
  })),
  soldOutEventPage: param => dispatch(eventPageDetailsActions.soldOutEventPage({
    payload: param,
  })),
  resumeSalesNon: param => dispatch(eventPageDetailsActions.resumeSalesNon({
    payload: param,
  })),
  fetchOfflineEventPagesPayments: param => dispatch(eventPageDetailsActions.fetchOfflineEventPagesPayments({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  // Category Fetch
  fetchItemsList: param => dispatch(inventoryItemsActions.fetchItemsList({
    payload: param,
  })),
  // Item Fetch
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),

  fetchPromosList: gid => dispatch(inventoryShopsActions.fetchPromosList({
    payload: gid,
  })),

  fetchPromosByEventPage: gid => dispatch(eventPageDetailsActions.fetchPromosByEventPage({
    payload: gid,
  })),
  fetchAllPromosByEventPage: gid => dispatch(eventPageDetailsActions.fetchAllPromosByEventPage({
    payload: gid,
  })),

  addPromosByEventPage: param => dispatch(eventPageDetailsActions.addPromosByEventPage({
    payload: param,
  })),

  updatePromosByEventPage: param => dispatch(eventPageDetailsActions.updatePromosByEventPage({
    payload: param,
  })),

  deletePromosByEventPage: param => dispatch(eventPageDetailsActions.deletePromosByEventPage({
    payload: param,
  })),

  fetchShopRates: param => dispatch(inventoryShopsActions.fetchShopRates({
    payload: param,
  })),

  fetchServicerates: param => dispatch(serviceRatesActions.fetchServicerates({
    payload: param,
  })),

});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(EventsDetails);
