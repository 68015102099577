/* eslint-disable no-unused-vars */
import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  INVENTORY_MODIFIERS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryModifiers = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : 'sortBy=name&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_MODIFIER}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_MODIFIER}${query}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_MODIFIER}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_MODIFIER}${query}${sorting}`);
};

const fetchInventorySortModifer = async (payload) => {
  const queryParam = '?page=1&size=5000';
  const sorting = 'sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_MODIFIER}${queryParam}${sorting}`);
};

const postInventoryModifer = async ({ params }) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVENTORY_MODIFIER}`, params);

const patchInventoryModifer = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_MODIFIER}/${gid}`, params);

const patchInventorySortModifer = async ({ params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_MODIFIER}/sort-order`, params);

const deleteSelectedInventoryModifierGroup = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_INVENTORY_MODIFIER}/${gid}`);

export function* getInventoryModifiers(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInventoryModifiers, action.payload);
    yield put({ type: INVENTORY_MODIFIERS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_MODIFIERS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateInventoryModifer(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventoryModifer, action.payload);
    const { data: { entity } } = yield call(fetchInventoryModifiers, action.payload);
    yield put({ type: INVENTORY_MODIFIERS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_MODIFIER_GROUP,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addInventoryModifer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postInventoryModifer, action.payload);
    const { data: { entity } } = yield call(fetchInventoryModifiers, action.payload);
    yield put({ type: INVENTORY_MODIFIERS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.ADD_MODIFIER_GROUP,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getInventorySortModifer(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchInventorySortModifer, action.payload);
    yield put({ type: INVENTORY_MODIFIERS.ORDER_MODIFIERS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: INVENTORY_MODIFIERS.ORDER_MODIFIERS_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateInventorySortModifer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInventorySortModifer, action.payload);
    const { data: { entity } } = yield call(fetchInventorySortModifer, action.payload);
    yield put({ type: INVENTORY_MODIFIERS.ORDER_MODIFIERS_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.UPDATE_SORT_MODIFIER,
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteInventoryModifer(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteSelectedInventoryModifierGroup, action.payload);
    const { data: { entity } } = yield call(fetchInventoryModifiers, action.payload);
    yield put({ type: INVENTORY_MODIFIERS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.INVENTORY_ORDERS.DELETE_MODIFIERGROUP,
    });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
