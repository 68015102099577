/* eslint-disable no-unused-vars */
// @flow
import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Icon,
  Input,
  Select,
  DatePicker,
  Divider,
  Upload,
  message,
  Checkbox,
  AutoComplete,
} from 'antd';
import {
  SpText,
  SpButton,
  Line,
  SpH5,
  SpError,
  SpStatusTag,
  SpFormWrapper,
  SpWrapper,
} from 'components/DesignKit';
import debounce from 'lodash.debounce';
import {
  MAX_AMOUNT,
  COUNTRIES,
  RATE_TYPES,
  MESSAGES,
} from 'appconstants';
import {
  fundingSourceActions,
  notificationActions,
  sequenceGeneratorsActions,
} from 'store/actions';
import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import {
  NOTIFICATION,
} from 'store/actionTypes';
import formatNumber from 'utils/formatNumber';
import getTaxAmount from 'utils/getTaxAmount';
import getDiscountOff from 'utils/getDiscountOff';
import getStateList from 'utils/stateList';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import { validators } from 'utils/validationMessages';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import getInvoiceBackground from 'utils/getInvoiceBackground';
import getInvoiceStatus from 'utils/getInvoiceStatus';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import swirepaylogo from 'components/NavIcons/swirepay';
import PdfIcon from 'components/NavIcons/pdfIcon';
import Address from './Address';
import AddNewCustomer from './AddNewCustomer';
import AddNewProduct from './AddNewProduct';
import DeleteInvoice from './DeleteInvoice';
import SelectCoupon from './SelectCoupon';
import AddCoupon from './AddNewCoupon';
import SelectTaxrate from './SelectTaxrate';
import AddTaxrate from './AddNewTax';

const { Option } = Select;

const dateFormat = 'MMM DD, YYYY';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const MOMENT_FORMAT_TRANSFERDATE = 'YYYY-MM-DD';

type Props = {
  invoiceDetails: Object,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  invoiceUpdate: Function,
  deleteInvoices: Function,
  customersList: Array<Object>,
  searchCustomers: Function,
  submitting: boolean,
  zipTag: string,
  customerAdd: Function,
  validator: boolean,
  productsList: Array<Object>,
  getProducts: Function,
  productAdd: Function,
  addedNewCustomer: Object,
  addedNewProduct: Object,
  addNewCoupon: Function,
  getTaxes: Function,
  taxList: Array<Object>,
  addNewTaxRate: Function,
  newCoupon: Object,
  newTaxrate: Object,
  setNotification: Function,
  fetchAllInvoiceSequence:Function,
  invoiceSeqNo:Array,
  addInvoiceSequence:Function,
  fundingSource: Array<Object>,
  fetchFundingSourceList: Function,
};

const EditInvoice = (props: Props) => {
  const {
    invoiceDetails,
    selectedAccount,
    invoiceUpdate,
    deleteInvoices,
    customersList,
    searchCustomers,
    submitting,
    zipTag,
    customerAdd,
    validator,
    productsList,
    getProducts,
    productAdd,
    addedNewCustomer,
    addedNewProduct,
    addNewCoupon,
    getTaxes,
    taxList,
    addNewTaxRate,
    newCoupon,
    newTaxrate,
    setNotification,
    fetchAllInvoiceSequence,
    invoiceSeqNo,
    addInvoiceSequence,
    fundingSource,
    fetchFundingSourceList,
  } = props;

  const [, forceUpdate] = useState();
  const location = useLocation();
  const [invoiceNo, setInvoiceNo] = useState(invoiceDetails.invoiceNumber);
  const [description, setDescription] = useState(invoiceDetails.description);
  const [issueDate, setIssueDate] = useState(invoiceDetails.issueDate);
  const [transferDueDate, setTransferDueDate] = useState(invoiceDetails.transferDueDate);
  const [customerName, setCustomerName] = useState(invoiceDetails.contact.name);
  const [customerId, setCustomerId] = useState(invoiceDetails && invoiceDetails.contact && invoiceDetails.contact.gid);
  const [shippingAddress, setShippingAddress] = useState(invoiceDetails.shippingAddress);
  const [shippingCntryId, setShippingCntryId] = useState(getCountryAlpha2(
    invoiceDetails.shippingAddress && invoiceDetails.shippingAddress.country ? invoiceDetails.shippingAddress.country.id : selectedAccount.country.id,
  ));
  const [billingAddress, setBillingAddress] = useState(invoiceDetails.billingAddress);
  const [billingCntryId, setBillingCntryId] = useState(getCountryAlpha2(
    invoiceDetails.billingAddress && invoiceDetails.billingAddress.country ? invoiceDetails.billingAddress.country.id : selectedAccount.country.id,
  ));
  const [notes, setNotes] = useState(invoiceDetails.vendorNote);
  const [addressModal, setAddressModal] = useState(false);
  const [editAddress, setEditAddress] = useState({});
  const [addressType, setAddressType] = useState('');
  const [addressMethod, setAddressMethod] = useState('');
  const currencyType = invoiceDetails.currency.name;
  const currencyPrefix = selectedAccount && selectedAccount.currency.prefix;
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [lineItemDtoList, setLineItemDtosList] = useState(invoiceDetails.invoiceLineItems);
  const [totalAmount, setTotalAmount] = useState(invoiceDetails.amount / 100);
  const [couponRedirect, setCouponRedirect] = useState(false);
  const [taxRedirect, setTaxRedirect] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [statesInCountry, setstatesInCountry] = useState([]);
  const dateIssued = moment.utc(invoiceDetails.issueDate).tz(selectedAccount.timezone).format(dateFormat);
  const dateScheduled = moment.utc(invoiceDetails.transferDueDate).format(dateFormat);
  const [loader, setLoader] = useState(false);
  const [invoiceFile, setInvoiceFile] = useState(invoiceDetails?.invoiceDocument);
  const [preview, setPreview] = useState(invoiceDetails?.invoiceDocument ? <PdfIcon /> : '');
  const [fileName, setFileName] = useState(invoiceDetails?.invoiceDocument ? 'invoiceDocument' : '');
  const [isEnableGenerationInvoiceNo, setIsEnableGenerationInvoiceNo] = useState(false);
  const [daysLeft, setDaysLeft] = useState(invoiceDetails.daysUntilDue);
  const [sameAddress, setSameAddress] = useState(false);
  const [addedCustomer, setAddedCustomer] = useState(false);
  const [addedProduct, setAddedProduct] = useState(false);
  const [lineItemIndex, setLineItemIndex] = useState(null);
  const [couponMsg, setCouponMsg] = useState('');
  const currencyObj = selectedAccount && selectedAccount.currency;
  const [selectCouponModal, setSelectCouponModal] = useState(false);
  const [selectTaxrateModal, setSelectTaxrateModal] = useState(false);
  const [updatedCoupon, setUpdatedCoupon] = useState(invoiceDetails.coupon ? invoiceDetails.coupon : {});
  const [transferType, setTransferType] = useState(invoiceDetails && invoiceDetails.transferType && invoiceDetails.transferType.value);
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [addTaxModal, setAddTaxModal] = useState(false);
  const [allTaxes, setAllTaxes] = useState(taxList);
  const [manageContactInfo, setManageContactInfo] = useState(fundingSource);
  const [fundingSourceGid, setFundingSourceGid] = useState((invoiceDetails && invoiceDetails?.fundingSource?.gid) || null);
  const [transferTypeLIst, setTransferTypeList] = useState(selectedAccount?.supportedTransferTypes);
  const [allSelectedTaxrates, setAllSelectedTaxrates] = useState(invoiceDetails.taxAmount ? invoiceDetails.taxAmount : []);
  const [addRecipient, setAddRecipient] = useState(invoiceDetails.recipientsEmail ?? []);
  const typeOfCurrency = selectedAccount && selectedAccount.currency.name;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const debouncedFetchFundingSourceList = debounce(fetchFundingSourceList, 2000, { leading: true });
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));
  const getFundingSourceContactDetails = (query) => {
    const parse = queryString.parse(query);
    const currentPage = parse.pageNum || 1;
    const sortBy = parse.sortBy || 'createdAt';
    const direction = parse.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parse.filterParams || '';
    const size = 1000;
    debouncedFetchFundingSourceList({
      currentPage, sortParams, filterParam, size,
    });
  };

  useEffect(() => {
    if (fundingSource && fundingSource.length > 0) {
      setManageContactInfo(fundingSource);
    }
  }, [fundingSource]);

  useEffect(() => {
    fetchAllInvoiceSequence('VENDOR_INVOICE');
    getFundingSourceContactDetails(location.search);
  }, [selectedAccount]);

  useEffect(() => {
    if (invoiceSeqNo && invoiceSeqNo.length === 0) {
      const params = {
        prefix: selectedAccount?.name?.slice(0, 4),
        type: 'VENDOR_INVOICE',
        valueSeparator: '-',
      };
      addInvoiceSequence(params);
    } else if (invoiceSeqNo && invoiceSeqNo.length > 0) {
      setIsEnableGenerationInvoiceNo(invoiceSeqNo[0]?.enableGeneration);
    }
  }, [invoiceSeqNo]);
  useEffect(() => {
    setAllTaxes(taxList);
  }, [taxList]);

  useEffect(() => {
    if (newCoupon.gid && couponRedirect) {
      setUpdatedCoupon(newCoupon);
    }
  }, [newCoupon]);

  useEffect(() => {
    if (newTaxrate.gid && taxRedirect) {
      const listOfTaxes = [...allSelectedTaxrates];
      const taxRates = {};
      Object.assign(taxRates, newTaxrate);
      const taxRate = {
        taxRates,
      };
      listOfTaxes.push(taxRate);
      setAllSelectedTaxrates(listOfTaxes);
    }
  }, [newTaxrate]);

  useEffect(() => {
    if (!validator) {
      setAddCustomerModal(false);
      setAddProductModal(false);
      setDeleteInvoiceModal(false);
      setAddCouponModal(false);
      setAddTaxModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (addedCustomer && addedNewCustomer && addedNewCustomer.gid) {
      setCustomerName(addedNewCustomer.name);
      setCustomerId(addedNewCustomer.gid);
    }
  }, [addedNewCustomer]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
      setstatesInCountry(getStateList(selectedAccount.country.id));
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (addedProduct && addedNewProduct && addedNewProduct.gid) {
      const list = [...lineItemDtoList];
      const { amount } = addedNewProduct;
      list[lineItemIndex].name = addedNewProduct.name;
      list[lineItemIndex].description = addedNewProduct.name;
      list[lineItemIndex].note = addedNewProduct.note;
      list[lineItemIndex].rate = (amount / 100).toFixed(2);
      if (list[lineItemIndex].quantity) {
        list[lineItemIndex].amount = amount * parseInt(list[lineItemIndex].quantity, 10);
      }
      const amountTotal = list.reduce((total, item) => total + ((item.amount / 100)), 0);
      setTotalAmount(amountTotal);
      setLineItemDtosList(list);
    }
  }, [addedNewProduct]);

  useEffect(() => {
    const list = [...lineItemDtoList];
    for (let i = 0; i < list.length; i += 1) {
      list[i].rate = list[i].amount && (list[i].amount / 100).toFixed(2);
      list[i].amount = list[i].amount && ((list[i].amount * list[i].quantity));
    }
    setLineItemDtosList([...list]);
  }, []);

  const getDueDate = (value) => {
    const regex = /^[0-9\b]+$/;
    if (regex.test(value)) {
      setDaysLeft(value);
      const dayIssued = moment().utc().tz(selectedAccount.timezone).startOf('day')
        .utc()
        .format(MOMENT_FORMAT);
      setIssueDate(dayIssued);
    } else {
      setDaysLeft(null);
    }
  };

  const onExpiryDateChange = (date) => {
    // eslint-disable-next-line
    const Date = moment(date).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setIssueDate(utcDate);
  };
  const onScheduleDateChange = (date) => {
    // eslint-disable-next-line
    const Date = moment(date).format(MOMENT_FORMAT_TRANSFERDATE);
    setTransferDueDate(Date);
  };
  const onProductSearch = (search) => {
    getProducts(search);
  };

  const getCustomers = (value) => {
    const results = fundingSource.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      const newAddressAdded = {
        street: results[0]?.contact?.address?.street,
        city: results[0]?.contact?.address?.city,
        state: results[0]?.contact?.address?.state,
        postalCode: results[0]?.contact?.address?.postalCode,
        countryId: getCountryAlpha2(results[0]?.contact?.address?.country?.id),
      };
      setBillingAddress(newAddressAdded);
      setShippingAddress(newAddressAdded);
      setCustomerName(results[0].contact.name);
      setCustomerId(results[0].contact.gid);
      setFundingSourceGid(results[0].gid);
    }
  };

  const onSearch = (val) => {
    // searchCustomers(searchText);
    if (val) {
      const testArray = fundingSource.filter((item) => (item.contact.name.toLowerCase()).includes(val.toLowerCase()));
      setManageContactInfo(testArray);
    } else {
      setManageContactInfo(fundingSource);
    }
  };

  const updateAddress = (address: Object) => {
    const updatedAddress = {
      street: address.streetName,
      city: address.cityName,
      state: address.stateName,
      postalCode: address.zipCode,
      countryId: address.countryId,
    };
    if (address.addressType === 'Billing Address') {
      setBillingAddress(updatedAddress);
      setBillingCntryId(getCountryAlpha2(updatedAddress.countryId));
    } else if (address.addressType === 'Shipping Address') {
      setShippingAddress(updatedAddress);
      setShippingCntryId(getCountryAlpha2(updatedAddress.countryId));
    }
    setAddressModal(false);
  };

  const addNewCustomer = (newCustomer: Object) => {
    const {
      name,
      email,
      phone,
      countryCode,
      contactType,
      customType,
      streetName,
      cityName,
      stateName,
      zipCode,
      phoneNumberCode,
    } = newCustomer;

    const params = {
      name,
      contactType,
      customType,
      email,
      phoneNumber: `${phoneNumberCode}${phone}`,
      address: {
        street: streetName,
        city: cityName,
        state: stateName,
        postalCode: zipCode,
        countryCode,
      },
    };
    customerAdd({
      params,
    });
    setAddedCustomer(true);
  };

  const addNewProduct = (newProduct: Object) => {
    const {
      productName,
      currency,
      productDescription,
      amount,
      productNotes,
    } = newProduct;

    const productAmount = amount.replace(/[. ,:-]+/g, '');

    const params = {
      name: productName,
      currencyCode: currency,
      description: productDescription,
      amount: Number(productAmount),
      note: productNotes,
      paymentMethodType: [
        'CARD',
      ],
      statementDescription: '',
    };

    productAdd({
      params,
    });
    setAddedProduct(true);
  };

  const deleteInvoice = () => {
    deleteInvoices();
  };

  const handleChange = (e, index) => {
    const gid = e[1];
    const productInfo = productsList.filter((item) => (item.gid === gid));
    const list = [...lineItemDtoList];
    const { amount } = productInfo[0];
    list[index].name = productInfo[0].name;
    list[index].description = productInfo[0].name;
    list[index].note = productInfo[0].note;
    list[index].rate = (amount / 100).toFixed(2);
    if (list[index].quantity) {
      list[index].amount = amount * parseInt(list[index].quantity, 10);
    }
    const amountTotal = list.reduce((total, item) => total + ((item.amount / 100)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const handleRemoveClick = index => {
    const list = [...lineItemDtoList];
    list.splice(index, 1);
    const amountTotal = list.reduce((total, item) => total + (item.amount / 100), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.currentTarget;
    let regex;
    if (name === 'quantity') {
      regex = /^[0-9\b]+$/;
    } else if (name === 'rate') {
      regex = /^\d*\.?\d*$/;
    }
    const list = [...lineItemDtoList];
    if (regex.test(value) || value === '') {
      list[index][name] = value;
      if (name === 'quantity' && value && !list[index].rate) {
        const sum = list[index].amount / 100;
        list[index].amount = sum * parseInt(list[index][name], 10);
      } else if (name === 'quantity' && value && list[index].rate) {
        const sum = (list[index].rate).replace(/[. ,:-]+/g, '');
        list[index].amount = sum * parseInt(list[index][name], 10);
      } else if (name === 'rate' && value && list[index].quantity) {
        const sum = (list[index].rate).replace(/[. ,:-]+/g, '');
        list[index].amount = sum * parseInt(list[index].quantity, 10);
      }
    } else if (!regex.test(value) && name === 'quantity') {
      list[index][name] = '';
    } else if (!regex.test(value) && name === 'rate') {
      list[index][name] = null;
    }
    const amountTotal = list.reduce((total, item) => total + (item.amount / 100), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const formatInputChange = (e, index) => {
    const { name, value } = e.currentTarget;
    const list = [...lineItemDtoList];
    const rateValue = parseFloat(value);
    if (Number.isInteger(rateValue)) {
      list[index][name] = parseInt(value, 10).toFixed(2);
    }
    setLineItemDtosList(list);
  };

  const handleAddClick = () => {
    getProducts();
    setLineItemDtosList([...lineItemDtoList, {
      amount: null,
      name: '',
      description: '',
      quantity: '1',
    }]);
  };

  // const createInvoice = () => {
  //   createNewInvoice();
  // };

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" />
        : (
          <SpText
            color="#279dfe"
            fontWeight="600"
            style={{ cursor: 'pointer' }}
          >
            <Icon type="upload" /> Upload Physical Invoice
          </SpText>
        )}
    </div>
  );

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 2;
    const fileType = (file.name).substring((file.name).length - 4);
    const PDF = '.pdf';
    let pattern = false;
    if (fileType === PDF) {
      pattern = true;
    }
    if (!pattern) {
      message.error('File format is not pdf');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 2MB!');
      return false;
    } return true;
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    const { file } = info;
    try {
      setLoader(true);
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(API_END_POINTS.UPLOAD_VENDORINVOICE);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const invoiceFileDetails = {
          fileUrl: entity,
        };
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(API_END_POINTS.UPLOAD_VENDORINVOICE, invoiceFileDetails);
        setInvoiceFile(fileUrl);
        setFileName(file.name);
        console.log(file.name, 'file.namefile.namefile.namefile.namefile.name');
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: MESSAGES.FILE_UPLOAD.SUCCESS,
        });
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleUploadChange = ({ file }) => {
    if (file.status === 'uploading') {
      setLoader(true);
    }
    const fileType = (file.name).substring((file.name).length - 4);
    const PDF = '.pdf';
    let pattern = false;
    if (fileType === PDF) {
      pattern = true;
    }
    if (pattern) {
      setPreview(<PdfIcon />);
    }
  };

  const fetchCustomer = (searchText) => {
    searchCustomers(searchText);
  };

  const getFinalAmount = (amount) => {
    let finalAmount;
    const listOfTaxes = [...allSelectedTaxrates];
    const taxLists = listOfTaxes.filter((item) => item.taxRates.inclusive === false);
    if (taxLists.length > 0) {
      const percentageAmount = taxLists.reduce(
        (total, item) => total + ((parseFloat(amount.replace(/,/g, '')) * item.taxRates.percentage) / 100), 0,
      );
      finalAmount = formatNumber((parseFloat(amount.replace(/,/g, '')) + percentageAmount).toFixed(2), currencyObj);
    } else {
      finalAmount = amount;
    }
    return finalAmount;
  };

  const getTotalAmount = (value) => {
    let finalAmount;
    if (value) {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      finalAmount = formatNumber((totalAmount - finalValue).toFixed(2), currencyObj);
    } else {
      finalAmount = formatNumber((totalAmount).toFixed(2), currencyObj);
    }
    return finalAmount;
  };

  const getAmountOff = (value) => {
    let amountOff;
    if (value.percentageOff) {
      const amount = (totalAmount * value.percentageOff) / 100;
      amountOff = formatNumber((amount).toFixed(2), currencyObj);
    } else if (value.amountOff) {
      amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyObj);
    }
    return amountOff;
  };

  const addCoupon = (coupon: Object) => {
    const {
      name,
      percentageOff,
      amountOff,
      validity,
      expiresAt,
      maximumRedemption,
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      promotional,
      customerGid,
      validiyInMonths,
    } = coupon;
    const params = {
      active: true,
      name,
      percentageOff: (percentageOff ? parseFloat(percentageOff) : null),
      amountOff: (amountOff ? parseInt((amountOff).replace(/[_\W]+/g, ''), 10) : null),
      validity,
      expiresAt,
      maximumRedemption: (maximumRedemption ? parseInt(maximumRedemption, 10) : null),
      validiyInMonths: (validiyInMonths ? parseInt(validiyInMonths, 10) : null),
      currencyCode: typeOfCurrency,
    };
    const payload = {
      active: true,
      code,
      enabledForCheckout: true,
      expiresAt: promoExpiresAt,
      maximumRedemption: promotionalRedeemed ? parseInt(promotionalRedeemed, 10) : 0,
      minimumAmount: minimumAmount ? parseInt((minimumAmount).replace(/[_\W]+/g, ''), 10) : 0,
      minimumAmountCurrency: typeOfCurrency,
      restrictedToCustomers: [
        customerGid,
      ],
    };
    if (promotional) {
      addNewCoupon({
        params,
        payload,
      });
    } else if (!promotional) {
      addNewCoupon({
        params,
      });
    }
    setCouponRedirect(true);
  };

  const newCouponAdd = () => {
    setSelectCouponModal(false);
    setAddCouponModal(true);
  };

  const newTaxAdd = () => {
    setSelectTaxrateModal(false);
    setAddTaxModal(true);
  };

  const updateCoupon = (Coupon: Object) => {
    setUpdatedCoupon(Coupon);
    setSelectCouponModal(false);
  };

  const removeCoupon = () => {
    setUpdatedCoupon({});
    setCouponMsg('');
    simpleValidator.current.purgeFields();
  };

  const fetchTaxDetails = (searchParams) => {
    getTaxes(searchParams);
  };

  const updateTaxrate = (Taxrate: Object) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.push(Taxrate);
    setAllSelectedTaxrates(listOfTaxes);
    setSelectTaxrateModal(false);
  };

  const removeTax = (i) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.splice(i, 1);
    setAllSelectedTaxrates(listOfTaxes);
  };

  const addTaxrate = (taxRate: Object) => {
    const {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      taxDescription,
    } = taxRate;
    const params = {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      description: taxDescription,
    };
    addNewTaxRate({
      params,
    });
    setTaxRedirect(true);
  };

  const updateInvoice = (event, value) => {
    const finalAmount = getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)));
    if (finalAmount <= 0) {
      simpleValidator.current.fields.coupon = false;
      setCouponMsg(validators.coupon.validCoupon);
    }
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let billAddress = null;
    let shipAddress = null;

    const taxesList = [];
    const listOfTaxes = [...allSelectedTaxrates];
    for (let i = 0; i < listOfTaxes.length; i += 1) {
      taxesList.push(listOfTaxes[i].taxRates.gid);
    }

    if (billingAddress && billingAddress.street) {
      billAddress = {
        street: billingAddress && billingAddress.street,
        city: billingAddress && billingAddress.city,
        state: billingAddress && billingAddress.state,
        postalCode: billingAddress && billingAddress.postalCode,
        countryCode: billingAddress && billingCntryId,
      };
    }
    if (shippingAddress && shippingAddress.street) {
      shipAddress = {
        street: shippingAddress && shippingAddress.street,
        city: shippingAddress && shippingAddress.city,
        state: shippingAddress && shippingAddress.state,
        postalCode: shippingAddress && shippingAddress.postalCode,
        countryCode: shippingAddress && shippingCntryId,
      };
    }

    const invoiceDtos = lineItemDtoList.map(({
      createdAt,
      currency,
      deleted,
      rate,
      gid,
      updatedAt,
      ...rest
    }) => ({ ...rest }));

    // eslint-disable-next-line
    const invoiceLineItemDtos = invoiceDtos.map((invoicesLine) => {
      return { ...invoicesLine, currencyCode: currencyType };
    });

    const itemList = [...invoiceLineItemDtos];

    for (let i = 0; i < itemList.length; i += 1) {
      itemList[i].amount /= itemList[i].quantity;
    }
    let filterRecipient = null;
    if (addRecipient && addRecipient.length !== 0) {
      filterRecipient = addRecipient.filter(val => val !== '');
    }
    const invoiceStatus = value;
    const invoiceParams = {
      currencyCode: currencyType,
      contactGid: customerId,
      issueDate,
      transferDueDate,
      fundingSourceGid,
      // daysUntilDue: daysLeft,
      amount: (totalAmount * 100),
      status: invoiceStatus,
      description,
      taxRates: taxesList,
      vendorNote: notes,
      transferTypeCode: transferType,
      billingAddress: billAddress,
      shippingAddress: shipAddress,
      invoiceNumber: invoiceNo,
      invoiceLineItems: itemList,
      invoiceDocument: invoiceFile,
      recipientsEmail: filterRecipient && filterRecipient.length !== 0 ? filterRecipient : null,

    };

    const params = isEnableGenerationInvoiceNo
      ? invoiceParams
      : { invoiceNumber: invoiceNo, ...invoiceParams };

    invoiceUpdate({
      params,
    });
  };
  const addNewRecipient = (event) => {
    event.preventDefault();
    setAddRecipient([...addRecipient, '']);
  };
  const handleRemoveEmail = (i) => {
    const list = [...addRecipient];
    list.splice(i, 1);
    setAddRecipient(list);
  };

  const handleViewPdf = () => {
    window.open(invoiceFile, '_blank');
  };


  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
        <Col>
          <SpStatusTag style={{ backgroundColor: getInvoiceBackground(invoiceDetails.status), height: '21px' }}>
            {getInvoiceStatus(invoiceDetails.status)}
          </SpStatusTag>
        </Col>
        <Col>
          <SpButton
            type="secondary"
            shape="round"
            className="mr-4"
            ghost
            onClick={(e) => updateInvoice(e, 'DRAFT')}
          >
            Save Draft
          </SpButton>
          <SpButton
            type="secondary"
            shape="round"
            className="mr-4"
            ghost
            onClick={() => setDeleteInvoiceModal(true)}
          >
            Delete Invoice
          </SpButton>
          <SpButton
            type="primary"
            shape="round"
            className="mr-4"
            onClick={(e) => updateInvoice(e, 'ACTIVE')}
          >
            Schedule / Pay Invoice
          </SpButton>
        </Col>
      </Row>

      {/* ADD CUSTOMER MODAL */}
      {addCustomerModal && (
        <AddNewCustomer
          visible={addCustomerModal}
          submitting={submitting}
          close={() => setAddCustomerModal(false)}
          submit={addNewCustomer}
          selectedAccount={selectedAccount}
          phoneCode={phoneCode}
          country={selectedAccount.country.name}
          cntryId={selectedAccount.country.id}
          statesInCountry={statesInCountry}
          zipTag={zipTag}
        />
      )}

      {/* ADDRESS MODAL */}
      {addressModal && (
        <Address
          visible={addressModal}
          submitting={submitting}
          close={() => setAddressModal(false)}
          submit={updateAddress}
          country={selectedAccount.country.name}
          cntryId={selectedAccount.country.id}
          editAddress={editAddress}
          addressType={addressType}
          addressMethod={addressMethod}
          zipTag={zipTag}
        />
      )}

      {/* ADD PRODUCT MODAL */}
      {addProductModal && (
        <AddNewProduct
          visible={addProductModal}
          submitting={submitting}
          close={() => setAddProductModal(false)}
          submit={addNewProduct}
          selectedAccount={selectedAccount}
        />
      )}

      {/* DELETE INVOICE MODAL */}
      {deleteInvoiceModal && (
        <DeleteInvoice
          visible={deleteInvoiceModal}
          invoiceNo={invoiceNo}
          submitting={submitting}
          close={() => setDeleteInvoiceModal(false)}
          submit={deleteInvoice}
        />
      )}

      {/* SELECT COUPON MODAL */}
      {selectCouponModal && (
        <SelectCoupon
          visible={selectCouponModal}
          submitting={submitting}
          close={() => setSelectCouponModal(false)}
          submit={updateCoupon}
          newCouponAdd={newCouponAdd}
        />
      )}

      {/* ADD COUPON MODAL */}
      {addCouponModal && (
        <AddCoupon
          visible={addCouponModal}
          submitting={submitting}
          close={() => setAddCouponModal(false)}
          submit={addCoupon}
          selectedAccount={selectedAccount}
          allCustomers={customersList}
          fetchCustomer={fetchCustomer}
        />
      )}

      {/* SELECT TAXRATE MODAL */}
      {selectTaxrateModal && (
        <SelectTaxrate
          visible={selectTaxrateModal}
          submitting={submitting}
          close={() => setSelectTaxrateModal(false)}
          submit={updateTaxrate}
          newTaxAdd={newTaxAdd}
        />
      )}

      {/* ADD TAX MODAL */}
      {addTaxModal && (
        <AddTaxrate
          visible={addTaxModal}
          submitting={submitting}
          close={() => setAddTaxModal(false)}
          submit={addTaxrate}
          selectedAccount={selectedAccount}
          allTaxes={allTaxes}
          fetchTaxDetails={fetchTaxDetails}
        />
      )}

      <SpWrapper>
        <SpFormWrapper>
          <Row type="flex" justify="space-between" className="my-2 mb-4 mt-2">
            <Col className="d-flex align-items-center">
              {
                selectedAccount && selectedAccount.largeLogo ? (
                  <img src={selectedAccount.largeLogo} alt={selectedAccount.name} style={{ width: '108px', height: '74px' }} />
                ) : (
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">{selectedAccount.name}</SpText>
                )
              }
            </Col>
            <Col className="d-flex">
              <div className="mr-2 mt-3">
                <SpText color="rgba(0, 0, 0, 0.7)">Powered by</SpText>
              </div>
              <Icon
                style={{
                  fontSize: '24px',
                }}
                component={swirepaylogo}
              />
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-4">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">INVOICE No.</SpText></div>
              <div className="d-flex pt-1 pb-1 mr-5">
                <Input
                  defaultValue={invoiceNo}
                  disabled={isEnableGenerationInvoiceNo}
                  onChange={(e) => setInvoiceNo(e.currentTarget.value)}
                  placeholder="Enter invoice number"
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Service Date</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <DatePicker
                  defaultValue={moment(dateIssued, dateFormat)}
                  format={dateFormat}
                  allowClear={false}
                  disabledDate={current => current && current < moment().subtract(1, 'days')}
                  onChange={onExpiryDateChange}
                />
              </div>
              <SpError>
                {simpleValidator.current.message('Service Date', issueDate, 'required')}
              </SpError>
            </Col>
            {/* <Col span={6} style={{ marginTop: '-25px' }}>
              <Row>
                <Col>
                  <SpText fontWeight="600">Due Date</SpText>
                  <div className="d-flex pt-1 pb-1 mr-2">
                    <Input
                      style={{ width: '60%' }}
                      className="pt-1 mr-2"
                      value={daysLeft}
                      maxlength="3"
                      onChange={(e) => getDueDate(e.currentTarget.value)}
                      onKeyPress={e => {
                        const keyCode = e.charCode || e.which;
                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <SpError>
                {simpleValidator.current.message('Due date', daysLeft, 'required|numeric')}
              </SpError>
            </Col> */}
            {/* <Col span={5} style={{ marginLeft: '-25px' }}>
              <SpText>days after issue date</SpText>
            </Col> */}
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Description</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <Input
                  value={description}
                  onChange={(e) => setDescription(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('Description', description, 'required')}
                </SpError>
              </div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Transfer Type</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                  <Select
                    className="w-100"
                    showSearch
                    value={transferType}
                    onChange={(e) => setTransferType(e)}
                  >
                    {
                      transferTypeLIst.map((item) => (
                        <Option key={1} value={item}>{item === 'RTP' ? 'INSTANT PAYMENT' : item?.replace(/_/g, ' ')}</Option>
                      ))
                    }
                  </Select>
                </div>
                <SpError>
                  {simpleValidator.current.message('transfer type', transferType, 'required')}
                </SpError>
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Contact</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                  <AutoComplete
                    placeholder="Select Contact"
                    showSearch
                    className="w-100"
                    onSearch={onSearch}
                    dropdownMenuStyle={{ width: '35rem' }}
                    dataSource={manageContactInfo.map((item, key) => {
                      const { contact, issuerBank } = item;
                      let displayDetails = `${contact?.name} | ${issuerBank?.bankName} **** ${issuerBank?.lastFour}`;
                      if (issuerBank?.isVerified) {
                        displayDetails += ' (VERIFIED)';
                      }
                      return (
                        <Option key={key} value={item.gid}>
                          {displayDetails}
                        </Option>
                      );
                    })}
                    defaultValue={customerName}
                    onSelect={(e) => getCustomers(e)}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '2px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                          <span
                            style={{
                              flex: 'none',
                              padding: '8px',
                              display: 'block',
                              cursor: 'pointer',
                              color: '#279dfe',
                            }}
                            onClick={() => setAddCustomerModal(true)}
                          >
                            <Icon type="plus-circle" />&nbsp;Add New Contact
                          </span>
                        </div>
                      </div>
                    )}
                  />
                  <SpError>
                    {simpleValidator.current.message('Customer', customerName, 'required')}
                  </SpError>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Transfer Date</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <DatePicker
                  defaultValue={moment(dateScheduled, dateFormat)}
                  format={dateFormat}
                  allowClear={false}
                  disabledDate={current => current && current < moment().subtract(1, 'days')}
                  onChange={onScheduleDateChange}
                />
              </div>
              <SpError>
                {simpleValidator.current.message('Transfer Date', transferDueDate, 'required')}
              </SpError>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="d-flex pt-1 pb-1">
                <div className="mr-3"><SpText fontWeight="600">Billing Address</SpText></div>
                <div className="d-flex ml-1">
                  {billingAddress && billingAddress.street && (
                    <>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setAddressModal(true);
                          setEditAddress(billingAddress);
                          setAddressType('Billing Address');
                          setAddressMethod('Update');
                        }}
                      >
                        Update
                      </SpText>
                      <span className="mr-2 ml-2" style={{ fontWeight: '600' }}>|</span>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setBillingAddress({});
                          setBillingCntryId();
                        }}
                      >
                        Remove
                      </SpText>
                    </>
                  )}
                </div>
              </div>
              <div className="pt-1 pb-1 pr-5">
                {
                  billingAddress && billingAddress.street ? (
                    <SpText color="#434343">
                      {`${billingAddress.street}, ${billingAddress.city},
                  ${billingAddress.state} ${billingAddress.postalCode}`}
                    </SpText>
                  ) : (
                    <SpText
                      color="#279dfe"
                      fontWeight="600"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setAddressModal(true);
                        setEditAddress(billingAddress);
                        setAddressType('Billing Address');
                        setAddressMethod('Add');
                      }}
                    >
                      {'\u002B'}&nbsp;Add billing address
                    </SpText>
                  )
                }
              </div>
            </Col>
            <Col span={12}>
              <div className="d-flex pt-1 pb-1">
                <div className="mr-3"><SpText fontWeight="600">Shipping Address</SpText></div>
                <div className="d-flex ml-1">
                  {shippingAddress && shippingAddress.street && (
                    <>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setAddressModal(true);
                          setEditAddress(shippingAddress);
                          setAddressType('Shipping Address');
                          setAddressMethod('Update');
                        }}
                      >
                        Update
                      </SpText>
                      <span className="mr-2 ml-2" style={{ fontWeight: '600' }}>|</span>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShippingAddress({});
                          setShippingCntryId();
                          setSameAddress(false);
                        }}
                      >
                        Remove
                      </SpText>
                    </>
                  )}
                </div>
              </div>
              <div className="pt-1 pb-1 pr-5">
                {
                  shippingAddress && shippingAddress.street ? (
                    <SpText color="#434343">
                      {`${shippingAddress.street}, ${shippingAddress.city},
                  ${shippingAddress.state} ${shippingAddress.postalCode}`}
                    </SpText>
                  ) : (
                    <>
                      <SpText
                        color="#279dfe"
                        fontWeight="600"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setAddressModal(true);
                          setEditAddress(shippingAddress);
                          setAddressType('Shipping Address');
                          setAddressMethod('Add');
                        }}
                      >
                        {'\u002B'}&nbsp;Add shipping address
                      </SpText>
                      <div className="d-flex mt-1">
                        <Checkbox
                          checked={sameAddress}
                          className="mb-1 mr-2"
                          onChange={({ target: { checked } }) => {
                            setSameAddress(checked);
                            setShippingAddress(billingAddress);
                          }}
                        />
                        Same as the billing address
                      </div>
                    </>
                  )
                }
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
            <Col span={10}>
              <div className="pt-1 pb-1 pl-1"><SpText fontWeight="600">Description</SpText></div>
            </Col>
            <Col span={14}>
              <Row type="flex" justify="end">
                <Col span={6} className="pt-1 mr-3" align="center">
                  <SpText fontWeight="600" fontSize="14px">Rate</SpText>
                </Col>
                <Col span={6} className="pt-1 mr-3" align="center">
                  <SpText fontWeight="600" fontSize="14px">Quantity</SpText>
                </Col>
                <Col span={6} className="pt-1 mr-3" align="right">
                  <SpText fontWeight="600" fontSize="14px">Total</SpText>
                </Col>
                <Col span={2} className="pt-1" align="right" />
              </Row>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 0 0 5px' }}>
            {lineItemDtoList.map((item, i) => (
              <>
                <Col span={10} className="pt-1 pb-1">
                  <div className="w-100 pr-3">
                    <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                      <Select
                        value={item.name}
                        placeholder="Select an item"
                        style={{ width: '100%' }}
                        showSearch
                        onSearch={onProductSearch}
                        onSelect={e => handleChange(e, i)}
                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: '2px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                              <span
                                style={{
                                  flex: 'none',
                                  padding: '8px',
                                  display: 'block',
                                  cursor: 'pointer',
                                  color: '#279dfe',
                                }}
                                onClick={() => {
                                  setAddProductModal(true);
                                  setLineItemIndex(i);
                                }}
                              >
                                <Icon type="plus-circle" />&nbsp;Add New Product
                              </span>
                            </div>
                          </div>
                        )}
                      >
                        {productsList.map((product) => (
                          <Option
                            key={product.gid}
                            value={[
                              product.name,
                              product.gid,
                            ]}
                          >
                            {product.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Col>
                <Col span={14}>
                  <Row type="flex" justify="end">
                    <Col span={6} className="mr-3">
                      <Input
                        name="rate"
                        value={item.rate}
                        placeholder="0.00"
                        onChange={e => handleInputChange(e, i)}
                        prefix={currencyPrefix}
                        onKeyPress={e => {
                          const keyCode = e.charCode || e.which;
                          if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                            e.preventDefault();
                          }
                        }}
                        onBlur={e => formatInputChange(e, i)}
                      />
                      <SpError>
                        {simpleValidator.current.message('Rate', lineItemDtoList[i].rate, 'required|amount')}
                      </SpError>
                    </Col>
                    <Col span={6} className="mr-3">
                      <Input
                        name="quantity"
                        value={item.quantity}
                        onChange={e => handleInputChange(e, i)}
                        onKeyPress={e => {
                          const keyCode = e.charCode || e.which;
                          if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('Quantity', lineItemDtoList[i].quantity, 'required')}
                      </SpError>
                    </Col>
                    <Col span={6} className="pt-2 mr-3" align="right">
                      <SpText fontWeight="600">{currencyPrefix} {formatNumber((item.amount / 100).toFixed(2), currencyObj)}</SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right">
                      {i !== 0 ? (
                        <Icon
                          type="minus-circle"
                          className="mr-2 mt-1"
                          style={{ cursor: 'pointer', color: '#ff4d50' }}
                          onClick={() => handleRemoveClick(i)}
                        />
                      ) : (
                        <span className="mr-2 mt-2">&nbsp;&nbsp;&nbsp;</span>
                      )}
                    </Col>
                  </Row>
                </Col>
              </>
            ))}
          </Row>
          <Row type="flex" className="mt-2">
            <Col span={10}>
              <div className="pt-1 pb-1 ml-2" style={{ cursor: 'pointer' }}>
                <SpText
                  fontWeight="600"
                  color="#279dfe"
                  onClick={handleAddClick}
                >
                  {'\u002B'}&nbsp; Add another item
                </SpText>
              </div>
            </Col>
            <Col span={14}>
              <Row type="flex" justify="end" className="ml-1">
                <Col span={12} className="pt-1 ml-5" align="left">
                  <SpText fontWeight="600" fontSize="14px">Subtotal</SpText>
                </Col>
                <Col span={6} className="pt-1 mr-3" align="right">
                  <SpText fontWeight="600">
                    {`${currencyPrefix} ${formatNumber((totalAmount).toFixed(2), currencyObj)}`}
                  </SpText>
                </Col>
                <Col span={2} className="pt-1" align="right" />
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="end" className="ml-2 mb-2">
            {updatedCoupon.gid ? (
              <Col span={14}>
                <Row type="flex" justify="end">
                  <Col span={12} className="pt-1 ml-3" align="left">
                    <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                      <SpText>
                        {updatedCoupon.name}
                      </SpText><br />
                      <SpText fontSize="12px">
                        {getDiscountOff(updatedCoupon)}
                      </SpText>
                    </div>
                    <div>
                      <SpError>
                        {couponMsg}
                      </SpError>
                    </div>
                  </Col>
                  <Col span={6} className="pt-2 mr-3" align="right">
                    <SpText fontWeight="600">({currencyPrefix} {getAmountOff(updatedCoupon)})</SpText>
                  </Col>
                  <Col span={2} className="pt-1" align="right">
                    <Icon
                      type="minus-circle"
                      className="mr-2 mt-1"
                      style={{ cursor: 'pointer', color: '#ff4d50' }}
                      onClick={removeCoupon}
                    />
                  </Col>
                </Row>
              </Col>
            ) : ''}
          </Row>
          <Row type="flex" justify="end" className="ml-2">
            {
              allSelectedTaxrates.map((item, i) => (
                <Col span={14}>
                  <Row type="flex" justify="end">
                    <Col span={9} className="pt-1 ml-3" align="left">
                      <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                        <SpText>
                          {item.taxRates.displayName}
                        </SpText><br />
                        <SpText fontSize="12px">
                          {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                        </SpText>
                      </div>
                    </Col>
                    <Col span={3} className="pt-1" align="right">{item.taxRates.percentage} %</Col>
                    <Col span={6} className="pt-2 mr-3" align="right">
                      <SpText fontWeight="600">
                        {currencyPrefix} {getTaxAmount(item.taxRates, totalAmount, getAmountOff(updatedCoupon), selectedAccount)}
                      </SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right">
                      <Icon
                        type="minus-circle"
                        className="mr-2 mt-1"
                        style={{ cursor: 'pointer', color: '#ff4d50' }}
                        onClick={() => removeTax(i)}
                      />
                    </Col>
                  </Row>
                </Col>
              ))
            }
          </Row>
          <Row type="flex" justify="end" className="ml-2 mb-2">
            <Col span={14}>
              <Row type="flex" justify="end">
                <Col span={12} className="pt-1 ml-3" align="left">
                  <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => setSelectTaxrateModal(true)}
                    >
                      {
                        allSelectedTaxrates.length !== 0 && (
                          <span>{'\u002B'}&nbsp;</span>
                        )
                      }
                      Add Tax Rates
                    </SpText>
                  </div>
                </Col>
                <Col span={8} className="pt-1 mr-3" align="right" />
              </Row>
            </Col>
          </Row>
          <Line opacity="0.3" className="mt-1 mb-1" />
          <Row type="flex" justify="end" className="mr-3">
            <Col className="mt-1 pb-1 mr-3 pr-1">
              <SpText className="mr-5" fontWeight="500" fontSize="20px">
                Total Amount
              </SpText>
              <SpText fontWeight="600" fontSize="20px">
                {
                  (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                    `${currencyPrefix} 0.00`
                  ) : (
                    `${currencyPrefix} ${getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))}`
                  )
                }
              </SpText>
            </Col>
          </Row>
          <Line />
          <Row type="flex" className="my-2 mt-2">
            <Col span={12}>
              <div className="mr-3">
                <SpText fontWeight="600">Recipient List</SpText>
              </div>
            </Col>
          </Row>
          {addRecipient && addRecipient.length !== 0 ? addRecipient.map((item, i) => (
            <>
              <Row type="flex" className="my-2 mt-2">
                <Col span={10}>
                  <Input
                    value={item}
                    key={`Email${i}`}
                    onChange={(e) => {
                      const list = [...addRecipient];
                      list[i] = e.currentTarget.value;
                      setAddRecipient(list);
                    }}
                    placeholder="Email"
                  />
                </Col>
                <Col span={2} className="mt-1">
                  <Icon
                    type="minus-circle"
                    style={{
                      cursor: 'pointer', color: '#ff4d50', marginLeft: '10px',
                    }}
                    onClick={() => handleRemoveEmail(i)}
                  />
                </Col>
              </Row>
              <SpError className="mb-2">
                {simpleValidator.current.message('email', item, 'email|checkLowerCase',
                  { messages: { email: 'The email must be valid' } })}
              </SpError>
            </>
          )) : null}
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="mr-3">
                <SpText
                  color="#279dfe"
                  fontWeight="600"
                  style={{ cursor: 'pointer' }}
                  onClick={addNewRecipient}
                >
                  {'\u002B'}&nbsp;Add New Recipient
                </SpText>
              </div>
            </Col>
          </Row>
          <Row className="my-2 mb-3 mt-4">
            <Col>
              <Upload
                name="avatar"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={fileUpload}
                onChange={handleUploadChange}
              >
                {fileName ? preview : uploadButton}
              </Upload>
              <SpButton
                type="primary"
                shape="round"
                className="ml-4"
                onClick={handleViewPdf}
                disabled={!invoiceFile}// Disable button if invoiceFile is an empty string
              >
                View
              </SpButton>
            </Col>
          </Row>
          <Row className="my-2 mb-3 mt-2">
            <Col>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Notes</SpText></div>
              <div className="pt-1 pb-1 mr-5">
                <Input
                  defaultValue={notes}
                  onChange={(e) => setNotes(e.currentTarget.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
            <Col>
              <SpText fontWeight="600">{selectedAccount.name}</SpText>
            </Col>
          </Row>
        </SpFormWrapper>
      </SpWrapper>
      <Row type="flex" justify="end" gutter={[16, 16]} className="pt-3">
        <Col>
          <SpH5 color="#7f7f7f">{invoiceDetails.gid}</SpH5>
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  loading: state.loading.loading,
  invoiceSeqNo: state.sequenceGenerators.invoiceSequence.content,
  fundingSource: state.fundingSource.content,

});

const mapDispatchToProps = (dispatch) => ({
  fetchFundingSourceList: param => dispatch(fundingSourceActions.fetchFundingSourceList({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchAllInvoiceSequence: param => dispatch(sequenceGeneratorsActions.fetchAllInvoiceSequence({
    payload: param,
  })),
  addInvoiceSequence: param => dispatch(sequenceGeneratorsActions.addInvoiceSequence({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(EditInvoice);
