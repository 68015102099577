import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ISSUER_RATES,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchIssuerRates = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.ISSUER_RATE}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.ISSUER_RATE}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.ISSUER_RATE}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.ISSUER_RATE}${query}${sorting}`);
};

const postIssuerRate = async payload => axiosAuthInstance.post(`${API_END_POINTS.ISSUER_RATE}`, payload);

const fetchTransferType = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=updatedAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.TRANSFER_TYPE}${query}${sorting}`);
};

const DeleteIssuerRate = async gid => axiosAuthInstance.delete(`${API_END_POINTS.ISSUER_RATES_DELETE}/${gid}`);

export function* getIssuerRates(action) {
  try {
    const { data: { entity } } = yield call(fetchIssuerRates, action.payload);
    yield put({ type: ISSUER_RATES.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: ISSUER_RATES.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addIssuerRate(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postIssuerRate, action.payload);
    const { data: { entity } } = yield call(fetchIssuerRates, action.payload);
    yield put({ type: ISSUER_RATES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ISSUER_RATE.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getTransferType(action) {
  try {
    const { data: { entity } } = yield call(fetchTransferType, action.payload);
    yield put({ type: ISSUER_RATES.TRANSFERTYPE_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: ISSUER_RATES.FAILED, payload: e.response.data });
  }
}

export function* deleteIssuer(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(DeleteIssuerRate, action.payload);
    const { data: { entity } } = yield call(fetchIssuerRates, action.payload);
    yield put({ type: ISSUER_RATES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Issuer rate deleted successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
