import React, { useState, useEffect } from 'react';
import {
  Layout, Rate, Input, Button, Row, Col, Typography, Modal,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import { useLocation } from 'react-router-dom';

import {
  axiosNoAuthInstance,
  ROOT_URL,
} from 'api';

const { Content } = Layout;
const { TextArea } = Input;
const { Title } = Typography;

const useQuery = () => new URLSearchParams(useLocation().search);

const FeedbackRating = () => {
  const [ratings, setRatings] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [isValidationModalVisible, setIsValidationModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const query = useQuery();
  const shopGid = query.get('shopGid');
  const inventoryOrderGid = query.get('inventoryOrderGid');
  const urlPath1 = `${ROOT_URL}/shop-feedback-question`;
  const urlPath2 = `${ROOT_URL}/order-feedback`;
  const urlPath3 = ROOT_URL;
  const [image, setImage] = useState('');
  const [isMobileView, setIsMobileView] = useState(true);

  const onResize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      setIsMobileView(true);
    } else if (windowWidth > 480) {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);


  // useEffect(() => {
  //   const handleResize = () => {
  //     // setIsMobile(window.innerWidth <= 800);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   console.log(window.innerWidth);
  //   // Clean up the event listener on component unmount
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);


  const modalStyle = {
    maxWidth: '400px',
    margin: '0 auto',
    maxHeight: '80vh',
    overflow: 'auto', // Allows scrolling if content is too long
  };
  const fetchInitialRating = async () => {
    try {
      const response = await axiosNoAuthInstance.get(`${urlPath1}?shopGid=${shopGid}&enabled=true`);
      console.log(response.data.entity.content);
      setRatings(response.data.entity.content);
    } catch (error) {
      console.error('Error fetching initial rating:', error);
    }
  };
  const fetchImage = async () => {
    try {
      const response = await axiosNoAuthInstance.get(`${urlPath3}/shop/${shopGid}/shop-details`);
      console.log(response.data.entity.banner);
      setImage(response.data.entity.banner);
    } catch (error) {
      console.error('Error fetching initial rating:', error);
    }
  };

  useEffect(() => {
    fetchInitialRating();
    fetchImage();
  }, []);

  const handleRatingChange = (index, value) => {
    const newRatings = [...ratings];
    newRatings[index].value = value;
    console.log(newRatings);
    setRatings(newRatings);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const transformFeedbackQuestions = (questions) => {
    const transformedQuestions = questions.map(question => ({
      shopFeedbackQuestionGid: question.gid,
      label: question.label,
      value: question.value,
    }));

    return {
      inventoryOrderGid,
      comments: feedback,
      orderFeedbackEntries: [
        ...transformedQuestions,
      ],
    };
  };

  const handleSubmit = async () => {
    const finalvalue = transformFeedbackQuestions(ratings);

    const unratedQuestions = ratings.filter((rating) => rating.value === undefined || rating.value === 0);
    if (unratedQuestions.length > 0 || unratedQuestions === undefined) {
      setIsErrorModalVisible(true);
      return;
    }

    console.log(JSON.stringify(finalvalue));

    try {
      await axiosNoAuthInstance.post(urlPath2, finalvalue);
      setIsSuccessModalVisible(true);
    } catch (error) {
      setIsValidationModalVisible(true);
      setErrorMessage(error.response.data.message
        ? error.response.data.message : 'Uh-oh! Something went wrong while submitting your feedback. Please give it another try.');
    }
  };

  const customRateStyle = {
    fontSize: '26px',
    marginTop: '-10px',
    marginLeft: '12px',
  };

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <div style={{ backgroundColor: '#FC1D56', padding: isMobileView ? '50px 0' : '20px 0' }}>
        <Row type="flex" justify="space-between">
          <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
            <img src={image} width={isMobileView ? '300' : '200'} alt="" />
          </Col>
        </Row>
      </div>
      <Content style={{ padding: isMobileView ? '5%' : '30px', width: isMobileView ? '100%' : '50%' }}>
        <Title level={5} style={{ fontSize: isMobileView ? '40px' : '30px' }}>Give Feedback</Title><br />
        <Title level={5} style={{ fontSize: isMobileView ? '20px' : '15px', marginBottom: '5%' }}>Please Share Your Experience</Title>
        {ratings && ratings.map((rating, gid) => (
          <div key={gid}>
            <Title
              level={5}
              style={{
                fontWeight: '600', fontSize: isMobileView ? '30px' : '20px',
              }}
            >{rating.question}
            </Title>

            <Rate
              value={rating.value}
              style={customRateStyle}
              onChange={(value) => handleRatingChange(gid, value)}
            />
            <br /><br /> <br />
          </div>
        ))}

        <Title
          level={5}
          style={{
            fontSize: isMobileView ? '30px' : '20px', fontWeight: '600',
          }}
        >Comment (Optional)
        </Title>
        <TextArea
          rows={8}
          value={feedback}
          onChange={handleFeedbackChange}
          placeholder="Write your feedback here..."
          style={{ fontSize: isMobileView ? '25px' : '20px' }}
        />
      </Content>
      <Row type="flex" justify="space-between">
        {
          isMobileView ? (
            <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
              <Button type="primary" onClick={handleSubmit} style={{ width: '80%' }}>
                Submit
              </Button>
            </Col>
          ) : (
            <Col span={24} className="mb-5" style={{ padding: '0% 5%' }}>
              <Button type="primary" onClick={handleSubmit} style={{ width: '40%' }}>
                Submit
              </Button>
            </Col>
          )
        }
      </Row>

      {/* Error Modal */}
      {isErrorModalVisible && (
        <Modal
          visible={isErrorModalVisible}
          onCancel={() => setIsErrorModalVisible(false)}
          footer={null}
          closeIcon={<CloseOutlined style={{ fontSize: '16px' }} />}
          style={modalStyle}
          centered
        >
          <div style={{ textAlign: 'center' }}>
            <Title level={5} style={{ fontSize: '20px' }}>Feedback Incomplete</Title>
            <p>Oops! It seems like you missed our feedback form. We&rsquo;d love to hear your thoughts.</p>
          </div>
        </Modal>
      )}

      {/* Validation Modal */}
      {isValidationModalVisible && (
        <Modal
          visible={isValidationModalVisible}
          onCancel={() => setIsValidationModalVisible(false)}
          footer={null}
          closeIcon={<CloseOutlined style={{ fontSize: '16px' }} />}
          style={modalStyle}
          centered
        >
          <div style={{ textAlign: 'center' }}>
            <Title level={5} style={{ fontSize: '20px' }}>Submission Failed</Title>
            <p style={{ fontWeight: 'bold', fontSize: '14px' }}>{errorMessage}</p>
          </div>
        </Modal>
      )}

      {/* Success Modal */}
      {isSuccessModalVisible && (
        <Modal
          visible={isSuccessModalVisible}
          onCancel={() => setIsSuccessModalVisible(false)}
          footer={null}
          closeIcon={<CloseOutlined style={{ fontSize: '16px' }} />}
          style={modalStyle}
          centered
        >
          <div style={{ textAlign: 'center' }}>
            <Title level={5} style={{ fontSize: '20px' }}>Thank you!</Title>
            <p style={{ fontWeight: 'bold', fontSize: '14px' }}> We really appreciate your feedback.</p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FeedbackRating;
