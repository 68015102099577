import React, { useEffect, useState } from 'react';
import { Card, Button } from 'antd';
// import Waiverdocs from './Waiverdocs';

const Waiver = (props: Props) => {
  const {
    data,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [storageInitialized, setIsStorageInitialized] = useState(false);

  const cardStyle = {
    borderRadius: '10px',
    width: '100%',
    marginTop: '20px',
    border: '1px solid rgba(0, 0, 0, 0.2)',

  };

  const paragraphStyle = {
    padding: '10px',
    margin: '0',
    fontWeight: '550',
  };

  const headingStyle = {
    fontWeight: 'bold',
    padding: '10px',
    margin: '0',
  };


  useEffect(() => {
    const checkStorage = () => {
      const wData = localStorage.getItem('waiverDocApproved');
      if (wData === 'true') {
        setIsStorageInitialized(true);
        localStorage.removeItem('waiverDocApproved');
        // alert("hi");
      } else {
        // alert("hello")
        setTimeout(checkStorage, 1000); // Check every second
      }
    };

    checkStorage(); // Initial check when component mounts

    // Clean up function
    return () => {
      clearTimeout(checkStorage);
    };
  }, []); // Empty dependency array to run effect only once on mount


  /* const handleButtonClick = () => {

    console.log('Button clicked!'+data.docurl);

    const encryptedParam = btoa(data.docurl);

    window.open(`/Waiverdocs/${encryptedParam}`, '_blank');

  }; */

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };

  const buttonStyle = {
    borderRadius: '4px',
    backgroundColor: 'white',
    color: 'black',
    border: '2px solid rgb(86,153,255)',
    padding: '3px 70px',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    textAlign: 'center',
  };

  return (

    <div style={{ display: 'flex', justifyContent: 'center' }}>

      <Card style={cardStyle} bordered={false}>
        <h4 style={headingStyle}>{data.label}</h4>
        <p style={paragraphStyle}>
          {data.description}
        </p>
        <div style={buttonContainerStyle}>
          <Button style={buttonStyle}>
            Click to view


          </Button>

        </div>
        <br />
      </Card>

    </div>
  );
};

export default Waiver;
