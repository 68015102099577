// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Modal,
  Checkbox,
  Row,
  Col,
  Button,
} from 'antd';
import {
  PAGES,
} from 'appconstants';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { notificationActions } from 'store/actions';
import moment from 'moment-timezone';
import { NOTIFICATION } from 'store/actionTypes';
import CompleteModal from 'imgs/CompleteModal.png';
import CancelModal from 'imgs/CancelModal.png';
import DelayTimerImage from 'imgs/delayTimer.svg';
import SimpleReactValidator from 'simple-react-validator';
import { SpText } from 'components/DesignKit';
import TimerComponent from './TimerComponent';
import UpdateItemStatus from './updateItemStatus';
import OutForDelivery from './outForDelivery';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedTab: String,
  selectedOrderItems: Object,
  selectedAccount: Object,
  updateItemsStatus: Function,
  updateDeliveryOrder: Function,
  role: Object,
  setNotification: Function,
};

const SelectedOrder = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedTab,
    selectedOrderItems,
    selectedAccount,
    updateItemsStatus,
    updateDeliveryOrder,
    role,
    setNotification,
  } = props;

  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [orderList, setorderList] = useState([]);
  const [lineItemModal, setLineItemModal] = useState(false);
  const [selectedLineItem, setSelectedLineItem] = useState();
  const [outforDeliveryModal, setOutforDeliveryModal] = useState(false);
  const [selectFullfillId, setSelectFullfillId] = useState('');
  const [allItemSKU, setAllItemSKU] = useState([]);
  const [skuInfo, setSkuInfo] = useState(true);
  const history = useHistory();
  const kdsRole = localStorage.getItem('kdsRole');
  const [timer, setTimer] = useState((selectedOrderItems && selectedOrderItems.delayTime) ? selectedOrderItems.delayTime : 0);
  const [delayTimer, setDelayTimer] = useState(false);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
      setOutforDeliveryModal(false);
    }
  }, [submitting]);

  const getValues = async (info) => {
    try {
      if (info) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}?${info}`);
        setSkuInfo(false);
        setAllItemSKU(entity && entity.content);
      }
    } catch (e) {
      console.log(e, 'e');
    }
  };

  const updateDelayTime = async () => {
    try {
      const { data: { entity } } = await axiosAuthInstance.patch(`${API_END_POINTS.DELAY_TIME}/${selectedOrderItems.gid}?delayTime=${timer}`);
      if (entity && entity.gid) {
        setTimer(entity.delayTime);
      }
      setDelayTimer(false);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: 'Delay time updated successfully',
      });
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : '',
      });
    }
  };

  useEffect(() => {
    if (skuInfo) {
      const itemsGids = selectedOrderItems && selectedOrderItems.inventoryOrderLineItems
        && selectedOrderItems.inventoryOrderLineItems.map((info) => info && info.itemGid);
      if ((itemsGids && itemsGids.length) !== 0) {
        const uniqueValues = [...new Set(itemsGids)];
        const queryString = uniqueValues.map(value => `gid.IN=${value}`).join('&');
        getValues(queryString);
      }
    }
  }, [skuInfo]);

  const getCatagory = (targetGid) => {
    const filterCategory = allItemSKU.find(item => item.gid === targetGid);
    return (filterCategory && filterCategory.categories && filterCategory.categories[0].name) || null;
  };

  const getSKU = (targetGid1) => {
    const filterCategory1 = allItemSKU.find(res => res.gid === targetGid1);
    return (filterCategory1 && filterCategory1.sku) || null;
  };

  const getItemInfo = (info) => {
    const filterCompleted = info.filter(data => data.status === 'COMPLETED');
    if (filterCompleted && filterCompleted.length !== 0) {
      return `(${filterCompleted.length} - ${info.length} items)`;
    } return `(${info.length} items)`;
  };

  const selectedRecord = (record) => {
    const orderGid = record.customer ? record.customer.gid : '';
    if (orderGid) {
      if (kdsRole !== 'KDS-ADMIN') {
        history.push({
          pathname: `${PAGES.CUSTOMERS}/${orderGid}`,
        });
      }
    }
  };

  const selectedOrders = (record) => {
    const orderGid = record.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_ORDERS}/${orderGid}`,
    });
  };

  const handleCheckboxChange = (id, status) => {
    if (status === 'COMPLETED') {
      setSelectedLineItem([id]);
      setLineItemModal(true);
    } else {
      const isSelected = lineItems.includes(id);
      const updatedSelection = isSelected
        ? lineItems.filter((itemId) => itemId !== id)
        : [...lineItems, id];

      setLineItems(updatedSelection);
    }
  };

  const handleOrderCheckboxChange = (id) => {
    const isSelected = orderList.includes(id.gid);
    const updatedSelection = isSelected
      ? orderList.filter((itemId) => itemId !== id.gid)
      : [...orderList, id.gid];
    setorderList(updatedSelection);
    let updatedAllSelection = [...lineItems];
    updatedAllSelection = [];
    id.inventoryOrderLineItems.forEach((item) => {
      updatedAllSelection = isSelected
        ? updatedAllSelection.filter((itemId) => itemId !== item.gid) // Remove item if isSelected is true
        : [...updatedAllSelection, item.gid]; // Add item if isSelected is false
    });
    setLineItems(updatedAllSelection);
  };

  const handleFullfillChange = (id) => {
    setSelectFullfillId(id);
    setOutforDeliveryModal(true);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const updateItems = lineItems.map((gid) => ({
      gid,
      status: 'COMPLETED',
    }));
    submit(updateItems);
  };

  const updateItemStatus = async (info: Array) => {
    updateItemsStatus(info);
    setLineItemModal(false);
  };

  const updateOrderStatus = async (info: Array) => {
    updateDeliveryOrder(info);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width={650}
      footer={[
        <>
          {
          !delayTimer ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                onClick={lineItems.length !== 0 ? validate : null}
                disabled={(lineItems.length === 0) || disableButton}
                style={{
                  opacity: lineItems.length === 0 ? 0.5 : '',
                  cursor: lineItems.length === 0 ? 'not-allowed' : 'pointer',
                }}
              >
                <img
                  style={{ width: '280px' }}
                  src={CompleteModal}
                  alt=""
                />
              </div>
              <div
                onClick={() => close()}
              >
                <img
                  style={{ width: '280px' }}
                  src={CancelModal}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <></>
          )
        }
        </>,
      ]}
    >
      {
        selectedTab === 'Inprogress' ? (
          <>
            {
              delayTimer ? (
                <div className="px-4">
                  <Row type="flex" justify="space-between">
                    <Col span={18} className="d-flex justify-content-start">
                      <SpText fontSize="16px" fontWeight="700">
                        Confirmation
                      </SpText>
                    </Col>
                    <Col span={6} className="d-flex justify-content-end">
                      <SpText fontSize="16px" fontWeight="700" style={{ cursor: 'pointer' }} onClick={() => { setTimer(0); setDelayTimer(false); }}>
                        X
                      </SpText>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" className="mt-4">
                    <Col span={24} className="d-flex justify-content-start">
                      <SpText fontSize="14px" fontWeight="600">
                        This order has been delayed. Would you prefer to have a delay time of {timer} minutes?
                      </SpText>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" className="mt-3">
                    <Col span={24} className="d-flex justify-content-end">
                      <Button
                        type="secondary"
                        shape="round"
                        className="align-items-center justify-content-center"
                        onClick={() => { setTimer(0); setDelayTimer(false); }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        shape="round"
                        className="align-items-center justify-content-center ml-2"
                        onClick={() => updateDelayTime()}
                      >
                        Ok
                      </Button>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className="px-4">
                  <div
                    style={{
                      background: '#F9DC5C',
                      color: '#000000',
                    }}
                  >
                    {/* SELECTED ITEM MODAL */}
                    {
                  lineItemModal && (
                    <UpdateItemStatus
                      visible={lineItemModal}
                      submitting={submitting}
                      selectedTab={selectedTab}
                      close={() => setLineItemModal(false)}
                      selectedAccount={selectedAccount}
                      selectedLineItem={selectedLineItem}
                      submit={updateItemStatus}
                    />
                  )
                }
                    <Row
                      type="flex"
                      justify="space-between"
                      className="pt-2"
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        fontSize: '22px',
                      }}
                    >
                      <Col
                        className="ml-3"
                      >
                        <span onClick={() => selectedOrders(selectedOrderItems)} style={{ cursor: 'pointer' }}>
                          {(selectedOrderItems.cloverPosOrderId) || (selectedOrderItems.orderNumber)}
                        </span>
                      </Col>
                      <Col>
                        {
                      selectedOrderItems.createdAt
                        ? (
                          moment
                            .utc(selectedOrderItems.createdAt)
                            .tz(selectedAccount && selectedAccount.timezone)
                            .format('MMM DD, YYYY, hh:mm a')
                        )
                        : <>&#8211;</>
                    }
                      </Col>
                      <Col
                        className="mr-3"
                      >
                        <TimerComponent
                          startTime={selectedOrderItems.createdAt}
                          selectedAccount={selectedAccount}
                        />
                      </Col>
                    </Row>
                    <Row
                      type="flex"
                      justify="space-between"
                    >
                      <Col
                        className="ml-3"
                        style={{
                          fontFamily: 'italic',
                          fontWeight: '400',
                          fontSize: '22px',
                        }}
                      >
                        <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(selectedOrderItems)}>
                          {selectedOrderItems.customer
                            ? ((selectedOrderItems.customer && selectedOrderItems.customer.name) || '')
                            : (selectedOrderItems.employeeName || '')}
                        </span>
                      </Col>
                      <Col
                        className="mr-3"
                        style={{
                          fontFamily: 'italic',
                          fontWeight: '400',
                          fontSize: '22px',
                        }}
                      >
                        {selectedOrderItems.shop
                          ? ((selectedOrderItems.shop && selectedOrderItems.shop.name) || '')
                          : <>&#8211;</>}
                      </Col>
                    </Row>
                  </div>
                  {
                (selectedOrderItems.shopTableReservationDto && selectedOrderItems.shopTableReservationDto.shopTable) ? (
                  <div
                    style={{
                      background: '#FF0054',
                      color: '#FFFFFF',
                    }}
                  >
                    <Row
                      type="flex"
                      justify="space-between"
                      className="pt-2"
                    >
                      <Col
                        className="ml-3"
                        style={{
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                          fontSize: '22px',
                        }}
                      >
                        <Checkbox
                          className="mr-2"
                          onChange={() => handleOrderCheckboxChange(selectedOrderItems)}
                          checked={(selectedOrderItems.status === 'COMPLETED') || orderList.includes(selectedOrderItems.gid)}
                        />
                        {(selectedOrderItems.shopTableReservationDto && selectedOrderItems.shopTableReservationDto.shopTable
                          && selectedOrderItems.shopTableReservationDto.shopTable.name) || ''}
                      </Col>
                      <Col
                        className="mr-3"
                        style={{
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                          fontSize: '22px',
                        }}
                      >
                        {getItemInfo(selectedOrderItems.inventoryOrderLineItems)}123
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div
                    style={{
                      background: '#0085A3',
                      color: '#FFFFFF',
                    }}
                  >
                    <Row
                      type="flex"
                      justify="space-between"
                      className="pt-2"
                    >
                      <Col
                        className="ml-3"
                        style={{
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                          fontSize: '22px',
                        }}
                      >
                        <Checkbox
                          className="mr-2"
                          onChange={() => handleOrderCheckboxChange(selectedOrderItems)}
                          checked={(selectedOrderItems.status === 'COMPLETED') || orderList.includes(selectedOrderItems.gid)}
                        />
                        {(selectedOrderItems.inventoryOrderType && selectedOrderItems.inventoryOrderType.label) || ''}
                      </Col>
                      <Col
                        className="mr-3"
                        style={{
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                          fontSize: '22px',
                        }}
                      >
                        {getItemInfo(selectedOrderItems.inventoryOrderLineItems)}
                      </Col>
                    </Row>
                  </div>
                )
              }

                  <div
                    style={{
                      background: '#F3F3F3',
                      color: '#000000',
                    }}
                  >

                    {
                  (selectedOrderItems.inventoryOrderLineItems && selectedOrderItems.inventoryOrderLineItems.length > 0) ? (
                    selectedOrderItems.inventoryOrderLineItems.map((items) => (
                      <>
                        {
                          (items.itemData && items.itemData.categories && items.itemData.categories.length !== 0) ? (
                            <Row
                              type="flex"
                              className="pt-2"
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: '800',
                                fontSize: '18px',
                                color: '#3C40A3',
                              }}
                            >
                              <Col className="ml-3">
                                {
                                  items.itemData ? (items.itemData && items.itemData.categories[0].name)
                                    : allItemSKU && allItemSKU.length !== 0
                                      ? getCatagory(items.itemGid) : ''
                                }
                              </Col>
                            </Row>
                          ) : null
                        }
                        <Row
                          type="flex"
                          className={(items.itemData && items.itemData.categories && items.itemData.categories.length !== 0) ? null : 'pt-2'}
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                            fontSize: '18px',
                          }}
                        >
                          <Col
                            className="ml-3"
                          >
                            <Checkbox
                              className="mr-2"
                              checked={(items.status === 'COMPLETED') || lineItems.includes(items.gid)}
                              disabled={orderList.includes(selectedOrderItems.gid) || (role && role.name === 'associate') || false}
                              onChange={() => handleCheckboxChange(items.gid, items.status)}
                            />
                            {items.quantity} X {items.itemName || (items.itemData && items.itemData.name)}
                            {items.itemData && items.itemData.sku ? (
                              <SpText fontStyle="italic" fontSize="14px" color="#6E6E6E">{` (${items.itemData.sku})`}</SpText>
                            ) : allItemSKU && allItemSKU.length !== 0
                              ? (
                                <SpText fontStyle="italic" fontSize="14px" color="#6E6E6E">&nbsp;{getSKU(items.itemGid)}</SpText>
                              ) : ''}
                            {
                              (items.lineItemModifiers && items.lineItemModifiers.length > 0) ? (
                                items.lineItemModifiers.map(modifier => (
                                  <Row
                                    className="ml-4"
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontWeight: '500',
                                      fontSize: '16px',
                                      color: '#000000',
                                    }}
                                  >
                                    <Col className="ml-4">
                                      {modifier.quantity} x {modifier.modifierName}
                                    </Col>
                                  </Row>
                                ))
                              ) : ''
                            }
                            {
                              items.note && (
                                <Row
                                  className="ml-4"
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    color: '#000000',
                                  }}
                                >
                                  <Col>{`(${items.note})`}</Col>
                                </Row>
                              )
                            }
                          </Col>
                        </Row>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        fontSize: '20px',
                        marginLeft: '20px',
                        marginTop: '10px',
                      }}
                    >
                      No Items
                    </div>
                  )
                }
                  </div>
                  <Row type="flex" justify="space-between" className="mt-2">
                    <Col span={24} className="d-flex justify-content-end align-items-center">
                      <SpText fontSize="10px" fontWeight="600" className="mr-2" style={{ textAlign: 'center' }}>
                        <img src={DelayTimerImage} alt="timer" width="30" /> <br /> Delay time
                      </SpText>
                      <SpText
                        fontSize="20px"
                        fontWeight="600"
                        style={{ backgroundColor: '#DBDBDB', padding: '0 12px', cursor: 'pointer' }}
                        onClick={() => (timer !== 0 ? setTimer(timer - 1) : '')}
                      >
                        &#8211;
                      </SpText>
                      <SpText fontSize="14px" fontWeight="600" style={{ backgroundColor: '#FFFFFF', padding: '4px 12px' }}>
                        {timer}
                      </SpText>
                      <SpText
                        fontSize="20px"
                        fontWeight="600"
                        style={{ backgroundColor: '#DBDBDB', padding: '0 12px', cursor: 'pointer' }}
                        onClick={() => setTimer(timer + 1)}
                      >
                        +
                      </SpText>
                      <Button
                        type="primary"
                        shape="round"
                        disabled={timer === 0}
                        className="align-items-center justify-content-center ml-2"
                        onClick={() => setDelayTimer(true)}
                      >
                        Set
                      </Button>
                    </Col>
                  </Row>
                </div>
              )
          }

          </>
        ) : (
          <div className="px-4">
            {/* SELECTED OUT FOR DELIVERY MODAL */}
            {
              outforDeliveryModal && (
                <OutForDelivery
                  visible={outforDeliveryModal}
                  submitting={submitting}
                  selectedTab={selectedTab}
                  close={() => setOutforDeliveryModal(false)}
                  selectedAccount={selectedAccount}
                  selectedLineItem={selectFullfillId}
                  submit={updateOrderStatus}
                />
              )
            }
            <div
              style={{
                background: selectedTab === 'Completed'
                  ? '#1AB58B' : selectedTab === 'OutforDelivery'
                    ? '#FD5D5D' : selectedTab === 'AcceptedAffiliate'
                      ? '#2CADD6' : '#045762',
                color: '#FFFFFF',
              }}
            >
              <Row
                type="flex"
                justify="space-between"
                className="pt-2"
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  fontSize: '22px',
                }}
              >
                <Col
                  className="ml-3"
                >
                  <span onClick={() => selectedOrders(selectedOrderItems)} style={{ cursor: 'pointer' }}>
                    {(selectedOrderItems.cloverPosOrderId) || (selectedOrderItems.orderNumber)}

                  </span>
                  {
                    selectedTab === 'Completed' && (
                      <Checkbox
                        className="ml-3"
                        onChange={() => handleFullfillChange(selectedOrderItems.gid)}
                        checked={outforDeliveryModal}
                        disabled={(role && role.name === 'associate') || false}
                      />
                    )
                  }
                </Col>
                <Col>
                  {
                    selectedOrderItems.createdAt
                      ? (
                        moment
                          .utc(selectedOrderItems.createdAt)
                          .tz(selectedAccount && selectedAccount.timezone)
                          .format('MMM DD, YYYY, hh:mm a')
                      )
                      : <>&#8211;</>
                  }
                </Col>
                <Col
                  className="mr-3"
                >
                  <TimerComponent
                    startTime={selectedOrderItems.createdAt}
                    selectedAccount={selectedAccount}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
              >
                <Col
                  className="ml-3"
                  style={{
                    fontFamily: 'italic',
                    fontWeight: '400',
                    fontSize: '22px',
                  }}
                >
                  <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(selectedOrderItems)}>
                    {selectedOrderItems.customer
                      ? ((selectedOrderItems.customer && selectedOrderItems.customer.name) || '')
                      : (selectedOrderItems.employeeName || '')}
                  </span>
                </Col>
                <Col
                  className="mr-3"
                  style={{
                    fontFamily: 'italic',
                    fontWeight: '400',
                    fontSize: '22px',
                  }}
                >
                  {selectedOrderItems.shop
                    ? ((selectedOrderItems.shop && selectedOrderItems.shop.name) || '')
                    : <>&#8211;</>}
                </Col>
              </Row>
            </div>
            {
              (selectedOrderItems.shopTableReservationDto && selectedOrderItems.shopTableReservationDto.shopTable) ? (
                <div
                  style={{
                    background: '#FF0054',
                    color: '#FFFFFF',
                  }}
                >
                  <Row
                    type="flex"
                    justify="space-between"
                    className="pt-2"
                  >
                    <Col
                      className="ml-3"
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        fontSize: '22px',
                      }}
                    >
                      {(selectedOrderItems.shopTableReservationDto && selectedOrderItems.shopTableReservationDto.shopTable
                        && selectedOrderItems.shopTableReservationDto.shopTable.name) || ''}
                    </Col>
                    <Col
                      className="mr-3"
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        fontSize: '22px',
                      }}
                    >
                      {`(${(selectedOrderItems.inventoryOrderLineItems && selectedOrderItems.inventoryOrderLineItems.length)} Items)`}
                    </Col>
                  </Row>
                </div>
              ) : (
                <div
                  style={{
                    background: '#858585',
                    color: '#FFFFFF',
                  }}
                >
                  <Row
                    type="flex"
                    justify="space-between"
                    className="pt-2"
                  >
                    <Col
                      className="ml-3"
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        fontSize: '22px',
                      }}
                    >
                      {(selectedOrderItems.inventoryOrderType && selectedOrderItems.inventoryOrderType.label) || ''}
                    </Col>
                    <Col
                      className="mr-3"
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        fontSize: '22px',
                      }}
                    >
                      {`(${(selectedOrderItems.inventoryOrderLineItems && selectedOrderItems.inventoryOrderLineItems.length)} Items)`}
                    </Col>
                  </Row>
                </div>
              )
            }
            <div
              style={{
                background: '#F3F3F3',
                color: '#000000',
              }}
            >

              {
                (selectedOrderItems.inventoryOrderLineItems && selectedOrderItems.inventoryOrderLineItems.length > 0) ? (
                  selectedOrderItems.inventoryOrderLineItems.map((items) => (
                    <>
                      {
                        (items.itemData && items.itemData.categories && items.itemData.categories.length !== 0) ? (
                          <Row
                            type="flex"
                            className="pt-2"
                            style={{
                              fontFamily: 'Roboto',
                              fontWeight: '800',
                              fontSize: '18px',
                              color: '#3C40A3',
                            }}
                          >
                            <Col className="ml-3">
                              {
                                items.itemData ? (items.itemData && items.itemData.categories[0].name)
                                  : allItemSKU && allItemSKU.length !== 0
                                    ? getCatagory(items.itemGid) : ''
                              }
                            </Col>
                          </Row>
                        ) : null
                      }
                      <Row
                        type="flex"
                        className={(items.itemData && items.itemData.categories && items.itemData.categories.length !== 0) ? null : 'pt-2'}
                        style={{
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                          fontSize: '18px',
                        }}
                      >
                        <Col
                          className="ml-3"
                        >
                          <Checkbox
                            className="mr-2"
                            checked={(items.status === 'COMPLETED') || lineItems.includes(items.gid)}
                            disabled={items.status === 'COMPLETED'}
                            onChange={() => handleCheckboxChange(items.gid)}
                          />
                          {items.quantity} X {items.itemName || (items.itemData && items.itemData.name)}
                          {items.itemData && items.itemData.sku ? (
                            <SpText fontStyle="italic" fontSize="14px" color="#6E6E6E">{` (${items.itemData.sku})`}</SpText>
                          ) : allItemSKU && allItemSKU.length !== 0
                            ? (
                              <SpText fontStyle="italic" fontSize="14px" color="#6E6E6E">&nbsp;{getSKU(items.itemGid)}</SpText>
                            ) : ''}
                          {
                            (items.lineItemModifiers && items.lineItemModifiers.length > 0) ? (
                              items.lineItemModifiers.map(modifier => (
                                <Row
                                  className="ml-4"
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    color: 'rgba(0, 0, 0, 0.5)',
                                  }}
                                >
                                  <Col>
                                    {modifier.quantity} x {modifier.modifierName}
                                  </Col>
                                </Row>
                              ))
                            ) : ''
                          }
                          {
                            items.note && (
                              <Row
                                className="ml-4"
                                style={{
                                  fontFamily: 'Roboto',
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  color: '#000000',
                                }}
                              >
                                <Col>{`(${items.note})`}</Col>
                              </Row>
                            )
                          }
                        </Col>
                      </Row>
                    </>
                  ))
                ) : (
                  <div
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: '500',
                      fontSize: '20px',
                      marginLeft: '20px',
                      marginTop: '10px',
                    }}
                  >
                    No Items
                  </div>
                )
              }
            </div>
          </div>
        )
      }

    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(SelectedOrder);
