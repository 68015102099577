/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  Select,
  Switch,
  Collapse,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpText, SpButton, SpError,
} from 'components/DesignKit';
import {
  PAGES,
} from 'appconstants';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloverSyncActions, cloverWebhookActions, notificationActions } from 'store/actions';

const { Option } = Select;

type Props = {
  loader: boolean,
  validator: boolean,
  cloverWebhook: Array,
  fetchCloverSync: Function,
  addCloverSync: Function,
  updateCloverSync: Function,
  cloverWebhookCredentials: Object;
  fetchCloverWebhook: Function,
  updateCloverWebhook: Function,
  addBulkImportWebHook: Function,
};

const CloverSync = (props: Props) => {
  const {
    loader,
    cloverWebhook,
    fetchCloverSync,
    addCloverSync,
    updateCloverSync,
    validator,
    cloverWebhookCredentials,
    fetchCloverWebhook,
    updateCloverWebhook,
    addBulkImportWebHook,
  } = props;
  const history = useHistory();
  const [, forceUpdate] = useState();
  const [selectOrder, setSelectOrder] = useState('DURING_CREATION');
  const [selectOrderPrint, setSelectOrderPrint] = useState('DURING_CREATION');
  const [paymentPrint, setPaymentPrint] = useState(null);
  const [selectManageInventory, setSelectManageInventory] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [confirmBulkImport, setConfirmBulkImport] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const cloverId = cloverWebhook && cloverWebhook[0] && cloverWebhook[0].gid;
  const [manageInventory, setManageInventory] = useState((cloverWebhookCredentials && cloverWebhookCredentials.manageCloverInventory) || false);

  const addNewWebhook = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      orderPushToClover: selectOrder,
      orderPrint: selectOrderPrint,
    };
    addCloverSync({
      params,
    });
    setRedirect(true);
  };

  const updateWebhook = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      orderPushToClover: selectOrder,
      orderPrint: selectOrderPrint,
      paymentPrint,
    };
    updateCloverSync({
      gid: cloverId,
      params,
    });
    setRedirect(true);
  };

  useEffect(() => {
    fetchCloverSync();
    fetchCloverWebhook({});
  }, []);

  useEffect(() => {
    if (cloverWebhook && cloverWebhook.length !== 0) {
      setSelectOrder(cloverWebhook && cloverWebhook[0] && cloverWebhook[0].orderPushToClover);
      setSelectOrderPrint(cloverWebhook && cloverWebhook[0] && cloverWebhook[0].orderPrint);
      setPaymentPrint(cloverWebhook && cloverWebhook[0] && cloverWebhook[0].paymentPrint);
    }
  }, [cloverWebhook]);

  useEffect(() => {
    if (!validator && redirect) {
      history && history.push(PAGES.SETTINGS);
    }
    setConfirmBulkImport(false);
    setInitialRender(false);
  }, [validator]);

  useEffect(() => {
    if (cloverWebhookCredentials) setManageInventory(cloverWebhookCredentials.manageCloverInventory);
  }, [cloverWebhookCredentials]);

  useEffect(() => {
    if (cloverWebhookCredentials.manageCloverInventory !== manageInventory && !initialRender) {
      updateCloverWebhook({ manageCloverInventory: manageInventory });
    } else {
      setInitialRender(false);
    }
  }, [manageInventory]);

  if (loader) {
    return <Loading />;
  }

  const onChangeCollapse = () => {
    setSelectManageInventory(prevState => !prevState);
  };

  return (
    <>
      <Card className="pl-2 mb-3">
        <>
          <Row className="mt-3 " type="flex" justify="end" gutter={[16, 16]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                onClick={() => history && history.push(PAGES.SETTINGS)}
              >
                Cancel
              </SpButton>
              {
                (cloverWebhook && cloverWebhook.length !== 0) ? (
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={() => updateWebhook()}
                  >
                    Update
                  </SpButton>
                ) : (
                  <SpButton
                    type="primary"
                    shape="round"
                    onClick={() => addNewWebhook()}
                  >
                    Save
                  </SpButton>
                )
              }
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={8}>
              <SpText fontWeight="600">Order Push To Clover</SpText>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={8}>
              <Select
                className="w-100"
                value={selectOrder || 'Select order push'}
                onChange={(e) => setSelectOrder(e)}
              >
                <Option key={1} value="DURING_CREATION">During Creation</Option>
                <Option key={2} value="DURING_PAYMENT">During Payment</Option>
              </Select>
              <SpError>
                {simpleValidator.current.message('order push', selectOrder, 'required')}
              </SpError>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
            <Col span={8}>
              <SpText fontWeight="600">Order Print</SpText>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={8}>
              <Select
                className="w-100"
                value={selectOrderPrint || 'Select order print'}
                onChange={(e) => setSelectOrderPrint(e)}
              >
                <Option key={1} value="DURING_CREATION">During Creation</Option>
                <Option key={2} value="DURING_PAYMENT">During Payment</Option>
              </Select>
              <SpError>
                {simpleValidator.current.message('order print', selectOrderPrint, 'required')}
              </SpError>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mt-3 mb-3">
            <Col span={8}>
              <SpText fontWeight="600">Payment Print</SpText>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={8}>
              <Select
                className="w-100"
                value={paymentPrint || 'Select payment print'}
                onChange={(e) => setPaymentPrint(e)}
              >
                <Option key={1} value="DURING_CREATION">During Creation</Option>
                <Option key={2} value="DURING_PAYMENT">During Payment</Option>
                <Option key={3} value="NONE">None</Option>
              </Select>
            </Col>
          </Row>
        </>
      </Card>
      <Card>
        <Collapse onClick={onChangeCollapse} style={{ border: 'none' }}>
          <Collapse.Panel header={<div style={{ fontSize: '16px', fontWeight: 'bold' }}>Manage Clover Inventory</div>} style={{ borderBottom: 'none' }}>
            <Row type="flex" justify="start" align="middle" className="mb-3">
              <Col span={8}>
                <Switch
                  className="mr-2"
                  defaultChecked={manageInventory}
                  onChange={(e) => setManageInventory(e)}
                />{' '}
                Manage Clover Inventory from Swirepay
              </Col>
            </Row>
            {!manageInventory && (
            <Row type="flex" justify="start" align="middle" className="mt-4 ml-4 mb-3">
              <Col span={8}>
                <SpButton
                  type="primary"
                  shape="round"
                  onClick={() => setConfirmBulkImport(true)}
                >
                  Bulk Import Clover Inventory
                </SpButton>
              </Col>
            </Row>
            )}
            {/* Warning Page */}
            {confirmBulkImport && (
            <Modal
              centered
              closable
              visible
              footer={null}
              onCancel={() => setConfirmBulkImport(false)}
              title={(
                <div style={{
                  fontFamily: 'Open Sans', fontStyle: 'normal', fontWeight: '700', fontSize: '16px', lineHeight: '22px',
                }}
                >Do you want to start the import?
                </div>
)}
            >
              <div style={{
                paddingTop: '15px',
                paddingRight: '80px',
                paddingBottom: '130px',
                paddingLeft: '30px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '169.7%',
              }}
              >
                <div className="w-100">This Import job will run on background and will take some time to complete.</div>
                <div className="w-100"> The status of import job will be communicated via an email.</div>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: '18px 28px 18px 275px',
                gap: '13px',
                position: 'absolute',
                width: '520px',
                height: '68px',
                left: '0px',
                top: '259px',
                background: '#FFFFFF',
                borderRadius: '0px 0px 8px 8px',
                zIndex: '9',
              }}
              >
                <SpButton onClick={() => setConfirmBulkImport(false)} type="secondary" style={{ marginRight: '10px' }}>Cancel</SpButton>
                <SpButton onClick={() => addBulkImportWebHook()}>Proceed</SpButton>
              </div>
            </Modal>
            )}
          </Collapse.Panel>
        </Collapse>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  cloverWebhookCredentials: state.cloverWebhook,
  cloverWebhook: state.cloverSync.content,
  salesforceError: state.salesforce.salesforceError,
  loader: state.loading.loading,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  updateInternalCloverWebhook: param => dispatch(cloverWebhookActions.updateInternalCloverWebhook({
    payload: param,
  })),
  fetchCloverSync: param => dispatch(cloverSyncActions.fetchCloverSync({
    payload: param,
  })),
  addCloverSync: param => dispatch(cloverSyncActions.addCloverSync({
    payload: param,
  })),
  updateCloverSync: param => dispatch(cloverSyncActions.updateCloverSync({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  updateCloverWebhook: param => dispatch(cloverWebhookActions.updateCloverWebhook({
    payload: param,
  })),
  fetchCloverWebhook: param => dispatch(cloverWebhookActions.fetchCloverWebhook({
    payload: param,
  })),
  addBulkImportWebHook: param => dispatch(cloverWebhookActions.addBulkImportWebHook({
    payload: param,
  })),
});


export default connect(mapStateToProps, mapDispatchToProps)(CloverSync);
