/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import { Col, ScreenClass } from 'react-awesome-styled-grid';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { h1FontSize, normalTextFontSize } from '../../../utils/reseponsive';

import { DescriptionText, PH1Text, PText } from '../../../design';

type Props = {
  content: string,
  imageUrl: string,
  title: string,
  isPreview: boolean,
  isAdaptive: boolean,
  venueAddress: String,
  coordinate: Object,
  ytVideoId: String,
  ytVideoDescription: String,
  ytVideoHeading: String,
  primaryColor: String,
  imageSupport: String,
}
const ProductDetails = (props: Props) => {
  const {
    content,
    imageUrl,
    title,
    isAdaptive,
    venueAddress,
    primaryColor,
    coordinate,
    ytVideoId,
    ytVideoDescription,
    ytVideoHeading,
    isPreview,
    imageSupport,
  } = props;
  const [showProductDescription, setShowProductDescription] = useState(false);
  const [showVenueDescription, setShowVenueDescription] = useState(false);
  const [showytDescription, setShowytDescription] = useState(false);
  const duration = 200;

  const defaultStyle = {
    transition: `opacity ${duration}ms linear`,
    opacity: 0,
    display: 'none',
  };

  const transitionStyles = {
    entering: { opacity: 0.25, display: 'block' },
    entered: { opacity: 1, display: 'block' },
    exiting: { opacity: 0, display: 'block' },
    exited: { opacity: 0, display: 'none' },
  };
  return (
    <>
      <PH1Text fontWeight={700} fontSize={h1FontSize(isPreview, isAdaptive)}>{title}</PH1Text>
      <Col className="px-0">
        <ScreenClass render={(screen) => (
          <>
            <DescriptionText
              hidden={!['xs', 'sm'].includes(screen)}
              fontSize="15px"
              color="#1791ff"
              onClick={() => setShowProductDescription(prevState => !prevState)}
            >
              <span>{showProductDescription ? 'Hide' : 'See'}</span>&nbsp;Description
            </DescriptionText>
            <Transition
              in={['xs', 'sm'].includes(screen) ? showProductDescription : true}
              timeout={duration}
            >
              {(stateTransition) => (
                <div
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[stateTransition],
                  }}
                >
                  <PText fontWeight={400} fontSize={normalTextFontSize(isPreview, isAdaptive)} className="mt-2">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </PText>
                  {imageUrl && (
                  <div className="mt-3 d-flex align-items-center">
                    <img
                      className="rounded-sm"
                      // eslint-disable-next-line no-param-reassign
                      onError={i => i.target.style.display = 'none'}
                      alt="product-img"
                      src={imageUrl}
                      style={{
                        maxWidth: imageSupport === 'HORIZONTAL' ? '92%' : '100%',
                        width: imageSupport === 'HORIZONTAL' ? '92%' : '100%',
                        transform: imageSupport === 'HORIZONTAL' ? 'rotate(90deg)' : 'rotate(0)',
                      }}
                    />
                  </div>
                  )}
                </div>
              )}
            </Transition>

          </>
        )}
        />
      </Col>
      {
        ytVideoId && (
          <>
            <Col className="px-0">
              <ScreenClass render={(screen) => (
                <>
                  <div className="mt-3 d-flex">
                    <div
                      style={{
                        height: '9px', width: '9px', backgroundColor: (primaryColor || '#40A9FF'), marginTop: '0.7rem', marginLeft: '-1.0rem',
                      }}
                      className="d-flex align-items-center"
                    >&nbsp;
                    </div>
                    <div style={{ marginLeft: '0.5rem' }}>
                      <PText fontWeight={600} fontSize={normalTextFontSize(isPreview, isAdaptive)}>{ytVideoHeading}</PText>
                    </div>
                  </div>
                  <DescriptionText
                    hidden={!['xs', 'sm'].includes(screen)}
                    fontSize="15px"
                    color="#1791ff"
                    onClick={() => setShowytDescription(prevState => !prevState)}
                  >
                    <span>{showytDescription ? 'Hide' : 'See'}</span>&nbsp;Youtube Details
                  </DescriptionText>
                  <Transition
                    in={['xs', 'sm'].includes(screen) ? showytDescription : true}
                    timeout={duration}
                  >
                    {(stateTransition) => (
                      <div
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[stateTransition],
                          position: 'relative',
                        }}
                      >

                        <PText fontWeight={400} fontSize={normalTextFontSize(isPreview, isAdaptive)} className="mt-1">{ytVideoDescription}</PText>
                        <div className="mt-3">
                          <iframe
                            src={`https://www.youtube.com/embed/${ytVideoId}`}
                            title={ytVideoDescription}
                            frameBorder="0"
                            style={{ width: '100%', height: '300px' }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      </div>
                    )}
                  </Transition>

                </>
              )}
              />
            </Col>
          </>
        )
      }
      {
        coordinate && (
          <>
            <Col className="px-0">
              <ScreenClass render={(screen) => (
                <>
                  <div className="mt-3 d-flex">
                    <div
                      style={{
                        height: '9px', width: '9px', backgroundColor: (primaryColor || '#40A9FF'), marginTop: '0.7rem', marginLeft: '-1.0rem',
                      }}
                      className="d-flex align-items-center"
                    >&nbsp;
                    </div>
                    <div style={{ marginLeft: '0.5rem' }}>
                      <PText fontWeight={600} fontSize={normalTextFontSize(isPreview, isAdaptive)}>Venue</PText>
                    </div>
                  </div>
                  <DescriptionText
                    hidden={!['xs', 'sm'].includes(screen)}
                    fontSize="15px"
                    color="#1791ff"
                    onClick={() => setShowVenueDescription(prevState => !prevState)}
                  >
                    <span>{showVenueDescription ? 'Hide' : 'See'}</span>&nbsp;Venue Details
                  </DescriptionText>
                  <Transition
                    in={['xs', 'sm'].includes(screen) ? showVenueDescription : true}
                    timeout={duration}
                  >
                    {(stateTransition) => (
                      <div
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[stateTransition],
                          position: 'relative',
                          height: '350px',
                        }}
                      >
                        <PText fontWeight={400} fontSize={normalTextFontSize(isPreview, isAdaptive)} className="mt-1">{venueAddress}</PText>
                        <div className="mt-3">
                          <Map
                            // eslint-disable-next-line react/prop-types
                            google={props.google}
                            zoom={14}
                            style={{ height: '90%' }}
                            initialCenter={{ lat: coordinate && coordinate.x, lng: coordinate && coordinate.y }}
                          >
                            <Marker position={{ lat: coordinate && coordinate.x, lng: coordinate && coordinate.y }} />
                          </Map>
                        </div>
                      </div>
                    )}
                  </Transition>

                </>
              )}
              />
            </Col>
          </>
        )
      }
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds',
})(ProductDetails);
