export const getBarCodeValue = (value) => {
  switch (value) {
    case 'BARCODE_TYPE_1':
      return 'IIIIIIPPPppC';
    case 'BARCODE_TYPE_2':
      return 'IIIIIICPPppC';
    case 'BARCODE_TYPE_3':
      return 'CIIIIIICPPppC';
    case 'BARCODE_TYPE_4':
      return 'CIIIIIIPPPppC';
    default:
      return '';
  }
};
