/* eslint-disable max-len */
import { call, put } from 'redux-saga/effects';

// import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  PAYMENTWIDGETSDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchPaymentWidgetDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.PAYMENTWIDGET}/${gid}`);

const deactivePaymentWidget = async gid => axiosAuthInstance.patch(`${API_END_POINTS.PAYMENTWIDGET}/${gid}/deactivate`);

const patchPaymentWidget = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.PAYMENTWIDGET}/${gid}`, params);

const fetchPayments = async (fetchParams) => axiosAuthInstance.get(`${API_END_POINTS.PAYMENTWIDGET_PAYMENT}?paymentSession.paymentWidgetGid.EQ=${fetchParams.gid}&page=${fetchParams.page ?? 1}&sortBy=createdAt&direction=DESC`);

const fetchPaymentWidgetSlug = async gid => axiosAuthInstance.get(`${API_END_POINTS.PAYMENTWIDGET_SLUG_API}/${gid}`);

const fetchSubscriptions = async (fetchParams) => axiosAuthInstance.get(`${API_END_POINTS.PAYMENTWIDGET_SUBSCRIPTION}?subscription.paymentWidgetGid.EQ=${fetchParams.gid}&page=${fetchParams.page ?? 1}&sortBy=createdAt&direction=DESC`);

export function* getPaymentWidgetDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchPaymentWidgetDetails, action.payload);
    yield put({ type: PAYMENTWIDGETSDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTWIDGETSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* deactivePayment(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deactivePaymentWidget, action.payload);
    const { data: { entity } } = yield call(fetchPaymentWidgetDetails, action.payload);
    yield put({ type: PAYMENTWIDGETSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSWIDGETS.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updatePaymentWidget(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPaymentWidget, action.payload);
    yield put({ type: LOADING.VALIDATED });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSWIDGETS.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getWidgetPayments(action) {
  try {
    const { data: { entity } } = yield call(fetchPayments, action.payload);
    yield put({ type: PAYMENTWIDGETSDETAILS.SUCCESS_PAYMENTS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTWIDGETSDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getWidgetSubscriptions(action) {
  try {
    const { data: { entity } } = yield call(fetchSubscriptions, action.payload);
    yield put({ type: PAYMENTWIDGETSDETAILS.SUCCESS_SUBSCRIPTIONS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTWIDGETSDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getPaymentWidgetSlug(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchPaymentWidgetSlug, action.payload);
    yield put({ type: PAYMENTWIDGETSDETAILS.SLUG_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: PAYMENTWIDGETSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
