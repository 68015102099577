// @flow
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
} from 'antd';
import {
  SpButton,
} from 'components/DesignKit';
import { QRCode } from 'react-qrcode-logo';
// import { toPng } from 'html-to-image';
import {
  notificationActions,
} from 'store/actions';
import {
  NOTIFICATION,
} from 'store/actionTypes';
import {
  MESSAGES,
} from 'appconstants';
import Qrlogo from '../../../../../assets/imgs/SwirepayQrlogo.png';


type Props = {
  close: Function,
  visible: boolean,
  selectedQR: string,
  setNotification: Function,
  returnFromQrCode: Function,
};

const ShowQrSlug = (props: Props) => {
  const {
    visible,
    close,
    selectedQR,
    setNotification,
    returnFromQrCode,
  } = props;
  // const primaryColor = useState('#000000');
  const qrCodeRef = useRef(null);
  const qrSize = 256;

  const downloadQRCode = async () => {
    const qrCode = qrCodeRef.current.canvas.current;
    if (!qrCode) {
      console.error('QR code element not found.');
      return;
    }
    try {
      const dataUrl = qrCode.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'qrcode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.QR_Download,
      });
      returnFromQrCode(true);
    } catch (error) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Error capturing QR code',
      });
      returnFromQrCode(false);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      closable={false}
      title="QR Code"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={downloadQRCode}>Download</SpButton>,
      ]}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <QRCode
            size={qrSize}
            value={JSON.stringify(selectedQR)}
            logoImage={Qrlogo}
            ref={qrCodeRef}
            logoPadding={5}
            // fgColor={primaryColor}
            removeQrCodeBehindLogo
          />
          {/* <QRCode size={qrSize} value={JSON.stringify(selectedQR)} fgColor={primaryColor} ref={qrCodeRef} /> */}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ShowQrSlug);
