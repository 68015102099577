import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
} from 'antd';

import { rewardSettingsActions } from 'store/actions';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import { SpButton, SpText, SpStatusTag } from 'components/DesignKit';
import getRewardBackground from 'utils/getRewardBackground';
import RBAC from 'components/rbac';
import { ROLES, COUNTRIES } from 'appconstants';
import Add from './Add';
import View from './View';
import Edit from './Edit';

type Props = {
  fetchRewardSettings: Function,
  updateRewardSettings: Function,
  addRewardSettings: Function,
  rewardSettings: Object,
  loading: boolean,
  test: boolean,
  selectedAccount: Object,
  validator: boolean,
  internalRole: Object,
};

const TransferSettings = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    rewardSettings,
    loading,
    test,
    fetchRewardSettings,
    updateRewardSettings,
    addRewardSettings,
    validator,
    internalRole,
  } = props;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const rewardStatus = (rewardSettings && rewardSettings.enableRewards) ? 'Active' : 'Inactive';
  const [editSettings, setEditSettings] = useState(false);
  console.log(Object.keys(rewardSettings).length, 'length');
  console.log(rewardSettings, '12121');

  const getData = async () => {
    fetchRewardSettings();
  };

  useEffect(() => {
    getData();
  }, [location, test]);

  useEffect(() => {
    if (!validator) {
      setEditSettings(false);
    }
  }, [validator]);

  const updateSetting = (record: Object) => {
    const gid = rewardSettings && rewardSettings.gid;
    const {
      enableRewards,
      rewardCap,
      rewardRate,
      cashBackCap,
      cashBackRate,
      daysToExpiry,
    } = record;

    const payloadrewardCap = rewardCap ? rewardCap.replace(/[^\d]/g, '') : '';
    const payloadrewardRate = rewardRate ? rewardRate.replace(/[^\d]/g, '') : '';
    const payloadcashBackCap = cashBackCap ? cashBackCap.replace(/[^\d]/g, '') : '';
    const payloadcashBackRate = cashBackRate ? cashBackRate.replace(/[^\d]/g, '') : '';

    const params = {
      rewardRate: payloadrewardRate ? Number(payloadrewardRate) : null,
      rewardCap: payloadrewardCap ? Number(payloadrewardCap) : null,
      redemptionRate: payloadcashBackRate ? Number(payloadcashBackRate) : null,
      redemptionCap: payloadcashBackCap ? Number(payloadcashBackCap) : null,
      enableRewards,
      daysToExpiry,
    };

    updateRewardSettings({
      gid,
      params,
    });
  };

  const addSetting = (record: Object) => {
    const {
      enableRewards,
      rewardCap,
      rewardRate,
      cashBackCap,
      cashBackRate,
      daysToExpiry,
    } = record;

    const payloadrewardCap = rewardCap ? rewardCap.replace(/[^\d]/g, '') : '';
    const payloadrewardRate = rewardRate ? rewardRate.replace(/[^\d]/g, '') : '';
    const payloadcashBackCap = cashBackCap ? cashBackCap.replace(/[^\d]/g, '') : '';
    const payloadcashBackRate = cashBackRate ? cashBackRate.replace(/[^\d]/g, '') : '';
    const payloadEnableRewards = enableRewards && enableRewards ? enableRewards : false;
    const payloadDaysToExpiry = daysToExpiry && daysToExpiry ? daysToExpiry : 30;

    addRewardSettings({
      rewardRate: payloadrewardRate ? Number(payloadrewardRate) : null,
      rewardCap: payloadrewardCap ? Number(payloadrewardCap) : null,
      redemptionRate: payloadcashBackRate ? Number(payloadcashBackRate) : null,
      redemptionCap: payloadcashBackCap ? Number(payloadcashBackCap) : null,
      enableRewards: payloadEnableRewards,
      daysToExpiry: payloadDaysToExpiry,
    });
  };

  const LoadComponent = () => {
    if ((Object.keys(rewardSettings).length <= 0) && !internalRole) {
      return (
        <>
          <Row type="flex" justify="space-between" className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase" fontSize="20px">REWARD SETTINGS</SpText>
            </Col>
          </Row>
          <Row>
            <Col>
              <SpText fontSize="18px">No Wallet Reward Settings found for this account.</SpText>
            </Col>
          </Row>
        </>
      );
    }
    if (Boolean(rewardSettings && Object.keys(rewardSettings).length) && !editSettings) {
      return (
        <>
          <Row type="flex" justify="space-between" className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase" fontSize="20px">
                Manage Reward Settings
                <SpStatusTag style={{ backgroundColor: getRewardBackground(rewardStatus), fontSize: '14px' }}>{rewardStatus}</SpStatusTag>
              </SpText>
            </Col>
            <Col>
              {!editSettings && (
                <RBAC permit={[ROLES.SUPERUSER]}>
                  <SpButton
                    type="secondary"
                    shape="round"
                    ghost
                    onClick={() => setEditSettings(true)}
                  >
                    Edit Reward Settings
                  </SpButton>
                </RBAC>
              )}
            </Col>
          </Row>
          <View
            rewardSettings={rewardSettings}
            selectedAccount={selectedAccount}
          />
        </>
      );
    }
    return editSettings ? (
      <Edit
        rewardSettings={rewardSettings}
        isIndia={isIndia}
        prefix={prefix}
        updateSetting={updateSetting}
        setEditSettings={() => setEditSettings(false)}
      />
    ) : (
      <Add
        status={selectedAccount.status}
        isIndia={isIndia}
        prefix={prefix}
        addSetting={addSetting}
      />
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card className="mb-2">
      <LoadComponent />
    </Card>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  rewardSettings: state.rewardSettings.content,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  validator: state.loading.validator,
  internalRole: state.user.internalRole,
});

const mapDispatchToProps = dispatch => ({
  fetchRewardSettings: gid => dispatch(rewardSettingsActions.fetchRewardSettings({
    payload: gid,
  })),
  addRewardSettings: payload => dispatch(rewardSettingsActions.addRewardSettings({
    payload,
  })),
  updateRewardSettings: params => dispatch(rewardSettingsActions.updateRewardSettings({
    payload: params,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransferSettings);
