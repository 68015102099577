import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  FUNDINGSOURCE,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchFundingSourceDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}/${gid}`);
const DeleteFundingSource = async gid => axiosAuthInstance.delete(`${API_END_POINTS.GET_FUNDINGSOURCE}/${gid}`);


const fetchFundingSourceList = async (searchParams) => {
  const { payload } = searchParams;
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.size) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}?page=1&size=1000&sortBy=createdAt&direction=DESC&contact.contactType.NE=SELF`);
  }
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}?${payload.filterParams}&contact.contactType.NE=SELF`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}${query}${sorting}&${payload.filterParams}&contact.contactType.NE=SELF`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}${query}${sorting}&contact.contactType.NE=SELF`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}${query}${sorting}&contact.contactType.NE=SELF`);
};

const postFundingSource = async (params) => axiosAuthInstance.post(API_END_POINTS.GET_FUNDINGSOURCE, params);

export function* getFundingSourceList(action) {
  try {
    const { data: { entity } } = yield call(fetchFundingSourceList, action.payload);
    yield put({ type: FUNDINGSOURCE.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: FUNDINGSOURCE.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

const fetchFundingSourceListByContactGid = async (searchParams) => {
  const { payload } = searchParams;
  // eslint-disable-next-line max-len
  return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}?contact.gid.EQ=${payload?.contactGid}&sortBy=createdAt&direction=DESC&size=10&page=${payload.pageNo}`);
};

export function* getFundingSourceListByContactGid(action) {
  try {
    const { data: { entity } } = yield call(fetchFundingSourceListByContactGid, action.payload);
    yield put({ type: LOADING.SET_SKELETON });
    yield put({ type: FUNDINGSOURCE.SUCCESS_CONTACTGID, payload: entity });
  } catch (e) {
    yield put({ type: FUNDINGSOURCE.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET_SKELETON });
  }
}

export function* createFundingSource(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postFundingSource, action.payload);
    yield put({ type: FUNDINGSOURCE.ADD_FUNDINGSOURCE, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.FUNDINGSOURCE.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getFundingSourceDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchFundingSourceDetails, action.payload);
    yield put({ type: FUNDINGSOURCE.FUNDINGSOURCEDETAILS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: FUNDINGSOURCE.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* deleteFundingSource(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(DeleteFundingSource, action.payload);
    // const { data: { entity } } = yield call(fetchVendors, action.payload);
    // yield put({ type: VENDORSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.FUNDINGSOURCE.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
