// @flow
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
} from 'antd';
import {
  SpH5,
  SpError,
} from 'components/DesignKit';
import {
  MAX_AMOUNT,
} from 'appconstants';
import SimpleReactValidator from 'simple-react-validator';

const { Option } = Select;
type Props = {
  submitting: boolean,
  itemsList: Array,
  selectItemData: Object,
};

const SelectItem = forwardRef((props: Props, ref) => {
  const {
    submitting,
    itemsList,
    selectItemData,
  } = props;
  const [, forceUpdate] = useState();
  const [code] = useState(selectItemData && selectItemData.code);
  const [valueQuantity] = useState(selectItemData && selectItemData.valueQuantity);
  const [valueItems, setValueItems] = useState((selectItemData && selectItemData.valueItems) || []);
  const [claimableItems, setClaimableItems] = useState((selectItemData && selectItemData.claimableItems) || []);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      quantity: {
        message: 'Minimum 1 item should be selected',
        rule: (val) => {
          const numericValue = typeof val === 'string' ? val.replace(/,/g, '') : val;
          const numberValue = Number(numericValue);
          return MAX_AMOUNT.LIMIT_USD_MIN <= numberValue;
        },
      },
    },
  }));

  const handleSelectBuyItemChange = (values) => {
    setValueItems(values);
  };

  const handleSelectGetItemChange = (values) => {
    setClaimableItems(values);
  };

  useImperativeHandle(ref, () => ({
    validate: (type) => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid && type === 'next') {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      code,
      valueQuantity,
      valueItems,
      claimableItems,
    }),
  }));

  return (
    <>
      <div className="pb-2">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="px-5 mt-4 mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            disabled
            value={code}
          />
        </div>
        <div style={{
          backgroundColor: '#eeeeee',
          marginBottom: '25px',
          padding: '5px',
          height: '98px',
        }}
        >
          <div className="px-5 mt-2 mb-3">
            <label className="mt-2" htmlFor=""><SpH5>Quantity</SpH5></label>
            <Input
              disabled
              value={valueQuantity}
            />
          </div>
        </div>
        <div className="px-5 mb-3">
          <label htmlFor=""><SpH5>Select Buy Items</SpH5></label>
          <Select
            className="w-100"
            mode="multiple"
            allowClear
            onChange={handleSelectBuyItemChange}
            defaultValue={valueItems}
            showSearch
            optionFilterProp="name"
            style={{ width: '100%' }}
          >
            {itemsList.map((item) => (
              <Option key={item.gid} name={item.name} value={item.gid}>
                {item.name}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('valueItemsSize', valueItems.length, 'required|quantity')}
          </SpError>
        </div>
        <div className="px-5 mb-3">
          <label htmlFor=""><SpH5>Select Get Items</SpH5></label>
          <Select
            className="w-100"
            mode="multiple"
            allowClear
            onChange={(e) => { handleSelectGetItemChange(e); }}
            defaultValue={claimableItems}
            showSearch
            optionFilterProp="name"
            style={{ width: '100%' }}
          >
            {itemsList.map((item) => (
              <Option key={item.gid} name={item.name} value={item.gid}>
                {item.name}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('claimableItemsSize', claimableItems.length, 'required|quantity')}
          </SpError>
        </div>
      </div>
    </>
  );
});

// $FlowFixMe
export default connect(
  null,
  null,
  null,
  { forwardRef: true },
)(SelectItem);
