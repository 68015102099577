/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { AmountDivideByHundred } from '../../../../../../helper';
import outOfStock from '../../../../../../imgs/outOfStock.png';
import ChooseSeats from './chooseSeats';

type Props = {
    eventDetails: Object,
    eventPageProduct: Object,
    orderItemIndex: Number,
    lineItems: Array<Object>,
    setOrderItems: Function,
    splitUpApi: Function,
    splitUpResponse: Object,
    notify: Function,
    setShowButton: Function,
    setFullScreen: Function,
    mobileView: Boolean,
}
const AddProducts = (props: Props) => {
  const {
    eventDetails,
    eventPageProduct,
    orderItemIndex,
    lineItems,
    setOrderItems,
    splitUpApi,
    splitUpResponse,
    notify,
    setShowButton,
    setFullScreen,
    mobileView,
  } = props;

  let productCopy = eventPageProduct;
  const [validationErrors, setValidationErrors] = useState([]);
  const [mandatoryItems, setMandatoryItems] = useState([]);

  const [current, setCurrent] = useState('chooseSeat');
  const [updates, setUpdates] = useState([]);
  const [updateOrderItem, setUpdateOrderItem] = useState(false);
  const [splitUpCalled, setSplitUpCalled] = useState(false);

  if (typeof productCopy !== 'undefined' && productCopy !== eventPageProduct) {
    productCopy = eventPageProduct;
  }
  const [activeModifier, setActiveModifier] = useState(null);
  const toggleModifier = (itemGid) => {
    setActiveModifier(activeModifier === itemGid ? null : itemGid);
  };

  const [activeItem, setActiveItem] = useState(null);
  const toggleItem = (index) => {
    setActiveItem(activeItem === index ? null : index);
  };

  const [activeItemModifier, setActiveItemModifier] = useState(null);
  const toggleItemModifier = (index) => {
    setActiveItemModifier(activeItemModifier === index ? null : index);
  };

  const updateSeatCurrent = (val) => {
    if (val === 'ticketPreview' && updates.length === 0) {
      notify('No Seats Selected, Please select a seat to continue');
    } else {
      setCurrent(val);
    }
  };

  useEffect(() => {
    if (current
            && eventDetails.isSeatedEvent
            && eventPageProduct?.eventPageProductType === 'TICKET'
            && current === 'chooseSeat') {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [current, productCopy]);

  useEffect(() => {
    setActiveModifier(null);
    setActiveItem(null);
    setActiveItemModifier(null);
    setMandatoryItems([]);
    setUpdateOrderItem(false);
    setCurrent(lineItems.length > 0 ? 'ticketPreview' : 'chooseSeat');
    // eslint-disable-next-line max-len
    const storedItems = sessionStorage.getItem(`placedLineItems-${eventDetails?.gid.replace('eventpage-', '')}-${eventPageProduct?.eventPageProductType}`);
    if (storedItems && storedItems.length > 0) {
      setUpdates(storedItems ? JSON.parse(storedItems) : []);
    } else {
      setUpdates(lineItems);
    }
  }, [productCopy]);

  const updateQuantityFunction = (customItem, item, modifier, seat, change, replace, duplicateIndex) => {
    // eslint-disable-next-line max-len
    const storedItems = sessionStorage.getItem(`placedLineItems-${eventDetails.gid.replace('eventpage-', '')}-${eventPageProduct.eventPageProductType}`);
    const updateItems = storedItems ? JSON.parse(storedItems) : [];
    let updateItem;
    let index;
    if (customItem) {
      index = updateItems.findIndex((update) => update.customItemGid === customItem.gid && update.duplicateIndex === duplicateIndex);
    } else {
      index = updateItems.findIndex((update) => update.itemGid === item.gid && update.duplicateIndex === duplicateIndex);
    }

    if (index === -1) {
      if (change > 0 || replace > 0) {
        updateItem = {
          name: customItem ? customItem.inputFieldLabel : item.displayName,
          price: customItem ? customItem.amount : item.price,
          totalPrice: (customItem ? customItem.amount : item.price) * (replace || change),
          customItemGid: customItem ? customItem.gid : undefined,
          itemGid: !customItem && item ? item.gid : undefined,
          quantity: replace || change,
          lineItemModifiers: [],
          lineItemItems: [],
          lineItemDiscounts: item?.coupon ? [{
            discountGid: item.coupon.gid,
            stackable: item.discountStackable,
            type: 'COUPON',
            amountOff: item.coupon.amountOff ? item.coupon.amountOff * -1 : 0,
            percentageOff: item.coupon.percentageOff,
          }] : [],
          seats: customItem && seat ? [seat] : undefined,
          listedItem: true,
        };
        return [...updateItems, updateItem];
      }
      return updateItems;
    }

    updateItem = { ...updateItems[index] };

    if (customItem && item) {
      const itemIndex = updateItem.lineItemItems.findIndex(lineItem => lineItem.itemGid === item.gid);

      if (itemIndex === -1) {
        if (change > 0 || replace > 0) {
          updateItem.lineItemItems.push({
            name: item.displayName,
            price: item.price,
            totalPrice: (item.price - (item.coupon ? (item.coupon.percentageOff
              ? Math.round((item.price * item.coupon.percentageOff) / 100)
              : Math.min(item.coupon.amountOff, item.price)) : 0)) * change,
            itemGid: item.gid,
            quantity: item.enableStock ? Math.min(item.onlineStock, replace || change) : replace || change,
            lineItemModifiers: [],
            lineItemDiscounts: item.coupon ? [{
              discountGid: item.coupon.gid,
              stackable: item.discountStackable,
              type: 'COUPON',
              amountOff: item.coupon.amountOff ? item.coupon.amountOff * -1 : 0,
              percentageOff: item.coupon.percentageOff,
            }] : [],
          });
        }
      } else if (modifier) {
        const modIndex = updateItem.lineItemItems[itemIndex].lineItemModifiers.findIndex(m => m.modifierGid === modifier.gid);

        if (modIndex === -1) {
          if (change > 0 || replace > 0) {
            updateItem.lineItemItems[itemIndex].lineItemModifiers.push({
              name: modifier.name,
              price: modifier.price,
              modifierGid: modifier.gid,
              quantity: modifier.maxStackableQuantity
                ? Math.min(replace || change, modifier.maxStackableQuantity)
                : replace || change,
            });
          }
        } else {
          updateItem.lineItemItems[itemIndex].lineItemModifiers[modIndex].quantity = modifier.maxStackableQuantity
            ? Math.min(replace || (updateItem.lineItemItems[itemIndex].lineItemModifiers[modIndex].quantity + change), modifier.maxStackableQuantity)
            : replace || (updateItem.lineItemItems[itemIndex].lineItemModifiers[modIndex].quantity + change);
          // eslint-disable-next-line max-len
          updateItem.lineItemItems[itemIndex].lineItemModifiers = updateItem.lineItemItems[itemIndex].lineItemModifiers.filter(mod => mod.quantity > 0);
        }
      } else {
        updateItem.lineItemItems[itemIndex].quantity = item.enableStock
          ? Math.min(item.onlineStock, replace || Math.max(0, updateItem.lineItemItems[itemIndex].quantity + change))
          : replace || Math.max(0, updateItem.lineItemItems[itemIndex].quantity + change);
        updateItem.lineItemItems = updateItem.lineItemItems.filter(lineItem => lineItem.quantity > 0);
      }
    } else if (modifier) {
      const modIndex = updateItem.lineItemModifiers.findIndex(m => m.modifierGid === modifier.gid);

      if (modIndex === -1) {
        if (change > 0 || replace > 0) {
          updateItem.lineItemModifiers.push({
            name: modifier.name,
            price: modifier.price,
            modifierGid: modifier.gid,
            quantity: modifier.maxStackableQuantity
              ? Math.min(replace || change, modifier.maxStackableQuantity)
              : replace || change,
          });
        }
      } else {
        updateItem.lineItemModifiers[modIndex].quantity = modifier.maxStackableQuantity
          ? Math.min(replace || (updateItem.lineItemModifiers[modIndex].quantity + change), modifier.maxStackableQuantity)
          : replace || (updateItem.lineItemModifiers[modIndex].quantity + change);
        updateItem.lineItemModifiers = updateItem.lineItemModifiers.filter(mod => mod.quantity > 0);
      }
    } else {
      if (seat) {
        if (change > 0 && !updateItem.seats.some((seatNumber) => seat === seatNumber)) {
          updateItem.quantity = Math.max(0, replace || (updateItem.quantity + change));
          updateItem.seats = [...updateItem.seats, seat];
        } else if (change < 0) {
          updateItem.quantity = Math.max(0, replace || (updateItem.quantity + change));
          updateItem.seats = updateItem.seats.filter((seatNumber) => seat !== seatNumber);
        }
      } else {
        updateItem.quantity = Math.max(0, replace || (updateItem.quantity + change));
      }
      if (updateItem.quantity === 0) return updateItems.filter((_, i) => i !== index);
    }

    const sumModifiers = updateItem.lineItemModifiers.reduce((sum, mod) => sum + (mod.quantity * mod.price || 0), 0);
    const sumItems = updateItem.lineItemItems.reduce((sum, lineItem) => {
      const modifierTotal = lineItem.lineItemModifiers.reduce((mSum, mod) => mSum + (mod.quantity * mod.price || 0), 0);
      const discountTotal = lineItem.lineItemDiscounts.reduce((dSum, discount) => {
        const discountValue = discount.percentageOff
          ? Math.round((lineItem.price * discount.percentageOff) / 100) * -1
          : Math.min(discount.amountOff * -1, lineItem.price) * -1;
        return dSum + discountValue;
      }, 0);
      return sum + (lineItem.quantity * (lineItem.price + modifierTotal + discountTotal));
    }, 0);

    updateItem.totalPrice = (updateItem.price + sumModifiers + sumItems);
    updateItem.totalPrice += updateItem.lineItemDiscounts.reduce((sum, discount) => {
      const discountValue = discount.percentageOff
        ? Math.round((updateItem.totalPrice * discount.percentageOff) / 100) * -1
        : Math.min(discount.amountOff, updateItem.totalPrice);
      return sum + discountValue;
    }, 0);
    updateItem.totalPrice *= updateItem.quantity;

    return updateItems.map((update, i) => (i === index ? updateItem : update));
  };

  const updateQuantity = async (customItem, item, modifier, seat, change, replace, duplicateIndex) => {
    const updatedItems = await updateQuantityFunction(customItem, item, modifier, seat, change, replace, duplicateIndex);
    setUpdates(updatedItems);
    setUpdateOrderItem(true);
    // eslint-disable-next-line max-len
    sessionStorage.setItem(`placedLineItems-${eventDetails.gid.replace('eventpage-', '')}-${eventPageProduct.eventPageProductType}`, JSON.stringify(updatedItems));
  };

  const duplicateItem = async (customItem, item) => {
    let validationError;
    if (customItem) {
      validationError = validationErrors.filter((error) => error.customItemGid === customItem.gid);
    } else {
      validationError = validationErrors.filter((error) => error.itemGid === item.gid);
    }
    if (validationError?.length > 0) {
      validationError.forEach((error) => notify(error.errorMessage));
      return;
    }

    const normalize = (value) => (value || []);
    if (customItem) {
      if (activeModifier === customItem.gid) {
        toggleModifier();
      }
      if (activeItem === customItem.gid) {
        toggleItem(customItem.gid);
        toggleItemModifier();
      }
    } else if (activeModifier === item.gid) {
      toggleModifier();
    }
    const updateLineItem = () => {
      // eslint-disable-next-line max-len
      const storedItems = sessionStorage.getItem(`placedLineItems-${eventDetails.gid.replace('eventpage-', '')}-${eventPageProduct.eventPageProductType}`);
      let updateItems = storedItems ? JSON.parse(storedItems) : [];
      let updateItem; let
        index;
      if (customItem) {
        index = updateItems.findIndex((update) => update.customItemGid === customItem.gid && update.listedItem);
      } else {
        index = updateItems.findIndex((update) => update.itemGid === item.gid && update.listedItem);
      }

      if (index !== -1) {
        updateItem = { ...updateItems[index] };

        const matchingItemIndex = updateItems.findIndex((lineItem) => !lineItem.listedItem
                    && updateItem.customItemGid === lineItem.customItemGid
                    && updateItem.itemGid === lineItem.itemGid
                    && JSON.stringify(normalize(lineItem.lineItemModifiers)) === JSON.stringify(normalize(updateItem.lineItemModifiers))
                    && JSON.stringify(normalize(lineItem.lineItemItems)) === JSON.stringify(normalize(updateItem.lineItemItems))
                    && JSON.stringify(normalize(lineItem.lineItemDiscounts)) === JSON.stringify(normalize(updateItem.lineItemDiscounts)));

        if (matchingItemIndex !== -1) {
          const matchingItem = { ...updateItems[matchingItemIndex] };
          matchingItem.quantity += updateItem.quantity;
          updateItem = matchingItem;
          updateItems = updateItems.filter((update, i) => i !== index);
          index = matchingItemIndex;
        } else {
          let size;
          if (customItem) {
            size = updateItems.filter((update) => update.customItemGid === customItem.gid);
          } else {
            size = updateItems.filter((update) => update.itemGid === item.gid);
          }
          updateItem.listedItem = false;
          updateItem.duplicateIndex = size + 1;
        }
        return updateItems.map((update, i) => (i === index ? updateItem : update));
      }
      return updateItems;
    };

    const updateItems = await updateLineItem();
    setUpdates(updateItems);
    setUpdateOrderItem(true);
    // eslint-disable-next-line max-len
    sessionStorage.setItem(`placedLineItems-${eventDetails.gid.replace('eventpage-', '')}-${eventPageProduct.eventPageProductType}`, JSON.stringify(updateItems));
  };

  useEffect(() => {
    if (eventPageProduct?.gid && updates && updateOrderItem) {
      splitUpApi({
        eventPageGid: eventDetails.gid,
        eventPageOrderLineItems: updates,
        eventPageOrderDiscounts: eventDetails.coupon ? [
          {
            discountGid: eventDetails.coupon.gid,
            stackable: eventDetails.discountStackable,
            type: 'COUPON',
            amountOff: eventDetails.coupon.amountOff ? eventDetails.coupon.amountOff * -1 : eventDetails.coupon.amountOff,
            percentageOff: eventDetails.coupon.percentageOff,
          },
        ] : [],
      });
      setSplitUpCalled(true);
    }
  }, [updateOrderItem]);

  useEffect(() => {
    if (splitUpResponse && splitUpCalled) {
      setOrderItems((prevOrderItems) => {
        const updatedOrderItems = [...prevOrderItems];
        const updatedOrderItem = updatedOrderItems[orderItemIndex];
        if (updatedOrderItem) {
          updatedOrderItems[orderItemIndex] = {
            ...updatedOrderItems[orderItemIndex],
            lineItems: updates,
            totalAmount: splitUpResponse?.subTotal || 0,
            totalCount: updates.reduce((sum, update) => sum + (update.quantity || 0), 0),
            errors: [
              ...(validationErrors ? validationErrors
                .filter((validationError) => validationError.productGid === eventPageProduct.gid)
                .map((modifierGroupError) => modifierGroupError.errorMessage) : []),
              ...mandatoryItems
                .filter((customItem) => !updates.some((lineItem) => lineItem.customItemGid === customItem.customItemGid))
                .map((customItem) => (customItem.errorMessage)),
            ],
          };
        } else {
          updatedOrderItems.push({
            productGid: eventPageProduct.gid,
            name: eventPageProduct.eventPageProductName,
            lineItems: updates,
            totalAmount: splitUpResponse?.subTotal || 0,
            totalCount: updates.reduce((sum, update) => sum + (update.quantity || 0), 0),
            errors: [
              ...(validationErrors ? validationErrors.map((modifierGroupError) => modifierGroupError.errorMessage) : []),
              ...mandatoryItems
                .filter((customItem) => !updates.some((lineItem) => lineItem.customItemGid === customItem.customItemGid))
                .map((customItem) => (customItem.errorMessage)),
            ],
          });
        }

        return updatedOrderItems;
      });

      setUpdateOrderItem(false);
      setSplitUpCalled(false);
    }
  }, [splitUpResponse]);

  return (
    <div className="product-screen">
      {eventPageProduct && (
        <>
          {eventDetails.isSeatedEvent && eventPageProduct.eventPageProductType === 'TICKET' && (
          <div className="d-flex" style={{ gap: '15px', padding: '10px' }}>
            <div
              className="seats-option"
              style={{
                width: '-webkit-fill-available',
                background: current === 'chooseSeat' ? `${eventDetails.primaryColor}` : '',
                color: current === 'chooseSeat' ? '#fff' : '#333',
                boxShadow: current === 'chooseSeat' ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : 'none',
              }}
              onClick={() => updateSeatCurrent('chooseSeat')}
            >
              1. Choose Seats
            </div>
            <div
              className="seats-option"
              style={{
                width: '-webkit-fill-available',
                background: current === 'ticketPreview' ? `${eventDetails.primaryColor}` : '',
                color: current === 'ticketPreview' ? '#fff' : '#333',
                boxShadow: current === 'ticketPreview' ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : 'none',
              }}
              onClick={() => updateSeatCurrent('ticketPreview')}
            >
              2. Selected Seats
            </div>
          </div>
          )}

          {eventDetails.isSeatedEvent && eventPageProduct.eventPageProductType === 'TICKET'
            ? (
              <>
                {current === 'chooseSeat' ? (
                  <ChooseSeats
                    eventDetails={eventDetails}
                    eventPageProduct={eventPageProduct}
                    mobileView={mobileView}
                    setFullScreen={setFullScreen}
                    updateQuantity={updateQuantity}
                  />

                ) : (
                  <>
                    <h4 className="mt-4" style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
                      {eventPageProduct.eventPageProductName}
                    </h4>

                    {/* eslint-disable-next-line max-len */}
                    {eventPageProduct?.customItems?.map((customItem, index) => (updates?.some((lineItem) => lineItem.customItemGid === customItem.gid && lineItem.listedItem) ? (
                      <div
                        key={index}
                        style={{
                          marginBottom: '10px',
                          padding: mobileView ? '0' : '10px',
                          width: '100%',
                          borderTop: '2px dashed #B3B3B3',
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>{customItem.inputFieldLabel}</h3>
                            {(() => {
                              const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);
                              if (customItemInUpdates && customItemInUpdates.seats) {
                                // eslint-disable-next-line max-len
                                return (<p className="additional-info" style={{ padding: '5px' }}>Seats: [{customItemInUpdates.seats.join(', ')}]</p>);
                              }
                              return (<></>);
                            })()}
                            <p style={{ color: '#555', padding: '5px' }}>$ {customItem.amount ? AmountDivideByHundred(customItem.amount) : '0.00'}</p>
                            {(() => {
                              if (!customItem.isOptional) {
                                const updateMandatoryItems = [...mandatoryItems];
                                const itemIndex = updateMandatoryItems.findIndex(
                                  (update) => update.customItemGid === (customItem.gid),
                                );
                                if (itemIndex === -1) {
                                  updateMandatoryItems.push({
                                    customItemGid: customItem.gid,
                                    errorMessage: `Add ${customItem.inputFieldLabel} To Proceed`,
                                  });
                                  setMandatoryItems(updateMandatoryItems);
                                  setTimeout(() => {
                                    setUpdateOrderItem(true);
                                  }, 500);
                                }
                              }
                              return (<></>);
                            })()}
                          </div>
                          <span style={{ fontWeight: 'bold' }}>
                            {updates.find((u) => u.customItemGid === customItem.gid && u.listedItem)?.quantity || 0} Ticket(s)
                          </span>
                        </div>
                        {customItem.modifierGroups?.length > 0 && (
                        <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                          <button
                            type="button"
                            className="drop-down-menu"
                            onClick={() => toggleModifier(customItem.gid)}
                          >
                            Add Modifications &#9660;
                          </button>
                          {activeModifier === customItem.gid && (
                          <div style={{ marginTop: '10px', marginLeft: mobileView ? '10px' : '20px' }}>
                            {customItem.modifierGroups.map((modifierGroup) => (
                              <div key={modifierGroup.gid} style={{ marginBottom: '5px' }}>
                                <h4 className="additional-info mb-2" style={{ fontStyle: 'normal', color: '#000000' }}>{modifierGroup.name} {modifierGroup?.minRequired || modifierGroup?.maxAllowed ? `(${modifierGroup.minRequired ? `choose ${modifierGroup.minRequired}` : ''} ${modifierGroup.maxAllowed ? `max ${modifierGroup.maxAllowed}` : ''})` : ''}</h4>
                                {modifierGroup?.modifiers.map((mod, modIndex) => {
                                  const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);

                                  const modifiersInGroup = customItemInUpdates?.lineItemModifiers.filter(
                                                                                (lineItemModifier) => modifierGroup.modifiers.some(
                                                                                  (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                                                )
                                                                            ) || [];

                                  const modifierQuantityTotal = modifiersInGroup.reduce(
                                    (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                    0,
                                  );

                                  const modifierInItem = customItemInUpdates?.lineItemModifiers.find((m) => m.modifierGid === mod.gid);

                                  const disableModifier = !mod.available
                                    || (mod?.maxStackableQuantity && modifierInItem?.quantity >= mod.maxStackableQuantity)
                                    || (modifierGroup?.maxAllowed && modifierQuantityTotal >= modifierGroup.maxAllowed);

                                  return (
                                    <div
                                      key={mod.gid}
                                      className="d-flex align-items-center justify-content-between"
                                      style={{
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        borderTop: modIndex === 0 ? 'none' : '1px dashed #B3B3B3',
                                      }}
                                    >
                                      <div>
                                        <p>{mod.name}</p>
                                        <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                                          $ {mod.price ? AmountDivideByHundred(mod.price) : '0.00'}
                                        </p>
                                      </div>

                                      {modifierInItem ? (
                                        <div
                                          className="d-flex align-items-center justify-content-between overflow-hidden"
                                          style={{
                                            backgroundColor: '#FFFFFF',
                                            border: '1px solid #000000',
                                            borderRadius: '5px',
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="increase-decrease-button"
                                            style={{ padding: '6px 12px' }}
                                            onClick={() => updateQuantity(customItem, null, mod, null, -1, 0)}
                                          >
                                            –
                                          </button>
                                          <input
                                            type="text"
                                            className="input-item"
                                            value={modifierInItem?.quantity || 0}
                                            readOnly
                                          />
                                          {!disableModifier && (
                                          <button
                                            type="button"
                                            className="increase-decrease-button"
                                            style={{ padding: '6px 12px' }}
                                            onClick={() => updateQuantity(customItem, null, mod, null, 1, 0)}
                                            disabled={disableModifier}
                                          >
                                            +
                                          </button>
                                          )}
                                        </div>
                                      ) : (
                                        <button
                                          type="button"
                                          className="add-button"
                                          style={{
                                            color: `${!disableModifier ? 'rgba(0,0,0,0.69)' : 'rgba(0,0,0,0.35)'}`,
                                            padding: '6px 12px',
                                          }}
                                          disabled={disableModifier}
                                          onClick={() => updateQuantity(customItem, null, mod, null, 1, 0)}
                                        >
                                          Add
                                        </button>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            ))}
                          </div>
                          )}
                          {customItem.modifierGroups?.map((modifierGroup) => {
                            const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);

                            const modifiersInGroup = customItemInUpdates?.lineItemModifiers.filter(
                                                                (lineItemModifier) => modifierGroup.modifiers.some(
                                                                  (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                                )
                                                            ) || [];

                            const modifierQuantityTotal = modifiersInGroup.reduce(
                              (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                              0,
                            );
                            let updateValidationError = [...validationErrors];
                            const validationErrorIndex = updateValidationError.findIndex(
                              (update) => update.uniqueGid === (`${modifierGroup.gid}&${customItem.gid}`)
                                                                    && update.productGid === eventPageProduct.gid,
                            );
                            if (modifierGroup.minRequired && customItemInUpdates?.quantity > 0) {
                              if (modifierQuantityTotal < modifierGroup.minRequired) {
                                if (validationErrorIndex === -1) {
                                  updateValidationError.push({
                                    uniqueGid: `${modifierGroup.gid}&${customItem.gid}`,
                                    customItemGid: customItem.gid,
                                    productGid: eventPageProduct.gid,
                                    errorMessage: `Add Modifications -> "${modifierGroup.name}" : for Item -> "${customItem.inputFieldLabel}"`,
                                  });
                                  setValidationErrors(updateValidationError);
                                  setUpdateOrderItem(true);
                                }
                              } else if (validationErrorIndex !== -1) {
                                updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                                setValidationErrors(updateValidationError);
                              }
                            } else if (validationErrorIndex !== -1) {
                              updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                              setValidationErrors(updateValidationError);
                            }
                          })}
                        </div>
                                                )}
                        {customItem?.items?.length > 0 && (
                        <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                          <button
                            type="button"
                            className="drop-down-menu"
                            onClick={() => toggleItem(customItem.gid)}
                          >
                            Add Items &#9660;
                          </button>
                          {activeItem === customItem.gid && (
                          <div style={{ marginTop: '10px', marginLeft: mobileView ? '10px' : '20px' }}>
                            {customItem.items.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  marginBottom: '10px',
                                  padding: mobileView ? '0' : '10px',
                                  borderTop: index === 0 ? 'none' : '1px dashed #B3B3B3',

                                }}
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>{item.displayName}</h3>
                                    <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                                      $ {item.price ? AmountDivideByHundred(item.price) : '0.00'}
                                      {item.coupon && (
                                      <span className="sc-fbJfA uhGua discount-badge">
                                        {item.coupon.percentageOff
                                          ? `${item.coupon.percentageOff}%`
                                          : `${item.price > item.coupon.amountOff
                                            ? '$ ' : '100% upto $ '}${AmountDivideByHundred(item.coupon.amountOff)}`} Off
                                      </span>
                                      )}
                                    </p>
                                  </div>
                                  {(() => {
                                    const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);
                                    const itemInUpdates = customItemInUpdates?.lineItemItems?.find((c) => c.itemGid === item.gid);

                                    return !itemInUpdates ? (
                                      item.enableStock && item.onlineStock === 0 ? (
                                        <img alt="" src={outOfStock} style={{ width: '64px', height: '64px' }} />
                                      ) : (
                                        <button
                                          type="button"
                                          className="add-button"
                                          style={{
                                            color: `${item.enableStock && item.onlineStock === 0 ? 'rgba(0,0,0,0.35)' : 'rgba(0,0,0,0.69)'}`,
                                          }}
                                          disabled={(item.enableStock && item.onlineStock === 0)}
                                          onClick={() => updateQuantity(customItem, item, null, null, 1, 0)}
                                        >
                                          Add
                                        </button>
                                      )
                                    ) : (
                                      <div
                                        className="d-flex align-items-center justify-content-between overflow-hidden"
                                        style={{
                                          backgroundColor: '#FFFFFF',
                                          border: '1px solid #000000',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="increase-decrease-button"
                                          style={{ padding: '6px 12px' }}
                                          onClick={() => updateQuantity(customItem, item, null, null, -1, 0)}
                                        >
                                          –
                                        </button>
                                        <input
                                          type="text"
                                          className="input-item"
                                          value={itemInUpdates?.quantity || 0}
                                        />
                                        {!(item.enableStock && (itemInUpdates?.quantity * customItemInUpdates.quantity) >= item.onlineStock) && (
                                        <button
                                          type="button"
                                          className="increase-decrease-button"
                                          style={{ padding: '6px 12px' }}
                                          onClick={() => updateQuantity(customItem, item, null, null, 1, 0)}
                                        >
                                          +
                                        </button>
                                        )}
                                      </div>
                                    );
                                  })()}
                                </div>
                                {item.modifierGroups?.length > 0 && (
                                <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                                  <button
                                    type="button"
                                    onClick={() => toggleItemModifier(item.gid)}
                                    disabled={item.enableStock && item.onlineStock === 0}
                                    className="drop-down-menu"
                                  >
                                    Add Modifications &#9660;
                                  </button>
                                  {activeItemModifier === item.gid && (
                                  <div style={{ marginTop: '10px', marginLeft: mobileView ? '10px' : '20px' }}>
                                    {item.modifierGroups.map((modifierGroup) => (
                                      <div key={modifierGroup.gid} style={{ marginBottom: '5px' }}>
                                        <h4
                                          className="additional-info mb-2"
                                          style={{ fontStyle: 'normal', color: '#000000' }}
                                        >
                                          {modifierGroup.name}
                                          {modifierGroup?.minRequired || modifierGroup?.maxAllowed
                                            ? `(${modifierGroup.minRequired ? `choose ${modifierGroup.minRequired}` : ''}
                                            ${modifierGroup.maxAllowed ? `max ${modifierGroup.maxAllowed}` : ''})` : ''}
                                        </h4>
                                        {modifierGroup?.modifiers.map((mod, modIndex) => {
                                          const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);
                                          const itemInUpdates = customItemInUpdates?.lineItemItems?.find((u) => u.itemGid === item.gid);

                                          const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                            (lineItemModifier) => modifierGroup.modifiers.some(
                                              (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                            )
                                                                                                    ) || [];

                                          const modifierQuantityTotal = modifiersInGroup.reduce(
                                            (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                            0,
                                          );

                                          const modifierInItem = itemInUpdates?.lineItemModifiers.find((m) => m.modifierGid === mod.gid);

                                          const disableModifier = !mod.available
                                            || (mod?.maxStackableQuantity && modifierInItem?.quantity >= mod.maxStackableQuantity)
                                            || (modifierGroup?.maxAllowed && modifierQuantityTotal >= modifierGroup.maxAllowed);

                                          return (
                                            <div
                                              key={mod.gid}
                                              className="d-flex align-items-center justify-content-between"
                                              style={{
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                                borderTop: modIndex === 0 ? 'none' : '0.5px dashed #B3B3B3',
                                              }}
                                            >
                                              <div>
                                                <p>{mod.name}</p>
                                                <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                                                  $ {mod.price ? AmountDivideByHundred(mod.price) : '0.00'}
                                                </p>
                                              </div>

                                              {modifierInItem ? (
                                                <div
                                                  className="d-flex align-items-center justify-content-between overflow-hidden"
                                                  style={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '1px solid #000000',
                                                    borderRadius: '5px',
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    className="increase-decrease-button"
                                                    style={{ padding: '6px 12px' }}
                                                    onClick={() => updateQuantity(customItem, item, mod, null, -1, 0)}
                                                  >
                                                    –
                                                  </button>
                                                  <input
                                                    type="text"
                                                    className="input-item"
                                                    value={modifierInItem?.quantity || 0}
                                                  />
                                                  {!disableModifier && (
                                                  <button
                                                    type="button"
                                                    className="increase-decrease-button"
                                                    style={{ padding: '6px 12px' }}
                                                    onClick={() => updateQuantity(customItem, item, mod, null, 1, 0)}
                                                    disabled={disableModifier}
                                                  >
                                                    +
                                                  </button>
                                                  )}
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="add-button"
                                                  style={{
                                                    color: `${!disableModifier ? 'rgba(0,0,0,0.69)' : 'rgba(0,0,0,0.35)'}`,
                                                    padding: '6px 12px',
                                                  }}
                                                  disabled={disableModifier}
                                                  onClick={() => updateQuantity(customItem, item, mod, null, 1, 0)}
                                                >
                                                  Add
                                                </button>
                                              )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ))}
                                  </div>
                                  )}
                                  {item.modifierGroups?.map((modifierGroup) => {
                                    const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);
                                    const itemInUpdates = customItemInUpdates?.lineItemItems?.find((u) => u.itemGid === item.gid);

                                    const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                                                                        (lineItemModifier) => modifierGroup.modifiers.some(
                                                                                          (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                                                        )
                                                                                    ) || [];

                                    const modifierQuantityTotal = modifiersInGroup.reduce(
                                      (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                      0,
                                    );

                                    let updateValidationError = [...validationErrors];
                                    const validationErrorIndex = updateValidationError.findIndex(
                                      (update) => update.uniqueGid === (`${modifierGroup.gid}&${customItem.gid}&${item.gid}`)
                                                                                            && update.productGid === eventPageProduct.gid,
                                    );
                                    if (modifierGroup.minRequired && itemInUpdates?.quantity > 0) {
                                      if (modifierQuantityTotal < modifierGroup.minRequired) {
                                        if (validationErrorIndex === -1) {
                                          updateValidationError.push({
                                            uniqueGid: `${modifierGroup.gid}&${customItem.gid}&${item.gid}`,
                                            customItemGid: customItem.gid,
                                            itemGid: item.gid,
                                            productGid: eventPageProduct.gid,
                                            errorMessage: `Add Modifications -> "${modifierGroup.name}" : for Item -> "${item.displayName}" in -> "${customItem.inputFieldLabel}"`,
                                          });
                                          setValidationErrors(updateValidationError);
                                        }
                                      } else if (validationErrorIndex !== -1) {
                                        updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                                        setValidationErrors(updateValidationError);
                                      }
                                    } else if (validationErrorIndex !== -1) {
                                      updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                                      setValidationErrors(updateValidationError);
                                    }
                                  })}
                                </div>
                                                                        )}
                              </div>
                            ))}
                          </div>
                          )}
                        </div>
                                                )}
                      </div>
                    ) : (
                      <>
                        {customItem.modifierGroups?.length > 0 && (
                        <div>
                          {customItem.modifierGroups?.map((modifierGroup) => {
                            let updateValidationError = [...validationErrors];
                            const validationErrorIndex = updateValidationError.findIndex(
                              (update) => update.uniqueGid === (`${modifierGroup.gid}&${customItem.gid}`)
                                                                    && update.productGid === eventPageProduct.gid,
                            );
                            if (validationErrorIndex !== -1) {
                              updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                              setValidationErrors(updateValidationError);
                              setUpdateOrderItem(true);
                            }
                          })}
                        </div>
                                                )}
                      </>
                    ))
                                        )}
                  </>
                )}
              </>
            ) : (
              <>
                <h2 style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
                  {eventPageProduct.eventPageProductName}
                </h2>

                {eventPageProduct?.items?.length > 0 && (
                    eventPageProduct.items.map((item, index) => (
                      <>
                        <div
                          key={item.gid}
                          style={{
                            marginBottom: '10px',
                            padding: mobileView ? '0' : '10px',
                            borderTop: `${index === 0 ? '' : '2px dashed #B3B3B3'}`,

                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>
                                {item.displayName}
                              </h3>
                              <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                                $ {item.price ? AmountDivideByHundred(item.price) : '0.00'}
                                {item.coupon && (
                                <span className="discount-badge">
                                  {item.coupon.percentageOff
                                    ? `${item.coupon.percentageOff}%`
                                    : `${item.price > item.coupon.amountOff ? '$ ' : '100% upto $ '}${AmountDivideByHundred(item.coupon.amountOff)}`} Off
                                </span>
                                )}
                              </p>
                            </div>
                            <div className="d-flex align-items-center" style={{ gap: '10px', flexDirection: 'column' }}>
                              {!updates.some((u) => u.itemGid === item.gid && u.listedItem) ? (
                                item.enableStock && item.onlineStock === 0 ? (
                                  <img alt="" src={outOfStock} style={{ width: '64px', height: '64px' }} />
                                ) : (
                                  <button
                                    type="button"
                                    className="add-button"
                                    style={{
                                      color: `${item.enableStock && item.onlineStock === 0 ? 'rgba(0,0,0,0.35)' : 'rgba(0,0,0,0.69)'}`,
                                    }}
                                    disabled={item.enableStock && item.onlineStock === 0}
                                    onClick={() => updateQuantity(null, item, null, null, 1, 0)}
                                  >
                                    Add
                                  </button>
                                )
                              ) : (
                                <div style={{ display: 'contents' }}>
                                  <div
                                    className="d-flex align-items-center justify-content-between overflow-hidden"
                                    style={{
                                      backgroundColor: '#FFFFFF',
                                      border: '1px solid #000000',
                                      borderRadius: '5px',
                                      marginTop: item.modifierGroups?.length > 0
                                                            && !(item.enableStock && updates.find((u) => u.itemGid === item.gid && u.listedItem)?.quantity >= item.onlineStock)
                                                            && updates.find((u) => u.itemGid === item.gid && u.listedItem)?.quantity > 0
                                        ? '10px'
                                        : '0px',
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="increase-decrease-button"
                                      onClick={() => updateQuantity(null, item, null, null, -1, 0)}
                                    >
                                      –
                                    </button>
                                    <input
                                      type="text"
                                      className="input-item"
                                      value={updates.find((u) => u.itemGid === item.gid && u.listedItem)?.quantity || 0}
                                    />
                                    {!(item.enableStock && updates.find((u) => u.itemGid === item.gid && u.listedItem)?.quantity >= item.onlineStock) && (
                                    <button
                                      type="button"
                                      className="increase-decrease-button"
                                      onClick={() => updateQuantity(null, item, null, null, 1, 0)}
                                    >
                                      +
                                    </button>
                                    )}
                                  </div>
                                  {item.modifierGroups?.length > 0
                                                    && !(item.enableStock && updates.find((u) => u.itemGid === item.gid && u.listedItem)?.quantity >= item.onlineStock)
                                                    && updates.find((u) => u.itemGid === item.gid && u.listedItem)?.quantity > 0 && (
                                                    <button
                                                      type="button"
                                                      className="drop-down-menu"
                                                      onClick={() => duplicateItem(null, item)}
                                                    >
                                                      <u>Add More Item</u>
                                                    </button>
                                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                          {item.modifierGroups?.length > 0 && (
                            <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                              <button
                                type="button"
                                className="drop-down-menu"
                                onClick={() => toggleModifier(item.gid)}
                                disabled={item.enableStock && item.onlineStock === 0}
                              >
                                Add Modifications &#9660;
                              </button>
                              {activeModifier === item.gid && (
                              <div style={{ marginTop: '10px', marginLeft: mobileView ? '10px' : '20px' }}>
                                {item.modifierGroups.map((modifierGroup) => (
                                  <div key={modifierGroup.gid} style={{ marginBottom: '5px' }}>
                                    <h4 className="additional-info mb-2" style={{ fontStyle: 'normal', color: '#000000' }}>{modifierGroup.name} {modifierGroup?.minRequired || modifierGroup?.maxAllowed ? `(${modifierGroup.minRequired ? `choose ${modifierGroup.minRequired}` : ''} ${modifierGroup.maxAllowed ? `max ${modifierGroup.maxAllowed}` : ''})` : ''}</h4>
                                    {modifierGroup?.modifiers.map((mod, modIndex) => {
                                      const itemInUpdates = updates.find((u) => u.itemGid === item.gid && u.listedItem);

                                      const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                                                (lineItemModifier) => modifierGroup.modifiers.some(
                                                                  (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                                )
                                                            ) || [];

                                      const modifierQuantityTotal = modifiersInGroup.reduce(
                                        (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                        0,
                                      );

                                      const modifierInItem = itemInUpdates?.lineItemModifiers.find((m) => m.modifierGid === mod.gid);

                                      const disableModifier = !mod.available
                                        || (mod?.maxStackableQuantity && modifierInItem?.quantity >= mod.maxStackableQuantity)
                                        || (modifierGroup?.maxAllowed && modifierQuantityTotal >= modifierGroup.maxAllowed);

                                      return (
                                        <div
                                          key={mod.gid}
                                          className="d-flex align-items-center justify-content-between"
                                          style={{
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            borderTop: modIndex === 0 ? 'none' : '1px dashed #B3B3B3',
                                          }}
                                        >
                                          <div>
                                            <p>{mod.name}</p>
                                            <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                                              $ {mod.price ? AmountDivideByHundred(mod.price) : '0.00'}
                                            </p>
                                          </div>

                                          {modifierInItem ? (
                                            <div
                                              className="d-flex align-items-center justify-content-between overflow-hidden"
                                              style={{
                                                backgroundColor: '#FFFFFF',
                                                border: '1px solid #000000',
                                                borderRadius: '5px',
                                              }}
                                            >
                                              <button
                                                type="button"
                                                className="increase-decrease-button"
                                                style={{ padding: '6px 12px' }}
                                                onClick={() => updateQuantity(null, item, mod, null, -1, 0)}
                                              >
                                                –
                                              </button>
                                              <input
                                                type="text"
                                                className="input-item"
                                                value={modifierInItem?.quantity || 0}
                                              />
                                              {!disableModifier && (
                                              <button
                                                type="button"
                                                className="increase-decrease-button"
                                                style={{ padding: '6px 12px' }}
                                                onClick={() => updateQuantity(null, item, mod, null, 1, 0)}
                                                disabled={disableModifier}
                                              >
                                                +
                                              </button>
                                              )}
                                            </div>
                                          ) : (
                                            <button
                                              type="button"
                                              className="add-button"
                                              style={{
                                                padding: '8px 16px',
                                                color: `${!disableModifier ? 'rgba(0,0,0,0.69)' : 'rgba(0,0,0,0.35)'}`,
                                              }}
                                              disabled={disableModifier}
                                              onClick={() => updateQuantity(null, item, mod, null, 1, 0)}
                                            >
                                              Add
                                            </button>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ))}
                              </div>
                              )}
                              {item.modifierGroups?.map((modifierGroup) => {
                                const itemInUpdates = updates.find((u) => u.itemGid === item.gid && u.listedItem);

                                const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                                (lineItemModifier) => modifierGroup.modifiers.some(
                                                  (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                )
                                            ) || [];

                                const modifierQuantityTotal = modifiersInGroup.reduce(
                                  (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                  0,
                                );

                                let updateValidationError = [...validationErrors];
                                const validationErrorIndex = updateValidationError.findIndex(
                                  (update) => update.uniqueGid === (`${modifierGroup.gid}&${item.gid}`)
                                                    && update.productGid === eventPageProduct.gid,
                                );
                                if (modifierGroup.minRequired && itemInUpdates?.quantity > 0) {
                                  if (modifierQuantityTotal < modifierGroup.minRequired) {
                                    if (validationErrorIndex === -1) {
                                      updateValidationError.push({
                                        uniqueGid: `${modifierGroup.gid}&${item.gid}`,
                                        itemGid: item.gid,
                                        productGid: eventPageProduct.gid,
                                        errorMessage: `Add Modification -> "${modifierGroup.name}" : for Item -> "${item.displayName}"`,
                                      });
                                      setValidationErrors(updateValidationError);
                                    }
                                  } else if (validationErrorIndex !== -1) {
                                    updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                                    setValidationErrors(updateValidationError);
                                  }
                                } else if (validationErrorIndex !== -1) {
                                  updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                                  setValidationErrors(updateValidationError);
                                }
                              })}
                            </div>
                                )}
                        </div>
                        {updates?.filter((lineItem) => lineItem.itemGid === item.gid && !lineItem.listedItem).map((lineItem, lineIndex) => (
                          <div
                            key={lineIndex}
                            style={{
                              marginBottom: '10px',
                              padding: mobileView ? '0' : '10px',
                              borderTop: '2px dashed #B3B3B3',
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>
                                  {lineItem.name}
                                </h3>
                                {lineItem.seats?.length > 0 && (<p className="additional-info">Seats: [{lineItem.seats.join(', ')}]</p>)}
                                <p style={{ color: '#555', padding: '5px' }}>
                                  ${lineItem.price ? AmountDivideByHundred(lineItem.price) : '0.00'}
                                  {lineItem.lineItemDiscounts?.length > 0 && (
                                    <span className="sc-fbJfA uhGua discount-badge">
                                      {lineItem.lineItemDiscounts[0].percentageOff
                                        ? `${lineItem.lineItemDiscounts[0].percentageOff}%`
                                        : `${lineItem.price > lineItem.lineItemDiscounts[0].amountOff ? '$ ' : '100% upto $ '}${AmountDivideByHundred(lineItem.lineItemDiscounts[0].amountOff * -1)}`} Off
                                    </span>
                                                )}
                                </p>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between overflow-hidden"
                                style={{
                                  backgroundColor: '#FFFFFF',
                                  border: '1px solid #000000',
                                  borderRadius: '5px',
                                }}
                              >
                                <button
                                  type="button"
                                  className="increase-decrease-button"
                                  onClick={() => updateQuantity(null, item, null, null, -1, 0, lineItem.duplicateIndex)}
                                >
                                  –
                                </button>
                                <input
                                  type="text"
                                  className="input-item"
                                  value={lineItem?.quantity || 0}
                                />
                                {!(item?.enableStock && lineItem?.quantity >= item?.onlineStock) && (
                                <button
                                  type="button"
                                  className="increase-decrease-button"
                                  onClick={() => updateQuantity(null, item, null, null, 1, 0, lineItem.duplicateIndex)}
                                >
                                  +
                                </button>
                                )}
                              </div>
                            </div>
                            {lineItem.lineItemModifiers && lineItem.lineItemModifiers.length > 0 && (
                            <div style={{ padding: '5px' }}>
                              <p className="additional-info">Modifiers:</p>
                              {lineItem.lineItemModifiers.map((lineItemModifier, lineIndex) => (
                                <p
                                  key={lineIndex}
                                  style={{ margin: '7.5px 20px', fontSize: '14px', color: '#555' }}
                                >
                                  {lineItemModifier.name} ($
                                  {lineItemModifier.price ? AmountDivideByHundred(lineItemModifier.price) : '0.00'} x
                                  {lineItemModifier.quantity})
                                </p>
                              ))}
                            </div>
                            )}
                            {lineItem.lineItemItems && lineItem.lineItemItems.length > 0 && (
                            <>
                              <p className="additional-info" style={{ padding: '5px' }}>
                                Items:
                              </p>
                              {lineItem.lineItemItems.map((lineItemItem, lineIndex) => (
                                <div style={{ padding: '5px' }} key={lineIndex}>
                                  <p
                                    style={{ margin: '7.5px 20px', fontSize: '14px', color: '#555' }}
                                  >
                                    {lineItemItem.name}
                                    ({lineItemItem.lineItemModifiers && lineItemItem.lineItemModifiers.length > 0
                                      ? `($ ${lineItemItem.price ? AmountDivideByHundred(lineItemItem.price) : '0.00'} + (M - $ ${AmountDivideByHundred(lineItemItem.lineItemModifiers.reduce(
                                        (sum, modifier) => sum + (modifier.quantity * modifier.price || 0), 0,
                                      ))}))`
                                      : `$ ${lineItemItem.price ? AmountDivideByHundred(lineItemItem.price) : '0.00'}`} x {lineItemItem.quantity})
                                    {lineItemItem.lineItemDiscounts?.length > 0 && (
                                    <span className="sc-fbJfA uhGua discount-badge">
                                      {lineItemItem.lineItemDiscounts[0].percentageOff
                                        ? `${lineItemItem.lineItemDiscounts[0].percentageOff}%`
                                        : `${lineItemItem.price > (lineItemItem.lineItemDiscounts[0].amountOff * -1) ? '$ ' : '100% upto $ '}${AmountDivideByHundred(lineItemItem.lineItemDiscounts[0].amountOff * -1)}`} Off
                                    </span>
                                                        )}
                                  </p>

                                  {lineItemItem.lineItemModifiers && lineItemItem.lineItemModifiers.length > 0 && (
                                  <>
                                    <p className="additional-info" style={{ margin: '7.5px 20px', padding: '5px' }}>
                                      Modifiers(M):
                                    </p>
                                    {lineItemItem.lineItemModifiers.map((lineItemModifier, lineItemIndex) => (
                                      <p
                                        key={`${lineIndex}-${lineItemIndex}`}
                                        style={{
                                          margin: '7.5px 40px', fontSize: '14px', color: '#555', padding: '5px',
                                        }}
                                      >
                                        {lineItemModifier.name} ($
                                        {lineItemModifier.price ? AmountDivideByHundred(lineItemModifier.price) : '0.00'} x
                                        {lineItemModifier.quantity})
                                      </p>
                                    ))}
                                  </>
                                  )}
                                </div>
                              ))}
                            </>
                            )}
                          </div>
                        ))}
                      </>
                    )))}

                {eventPageProduct?.customItems?.length > 0 && (
                    eventPageProduct.customItems.map((customItem, index) => (
                      <>
                        <div
                          key={index}
                          style={{
                            marginBottom: '10px',
                            padding: mobileView ? '0' : '10px',
                            borderTop: index !== 0 || eventPageProduct?.items?.length > 0 ? '2px dashed #B3B3B3' : 'none',
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>{customItem.inputFieldLabel}</h3>
                              {(() => {
                                const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);
                                if (customItemInUpdates && customItemInUpdates.seats) {
                                  return (<p className="additional-info" style={{ padding: '5px' }}>Seats: [{customItemInUpdates.seats.join(', ')}]</p>);
                                }
                                return (<></>);
                              })()}
                              <p style={{ color: '#555', padding: '5px' }}>$ {customItem.amount ? AmountDivideByHundred(customItem.amount) : '0.00'}</p>
                              {(() => {
                                if (!customItem.isOptional) {
                                  const updateMandatoryItems = [...mandatoryItems];
                                  const itemIndex = updateMandatoryItems.findIndex(
                                    (update) => update.customItemGid === (customItem.gid),
                                  );
                                  if (itemIndex === -1) {
                                    updateMandatoryItems.push({
                                      customItemGid: customItem.gid,
                                      errorMessage: `Add ${customItem.inputFieldLabel} To Proceed`,
                                    });
                                    setMandatoryItems(updateMandatoryItems);
                                    setTimeout(() => {
                                      setUpdateOrderItem(true);
                                    }, 500);
                                  }
                                }
                                return (<></>);
                              })()}
                            </div>
                            <div className="d-flex align-items-center" style={{ gap: '10px', flexDirection: 'column' }}>
                              {!updates.some((u) => u.customItemGid === customItem.gid && u.listedItem) ? (
                                <button
                                  type="button"
                                  className="add-button"
                                  style={{
                                    color: 'rgba(0,0,0,0.69)',
                                  }}
                                  onClick={() => updateQuantity(customItem, null, null, null, 1, 0)}
                                >
                                  Add
                                </button>
                              ) : (
                                <div style={{ display: 'contents' }}>
                                  <div
                                    className="d-flex align-items-center justify-content-between overflow-hidden"
                                    style={{
                                      backgroundColor: '#FFFFFF',
                                      border: '1px solid #000000',
                                      borderRadius: '5px',
                                      marginTop: (customItem.modifierGroups?.length > 0 || customItem.items?.length > 0)
                                                            && updates.find((u) => u.customItemGid === customItem.gid && u.listedItem)?.quantity > 0 ? '10px' : '',
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="increase-decrease-button"
                                      onClick={() => updateQuantity(customItem, null, null, null, -1, 0)}
                                    >
                                      –
                                    </button>
                                    <input
                                      type="text"
                                      className="input-item"
                                      value={updates.find((u) => u.customItemGid === customItem.gid && u.listedItem)?.quantity || 0}
                                    />
                                    <button
                                      type="button"
                                      className="increase-decrease-button"
                                      onClick={() => updateQuantity(customItem, null, null, null, 1, 0)}
                                    >
                                      +
                                    </button>
                                  </div>
                                  {(customItem.modifierGroups?.length > 0 || customItem.items?.length > 0)
                                    && updates.find((u) => u.customItemGid === customItem.gid && u.listedItem)?.quantity > 0 && (
                                    <button
                                      type="button"
                                      className="drop-down-menu"
                                      onClick={() => duplicateItem(customItem, null)}
                                    >
                                      <u>Add More Item</u>
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          {customItem.modifierGroups?.length > 0 && (
                            <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                              <button
                                type="button"
                                className="drop-down-menu"
                                onClick={() => toggleModifier(customItem.gid)}
                              >
                                Add Modifications &#9660;
                              </button>
                              {activeModifier === customItem.gid && (
                              <div style={{ marginTop: '10px', marginLeft: mobileView ? '10px' : '20px' }}>
                                {customItem.modifierGroups.map((modifierGroup) => (
                                  <div key={modifierGroup.gid} style={{ marginBottom: '5px' }}>
                                    <h4 className="additional-info mb-2" style={{ fontStyle: 'normal', color: '#000000' }}>{modifierGroup.name} {modifierGroup?.minRequired || modifierGroup?.maxAllowed ? `(${modifierGroup.minRequired ? `choose ${modifierGroup.minRequired}` : ''} ${modifierGroup.maxAllowed ? `max ${modifierGroup.maxAllowed}` : ''})` : ''}</h4>
                                    {modifierGroup?.modifiers.map((mod, modIndex) => {
                                      const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);

                                      const modifiersInGroup = customItemInUpdates?.lineItemModifiers.filter(
                                                                (lineItemModifier) => modifierGroup.modifiers.some(
                                                                  (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                                )
                                                            ) || [];

                                      const modifierQuantityTotal = modifiersInGroup.reduce(
                                        (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                        0,
                                      );

                                      const modifierInItem = customItemInUpdates?.lineItemModifiers.find((m) => m.modifierGid === mod.gid);

                                      const disableModifier = !mod.available
                                                                || (mod?.maxStackableQuantity && modifierInItem?.quantity >= mod.maxStackableQuantity)
                                                                || (modifierGroup?.maxAllowed && modifierQuantityTotal >= modifierGroup.maxAllowed);

                                      return (
                                        <div
                                          key={mod.gid}
                                          className="d-flex align-items-center justify-content-between"
                                          style={{
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            borderTop: modIndex === 0 ? 'none' : '1px dashed #B3B3B3',
                                          }}
                                        >
                                          <div>
                                            <p>{mod.name}</p>
                                            <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                                              $ {mod.price ? AmountDivideByHundred(mod.price) : '0.00'}
                                            </p>
                                          </div>

                                          {modifierInItem ? (
                                            <div
                                              className="d-flex align-items-center justify-content-between overflow-hidden"
                                              style={{
                                                backgroundColor: '#FFFFFF',
                                                border: '1px solid #000000',
                                                borderRadius: '5px',
                                              }}
                                            >
                                              <button
                                                type="button"
                                                className="increase-decrease-button"
                                                style={{ padding: '6px 12px' }}
                                                onClick={() => updateQuantity(customItem, null, mod, null, -1, 0)}
                                              >
                                                –
                                              </button>
                                              <input
                                                type="text"
                                                className="input-item"
                                                value={modifierInItem?.quantity || 0}
                                                readOnly
                                              />
                                              {!disableModifier && (
                                              <button
                                                type="button"
                                                className="increase-decrease-button"
                                                style={{ padding: '6px 12px' }}
                                                onClick={() => updateQuantity(customItem, null, mod, null, 1, 0)}
                                                disabled={disableModifier}
                                              >
                                                +
                                              </button>
                                              )}
                                            </div>
                                          ) : (
                                            <button
                                              type="button"
                                              className="add-button"
                                              style={{
                                                color: `${!disableModifier ? 'rgba(0,0,0,0.69)' : 'rgba(0,0,0,0.35)'}`,
                                                padding: '6px 12px',
                                              }}
                                              disabled={disableModifier}
                                              onClick={() => updateQuantity(customItem, null, mod, null, 1, 0)}
                                            >
                                              Add
                                            </button>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ))}
                              </div>
                              )}
                              {customItem.modifierGroups?.map((modifierGroup) => {
                                const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);

                                const modifiersInGroup = customItemInUpdates?.lineItemModifiers.filter(
                                                (lineItemModifier) => modifierGroup.modifiers.some(
                                                  (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                )
                                            ) || [];

                                const modifierQuantityTotal = modifiersInGroup.reduce(
                                  (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                  0,
                                );
                                let updateValidationError = [...validationErrors];
                                const validationErrorIndex = updateValidationError.findIndex(
                                  (update) => update.uniqueGid === (`${modifierGroup.gid}&${customItem.gid}`)
                                                    && update.productGid === eventPageProduct.gid,
                                );
                                if (modifierGroup.minRequired && customItemInUpdates?.quantity > 0) {
                                  if (modifierQuantityTotal < modifierGroup.minRequired) {
                                    if (validationErrorIndex === -1) {
                                      updateValidationError.push({
                                        uniqueGid: `${modifierGroup.gid}&${customItem.gid}`,
                                        customItemGid: customItem.gid,
                                        productGid: eventPageProduct.gid,
                                        errorMessage: `Add Modifications -> "${modifierGroup.name}" : for Item -> "${customItem.inputFieldLabel}"`,
                                      });
                                      setValidationErrors(updateValidationError);
                                    }
                                  } else if (validationErrorIndex !== -1) {
                                    updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                                    setValidationErrors(updateValidationError);
                                  }
                                } else if (validationErrorIndex !== -1) {
                                  updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                                  setValidationErrors(updateValidationError);
                                }
                              })}
                            </div>
                                )}
                          {customItem?.items?.length > 0 && (
                            <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                              <button
                                type="button"
                                className="drop-down-menu"
                                onClick={() => toggleItem(customItem.gid)}
                              >
                                Add Items &#9660;
                              </button>
                              {activeItem === customItem.gid && (
                              <div style={{ marginTop: '10px', marginLeft: mobileView ? '10px' : '20px' }}>
                                {customItem.items.map((item, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      marginBottom: '10px',
                                      padding: mobileView ? '0' : '10px',
                                      borderTop: index === 0 ? 'none' : '1px dashed #B3B3B3',
                                    }}
                                  >
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div>
                                        <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>{item.displayName}</h3>
                                        <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                                          $ {item.price ? AmountDivideByHundred(item.price) : '0.00'}
                                          {item.coupon && (
                                          <span className="sc-fbJfA uhGua discount-badge">
                                            {item.coupon.percentageOff
                                              ? `${item.coupon.percentageOff}%`
                                              : `${item.price > item.coupon.amountOff ? '$ ' : '100% upto $ '}${AmountDivideByHundred(item.coupon.amountOff)}`} Off
                                          </span>
                                          )}
                                        </p>
                                      </div>
                                      {(() => {
                                        const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);
                                        const itemInUpdates = customItemInUpdates?.lineItemItems?.find((c) => c.itemGid === item.gid);

                                        return !itemInUpdates ? (
                                          item.enableStock && item.onlineStock === 0 ? (
                                            <img alt="" src={outOfStock} style={{ width: '64px', height: '64px' }} />
                                          ) : (
                                            <button
                                              type="button"
                                              className="add-button"
                                              style={{
                                                color: `${item.enableStock && item.onlineStock === 0 ? 'rgba(0,0,0,0.35)' : 'rgba(0,0,0,0.69)'}`,
                                              }}
                                              disabled={(item.enableStock && item.onlineStock === 0)}
                                              onClick={() => updateQuantity(customItem, item, null, null, 1, 0)}
                                            >
                                              Add
                                            </button>
                                          )
                                        ) : (
                                          <div
                                            className="d-flex align-items-center justify-content-between overflow-hidden"
                                            style={{
                                              backgroundColor: '#FFFFFF',
                                              border: '1px solid #000000',
                                              borderRadius: '5px',
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="increase-decrease-button"
                                              style={{ padding: '6px 12px' }}
                                              onClick={() => updateQuantity(customItem, item, null, null, -1, 0)}
                                            >
                                              –
                                            </button>
                                            <input
                                              type="text"
                                              className="input-item"
                                              value={itemInUpdates?.quantity || 0}
                                            />
                                            {!(item.enableStock && (itemInUpdates?.quantity * customItemInUpdates.quantity) >= item.onlineStock) && (
                                            <button
                                              type="button"
                                              className="increase-decrease-button"
                                              style={{ padding: '6px 12px' }}
                                              onClick={() => updateQuantity(customItem, item, null, null, 1, 0)}
                                            >
                                              +
                                            </button>
                                            )}
                                          </div>
                                        );
                                      })()}
                                    </div>
                                    {item.modifierGroups?.length > 0 && (
                                    <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                                      <button
                                        type="button"
                                        onClick={() => toggleItemModifier(item.gid)}
                                        disabled={item.enableStock && item.onlineStock === 0}
                                        className="drop-down-menu"
                                      >
                                        Add Modifications &#9660;
                                      </button>
                                      {activeItemModifier === item.gid && (
                                      <div style={{ marginTop: '10px', marginLeft: mobileView ? '10px' : '20px' }}>
                                        {item.modifierGroups.map((modifierGroup) => (
                                          <div key={modifierGroup.gid} style={{ marginBottom: '5px' }}>
                                            <h4 className="additional-info mb-2" style={{ fontStyle: 'normal', color: '#000000' }}>{modifierGroup.name} {modifierGroup?.minRequired || modifierGroup?.maxAllowed ? `(${modifierGroup.minRequired ? `choose ${modifierGroup.minRequired}` : ''} ${modifierGroup.maxAllowed ? `max ${modifierGroup.maxAllowed}` : ''})` : ''}</h4>
                                            {modifierGroup?.modifiers.map((mod, modIndex) => {
                                              const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);
                                              const itemInUpdates = customItemInUpdates?.lineItemItems?.find((u) => u.itemGid === item.gid);

                                              const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                                                                        (lineItemModifier) => modifierGroup.modifiers.some(
                                                                                          (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                                                        )
                                                                                    ) || [];

                                              const modifierQuantityTotal = modifiersInGroup.reduce(
                                                (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                                0,
                                              );

                                              const modifierInItem = itemInUpdates?.lineItemModifiers.find((m) => m.modifierGid === mod.gid);

                                              const disableModifier = !mod.available
                                                                                        || (mod?.maxStackableQuantity && modifierInItem?.quantity >= mod.maxStackableQuantity)
                                                                                        || (modifierGroup?.maxAllowed && modifierQuantityTotal >= modifierGroup.maxAllowed);

                                              return (
                                                <div
                                                  key={mod.gid}
                                                  className="d-flex align-items-center justify-content-between"
                                                  style={{
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                    borderTop: modIndex === 0 ? 'none' : '0.5px dashed #B3B3B3',
                                                  }}
                                                >
                                                  <div>
                                                    <p>{mod.name}</p>
                                                    <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                                                      $ {mod.price ? AmountDivideByHundred(mod.price) : '0.00'}
                                                    </p>
                                                  </div>

                                                  {modifierInItem ? (
                                                    <div
                                                      className="d-flex align-items-center justify-content-between overflow-hidden"
                                                      style={{
                                                        backgroundColor: '#FFFFFF',
                                                        border: '1px solid #000000',
                                                        borderRadius: '5px',
                                                      }}
                                                    >
                                                      <button
                                                        type="button"
                                                        className="increase-decrease-button"
                                                        style={{ padding: '6px 12px' }}
                                                        onClick={() => updateQuantity(customItem, item, mod, null, -1, 0)}
                                                      >
                                                        –
                                                      </button>
                                                      <input
                                                        type="text"
                                                        className="input-item"
                                                        value={modifierInItem?.quantity || 0}
                                                      />
                                                      {!disableModifier && (
                                                      <button
                                                        type="button"
                                                        className="increase-decrease-button"
                                                        style={{ padding: '6px 12px' }}
                                                        onClick={() => updateQuantity(customItem, item, mod, null, 1, 0)}
                                                        disabled={disableModifier}
                                                      >
                                                        +
                                                      </button>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <button
                                                      type="button"
                                                      className="add-button"
                                                      style={{
                                                        color: `${!disableModifier ? 'rgba(0,0,0,0.69)' : 'rgba(0,0,0,0.35)'}`,
                                                        padding: '6px 12px',
                                                      }}
                                                      disabled={disableModifier || !customItemInUpdates}
                                                      title={!customItemInUpdates ? `Add ${customItem.inputFieldLabel} to proceed` : ''}
                                                      onClick={() => updateQuantity(customItem, item, mod, null, 1, 0)}
                                                    >
                                                      Add
                                                    </button>
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        ))}
                                      </div>
                                      )}
                                      {item.modifierGroups?.map((modifierGroup) => {
                                        const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid && u.listedItem);
                                        const itemInUpdates = customItemInUpdates?.lineItemItems?.find((u) => u.itemGid === item.gid);

                                        const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                                                        (lineItemModifier) => modifierGroup.modifiers.some(
                                                                          (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                                        )
                                                                    ) || [];

                                        const modifierQuantityTotal = modifiersInGroup.reduce(
                                          (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                          0,
                                        );

                                        let updateValidationError = [...validationErrors];
                                        const validationErrorIndex = updateValidationError.findIndex(
                                          (update) => update.uniqueGid === (`${modifierGroup.gid}&${customItem.gid}&${item.gid}`)
                                                                            && update.productGid === eventPageProduct.gid,
                                        );
                                        if (modifierGroup.minRequired && itemInUpdates?.quantity > 0) {
                                          if (modifierQuantityTotal < modifierGroup.minRequired) {
                                            if (validationErrorIndex === -1) {
                                              updateValidationError.push({
                                                uniqueGid: `${modifierGroup.gid}&${customItem.gid}&${item.gid}`,
                                                customItemGid: customItem.gid,
                                                itemGid: item.gid,
                                                productGid: eventPageProduct.gid,
                                                errorMessage: `Add Modifications -> "${modifierGroup.name}" : for Item -> "${item.displayName}" in -> "${customItem.inputFieldLabel}"`,
                                              });
                                              setValidationErrors(updateValidationError);
                                            }
                                          } else if (validationErrorIndex !== -1) {
                                            updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                                            setValidationErrors(updateValidationError);
                                          }
                                        } else if (validationErrorIndex !== -1) {
                                          updateValidationError = updateValidationError.filter((_, index) => index !== validationErrorIndex);
                                          setValidationErrors(updateValidationError);
                                        }
                                      })}
                                    </div>
                                                        )}
                                  </div>
                                ))}
                              </div>
                              )}
                            </div>
                                )}
                        </div>
                        {updates?.filter((lineItem) => lineItem.customItemGid === customItem.gid && !lineItem.listedItem).map((lineItem, lineIndex) => (
                          <div
                            key={lineIndex}
                            style={{
                              marginBottom: '10px',
                              padding: mobileView ? '0' : '10px',
                              borderTop: '2px dashed #B3B3B3',
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>
                                  {lineItem.name}
                                </h3>
                                {lineItem.seats?.length > 0 && (<p className="additional-info">Seats: [{lineItem.seats.join(', ')}]</p>)}
                                <p style={{ color: '#555', padding: '5px' }}>
                                  ${lineItem.price ? AmountDivideByHundred(lineItem.price) : '0.00'}
                                  {lineItem.lineItemDiscounts?.length > 0 && (
                                    <span className="sc-fbJfA uhGua discount-badge">
                                      {lineItem.lineItemDiscounts[0].percentageOff
                                        ? `${lineItem.lineItemDiscounts[0].percentageOff}%`
                                        : `${lineItem.price > lineItem.lineItemDiscounts[0].amountOff ? '$ ' : '100% upto $ '}${AmountDivideByHundred(lineItem.lineItemDiscounts[0].amountOff * -1)}`} Off
                                    </span>
                                                )}
                                </p>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between overflow-hidden"
                                style={{
                                  backgroundColor: '#FFFFFF',
                                  border: '1px solid #000000',
                                  borderRadius: '5px',
                                }}
                              >
                                <button
                                  type="button"
                                  className="increase-decrease-button"
                                  onClick={() => updateQuantity(customItem, null, null, null, -1, 0, lineItem.duplicateIndex)}
                                >
                                  –
                                </button>
                                <input
                                  type="text"
                                  className="input-item"
                                  value={lineItem?.quantity || 0}
                                />
                                <button
                                  type="button"
                                  className="increase-decrease-button"
                                  onClick={() => updateQuantity(customItem, null, null, null, 1, 0, lineItem.duplicateIndex)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            {lineItem.lineItemModifiers?.length > 0 && (
                            <div style={{ padding: '5px' }}>
                              <p className="additional-info">Modifiers:</p>
                              {lineItem.lineItemModifiers.map((lineItemModifier, lineIndex) => (
                                <p
                                  key={lineIndex}
                                  style={{ margin: '7.5px 20px', fontSize: '14px', color: '#555' }}
                                >
                                  {lineItemModifier.name} ($
                                  {lineItemModifier.price ? AmountDivideByHundred(lineItemModifier.price) : '0.00'} x
                                  {lineItemModifier.quantity})
                                </p>
                              ))}
                            </div>
                                    )}
                            {lineItem.lineItemItems?.length > 0 && (
                            <div style={{ padding: '5px' }}>
                              <p className="additional-info">
                                Items:
                              </p>
                              {lineItem.lineItemItems.map((lineItemItem, lineIndex) => (
                                <div style={{ padding: '5px' }} key={lineIndex}>
                                  <p
                                    style={{ margin: '0px 20px', fontSize: '14px', color: '#555' }}
                                  >
                                    {lineItemItem.name}
                                    ({lineItemItem.lineItemModifiers && lineItemItem.lineItemModifiers.length > 0
                                      ? `($ ${lineItemItem.price ? AmountDivideByHundred(lineItemItem.price) : '0.00'} + (M - $ ${AmountDivideByHundred(lineItemItem.lineItemModifiers.reduce(
                                        (sum, modifier) => sum + (modifier.quantity * modifier.price || 0), 0,
                                      ))}))`
                                      : `$ ${lineItemItem.price ? AmountDivideByHundred(lineItemItem.price) : '0.00'}`} x {lineItemItem.quantity})
                                    {lineItemItem.lineItemDiscounts?.length > 0 && (
                                    <span className="sc-fbJfA uhGua discount-badge">
                                      {lineItemItem.lineItemDiscounts[0].percentageOff
                                        ? `${lineItemItem.lineItemDiscounts[0].percentageOff}%`
                                        : `${lineItemItem.price > (lineItemItem.lineItemDiscounts[0].amountOff * -1) ? '$ ' : '100% upto $ '}${AmountDivideByHundred(lineItemItem.lineItemDiscounts[0].amountOff * -1)}`} Off
                                    </span>
                                                        )}
                                  </p>

                                  {lineItemItem.lineItemModifiers && lineItemItem.lineItemModifiers.length > 0 && (
                                  <>
                                    <p className="additional-info" style={{ margin: '0px 20px', padding: '5px' }}>
                                      Modifiers(M):
                                    </p>
                                    {lineItemItem.lineItemModifiers.map((lineItemModifier, lineItemIndex) => (
                                      <p
                                        key={`${lineIndex}-${lineItemIndex}`}
                                        style={{
                                          margin: '0px 40px', fontSize: '14px', color: '#555', padding: '5px',
                                        }}
                                      >
                                        {lineItemModifier.name} ($
                                        {lineItemModifier.price ? AmountDivideByHundred(lineItemModifier.price) : '0.00'} x
                                        {lineItemModifier.quantity})
                                      </p>
                                    ))}
                                  </>
                                  )}
                                </div>
                              ))}
                            </div>
                                    )}
                          </div>
                        ))}
                      </>
                    )))}
              </>
            )}
        </>
      )}
    </div>
  );
};

export default AddProducts;
