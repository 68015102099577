/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Input,
  Select,
  Tabs,
  Divider,
  Icon,
  Checkbox,
} from 'antd';
import {
  SpForm,
  SpError,
  SpButton,
  SpText,
} from 'components/DesignKit';
import { notificationActions, invoiceDetailsAction } from 'store/actions';
import { useLocation } from 'react-router-dom';
import getStateList from 'utils/stateList';
import formatAmount from 'utils/formatAmount';
import getSortedList from 'utils/getSortedList';
import Loading from 'components/Loading';
import { validators } from 'utils/validationMessages';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { API_END_POINTS, axiosAuthInstance } from 'api';
import SimpleReactValidator from 'simple-react-validator';
import {
  COUNTRY_CODES,
  MESSAGES,
} from 'appconstants';
import { NOTIFICATION } from 'store/actionTypes';
import AddNewCustomer from './AddNewCustomer';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

type Props = {
  test: boolean,
  selectedAccount: Object,
  setNotification: Function,
  getCustomersList: Function,
  customersList: Array<Object>,
  submitting: boolean,
  addCustomer: Function,
  addedNewCustomer: Object,
};

const Virtualterminal = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    test,
    setNotification,
    getCustomersList,
    customersList,
    submitting,
    addCustomer,
    addedNewCustomer,
  } = props;
  const [, forceUpdate] = useState();
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState('');
  const [cardLength, setCardLength] = useState(16);
  const [hasCardErrors, setHasCardErrors] = useState(false);
  const [expiry, setExpiry] = useState('');
  const [hasExpiryErrors, setHasExpiryErrors] = useState(false);
  const [cvv, setCvv] = useState('');
  const [hasCvvError, setCvvHasError] = useState(false);
  const [name, setName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryName, setCountryName] = useState(selectedAccount && selectedAccount.country && selectedAccount.country.name);
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [eventAmount, setAmount] = useState(null);
  const countryId = selectedAccount && selectedAccount.country.alpha2;
  const postalCodeRegex = countryId === 'US' ? /^[0-9][0-9]{4}$/ : /^[1-9][0-9]{5}$/;
  const prefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const currencyType = selectedAccount && selectedAccount.currency;
  const [loading, setLoading] = useState(false);
  const [transferType, setTransferType] = useState('card');
  const [accountType, setAccountType] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState();
  const [routingNumber, setRoutingNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const accountRegex = /[0-9]{6,17}/;
  const routingRegex = /[0-9]{9}/;
  const upiRegex = /^\w.+@\w+$/;
  const [customerName, setCustomerName] = useState();
  const [card, setCard] = useState();
  const [card2, setCard2] = useState();
  const [customerId, setCustomerId] = useState();
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [addedCustomer, setAddedCustomer] = useState(false);
  // const [lineItemAdd, setLineItemAdd] = useState(true);
  const [storeCustomerProfile, setStoreCustomerProfile] = useState(false);
  const [customerConsent, setCustomerConsent] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState('1');
  const [paymentMethodGid, setPaymentMethodGid] = useState('');
  const [bankGid, setBankGid] = useState('');
  const [cardGid, setCardGid] = useState('');
  const [entityData, setEntityData] = useState(null);
  const [defaultInterval, setDefaultInterval] = useState('One-time');
  const [description, setDescription] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      confirmAccount: {
        message: MESSAGES.BANK_DETAILS.MATCH,
        rule: (val, params) => val === params[0],
      },
      accountNumber: {
        message: MESSAGES.BANK_DETAILS.VALID,
        rule: val => accountRegex.test(val),
      },
      bankRoutingNumber: {
        message: MESSAGES.BANK_DETAILS.ROUTING,
        rule: val => routingRegex.test(val),
      },
      bankName: {
        message: validators.bussiness.validBankName,
        rule: val => /^[A-Za-z0-9&,\s.\-'\$@_]{1,50}$/.test(val),
      },
      accountHolderName: {
        message: validators.bussiness.validBankAccountName,
        rule: val => /^[A-Za-z0-9&,\s.\-'\$@_]{1,50}$/.test(val),
      },
      stringLength: {
        message: 'please enter a valid bank name',
        rule: val => val.length >= 4,
      },
      upiNumber: {
        message: MESSAGES.BANK_DETAILS.UPI,
        rule: val => upiRegex.test(val),
      },
      cvvValidation: {
        message: 'CVV number is required',
        rule: (val, params) => {
          const [card] = params;
          return card !== '' || (val && val.trim() !== '');
        },
      },
      description: {
        message: 'Description must be 100 characters or fewer.',
        rule: val => val.length <= 100,
      },
    },
  }));

  useEffect(() => {
    getCustomersList();
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
    }
  }, [location, test]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
    }
  }, [selectedAccount]);

  // useEffect(() => {
  //     if (!validator) {
  //         setEditSettings(false);
  //     }
  // }, [validator]);

  const validateCardType = () => {
    const maxLength = cardNumber.substring(0, cardLength);
    const visaCard = ['4'];
    const masterCard = ['51', '52', '53', '54', '55'];
    const dinersCard = ['36', '38', '54', '55'];
    const cartCard = ['300', '301', '302', '303', '304', '305'];
    const amexCard = ['34', '37'];
    const discoverCard = ['60', '62', '64', '65'];
    const jcbCard = ['35'];
    const enRouteCard = ['2014', '2149'];
    const soloCard = ['6334', '6767'];
    const masteroCard = [
      '5018',
      '5020',
      '5038',
      '6304',
      '6759',
      '6761',
      '6762',
      '6763',
    ];
    const visaElectronCard = ['4026', '417500', '4508', '4844', '4913', '4917'];
    const laserCard = ['6304', '6706', '6771', '6709'];

    if (
      (maxLength.length === 13 || maxLength.length === 16)
      && visaCard.includes(maxLength.substring(0, 1))
    ) {
      setCardType('VISA');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
      && masterCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('MASTER');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 14 || maxLength.length === 16)
      && dinersCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('DINERS CLUB');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 14
      && cartCard.includes(maxLength.substring(0, 3))
    ) {
      setCardType('CARTE BLANCHE');
      setCardLength(14);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 15
      && amexCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('AMEX');
      setCardLength(15);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
      && discoverCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('DISCOVER');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
      && jcbCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('JCB');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 15
      && enRouteCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('ENROUTE');
      setCardLength(15);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 16
        || maxLength.length === 18
        || maxLength.length === 19)
      && soloCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('SOLO');
      setCardLength(19);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 12
        || maxLength.length === 13
        || maxLength.length === 14
        || maxLength.length === 15
        || maxLength.length === 16
        || maxLength.length === 18
        || maxLength.length === 19)
      && masteroCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('MAESTRO');
      setCardLength(19);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
      && visaElectronCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('VISA ELECTRON');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 16
        || maxLength.length === 18
        || maxLength.length === 19)
      && laserCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('LASER CARD');
      setCardLength(19);
      setHasCardErrors(false);
    } else if (maxLength.length > 1) {
      setCardType('');
      setHasCardErrors(true);
    }
  };

  const validateCard = (text) => {
    const sample = text.replace(/[^\d]/g, '');
    const maxLength = sample.substring(0, cardLength);
    if (maxLength.length > 0) {
      const joy = maxLength.match(/.{1,4}/g);
      setCardNumber(joy.join(' '));
    } else {
      setCardNumber('');
      setCardType('');
      setHasCardErrors(false);
    }
  };

  const formatCardDate = input => {
    const text = input
      .replace(
        /[^0-9]/g,
        '', // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        '0$1', // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        '0$1/$2', // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        '0', // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g,
        '$1/$2', // To handle 113 > 11/3
      );
    setExpiry(text);
    if (text.length === 5) {
      setHasExpiryErrors(false);
    } else if (text.length === 7) {
      setHasExpiryErrors(false);
    } else if (text.length !== 5) {
      setHasExpiryErrors(true);
    }
  };

  const validateCvv = text => {
    const sample = text.replace(/[^\d]/g, '');
    const maxLength = sample.substring(0, 4);
    setCvv(maxLength);
    if (maxLength.length === 3 || maxLength.length === 4) {
      setCvvHasError(false);
    } else if (maxLength.length !== 3 || maxLength.length !== 4) {
      setCvvHasError(true);
    }
  };

  const addSupportSettings = async (event) => {
    event.preventDefault();
    if (transferType === 'card') {
      simpleValidator.current.fields.Name = true;
      simpleValidator.current.fields['account holder name'] = true;
      simpleValidator.current.fields['account number'] = true;
      simpleValidator.current.fields['account type'] = true;
      simpleValidator.current.fields['bank name'] = true;
      simpleValidator.current.fields['routing number'] = true;
    }
    if (transferType === 'ach') {
      simpleValidator.current.fields.Name = true;
      simpleValidator.current.fields['card number'] = true;
      simpleValidator.current.fields['cvv number'] = true;
      simpleValidator.current.fields['expiry date'] = true;
    }
    const formValid = simpleValidator.current.allValid();
    if (transferType === 'card') {
      if (!formValid || hasCardErrors || hasCvvError || hasExpiryErrors) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
    }
    if (transferType === 'ach') {
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
    }
    let paymentInfo = {};
    if (transferType === 'card') {
      const yearOfExpiry = expiry.split('/')[1];
      let expiryYear = '';
      if (yearOfExpiry?.length === 2) {
        expiryYear = `20${yearOfExpiry}`;
      } else if (yearOfExpiry?.length === 4) {
        expiryYear = yearOfExpiry;
      }
      paymentInfo = {
        type: 'CARD',
        postalCode: zipCode,
        card: {
          cvv,
          name,
          number: cardNumber.replaceAll(' ', ''),
          expiryMonth: expiry.split('/')[0],
          expiryYear,
        },
        paymentMethodBillingAddress: {
          street: stateName,
          city: cityName,
          state: stateName,
          countryCode: countryId,
        },
      };
    }
    if (transferType === 'ach') {
      paymentInfo = {
        type: 'ACH_LEGACY',
        postalCode: zipCode,
        bank: {
          accountType,
          routingNumber,
          accountNumber,
          bankName,
          name: accountHolderName,
        },
        paymentMethodBillingAddress: {
          street: stateName,
          city: cityName,
          state: stateName,
          countryCode: countryId,
        },
      };
    }
    const paymentData = {
      amount: eventAmount.replace(/[^\d]/g, ''),
      currency: currencyType?.name,
      customerConsent,
      cardholderParticipation: 'cardholderParticipation',
      storeCustomerProfile,
      customerGid: customerId,
      description,
    };
    if (defaultInterval !== 'One-time') {
      paymentData.interval = defaultInterval;
    }
    if (paymentMethodGid) {
      paymentData.paymentMethodGid = paymentMethodGid;
    } else {
      paymentData.paymentMethod = paymentInfo;
    }
    setLoading(true);
    try {
      const { data: { entity } } = await axiosAuthInstance.post(`${API_END_POINTS.VIRTUAL_TERMINAL}`, paymentData);
      if (entity?.paymentSession?.status === 'SUCCEEDED') {
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: 'Payment added successfully.',
        });
      } else if (entity?.paymentSession?.status !== 'SUCCEEDED') {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: entity?.paymentSession?.errorDescription,
        });
      }
      setCardNumber('');
      setName('');
      setExpiry('');
      setAmount(null);
      setCardType('');
      setCardLength(16);
      setHasCardErrors(false);
      setHasExpiryErrors(false);
      setCvvHasError(false);
      setName('');
      setStreetName('');
      setCityName('');
      setStateName('');
      setZipCode('');
      setCvv('');
      setAccountType('');
      setAccountHolderName('');
      setAccountNumber('');
      setRoutingNumber('');
      setBankName('');
      setConfirmAccountNumber('');
      setStoreCustomerProfile(false);
      setCustomerConsent(false);
      setCustomerName();
      setBankGid('');
      setCardGid('');
      setCustomerId('');
      setEntityData(null);
      setCard('');
      setCard2('');
      setDefaultInterval('One-time');
      setDescription('');
      simpleValidator.current.purgeFields();
      simpleValidator.current.hideMessages();
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data && e.response.data.message,
      });
      simpleValidator.current.purgeFields();
      simpleValidator.current.hideMessages();
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (searchText) => {
    getCustomersList(searchText);
  };

  const getCustomers = (customer) => {
    setCustomerName(customer[0]);
    setCustomerId(customer[1]);
    setCard('');
    setCard2('');
    setEntityData(null);
  };

  const getCustCard = async (custGid) => {
    try {
      const { data: { entity } } = await
      axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENT_VIRTUAL}?customer.gid.EQ=${custGid}&paymentType.EQ=CARD`);
      if (entity?.content.length > 0) {
        setEntityData(entity?.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getCustACH = async (custGid) => {
    try {
      const { data: { entity } } = await
      axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENT_VIRTUAL}?customer.gid.EQ=${custGid}&paymentType.EQ=ACH_LEGACY`);
      if (entity?.content.length > 0) {
        setEntityData(entity?.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onChange = (key) => {
    setDefaultPayment(key);
    setPaymentMethodGid('');
    setEntityData(null);
    if (key === '1') {
      setTransferType('card');
      getCustCard(customerId);
    }
    if (key === '2') {
      setTransferType('ach');
      getCustACH(customerId);
    }
  };

  const selectedCustomer = (value) => {
    const customer = customersList.find((item) => item.gid === value[1]);
    if (customer?.gid) {
      setCardNumber('');
      setName('');
      setExpiry('');
      setAmount(null);
      setCardType('');
      setCardLength(16);
      setHasCardErrors(false);
      setHasExpiryErrors(false);
      setCvvHasError(false);
      setName('');
      setStreetName('');
      setCityName('');
      setStateName('');
      setZipCode('');
      setCvv('');
      setAccountType('');
      setAccountHolderName('');
      setAccountNumber('');
      setRoutingNumber('');
      setBankName('');
      setConfirmAccountNumber('');
      setStoreCustomerProfile(false);
      setCustomerConsent(false);
      setBankGid('');
      setCardGid('');
      setDescription('');
      setCountryName(selectedAccount && selectedAccount.country && selectedAccount.country.name);
    }
    if (defaultPayment === '1') {
      getCustCard(customer?.gid);
    } else if (defaultPayment === '2') {
      getCustACH(customer?.gid);
    }
    setCustomerId(customer?.gid);
  };

  useEffect(() => {
    if (addedCustomer && addedNewCustomer && addedNewCustomer.gid) {
      setCustomerName(addedNewCustomer.name);
      setCustomerId(addedNewCustomer.gid);
      getCustCard(addedNewCustomer.gid);
      getCustACH(addedNewCustomer.gid);
      setAddCustomerModal(false);
    }
  }, [addedNewCustomer]);

  const addNewCustomer = (newCustomer: Object) => {
    const {
      // eslint-disable-next-line no-shadow
      name,
      email,
      phoneNumber,
      taxStatus,
      taxValue,
      taxId,
      countryCode,
    } = newCustomer;
    const params = {
      name,
      phoneNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
      email: email ? `${email}` : null,
      taxId,
      taxStatus,
      taxValue,
    };
    addCustomer({
      params,
    });
    setAddedCustomer(true);
    // setLineItemAdd(false);
  };

  const setCardDetails = (value) => {
    const selectedEntity = entityData?.find((item) => item?.gid === value);
    if (selectedEntity && transferType === 'card') {
      setCardGid(selectedEntity.gid);
      setName(selectedEntity?.card?.name);
      setCardNumber(`**** **** **** ${selectedEntity?.card?.lastFour}`);
      setExpiry(`${selectedEntity?.card?.expiryMonth}/${selectedEntity?.card?.expiryYear}`);
      setCvv('979');
      setStreetName(selectedEntity?.customer?.billingAddress?.street);
      setCityName(selectedEntity?.customer?.billingAddress?.city);
      setStateName(selectedEntity?.customer?.billingAddress?.state);
      setZipCode(selectedEntity?.customer?.billingAddress?.postalCode);
      setCountryName(selectedEntity?.customer?.billingAddress?.country?.name);
      setPaymentMethodGid(selectedEntity?.gid);
      setDescription('');
    } else if (selectedEntity && transferType === 'ach') {
      setPaymentMethodGid(selectedEntity?.gid);
      setBankGid(selectedEntity?.gid);
      setBankName(selectedEntity?.bank?.bankName);
      setAccountHolderName(selectedEntity?.bank?.name);
      setAccountNumber(`************${selectedEntity?.bank?.lastFour}`);
      setConfirmAccountNumber(`************${selectedEntity?.bank?.lastFour}`);
      setAccountType(selectedEntity?.bank?.accountType);
      setRoutingNumber(selectedEntity?.bank?.routingNumber);
      setStreetName(selectedEntity?.customer?.billingAddress?.street);
      setCityName(selectedEntity?.customer?.billingAddress?.city);
      setStateName(selectedEntity?.customer?.billingAddress?.state);
      setZipCode(selectedEntity?.customer?.billingAddress?.postalCode);
      setCountryName(selectedEntity?.customer?.billingAddress?.country?.name);
      setDescription('');
    }
  };

  const addNewCard = () => {
    setCardGid('');
    setName('');
    setCardNumber('');
    setExpiry('');
    setCvv('');
    setPaymentMethodGid('');
    setCard();
  };

  const addNewBank = () => {
    setPaymentMethodGid('');
    setBankGid('');
    setBankName('');
    setAccountHolderName('');
    setAccountNumber('');
    setConfirmAccountNumber('');
    setAccountType('');
    setRoutingNumber('');
    setCard2();
  };

  const searchCustomers = () => {
    getCustomersList();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card className="mb-2">
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Customer Details
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Customer Name</span>
          </Col>
          <Col span={6}>
            <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
              <Select
                value={customerName || 'Select Customer'}
                style={{ width: '100%' }}
                showSearch
                onSearch={onSearch}
                onFocus={() => searchCustomers()}
                onChange={(e) => getCustomers(e)}
                onSelect={e => selectedCustomer(e)}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '2px 0' }} />
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                      <span
                        style={{
                          flex: 'none',
                          padding: '8px',
                          display: 'block',
                          cursor: 'pointer',
                          color: '#279dfe',
                        }}
                        onClick={() => setAddCustomerModal(true)}
                      >
                        <Icon type="plus-circle" />&nbsp;Add New Customer
                      </span>
                    </div>
                  </div>
                )}
              >
                {getSortedList(customersList).map((item) => (
                  <Option key={item.gid} value={[item.name, item.gid]}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <SpError>
              {simpleValidator.current.message('Customer', customerName, 'required')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
      <Tabs defaultActiveKey={defaultPayment} tabPosition="top" onChange={onChange} className="tab-header">
        <TabPane tab="CARD INFORMATION" key="1">
          <Row className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase" fontSize="20px">
                Card Details
              </SpText>
            </Col>
          </Row>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Cards</span>
              </Col>
              <Col span={6}>
                <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    value={card || 'Select Card'}
                    onSearch={onSearch}
                    onChange={(e) => { setCardDetails(e); setCard(e); }}
                    placeholder="Select Card"
                  >
                    {entityData?.map((item) => (
                      <Option key={item.gid} value={item.gid}>
                        {`${item.customer?.name} | **** **** **** ${item?.card?.lastFour}`}
                      </Option>
                    ))}
                  </Select>
                </div>
                {/* <SpError>
                  {simpleValidator.current.message('Customer', card, 'required')}
                </SpError> */}
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Name on Card</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Name on card"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                  disabled={cardGid}
                />
                {
                  transferType === 'card' ? (
                    <SpError>
                      {simpleValidator.current.message('Name', name, 'required|alpha_space')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Card Information</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Enter Your Card Number"
                  onChange={(e) => validateCard(e.currentTarget.value)}
                  value={cardNumber}
                  suffix={cardType}
                  onBlur={validateCardType}
                  disabled={cardGid}
                />
                {
                  transferType === 'card' ? (
                    <>
                      {
                        hasCardErrors ? (
                          <SpError>
                            Invalid Card Number
                          </SpError>
                        ) : (
                          <SpError>
                            {simpleValidator.current.message('card number', cardNumber, 'required')}
                          </SpError>
                        )
                      }
                    </>
                  ) : (
                    <></>
                  )
                }
                <div className="d-flex mt-3">
                  <Input
                    placeholder="MM/YYYY"
                    onChange={(e) => formatCardDate(e.currentTarget.value)}
                    value={expiry}
                    autocomplete="false"
                    disabled={cardGid}
                  />
                  <Input
                    placeholder="CVV"
                    onChange={(e) => validateCvv(e.currentTarget.value)}
                    value={cvv}
                    autoComplete="new-password"
                    style={{ webkitTextSecurity: 'disc' }}
                    disabled={cardGid}
                  />
                </div>
                {
                  transferType === 'card' ? (
                    <>
                      <div>
                        {
                          hasExpiryErrors ? (
                            <SpError>
                              Invalid Expiry Date
                            </SpError>
                          ) : (
                            <SpError>
                              {simpleValidator.current.message('cvv number', cvv, `cvvValidation:${card}`)}
                            </SpError>
                          )
                        }
                      </div>
                      <div>
                        {
                          (card === '' && hasCvvError) ? (
                            <SpError>
                              Invalid CVV
                            </SpError>
                          ) : (
                            <SpError>
                              {simpleValidator.current.message('cvv number', cvv, `cvvValidation:${card}`)}
                            </SpError>

                          )
                        }
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
          </SpForm>
          {
            !cardGid ? (
              <>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Store customer profile</span>
                    </Col>
                    <Col span={6}>
                      <Checkbox
                        className="mr-2"
                        checked={storeCustomerProfile}
                        onChange={({ target: { checked } }) => {
                          setStoreCustomerProfile(checked);
                        }}
                      />
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Customer Consent</span>
                    </Col>
                    <Col span={6}>
                      <Checkbox
                        className="mr-2"
                        checked={customerConsent}
                        onChange={({ target: { checked } }) => {
                          setCustomerConsent(checked);
                        }}
                      />
                    </Col>
                    {(storeCustomerProfile && (customerConsent === false)) && (
                      <SpError>
                        Customer consent should be select
                      </SpError>
                    )}
                  </Row>
                </SpForm>
              </>
            ) : (
              <></>
            )
          }
          {
            cardGid ? (
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    &nbsp;
                  </Col>
                  <Col span={6}>
                    <SpText
                      color="#1890ff"
                      fontWeight="600"
                      style={{ cursor: 'pointer' }}
                      onClick={() => addNewCard()}
                    >
                      Add new card
                    </SpText>
                  </Col>
                </Row>
              </SpForm>
            ) : null
          }
        </TabPane>
        <TabPane tab="BANK INFORMATION" key="2">
          <Row className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase" fontSize="20px">
                BANK Details
              </SpText>
            </Col>
          </Row>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Banks</span>
              </Col>
              <Col span={6}>
                <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    value={card2 || 'Select Bank'}
                    onSearch={onSearch}
                    onChange={(e) => { setCardDetails(e); setCard2(e); }}
                    placeholder="Select Bank"
                  >
                    {entityData?.map((item) => (
                      <Option key={item.gid} value={item.gid}>
                        {`${item?.bank?.bankName} | **** **** **** ${item?.bank?.lastFour}`}
                      </Option>
                    ))}
                  </Select>
                </div>
                {/* <SpError>
                  {simpleValidator.current.message('Customer', card, 'required')}
                </SpError> */}
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Account Type</span>
              </Col>
              <Col span={6}>
                {
                  countryId === 'US' ? (
                    <Select
                      className="w-100"
                      showSearch
                      value={accountType || 'Account Type'}
                      onChange={(value) => {
                        setAccountType(value);
                      }}
                      disabled={bankGid}
                    >
                      <Option key={1} value="CHECKING">CHECKING</Option>
                      <Option key={2} value="SAVINGS">SAVINGS</Option>
                    </Select>
                  ) : (
                    <Select
                      className="w-100"
                      showSearch
                      value={accountType || 'Account Type'}
                      onChange={(value) => {
                        setAccountType(value);
                      }}
                      disabled={bankGid}
                    >
                      <Option key={1} value="CURRENT">CURRENT ACCOUNT</Option>
                      <Option key={2} value="SAVINGS">SAVINGS</Option>
                    </Select>
                  )
                }
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('account type', accountType, 'required')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Account Holder Name</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Name"
                  value={accountHolderName}
                  disabled={bankGid}
                  onChange={(e) => setAccountHolderName(e.currentTarget.value)}
                />
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('account holder name', accountHolderName, 'required|accountHolderName')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Account Number</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Account Number"
                  value={accountNumber}
                  disabled={bankGid}
                  onChange={(e) => setAccountNumber(e.currentTarget.value)}
                />
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('account number', accountNumber, 'required')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Retype Account Number</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Confirm Account Number"
                  value={confirmAccountNumber}
                  disabled={bankGid}
                  onChange={(e) => setConfirmAccountNumber(e.currentTarget.value)}
                />
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('account number', confirmAccountNumber, `required|confirmAccount:${accountNumber}`)}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            {
              countryId === 'US' ? (
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Routing Number</span>
                  </Col>
                  <Col span={6}>
                    <Input
                      placeholder="Routing Number"
                      value={routingNumber}
                      disabled={bankGid}
                      onChange={(e) => setRoutingNumber(e.currentTarget.value)}
                    />
                    {
                      transferType === 'ach' ? (
                        <SpError>
                          {simpleValidator.current.message('routing number', routingNumber, 'required|bankRoutingNumber')}
                        </SpError>
                      ) : (
                        <></>
                      )
                    }
                  </Col>
                </Row>
              ) : (
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>IFSC Code</span>
                  </Col>
                  <Col span={6}>
                    <Input
                      placeholder="IFSC Code"
                      value={routingNumber}
                      disabled={bankGid}
                      onChange={(e) => setRoutingNumber((e.currentTarget.value).toUpperCase())}
                    />
                    {
                      transferType === 'ach' ? (
                        <SpError>
                          {simpleValidator.current.message('ifsc code', routingNumber, 'required')}
                        </SpError>
                      ) : (
                        <></>
                      )
                    }
                  </Col>
                </Row>
              )
            }
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Bank Name</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Bank Name"
                  value={bankName}
                  disabled={bankGid}
                  onChange={(e) => setBankName(e.currentTarget.value)}
                />
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('bank name', bankName, 'required|bankName|stringLength')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
          </SpForm>
          {
            !bankGid ? (
              <>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Store customer profile</span>
                    </Col>
                    <Col span={6}>
                      <Checkbox
                        className="mr-2"
                        checked={storeCustomerProfile}
                        onChange={({ target: { checked } }) => {
                          setStoreCustomerProfile(checked);
                        }}
                      />
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Customer Consent</span>
                    </Col>
                    <Col span={6}>
                      <Checkbox
                        className="mr-2"
                        checked={customerConsent}
                        onChange={({ target: { checked } }) => {
                          setCustomerConsent(checked);
                        }}
                      />
                    </Col>
                    {(storeCustomerProfile && (customerConsent === false)) && (
                      <SpError>
                        Customer consent should be select
                      </SpError>
                    )}
                  </Row>
                </SpForm>
              </>
            ) : (
              <></>
            )
          }
          {
            bankGid ? (
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    &nbsp;
                  </Col>
                  <Col span={6}>
                    <SpText
                      color="#1890ff"
                      fontWeight="600"
                      style={{ cursor: 'pointer' }}
                      onClick={() => addNewBank()}
                    >
                      Add new bank
                    </SpText>
                  </Col>
                </Row>
              </SpForm>
            ) : null
          }
        </TabPane>
      </Tabs>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Billing Address
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Street</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Street Address"
              value={streetName}
              onChange={(e) => setStreetName(e.currentTarget.value)}
            />
            {/* <SpError>
              {simpleValidator.current.message('street', streetName, 'required')}
            </SpError> */}
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>City</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="City"
              value={cityName}
              onChange={(e) => setCityName(e.currentTarget.value)}
            />
            {/* <SpError>
              {simpleValidator.current.message('city', cityName, 'required')}
            </SpError> */}
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>State</span>
          </Col>
          <Col span={6}>
            <Select
              className="w-100"
              placeholder="State"
              showSearch
              optionFilterProp="children"
              value={stateName || 'State'}
              onChange={(e) => setStateName(e)}
            >
              {
                statesInCountry.map(s => (
                  <Option
                    key={s.id}
                    value={s.id}
                    className="text-capitalize"
                  >
                    {s.name}
                  </Option>
                ))
              }
            </Select>
            {/* <SpError>
              {simpleValidator.current.message('state', stateName, 'required')}
            </SpError> */}
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Country</span>
          </Col>
          <Col span={6}>
            <Select
              defaultValue={countryName}
              style={{ width: '100%' }}
              disabled
            >
              {COUNTRY_CODES.map((item) => (
                <Option key={item.ID} value={item.ALPHA2}>
                  {item.NAME}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Postal Code</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Postal Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.currentTarget.value)}
            />
            {/* <SpError>
              {simpleValidator.current.message('postal code', zipCode, 'required|postalcode')}
            </SpError> */}
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Transaction Details
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Amount</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              value={eventAmount}
              prefix={prefix}
              onChange={(e) => setAmount(e.currentTarget.value)}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const value = formatAmount(e.currentTarget.value, currencyType);
                  setAmount(value);
                }
              }}
            />
            <p className="text-muted" style={{ fontSize: '10px', textAlign: 'end' }}>
              Minimum amount {prefix} 10.00
            </p>
            <SpError>
              {simpleValidator.current.message('amount', eventAmount, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Payment Interval</span>
          </Col>
          <Col span={6}>
            <Select
              className="w-100"
              placeholder="State"
              value={defaultInterval}
              onChange={(e) => setDefaultInterval(e)}
            >
              <Option key={1} value="One-time">One-time</Option>
              <Option key={2} value="WEEK">Weekly</Option>
              <Option key={3} value="MONTH">Monthly</Option>
              <Option key={4} value="YEAR">Yearly</Option>
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Description</span>
          </Col>
          <Col span={6}>
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <SpError>
              {simpleValidator.current.message('description', description, 'description')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={(e) => addSupportSettings(e)}
          >
            Process
          </SpButton>
        </Col>
      </Row>
      {/* ADD CUSTOMER MODAL */}
      {addCustomerModal && (
        <AddNewCustomer
          visible={addCustomerModal}
          submitting={submitting}
          close={() => setAddCustomerModal(false)}
          submit={addNewCustomer}
          selectedAccount={selectedAccount}
        />
      )}
    </Card>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  validator: state.loading.validator,
  customersList: state.invoiceDetails.customersList,
  submitting: state.loading.submitting,
  addedNewCustomer: state.invoiceDetails.newCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  getCustomersList: param => dispatch(invoiceDetailsAction.getCustomersList({
    payload: param,
  })),
  addCustomer: param => dispatch(invoiceDetailsAction.addCustomer({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Virtualterminal);
