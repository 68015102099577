// @flow
import React, { useEffect, useState } from 'react';
import {
  Card,
  Table,
  // Row,
  // Col,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import toSentenceCase from 'utils/sentencecase';
import queryString from 'query-string';

import {
  // SpText,
  CardSchemeIcon,
} from 'components/DesignKit';
import {
  customerDetailsAction,
} from 'store/actions';
import { useLocation } from 'react-router-dom';
import {
  PAGES,
} from 'appconstants';

type Props = {
  fetchCardDetails: Function,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  cardsDetails: Array<Object>,
  totalElements: number,
  selectedAccount: Object,
}

const CustomerRegisteredCardList = (props: Props) => {
  const {
    fetchCardDetails,
    cardsDetails,
    totalElements,
    history,
    match,
    selectedAccount,
  } = props;

  const customerGid = match.params.gid;

  const [pagination, setPagination] = useState({
    total: totalElements,
    current: 1,
    show: total => `There are ${total} registered card(s)`,
  });
  const location = useLocation();

  // const getCardData = (currentPage) => {
  //   const sortBy = 'createdAt';
  //   const direction = 'DESC';
  //   const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
  //   fetchCardDetails({ customerGid, currentPage, sortParams });
  // };

  const getCardData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchCardDetails({ gid: customerGid, currentPage, sortParams });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getCardData(location.search);
  }, []);

  useEffect(() => {
    getCardData(location.search);
  }, [selectedAccount]);

  useEffect(() => {
    getCardData(location.search);
  }, [location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [cardsDetails]);

  const handleTableChange = (tableChange, sorter, filters) => {
    const currentPage = tableChange.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.CUSTOMERS}/${customerGid}`,
      search: `?pageNum=${currentPage}${tempParams}`,
    });
  };

  const columns = [
    {
      title: 'NAME ON CARD',
      dataIndex: 'name',
      width: '19%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {name || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'CARD NUMBER',
      dataIndex: 'cards',
      width: '19%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        const bank = record.bankName ? record.bankName : '';
        const number = record.lastFour;
        const cards = `${bank}****${number}`;
        return (
          <span>{cards || <>&#8211;</>}</span>
        );
      },
    },
    {
      title: 'EXPIRY',
      dataIndex: 'expiry',
      width: '12%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        const month = record.expiryMonth;
        const year = record.expiryYear;
        if (month) {
          if (month < 10) {
            return (
              <span>{`0${month}/${year}`}</span>
            );
          }
          return (
            <span>{`${month}/${year}`}</span>
          );
        } return <span>&#8211;</span>;
      },
    },
    {
      title: 'CARD TYPE',
      dataIndex: 'scheme',
      width: '19%',
      align: 'left',
      sorter: false,
      render: (text, record) => <span> <CardSchemeIcon scheme={record.scheme} />&nbsp;&nbsp;{toSentenceCase(record.type)}</span>,
    },
    {
      title: 'COUNTRY',
      dataIndex: 'country.name',
      width: '11%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        const countryName = record.country.name;
        if (countryName === 'United States of America') {
          return <span> US</span>;
        } if (countryName === 'India') {
          return <span> IN</span>;
        }
        return null;
      },
    },
    {
      title: 'ID',
      dataIndex: 'gid',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (cardId) => {
        const cardGid = cardId.slice(0, 16);
        return (
          <Tooltip placement="top" title={cardId}>
            <span>{cardGid}</span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Card className="pl-2">
      <Table
        columns={columns}
        rowKey={(record) => record.gid}
        dataSource={cardsDetails}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  cardsDetails: state.customerDetails.cardDetails.content,
  totalElements: state.customerDetails.cardDetails ? state.customerDetails.cardDetails.totalElements : '',
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCardDetails: param => dispatch(customerDetailsAction.fetchCardDetails({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomerRegisteredCardList);
