/* eslint-disable camelcase */
// @flow
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Select, Input, Upload, Modal, Switch,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import {
  customerSupportActions,
  inventoryShopsDetailsActions,
  notificationActions,
} from 'store/actions';
import debounce from 'lodash.debounce';
import { EyeOutlined, UploadOutlined } from '@ant-design/icons';
import Loading from 'components/Loading';
import { NOTIFICATION } from 'store/actionTypes';

type Props = {
    fetchShops: Function,
    selectedAccount: Object,
    test: Boolean,
    shops: Array<objects>,
    setNotification: Function,
    fetchShopsSlug: Function,
    paymentSlug: Array,
    visible: Boolean,
    close: Function,
    getDataDeals: Function,
    currentPage:Number,
    pageSize:Number,
   selectedDeal:Object,
   strapiToken: String,
};

const UpdateDeal = (props: Props) => {
  const {
    fetchShops,
    // loading,
    selectedAccount,
    test,
    shops,
    setNotification,
    fetchShopsSlug,
    paymentSlug,
    close,
    visible,
    getDataDeals,
    currentPage,
    pageSize,
    selectedDeal,
    strapiToken,
  } = props;
  const location = useLocation();
  const selectedShopAddress = shops.filter(
    (shop) => shop.gid === selectedDeal?.attributes?.business_id?.data?.attributes?.shopGid,
  );
  const [, forceUpdate] = useState();
  const [shopsList, setShopsList] = useState([]);
  const [selectedShopName, setSelectedShopName] = useState(selectedDeal?.attributes?.business_id?.data?.attributes?.name || '');
  const [dealName, setDealName] = useState(selectedDeal?.attributes?.name || '');
  const [shopAddress, setShopAddress] = useState(selectedShopAddress[0]?.address || '');
  const [disableButton, setDisableButton] = useState(false);
  const [business, setBusiness] = useState({});
  const [error, setError] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(selectedDeal?.attributes?.isEnabled || false);
  const [shopGid, setShopGid] = useState(selectedShopAddress[0]?.gid || null);
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_STRAPI_ENDPOINT;
  // eslint-disable-next-line max-len
  const url = uploadResponse ? `${apiUrl}${uploadResponse[0]?.url}` : selectedDeal?.attributes?.image;

  const simpleValidator = useRef(new SimpleReactValidator());
  simpleValidator.current.purgeFields();
  const debouncedFetchShops = debounce(fetchShops, 2000, { leading: true });
  const getDataShops = (query) => {
    const parsed = queryString.parse(query);
    // eslint-disable-next-line no-shadow
    const currentPage = parsed.page || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = '';
    debouncedFetchShops({ currentPage, sortParams, filterParams });
  };
  const beforeUpload = (uploadedFile) => {
    // Define allowed MIME types for images
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!allowedTypes.includes(uploadedFile.type)) {
      setError(true);
      return false;
    }
    return true; // Allow the upload if the file type is valid
  };

  const getDataBusiness = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/businesses`, {
        headers: {
          Authorization: `Bearer ${strapiToken}`,
        },
      });
      // Handle successful response
      console.log('Data:', response.data);
      setBusiness(response?.data);
    } catch (e) {
      // Handle errors
      console.error('Error fetching data:', e);
    }
  };

  useEffect(() => {
    getDataShops(location.search);
    getDataBusiness();
  }, [selectedAccount, test]);
  useEffect(() => {
    if (shops && shops.length !== 0) {
      setShopsList(shops);
    }
  }, [shops]);


  const divStyle = {
    padding: '4px 12px',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    fontSize: '14px',
    width: '100%',
    boxSizing: 'border-box',
  };
  const maindivStyle = {
    padding: '20px',
    borderRadius: '4px',
    fontSize: '14px',
    width: '100%',
    boxSizing: 'border-box',
  };
  const iconStyle = {
    marginLeft: 'auto',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  };
  const inputStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    padding: '13px 11px',
    backgroundColor: '#DCE0E4',
    // height: inputHeight(42, isPreview, isAdaptive),
    width: '100%',
    cursor: 'pointer',
    // fontSize: smallTextFontSize(isPreview, isAdaptive),
  };

  const uploadImageToStrapi = async (file) => {
    try {
      const formData = new FormData();
      formData.append('files', file);
      const response = await axios.post(`${apiUrl}/api/upload`, formData, {
        headers: {
          Authorization: `Bearer ${strapiToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setUploadResponse(response.data);
      return response.data;
    } catch (e) {
      console.error('Error uploading file:', e);
      throw e;
    }
  };
  const handleImageUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setImageUploadLoading(true);
      return;
    }

    if (info.file.status === 'done' || info.file.status === 'error') {
      try {
        const file = info.file.originFileObj;
        if (file) {
          await uploadImageToStrapi(file);
        }
      } catch (e) {
        console.error('File upload error:', e);
      } finally {
        setImageUploadLoading(false);
      }
    }
  };

  const validate = async (event) => {
    setDisableButton(true);
    event.preventDefault();
    // Check for form validity
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();

      forceUpdate(1); // Uncomment if you use forceUpdate method correctly
      return;
    }

    // Retrieve the existing shop
    const existingShop = business?.data?.some(
      (shop) => shop?.attributes?.shopGid === shopGid,
    );
    try {
      const paramsBusiness = {
        data: {
          name: selectedShopName,
          street: shopAddress.street,
          city: shopAddress.city,
          state: shopAddress.state,
          country: shopAddress.country?.name,
          zipCode: parseFloat(shopAddress?.postalCode),
          supportPOS: true,
          supportOnline: true,
          website_url: paymentSlug?.[0]?.accountSlug ?? 'NA',
          shopGid,
        },
      };
      // let businessId;
      if (!existingShop) {
        // Create business
        setLoading(true);
        const responseBusiness = await axios.post(
          `${apiUrl}/api/businesses`,
          paramsBusiness,
          {
            headers: {
              Authorization: `Bearer ${strapiToken}`,
              'Content-Type': 'application/json',
            },
          },
        );
        const businessId = responseBusiness.data?.data?.id ?? 'NA';
        const paramsNewDeal = {
          data: {
            name: dealName,
            street: shopAddress.street,
            city: shopAddress.city,
            state: shopAddress.state,
            country: shopAddress.country?.name,
            zipCode: parseFloat(shopAddress?.postalCode),
            image: url,
            business_id: businessId,
            deal_url: paymentSlug?.[0]?.accountSlug ?? 'NA',
            accountGid: selectedAccount && selectedAccount?.gid,
            isEnabled,
          },
        };
        // Create deal
        await axios.put(`${apiUrl}/api/app-deals/${selectedDeal.id}`, paramsNewDeal, {
          headers: {
            Authorization: `Bearer ${strapiToken}`,
            'Content-Type': 'application/json',
          },
        });
      } else {
        setLoading(true);
        const existingShopBusinessId = business?.data?.filter(
          (shop) => shop?.attributes?.shopGid === shopGid,
        )[0]?.id;
        const paramsDeal = {
          data: {
            name: dealName,
            street: shopAddress.street,
            city: shopAddress.city,
            state: shopAddress.state,
            country: shopAddress.country?.name,
            zipCode: parseFloat(shopAddress?.postalCode),
            image: url,
            business_id: existingShopBusinessId,
            deal_url: paymentSlug?.[0]?.accountSlug ?? null,
            accountGid: selectedAccount && selectedAccount?.gid,
            isEnabled,
          },
        };
        // Create deal
        await axios.put(`${apiUrl}/api/app-deals/${selectedDeal.id}`, paramsDeal, {
          headers: {
            Authorization: `Bearer ${strapiToken}`,
            'Content-Type': 'application/json',
          },
        });
      }

      // Show success notification
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: 'Deal Added Successfully',
      });

      // Fetch updated deals and handle UI updates
      // handleRemoveClick(id);
    } catch (e) {
      console.error('Error posting data:', e);
      setLoading(false);
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Something Went Wrong',
      });
    } finally {
      getDataBusiness();
      getDataDeals(currentPage, pageSize);
      setShopAddress('');
      setLoading(false);
      forceUpdate(1);
      setDisableButton(false);
      close();
    }
  };

  const updateSelectedShop = (shop) => {
    setShopAddress(shop[1].address);
    setSelectedShopName(shop[1].name);
    setShopGid(shop[1].gid);
    fetchShopsSlug(shop[1].gid);
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Modal
      centered
      visible={visible}
      title="Edit Deal"
      closable={false}
      footer={[
        <SpButton onClick={() => close()} type="secondary">
          Cancel
        </SpButton>,
        <SpButton onClick={validate} disableButton={disableButton}>update</SpButton>,
      ]}
    >
      <div style={maindivStyle}>
        <SpH5>Deal Name</SpH5>
        <Input
          className="mt-2 mb-2"
          style={{ width: '100%' }}
          placeholder="Enter Deal Name"
          onChange={(e) => setDealName(e.target.value)}
          value={dealName}
        />
        <SpError>
          {simpleValidator.current.message('dealName', dealName, 'required')}
        </SpError>
        <SpH5 className="mt-2">Restaurant Name</SpH5>
        <Select
          className="mt-2 mb-2"
          style={{ width: '100%' }}
          value={selectedShopName || 'Select Shop'}
          bordered
          onChange={(value) => updateSelectedShop(value)}
        >
          {shopsList.map((option) => (
            <Select.Option key={option.gid} value={[option.gid, option]}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
        <SpError>
          {simpleValidator.current.message(
            'selectedShopName',
            selectedShopName,
            'required',
          )}
        </SpError>

        <SpH5 className="mt-2">Location</SpH5>
        <div style={divStyle} className="mt-2 mb-2">
          {shopAddress ? (
            <>
              {shopAddress.street} {shopAddress.city}{' '}
              {shopAddress.country?.alpha2} {shopAddress.postalCode}{' '}
              {shopAddress.country?.name}
            </>
          ) : (
            'Location'
          )}
        </div>
        <div className="mt-2">
          <div className="d-flex" style={{ justifyContent: 'space-between' }}>
            <Upload
              style={iconStyle}
              beforeUpload={beforeUpload}
              showUploadList={false}
              onChange={(info) => handleImageUpload(info)}
              className="mt-2"
            >
              <div style={inputStyle}>
                <div style={{ flexGrow: 1 }}>Upload</div>
                <UploadOutlined className="ml-3" />
              </div>
            </Upload>
            <div className="mt-4">
              <SpH5>Enable</SpH5>
              <Switch
                className="ml-2"
                checked={isEnabled}
                onChange={() => setIsEnabled(!isEnabled)}
              />
            </div>
          </div>
          <SpError>
            {simpleValidator.current.message('image', url, 'required')}
          </SpError>
          {error && (
          <p className="mt-2" style={{ color: 'red', fontSize: '12px' }}>
            Only Images Allowed
          </p>
          )}
          {imageUploadLoading && (
          <span className="spinner-border text-muted mt-2 ml-3"> </span>
          )}
          <Row type="flex" justify="between" align="middle" gutter={10}>
            <Col span={12}>
              <>
                <SpH5 className="mt-2">Image Url:</SpH5>
                <span>{url && url}</span>
              </>
            </Col>
            <Col span={12}>
              <EyeOutlined
                style={{ fontSize: '24px', color: '#08c', cursor: 'pointer' }}
                onClick={() => {
                  if (url) {
                    window.open(url, '_blank', 'noopener,noreferrer');
                  } else {
                    console.error('No URL available');
                  }
                }}
              />
            </Col>
          </Row>
        </div>

      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  shops: state.customerSupport.shops,
});


const mapDispatchToProps = (dispatch) => ({
  fetchShops: param => dispatch(customerSupportActions.fetchShops({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchShopsSlug: param => dispatch(inventoryShopsDetailsActions.fetchShopsSlug({
    payload: param,
  })),

});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps, null)(UpdateDeal);
