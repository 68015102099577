import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SHOPS_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchInventoryShopsDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_SHOPS}/${gid}`);

const fetchItemByShops = async gid => axiosAuthInstance.get(`/inventory/item/shop/${gid}`);

const fetchItemNameByShops = async (payload) => {
  if (payload.name) {
    return axiosAuthInstance.get(`/inventory/item/shop/${payload.gid}?name.LIKE=${payload.name}`);
  }
  return axiosAuthInstance.get(`/inventory/item/shop/${payload.gid}`);
};

const fetchPromosByShop = async (payload) => {
  const apiURL = `/shop/${payload.gid}/shop-promo`;
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${apiURL}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${apiURL}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${apiURL}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${apiURL}${query}${sorting}`);
};

const fetchAllPromosByShop = async (gid) => {
  const apiURL = `/shop/${gid}/shop-promo`;
  const queryParam = '?page=1&size=1000';
  const sorting = '&sortBy=createdAt&direction=ASC';
  return axiosAuthInstance.get(`${apiURL}${queryParam}${sorting}`);
};

const postPromoShops = async ({ gid, params }) => axiosAuthInstance.post(`/shop/${gid}/shop-promo`, params);

const removePromoShops = async ({ gid, promoGid }) => axiosAuthInstance.delete(`/shop/${gid}/shop-promo/${promoGid}`);

const patchPromoShops = async ({ gid, promoGid, params }) => axiosAuthInstance.patch(`/shop/${gid}/shop-promo/${promoGid}`, params);

const patchItemShops = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVENTORY_SHOPS}/${gid}/items`, params);

const fetchShopsSlug = async gid => axiosAuthInstance.get(`${API_END_POINTS.SHOP_SLUG_API}/${gid}`);

const addTipsShops = async ({ params }) => axiosAuthInstance.post('shop-tip', params);

const fetchTablesShops = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_SHOPS}/${gid}/activeReservationsAndUnpaidOrder`);

// const removeTipsDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_TIPS}/${gid}`);
const removeTipsDetails = async (gid) => axiosAuthInstance.delete(`shop-tip/${gid}`);

export function* getInventoryShopsDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchInventoryShopsDetails, action.payload);
      yield put({ type: SHOPS_DETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchInventoryShopsDetails, action);
      yield put({ type: SHOPS_DETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: SHOPS_DETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getInventoryItemByShops(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(fetchItemByShops, action.payload);
      yield put({ type: SHOPS_DETAILS.SHOPS_ITEM_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchItemByShops, action);
      yield put({ type: SHOPS_DETAILS.SHOPS_ITEM_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: SHOPS_DETAILS.SHOPS_ITEM_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addItemShops(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchItemShops, action.payload);
    const { data: { entity } } = yield call(fetchItemByShops, action.payload && action.payload.gid);
    yield put({ type: SHOPS_DETAILS.SHOPS_ITEM_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Items Updated successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}


/* export function* getTipsByShops(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(fetchTipsByShops, action.payload);
      yield put({ type: SHOPS_DETAILS.SHOPS_TIPS_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchTipsByShops, action);
      yield put({ type: SHOPS_DETAILS.SHOPS_TIPS_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: SHOPS_DETAILS.SHOPS_ITEM_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
} */


export function* getShopsSlug(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchShopsSlug, action.payload);
    yield put({ type: SHOPS_DETAILS.SHOPS_SLUG_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SHOPS_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getInventoryPromosByShop(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchPromosByShop, action.payload);
      yield put({ type: SHOPS_DETAILS.SHOPS_PROMO_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchPromosByShop, action);
      yield put({ type: SHOPS_DETAILS.SHOPS_PROMO_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: SHOPS_DETAILS.SHOPS_PROMO_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addPromosByShop(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postPromoShops, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Promo added successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deletePromosByShop(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(removePromoShops, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Promo deleted successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteTipsByShop(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(removeTipsDetails, action.payload.tipGid);
    const { data: { entity } } = yield call(fetchInventoryShopsDetails, action.payload.shopGid);
    yield put({ type: SHOPS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Tips deleted successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updatePromosByShop(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPromoShops, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Promo updated successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addTips(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(addTipsShops, action.payload);
    const { data: { entity } } = yield call(fetchInventoryShopsDetails, action.payload.params.shopGid);
    yield put({ type: SHOPS_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Tip added successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}


export function* getInventoryAllPromosByShop(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchAllPromosByShop, action.payload);
      yield put({ type: SHOPS_DETAILS.SHOPS_ALLPROMO_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchAllPromosByShop, action);
      yield put({ type: SHOPS_DETAILS.SHOPS_ALLPROMO_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: SHOPS_DETAILS.SHOPS_ALLPROMO_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getInventoryItemNamesByShops(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(fetchItemNameByShops, action.payload);
      yield put({ type: SHOPS_DETAILS.SHOPS_ITEM_SEARCH_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchItemNameByShops, action);
      yield put({ type: SHOPS_DETAILS.SHOPS_ITEM_SEARCH_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: SHOPS_DETAILS.SHOPS_ITEM_SEARCH_FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getTablesShops(action) {
  try {
    const { data: { entity } } = yield call(fetchTablesShops, action.payload);
    yield put({ type: SHOPS_DETAILS.SUCCESS_SHOP_TABLES, payload: entity });
  } catch (e) {
    yield put({ type: SHOPS_DETAILS.FAILED_SHOP_TABLES, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
