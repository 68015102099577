// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  Card,
  // Table,
  Row,
  Col,
  // Tooltip,
  Menu,
} from 'antd';
// import queryString from 'query-string';
import {
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  // CardSchemeIcon,
  SpTab,
} from 'components/DesignKit';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import Loading from 'components/Loading';
import {
  customerDetailsAction,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  API_STATUS,
} from 'appconstants';
import RBAC from 'components/rbac';
import getStateList from 'utils/stateList';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
// import toSentenceCase from 'utils/sentencecase';
import UpdateCustomer from './components/updateCustomer';
import DeleteSelectedCustomer from './components/deleteSelectedCustomer';
import CustomerRegisteredCardList from './components/customerRegisteredCard';
import CustomerPayments from './components/customerPayments';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

type Props = {
  fetchCustomerDetails: Function,
  // fetchCardDetails: Function,
  updateCustomers: Function,
  setNotification: Function,
  customerDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  // cardsDetails: Array<Object>,
  // totalElements: number,
  customerDetailsStatus: string,
  isMobileView: Boolean,
}

const CustomerDetails = (props: Props) => {
  const {
    fetchCustomerDetails,
    setNotification,
    updateCustomers,
    customerDetails,
    loading,
    submitting,
    validator,
    history,
    selectedAccount,
    customerDetailsStatus,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const { gid } = props.match.params;
  const [currentTab, setCurrentTab] = useState('registered-cards');
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [deleteCustomerModal, setDeleteCustomerModal] = useState(false);
  const [phoneFormate, setPhoneFormate] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const gidPath = gid.split('-')[1];
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [zipTag, setZipTag] = useState('');

  const getData = () => {
    fetchCustomerDetails(gid);
  };

  useEffect(() => {
    if (customerDetails.gid) {
      const phone = customerDetails.phoneNumber ? customerDetails.phoneNumber : '';
      const cntryCode = customerDetails.phoneNumber ? customerDetails.phoneNumber.substring(0, 2) : '';
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (phone.length !== 14 && cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneFormate('+#-##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 14) {
        setPhoneFormate('+###-##########');
      }
    }
  }, [customerDetails]);

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.CUSTOMERS);
    }
  }, []);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData();
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
      setstatesInCountry(getStateList(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/customers')) {
      setCurrentTab('registered-cards');
    } else if (location.pathname.startsWith('/customer-payments')) {
      setCurrentTab('payments');
    } else if (location.pathname.startsWith('/customer-banks')) {
      setCurrentTab('banks');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!validator) {
      setEditCustomerModal(false);
    }
  }, [validator]);

  const showEditCustomerModal = () => {
    setEditCustomerModal(true);
  };

  const showDeleteCustomerModal = () => {
    setDeleteCustomerModal(true);
  };

  const editSelectedCustomer = (customer: Object) => {
    const {
      customerName,
      customerEmail,
      customerPhone,
      customerTaxId,
      customerTaxValue,
      customerTaxStatus,
      customerGid,
      countryCode,
      billingAddress,
      shippingAddress,
      removeBillingAddress,
      removeShippingAddress,
    } = customer;

    const params = {
      name: customerName,
      phoneNumber: customerPhone ? `${countryCode}${customerPhone}` : null,
      email: customerEmail,
      taxId: customerTaxId,
      taxStatus: customerTaxStatus,
      taxValue: customerTaxValue,
      billingAddress,
      shippingAddress: shippingAddress || null,
      removeBillingAddress,
      removeShippingAddress,
    };
    updateCustomers({
      gid: customerGid,
      params,
    });
  };

  const deleteSelectedCustomers = async (customerGid: string) => {
    try {
      await axiosAuthInstance.delete(`${API_END_POINTS.GET_CUSTOMERS}/${customerGid}`);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.CUSTOMERS.DELETE_SUCCESS,
      });
      history.push(PAGES.CUSTOMERS);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data && e.response.data.message,
      });
    } finally {
      setDeleteCustomerModal(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        customerDetails.gid && customerDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="text-uppercase mr-4" fontSize="20px">{customerDetails.name}</SpText>
                </Col>
                <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                  <Col>
                    <SpButton
                      type="danger"
                      shape="round"
                      onClick={showDeleteCustomerModal}
                    >
                      Delete Customer
                    </SpButton>
                  </Col>
                </RBAC>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Email</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>{customerDetails.email ? customerDetails.email : <>&#8211;</>}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Phone</span>
                  </Col>
                  <Col span={6}>
                    {
                      customerDetails.phoneNumber ? (
                        <NumberFormat
                          className="numFormate"
                          value={customerDetails.phoneNumber}
                          format={phoneFormate}
                          mask="_"
                          disabled
                        />
                      ) : <>&#8211;</>
                    }
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Tax Status</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>{customerDetails.taxStatus}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              {
                customerDetails.tax && customerDetails.tax.type !== 'NONE'
                  ? (
                    <>
                      <SpForm>
                        <Row type="flex" justify="start" align="middle">
                          <Col span={4}>
                            <span>Tax Type</span>
                          </Col>
                          <Col span={6}>
                            <SpH5>{customerDetails.tax.type}</SpH5>
                          </Col>
                        </Row>
                      </SpForm>
                      <SpForm>
                        <Row type="flex" justify="start" align="middle">
                          <Col span={4}>
                            <span>Tax Id</span>
                          </Col>
                          <Col span={6}>
                            <SpH5>{customerDetails.taxValue}</SpH5>
                          </Col>
                        </Row>
                      </SpForm>
                    </>
                  )
                  : (
                    <>
                      <SpForm>
                        <Row type="flex" justify="start" align="middle">
                          <Col span={4}>
                            <span>Tax Type</span>
                          </Col>
                          <Col span={6}>
                            <SpH5><>&#8211;</></SpH5>
                          </Col>
                        </Row>
                      </SpForm>
                      <SpForm>
                        <Row type="flex" justify="start" align="middle">
                          <Col span={4}>
                            <span>Tax Id</span>
                          </Col>
                          <Col span={6}>
                            <>&#8211;</>
                          </Col>
                        </Row>
                      </SpForm>
                    </>
                  )
              }
              <SpForm>
                <Row>
                  <Col span={4}>
                    <span>Billing Address</span>
                  </Col>
                  <Col>
                    {
                      customerDetails.billingAddress && customerDetails.billingAddress.street ? (
                        <SpH5>
                          {`${customerDetails.billingAddress.street}, ${customerDetails.billingAddress.city}, 
                      ${customerDetails.billingAddress.state} ${customerDetails.billingAddress.postalCode}`}
                        </SpH5>
                      ) : (
                        <SpH5>
                          N/A
                        </SpH5>
                      )
                    }
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row>
                  <Col span={4}>
                    <span>Shipping Address</span>
                  </Col>
                  <Col>
                    {
                      customerDetails.shippingAddress && customerDetails.shippingAddress.street ? (
                        <SpH5>
                          {`${customerDetails.shippingAddress.street}, ${customerDetails.shippingAddress.city}, 
                      ${customerDetails.shippingAddress.state} ${customerDetails.shippingAddress.postalCode}`}
                        </SpH5>
                      ) : (
                        <SpH5>
                          N/A
                        </SpH5>
                      )
                    }
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row>
                  <Col>
                    <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                      <SpButton
                        className="mr-2"
                        type="secondary"
                        shape="round"
                        onClick={showEditCustomerModal}
                      >
                        Update Details
                      </SpButton>
                    </RBAC>
                  </Col>
                </Row>
              </SpForm>
              {/* EDIT LOCATION MODAL */}
              {editCustomerModal && (
                <UpdateCustomer
                  visible={editCustomerModal}
                  submitting={submitting}
                  customerDetails={customerDetails}
                  phoneCode={phoneCode}
                  selectedAccount={selectedAccount}
                  zipTag={zipTag}
                  statesInCountry={statesInCountry}
                  close={() => setEditCustomerModal(false)}
                  submit={editSelectedCustomer}
                />
              )}
              {/* DELETE LOCATION MODAL */}
              {deleteCustomerModal && (
                <DeleteSelectedCustomer
                  visible={deleteCustomerModal}
                  customerDetails={customerDetails}
                  submitting={submitting}
                  close={() => setDeleteCustomerModal(false)}
                  submit={deleteSelectedCustomers}
                />
              )}
            </Card>
            <div>
              {
                (String(location.pathname).startsWith('/customer')) && (
                  <Menu onClick={e => setCurrentTab(e.key)} selectedKeys={[currentTab]} mode="horizontal" style={{ fontSize: '20px' }}>
                    <Menu.Item key="registered-cards">
                      <SpTab
                        to={`/customers/${gid}`}
                        selected={currentTab === 'registered-cards'}
                      >
                        REGISTERED CARDS
                      </SpTab>
                    </Menu.Item>
                    {/* <Menu.Item key="banks">
                      <SpTab
                        to={`/customer-banks/${gid}`}
                        selected={currentTab === 'banks'}
                      >
                        BANKS
                      </SpTab>
                    </Menu.Item> */}
                    <Menu.Item key="payments">
                      <SpTab
                        to={`/customer-payments/${gid}`}
                        selected={currentTab === 'payments'}
                      >
                        PAYMENTS
                      </SpTab>
                    </Menu.Item>
                  </Menu>
                )
              }
              <Switch>
                <Route exact path="/customers/:gid" component={CustomerRegisteredCardList} />
                {/* CustomerPaymentList */}
                <Route exact path="/customer-payments/:gid" component={CustomerPayments} />
                {/* CustomerBankList */}
                {/* <Route exact path="/customer-banks/:gid" component={CustomerRegisteredCardList} /> */}
              </Switch>
            </div>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  customerDetails: state.customerDetails,
  customerDetailsStatus: state.customerDetails.customerDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerDetails: gid => dispatch(customerDetailsAction.fetchCustomerDetails({
    payload: gid,
  })),
  updateCustomers: param => dispatch(customerDetailsAction.updateCustomers({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
