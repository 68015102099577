import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import {
  Card,
  Table,
  Tooltip,
  Row,
  Col,
  Badge,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import debounce from 'lodash.debounce';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import formatNumber from 'utils/formatNumber';
import getFilterOperators from 'utils/getFilterOperators';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';

import {
  transfersActions,
  transferSettingsActions,
  fundingSourceActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import getTransferBackground from 'utils/getTransferBackground';
import getTransferStatus from 'utils/getTransferStatus';
import {
  PAGES,
  FILTERS_TYPES,
  CURRENCY,
  COUNTRIES,
} from 'appconstants';
import getStateList from 'utils/stateList';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import Verified from 'assets/imgs/Verified.svg';
import VerifiedUser from 'assets/imgs/Verified_User.svg';
import AddTransfer from './components/AddTransfer';
import ConfirmOtp from './components/ConfirmOtp';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TRANSFERS_FILTERS_TYPES = FILTERS_TYPES.TRANSFERS;

type Props = {
  fetchTransfers: Function,
  fetchTransferSetting: Function,
  getContactList: Function,
  issueTransfer: Function,
  confirmTransferOtp: Function,
  transfers: Array<Object>,
  transferSettings: Object,
  contactList: Array,
  loading: boolean,
  test: boolean,
  issuerGid: Object,
  history: {
    push: Function,
  };
  totalElements: number,
  selectedAccount: Object,
  submitting: boolean,
  validator: boolean,
  setFilterData: Function,
  preserveFilters: Object,
};

const AllTransfers = (props: Props) => {
  const location = useLocation();
  const {
    loading,
    fetchTransfers,
    fetchTransferSetting,
    transferSettings,
    getContactList,
    issueTransfer,
    contactList,
    transfers,
    test,
    history,
    totalElements,
    selectedAccount,
    submitting,
    validator,
    confirmTransferOtp,
    issuerGid,
    setFilterData,
    preserveFilters,
  } = props;
  const [pagination, setPagination] = useState({});
  const myRef = useRef();
  const { pathname } = location;
  const parsedTransferType = queryString.parse(location.search);
  const transferListType = parsedTransferType['status.EQ'];
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(TRANSFERS_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterTransfers, setFilterTransfers] = useState((preserveFilters && preserveFilters.filterTransfers)
    ? (preserveFilters && preserveFilters.filterTransfers)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const { id } = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [addTransferModal, setAddTransferModal] = useState(false);
  const [transferOtpModal, setTransferOtpModal] = useState(false);
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [otpRequires, setOtpRequires] = useState('');
  const [zipTag, setZipTag] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [lastFour, setLastFour] = useState('');
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? (preserveFilters && preserveFilters.prevSearchParam)
      ? (((preserveFilters && preserveFilters.prevSearchParam) === transferListType)
        ? queryString.parse(preserveFilters.search)
        : setFilterData({
          pathname,
          search: '',
          filterTransfers: [{
            filterType: '',
            filterName: '',
            filterOperator: '',
            filterValue: '',
            filterNameDisplay: '',
            filterValueFrom: '',
            filterValueTo: '',
            checked: true,
          }],
        }))
      : queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterTransfers: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });
  const debouncedFetchTransfers = debounce(fetchTransfers, 2000, { leading: true });
  const supportsSchedule = (selectedAccount.issuer && selectedAccount.issuer.supportsSchedule);
  const issuerName = (selectedAccount.issuer && selectedAccount.issuer.name);
  const [otpConfirm, setOtpConfirm] = useState(false);
  const [otpPayload, setOtpPayload] = useState();

  const exportData = transfers ? transfers.map((values) => ({
    amount: `${values.currency.prefix}${formatNumber((values.amount / 100).toFixed(values.currency.toFixed), currencyType)}`,
    status: getTransferStatus(values.status),
    contact: values.contact && values.contact.name,
    utr: (values && values.rtgsTransfer)
      ? (values.rtgsTransfer && values.rtgsTransfer.utrNumber)
      : (values && values.neftTransfer) ? (values.neftTransfer && values.neftTransfer.utrNumber)
        : (values && values.impsTransfer) ? (values.impsTransfer && values.impsTransfer.utrNumber)
          : '',
    transferDate: (values && values.transferDate)
      ? moment
        .utc(values.scheduleDate ? values.scheduleDate : values.transferDate)
        .tz(selectedAccount.timezone)
        .format('MMM DD, YYYY') : '',
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY'),
  })) : '';

  const exportHeaders = isIndia ? [
    { label: 'AMOUNT', key: 'amount' },
    { label: 'STATUS', key: 'status' },
    { label: 'CONTACT', key: 'contact' },
    { label: 'UTR', key: 'utr' },
    { label: 'TRANSFER DATE', key: 'transferDate' },
    { label: 'DATE', key: 'createdAt' },
  ] : [
    { label: 'AMOUNT', key: 'amount' },
    { label: 'STATUS', key: 'status' },
    { label: 'CONTACT', key: 'contact' },
    { label: 'TRANSFER DATE', key: 'transferDate' },
    { label: 'DATE', key: 'createdAt' },
  ];

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const searchParam = parsed['status.IN'];
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = ((preserveParam && preserveParam.filterParams) ? preserveParam.filterParams : filterParam || '');
    debouncedFetchTransfers({
      searchParam,
      currentPage,
      sortParams,
      filterParams,
    });
    fetchTransferSetting({ sortParams });
    getContactList();
  };

  const clearFilters = () => {
    setFilterTransfers([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(TRANSFERS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e && e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn'
      && e.toElement.className !== 'ant-select-dropdown-menu  ant-select-dropdown-menu-root ant-select-dropdown-menu-vertical') {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!validator) {
      getData();
      setAddTransferModal(false);
      if (transferOtpModal && !otpConfirm) {
        setTransferOtpModal(false);
      } else if (!transferOtpModal && otpConfirm) {
        setTransferOtpModal(true);
      }
    }
  }, [validator]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
    if (selectedAccount && selectedAccount.contactNumber) {
      setLastFour((selectedAccount.contactNumber).slice(-4));
    }
    if (selectedAccount && selectedAccount.issuer) {
      setOtpRequires(selectedAccount.issuer.requiresOtp);
    }
  }, [selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    if (test) {
      if (location.search) {
        getData(location.search);
      } else {
        getData('?status.IN=ALL_TRANSFERS');
      }
    } else if (selectedAccount && selectedAccount.issuer) {
      if (location.search) {
        getData(location.search);
      } else {
        getData('?status.IN=ALL_TRANSFERS');
      }
    }
    if (!filterParams) {
      clearFilters();
    }
  }, [location, test, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [transfers]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push(`${PAGES.TRANSFERS}/${searchParams}`);
  };

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    const parsed = queryString.parse(location.search);
    const searchParam = parsed['status.IN'];
    const filterParams = parsed.filterParams || '';
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && searchParam && !filterParams) {
      history.push({
        pathname: PAGES.TRANSFERS,
        search: `?status.IN=${searchParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else if (!searchParam && !filterParams) {
      history.push({
        pathname: PAGES.TRANSFERS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    } else if (searchParam && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.TRANSFERS,
        search: `?status.IN=${searchParam}&filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterTransfers];
    if (filterTransfers.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = TRANSFERS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTransfers(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTransfers];
    if (e[1] === 'transferDate') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = TRANSFERS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTransfers(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterTransfers];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterTransfers(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterTransfers(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterTransfers];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterTransfers(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterTransfers(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTransfers[index].filterName === 'transferDate') {
      if (filterTransfers[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `transferDate.GT=${dateStart}&transferDate.LT=${dateEnd}`;
      } else if (filterTransfers[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `transferDate.LT=${dateStart}`;
      } else if (filterTransfers[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `transferDate.GT=${dateStart}`;
      }
    } else if (filterTransfers[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTransfers];
    list[index].filterValue = selectedValue;
    setFilterTransfers(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTransfers];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'transferDate') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'transferDate') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTransfers[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTransfers(list);
  };

  const addNewTransfer = (transferInfo: Object) => {
    const {
      contactGid,
      contactAddGid,
      description,
      transferAmount,
      transferType,
      transferApiDate,
      fundingSourceGid,
      fundingSourceAddGid,
      phoneNumberCode,
      contactPhone,
      email,
    } = transferInfo;
    const amount = transferAmount.replace(/[^\d]/g, '');
    const approveLimt = (transferSettings && transferSettings.minApprovalLimit);
    if ((Number(amount) > approveLimt) && !otpRequires) {
      setTransferOtpModal(true);
      setOtpPayload(transferInfo);
    } else {
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        contactGid: contactAddGid || contactGid,
        fundingSourceGid: fundingSourceAddGid || fundingSourceGid,
        description,
        transferTypeCode: transferType,
        scheduleDate: supportsSchedule ? transferApiDate : null,
        confirmationEmail: email,
        confirmationSms: `${phoneNumberCode}${contactPhone}`,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
      });
      if (otpRequires && !test) {
        setOtpConfirm(true);
      }
    }
  };

  const otpTransfer = async (otpInfo: Object) => {
    const {
      gid,
      otpCode,
      UniqueId,
      transferEmail,
    } = otpInfo;
    const params = { otpCode };
    if (otpRequires && !test) {
      confirmTransferOtp({
        gid,
        params,
      });
      setOtpConfirm(false);
    } else {
      setOtpConfirm(false);
      const {
        contactGid,
        contactAddGid,
        description,
        transferAmount,
        transferType,
        transferApiDate,
        fundingSourceGid,
        fundingSourceAddGid,
        phoneNumberCode,
        contactPhone,
        email,
      } = otpPayload;
      const amount = transferAmount.replace(/[^\d]/g, '');
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        contactGid: contactAddGid || contactGid,
        fundingSourceGid: fundingSourceAddGid || fundingSourceGid,
        description,
        transferTypeCode: transferType,
        scheduleDate: supportsSchedule ? transferApiDate : null,
        confirmationEmail: transferEmail || email,
        confirmationSms: `${phoneNumberCode}${contactPhone}`,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
        swirepayOtpGid: UniqueId,
        otpCode,
      });
    }
  };

  const handleAddClick = () => {
    const list = [...filterTransfers];
    setFilterTransfers([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTransfers([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(TRANSFERS_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.TRANSFERS);
  };

  const applyFilters = () => {
    const transfersFilters = filterTransfers.filter((item) => item.checked);
    for (let i = 0; i < transfersFilters.length; i += 1) {
      if (!transfersFilters[i].filterName || !transfersFilters[i].filterOperator || !transfersFilters[i].filterValue) {
        return;
      }
    }
    if (transfersFilters[0].filterValue || (transfersFilters[0].filterValueFrom && transfersFilters[0].filterValueTo)) {
      setCount(transfersFilters && transfersFilters.length);
      for (let i = 0; i < transfersFilters.length; i += 1) {
        if (transfersFilters[i].checked) {
          if (transfersFilters[i].filterName === 'transferDate') {
            transfersFilters[i] = transfersFilters[i].filterValue;
          } else if (transfersFilters[i].filterName === 'amount' && transfersFilters[i].filterOperator === 'BT') {
            const name = transfersFilters[i].filterName;
            const from = transfersFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = transfersFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            transfersFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (transfersFilters[i].filterName === 'amount' && transfersFilters[i].filterOperator !== 'BT') {
            const value = transfersFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            transfersFilters[i] = `${transfersFilters[i].filterName}.${transfersFilters[i].filterOperator}=${value}`;
          } else {
            transfersFilters[i] = `${transfersFilters[i].filterName}.${transfersFilters[i].filterOperator}=${transfersFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(transfersFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const searchParam = parsed['status.IN'];
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && searchParam && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?status.IN=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
          filterTransfers,
          prevSearchParam: searchParam,
        });
        history.push({
          pathname: PAGES.TRANSFERS,
          search: `?status.IN=${searchParam}&pageNum=${currentPage}${sortParams}&filterParams=${filterParams}`,
        });
      } else if (location.search && searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?status.IN=${searchParam}&filterParams=${filterParams}`,
          filterTransfers,
          prevSearchParam: searchParam,
        });
        history.push({
          pathname: PAGES.TRANSFERS,
          search: `?status.IN=${searchParam}&filterParams=${filterParams}`,
        });
      } else if (location.search && !searchParam && !parsed.pageNum && !parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterTransfers,
          prevSearchParam: searchParam,
        });
        history.push({
          pathname: PAGES.TRANSFERS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const utrColumns = [
    {
      title: 'NAME',
      dataIndex: 'contact',
      width: '20%',
      align: 'left',
      render: (text, record) => {
        const name = record?.contact?.name;
        return (
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <span>
            {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferBackground(status) }}>
            {getTransferStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'CONTACT',
      dataIndex: 'contact.name',
      width: '15%',
      align: 'left',
      render: (cname) => (
        <span style={{ cursor: 'pointer' }}>
          {cname || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'UTR',
      dataIndex: 'uniqueReferenceNumber',
      width: '15%',
      align: 'left',
      render: (uniqueReferenceNumber, record) => {
        if ((record.neftTransfer) && (record.neftTransfer.utrNumber)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(record.neftTransfer.utrNumber)}
            </span>
          );
        } if ((record.rtgsTransfer) && (record.rtgsTransfer.utrNumber)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(record.rtgsTransfer.utrNumber)}
            </span>
          );
        } if ((record.impsTransfer) && (record.impsTransfer.utrNumber)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(record.impsTransfer.utrNumber)}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'TRANSFER DATE',
      dataIndex: 'date',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        if (record.transferDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(record.transferDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        } if (record.scheduleDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(record.scheduleDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'contact',
      align: 'left',
      render: (text, record) => {
        const name = record?.contact?.name;
        return (
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'SCHEDULED DATE',
      dataIndex: 'date',
      align: 'left',
      sorter: true,
      render: (text, record) => {
        if (record.scheduleDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(record.scheduleDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'TRANSFER DATE',
      dataIndex: 'date',
      align: 'left',
      sorter: true,
      render: (text, record) => {
        if (record.transferDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(record.transferDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'TRANSFER MODE',
      dataIndex: 'transferTypeCode',
      align: 'left',
      render: (transferTypeCode) => (
        <span style={{ cursor: 'pointer' }}>
          {(transferTypeCode === 'RTP' ? 'INSTANT PAYMENT' : transferTypeCode.replace(/_/g, ' ')) || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'FROM ACCOUNT',
      dataIndex: 'fromAccount',
      align: 'left',
      render: (text, record) => {
        const name = record?.payerFundingSource?.issuerBank?.accountHolderName;
        const bankName = `${record?.payerFundingSource?.issuerBank?.bankName || ''} ****${record?.payerFundingSource?.issuerBank?.lastFour || ''}`;

        return (
          <span style={{ cursor: 'pointer' }}>
            {name} | {bankName}
          </span>
        );
      },
    },
    {
      title: 'TO ACCOUNT',
      dataIndex: 'toAccount',
      align: 'left',
      render: (text, record) => {
        const name = record?.fundingSource?.issuerBank?.accountHolderName;
        const verified = record?.fundingSource?.issuerBank?.isVerified;
        const bankName = `${record?.fundingSource?.issuerBank?.bankName || ''} ****${record?.fundingSource?.issuerBank?.lastFour || ''}`;

        return (
          <span style={{ cursor: 'pointer' }}>
            {name} | {bankName} {verified ? <><img src={Verified} alt="verified" /> <img src={VerifiedUser} alt="verified" /></> : ''}
          </span>
        );
      },
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <span>
            {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      align: 'left',
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferBackground(status) }}>
            {getTransferStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Row className="my-2">
          <div className="d-flex justify-content-end">
            <Col>
              {/* <RBAC
                permit={
                  [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.SUPERUSER]
                }
              >
                <SpButton
                  type="secondary"
                  shape="round"
                  ghost
                  className="mr-3"
                  onClick={() => {
                    setAddTransferModal(true);
                  }}
                >
                  {'\u002B'}&nbsp;Issue Transfer
                </SpButton>
              </RBAC> */}
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Transfers">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Transfers Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Transfers">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-265px' }}
                  >
                    <Filters
                      filtersGrid={filterTransfers}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterTransfers.length < TRANSFERS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </div>
        </Row>
        {/* ADD TRANSFER MODAL */}
        {addTransferModal && (
          <AddTransfer
            visible={addTransferModal}
            submitting={submitting}
            selectedAccount={selectedAccount}
            contactList={contactList}
            transferSettings={transferSettings}
            statesInCountry={statesInCountry}
            country={selectedAccount.country.name}
            cntryId={selectedAccount.country.id}
            isIndia={isIndia}
            supportsSchedule={supportsSchedule}
            issuerName={issuerName}
            zipTag={zipTag}
            close={() => setAddTransferModal(false)}
            submit={addNewTransfer}
          />
        )}
        {/* ADD OTP MODAL */}
        {transferOtpModal && (
          <ConfirmOtp
            visible={transferOtpModal}
            submitting={submitting}
            lastFour={lastFour}
            phoneCode={phoneCode}
            issuerGid={issuerGid}
            transferSettings={transferSettings}
            otpRequires={otpRequires}
            close={() => setTransferOtpModal(false)}
            submit={otpTransfer}
          />
        )}
        <Table
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowClassName={() => 'ant-table-clickable-row'}
          columns={isIndia ? utrColumns : columns}
          rowKey={(record) => record.gid}
          dataSource={transfers}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  newfundingsource: state.fundingSource.newfundingsource,
  transfers: state.transfers.content,
  transferSettings: state.transferSettings.content,
  contactList: state.transfers.contactList,
  issuerGid: state.transfers.issuerGid,
  loading: state.loading.loading,
  totalElements: state.transfers.totalElements,
  test: state.account.test,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransfers: param => dispatch(transfersActions.fetchTransfers({
    payload: param,
  })),
  fetchTransferSetting: gid => dispatch(transferSettingsActions.fetchTransferSetting({
    payload: gid,
  })),
  getContactList: param => dispatch(transfersActions.getContactList({
    payload: param,
  })),
  issueTransfer: param => dispatch(transfersActions.issueTransfer({
    payload: param,
  })),
  confirmTransferOtp: param => dispatch(transfersActions.confirmTransferOtp({
    payload: param,
  })),
  createFundingSource: param => dispatch(fundingSourceActions.createFundingSource({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllTransfers);
