// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal, Select,
} from 'antd';
import { SketchPicker } from 'react-color';
import {
  validators,
} from 'utils/validationMessages';
import SimpleReactValidator from 'simple-react-validator';
import {
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import {
  SpColor,
  SpCover,
  SpPopover,
  SpWatch,
} from 'components/PaymentWidgetDesignKit';

const { Option } = Select;

const { TextArea } = Input;
type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedItemList: Object,
  selectedAccount: Object,
  menuData:Array,
};

const EditItems = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    selectedItemList,
    menuData,
  } = props;


  const [, forceUpdate] = useState();
  const gid = selectedItemList ? selectedItemList.gid : '';
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [name, setName] = useState((selectedItemList && selectedItemList.name) || '');
  // eslint-disable-next-line no-unused-vars
  const [onlineName, setOnlineName] = useState((selectedItemList && selectedItemList.onlineName) || '');
  const [order, setOrder] = useState(selectedItemList && selectedItemList.sortOrder);
  const [description, setDescription] = useState(selectedItemList && selectedItemList.description);
  const [disableButton, setDisableButton] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const STRING_LENGTH = 1;
  const [operatingHours, setOperatingHours] = useState(selectedItemList.operatingHoursMaster ? selectedItemList.operatingHoursMaster.gid : null);
  const [primaryColor, setColorCode] = useState((selectedItemList && selectedItemList.colorCode) || '#007bff');
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minLimit: {
        message: 'Sort order must be valid',
        rule: val => val >= 0 && val < 999999,
      },
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      numericValidation: {
        message: 'Sort order must be valid',
        rule: (val) => (val ? !val.toString().includes('.') : true),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (e) => {
    setColorCode(e.hex);
  };

  const handleSelectChangeHours = (values) => {
    setOperatingHours(values);
  };

  const updateColor = (e) => {
    setColorCode(e.currentTarget.value);
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      gid,
      name,
      onlineName,
      order,
      primaryColor,
      description,
      operatingHours,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Save</SpButton>,
      ]}
      title="Edit Category"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Enter the name"
            onChange={(e) => setName(e.currentTarget.value)}
            value={name}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required|stringLength')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Category Color</SpH5></label>
          <div className="d-flex ml-1">
            <div>
              <SpWatch onClick={handleClick}>
                <SpColor className="color" style={{ backgroundColor: `${primaryColor}` }} />
              </SpWatch>
              {displayColorPicker ? (
                <>
                  <SpPopover>
                    <SpCover onClick={handleClose} />
                    <SketchPicker color={primaryColor} onChange={handleChange} />
                  </SpPopover>
                </>
              ) : null}
            </div>
            <Input
              style={{ width: '150px' }}
              className="ml-3"
              value={primaryColor}
              onChange={updateColor}
            />
          </div>
          <SpError>
            {simpleValidator.current.message('color code', primaryColor, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <TextArea
            maxLength={255}
            rows={5}
            placeholder="Description"
            className="resize"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor=""><SpH5>Sort Order</SpH5></label>
          <Input
            placeholder="Enter the order"
            type="number"
            onChange={(e) => setOrder(e.currentTarget.value)}
            value={order}
          />
          <SpError>
            {simpleValidator.current.message('order', order, 'numeric|numericValidation|minLimit')}
          </SpError>
        </div>
        {/* <div>
          <label htmlFor=""><SpH5>Online Name</SpH5></label>
          <Input
            placeholder="Enter the name"
            onChange={(e) => setOnlineName(e.currentTarget.value)}
            value={onlineName}
          />
          <SpError>
            {simpleValidator.current.message('onlinename', name, 'required|stringLength')}
          </SpError>
        </div> */}
        <div>
          <label htmlFor=""><SpH5>Operating Hours</SpH5></label>
          <Select
            className="w-100"
            allowClear
            placeholder="Select item"
            onChange={handleSelectChangeHours}
            value={operatingHours}
            showSearch
            // optionFilterProp="name"
            style={{ width: '100%' }}
          >
            {menuData.content.map((item) => (
              <Option key={item.gid} name={item.name} value={item.gid}>
                {item.name}
              </Option>
            ))}
          </Select>
          {/* <SpError>
            {simpleValidator.current.message('operating hours', operatingHours, 'required')}
          </SpError> */}
        </div>

      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(EditItems);
