// @flow
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { connect, useSelector } from 'react-redux';
import { withRouter, useLocation, matchPath } from 'react-router-dom';
import styled from 'styled-components';
import type { Node } from 'react';
import type { ContextRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { PAGES } from 'appconstants';
import SideNav from 'components/SideNav';
import Header from 'components/Header';
import Notification from 'components/Notification';
import AccountSelector from 'components/AccountSelector';
import { notificationActions, userActions, mobileViewActions } from 'store/actions';
import { API_END_POINTS, axiosLoginInstance } from 'api';
import { NOTIFICATION, USER } from 'store/actionTypes';

const { Content } = Layout;

// $FlowFixMe
const AppLayout = styled(Layout)({
});

// $FlowFixMe
const AppContent = styled(Content)({
  overflowY: 'hidden',
  overflowX: 'hidden',
});

const HIDE_CONTENT_ROUTES = [
  PAGES.LOGIN,
  PAGES.DOCUSIGN,
  PAGES.DOCUSIGNLATER,
  PAGES.FEEDBACKRATING,
  PAGES.NO_MATCH,
  PAGES.SET_PASSWORD,
  PAGES.FORGOT_PASSWORD,
  PAGES.RESET_PASSWORD,
  PAGES.REGISTER,
  `${PAGES.RECEIPT}/:id`,
  `${PAGES.CASH_RECEIPT}/:id`,
  `${PAGES.CHEQUE_RECEIPT}/:id`,
  PAGES.ADDPAYMENTPAGE,
  `${PAGES.UPDATE_PAYMENT_PAGE}/:id`,
  `${PAGES.TRANSFER_RECEIPT}/:id`,
  PAGES.ACCOUNT,
  PAGES.ACCOUNT_SETUP,
  PAGES.ACCOUNT_ERROR,
  PAGES.RELEASE,
  PAGES.ADDPAYMENTWIDGET,
  PAGES.LOCKED,
  PAGES.NEW_REGISTER,
  PAGES.POS_USER,
  `${PAGES.PAGE_RECIPT}/:id`,
  `${PAGES.CLOVER_RECEIPT}`,
  `${PAGES.ADDPAYMENTPAGE}/:id`,
  PAGES.ADDSEATEDPAGE,
  `${PAGES.ADDSEATEDPAGE}/:id`,
  `${PAGES.EVENT_RECIPT}/:id`,
  `${PAGES.EVENT_PAGE_RECEIPT}`,
  `${PAGES.ACCOUNT_SESSIONS}/:id`,
  `${PAGES.ACCOUNT_SESSIONS_VIEW_INVOICE}/:id`,
  `${PAGES.ORDER_RECEIPT}`,
  PAGES.ADDDONATIONPAGE,
  `${PAGES.UPDATE_DONATION_PAGE}/:id`,
  `${PAGES.ADDDONATIONPAGE}/:id`,
  PAGES.ADDEVENTSPAGE,
  `${PAGES.ADDEVENTSPAGE}/:id`,
  `${PAGES.EDIT_EVENT_PAGE}/:id`,
  PAGES.MAINATAINANCE_PAGE,
  `${PAGES.EVENT_PAGE_OFFLINE_PAYMENT}/:id`,
];

type Props = ContextRouter & {
  setTestData: Function,
  resetNotification: Function,
  history: {
    push: Function,
  },
  children: Node,
  error: boolean,
  success: boolean,
  role: Object,
  setUser:String,
  getMobileView: Function,
  isMobileView: Boolean,
};

const BasePage = (props: Props) => {
  const {
    history,
    children,
    error,
    success,
    resetNotification,
    role,
    setUser,
    getMobileView,
    isMobileView,
  } = props;


  const { pathname } = useLocation();
  // eslint-disable-next-line no-unused-vars
  let idleTimer = null;
  const TIME_OUT = 1000 * 60 * 30;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const getHealthCheck = async () => {
    try {
      const { data: { entity } } = await axiosLoginInstance.get(`${API_END_POINTS.MAINTAINACE_API}`);
      console.log(entity);
    } catch (e) {
      history.push(PAGES.MAINATAINANCE_PAGE);
    }
  };

  useEffect(() => {
    getHealthCheck();
  }, []);

  useEffect(() => {
    const windowWidth = window.screen.width;
    if (windowWidth < 480) {
      getMobileView(true);
    } else if (windowWidth > 480) {
      getMobileView(false);
    }
  }, []);

  const onResize = () => {
    const windowWidth = window.screen.width;
    if (windowWidth < 480) {
      getMobileView(true);
    } else if (windowWidth > 480) {
      getMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);


  const { selectedAccount } = useSelector(state => state.account);

  const handleOnAction = (e) => {
    console.log(e);
  };

  const handleOnActive = (e) => {
    console.log(e);
  };

  const handleOnIdle = (e) => {
    console.log(e);
    if (role.name !== 'KDS-ADMIN') {
      history.push(PAGES.LOCKED);
    }
  };

  useEffect(() => {
    resetNotification();
  }, []);

  useEffect(() => {
    resetNotification();
    selectedAccount && setUser(selectedAccount);
  }, [selectedAccount]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  const showHeader = !HIDE_CONTENT_ROUTES.find((route) => matchPath(history.location.pathname,
    {
      path: route,
      exact: true,
      strict: false,
    }));
  return (
    <>
      {
        showHeader
          ? (
            <AppLayout>
              <AccountSelector {...props} />
              <SideNav />
              <IdleTimer
                ref={ref => { idleTimer = ref; }}
                timeout={TIME_OUT}
                onActive={handleOnActive}
                onIdle={handleOnIdle}
                onAction={handleOnAction}
                debounce={500}
              />
              <Layout>
                <Header />
                <AppContent className={showHeader ? 'px-2' : ''}>
                  {
                    (Boolean(error) || Boolean(success)) && (
                      <Notification />
                    )
                  }
                  {children}
                </AppContent>
              </Layout>
            </AppLayout>
          )
          : (
            <Layout>
              {
                (Boolean(error) || Boolean(success)) && (
                  <Notification />
                )
              }
              {children}
            </Layout>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  success: state.notification.success,
  error: state.notification.error,
  role: state.user.role,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  resetNotification: () => dispatch(notificationActions.resetNotification({
    type: NOTIFICATION.RESET,
  })),
  getMobileView: param => dispatch(mobileViewActions.getMobileView({
    payload: param,
  })),
  setUser: (param) => dispatch(userActions.setUser({
    type: USER.FETCH,
    payload: param,
  })),

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasePage));
