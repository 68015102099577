// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  selectedRecordAccount: Object,
};

const DefaultAccount = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedRecordAccount,
  } = props;

  const defaultAccount = () => {
    submit(selectedRecordAccount);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={defaultAccount} type="primary">Confirm</SpButton>,
      ]}
      title="Confirm Switch Default Account?"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to switch your default bank account?</div>
        <div className="mt-2">Note: This change will affect future transactions.</div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DefaultAccount;
