// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Card,
  Table,
  Row,
  Menu,
  Col,
  Tooltip,
  Icon,
  Input,
  Switch,
  Select,
} from 'antd';
import moment from 'moment-timezone';

import {
  SpForm,
  SpText,
  SpH5,
  SpTab,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  couponDetailsActions,
  couponActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
  API_STATUS,
  COUPON_DURATION,
} from 'appconstants';
import getCouponBackground from 'utils/getCouponBackground';
import formatAmount from 'utils/formatAmount';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import DeleteSelectedCoupon from './components/DeleteSelectedCoupon';
import EditCoupon from './components/EditCoupon';
import AddPromotionalCode from './components/AddPromotionalCode';
import DeletePromotionalCode from './components/DeletePromotionalCode';

const { Option } = Select;

type Props = {
  fetchCouponDetails: Function,
  couponDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  couponDetailsStatus: string,
  deleteCoupon: Function,
  validator: boolean,
  updateCoupon: Function,
  fetchCustomers: Function,
  customers: Array<Object>,
  addPromoCode: Function,
  deletePromoCode: Function,
  redemptions: Array<Object>,
  promotionals: Array<Object>,
  allShops: Array<Object>,
  allMenus: Array<Object>,
  allCategories: Array<Object>,
  fetchCouponShops: Function,
  fetchCouponMenus: Function,
  fetchCouponCategories: Function,
  fetchCouponShop: Function,
  updateCouponShop: Function,
  fetchCouponItems: Function,
  allItems: Array<Object>,
  couponShop: Array<Object>,
  allEventPages: Array<Object>,
  eventPageItems: Array<Object>,
  eventPageCategories: Array<Object>,
  fetchAllEventPages: Function,
  fetchEventPageItems: Function,
  fetchEventPageCategories: Function,
  couponEventPage: Array<Object>,
  updateCouponEventPage: Function,
  isMobileView: Boolean,
}

const CouponDetails = (props: Props) => {
  const {
    fetchCouponDetails,
    couponDetails,
    loading,
    submitting,
    history,
    selectedAccount,
    couponDetailsStatus,
    deleteCoupon,
    validator,
    updateCoupon,
    fetchCustomers,
    customers,
    addPromoCode,
    deletePromoCode,
    redemptions,
    promotionals,
    allShops,
    allMenus,
    allCategories,
    fetchCouponShops,
    fetchCouponMenus,
    fetchCouponCategories,
    fetchCouponShop,
    updateCouponShop,
    fetchCouponItems,
    allItems,
    // couponMenu,
    couponShop,
    allEventPages,
    eventPageItems,
    eventPageCategories,
    fetchAllEventPages,
    fetchEventPageCategories,
    fetchEventPageItems,
    couponEventPage,
    updateCouponEventPage,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const { gid } = props.match.params;
  const path = props.match.url;
  const [deleteCouponModal, setDeleteCouponModal] = useState(false);
  const [editCouponModal, seteditCouponModal] = useState(false);
  const [addPromoCodeModal, setAddPromoCodeModal] = useState(false);
  const [deletePromoModal, setDeletePromoModal] = useState(false);
  const gidPath = gid.split('-')[1];
  const [redirect, setRedirect] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [allCustomers, setAllCustomers] = useState(customers);
  const typeOfCurrency = selectedAccount && selectedAccount.currency.name;
  const currencyPrefix = selectedAccount && selectedAccount.currency.prefix;
  const [selectedPromoCode, setSelectedPromoCode] = useState({});
  const [status, setStatus] = useState();
  const [percentage, setPercentage] = useState(null);
  const [amount, setAmount] = useState(null);
  const disabled = couponDetails && couponDetails.active;

  const [current, setCurrent] = useState('shop');
  const [currentCoupon, setCurrentCoupon] = useState('menu');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [existingDiscount, setExistingDiscount] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [selectedEventPage, setSelectedEventPage] = useState(null);
  const [selectedCouponShopEvent, setSelectedCouponShopEvent] = useState(null);

  const [searchText, setSearchText] = useState('');

  /* Coupon Related */

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.COUPONS);
    }
  }, []);

  const getData = () => {
    fetchAllEventPages();
    fetchCouponShops();
    fetchCouponShop(gid);
    fetchCouponDetails(gid);
    fetchCustomers();
  };

  useEffect(() => {
    if (couponDetails && couponDetails.gid) {
      setStatus((couponDetails && couponDetails.active) ? 'Active' : 'Deactivated');
      setPercentage(couponDetails.percentageOff);
      setAmount(couponDetails.amountOff);
    }
  }, [couponDetails]);

  useEffect(() => {
    if (!validator && redirect) {
      getData();
      seteditCouponModal(false);
      setAddPromoCodeModal(false);
      setDeletePromoModal(false);
      setSelectedRowKeys([]);
      setCurrent('shop');
      setCurrentCoupon('menu');
      setSelectedShop(null);
      setSelectedEventPage(null);
      setSelectedCouponShopEvent({
        stackable: false,
      });
      setDeleteCouponModal(false);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData();
  }, [selectedAccount]);

  useEffect(() => {
    setAllCustomers(customers);
  }, [customers]);

  const fetchCustomer = (searchParams) => {
    fetchCustomers(searchParams);
  };

  const deleteSelectedCoupons = (couponGid: string) => {
    deleteCoupon(couponGid);
    setRedirect(true);
  };

  const editSelectedCoupons = (coupon: object) => {
    const {
      name,
      active,
    } = coupon;
    const params = {
      name,
      active,
    };
    setRedirect(true);
    updateCoupon({
      params,
      gid,
    });
  };

  const addPromotionalCode = (promocode: Object) => {
    setRedirect(true);
    const {
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      customerGid,
    } = promocode;
    const payload = {
      active: true,
      code,
      enabledForCheckout: true,
      couponGid: gid,
      expiresAt: promoExpiresAt,
      maximumRedemption: promotionalRedeemed ? parseInt(promotionalRedeemed, 10) : null,
      minimumAmount: minimumAmount ? parseInt((minimumAmount).replace(/[_\W]+/g, ''), 10) : null,
      minimumAmountCurrency: typeOfCurrency,
      restrictedToCustomers: [
        customerGid,
      ],
    };
    addPromoCode({
      payload,
      gid,
    });
  };

  const showDeletePromoModal = (record) => {
    setSelectedPromoCode(record);
    setDeletePromoModal(true);
  };

  const deleteSelectedPromo = (promoGid: String) => {
    setRedirect(true);
    deletePromoCode({
      promoGid,
      gid,
    });
  };

  const sortPromotionalCodes = (array) => {
    array.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return array;
  };

  /* Discount Related */

  const shopRelatedInventory = (val) => {
    if (val) {
      fetchCouponMenus(val);
      fetchCouponCategories(val);
      fetchCouponItems(val);
    } else {
      setSelectedRowKeys([]);
    }
  };

  const eventPageRelatedInventory = (val) => {
    if (val) {
      fetchEventPageItems(val);
      fetchEventPageCategories(val);
    } else {
      setSelectedRowKeys([]);
    }
  };

  const handleSwitchChange = (e) => {
    if (selectedCouponShopEvent) {
      setSelectedCouponShopEvent((prev) => ({
        ...prev,
        stackable: e,
      }));
    } else {
      setSelectedCouponShopEvent({ stackable: e });
    }
  };

  const updateCurrentTab = (val) => {
    if (!applyDiscount) {
      setCurrent(val);
      if (val === 'event' && currentCoupon === 'menu') {
        setCurrentCoupon('category');
      }
    }
  };

  const updateCurrentCouponTab = (val) => {
    setCurrentCoupon(val);
  };

  const updateSelectedShop = (shop) => {
    if (shop !== 'selectShop') {
      const cs = couponShop.length > 0 ? couponShop.find((shopCoupon) => shopCoupon?.shop?.gid === shop[0]) : null;
      if (cs) {
        setSelectedCouponShopEvent(cs);
        setExistingDiscount(true);
      } else {
        setSelectedCouponShopEvent({
          stackable: false,
        });
        setExistingDiscount(false);
      }
      setSelectedShop(shop[1]);
      shopRelatedInventory(shop[0]);
    } else {
      setSelectedCouponShopEvent({
        stackable: false,
      });
      setExistingDiscount(false);
      shopRelatedInventory(null);
      setSelectedShop(null);
    }
  };

  const updateSelectedEventPage = (eventPage) => {
    if (eventPage !== 'selectEvent') {
      const cs = couponEventPage.length > 0 ? couponEventPage.find((shopCoupon) => shopCoupon?.eventPage?.gid === eventPage[0]) : null;
      if (cs) {
        setSelectedCouponShopEvent(cs);
        setExistingDiscount(true);
      } else {
        setSelectedCouponShopEvent({
          stackable: false,
        });
        setExistingDiscount(false);
      }
      setSelectedEventPage(eventPage[1]);
      eventPageRelatedInventory(eventPage[0]);
    } else {
      setSelectedCouponShopEvent({
        stackable: false,
      });
      setExistingDiscount(false);
      eventPageRelatedInventory(null);
      setSelectedEventPage(null);
    }
  };

  useEffect(() => {
    if (selectedCouponShopEvent && selectedCouponShopEvent.discountLevel) {
      switch (selectedCouponShopEvent.discountLevel) {
        case 'MENU_LEVEL':
          setCurrentCoupon('menu');
          break;
        case 'CATEGORY_LEVEL':
          setCurrentCoupon('category');
          break;
        case 'ITEM_LEVEL':
          setCurrentCoupon('item');
          break;
        default:
          setCurrentCoupon('menu');
      }
    }
  }, [selectedShop]);

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const filterDataSource = (datasource) => {
    if (currentCoupon === 'menu') {
      return datasource.filter((menu) => menu.inventoryMenu?.name?.toLowerCase().includes(searchText.toLowerCase()));
    } if (currentCoupon === 'category') {
      return datasource.filter((category) => category.name?.toLowerCase().includes(searchText.toLowerCase()));
    }
    return datasource.filter((item) => item.name?.toLowerCase().includes(searchText.toLowerCase()));
  };

  const updateAppliedDiscount = () => {
    setRedirect(true);
    if (selectedCouponShopEvent && current === 'shop') {
      updateCouponShop({
        params: selectedCouponShopEvent,
        gid,
        shopGid: selectedShop.gid,
      });
    } else if (selectedCouponShopEvent) {
      updateCouponEventPage({
        params: selectedCouponShopEvent,
        gid,
        eventPageGid: selectedEventPage.gid,
      });
    }
  };

  const handleSelectChange = (selectedRowKey) => {
    const updatedSelection = {
      menuGids: [],
      categoryGids: [],
      itemGids: [],
    };

    switch (currentCoupon) {
      case 'menu':
        updatedSelection.menuGids = selectedRowKey.filter((item) => item.startsWith('inventorymenu'));
        break;
      case 'category':
        updatedSelection.categoryGids = selectedRowKey.filter((item) => item.startsWith('inventorycategory'));
        break;
      default:
        updatedSelection.itemGids = selectedRowKey.filter((item) => item.startsWith('item'));
    }

    setSelectedCouponShopEvent((prev) => ({
      ...prev,
      ...updatedSelection,
    }));

    setSelectedRowKeys(selectedRowKey);
  };

  // eslint-disable-next-line no-unused-vars
  const [rowSelection, setRowSelection] = useState({
    type: 'checkbox',
    selectedRowKeys,
    onChange: handleSelectChange,
    columnWidth: '5%',
    fixed: 'left',
  });

  useEffect(() => {
    setRowSelection((prevRowSelection) => ({
      ...prevRowSelection,
      selectedRowKeys,
    }));
  }, [selectedRowKeys]);

  const getSelectedMenus = (record, coupon) => {
    if (coupon && coupon.menus && coupon.menus.length !== 0) {
      const gidsMenu = coupon.menus.map((option) => option.gid);
      return gidsMenu.includes(record.gid);
    }
    return false;
  };

  const getSelectedCategories = (record, coupon) => {
    if (coupon && coupon.categories && coupon.categories.length !== 0) {
      const gudCategories = coupon.categories.map((option) => option.gid);
      return gudCategories.includes(record.gid);
    }
    return false;
  };

  const getSelectedItems = (record, coupon) => {
    if (coupon && coupon.items && coupon.items.length !== 0) {
      const gidItems = coupon.items.map((option) => option.gid);
      return gidItems.includes(record.gid);
    }
    return false;
  };

  useEffect(() => {
    if (selectedCouponShopEvent) {
      switch (currentCoupon) {
        case 'menu':
          setSelectedRowKeys(allMenus.filter(record => getSelectedMenus(record.inventoryMenu, selectedCouponShopEvent))
            .map(record => record.inventoryMenu.gid));
          break;
        case 'category':
          setSelectedRowKeys(allCategories.filter(record => getSelectedCategories(record, selectedCouponShopEvent)).map(record => record.gid));
          break;
        default:
          setSelectedRowKeys(allItems.filter(record => getSelectedItems(record, selectedCouponShopEvent)).map(record => record.gid));
      }
    }
  }, [currentCoupon, selectedShop, allMenus, allCategories, allItems]);

  useEffect(() => {
    if (selectedCouponShopEvent) {
      switch (currentCoupon) {
        case 'category':
          setSelectedRowKeys(eventPageCategories.filter(record => getSelectedCategories(record, selectedCouponShopEvent)).map(record => record.gid));
          break;
        default:
          setSelectedRowKeys(eventPageItems.filter(record => getSelectedItems(record, selectedCouponShopEvent)).map(record => record.gid));
      }
    }
  }, [currentCoupon, selectedShop, eventPageItems, eventPageCategories]);

  const removeDiscount = () => {
    if (selectedCouponShopEvent && selectedShop) {
      const params = {
        removeDiscount: true,
      };
      setRedirect(true);
      updateCouponShop({
        params,
        gid,
        shopGid: selectedShop.gid,
      });
    } else if (selectedCouponShopEvent) {
      const params = {
        removeDiscount: true,
      };
      setRedirect(true);
      updateCouponEventPage({
        params,
        gid,
        eventPageGid: selectedEventPage.gid,
      });
    }
  };

  /* Columns Related */

  const columns = [
    {
      title: 'PROMOTION CODE',
      dataIndex: 'code',
      width: '20%',
      align: 'left',
    },
    {
      title: 'REDEMPTION',
      dataIndex: 'maximumRedemption',
      width: '20%',
      align: 'center',
      sorter: false,
      render: (text, record) => {
        if (record.maximumRedemption) {
          return (
            <span>
              {record.redemptionCount}/{record.maximumRedemption}
            </span>
          );
        } if (!record.maximumRedemption) {
          return (
            <span>
              {record.redemptionCount}/0
            </span>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'EXPIRES',
      dataIndex: 'expiresAt',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (expiresAt) => {
        if (expiresAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(expiresAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: '',
      width: '20%',
      align: 'center',
      render: (text, record) => (
        <span>
          <Tooltip placement="top" title={record.active ? 'deactivate' : ''}>
            <SpButton
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); showDeletePromoModal(record); }}
              disabled={!record.active}
              ghost
            >
              <Icon
                type="delete"
                style={{ fontSize: '20px', cursor: 'pointer' }}
              />
            </SpButton>
          </Tooltip>
        </span>
      ),
    },
  ];

  const redemptionsColumns = [
    {
      title: 'NAME',
      dataIndex: 'customerName',
      width: '15%',
      align: 'left',
    },
    {
      title: 'EMAIL',
      dataIndex: 'customerEmail',
      width: '20%',
      align: 'center',
      sorter: false,
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      width: '15%',
      align: 'left',
      sorter: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amountBeforeDiscount',
      width: '15%',
      align: 'left',
      sorter: false,
      render: (amountBeforeDiscount) => {
        const totalAmount = (amountBeforeDiscount / 100).toFixed(2);
        if (amountBeforeDiscount < 0) {
          return (
            <span>{`(${currencyPrefix} ${formatNumber(totalAmount, currencyType).replace(/-/g, '')})`}</span>
          );
        }
        return (
          <span> {`${currencyPrefix} ${formatNumber(totalAmount, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discountAmount',
      width: '15%',
      align: 'left',
      sorter: false,
      render: (discountAmount) => {
        const totalAmount = (discountAmount / 100).toFixed(2);
        if (discountAmount < 0) {
          return (
            <span>{`(${currencyPrefix} ${formatNumber(totalAmount, currencyType).replace(/-/g, '')})`}</span>
          );
        }
        return (
          <span> {`${currencyPrefix} ${formatNumber(totalAmount, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const shopColumn = [
    {
      title: 'SHOP',
      dataIndex: 'shops',
      width: '20%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.shop && record.shop.name) {
          return (
            <Tooltip placement="top" title={`Shop: ${record.shop.name}`}>
              <span style={{ cursor: 'pointer' }}>
                {record.shop.name}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="No shop available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'MENU',
      dataIndex: 'menu',
      width: '18%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.menus) {
          return (
            <>
              {
              record.menus.map((item) => (
                <Tooltip placement="top" title={`Menu: ${item.name}`}>
                  <span style={{ cursor: 'pointer' }}>
                    {item.name},&nbsp;
                  </span>
                </Tooltip>
              ))
            }
            </>

          );
        }
        return (
          <Tooltip placement="top" title="No shop available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'category',
      width: '18%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.categories) {
          return (
            <>
              {
              record.categories.map((item) => (
                <Tooltip placement="top" title={`Category: ${item.name}`}>
                  <span style={{ cursor: 'pointer' }}>
                    {item.name},&nbsp;
                  </span>
                </Tooltip>
              ))
            }
            </>

          );
        }
        return (
          <Tooltip placement="top" title="No shop available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'ITEM',
      dataIndex: 'item',
      width: '19%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.items) {
          return (
            <>
              {
              record.items.map((item) => (
                <Tooltip placement="top" title={`Item: ${item.name}`}>
                  <span style={{ cursor: 'pointer' }}>
                    {item.name},&nbsp;
                  </span>
                </Tooltip>
              ))
            }
            </>

          );
        }
        return (
          <Tooltip placement="top" title="No shop available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STACKABLE',
      dataIndex: 'stackable',
      width: '15%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => (
        <Tooltip placement="top" title={`Stackable: ${record && record.stackable ? 'Yes' : 'No'}`}>
          <span style={{ cursor: 'pointer' }}>
            {record && record.stackable ? 'Yes' : 'No'}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: '',
      width: '10%',
      align: 'center',
      render: (text, record) => (
        <span>
          <Tooltip placement="top" title="Edit Discounts">
            <SpButton
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedCouponShopEvent(record);
                setExistingDiscount(true);
                setSelectedShop(record.shop);
                shopRelatedInventory(record.shop.gid);
                setApplyDiscount(true);
              }}
              ghost
            >
              <Icon
                type="edit"
                style={{ fontSize: '20px', cursor: 'pointer' }}
              />
            </SpButton>
          </Tooltip>
        </span>
      ),
    },
  ];

  const eventColumn = [
    {
      title: 'EVENT',
      dataIndex: 'events',
      width: '25%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.eventPageGid) {
          const eventPage = allEventPages.find((event) => event.gid === record.eventPageGid);
          return (
            <Tooltip placement="top" title={`Event: ${eventPage.title}`}>
              <span style={{ cursor: 'pointer' }}>
                {eventPage.title}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="No Event available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'category',
      width: '25%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.categories) {
          return (
            <>
              {
              record.categories.map((item) => (
                <Tooltip placement="top" title={`Category: ${item.name}`}>
                  <span style={{ cursor: 'pointer' }}>
                    {item.name},&nbsp;
                  </span>
                </Tooltip>
              ))
            }
            </>

          );
        }
        return (
          <Tooltip placement="top" title="No Event available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'ITEM',
      dataIndex: 'item',
      width: '25%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.items) {
          return (
            <>
              {
              record.items.map((item) => (
                <Tooltip placement="top" title={`Item: ${item.name}`}>
                  <span style={{ cursor: 'pointer' }}>
                    {item.name},&nbsp;
                  </span>
                </Tooltip>
              ))
            }
            </>

          );
        }
        return (
          <Tooltip placement="top" title="No Event available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STACKABLE',
      dataIndex: 'stackable',
      width: '15%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => (
        <Tooltip placement="top" title={`Stackable: ${record && record.stackable ? 'Yes' : 'No'}`}>
          <span style={{ cursor: 'pointer' }}>
            {record && record.stackable ? 'Yes' : 'No'}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: '',
      width: '10%',
      align: 'center',
      render: (text, record) => (
        <span>
          <Tooltip placement="top" title="Edit Discounts">
            <Icon
              type="edit"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => {
                setSelectedCouponShopEvent(record);
                setExistingDiscount(true);
                setSelectedEventPage(allEventPages.find((event) => event.gid === record.eventPageGid));
                eventPageRelatedInventory(record.eventPageGid);
                setApplyDiscount(true);
              }}
              ghost
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const menuColumn = [
    {
      title: `All Menus (${selectedRowKeys.length}/ ${allMenus.length})`,
      dataIndex: 'menus',
      width: '100%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record?.inventoryMenu?.name) {
          return (
            <Tooltip placement="top" title={`Menu: ${record.inventoryMenu.name}`}>
              <span style={{ cursor: 'pointer' }}>{record.inventoryMenu.name}</span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="No menu available">
            <span style={{ cursor: 'pointer' }}>&#8211;</span>
          </Tooltip>
        );
      },
    },
  ];

  const categoryColumn = [
    {
      title: `All Categories (${selectedRowKeys.length}/ ${current === 'shop' ? allCategories.length : eventPageCategories.length})`,
      dataIndex: 'categories',
      width: '100%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.name) {
          return (
            <Tooltip placement="top" title={`Category: ${record.name}`}>
              <span style={{ cursor: 'pointer' }}>
                {record.name}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="No category available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const itemsColumn = [
    {
      title: `All Items (${selectedRowKeys.length}/ ${current === 'shop' ? allItems.length : eventPageItems.length})`,
      dataIndex: 'item',
      width: '100%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.name) {
          return (
            <Tooltip placement="top" title={`Item: ${record.name}`}>
              <span style={{ cursor: 'pointer' }}>
                {record.name}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="No item available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        couponDetails.gid && couponDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="text-uppercase mr-4" fontSize="20px">
                    {couponDetails.name}
                  </SpText>
                </Col>
                <RBAC
                  permit={
                    [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                      ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                  }
                >
                  <Col>
                    <SpButton
                      type="danger"
                      shape="round"
                      onClick={() => setDeleteCouponModal(true)}
                      disabled={!disabled}
                    >
                      Deactivate Coupon
                    </SpButton>
                  </Col>
                </RBAC>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={5}>
                    <span>Status</span>
                  </Col>
                  <Col span={6}>
                    <SpStatusTag style={{ backgroundColor: getCouponBackground(status) }}>{status}</SpStatusTag>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                {
                  couponDetails.percentageOff ? (
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Percentage discount</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>{percentage}%</SpH5>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )
                }
              </SpForm>
              <SpForm>
                {
                  couponDetails.amountOff ? (
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Amount discount</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>{currencyType.prefix} {formatAmount((amount / 100), currencyType)}</SpH5>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )
                }
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={5}>
                    <span>Duration</span>
                  </Col>
                  {
                    couponDetails && couponDetails.validity === COUPON_DURATION[2].value ? (
                      <Col span={6}>
                        <SpH5>{couponDetails.validiyInMonths} months</SpH5>
                      </Col>
                    ) : (
                      <Col span={6}>
                        <SpH5>{couponDetails.validity}</SpH5>
                      </Col>
                    )
                  }
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={12}>
                    <Col span={10}>
                      <span>Created on</span>
                    </Col>
                    <Col span={12}>
                      {
                        couponDetails && couponDetails.createdAt ? (
                          <SpH5>{
                            moment
                              .utc(couponDetails && couponDetails.createdAt)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY, hh:mm a')
                          }
                          </SpH5>
                        ) : (
                          <SpH5>&#8211;</SpH5>
                        )
                      }
                    </Col>
                  </Col>
                  <Col span={12} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{couponDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row>
                  <Col>
                    <RBAC
                      permit={
                        [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                          ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                      }
                    >
                      <SpButton
                        className="mr-2"
                        type="secondary"
                        shape="round"
                        onClick={() => seteditCouponModal(true)}
                      >
                        Update Details
                      </SpButton>
                    </RBAC>
                  </Col>
                </Row>
              </SpForm>
              {/* DELETE COUPON MODAL */}
              {deleteCouponModal && (
                <DeleteSelectedCoupon
                  visible={deleteCouponModal}
                  couponDetails={couponDetails}
                  submitting={submitting}
                  close={() => setDeleteCouponModal(false)}
                  submit={deleteSelectedCoupons}
                />
              )}
              {/* EDIT COUPON MODAL */}
              {editCouponModal && (
                <EditCoupon
                  visible={editCouponModal}
                  couponDetails={couponDetails}
                  close={() => seteditCouponModal(false)}
                  submit={editSelectedCoupons}
                />
              )}
              {/* ADD PROMOTIONAL CODE MODAL */}
              {addPromoCodeModal && (
                <AddPromotionalCode
                  visible={addPromoCodeModal}
                  couponDetails={couponDetails}
                  close={() => setAddPromoCodeModal(false)}
                  submit={addPromotionalCode}
                  selectedAccount={selectedAccount}
                  fetchCustomer={fetchCustomer}
                  allCustomers={allCustomers}
                />
              )}
              {/* DELETE COUPON MODAL */}
              {deletePromoModal && (
                <DeletePromotionalCode
                  visible={deletePromoModal}
                  selectedPromoCode={selectedPromoCode}
                  submitting={submitting}
                  close={() => setDeletePromoModal(false)}
                  submit={deleteSelectedPromo}
                />
              )}
            </Card>
            <Card className="pl-2 mb-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">PROMOTIONAL CODES</SpText>
                </Col>
                <Col>
                  <RBAC
                    permit={
                      [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                        ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                    }
                  >
                    <SpButton
                      className="mr-2"
                      type="secondary"
                      shape="round"
                      onClick={() => setAddPromoCodeModal(true)}
                    >
                      {'\u002B'}&nbsp;Add Promotional Code
                    </SpButton>
                  </RBAC>
                </Col>
              </Row>
              <Table
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={sortPromotionalCodes(promotionals)}
                // pagination={false}
              />
            </Card>
            <Card className="pl-2 mb-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">MANAGE DISCOUNTS</SpText>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" className="my-2" style={{ borderBottom: '2px solid #ddd' }}>
                <Col>
                  <Menu
                    selectedKeys={[current]}
                    mode="horizontal"
                    style={{ fontSize: '10px', fontWeight: '600' }}
                  >
                    <Menu.Item key="shop" className="pl-2">
                      <SpTab
                        to={`${path}`}
                        selected={current === 'shop'}
                        onClick={() => updateCurrentTab('shop')}
                      >
                        Shop
                      </SpTab>
                    </Menu.Item>
                    <Menu.Item key="event">
                      <SpTab
                        to={`${path}`}
                        selected={current === 'event'}
                        onClick={() => updateCurrentTab('event')}
                      >
                        Event
                      </SpTab>
                    </Menu.Item>
                  </Menu>
                </Col>
                <Col>
                  {!applyDiscount && (
                  <RBAC
                    permit={
                            [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                              ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                          }
                  >
                    <SpButton
                      className="mr-2"
                      type="secondary"
                      shape="round"
                      onClick={() => setApplyDiscount(true)}
                    >
                      {'\u002B'}&nbsp;Apply Discount
                    </SpButton>
                  </RBAC>
                  )}
                  {applyDiscount
                      && existingDiscount
                      && (
                      <div style={{ display: 'grid' }}>
                        <RBAC
                          permit={
                                  [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                                    ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                                }
                        >
                          <SpButton
                            className="mr-2"
                            type="dangerSecondary"
                            shape="round"
                            onClick={() => {
                              setSelectedCouponShopEvent({
                                stackable: false,
                              });
                              setExistingDiscount(false);
                              setSelectedShop(null);
                              setSelectedEventPage(null);
                              shopRelatedInventory(null);
                              setSearchText('');
                              setApplyDiscount(false);
                              removeDiscount();
                            }}
                          >
                            Remove Discount
                          </SpButton>
                          <span style={{ fontSize: '12px', fontWeight: '600' }}>
                            Remove All Applied Discounts from {current === 'shop' ? 'Shop' : 'Event'}
                          </span>
                        </RBAC>
                      </div>
                      )}
                </Col>
              </Row>
              {
                    applyDiscount ? (
                      <>
                        {current === 'shop' ? (
                          <Select
                            className="mr-4 mt-2"
                            value={(selectedShop && selectedShop.name) || 'selectShop'}
                            style={{ width: '350px' }}
                            bordered
                            onChange={(value) => updateSelectedShop(value)}
                          >
                            <Option key={1} value="selectShop">Select Shop</Option>
                            {
                                allShops.map((option) => (
                                  <Option
                                    key={option.gid}
                                    value={[option.gid, option]}
                                  >
                                    {option.name}
                                  </Option>
                                ))
                              }
                          </Select>
                        ) : (
                          <Select
                            className="mr-4 mt-2"
                            value={(selectedEventPage && selectedEventPage.title) || 'selectEvent'}
                            style={{ width: '350px' }}
                            bordered
                            onChange={(value) => updateSelectedEventPage(value)}
                          >
                            <Option key={1} value="selectEvent">Select Event</Option>
                            {
                                allEventPages.map((option) => (
                                  <Option
                                    key={option.gid}
                                    value={[option.gid, option]}
                                  >
                                    {option.title}
                                  </Option>
                                ))
                              }
                          </Select>
                        )}
                        {selectedShop || selectedEventPage ? (
                          <>
                            <Row type="flex" justify="space-between" className="my-2" style={{ borderBottom: '2px solid #ddd' }}>
                              <Col className="mr-4 mt-2">
                                <Menu
                                  onClick={e => setCurrentCoupon(e.key)}
                                  selectedKeys={[currentCoupon]}
                                  mode="horizontal"
                                  style={{ fontSize: '15px' }}
                                >
                                  {current === 'shop'
                                      && (
                                      <Menu.Item key="menu" className="pl-2">
                                        <SpTab
                                          to={`${path}`}
                                          selected={currentCoupon === 'menu'}
                                          onClick={() => updateCurrentCouponTab('menu')}
                                          fontSize="16px"
                                        >
                                          MENU
                                        </SpTab>
                                      </Menu.Item>
                                      )}
                                  <Menu.Item key="category">
                                    <SpTab
                                      to={`${path}`}
                                      selected={currentCoupon === 'category'}
                                      onClick={() => updateCurrentCouponTab('category')}
                                      fontSize="16px"
                                    >
                                      CATEGORY
                                    </SpTab>
                                  </Menu.Item>
                                  <Menu.Item key="item">
                                    <SpTab
                                      to={`${path}`}
                                      selected={currentCoupon === 'item'}
                                      onClick={() => updateCurrentCouponTab('item')}
                                      fontSize="16px"
                                    >
                                      ITEM
                                    </SpTab>
                                  </Menu.Item>
                                </Menu>
                              </Col>
                              <Col className="mr-4 mt-2 d-flex align-items-center">
                                <Switch
                                  checked={selectedCouponShopEvent.stackable}
                                  onChange={(e) => handleSwitchChange(e)}
                                />
                                <Tooltip title="Allows promoCode to be applied on top of discount">
                                  <span style={{ fontSize: '20px', marginLeft: '5px' }}>STACKABLE</span>
                                </Tooltip>
                              </Col>
                            </Row>
                            <Input
                              placeholder="Search..."
                              value={searchText}
                              onChange={handleSearch}
                              style={{ marginBottom: 16, width: '100%' }}
                            />
                            {
                                currentCoupon === 'menu' ? (
                                  <Table
                                    rowClassName={() => 'ant-table-clickable-row'}
                                    className="custom-table"
                                    columns={menuColumn}
                                    dataSource={filterDataSource(allMenus)}
                                    rowSelection={{
                                      selectedRowKeys,
                                      onChange: handleSelectChange,
                                    }}
                                    rowKey={(record) => record.inventoryMenu.gid}
                                    pagination={false}
                                    scroll={{ y: allMenus.length > 5 ? 250 : 'auto' }}
                                  />
                                ) : currentCoupon === 'category' ? (
                                  <Table
                                    rowClassName={() => 'ant-table-clickable-row'}
                                    columns={categoryColumn}
                                    rowSelection={{
                                      selectedRowKeys,
                                      onChange: handleSelectChange,
                                    }}
                                    rowKey={(record) => record.gid}
                                    className="custom-table"
                                    pagination={false}
                                    dataSource={filterDataSource(current === 'shop' ? allCategories : eventPageCategories)}
                                    scroll={{ y: (current === 'shop' ? allCategories.length : eventPageCategories.length) > 5 ? 250 : 'auto' }}
                                  />
                                ) : (
                                  <Table
                                    rowClassName={() => 'ant-table-clickable-row'}
                                    columns={itemsColumn}
                                    rowSelection={{
                                      selectedRowKeys,
                                      onChange: handleSelectChange,
                                    }}
                                    rowKey={(record) => record.gid}
                                    className="custom-table"
                                    pagination={false}
                                    dataSource={filterDataSource(current === 'shop' ? allItems : eventPageItems)}
                                    scroll={{ y: (current === 'shop' ? allItems.length : eventPageItems.length) > 5 ? 250 : 'auto' }}
                                  />
                                )
                              }
                            <Row type="flex" justify="end" className="my-2">
                              <Col style={{ align: 'right' }}>
                                <SpButton
                                  className="mr-2"
                                  type="secondary"
                                  shape="round"
                                  onClick={() => {
                                    setSelectedCouponShopEvent({
                                      stackable: false,
                                    });
                                    setExistingDiscount(false);
                                    setSelectedShop(null);
                                    setSelectedEventPage(null);
                                    shopRelatedInventory(null);
                                    setSearchText('');
                                    setApplyDiscount(false);
                                  }}
                                >
                                  Cancel
                                </SpButton>
                                <SpButton
                                  className="ml-2"
                                  type="primary"
                                  shape="round"
                                  onClick={() => {
                                    setSelectedCouponShopEvent({
                                      stackable: false,
                                    });
                                    setExistingDiscount(false);
                                    setSelectedShop(null);
                                    setSelectedEventPage(null);
                                    shopRelatedInventory(null);
                                    setSearchText('');
                                    setApplyDiscount(false);
                                    updateAppliedDiscount();
                                  }}
                                >
                                  Save
                                </SpButton>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <Row type="flex" justify="end" className="my-2">
                            <Col style={{ align: 'right' }}>
                              <SpButton
                                className="mr-2"
                                type="secondary"
                                shape="round"
                                onClick={() => {
                                  setSelectedCouponShopEvent({
                                    stackable: false,
                                  });
                                  setExistingDiscount(false);
                                  setSelectedShop(null);
                                  setSelectedEventPage(null);
                                  shopRelatedInventory(null);
                                  setSearchText('');
                                  setApplyDiscount(false);
                                }}
                              >
                                Cancel
                              </SpButton>
                            </Col>
                          </Row>
                        )}
                      </>
                    ) : (
                      current === 'shop' ? (
                        <Table
                          rowClassName={() => 'ant-table-clickable-row'}
                          columns={shopColumn}
                          rowKey={(record) => record.shop && record.shop.gid && record.shop.gid}
                          className="custom-table"
                          dataSource={couponShop}
                          pagination={false}
                          scroll={{ y: couponShop.length > 5 ? 250 : 'auto' }}
                        />
                      ) : (
                        <Table
                          rowClassName={() => 'ant-table-clickable-row'}
                          columns={eventColumn}
                          rowKey={(record) => record.eventPageGid}
                          className="custom-table"
                          dataSource={couponEventPage}
                          pagination={false}
                          scroll={{ y: couponEventPage.length > 5 ? 250 : 'auto' }}
                        />
                      )
                    )
                  }
            </Card>
            <Card className="pl-2 mb-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">REDEMPTIONS</SpText>
                </Col>
              </Row>
              <Table
                columns={redemptionsColumns}
                rowKey={(record) => record.gid}
                dataSource={redemptions}
                // pagination={false}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  couponDetails: state.couponDetails.content,
  couponDetailsStatus: state.couponDetails.couponDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  customers: state.coupons.customers,
  redemptions: state.couponDetails.redemptions,
  promotionals: state.couponDetails.promotionals,
  allShops: state.couponDetails.couponListShops,
  allMenus: state.couponDetails.couponListMenus,
  allCategories: state.couponDetails.couponListCategories,
  allItems: state.couponDetails.couponListItems,
  couponShop: state.couponDetails.couponShop,
  couponMenu: state.couponDetails.couponMenu,
  couponCategory: state.couponDetails.couponCategory,
  allEventPages: state.couponDetails.eventPageList,
  eventPageItems: state.couponDetails.eventPageItems,
  eventPageCategories: state.couponDetails.eventPageCategories,
  couponEventPage: state.couponDetails.couponEventPage,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCouponDetails: gid => dispatch(couponDetailsActions.fetchCouponDetails({
    payload: gid,
  })),
  deleteCoupon: param => dispatch(couponDetailsActions.deleteCoupon({
    payload: param,
  })),
  updateCoupon: param => dispatch(couponDetailsActions.updateCoupon({
    payload: param,
  })),
  fetchCustomers: param => dispatch(couponActions.fetchCustomers({
    payload: param,
  })),
  addPromoCode: param => dispatch(couponDetailsActions.addPromoCode({
    payload: param,
  })),
  deletePromoCode: param => dispatch(couponDetailsActions.deletePromoCode({
    payload: param,
  })),
  /* shop Discount */
  fetchCouponShops: param => dispatch(couponDetailsActions.fetchCouponShops({
    payload: param,
  })),
  fetchCouponMenus: param => dispatch(couponDetailsActions.fetchCouponMenus({
    payload: param,
  })),
  fetchCouponCategories: param => dispatch(couponDetailsActions.fetchCouponCategories({
    payload: param,
  })),
  fetchCouponItems: gid => dispatch(couponDetailsActions.fetchCouponItems({
    payload: gid,
  })),
  fetchCouponShop: gid => dispatch(couponDetailsActions.fetchCouponShop({
    payload: gid,
  })),
  updateCouponShop: param => dispatch(couponDetailsActions.updateCouponShop({
    payload: param,
  })),
  /* event-page Discount */
  fetchAllEventPages: param => dispatch(couponDetailsActions.fetchAllEventPages({
    payload: param,
  })),
  fetchEventPageItems: param => dispatch(couponDetailsActions.fetchEventPageItems({
    payload: param,
  })),
  fetchEventPageCategories: param => dispatch(couponDetailsActions.fetchEventPageCategories({
    payload: param,
  })),
  fetchCouponEventPage: gid => dispatch(couponDetailsActions.fetchCouponEventPage({
    payload: gid,
  })),
  updateCouponEventPage: param => dispatch(couponDetailsActions.updateCouponEventPage({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CouponDetails);
