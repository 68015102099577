import { takeEvery } from 'redux-saga/effects';

import {
  AUTH,
  MEMBERSHIP,
  ROLES,
  ACCOUNT,
  BUSINESS,
  PAYMENTS,
  CUSTOMERS,
  SETTINGS,
  PAYOUTS,
  TRANSACTIONS,
  REPORTS,
  ADMIN_REPORTS,
  TERMINAL,
  LOCATIONS,
  DISPUTES,
  LOCATIONSDETAILS,
  CUSTOMERSDETAILS,
  PUBLICKEYS,
  SECRETKEYS,
  SYMMETRICKEYS,
  PAYOUTSDETAILS,
  SUPPORT,
  PAYMENTSDETAILS,
  USERPROFILE,
  RECEIPTS,
  DISPUTEEVIDENCE,
  DASHBOARD,
  PAYMENTSLINK,
  PAYMENTSLINKDETAILS,
  SUBSCRIPTION,
  SUBSCRIPTIONDETAILS,
  PLANS,
  PLANSDETAILS,
  INVOICES,
  PRODUCT,
  INVOICEDETAILS,
  PAYMENTPAGES,
  PAYMENTPAGESDETAILS,
  REMINDERS,
  PAYMENTSBUTTON,
  PAYMENTSBUTTONDETAILS,
  SUBSCRIPTION_BUTTON,
  SUBSCRIPTION_BUTTONDETAILS,
  WEBHOOKS,
  QUICKBOOKS,
  REFERRAL,
  RATES,
  RATESDETAILS,
  ACQUIRER,
  SETTLEMENT,
  TAXRATE,
  COUPON,
  COUPONDETAILS,
  OFFER,
  OFFERDETAILS,
  AFFILIATE,
  AFFILIATE_TRANSACTIONS,
  AFFILIATE_EARNINGS,
  QUICKBOOKSDETAILS,
  SALESFORCE,
  SCHEDULE_REPORTS,
  SCHEDULE_ADMIN_REPORTS,
  ADMIN_TERMINAL,
  ADMIN_TERMINALDETAILS,
  SPFIELD, SP_OBJECT,
  POSKEY,
  GLOBAL_RESTRICTED_KEY,
  PAYMENTWIDGETS,
  PAYMENTWIDGETSDETAILS,
  ISSUER,
  ISSUER_RATES,
  EXCHANGE_RATES,
  ISSUER_RATESDETAILS,
  CASH_TRANSACTIONS,
  CHEQUE_TRANSACTIONS,
  CASH_TRANSACTIONS_DETAILS,
  CHEQUE_TRANSACTIONS_DETAILS,
  CASH_RECEIPTS,
  CHEQUE_RECEIPTS,
  INTERNAL_MEMBERSHIP,
  TERMINALDETAILS,
  SERVICES,
  SERVICEDETAILS,
  DOCUMENTS,
  TRANSFERS,
  ADMIN_BIN,
  PAGE_RECEIPTS,
  VENDORS,
  ACH_PAYMENT,
  VENDORSDETAILS,
  TRANSFERSDETAILS,
  FUNDINGSOURCE,
  FUNDINGSOURCE_LINKS,
  TRANSFERLINKS,
  BULKTRANSFER,
  BULKTRANSFER_DETAILS,
  BANK_TRANSFERS,
  TRANSFER_RECEIPTS,
  PAYMENTCHECKOUT,
  PAYMENTCHECKOUTDETAILS,
  CUSTOM_DOCUMENT_REQUEST,
  CUSTOM_DOCUMENT_REQUEST_DETAILS,
  ACCOUNT_SETLLEMENT,
  PARTNER_SETLLEMENT,
  OCR_REQUEST,
  BANK_STATEMENT,
  BANK_STATEMENT_DETAILS,
  ACCOUNT_SLUG,
  ACCOUNT_SLUG_DETAILS,
  WALLET,
  WALLET_TRANSACTION,
  WALLET_TRANSACTION_DETAILS,
  WALLET_DETAILS,
  TRANSFER_SETTINGS,
  REWARD_SETTINGS,
  STATIC_QR,
  STATIC_QR_DETAILS,
  REPORT_TEMPLATE,
  REPORT_TEMPLATE_DETAILS,
  CUSTOM_REPORT,
  RECAPTCHA_DETAILS,
  USONBOARDING,
  VENDORINVOICES,
  VENDORINVOICEDETAILS,
  BENEFICIARY,
  BENEFICIARYDETAILS,
  WALLETINVOICES,
  WALLETINVOICEDETAILS,
  WOOCOMMERCE,
  WOOCOMMERCE_DETAILS,
  CLOVER_RECEIPTS,
  ORDER_RECEIPTS,
  CATALOG,
  CATALOG_ORDERS,
  ADMIN_CATALOG,
  ADMIN_ORDERS,
  INVENTORY_ORDERS,
  INVENTORY_ITEMS,
  INVENTORY_CATEGORIES,
  INVENTORY_MODIFIERS,
  INVENTORY_ORDERS_DETAILS,
  INVENTORY_ITEMS_DETAILS,
  INVENTORY_CATEGORIES_DETAILS,
  INVENTORY_MODIFIERS_DETAILS,
  EVENTS,
  EVENTSDETAILS,
  INVENTORY_ORDERTYPES,
  PRINTER_LABEL,
  PRINTER_LABEL_DETAILS,
  SHOPS,
  SHOPS_DETAILS,
  CLOVER_WEBHOOK,
  QUICKSUPPORT,
  SUBSCRIPTIONV2,
  SUBSCRIPTIONV2DETAILS,
  PLANV2,
  PLANV2DETAILS,
  SUBSCRIPTION_BUTTONV2,
  SUBSCRIPTION_BUTTONV2DETAILS,
  GETSHOPDETAILS,
  DINING,
  CUSTOM_DASHBOARD,
  CLOVER_SYNC,
  INVENTORY_RESERVATIONS,
  INVENTORY_RESERVATIONS_DETAILS,
  CUSTOMER_SUPPORT,
  SERVICERATE,
  QUICK_SUPPORT_SUMMARY,
  TABLE_DETAILS,
  MENUS,
  OPERATINGHOURS,
  INVENTORY_PREORDERS,
  SEQUENCE_GENERATORS,
  FEEDBACK,
  ADVANCEFEES,
  MANAGE_ACCOUNTS,
  ACCOUNT_SESSION,
  DONATION_PAGES,
  DONATION_DETAILS_PAGES,
  EVENT_PAGES,
  EVENT_PAGE_DETAILS,
} from '../actionTypes';

import { authenticateUser } from './auth';
import {
  getMembership,
  updateMembership,
  deleteMembership,
  addMembership,
} from './membership';

import {
  getRoles,
  getInternalRoles,
} from './roles';

import {
  getUserAccount,
  getUserAccountById,
  filterUserAccount,
  updateUserAccount,
  addNewAccount,
  getUserAccountByInternalId,
  updateInternalUserAccount,
} from './account';

import {
  getBusinessByAccountId,
  addBusiness,
  editBusiness,
  getDocusignStatus,
  getBusinessCategory,
  addBusinessSignUp,
  getBusinessDraftByAccountId,
  addDraftBusiness,
} from './business';

import {
  getCustomers,
  updateCustomer,
  deleteCustomers,
  addCustomers,
} from './customers';

import {
  getPayments,
  refundPayments,
  updatePayment,
} from './payments';

import { updateAccountInfo, allowCrossTransfer } from './settings';
import { getPayouts, updatePayout } from './payouts';
import { getTransactions } from './transactions';
import {
  getReports,
  addReports,
} from './reports';
import {
  getAdminReports,
  addAdminReports,
} from './adminreports';
import {
  getTerminals,
  updateTerminal,
  fetchLocationsList,
} from './terminal';
import {
  getLocations,
  addLocation,
  updateLocation,
} from './locations';

import { getDisputes } from './disputes';

import {
  getLocationDetails,
  getTerminalDetails,
  updateTerminals,
  removeTerminal,
} from './locationDetails';

import {
  getCustomerDetails,
  updateCustomers,
  getCardDetails,
  getCustomerPayments,
} from './customerDetails';

import {
  getPublickeys,
  updatePublickey,
} from './publickeys';

import {
  getSecretkeys,
  updateSecretkey,
} from './secretkeys';

import {
  getSymmetrickey,
} from './symmetrickeys';

import {
  getPayoutDetails,
  getPayoutTransactions,
} from './payoutDetails';

import {
  getSupport,
  addSupport,
  updateSupport,
} from './support';

import {
  getPaymentDetails,
  getPaymentTimeline,
  refundPaymentDetails,
  getNotifyDetails,
  acceptEvidenceDetails,
  resolveEvidenceDetails,
  raiseDisputeDetails,
  completeRefund,
  getPaymentDisputeDetails,
  getNotifyPaymentPageDetails,
  getNotifyEventPageOrderDetails,
} from './paymentDetails';

import {
  getUserProfile,
  updateUserProfile,
} from './userProfile';

import {
  getReceipts,
} from './receipts';

import {
  submitDisputeEvidence,
} from './disputeEvidence';

import * as dashboard from './dashboard';

import {
  getPaymentsLink,
  addPaymentsLink,
  customerAdd,
} from './paymentsLink';

import {
  getPaymentsLinkDetails,
  getPaymentsList,
  updatePaymentsLink,
} from './paymentsLinkDetails';

import {
  getSubscription,
  addSubscription,
  getPlanSearch,
  getCustomerSearch,
  getProductSearch,
  getSearchProduct,
  getProfile,
} from './subscription';

import {
  getSubscriptionDetails,
  getSubscriptionPayments,
} from './subscriptionDetails';

import {
  getPlans,
  addPlans,
} from './plans';

import {
  getPlansDetails,
  getPlansPayments,
} from './plansDetails';
import {
  getInvoices,
} from './invoices';

import {
  getProducts,
  addProduct,
  updateProduct,
  deleteProduct,
} from './products';

import {
  getInvoiceDetails,
  updateInvoice,
  fetchCustomersList,
  addNewInvoice,
  addCustomer,
  deleteInvoice,
  fetchProductsList,
  addNewProcut,
  fetchCouponsList,
  fetchItemCouponsList,
  addNewCoupon,
  fetchTaxrates,
  addTaxrates,
  getTax,
  notifyInvoice,
  cancelInvoices,
  refundInvoices,
} from './invoiceDetails';

import {
  getPaymentPages,
  addPaymentPages,
} from './paymentPages';

import {
  getPaymentPagesDetails,
  getPaymentPagesList,
  getPaymentPageSlug,
  deactivePaymentpage,
  getNotifyPaymentPage,
  updatePaymentPages,
  soldOutPaymentPage,
  resumeSalePaymentPage,
  getPaymentOrdersList,
  getPaymentOrderDetails,
  getPaymentOrderTimeline,
  updateOrderStatus,
  updateCashPayment,
  getOfflinePaymentsList,
  deleteOfflinePayment,
  getPaymentPageReports,
  getPaymentPagesEntries,
} from './paymentPagesDetails';

import {
  getReminders,
  sendReminders,
} from './reminders';

import {
  getPaymentsButton,
  addPaymentsButton,
} from './paymentsButton';

import {
  getPaymentsButtonDetails,
  getPaymentsButtonList,
  deactivePayments,
  updatePaymentsButton,
} from './paymentsButtonDetails';

import {
  getSubscriptionButton,
  addSubscriptionButton,
} from './subscriptionButton';

import {
  getSubscriptionButtonDetails,
  getSubscriptionButtonList,
  deactiveSubscription,
  updateSubscriptionButton,
} from './subscriptionButtonDetails';

import {
  getWebhooks,
  updateWebhook,
  deleteWebhooks,
  addWebhook,
  getWebhookEvents,
} from './webhooks';

import {
  getQuickBooks,
  addQuickBooks,
} from './quickbooks';
import {
  getReferral,
  addReferral,
  notifyReferral,
} from './referral';

import {
  getRates,
  addRate,
  getRateAcquirer,
  getRateCategory,
  getPaymentType,
} from './rates';

import {
  getIssuerRates,
  addIssuerRate,
  getTransferType,
  deleteIssuer,
} from './issuerrate';

import {
  addExchangeRate,
  getExchangeRatesList,
  deleteExchangeRate,
  updateExchangeRate,
  getExchangeRateForAmount,
} from './exchangeRate';

import {
  getRatesDetails,
  deleteRate,
} from './ratesDetails';

import {
  getIssuerRatesDetails,
} from './issuerrateDetails';

import {
  getAcquirer,
  addAcquirer,
  deleteAcquirer,
  updateAcquirer,
  getAcquirerSearch,
} from './acquirer';

import {
  addImsSettlement,
  addStripeSettlement,
  addAffinipaySettlement,
  getCurrency,
  getSettlementRun,
} from './settlement';
import {
  getTaxrates,
  addTaxrate,
  deleteTaxrate,
  getTaxes,
} from './taxRates';

import {
  getCoupons,
  addCoupons,
  getCustomer,
} from './coupons';

import {
  getCouponDetails,
  updateCoupons,
  deleteCoupon,
  addPromoCode,
  deletePromoCode,
  getRedemptions,
  getShopDetails,
  getMenus,
  getCategories,
  getCouponShop,
  getCouponMenu,
  getCouponCategory,
  updateCouponShop,
  updateCouponMenu,
  updateCouponCategory,
  getItems,
  getAllEventPages,
  getCouponEventPage,
  updateCouponEventPage,
  getEventPageItems,
  getEventPageCategories,
} from './couponDetails';

import {
  getOffers,
  addOffer,
} from './offers.js';

import {
  getOfferDetails,
  updateOffer,
  deleteOffer,
  getOfferRedemptions,
} from './offerDetails';


import {
  getAffiliate,
  addAffiliate,
  notifyAffiliate,
} from './affiliate';

import {
  getAffiliateTransactions,
} from './affiliateTransactions';

import {
  getAffiliateEarnings,
} from './affiliateEarnings';

import {
  getQuickBookDetails,
} from './quickbookDetails';

import {
  getSalesforce,
  addSalesforce,
  getSpObject,
  updateCredentials,
  getSpField,
  addSalesforceMapping,
  getMappingList,
  getMappingDetails,
  updateSalesforceMapping,
  deleteSalesforceMapping,
} from './salesforce';

import {
  getScheduleReports,
  addScheduleReport,
  deleteScheduleReports,
} from './scheduleReports';

import {
  getAdminScheduleReports,
  addAdminScheduleReports,
  deleteAdminScheduleReports,
} from './adminschedulereports';

import {
  getAdminTerminal,
  addAdminTerminal,
  updateAdminTerminal,
} from './adminTerminal';

import {
  getAdminTerminalDetails,
  deleteAdminTerminal,
  updateTerminalPin,
} from './adminTerminalDetails';

import {
  fetchSpFields,
  addSpField,
  updateSpField,
  deleteSpField,
} from './spField';

import {
  fetchSpObjects,
  addSpObject,
  updateSpObject,
  deleteSpObject,
  fetchAllSpObject,
} from './spObject';

import {
  getPoskey,
  updatePoskey,
} from './poskey';

import {
  getGlobalRestrictedKey,
  addGlobalRestrictedKey,
  updateGlobalRestrictedKey,
  deleteGlobalRestrictedKey,
} from './globalRestrictedKey';

import {
  getPaymentWidget,
  addPaymentWidget,
} from './paymentWidgets';

import {
  getPaymentWidgetDetails,
  getWidgetPayments,
  getWidgetSubscriptions,
  deactivePayment,
  updatePaymentWidget,
  getPaymentWidgetSlug,
} from './paymentWidgetsDetails';

import {
  getIssuer,
  addIssuer,
} from './issuer';

import { getCashTransactions } from './cashtransactions';

import { getChequeTransactions } from './chequetransactions';

import {
  getCashTransactionDetails,
  getNotifyCashTransactionDetails,
  voidPayment,
  refundCashPayment,
} from './cashtransactionsDetails';

import {
  getChequeTransactionDetails,
  getNotifyChequeTransactionDetails,
  voidChequePayment,
} from './chequetransactionsDetails';

import {
  getCashReceipts,
} from './cashreceipts';

import {
  getChequeReceipts,
} from './chequereceipts';

import {
  getInternalMembership,
  addInternalMembership,
  updateInternalMembership,
  deleteInternalMembership,
} from './internalMembership';

import {
  getTerminalsDetail,
  getTerminalPayments,
  updateTerminalDetails,
  updateTerminalProof,
} from './terminalDetails';

import {
  getServices,
} from './services';

import {
  getServiceDetails,
  updateServiceDetails,
  getServiceTimeLine,
  getFieldAgent,
} from './serviceDetails';

import {
  getDocuments,
  addDocument,
} from './documents';

import {
  getTransfers,
  fetchContactList,
  issueTransfer,
  getFundingSource,
  searchFundingSourceList,
  confirmTransferOtp,
  updateTransfers,
  updateCrossBorder,
  cancelTransferInDetails,
  cancelTransfers,
} from './transfers';

import {
  getAdminBin,
  addAdminBin,
  updateAdminBin,
} from './adminBin';

import {
  getPageReceipts,
} from './pageRecipts';

import {
  getVendors,
  updateVendor,
  deleteVendor,
  addVendor,
  getCustomTypes,
  deleteSingleCustomType,
  deleteAllCustomTypes,
  multiSendFile,
} from './vendors';

import {
  addAchCustomer,
  fetchBeneficiars,
  updateBenficiaryStatus,
} from './achPayments';

import {
  getVendorDetails,
  getTransfersList,
  DeleteVendor,
} from './vendorsDetails';

import {
  getTransferDetails,
  getTransferTimeline,
  getTransferNotifyDetails,
  getTransferOtp,
} from './transfersDetails';

import {
  getFundingSourceList,
  createFundingSource,
  getFundingSourceListByContactGid,
  getFundingSourceDetails,
  deleteFundingSource,

} from './fundingSource';

import {
  getFundingSourceLink,
  createFundingSourceLink,
} from './fundingSourceLinks';

import {
  getTransferLinks,
  createTransferLinks,
  confirmTransferLinkOtp,
} from './transferlinks';

import {
  getBulkTransfer,
  addBulkTransfer,
  getBulkTransferSearch,
} from './bulkTransfer';

import {
  getBulkTransferDetails,
  getBulkTransfersList,
} from './bulkTransferDetails';

import {
  addIcicCustomer,
  accountById,
  createIciciFundingSource,
  createPrimaryContact,
  deleteContact,
  checkBalanceById,
} from './bankTransfers';

import {
  getTransferReceipts,
} from './transferReceipts';

import {
  getPaymentCheckout,
  addPaymentCheckout,
} from './paymentCheckout';

import {
  getPaymentCheckoutDetails,
  getPaymentCheckoutList,
} from './paymentCheckoutDetails';

import {
  getCustomDocumentRequest,
  addCustomDocumentRequest,
  updateCustomDocumentRequest,
} from './customDocumentRequest';

import {
  getCustomDocumentRequestDetails,
  deleteCustomDocumentRequest,
} from './customDocumentRequestDetails';

import {
  getAccountSettlement,
  addAccountSettlement,
  deleteAccountSettlement,
} from './accountSettlement';

import {
  getPartnerSettlement,
  addPartnerSettlement,
  deletePartnerSettlement,
} from './partnerSettlement';

import {
  getOcrRequest,
  addOcrRequest,
} from './ocrRequest';

import {
  getBankStatement,
  addBankStatement,
} from './bankStatement';

import {
  getAccountReceivables,
  getAccountPayables,
} from './bankStatementDetails';

import {
  getAccountSlug,
  addAccountSlug,
  linkAccountSlug,
  unLinkAccountSlug,
} from './accountSlug';

import {
  getAccountSlugDetails,
} from './accountSlugDetails';

import {
  getWallet,
  addWallet,
  addConfirmWallet,
  fetchTransactions,
  pinSetUp,
  verifyPin,
} from './wallet';

import {
  getWalletTransaction,
} from './walletTransaction';

import {
  getWalletTransactionDetails,
  applyCashBack,
} from './walletTransactionDetails';
import {
  getWalletDetails,
  addNewBankAccount,
  loadBalance,
  addNewCard,
  getDebitCards,
  withdrawAmount,
  deleteCard,
  updateCard,
} from './walletDetails';
import {
  getTransferSetting,
  addTransferSetting,
  updateTransferSetting,
  enableLiveTransfer,
} from './transferSettings';
import {
  getRewardSettings,
  addRewardSettings,
  updateRewardSettings,
} from './rewardSettings';
import {
  getStaticQR,
  addStaticQR,
  addBulkStaticQR,
} from './staticqr';
import {
  getStaticQRDetails,
  deleteStaticQR,
  updateStaticQR,
} from './staticqrDetails';
import {
  getReportTemplate,
  addReportTemplate,
} from './reportTemplates';
import {
  getReportTemplateDetails,
  updateReportTemplate,
  deleteReportTemplate,
} from './reportTemplatesDetails';
import {
  getCustomReports,
  addCustomReport,
} from './customReports';
import {
  getRecaptchaDetails,
} from './recaptchaDetails';
import {
  getOnboardingLinks,
  addOnboardingLink,
} from './usonboarding';

import {
  getVendorInvoices,
} from './vendorInvoices';

import {
  getVendorInvoiceDetails,
  updateVendorInvoice,
  fetchVendorContactList,
  addNewVendorInvoice,
  addContact,
  deleteVendorInvoice,
  notifyVendorInvoice,
  cancelVendorInvoices,
  payVendorInvoice,
} from './vendorInvoiceDetails';

import {
  getBeneficiary,
  addBeneficiary,
  updateBeneficiary,
  deleteBeneficiary,
} from './beneficiary';

import {
  getBeneficiaryDetails,
} from './beneficiaryDetails';

import {
  getWalletInvoices,
} from './walletInvoices';

import {
  getWalletInvoiceDetails,
  updateWalletInvoice,
  fetchWalletBeneficiaryList,
  addNewWalletInvoice,
  addWalletBeneficiary,
  deleteWalletInvoice,
  notifyWalletInvoice,
  cancelWalletInvoices,
  payWalletInvoice,
} from './walletInvoiceDetails';

import {
  getWoocommerce,
  addWoocommerce,
  updateWoocommerce,
  getWoocommerceList,
} from './woocommerce';

import {
  getWooCommerceOrderDetails,
  getWooCommerceOrderTimeline,
  updateWooCommerceOrderStatus,
} from './wooCommerceDetails';

import {
  getCloverReceipts,
} from './cloverReceipts';

import { getOrderReceipt } from './orderReceipt';

import {
  getCatalogs,
  orderCatalog,
} from './catalog';

import {
  getCatalogOrders,
} from './catalogOrders';

import {
  getAdminCatalogs,
  deleteCatalogs,
  addCatalog,
  updateCatalog,
} from './adminCatalog';

import {
  getAdminOrders,
  updateAdminOrders,
} from './adminOrders';

import {
  getInventoryOrders,
  getInventoryFilterOrders,
} from './inventoryOrders';

import {
  getInventoryItems,
  getInventorySortItems,
  getInventoryItemsList,
  updateInventoryItems,
  addInventoryItems,
  getInventoryModifierItemsList,
  updateInventorySortItems,
  deleteInventoryItems,
  updateBulkItems,
} from './inventoryItems';

import {
  getInventoryCategories,
  updateInventoryCategory,
  addInventoryCategory,
  getInventorySortCategory,
  updateInventorySortCategory,
  deleteInventoryCategory,
  updateBulkCategories,
  fetchCategoryOperatingHours,
  fetchInventoryCategoriesWithItems,
} from './inventoryCategories';

import {
  getInventoryModifiers,
  updateInventoryModifer,
  addInventoryModifer,
  getInventorySortModifer,
  updateInventorySortModifer,
  deleteInventoryModifer,
} from './inventoryModifiers';

import {
  getInventoryOrderDetails,
  refundOrderDetails,
  deleteOrderLineItem,
  refundOrderLineItem,
  deleteOrderDetails,
  getInventoryPayments,
  addItemOrder,
} from './inventoryOrderDetails';

import {
  getInventoryItemDetails,
} from './inventoryItemDetails';

import {
  getInventoryCategorieDetails,
  getAllShop,
  getInventoryItemByCategory,
  getInventoryOperatingHours,
  addItemCategory,
  getAllItemsByCategory,
  getItemsBySelectedCategory,
} from './inventoryCategorieDetails';

import {
  getInventoryModifierDetails,
  addInventoryModifier,
  deleteInventoryModifier,
  updateInventoryModifier,
} from './inventoryModifierDetails';

import {
  getEvents,
  updateEvents,
  deleteEvents,
  addNewEvents,
  getEventReceipts,
} from './events';

import {
  getEventDetails,
  updateEvent,
  getCardDetail,
  deactivateEvent,
  soldOutEvent,
  resumeSales,
  offlinePayments,
  getEventsSlug,
  getOfflineEventsPaymentsList,
  getNotifySeatedPageDetails,
  getNotifySeatedPage,
} from './eventDetails';

import {
  getInventoryOrderTypes,
  addInventoryOrderTypes,
  deleteInventoryOrderTypes,
  updateInventoryOrderTypes,
} from './inventoryOrderTypes';

import {
  getInventoryPrinterLabel,
  addInventoryPrinterLabel,
  updateInventoryPrinterLabel,
  deleteInventoryPrinterLabel,
  getPrinterLabelList,
} from './inventoryPrinterLabel';

import {
  getInventoryPrinterLabelDetails,
  addItemPrinterLabel,
  getInventoryItemByPrinterLabel,
} from './inventoryPrinterLabelDetails';

import {
  getInventoryShops,
  addInventoryShops,
  updateInventoryShops,
  deleteInventoryShops,
  getInventoryRatesList,
  getInventoryPromosList,
  addInventoryShopAssociate,
  updateInventoryShopAssociate,
  getInventoryShopAssociate,
  deleteInventoryShopAssociate,
} from './inventoryShops';

import {
  getInventoryShopsDetails,
  addItemShops,
  getInventoryItemByShops,
  getInventoryItemNamesByShops,
  getShopsSlug,
  addPromosByShop,
  deletePromosByShop,
  deleteTipsByShop,
  updatePromosByShop,
  getInventoryPromosByShop,
  getInventoryAllPromosByShop,
  addTips,
  getTablesShops,
  // getTipsByShops,
} from './inventoryShopsDetails';

import {
  getCloverWebhook,
  updateCloverWebhook,
  getInternalCloverWebhook,
  updateInternalCloverWebhook,
  addBulkImportWebHook,
} from './cloverWebhook';

import {
  getInventoryQuickSupport,
  addInventoryQuickSupport,
  updateInventoryQuickSupport,
  deleteInventoryQuickSupport,
} from './inventoryQuickSupport';

import {
  getSubscriptionV2,
  getPlanV2Search,
  addSubscriptionV2,
} from './subscriptionv2';

import {
  getSubscriptionV2Details,
  getSubscriptionV2Payments,
} from './subscriptionV2Details';

import {
  getPlanV2,
  addPlanV2,
} from './planv2';

import {
  getPlanV2Details,
  getPlanV2Payments,
} from './planv2Details';

import {
  getSubscriptionButtonV2,
  addSubscriptionButtonV2,
} from './subscriptionButtonV2';

import {
  getSubscriptionButtonV2Details,
  getSubscriptionButtonV2List,
  deactiveSubscriptionV2Button,
  updateSubscriptionButtonV2,
} from './subscriptionButtonV2Details';

import {
  getOrderShopDetails,
  getShopOrdersDetails,
  updateLineItems,
  sendFcmToken,
  getAllShopDetails,
  getFullfillDetails,
  updateFullFill,
} from './shopDetails';

import {
  getDiningSections,
  addDiningSection,
  getDiningTable,
  addDiningTable,
  updateDiningTable,
  deleteDiningTable,
  updateSection,
  deleteDiningSection,
  fetchDiningDetails,
  getAssignTable,
  assignTable,
  updateAssignTable,
  deleteUserTable,
} from './dining';

import {
  getCustomDashboard,
  addCustomDashboard,
  updateCustomDashboard,
} from './customdashboard';

import {
  getCloverSync,
  addCloverSync,
  updateCloverSync,
} from './cloverSync';

import {
  getInventoryReservations,
  getFilterReservations,
  deleteInventoryReservations,
  getHoldReservations,
} from './inventoryReservations';

import {
  getInventoryReservationsDetails,
  cancelInventoryReservations,
} from './inventoryReservationsDetails';

import {
  getInventoryPreorders,
  getFilterPreorders,
} from './inventoryPreorders';

import {
  getShops,
  getSupportType,
  updateSupportType,
  getFilterSupportType,
  getQuickSupportType,
} from './customerSupport';

import {
  getServicerates,
  addServicerate,
  updateServicerate,
  deleteServicerate,
} from './serviceRates';

import {
  getQuickSupportSummary,
  getQuickSupportSection,
  updateQuickSupportSummary,
} from './quickSupportSummary';

import {
  getTableDetails,
  getStaffDetails,
  getGuestDetails,
} from './tableDetails';

import {
  getInventoryMenus,
  deleteInventoryMenus,
  getMenusOperatingHours,
  addInventoryMenu,
  updateInventoryMenus,
} from './menus';

import {
  getHoursOfOPeratins,
  addHoursOfOperations,
  updateHoursOfOperations,
  deleteHoursOfOperations,
} from './operatingHours';

import {
  getSequenceGenerators,
  addSequenceGenerators,
  updateSequenceGenerator,
  getInvoiceSequenceGenerators,
  addInvoiceSequenceGenerators,
  updateInvoiceSequence,
  getCurrentInvoiceSequence,
  addPOSSequenceGenerators,
  getPOSSequenceGenerators,
  updatePOSSequenceGenerator,
} from './sequenceGenerators';

import {
  getInventoryFeedack,
  addInventoryFeedback,
  updateInventoryFeedback,
  deleteInventoryFeedback,
  getInventoryOrderFeedack,
} from './inventoryFeedback';

import {
  getAdvanceFees,
  addAdvanceFee,
  updateAdvanceFee,
  deleteAdvanceFee,
  fetchAdvanceFeeById,
  getAdvanceFeeItems,
} from './advanceFee';

import {
  getSelfAccounts,
  addSelfAccount,
  deleteSelefAccount,
  fetchSelfAccountById,
  updateSelfAccount,
} from './manageAccounts';

import {
  getAccountSession,
  getAccountSessionInvoices,
  getAccountSessionViewInvoices,
} from './accountSession';

import {
  getDonationPages,
  addDonationPage,
  updateDonationPage,
} from './donationPages';

import {
  getDonationPageDetails,
  getDonationPageSlug,
  getNotifyDonationPage,
  soldOutDonationPage,
  resumeSaleDonationPage,
  deactiveDonationpage,
  offlineDonationPage,
  getDonationPagesEntries,
  getDonationPagesList,
  getOfflineDonationsList,
} from './donationpageDetails';

import {
  getEventPages,
  addEventPage,
  getEventPageReceipts,
} from './eventPages';

import {
  getEventPageDetails,
  updateEventPage,
  getEventPageOrderPaymentData,
  deactivateEventPage,
  soldOutEventPage,
  resumeSalesNon,
  getEventPagesSlug,
  getPromosByEventPage,
  getAllPromosByEventPage,
  addPromosByEventPage,
  updatePromosByEventPage,
  deletePromosByEventPage,
  getEventPageCustomTemplates,
  removeCustomTemplateEventPage,
  getEventPageProductItemDetails,
  getEventPageSecureDetails,
  createEventPageOfflineOrder,
  getEventPageSplitUp,
} from './eventPageDetails';


export default function* rootSaga() {
  yield takeEvery(AUTH.AUTHENTICATE, authenticateUser);
  yield takeEvery(MEMBERSHIP.FETCH, getMembership);
  yield takeEvery(MEMBERSHIP.ADD, addMembership);
  yield takeEvery(MEMBERSHIP.UPDATE, updateMembership);
  yield takeEvery(MEMBERSHIP.DELETE, deleteMembership);
  yield takeEvery(ROLES.FETCH, getRoles);
  yield takeEvery(ROLES.INTERNAL_FETCH, getInternalRoles);
  yield takeEvery(ACCOUNT.ADD, addNewAccount);
  yield takeEvery(ACCOUNT.FETCH, getUserAccount);
  yield takeEvery(ACCOUNT.FETCH_BY_ID, getUserAccountById);
  yield takeEvery(ACCOUNT.FETCH_BY_FILTER, filterUserAccount);
  yield takeEvery(ACCOUNT.UPDATE, updateUserAccount);
  yield takeEvery(ACCOUNT.INTERNAL_UPDATE, updateInternalUserAccount);
  yield takeEvery(ACCOUNT.FETCH_BY_INTERNAL_ID, getUserAccountByInternalId);
  yield takeEvery(BUSINESS.FETCH_BUSINESS_BY_ID, getBusinessByAccountId);
  yield takeEvery(BUSINESS.FETCH_BUSINESS_DRAFT_BY_ID, getBusinessDraftByAccountId);
  yield takeEvery(BUSINESS.FETCH_DOCUSIGN_STATUS, getDocusignStatus);
  yield takeEvery(BUSINESS.ADD_BUSINESS, addBusiness);
  yield takeEvery(BUSINESS.ADD_DRAFT_BUSINESS, addDraftBusiness);
  yield takeEvery(BUSINESS.ADD_BUSINESS_SIGN_UP, addBusinessSignUp);
  yield takeEvery(BUSINESS.BUSINESS_CATEGORIES_FETCH, getBusinessCategory);
  yield takeEvery(BUSINESS.EDIT_BUSINESS, editBusiness);
  yield takeEvery(PAYMENTS.FETCH, getPayments);
  yield takeEvery(PAYMENTS.REFUND, refundPayments);
  yield takeEvery(PAYMENTS.UPDATE, updatePayment);
  yield takeEvery(CUSTOMERS.FETCH, getCustomers);
  yield takeEvery(CUSTOMERS.ADD, addCustomers);
  yield takeEvery(CUSTOMERS.UPDATE, updateCustomer);
  yield takeEvery(CUSTOMERS.DELETE, deleteCustomers);
  yield takeEvery(CUSTOMERSDETAILS.FETCH, getCustomerDetails);
  yield takeEvery(CUSTOMERSDETAILS.CARDS_FETCH, getCardDetails);
  yield takeEvery(CUSTOMERSDETAILS.PAYMENTS_FETCH, getCustomerPayments);
  yield takeEvery(CUSTOMERSDETAILS.UPDATE, updateCustomers);
  yield takeEvery(SETTINGS.UPDATE, updateAccountInfo);
  yield takeEvery(SETTINGS.UPDATE_CROSS, allowCrossTransfer);
  yield takeEvery(PAYOUTS.FETCH, getPayouts);
  yield takeEvery(PAYOUTS.UPDATE, updatePayout);
  yield takeEvery(TRANSACTIONS.FETCH, getTransactions);
  yield takeEvery(REPORTS.FETCH, getReports);
  yield takeEvery(REPORTS.ADD, addReports);
  yield takeEvery(ADMIN_REPORTS.FETCH, getAdminReports);
  yield takeEvery(ADMIN_REPORTS.ADD, addAdminReports);
  yield takeEvery(TERMINAL.FETCH, getTerminals);
  yield takeEvery(TERMINAL.UPDATE, updateTerminal);
  yield takeEvery(TERMINAL.LOCATIONS_FETCH, fetchLocationsList);
  yield takeEvery(LOCATIONS.FETCH, getLocations);
  yield takeEvery(LOCATIONS.ADD, addLocation);
  yield takeEvery(LOCATIONS.UPDATE, updateLocation);
  yield takeEvery(LOCATIONSDETAILS.FETCH, getLocationDetails);
  yield takeEvery(LOCATIONSDETAILS.TERMINALS_FETCH, getTerminalDetails);
  yield takeEvery(LOCATIONSDETAILS.TERMINALS_UPDATE, updateTerminals);
  yield takeEvery(LOCATIONSDETAILS.TERMINALS_DELETE, removeTerminal);
  yield takeEvery(DISPUTES.FETCH, getDisputes);
  yield takeEvery(PUBLICKEYS.FETCH, getPublickeys);
  yield takeEvery(PUBLICKEYS.UPDATE, updatePublickey);
  yield takeEvery(SECRETKEYS.FETCH, getSecretkeys);
  yield takeEvery(SECRETKEYS.UPDATE, updateSecretkey);
  yield takeEvery(SYMMETRICKEYS.FETCH, getSymmetrickey);
  yield takeEvery(PAYOUTSDETAILS.FETCH, getPayoutDetails);
  yield takeEvery(PAYOUTSDETAILS.TRANSACTIONS_FETCH, getPayoutTransactions);
  yield takeEvery(SUPPORT.FETCH, getSupport);
  yield takeEvery(SUPPORT.ADD, addSupport);
  yield takeEvery(SUPPORT.UPDATE, updateSupport);
  yield takeEvery(PAYMENTSDETAILS.FETCH, getPaymentDetails);
  yield takeEvery(PAYMENTSDETAILS.TIMELINE_FETCH, getPaymentTimeline);
  yield takeEvery(PAYMENTSDETAILS.REFUND, refundPaymentDetails);
  yield takeEvery(USERPROFILE.FETCH, getUserProfile);
  yield takeEvery(USERPROFILE.UPDATE, updateUserProfile);
  yield takeEvery(PAYMENTSDETAILS.NOTIFY, getNotifyDetails);
  yield takeEvery(PAYMENTSDETAILS.PAGE_NOTIFY, getNotifyPaymentPageDetails);
  yield takeEvery(PAYMENTSDETAILS.EVENT_PAGE_NOTIFY, getNotifyEventPageOrderDetails);
  yield takeEvery(RECEIPTS.FETCH, getReceipts);
  yield takeEvery(DISPUTEEVIDENCE.SUBMIT_EVIDENCE, submitDisputeEvidence);
  yield takeEvery(PAYMENTSDETAILS.ACCEPT_EVIDENCE, acceptEvidenceDetails);
  yield takeEvery(PAYMENTSDETAILS.REFUND_COMPLETE, completeRefund);
  yield takeEvery(DASHBOARD.SET_DURATION, dashboard.setDurationSaga);
  yield takeEvery(PAYMENTSLINK.FETCH, getPaymentsLink);
  yield takeEvery(PAYMENTSLINK.ADD, addPaymentsLink);
  yield takeEvery(PAYMENTSLINK.ADD_CUSTOMER, customerAdd);
  yield takeEvery(PAYMENTSLINKDETAILS.FETCH, getPaymentsLinkDetails);
  yield takeEvery(PAYMENTSLINKDETAILS.PAYMENTS_FETCH, getPaymentsList);
  yield takeEvery(PAYMENTSLINKDETAILS.UPDATE, updatePaymentsLink);
  yield takeEvery(SUBSCRIPTION.FETCH, getSubscription);
  yield takeEvery(SUBSCRIPTION.ADD, addSubscription);
  yield takeEvery(SUBSCRIPTION.SEARCH_FETCH, getPlanSearch);
  yield takeEvery(SUBSCRIPTION.CUSTOMER_FETCH, getCustomerSearch);
  yield takeEvery(SUBSCRIPTION.PRODUCT_FETCH, getProductSearch);
  yield takeEvery(SUBSCRIPTION.PROFILE_FETCH, getProfile);
  yield takeEvery(SUBSCRIPTION.PRODUCT_SEARCH_FETCH, getSearchProduct);
  yield takeEvery(SUBSCRIPTIONDETAILS.FETCH, getSubscriptionDetails);
  yield takeEvery(SUBSCRIPTIONDETAILS.PAYMENTS_FETCH, getSubscriptionPayments);
  yield takeEvery(PLANS.FETCH, getPlans);
  yield takeEvery(PLANS.ADD, addPlans);
  yield takeEvery(PLANSDETAILS.FETCH, getPlansDetails);
  yield takeEvery(PLANSDETAILS.PAYMENTS_FETCH, getPlansPayments);
  yield takeEvery(INVOICES.FETCH, getInvoices);
  yield takeEvery(PRODUCT.FETCH, getProducts);
  yield takeEvery(PRODUCT.ADD, addProduct);
  yield takeEvery(PRODUCT.UPDATE, updateProduct);
  yield takeEvery(PRODUCT.DELETE, deleteProduct);
  yield takeEvery(INVOICEDETAILS.FETCH, getInvoiceDetails);
  yield takeEvery(INVOICEDETAILS.UPDATE, updateInvoice);
  yield takeEvery(INVOICEDETAILS.CUSTOMERS_FETCH, fetchCustomersList);
  yield takeEvery(INVOICEDETAILS.INVOICE_ADD, addNewInvoice);
  yield takeEvery(INVOICEDETAILS.CUSTOMER_ADD, addCustomer);
  yield takeEvery(INVOICEDETAILS.DELETE, deleteInvoice);
  yield takeEvery(INVOICEDETAILS.CANCEL, cancelInvoices);
  yield takeEvery(INVOICEDETAILS.REFUND, refundInvoices);
  yield takeEvery(INVOICEDETAILS.PRODUCTS_FETCH, fetchProductsList);
  yield takeEvery(INVOICEDETAILS.PRODUCT_ADD, addNewProcut);
  yield takeEvery(INVOICEDETAILS.COUPON_FETCH, fetchCouponsList);
  yield takeEvery(INVOICEDETAILS.ITEMCOUPON_FETCH, fetchItemCouponsList);
  yield takeEvery(INVOICEDETAILS.COUPON_ADD, addNewCoupon);
  yield takeEvery(INVOICEDETAILS.TAX_RATE_FETCH, fetchTaxrates);
  yield takeEvery(INVOICEDETAILS.TAX_RATE_ADD, addTaxrates);
  yield takeEvery(INVOICEDETAILS.TAXES_FETCH, getTax);
  yield takeEvery(PAYMENTPAGES.FETCH, getPaymentPages);
  yield takeEvery(PAYMENTPAGES.ADD, addPaymentPages);
  yield takeEvery(PAYMENTPAGESDETAILS.FETCH, getPaymentPagesDetails);
  yield takeEvery(PAYMENTPAGESDETAILS.PAYMENTS_FETCH, getPaymentPagesList);
  yield takeEvery(PAYMENTPAGESDETAILS.FETCH_ORDERS, getPaymentOrdersList);
  yield takeEvery(PAYMENTPAGESDETAILS.FETCH_OFFLINE_PAYMENTS, getOfflinePaymentsList);
  yield takeEvery(PAYMENTPAGESDETAILS.FETCH_ORDER_BY_ID, getPaymentOrderDetails);
  yield takeEvery(PAYMENTPAGESDETAILS.FETCH_TIMELINE, getPaymentOrderTimeline);
  yield takeEvery(PAYMENTPAGESDETAILS.UPDATE_ORDER_STATUS, updateOrderStatus);
  yield takeEvery(PAYMENTPAGESDETAILS.UPDATE_CASH_PAYMENT, updateCashPayment);
  yield takeEvery(PAYMENTPAGESDETAILS.SLUG_FETCH, getPaymentPageSlug);
  yield takeEvery(PAYMENTPAGESDETAILS.DELETE, deactivePaymentpage);
  yield takeEvery(PAYMENTPAGESDETAILS.NOTIFY, getNotifyPaymentPage);
  yield takeEvery(PAYMENTPAGESDETAILS.UPDATE, updatePaymentPages);
  yield takeEvery(PAYMENTPAGESDETAILS.SOLDOUT, soldOutPaymentPage);
  yield takeEvery(PAYMENTPAGESDETAILS.RESUMESALE, resumeSalePaymentPage);
  yield takeEvery(PAYMENTPAGESDETAILS.OFFLINE_DELETE, deleteOfflinePayment);
  yield takeEvery(PAYMENTPAGESDETAILS.FETCH_PAYMENTS_REPORT, getPaymentPageReports);
  yield takeEvery(PAYMENTPAGESDETAILS.FETCH_PAYMENTS_PAGE_ENTRIES, getPaymentPagesEntries);
  yield takeEvery(INVOICEDETAILS.NOTIFY_SUCCESS, notifyInvoice);
  yield takeEvery(REMINDERS.UPDATE, sendReminders);
  yield takeEvery(REMINDERS.FETCH, getReminders);
  yield takeEvery(PAYMENTSBUTTON.FETCH, getPaymentsButton);
  yield takeEvery(PAYMENTSBUTTON.ADD, addPaymentsButton);
  yield takeEvery(PAYMENTSBUTTONDETAILS.FETCH, getPaymentsButtonDetails);
  yield takeEvery(PAYMENTSBUTTONDETAILS.PAYMENTS_FETCH, getPaymentsButtonList);
  yield takeEvery(PAYMENTSBUTTONDETAILS.DELETE, deactivePayments);
  yield takeEvery(PAYMENTSBUTTONDETAILS.UPDATE, updatePaymentsButton);
  yield takeEvery(SUBSCRIPTION_BUTTON.FETCH, getSubscriptionButton);
  yield takeEvery(SUBSCRIPTION_BUTTON.ADD, addSubscriptionButton);
  yield takeEvery(SUBSCRIPTION_BUTTONDETAILS.FETCH, getSubscriptionButtonDetails);
  yield takeEvery(SUBSCRIPTION_BUTTONDETAILS.PAYMENTS_FETCH, getSubscriptionButtonList);
  yield takeEvery(SUBSCRIPTION_BUTTONDETAILS.DELETE, deactiveSubscription);
  yield takeEvery(SUBSCRIPTION_BUTTONDETAILS.UPDATE, updateSubscriptionButton);
  yield takeEvery(WEBHOOKS.FETCH, getWebhooks);
  yield takeEvery(WEBHOOKS.ADD, addWebhook);
  yield takeEvery(WEBHOOKS.UPDATE, updateWebhook);
  yield takeEvery(WEBHOOKS.DELETE, deleteWebhooks);
  yield takeEvery(WEBHOOKS.EVENTS_FETCH, getWebhookEvents);
  yield takeEvery(QUICKBOOKS.FETCH, getQuickBooks);
  yield takeEvery(QUICKBOOKS.ADD, addQuickBooks);
  yield takeEvery(REFERRAL.FETCH, getReferral);
  yield takeEvery(REFERRAL.ADD, addReferral);
  yield takeEvery(REFERRAL.NOTIFY, notifyReferral);
  yield takeEvery(RATES.FETCH, getRates);
  yield takeEvery(RATES.ADD, addRate);
  yield takeEvery(RATES.ACQUIRER_FETCH, getRateAcquirer);
  yield takeEvery(RATES.CATEGORY_FETCH, getRateCategory);
  yield takeEvery(RATES.PAYMENTTYPE_FETCH, getPaymentType);
  yield takeEvery(RATESDETAILS.FETCH, getRatesDetails);
  yield takeEvery(RATESDETAILS.DELETE, deleteRate);
  yield takeEvery(ACQUIRER.FETCH, getAcquirer);
  yield takeEvery(ACQUIRER.ADD, addAcquirer);
  yield takeEvery(ACQUIRER.DELETE, deleteAcquirer);
  yield takeEvery(ACQUIRER.UPDATE, updateAcquirer);
  yield takeEvery(ACQUIRER.SEARCH_FETCH, getAcquirerSearch);
  yield takeEvery(SETTLEMENT.IMS_ADD, addImsSettlement);
  yield takeEvery(SETTLEMENT.STRIPE_ADD, addStripeSettlement);
  yield takeEvery(SETTLEMENT.AFFINIPAY_ADD, addAffinipaySettlement);
  yield takeEvery(SETTLEMENT.FETCH_CURRENCY, getCurrency);
  yield takeEvery(SETTLEMENT.FETCH_RUN, getSettlementRun);
  yield takeEvery(TAXRATE.FETCH, getTaxrates);
  yield takeEvery(TAXRATE.ADD, addTaxrate);
  yield takeEvery(TAXRATE.DELETE, deleteTaxrate);
  yield takeEvery(TAXRATE.FETCH_TAXES, getTaxes);
  yield takeEvery(PAYMENTSDETAILS.RESOLVE_EVIDENCE, resolveEvidenceDetails);
  yield takeEvery(PAYMENTSDETAILS.RAISE_DISPUTE, raiseDisputeDetails);
  yield takeEvery(PAYMENTSDETAILS.DISPUTE_FETCH, getPaymentDisputeDetails);
  yield takeEvery(COUPON.FETCH, getCoupons);
  yield takeEvery(COUPON.ADD, addCoupons);
  yield takeEvery(COUPON.FETCH_CUSTOMERS, getCustomer);
  yield takeEvery(COUPONDETAILS.FETCH, getCouponDetails);
  yield takeEvery(COUPONDETAILS.UPDATE, updateCoupons);
  yield takeEvery(COUPONDETAILS.DELETE, deleteCoupon);
  yield takeEvery(COUPONDETAILS.ADD_PROMOTIONS, addPromoCode);
  yield takeEvery(COUPONDETAILS.DELETE_PROMOTIONS, deletePromoCode);
  yield takeEvery(COUPONDETAILS.FETCH_REDEMPTIONS, getRedemptions);
  yield takeEvery(COUPONDETAILS.FETCH_COUPON_SHOPS, getShopDetails);
  yield takeEvery(COUPONDETAILS.FETCH_COUPONS_MENUS, getMenus);
  yield takeEvery(COUPONDETAILS.FETCH_COUPON_CATEGORIES, getCategories);
  yield takeEvery(COUPONDETAILS.FETCH_COUPON_ITEMS, getItems);
  yield takeEvery(COUPONDETAILS.FETCH_COUPON_SHOP, getCouponShop);
  yield takeEvery(COUPONDETAILS.FETCH_COUPON_MENU, getCouponMenu);
  yield takeEvery(COUPONDETAILS.FETCH_COUPON_CATEGORY, getCouponCategory);
  yield takeEvery(COUPONDETAILS.UPDATE_COUPON_SHOP, updateCouponShop);
  yield takeEvery(COUPONDETAILS.UPDATE_COUPON_MENU, updateCouponMenu);
  yield takeEvery(COUPONDETAILS.UPDATE_COUPON_CATEGORY, updateCouponCategory);
  yield takeEvery(COUPONDETAILS.FETCH_ALL_EVENT_PAGES, getAllEventPages);
  yield takeEvery(COUPONDETAILS.FETCH_EVENT_PAGE_ITEMS, getEventPageItems);
  yield takeEvery(COUPONDETAILS.FETCH_EVENT_PAGE_CATEGORIES, getEventPageCategories);
  yield takeEvery(COUPONDETAILS.FETCH_COUPON_SHOP, getCouponEventPage);
  yield takeEvery(COUPONDETAILS.UPDATE_COUPON_EVENT_PAGE, updateCouponEventPage);
  yield takeEvery(OFFER.FETCH, getOffers);
  yield takeEvery(OFFER.ADD, addOffer);
  yield takeEvery(OFFERDETAILS.FETCH, getOfferDetails);
  yield takeEvery(OFFERDETAILS.UPDATE, updateOffer);
  yield takeEvery(OFFERDETAILS.DELETE, deleteOffer);
  yield takeEvery(OFFERDETAILS.FETCH_REDEMPTIONS, getOfferRedemptions);
  yield takeEvery(AFFILIATE.FETCH, getAffiliate);
  yield takeEvery(AFFILIATE.ADD, addAffiliate);
  yield takeEvery(AFFILIATE.NOTIFY, notifyAffiliate);
  yield takeEvery(AFFILIATE_TRANSACTIONS.FETCH, getAffiliateTransactions);
  yield takeEvery(AFFILIATE_EARNINGS.FETCH, getAffiliateEarnings);
  yield takeEvery(QUICKBOOKSDETAILS.FETCH, getQuickBookDetails);
  yield takeEvery(SALESFORCE.FETCH, getSalesforce);
  yield takeEvery(SALESFORCE.ADD, addSalesforce);
  yield takeEvery(SALESFORCE.SPOBJECT_FETCH, getSpObject);
  yield takeEvery(SALESFORCE.CREDENTIALS_UPDATE, updateCredentials);
  yield takeEvery(SALESFORCE.SPFIELD_FETCH, getSpField);
  yield takeEvery(SALESFORCE.ADD_MAPPINGS, addSalesforceMapping);
  yield takeEvery(SALESFORCE.FETCH_MAPPINGLIST, getMappingList);
  yield takeEvery(SALESFORCE.FETCH_MAPPINGDETAILS, getMappingDetails);
  yield takeEvery(SALESFORCE.UPDATE_MAPPINGS, updateSalesforceMapping);
  yield takeEvery(SALESFORCE.DELETE_MAPPINGS, deleteSalesforceMapping);
  yield takeEvery(SCHEDULE_REPORTS.FETCH, getScheduleReports);
  yield takeEvery(SCHEDULE_REPORTS.ADD, addScheduleReport);
  yield takeEvery(SCHEDULE_REPORTS.DELETE, deleteScheduleReports);
  yield takeEvery(SCHEDULE_ADMIN_REPORTS.FETCH, getAdminScheduleReports);
  yield takeEvery(SCHEDULE_ADMIN_REPORTS.ADD, addAdminScheduleReports);
  yield takeEvery(SCHEDULE_ADMIN_REPORTS.DELETE, deleteAdminScheduleReports);
  yield takeEvery(ADMIN_TERMINAL.FETCH, getAdminTerminal);
  yield takeEvery(ADMIN_TERMINAL.ADD, addAdminTerminal);
  yield takeEvery(ADMIN_TERMINAL.UPDATE, updateAdminTerminal);
  yield takeEvery(ADMIN_TERMINALDETAILS.FETCH, getAdminTerminalDetails);
  yield takeEvery(ADMIN_TERMINALDETAILS.DELETE, deleteAdminTerminal);
  yield takeEvery(ADMIN_TERMINALDETAILS.UPDATE, updateTerminalPin);
  yield takeEvery(SPFIELD.FETCH, fetchSpFields);
  yield takeEvery(SPFIELD.ADD, addSpField);
  yield takeEvery(SPFIELD.UPDATE, updateSpField);
  yield takeEvery(SPFIELD.DELETE, deleteSpField);
  yield takeEvery(SP_OBJECT.FETCH, fetchSpObjects);
  yield takeEvery(SP_OBJECT.FETCH_ALL, fetchAllSpObject);
  yield takeEvery(SP_OBJECT.ADD, addSpObject);
  yield takeEvery(SP_OBJECT.UPDATE, updateSpObject);
  yield takeEvery(SP_OBJECT.DELETE, deleteSpObject);
  yield takeEvery(POSKEY.FETCH, getPoskey);
  yield takeEvery(POSKEY.UPDATE, updatePoskey);
  yield takeEvery(GLOBAL_RESTRICTED_KEY.FETCH, getGlobalRestrictedKey);
  yield takeEvery(GLOBAL_RESTRICTED_KEY.ADD, addGlobalRestrictedKey);
  yield takeEvery(GLOBAL_RESTRICTED_KEY.UPDATE, updateGlobalRestrictedKey);
  yield takeEvery(GLOBAL_RESTRICTED_KEY.DELETE, deleteGlobalRestrictedKey);
  yield takeEvery(PAYMENTWIDGETS.FETCH, getPaymentWidget);
  yield takeEvery(PAYMENTWIDGETS.ADD, addPaymentWidget);
  yield takeEvery(PAYMENTWIDGETSDETAILS.FETCH, getPaymentWidgetDetails);
  yield takeEvery(PAYMENTWIDGETSDETAILS.FETCH_PAYMENTS, getWidgetPayments);
  yield takeEvery(PAYMENTWIDGETSDETAILS.FETCH_SUBSCRIPTIONS, getWidgetSubscriptions);
  yield takeEvery(PAYMENTWIDGETSDETAILS.DELETE, deactivePayment);
  yield takeEvery(PAYMENTWIDGETSDETAILS.UPDATE, updatePaymentWidget);
  yield takeEvery(PAYMENTWIDGETSDETAILS.SLUG_FETCH, getPaymentWidgetSlug);
  yield takeEvery(ISSUER.FETCH, getIssuer);
  yield takeEvery(ISSUER.ADD, addIssuer);
  yield takeEvery(ISSUER_RATES.FETCH, getIssuerRates);
  yield takeEvery(ISSUER_RATES.ADD, addIssuerRate);
  yield takeEvery(ISSUER_RATES.DELETE, deleteIssuer);
  yield takeEvery(ISSUER_RATES.TRANSFERTYPE_FETCH, getTransferType);
  yield takeEvery(ISSUER_RATESDETAILS.FETCH, getIssuerRatesDetails);
  yield takeEvery(EXCHANGE_RATES.ADD, addExchangeRate);
  yield takeEvery(EXCHANGE_RATES.UPDATE, updateExchangeRate);
  yield takeEvery(EXCHANGE_RATES.DELETE, deleteExchangeRate);
  yield takeEvery(EXCHANGE_RATES.FETCH, getExchangeRatesList);
  yield takeEvery(EXCHANGE_RATES.FETCH_AMOUNT, getExchangeRateForAmount);
  yield takeEvery(CASH_TRANSACTIONS.FETCH, getCashTransactions);
  yield takeEvery(CHEQUE_TRANSACTIONS.FETCH, getChequeTransactions);
  yield takeEvery(CASH_TRANSACTIONS_DETAILS.FETCH, getCashTransactionDetails);
  yield takeEvery(CHEQUE_TRANSACTIONS_DETAILS.FETCH, getChequeTransactionDetails);
  yield takeEvery(CASH_TRANSACTIONS_DETAILS.NOTIFY, getNotifyCashTransactionDetails);
  yield takeEvery(CASH_TRANSACTIONS_DETAILS.VOIDED, voidPayment);
  yield takeEvery(CASH_TRANSACTIONS_DETAILS.REFUND, refundCashPayment);
  yield takeEvery(CHEQUE_TRANSACTIONS_DETAILS.NOTIFY, getNotifyChequeTransactionDetails);
  yield takeEvery(CHEQUE_TRANSACTIONS_DETAILS.VOIDED, voidChequePayment);
  yield takeEvery(CASH_RECEIPTS.FETCH, getCashReceipts);
  yield takeEvery(CHEQUE_RECEIPTS.FETCH, getChequeReceipts);
  yield takeEvery(INTERNAL_MEMBERSHIP.FETCH, getInternalMembership);
  yield takeEvery(INTERNAL_MEMBERSHIP.ADD, addInternalMembership);
  yield takeEvery(INTERNAL_MEMBERSHIP.UPDATE, updateInternalMembership);
  yield takeEvery(INTERNAL_MEMBERSHIP.DELETE, deleteInternalMembership);
  yield takeEvery(TERMINALDETAILS.FETCH, getTerminalsDetail);
  yield takeEvery(TERMINALDETAILS.TERMINALS_PAYMENTS_FETCH, getTerminalPayments);
  yield takeEvery(TERMINALDETAILS.TERMINALS_UPDATE, updateTerminalDetails);
  yield takeEvery(TERMINALDETAILS.ADD_PROOF, updateTerminalProof);
  yield takeEvery(SERVICES.FETCH, getServices);
  yield takeEvery(SERVICEDETAILS.FETCH, getServiceDetails);
  yield takeEvery(SERVICEDETAILS.UPDATE, updateServiceDetails);
  yield takeEvery(SERVICEDETAILS.FETCH_TIMELINE, getServiceTimeLine);
  yield takeEvery(SERVICEDETAILS.FIELDAGENT_FETCH, getFieldAgent);
  yield takeEvery(DOCUMENTS.FETCH, getDocuments);
  yield takeEvery(DOCUMENTS.ADD, addDocument);
  yield takeEvery(TRANSFERS.FETCH, getTransfers);
  yield takeEvery(TRANSFERS.ISSUETRANSFER, issueTransfer);
  yield takeEvery(TRANSFERS.CONFIRMTRANSFEROTP, confirmTransferOtp);
  yield takeEvery(TRANSFERS.CANCELTRANSFERINDETAILS, cancelTransferInDetails);
  yield takeEvery(TRANSFERS.UPDATE, updateTransfers);
  yield takeEvery(TRANSFERS.CROSS_BORDER, updateCrossBorder);
  yield takeEvery(TRANSFERS.CANCEL_TRANSFERS, cancelTransfers);
  yield takeEvery(TRANSFERS.SEARCH_FUNDING_FETCH, searchFundingSourceList);
  yield takeEvery(TRANSFERS.CONTACT_FETCH, fetchContactList);
  yield takeEvery(TRANSFERS.FUNDING_FETCH, getFundingSource);
  yield takeEvery(ADMIN_BIN.FETCH, getAdminBin);
  yield takeEvery(ADMIN_BIN.ADD, addAdminBin);
  yield takeEvery(ADMIN_BIN.UPDATE, updateAdminBin);
  yield takeEvery(PAGE_RECEIPTS.FETCH, getPageReceipts);
  yield takeEvery(VENDORS.FETCH, getVendors);
  yield takeEvery(VENDORS.ADD, addVendor);
  yield takeEvery(VENDORS.UPDATE, updateVendor);
  yield takeEvery(VENDORS.DELETE, deleteVendor);
  yield takeEvery(VENDORS.FETCH_CUSTOM_TYPES, getCustomTypes);
  yield takeEvery(VENDORS.DELETE_SINGLE_CUSTOM_TYPE, deleteSingleCustomType);
  yield takeEvery(VENDORS.DELETE_ALL_CUSTOM_TYPES, deleteAllCustomTypes);
  yield takeEvery(VENDORS.MULTI_SEND, multiSendFile);
  yield takeEvery(ACH_PAYMENT.CUSTOMER_ADD, addAchCustomer);
  yield takeEvery(ACH_PAYMENT.UPDATE, updateBenficiaryStatus);
  yield takeEvery(ACH_PAYMENT.FETCH, fetchBeneficiars);
  yield takeEvery(VENDORSDETAILS.FETCH, getVendorDetails);
  yield takeEvery(VENDORSDETAILS.TRANSFERS_FETCH, getTransfersList);
  yield takeEvery(VENDORSDETAILS.DELETE, DeleteVendor);
  yield takeEvery(TRANSFERSDETAILS.FETCH, getTransferDetails);
  yield takeEvery(TRANSFERSDETAILS.TIMELINE_FETCH, getTransferTimeline);
  yield takeEvery(TRANSFERSDETAILS.NOTIFY, getTransferNotifyDetails);
  yield takeEvery(TRANSFERSDETAILS.RESENDOTP, getTransferOtp);
  yield takeEvery(FUNDINGSOURCE.FETCH, getFundingSourceList);
  yield takeEvery(FUNDINGSOURCE.FETCH_BY_CONTACTGID, getFundingSourceListByContactGid);
  yield takeEvery(FUNDINGSOURCE.FETCH_FUNDINGSOURCEDETAILS, getFundingSourceDetails);
  yield takeEvery(FUNDINGSOURCE.CREATE, createFundingSource);
  yield takeEvery(FUNDINGSOURCE.DELETE, deleteFundingSource);
  yield takeEvery(FUNDINGSOURCE_LINKS.FETCH, getFundingSourceLink);
  yield takeEvery(FUNDINGSOURCE_LINKS.CREATE, createFundingSourceLink);
  yield takeEvery(TRANSFERLINKS.FETCH, getTransferLinks);
  yield takeEvery(TRANSFERLINKS.CREATETRANSFERLINK, createTransferLinks);
  yield takeEvery(TRANSFERLINKS.CONFIRMTRANSFERLINKOTP, confirmTransferLinkOtp);
  yield takeEvery(BULKTRANSFER.FETCH, getBulkTransfer);
  yield takeEvery(BULKTRANSFER.FETCH_SEARCH, getBulkTransferSearch);
  yield takeEvery(BULKTRANSFER.ADD, addBulkTransfer);
  yield takeEvery(BULKTRANSFER_DETAILS.FETCH, getBulkTransferDetails);
  yield takeEvery(BULKTRANSFER_DETAILS.TRANSFERS_FETCH, getBulkTransfersList);
  yield takeEvery(BANK_TRANSFERS.REGISTER, addIcicCustomer);
  yield takeEvery(BANK_TRANSFERS.ACCOUNT_BY_ID, accountById);
  yield takeEvery(BANK_TRANSFERS.BALANCE_BY_ID, checkBalanceById);
  yield takeEvery(BANK_TRANSFERS.CREATE_FUNDING_SOURCE, createIciciFundingSource);
  yield takeEvery(BANK_TRANSFERS.CREATE_PRIMARY_CONTACT, createPrimaryContact);
  yield takeEvery(BANK_TRANSFERS.DELETE_CONTACT, deleteContact);
  yield takeEvery(TRANSFER_RECEIPTS.FETCH, getTransferReceipts);
  yield takeEvery(PAYMENTCHECKOUT.FETCH, getPaymentCheckout);
  yield takeEvery(PAYMENTCHECKOUT.ADD, addPaymentCheckout);
  yield takeEvery(PAYMENTCHECKOUTDETAILS.FETCH, getPaymentCheckoutDetails);
  yield takeEvery(PAYMENTCHECKOUTDETAILS.PAYMENTS_FETCH, getPaymentCheckoutList);
  yield takeEvery(CUSTOM_DOCUMENT_REQUEST.FETCH, getCustomDocumentRequest);
  yield takeEvery(CUSTOM_DOCUMENT_REQUEST.ADD, addCustomDocumentRequest);
  yield takeEvery(CUSTOM_DOCUMENT_REQUEST.UPDATE, updateCustomDocumentRequest);
  yield takeEvery(CUSTOM_DOCUMENT_REQUEST_DETAILS.FETCH, getCustomDocumentRequestDetails);
  yield takeEvery(CUSTOM_DOCUMENT_REQUEST_DETAILS.DELETE, deleteCustomDocumentRequest);
  yield takeEvery(ACCOUNT_SETLLEMENT.FETCH, getAccountSettlement);
  yield takeEvery(ACCOUNT_SETLLEMENT.ADD, addAccountSettlement);
  yield takeEvery(PARTNER_SETLLEMENT.FETCH, getPartnerSettlement);
  yield takeEvery(PARTNER_SETLLEMENT.ADD, addPartnerSettlement);
  yield takeEvery(PARTNER_SETLLEMENT.DELETE, deletePartnerSettlement);
  yield takeEvery(ACCOUNT_SETLLEMENT.DELETE, deleteAccountSettlement);
  yield takeEvery(OCR_REQUEST.FETCH, getOcrRequest);
  yield takeEvery(OCR_REQUEST.ADD, addOcrRequest);
  yield takeEvery(BANK_STATEMENT.FETCH, getBankStatement);
  yield takeEvery(BANK_STATEMENT.ADD, addBankStatement);
  yield takeEvery(BANK_STATEMENT_DETAILS.FETCH, getAccountReceivables);
  yield takeEvery(BANK_STATEMENT_DETAILS.PAYABLES_FETCH, getAccountPayables);
  yield takeEvery(ACCOUNT_SLUG.FETCH, getAccountSlug);
  yield takeEvery(ACCOUNT_SLUG.ADD, addAccountSlug);
  yield takeEvery(ACCOUNT_SLUG.LINK, linkAccountSlug);
  yield takeEvery(ACCOUNT_SLUG.UNLINK, unLinkAccountSlug);
  yield takeEvery(ACCOUNT_SLUG_DETAILS.FETCH, getAccountSlugDetails);
  yield takeEvery(WALLET.FETCH, getWallet);
  yield takeEvery(WALLET_TRANSACTION.FETCH, getWalletTransaction);
  yield takeEvery(WALLET_TRANSACTION_DETAILS.FETCH, getWalletTransactionDetails);
  yield takeEvery(WALLET_TRANSACTION_DETAILS.ADD, applyCashBack);
  yield takeEvery(WALLET.ADD, addWallet);
  yield takeEvery(WALLET.ADD_CONFIRM, addConfirmWallet);
  yield takeEvery(WALLET.FETCH_TRANSACTIONS, fetchTransactions);
  yield takeEvery(WALLET.REST_PIN, pinSetUp);
  yield takeEvery(WALLET.VERIFY_PIN, verifyPin);
  yield takeEvery(WALLET_DETAILS.FETCH, getWalletDetails);
  yield takeEvery(WALLET_DETAILS.ADD_BANK, addNewBankAccount);
  yield takeEvery(WALLET_DETAILS.LOAD_BALANCE, loadBalance);
  yield takeEvery(WALLET_DETAILS.ADD_CARD, addNewCard);
  yield takeEvery(WALLET_DETAILS.FETCH_CARDS, getDebitCards);
  yield takeEvery(WALLET_DETAILS.WITHDRAW, withdrawAmount);
  yield takeEvery(WALLET_DETAILS.DELETE_CARD, deleteCard);
  yield takeEvery(WALLET_DETAILS.UPDATE_CARD, updateCard);
  yield takeEvery(TRANSFER_SETTINGS.FETCH, getTransferSetting);
  yield takeEvery(TRANSFER_SETTINGS.ADD, addTransferSetting);
  yield takeEvery(TRANSFER_SETTINGS.UPDATE, updateTransferSetting);
  yield takeEvery(TRANSFER_SETTINGS.ENABLE_LIVE, enableLiveTransfer);
  yield takeEvery(REWARD_SETTINGS.FETCH, getRewardSettings);
  yield takeEvery(REWARD_SETTINGS.ADD, addRewardSettings);
  yield takeEvery(REWARD_SETTINGS.UPDATE, updateRewardSettings);
  yield takeEvery(STATIC_QR.FETCH, getStaticQR);
  yield takeEvery(STATIC_QR.ADD, addStaticQR);
  yield takeEvery(STATIC_QR.BULK, addBulkStaticQR);
  yield takeEvery(STATIC_QR_DETAILS.FETCH, getStaticQRDetails);
  yield takeEvery(STATIC_QR_DETAILS.DELETE, deleteStaticQR);
  yield takeEvery(STATIC_QR_DETAILS.UPDATE, updateStaticQR);
  yield takeEvery(REPORT_TEMPLATE.FETCH, getReportTemplate);
  yield takeEvery(REPORT_TEMPLATE.ADD, addReportTemplate);
  yield takeEvery(REPORT_TEMPLATE_DETAILS.FETCH, getReportTemplateDetails);
  yield takeEvery(REPORT_TEMPLATE_DETAILS.DELETE, deleteReportTemplate);
  yield takeEvery(REPORT_TEMPLATE_DETAILS.UPDATE, updateReportTemplate);
  yield takeEvery(CUSTOM_REPORT.FETCH, getCustomReports);
  yield takeEvery(CUSTOM_REPORT.ADD, addCustomReport);
  yield takeEvery(RECAPTCHA_DETAILS.FETCH, getRecaptchaDetails);
  yield takeEvery(USONBOARDING.FETCH, getOnboardingLinks);
  yield takeEvery(USONBOARDING.ADD, addOnboardingLink);
  yield takeEvery(VENDORINVOICES.FETCH, getVendorInvoices);
  yield takeEvery(VENDORINVOICEDETAILS.FETCH, getVendorInvoiceDetails);
  yield takeEvery(VENDORINVOICEDETAILS.UPDATE, updateVendorInvoice);
  yield takeEvery(VENDORINVOICEDETAILS.CONTACT_FETCH, fetchVendorContactList);
  yield takeEvery(VENDORINVOICEDETAILS.INVOICE_ADD, addNewVendorInvoice);
  yield takeEvery(VENDORINVOICEDETAILS.CONTACT_ADD, addContact);
  yield takeEvery(VENDORINVOICEDETAILS.DELETE, deleteVendorInvoice);
  yield takeEvery(VENDORINVOICEDETAILS.CANCEL, cancelVendorInvoices);
  yield takeEvery(VENDORINVOICEDETAILS.NOTIFY_SUCCESS, notifyVendorInvoice);
  yield takeEvery(VENDORINVOICEDETAILS.INVOICE_PAY, payVendorInvoice);
  yield takeEvery(BENEFICIARY.FETCH, getBeneficiary);
  yield takeEvery(BENEFICIARY.ADD, addBeneficiary);
  yield takeEvery(BENEFICIARY.UPDATE, updateBeneficiary);
  yield takeEvery(BENEFICIARY.DELETE, deleteBeneficiary);
  yield takeEvery(BENEFICIARYDETAILS.FETCH, getBeneficiaryDetails);
  yield takeEvery(WALLETINVOICES.FETCH, getWalletInvoices);
  yield takeEvery(WALLETINVOICEDETAILS.FETCH, getWalletInvoiceDetails);
  yield takeEvery(WALLETINVOICEDETAILS.UPDATE, updateWalletInvoice);
  yield takeEvery(WALLETINVOICEDETAILS.BENEFICIARY_FETCH, fetchWalletBeneficiaryList);
  yield takeEvery(WALLETINVOICEDETAILS.INVOICE_ADD, addNewWalletInvoice);
  yield takeEvery(WALLETINVOICEDETAILS.BENEFICIARY_ADD, addWalletBeneficiary);
  yield takeEvery(WALLETINVOICEDETAILS.DELETE, deleteWalletInvoice);
  yield takeEvery(WALLETINVOICEDETAILS.CANCEL, cancelWalletInvoices);
  yield takeEvery(WALLETINVOICEDETAILS.NOTIFY_SUCCESS, notifyWalletInvoice);
  yield takeEvery(WALLETINVOICEDETAILS.INVOICE_PAY, payWalletInvoice);
  yield takeEvery(WOOCOMMERCE.FETCH, getWoocommerce);
  yield takeEvery(WOOCOMMERCE.ADD, addWoocommerce);
  yield takeEvery(WOOCOMMERCE.UPDATE, updateWoocommerce);
  yield takeEvery(WOOCOMMERCE.LIST_FETCH, getWoocommerceList);
  yield takeEvery(WOOCOMMERCE_DETAILS.FETCH, getWooCommerceOrderDetails);
  yield takeEvery(WOOCOMMERCE_DETAILS.FETCH_TIMELINE, getWooCommerceOrderTimeline);
  yield takeEvery(WOOCOMMERCE_DETAILS.UPDATE_ORDER_STATUS, updateWooCommerceOrderStatus);
  yield takeEvery(CLOVER_RECEIPTS.FETCH, getCloverReceipts);
  yield takeEvery(ORDER_RECEIPTS.FETCH, getOrderReceipt);
  yield takeEvery(CATALOG.FETCH, getCatalogs);
  yield takeEvery(CATALOG_ORDERS.FETCH, getCatalogOrders);
  yield takeEvery(CATALOG.ADD, orderCatalog);
  yield takeEvery(ADMIN_CATALOG.FETCH, getAdminCatalogs);
  yield takeEvery(ADMIN_CATALOG.DELETE, deleteCatalogs);
  yield takeEvery(ADMIN_CATALOG.ADD, addCatalog);
  yield takeEvery(ADMIN_CATALOG.UPDATE, updateCatalog);
  yield takeEvery(ADMIN_ORDERS.FETCH, getAdminOrders);
  yield takeEvery(ADMIN_ORDERS.UPDATE, updateAdminOrders);
  yield takeEvery(INVENTORY_ORDERS.FETCH, getInventoryOrders);
  yield takeEvery(INVENTORY_ORDERS.FETCH_FILTER_ORDERS, getInventoryFilterOrders);
  yield takeEvery(INVENTORY_ITEMS.FETCH, getInventoryItems);
  yield takeEvery(INVENTORY_ITEMS.ORDER_LIST_FETCH, getInventorySortItems);
  yield takeEvery(INVENTORY_ITEMS.LIST_FETCH, getInventoryItemsList);
  yield takeEvery(INVENTORY_ITEMS.MODIFIER_LIST_FETCH, getInventoryModifierItemsList);
  yield takeEvery(INVENTORY_ITEMS.UPDATE, updateInventoryItems);
  yield takeEvery(INVENTORY_ITEMS.DELETE, deleteInventoryItems);
  yield takeEvery(INVENTORY_ITEMS.ORDER_LIST_UPDATE, updateInventorySortItems);
  yield takeEvery(INVENTORY_ITEMS.BULK_UPDATE_ITEM, updateBulkItems);
  yield takeEvery(INVENTORY_CATEGORIES.FETCH, getInventoryCategories);
  yield takeEvery(INVENTORY_CATEGORIES.ORDER_CATEGORIES_FETCH, getInventorySortCategory);
  yield takeEvery(INVENTORY_CATEGORIES.ORDER_CATEGORIES_UPDATE, updateInventorySortCategory);
  yield takeEvery(INVENTORY_CATEGORIES.BULK_UPDATE, updateBulkCategories);
  yield takeEvery(INVENTORY_CATEGORIES.FETCH_OPERATING_HOURS, fetchCategoryOperatingHours);
  yield takeEvery(INVENTORY_CATEGORIES.FETCH_CATEGORY, fetchInventoryCategoriesWithItems);
  yield takeEvery(INVENTORY_MODIFIERS.FETCH, getInventoryModifiers);
  yield takeEvery(INVENTORY_MODIFIERS.ORDER_MODIFIERS_FETCH, getInventorySortModifer);
  yield takeEvery(INVENTORY_MODIFIERS.ORDER_MODIFIERS_UPDATE, updateInventorySortModifer);
  yield takeEvery(INVENTORY_ORDERS_DETAILS.FETCH, getInventoryOrderDetails);
  yield takeEvery(INVENTORY_ORDERS_DETAILS.REFUND, refundOrderDetails);
  yield takeEvery(INVENTORY_ORDERS_DETAILS.DELETE_ITEM, deleteOrderLineItem);
  yield takeEvery(INVENTORY_ORDERS_DETAILS.REFUND_ITEM, refundOrderLineItem);
  yield takeEvery(INVENTORY_ORDERS_DETAILS.DELETE, deleteOrderDetails);
  yield takeEvery(INVENTORY_ORDERS_DETAILS.PAYMENTS_FETCH, getInventoryPayments);
  yield takeEvery(INVENTORY_ORDERS_DETAILS.ADDITEMS_ORDER, addItemOrder);
  yield takeEvery(INVENTORY_ITEMS_DETAILS.FETCH, getInventoryItemDetails);
  yield takeEvery(INVENTORY_CATEGORIES_DETAILS.FETCH, getInventoryCategorieDetails);
  yield takeEvery(INVENTORY_CATEGORIES_DETAILS.FETCH_ALL_SHOP, getAllShop);
  yield takeEvery(INVENTORY_CATEGORIES_DETAILS.FETCH_ALLITEM_CATEGORY, getAllItemsByCategory);
  yield takeEvery(INVENTORY_CATEGORIES_DETAILS.GET_SELECTED_CATEOGARY_ITEM, getItemsBySelectedCategory);
  yield takeEvery(INVENTORY_CATEGORIES_DETAILS.FETCH_OPERATING_HOURS, getInventoryOperatingHours);
  yield takeEvery(INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_FETCH, getInventoryItemByCategory);
  yield takeEvery(INVENTORY_CATEGORIES_DETAILS.CATEGORY_ITEM_ADD, addItemCategory);
  yield takeEvery(INVENTORY_MODIFIERS_DETAILS.FETCH, getInventoryModifierDetails);
  yield takeEvery(INVENTORY_CATEGORIES.DELETE, deleteInventoryCategory);
  yield takeEvery(INVENTORY_CATEGORIES.FETCH_OPERATING_HOURS, fetchCategoryOperatingHours);
  yield takeEvery(INVENTORY_MODIFIERS.UPDATE, updateInventoryModifer);
  yield takeEvery(INVENTORY_MODIFIERS.DELETE, deleteInventoryModifer);
  yield takeEvery(INVENTORY_MODIFIERS_DETAILS.ADD_MODIFIER, addInventoryModifier);
  yield takeEvery(INVENTORY_MODIFIERS_DETAILS.DELETE_MODIFIER, deleteInventoryModifier);
  yield takeEvery(INVENTORY_MODIFIERS_DETAILS.UPDATE_MODIFIER, updateInventoryModifier);
  yield takeEvery(INVENTORY_CATEGORIES.UPDATE, updateInventoryCategory);
  yield takeEvery(INVENTORY_MODIFIERS.UPDATE, updateInventoryModifer);
  yield takeEvery(INVENTORY_ITEMS.ADD, addInventoryItems);
  yield takeEvery(INVENTORY_CATEGORIES.ADD, addInventoryCategory);
  yield takeEvery(INVENTORY_MODIFIERS.ADD, addInventoryModifer);
  yield takeEvery(EVENTS.FETCH, getEvents);
  yield takeEvery(EVENTS.ADD_EVENT, addNewEvents);
  yield takeEvery(EVENTS.UPDATE, updateEvents);
  yield takeEvery(EVENTS.DELETE, deleteEvents);
  yield takeEvery(EVENTSDETAILS.FETCH, getEventDetails);
  yield takeEvery(EVENTSDETAILS.CARDS_FETCH, getCardDetail);
  yield takeEvery(EVENTSDETAILS.UPDATE, updateEvent);
  yield takeEvery(INVENTORY_ORDERTYPES.FETCH, getInventoryOrderTypes);
  yield takeEvery(INVENTORY_ORDERTYPES.ADD, addInventoryOrderTypes);
  yield takeEvery(INVENTORY_ORDERTYPES.UPDATE, updateInventoryOrderTypes);
  yield takeEvery(INVENTORY_ORDERTYPES.DELETE, deleteInventoryOrderTypes);
  yield takeEvery(EVENTSDETAILS.DEACTIVE, deactivateEvent);
  yield takeEvery(EVENTSDETAILS.SOLDOUT, soldOutEvent);
  yield takeEvery(EVENTSDETAILS.RESUME_SALES, resumeSales);
  yield takeEvery(EVENTSDETAILS.OFFLINE_PAYMENTS, offlinePayments);
  yield takeEvery(EVENTSDETAILS.EVENTS_SLUG_FETCH, getEventsSlug);
  yield takeEvery(EVENTSDETAILS.FETCH_OFFLINE_PAYMENTS, getOfflineEventsPaymentsList);
  yield takeEvery(EVENTSDETAILS.PAGE_NOTIFY, getNotifySeatedPageDetails);
  yield takeEvery(EVENTSDETAILS.SEATED_NOTIFY, getNotifySeatedPage);
  yield takeEvery(EVENTS.FETCH_RECEIPT, getEventReceipts);
  yield takeEvery(PRINTER_LABEL.FETCH, getInventoryPrinterLabel);
  yield takeEvery(PRINTER_LABEL.ADD, addInventoryPrinterLabel);
  yield takeEvery(PRINTER_LABEL.UPDATE, updateInventoryPrinterLabel);
  yield takeEvery(PRINTER_LABEL.DELETE, deleteInventoryPrinterLabel);
  yield takeEvery(PRINTER_LABEL.FETCH_LIST, getPrinterLabelList);
  yield takeEvery(PRINTER_LABEL_DETAILS.FETCH, getInventoryPrinterLabelDetails);
  yield takeEvery(PRINTER_LABEL_DETAILS.LABEL_ITEM_ADD, addItemPrinterLabel);
  yield takeEvery(PRINTER_LABEL_DETAILS.LABEL_ITEM_FETCH, getInventoryItemByPrinterLabel);
  yield takeEvery(SHOPS.FETCH, getInventoryShops);
  yield takeEvery(SHOPS.ADD, addInventoryShops);
  yield takeEvery(SHOPS.ASSOCIATE_ADD, addInventoryShopAssociate);
  yield takeEvery(SHOPS.ASSOCIATE_UPDATE, updateInventoryShopAssociate);
  yield takeEvery(SHOPS.ASSOCIATE_DELETE, deleteInventoryShopAssociate);
  yield takeEvery(SHOPS.ASSOCIATE_FETCH, getInventoryShopAssociate);
  yield takeEvery(SHOPS.UPDATE, updateInventoryShops);
  yield takeEvery(SHOPS.DELETE, deleteInventoryShops);
  yield takeEvery(SHOPS.RATES_FETCH, getInventoryRatesList);
  yield takeEvery(SHOPS.PROMOS_FETCH, getInventoryPromosList);
  yield takeEvery(SHOPS_DETAILS.FETCH, getInventoryShopsDetails);
  yield takeEvery(SHOPS_DETAILS.SHOPS_ITEM_ADD, addItemShops);
  yield takeEvery(SHOPS_DETAILS.SHOPS_ITEM_FETCH, getInventoryItemByShops);
  yield takeEvery(SHOPS_DETAILS.SHOPS_ITEM_SEARCH_FETCH, getInventoryItemNamesByShops);
  yield takeEvery(SHOPS_DETAILS.FETCH_SHOPS_TABLES, getTablesShops);
  yield takeEvery(SHOPS_DETAILS.SHOPS_PROMO_ADD, addPromosByShop);
  yield takeEvery(SHOPS_DETAILS.SHOPS_PROMO_DELETE, deletePromosByShop);
  yield takeEvery(SHOPS_DETAILS.SHOPS_PROMO_UPDATE, updatePromosByShop);
  yield takeEvery(SHOPS_DETAILS.SHOPS_PROMO_FETCH, getInventoryPromosByShop);
  yield takeEvery(SHOPS_DETAILS.SHOPS_ALLPROMO_FETCH, getInventoryAllPromosByShop);
  yield takeEvery(SHOPS_DETAILS.SHOPS_SLUG_FETCH, getShopsSlug);
  yield takeEvery(CLOVER_WEBHOOK.FETCH, getCloverWebhook);
  yield takeEvery(SHOPS_DETAILS.SHOPS_TIPS_ADD, addTips);
  yield takeEvery(SHOPS_DETAILS.SHOPS_TIPS_DELETE, deleteTipsByShop);
  yield takeEvery(CLOVER_WEBHOOK.UPDATE, updateCloverWebhook);
  yield takeEvery(CLOVER_WEBHOOK.INTERNAL_FETCH, getInternalCloverWebhook);
  yield takeEvery(CLOVER_WEBHOOK.INTERNAL_UPDATE, updateInternalCloverWebhook);
  yield takeEvery(CLOVER_WEBHOOK.BULKIMPORT, addBulkImportWebHook);
  yield takeEvery(QUICKSUPPORT.FETCH, getInventoryQuickSupport);
  yield takeEvery(QUICKSUPPORT.ADD, addInventoryQuickSupport);
  yield takeEvery(QUICKSUPPORT.UPDATE, updateInventoryQuickSupport);
  yield takeEvery(QUICKSUPPORT.DELETE, deleteInventoryQuickSupport);
  yield takeEvery(SUBSCRIPTIONV2.FETCH, getSubscriptionV2);
  yield takeEvery(SUBSCRIPTIONV2DETAILS.FETCH, getSubscriptionV2Details);
  yield takeEvery(SUBSCRIPTIONV2DETAILS.PAYMENTS_FETCH, getSubscriptionV2Payments);
  yield takeEvery(SUBSCRIPTIONV2.SEARCH_FETCH, getPlanV2Search);
  yield takeEvery(SUBSCRIPTIONV2.ADD, addSubscriptionV2);
  yield takeEvery(PLANV2.FETCH, getPlanV2);
  yield takeEvery(PLANV2.ADD, addPlanV2);
  yield takeEvery(PLANV2DETAILS.FETCH, getPlanV2Details);
  yield takeEvery(PLANV2DETAILS.PAYMENTS_FETCH, getPlanV2Payments);
  yield takeEvery(SUBSCRIPTION_BUTTONV2.FETCH, getSubscriptionButtonV2);
  yield takeEvery(SUBSCRIPTION_BUTTONV2.ADD, addSubscriptionButtonV2);
  yield takeEvery(SUBSCRIPTION_BUTTONV2DETAILS.FETCH, getSubscriptionButtonV2Details);
  yield takeEvery(SUBSCRIPTION_BUTTONV2DETAILS.PAYMENTS_FETCH, getSubscriptionButtonV2List);
  yield takeEvery(SUBSCRIPTION_BUTTONV2DETAILS.DELETE, deactiveSubscriptionV2Button);
  yield takeEvery(SUBSCRIPTION_BUTTONV2DETAILS.UPDATE, updateSubscriptionButtonV2);
  yield takeEvery(GETSHOPDETAILS.FETCH, getOrderShopDetails);
  yield takeEvery(GETSHOPDETAILS.ORDER_FETCH, getShopOrdersDetails);
  yield takeEvery(GETSHOPDETAILS.UPDATE, updateLineItems);
  yield takeEvery(GETSHOPDETAILS.SENDTOKEN, sendFcmToken);
  yield takeEvery(GETSHOPDETAILS.SHOP_FETCH, getAllShopDetails);
  yield takeEvery(GETSHOPDETAILS.FULLFILL_FETCH, getFullfillDetails);
  yield takeEvery(GETSHOPDETAILS.UPDATE_FULLFILL, updateFullFill);
  yield takeEvery(DINING.SECTION_FETCH, getDiningSections);
  yield takeEvery(DINING.SECTION_ADD, addDiningSection);
  yield takeEvery(DINING.TABLE_FETCH, getDiningTable);
  yield takeEvery(DINING.TABLE_ADD, addDiningTable);
  yield takeEvery(DINING.TABLE_UPDATE, updateDiningTable);
  yield takeEvery(DINING.TABLE_DELETE, deleteDiningTable);
  yield takeEvery(DINING.SECTION_UPDATE, updateSection);
  yield takeEvery(DINING.TABLE_DETAILS, fetchDiningDetails);
  yield takeEvery(DINING.SECTION_DELETE, deleteDiningSection);
  yield takeEvery(DINING.ASSIGNTABLE_FETCH, getAssignTable);
  yield takeEvery(DINING.ASSIGN_TABLE, assignTable);
  yield takeEvery(DINING.ASSIGN_TABLE_UPDATE, updateAssignTable);
  yield takeEvery(DINING.ASSIGN_TABLE_DELETE, deleteUserTable);
  yield takeEvery(CUSTOM_DASHBOARD.FETCH, getCustomDashboard);
  yield takeEvery(CUSTOM_DASHBOARD.ADD, addCustomDashboard);
  yield takeEvery(CUSTOM_DASHBOARD.UPDATE, updateCustomDashboard);
  yield takeEvery(CLOVER_SYNC.FETCH, getCloverSync);
  yield takeEvery(CLOVER_SYNC.ADD, addCloverSync);
  yield takeEvery(CLOVER_SYNC.UPDATE, updateCloverSync);
  yield takeEvery(INVENTORY_RESERVATIONS.FETCH, getInventoryReservations);
  yield takeEvery(INVENTORY_RESERVATIONS.FETCH_FILTER_RESERVATIONS, getFilterReservations);
  yield takeEvery(INVENTORY_RESERVATIONS.DELETE, deleteInventoryReservations);
  yield takeEvery(INVENTORY_RESERVATIONS.FETCH_HOLD_RESERVATIONS, getHoldReservations);
  yield takeEvery(INVENTORY_RESERVATIONS_DETAILS.FETCH, getInventoryReservationsDetails);
  yield takeEvery(INVENTORY_RESERVATIONS_DETAILS.DELETE, cancelInventoryReservations);
  yield takeEvery(CUSTOMER_SUPPORT.FETCH_SHOPS, getShops);
  yield takeEvery(CUSTOMER_SUPPORT.FETCH_SUPPORTS, getSupportType);
  yield takeEvery(CUSTOMER_SUPPORT.SUPPORTS_UPDATE, updateSupportType);
  yield takeEvery(CUSTOMER_SUPPORT.FETCH_FILTER_SUPPORTS, getFilterSupportType);
  yield takeEvery(CUSTOMER_SUPPORT.QUICKSUPPORT_FETCH, getQuickSupportType);
  yield takeEvery(SERVICERATE.FETCH, getServicerates);
  yield takeEvery(SERVICERATE.ADD, addServicerate);
  yield takeEvery(SERVICERATE.UPDATE, updateServicerate);
  yield takeEvery(SERVICERATE.DELETE, deleteServicerate);
  yield takeEvery(QUICK_SUPPORT_SUMMARY.FETCH, getQuickSupportSummary);
  yield takeEvery(QUICK_SUPPORT_SUMMARY.FETCH_SECTION, getQuickSupportSection);
  yield takeEvery(QUICK_SUPPORT_SUMMARY.UPDATE, updateQuickSupportSummary);
  yield takeEvery(TABLE_DETAILS.FETCH, getTableDetails);
  yield takeEvery(TABLE_DETAILS.FETCH_STAFF, getStaffDetails);
  yield takeEvery(TABLE_DETAILS.FETCH_GUEST, getGuestDetails);
  yield takeEvery(MENUS.FETCH, getInventoryMenus);
  yield takeEvery(MENUS.DELETE, deleteInventoryMenus);
  yield takeEvery(MENUS.FETCH_OPERATING_HOURS, getMenusOperatingHours);
  yield takeEvery(MENUS.ADD_MENUS, addInventoryMenu);
  yield takeEvery(MENUS.UPDATE_MENUS, updateInventoryMenus);
  yield takeEvery(OPERATINGHOURS.FETCH, getHoursOfOPeratins);
  yield takeEvery(OPERATINGHOURS.ADD_OPERATING_HOURS, addHoursOfOperations);
  yield takeEvery(OPERATINGHOURS.UPDATE_OPERATING_HOURS, updateHoursOfOperations);
  yield takeEvery(OPERATINGHOURS.DELETE_OPERATING_HOURS, deleteHoursOfOperations);
  yield takeEvery(INVENTORY_PREORDERS.FETCH, getInventoryPreorders);
  yield takeEvery(INVENTORY_PREORDERS.FETCH_FILTER_PREORDERS, getFilterPreorders);
  yield takeEvery(SEQUENCE_GENERATORS.FETCH, getSequenceGenerators);
  yield takeEvery(SEQUENCE_GENERATORS.FETCH_POS, getPOSSequenceGenerators);
  yield takeEvery(SEQUENCE_GENERATORS.FETCH_INVOICE, getInvoiceSequenceGenerators);
  yield takeEvery(SEQUENCE_GENERATORS.FETCH_CURRENT_INVOICE, getCurrentInvoiceSequence);
  yield takeEvery(SEQUENCE_GENERATORS.ADD_ACCOUNT_SEQUENCE, addSequenceGenerators);
  yield takeEvery(SEQUENCE_GENERATORS.ADD_INVOICE_SEQUENCE, addInvoiceSequenceGenerators);
  yield takeEvery(SEQUENCE_GENERATORS.UPDATE_ACCOUNT_SEQUENCE, updateSequenceGenerator);
  yield takeEvery(SEQUENCE_GENERATORS.UPDATE_POS_SEQUENCE, updatePOSSequenceGenerator);
  yield takeEvery(SEQUENCE_GENERATORS.UPDATE_INVOICE_SEQUENCE, updateInvoiceSequence);
  yield takeEvery(SEQUENCE_GENERATORS.ADD_POS_SEQUENCE, addPOSSequenceGenerators);
  yield takeEvery(FEEDBACK.FETCH, getInventoryFeedack);
  yield takeEvery(FEEDBACK.ORDER_FEEDBACK_FETCH, getInventoryOrderFeedack);
  yield takeEvery(FEEDBACK.ADD, addInventoryFeedback);
  yield takeEvery(FEEDBACK.UPDATE, updateInventoryFeedback);
  yield takeEvery(FEEDBACK.DELETE, deleteInventoryFeedback);
  yield takeEvery(ADVANCEFEES.FETCH, getAdvanceFees);
  yield takeEvery(ADVANCEFEES.ADD, addAdvanceFee);
  yield takeEvery(ADVANCEFEES.UPDATE, updateAdvanceFee);
  yield takeEvery(ADVANCEFEES.DELETE, deleteAdvanceFee);
  yield takeEvery(ADVANCEFEES.FETCH_BY_ID, fetchAdvanceFeeById);
  yield takeEvery(ADVANCEFEES.FETCH_ADD_ITEMS, getAdvanceFeeItems);
  yield takeEvery(MANAGE_ACCOUNTS.FETCH, getSelfAccounts);
  yield takeEvery(MANAGE_ACCOUNTS.ADD_ACCOUNT, addSelfAccount);
  yield takeEvery(MANAGE_ACCOUNTS.DELETE, deleteSelefAccount);
  yield takeEvery(MANAGE_ACCOUNTS.UPDATE_ACCOUNT, updateSelfAccount);
  yield takeEvery(MANAGE_ACCOUNTS.FETCH_BY_ID, fetchSelfAccountById);
  yield takeEvery(ACCOUNT_SESSION.FETCH, getAccountSession);
  yield takeEvery(ACCOUNT_SESSION.FETCH_INVOICES, getAccountSessionInvoices);
  yield takeEvery(ACCOUNT_SESSION.FETCH_VIEW_INVOICES, getAccountSessionViewInvoices);
  yield takeEvery(DONATION_PAGES.FETCH, getDonationPages);
  yield takeEvery(DONATION_PAGES.DONATION_ADD, addDonationPage);
  yield takeEvery(DONATION_PAGES.DONATION_UPDATE, updateDonationPage);
  yield takeEvery(DONATION_DETAILS_PAGES.FETCH, getDonationPageDetails);
  yield takeEvery(DONATION_DETAILS_PAGES.SLUG_FETCH, getDonationPageSlug);
  yield takeEvery(DONATION_DETAILS_PAGES.NOTIFY, getNotifyDonationPage);
  yield takeEvery(DONATION_DETAILS_PAGES.SOLDOUT, soldOutDonationPage);
  yield takeEvery(DONATION_DETAILS_PAGES.RESUMESALE, resumeSaleDonationPage);
  yield takeEvery(DONATION_DETAILS_PAGES.DELETE, deactiveDonationpage);
  yield takeEvery(DONATION_DETAILS_PAGES.DONATION_OFFLINE_DONATION, offlineDonationPage);
  yield takeEvery(DONATION_DETAILS_PAGES.DONATION_PAGE_FETCH, getDonationPagesEntries);
  yield takeEvery(DONATION_DETAILS_PAGES.DONATION_PAYMENTS_FETCH, getDonationPagesList);
  yield takeEvery(DONATION_DETAILS_PAGES.DONATION_OFFLINE_PAYMENTS_FETCH, getOfflineDonationsList);
  yield takeEvery(EVENT_PAGES.FETCH, getEventPages);
  yield takeEvery(EVENT_PAGES.ADD_EVENT, addEventPage);
  yield takeEvery(EVENT_PAGES.FETCH_RECEIPT, getEventPageReceipts);
  yield takeEvery(EVENT_PAGE_DETAILS.FETCH, getEventPageDetails);
  yield takeEvery(EVENT_PAGE_DETAILS.PRODUCT_ITEM_FETCH, getEventPageProductItemDetails);
  yield takeEvery(EVENT_PAGE_DETAILS.PAYMENT_FETCH, getEventPageOrderPaymentData);
  yield takeEvery(EVENT_PAGE_DETAILS.CUSTOM_TEMPLATE_FETCH, getEventPageCustomTemplates);
  yield takeEvery(EVENT_PAGE_DETAILS.CUSTOM_TEMPLATE_DELETE, removeCustomTemplateEventPage);
  yield takeEvery(EVENT_PAGE_DETAILS.UPDATE, updateEventPage);
  yield takeEvery(EVENT_PAGE_DETAILS.DEACTIVE, deactivateEventPage);
  yield takeEvery(EVENT_PAGE_DETAILS.SOLDOUT, soldOutEventPage);
  yield takeEvery(EVENT_PAGE_DETAILS.RESUME_SALES, resumeSalesNon);
  yield takeEvery(EVENT_PAGE_DETAILS.EVENT_PAGE_SLUG_FETCH, getEventPagesSlug);
  yield takeEvery(EVENT_PAGE_DETAILS.EVENT_PAGE_PROMOS, getPromosByEventPage);
  yield takeEvery(EVENT_PAGE_DETAILS.EVENT_PAGE_ALL_PROMOS, getAllPromosByEventPage);
  yield takeEvery(EVENT_PAGE_DETAILS.EVENT_PAGE_PROMO_ADD, addPromosByEventPage);
  yield takeEvery(EVENT_PAGE_DETAILS.EVENT_PAGE_PROMO_UPDATE, updatePromosByEventPage);
  yield takeEvery(EVENT_PAGE_DETAILS.EVENT_PAGE_PROMO_DELETE, deletePromosByEventPage);
  yield takeEvery(EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_DETAILS_FETCH, getEventPageSecureDetails);
  yield takeEvery(EVENT_PAGE_DETAILS.EVENT_PAGE_SPLIT_UP_FETCH, getEventPageSplitUp);
  yield takeEvery(EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_CREATE_ORDER, createEventPageOfflineOrder);
}
