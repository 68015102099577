// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import {
  Input,
  Select,
  DatePicker,
  Checkbox,
  AutoComplete,
  Row,
  Col,
  Icon,
  Divider,
} from 'antd';

import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import formatNumber from 'utils/formatNumber';
import getDiscountOff from 'utils/getDiscountOff';
import getTaxAmount from 'utils/getTaxAmount';
import getFrequency from 'utils/getFrequency';
import {
  SpH5,
  SpError,
  SpText,
} from 'components/DesignKit';
import {
  validators,
} from 'utils/validationMessages';
import {
  COUNTRIES,
  MAX_AMOUNT,
  RATE_TYPES,
  ROLES,
} from 'appconstants';
import getSortedList from 'utils/getSortedList';
import SelectShippingFee from 'routes/Invoices/components/NewInvoice/SelectShippingFee';

const { Option } = Select;

type Props = {
  submitting: boolean,
  selectedAccount: Object,
  planData: Object,
  fetchPlanDetails: Function,
  updatedCoupon: Object,
  allSelectedTaxrates: Array<Object>,
  removeCoupon: Function,
  removeTax: Function,
  role: Object,
  productsList: Array<Object>,
  getListItems: Function,
  addNewProductforPlan: Function,
  addNewItemforPlan: Function,
  addNewCuponforPlan: Function,
  addNewTaxforPlan: Function,
  addedNewProduct: Function,
  addedNewItem: Object,
  addedProduct: Boolean,
  addedItem: Boolean,
  onItemSearch: Function,
  inventoryItems: Array<Object>,
  legacySubscriber: Boolean,
  getListProducts: Function,
  onProductSearch: Function,
  getDataItems: Function,
  updateShippingAmount: Function,
};

const PlanDetails = (props: Props, ref) => {
  const {
    submitting,
    selectedAccount,
    planData,
    fetchPlanDetails,
    updatedCoupon,
    allSelectedTaxrates,
    removeCoupon,
    removeTax,
    role,
    productsList,
    getListItems,
    addNewProductforPlan,
    addNewItemforPlan,
    addNewCuponforPlan,
    addNewTaxforPlan,
    addedNewProduct,
    addedProduct,
    onItemSearch,
    inventoryItems,
    legacySubscriber,
    getListProducts,
    onProductSearch,
    getDataItems,
    addedNewItem,
    addedItem,
    updateShippingAmount,
  } = props;
  const [, forceUpdate] = useState();
  const [planName, setPlanName] = useState(planData.planName);
  const [period, setPeriod] = useState(planData.period);
  const [description, setDescription] = useState(planData.descriptions);
  const [count, setCount] = useState(planData.counts ? planData.counts : null);
  const [dop, setDop] = useState(planData.planDate);
  const [frequency, setFrequency] = useState(planData.pfrequency);
  const [startDate, setStartDate] = useState(planData.startDates);
  const [planGid, setPlanGid] = useState(planData.plansGid);
  const [disablePlanDetails, setDisablePlanDetails] = useState(planData.disableSubscriptionPlan);
  const [dateDisable, setDateDisable] = useState(false);
  const [planApiDate, setPlanApiDate] = useState(planData.apiPlanDate);
  const [notify, setNotify] = useState(planData.notify);
  const plans = getSortedList(planData.planList);
  const prefix = planData && (planData.prefix);
  const currencyCode = planData && (planData.currencyCode);
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const disableDate = planData.planDate;
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const currencyObj = selectedAccount && selectedAccount.currency;
  const [applicationFee, setApplicationFee] = useState(planData ? formatAmount(planData.applicationFee, currencyType) : '0.00');
  const [lineItemDtoList, setLineItemDtosList] = useState(planData && planData.lineItemDtoList);
  const [totalAmount, setTotalAmount] = useState(planData && planData.totalAmount);
  const [selectShippingFeeModal, setSelectedShippingFeeModal] = useState(false);
  const [shippingAmount, setShippingAmount] = useState(planData.shippingAmount);
  const cupoAaddon = (updatedCoupon);
  const allRatesAaddon = (allSelectedTaxrates);
  const [lineItemIndex, setLineItemIndex] = useState(null);
  const [upFrontError, setUpFrontError] = useState(null);
  const STRING_LENGTH = 5;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      percentage: {
        message: MAX_AMOUNT.MESSAGE_PERCENTAGE,
        rule: (val) => MAX_AMOUNT.MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_MAX,
      },
      frequency: {
        message: 'Billing period should not be more than 365 days',
        // eslint-disable-next-line radix
        rule: (val) => parseInt(val) <= 365,
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const searchPlan = (value) => {
    fetchPlanDetails(value);
    setPlanName(value);
    const results = plans.filter((item) => (item.name === value));
    if (results.length === 0) {
      setDisablePlanDetails(false);
      setPlanGid('');
    }
  };

  const onItemListSearch = (productSearch) => {
    onItemSearch(productSearch);
  };

  const removeShippingFee = () => {
    setShippingAmount(null);
    updateShippingAmount(0);
    simpleValidator.current.purgeFields();
  };
  const updateShippingFee = (shippingFee: Number) => {
    setShippingAmount(shippingFee);
    updateShippingAmount(shippingFee);
    setSelectedShippingFeeModal(false);
  };


  const onProductListSearch = (productSearch) => {
    onProductSearch(productSearch);
  };

  useEffect(() => {
    if (addedProduct && addedNewProduct && addedNewProduct.gid && lineItemDtoList) {
      const list = [...lineItemDtoList];
      const { amount } = addedNewProduct;
      list[lineItemIndex].name = addedNewProduct.name;
      list[lineItemIndex].description = addedNewProduct.name;
      list[lineItemIndex].note = addedNewProduct.note;
      list[lineItemIndex].rate = (amount / 100).toFixed(2);
      list[lineItemIndex].productGid = addedNewProduct.gid;
      // only upfront key is set while adding products
      list[lineItemIndex].recurring = !addedNewProduct.upfront;
      list[lineItemIndex].upfront = addedNewProduct.upfront;
      list[lineItemIndex].currencyCode = addedNewProduct.currency && addedNewProduct.currency.name;
      if (list[lineItemIndex].quantity) {
        list[lineItemIndex].amount = amount * parseInt(list[lineItemIndex].quantity, 10);
      }
      const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
      setTotalAmount(amountTotal);
      setLineItemDtosList(list);
    }
  }, [addedNewProduct]);

  useEffect(() => {
    if (addedItem && addedNewItem && addedNewItem.gid && lineItemDtoList) {
      const list = [...lineItemDtoList];
      const { price } = addedNewItem;
      list[lineItemIndex].name = addedNewItem.name;
      list[lineItemIndex].description = addedNewItem.name;
      list[lineItemIndex].note = addedNewItem.note;
      list[lineItemIndex].rate = (price / 100).toFixed(2);
      list[lineItemIndex].itemGid = addedNewItem.gid;
      list[lineItemIndex].upfront = !addedNewItem.recurring;
      list[lineItemIndex].recurring = addedNewItem.recurring;
      list[lineItemIndex].currencyCode = currencyCode;
      if (list[lineItemIndex].quantity) {
        list[lineItemIndex].price = price * parseInt(list[lineItemIndex].quantity, 10);
      }
      const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
      setTotalAmount(amountTotal);
      setLineItemDtosList(list);
    }
  }, [addedNewItem]);

  const updatePlan = (value) => {
    const results = plans.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setPlanName(results[0].name);
      setDescription(results[0].description);
      setPlanGid(results[0].gid);
      setPeriod(results[0].billingPeriod);
      setFrequency(results[0].billingFrequency);
      setDisablePlanDetails(true);
    }
  };

  const onDateChange = (date, dateString) => {
    setDop(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setPlanApiDate(utcDate);
  };

  const onCheckChange = (e) => {
    const dateChecked = e.target.checked;
    setStartDate(dateChecked);
    const displayFormat = moment().utc().tz(selectedAccount.timezone).startOf('day')
      .format(dateFormat);
    // eslint-disable-next-line
    const selectedDate = moment.utc().tz(selectedAccount.timezone).startOf('day').utc().format(MOMENT_FORMAT);
    setPlanApiDate(selectedDate);
    if (dateChecked) {
      setDateDisable(true);
      setDop(displayFormat);
    } else {
      setDateDisable(false);
    }
  };

  const getAmountWithShippingFee = (amount) => {
    if (shippingAmount) {
      const numericAmount = parseFloat(amount.replace(/,/g, '')) || 0;
      const numericShippingAmount = parseFloat(shippingAmount) || 0;
      const addedShippingAmount = numericAmount + numericShippingAmount;
      return addedShippingAmount.toFixed(2);
    }
    return amount;
  };

  const getFinalAmount = (amount) => {
    let finalAmount;
    const listOfTaxes = [...allSelectedTaxrates];
    const taxLists = listOfTaxes.filter((item) => item.taxRates.inclusive === false);
    if (taxLists.length > 0) {
      const percentageAmount = taxLists.reduce(
        (total, item) => total + ((parseFloat(amount.replace(/,/g, '')) * item.taxRates.percentage) / 100), 0,
      );
      finalAmount = formatNumber((parseFloat(amount.replace(/,/g, '')) + percentageAmount).toFixed(2), currencyObj);
    } else {
      finalAmount = amount;
    }
    return finalAmount;
  };

  const getTotalAmount = (value) => {
    let finalAmount;
    if (value) {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      finalAmount = formatNumber((totalAmount - finalValue).toFixed(2), currencyObj);
    } else {
      finalAmount = formatNumber((totalAmount).toFixed(2), currencyObj);
    }
    return finalAmount;
  };

  const getAmountOff = (value) => {
    let amountOff;
    if (value.percentageOff) {
      const amount = (totalAmount * value.percentageOff) / 100;
      amountOff = formatNumber((amount).toFixed(2), currencyObj);
    } else if (value.amountOff) {
      amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyObj);
    }
    return amountOff;
  };


  const handleChange = (e, index) => {
    const gid = e[1];
    const productInfo = inventoryItems.filter((item) => (item.gid === gid));
    const list = [...lineItemDtoList];
    const { price } = productInfo[0];
    // list[index].productGid = productInfo[0].gid;
    list[index].upfront = !productInfo[0].recurring;
    list[index].recurring = productInfo[0].recurring;
    list[index].currencyCode = currencyCode;
    list[index].name = productInfo[0].name;
    list[index].itemGid = gid;
    list[index].description = productInfo[0].description;
    list[index].note = productInfo[0].note;
    list[index].rate = (price / 100).toFixed(2);
    if (list[index].quantity) {
      list[index].price = price * parseInt(list[index].quantity, 10);
    }
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };
  const handleChangeProduct = (e, index) => {
    const gid = e[1];
    const productInfo = productsList.filter((item) => (item.gid === gid));
    const list = [...lineItemDtoList];
    const { amount } = productInfo[0];
    list[index].productGid = productInfo[0].gid;
    list[index].upfront = productInfo[0].upfront;
    list[index].currencyCode = productInfo[0].currency && productInfo[0].currency.name;
    // list[index].productGid = productInfo[0].gid;
    list[index].name = productInfo[0].name;
    list[index].description = productInfo[0].description;
    list[index].note = productInfo[0].note;
    list[index].rate = (amount / 100).toFixed(2);
    if (list[index].quantity) {
      list[index].amount = amount * parseInt(list[index].quantity, 10);
    }
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const handleRemoveClick = index => {
    const list = [...lineItemDtoList];
    list.splice(index, 1);
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.currentTarget;
    let regex;
    if (name === 'quantity') {
      regex = /^[0-9\b]+$/;
    } else if (name === 'rate') {
      regex = /^\d*\.?\d*$/;
    }
    const list = [...lineItemDtoList];
    if (regex.test(value) || value === '') {
      list[index][name] = value;
      if (name === 'quantity' && value && list[index].rate) {
        const sum = list[index].rate * 100;
        list[index].amount = sum * parseInt(list[index][name], 10);
      } else if (name === 'rate' && value && list[index].quantity) {
        const sum = list[index].rate * 100;
        list[index].amount = sum * parseInt(list[index].quantity, 10);
      }
    } else if (!regex.test(value) && name === 'quantity') {
      list[index][name] = '1';
    } else if (!regex.test(value) && name === 'rate') {
      list[index][name] = 0;
    }
    const amountTotal = list.reduce((total, item) => total + ((item.rate * item.quantity)), 0);
    setTotalAmount(amountTotal);
    setLineItemDtosList(list);
  };

  const handleAddClick = (Type) => {
    if (Type === 'product') {
      getListItems();
      getListProducts();
    } else {
      getDataItems();
    }

    setLineItemDtosList([...lineItemDtoList, {
      amount: 0,
      rate: 0,
      productGid: '',
      currencyCode: '',
      description: '',
      name: '',
      note: '',
      quantity: '1',
      recurring: '',
      itemGid: '',
    }]);
  };
  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      let upfrontValidate = true;

      if (legacySubscriber) {
        const filterUpfronts = lineItemDtoList.filter(item => item.upfront === false);
        if (filterUpfronts.length === 0) {
          upfrontValidate = false;
          setUpFrontError('Please select at least one recurring product');
        }
      } else {
        const filterRecurring = lineItemDtoList.filter(item => item.recurring === true);
        if (filterRecurring.length === 0) {
          upfrontValidate = false;
          setUpFrontError('Please select at least one recurring item');
        }
      }

      if (upfrontValidate) {
        setUpFrontError(null);
      }

      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      } if (!upfrontValidate) {
        forceUpdate(1);
        return false;
      }
      return true;
    },

    getValues: () => ({
      planName,
      period,
      description,
      count,
      dop,
      frequency,
      startDate,
      planGid,
      planApiDate,
      applicationFee,
      disablePlanDetails,
      notify,
      prefix,
      currencyCode,
      lineItemDtoList,
      cupoAaddon,
      allRatesAaddon,
      totalAmount,
      shippingAmount,
      // subscriptionAmount: getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon))),
    }),
  }));

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-3">
          <label htmlFor=""><SpH5>Plan Name</SpH5></label>
          <AutoComplete
            placeholder="Enter a plan name"
            showSearch
            className="w-100"
            dataSource={plans.map((item, idx) => (
              <Option key={idx} value={item.gid}>
                {item.name}
              </Option>
            ))}
            style={{ height: '40px' }}
            onSearch={searchPlan}
            defaultValue={planName}
            onSelect={e => updatePlan(e)}
          />
          <SpError>
            {simpleValidator.current.message('plan name', planName, 'required|stringLength')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Plan Description</SpH5></label>
          <Input
            placeholder="Optional"
            value={description}
            disabled={disablePlanDetails}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>

        {
          (role && role.name === ROLES.PARTNER) && (
            <div className="mb-3">
              <div className="d-flex">
                <div style={{ width: '20%' }}>
                  <label htmlFor=""><SpH5>Application Fee</SpH5></label>
                  <div className="d-flex">
                    <Input
                      value={applicationFee}
                      suffix="%"
                      onChange={(e) => {
                        const regex = /^\d*\.?\d*$/;
                        const { value } = e.currentTarget;
                        if (regex.test(value) || value === '') {
                          setApplicationFee(e.currentTarget.value);
                        } else if (!regex.test(value)) {
                          setApplicationFee(0.00);
                        }
                      }}
                      onKeyPress={e => {
                        const keyCode = e.charCode || e.which;
                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={e => {
                        if (e.currentTarget.value) {
                          const value = formatAmount(e.currentTarget.value, currencyType);
                          setApplicationFee(value);
                        }
                      }}
                    />
                  </div>
                  <SpError>
                    {simpleValidator.current.message('percentage', applicationFee, 'required|percentage')}
                  </SpError>
                </div>
              </div>
            </div>
          )
        }
        <div className="mt-2 mb-1">
          <label htmlFor="">
            {legacySubscriber ? <SpH5>Select Product</SpH5> : <SpH5>Select Item</SpH5>}

            <div className="pt-1">
              {
                upFrontError && (
                  <SpError>{upFrontError}</SpError>
                )
              }
            </div>
          </label>
        </div>
        <div
          className="mb-4"
          style={{
            border: '1px solid #C4C4C4',
            background: '#ffffff',
            borderRadius: '6px',
            paddingLeft: '5px',
          }}
        >
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={8}>
              <div className="pt-1 pb-1 pl-1"><SpText fontWeight="600">Description</SpText></div>
            </Col>
            <Col span={16}>
              <Row type="flex" justify="end">
                <Col span={5} className="pt-1 mr-3" align="left">
                  <SpText fontWeight="600" fontSize="14px">Recurring?</SpText>
                </Col>
                <Col span={4} className="pt-1 mr-3" align="left">
                  <SpText fontWeight="600" fontSize="14px">Rate</SpText>
                </Col>
                <Col span={4} className="pt-1 mr-3" align="center">
                  <SpText fontWeight="600" fontSize="14px">Quantity</SpText>
                </Col>
                <Col span={4} className="pt-1 mr-3" align="right">
                  <SpText fontWeight="600" fontSize="14px">Total</SpText>
                </Col>
                <Col span={2} className="pt-1" align="right" />
              </Row>
            </Col>
          </Row>
          {legacySubscriber
            ? (
              <>
                <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 0 0 5px' }}>
                  {lineItemDtoList.map((item, i) => (
                    <>
                      <Col span={8} className="pb-1">
                        <div className="w-100 pr-3">
                          <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                            <Select
                              value={item.name || 'Select a Product'}
                              style={{ width: '100%' }}
                              showSearch
                              onSearch={onProductListSearch}
                              onSelect={e => handleChangeProduct(e, i)}
                              dropdownRender={menu => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '2px 0' }} />
                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                                    <span
                                      style={{
                                        flex: 'none',
                                        padding: '8px',
                                        display: 'block',
                                        cursor: 'pointer',
                                        color: '#279dfe',
                                      }}
                                      onClick={() => {
                                        addNewProductforPlan(i);
                                        setLineItemIndex(i);
                                      }}
                                    >
                                      <Icon type="plus-circle" />&nbsp;Create New Product
                                    </span>
                                  </div>
                                </div>
                              )}
                            >
                              {productsList.map((product) => (
                                <Option
                                  key={product.gid}
                                  value={[
                                    product.name,
                                    product.gid,
                                  ]}
                                >
                                  {product.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <SpError>
                            {simpleValidator.current.message('product', lineItemDtoList[i].name, 'required')}
                          </SpError>
                        </div>
                      </Col>
                      <Col span={16}>
                        <Row type="flex" justify="end">
                          <Col span={4} className="pt-1 mr-2">
                            {(item.upfront === true) ? 'No' : 'Yes'}

                          </Col>
                          <Col span={4} className="pt-1 mr-4">
                            <SpText fontWeight="600">{prefix} {item.rate || '0.00'}</SpText>
                          </Col>
                          <Col span={4} className="mr-3">
                            <Input
                              name="quantity"
                              value={item.quantity}
                              maxlength="3"
                              onChange={e => handleInputChange(e, i)}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <SpError>
                              {simpleValidator.current.message('Quantity', lineItemDtoList[i].quantity, 'required|numeric')}
                            </SpError>
                          </Col>
                          <Col span={4} className="pt-1 mr-3" align="right">
                            <SpText fontWeight="600">{prefix} {formatNumber((item.rate * item.quantity).toFixed(2), currencyObj)}</SpText>
                          </Col>
                          <Col span={2} className="pt-1" align="right">
                            {i !== 0 ? (
                              <Icon
                                type="minus-circle"
                                className="mr-2 mt-1"
                                style={{ cursor: 'pointer', color: '#ff4d50' }}
                                onClick={() => handleRemoveClick(i)}
                              />
                            ) : (
                              <span className="mr-2 mt-2">&nbsp;&nbsp;&nbsp;</span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ))}
                </Row>
                <Row type="flex" className="mt-2">
                  <Col span={10}>
                    <div className="pt-1 pb-1 ml-2" style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={() => handleAddClick('product')}
                      >
                        {'\u002B'}&nbsp; Add New Product
                      </SpText>
                    </div>
                  </Col>
                  <Col span={14}>
                    <Row type="flex" justify="end" className="ml-1">
                      <Col span={12} className="pt-1 ml-5" align="left">
                        <SpText fontWeight="600" fontSize="14px">Subtotal</SpText>
                      </Col>
                      <Col span={6} className="pt-1 pr-3" align="right">
                        <SpText fontWeight="600">
                          {`${prefix} ${formatNumber((totalAmount).toFixed(2), currencyObj)}`}
                        </SpText>
                      </Col>
                      <Col span={2} className="pt-1" align="right" />
                    </Row>
                  </Col>
                </Row>
              </>
            )
            : (
              <>
                <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 0 0 5px' }}>
                  {lineItemDtoList.map((item, i) => (
                    <>
                      <Col span={8} className="pb-1">
                        <div className="w-100 pr-3">
                          <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                            <Select
                              value={item.name || 'Select a Item'}
                              style={{ width: '100%' }}
                              showSearch
                              onSearch={onItemListSearch}
                              onSelect={e => handleChange(e, i)}
                              dropdownRender={menu => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '2px 0' }} />
                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                                    <span
                                      style={{
                                        flex: 'none',
                                        padding: '8px',
                                        display: 'block',
                                        cursor: 'pointer',
                                        color: '#279dfe',
                                      }}
                                      onClick={() => {
                                        addNewItemforPlan(i);
                                        setLineItemIndex(i);
                                      }}
                                    >
                                      <Icon type="plus-circle" />&nbsp;Create New Item
                                    </span>
                                  </div>
                                </div>
                              )}
                            >
                              {inventoryItems.map((inventoryitem) => (
                                <Option
                                  key={inventoryitem.gid}
                                  value={[
                                    inventoryitem.name,
                                    inventoryitem.gid,
                                    inventoryitem.price,
                                  ]}
                                >
                                  {inventoryitem.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <SpError>
                            {simpleValidator.current.message('product', lineItemDtoList[i].name, 'required')}
                          </SpError>
                        </div>
                      </Col>
                      <Col span={16}>
                        <Row type="flex" justify="end">
                          <Col span={4} className="pt-1 mr-2">
                            {(item.recurring === true) ? 'Yes' : 'No'}
                          </Col>
                          <Col span={4} className="pt-1 mr-4">
                            <SpText fontWeight="600">{prefix} {item.rate || '0.00'}</SpText>
                          </Col>
                          <Col span={4} className="mr-3">
                            <Input
                              name="quantity"
                              value={item.quantity}
                              maxlength="3"
                              onChange={e => handleInputChange(e, i)}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <SpError>
                              {simpleValidator.current.message('Quantity', lineItemDtoList[i].quantity, 'required|numeric')}
                            </SpError>
                          </Col>
                          <Col span={4} className="pt-1 mr-3" align="right">
                            <SpText fontWeight="600">{prefix} {formatNumber((item.rate * item.quantity).toFixed(2), currencyObj)}</SpText>
                          </Col>
                          <Col span={2} className="pt-1" align="right">
                            {i !== 0 ? (
                              <Icon
                                type="minus-circle"
                                className="mr-2 mt-1"
                                style={{ cursor: 'pointer', color: '#ff4d50' }}
                                onClick={() => handleRemoveClick(i)}
                              />
                            ) : (
                              <span className="mr-2 mt-2">&nbsp;&nbsp;&nbsp;</span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ))}
                </Row>
                <Row type="flex" className="mt-2">
                  <Col span={10}>
                    <div className="pt-1 pb-1 ml-2" style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={() => handleAddClick('items')}
                      >
                        {'\u002B'}&nbsp; Add New Item
                      </SpText>
                    </div>
                  </Col>
                  <Col span={14}>
                    <Row type="flex" justify="end" className="ml-1">
                      <Col span={12} className="pt-1 ml-5" align="left">
                        <SpText fontWeight="600" fontSize="14px">Subtotal</SpText>
                      </Col>
                      <Col span={6} className="pt-1 pr-3" align="right">
                        <SpText fontWeight="600">
                          {`${prefix} ${formatNumber((totalAmount).toFixed(2), currencyObj)}`}
                        </SpText>
                      </Col>
                      <Col span={2} className="pt-1" align="right" />
                    </Row>
                  </Col>
                </Row>

              </>
            )}
          <Row type="flex" justify="end" className="ml-2 mb-2">
            {updatedCoupon.gid ? (
              <Col span={14}>
                <Row type="flex" justify="end">
                  <Col span={12} className="pt-1 ml-3" align="left">
                    <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                      <SpText>
                        {updatedCoupon.name}
                      </SpText><br />
                      <SpText fontSize="12px">
                        {getDiscountOff(updatedCoupon)}
                      </SpText>
                    </div>
                    {/* <div>
                      <SpError>
                        {couponMsg}
                      </SpError>
                    </div> */}
                  </Col>
                  <Col span={6} className="pt-2 mr-3" align="right">
                    <SpText fontWeight="600">({prefix} {getAmountOff(updatedCoupon)})</SpText>
                  </Col>
                  <Col span={2} className="pt-1" align="right">
                    <Icon
                      type="minus-circle"
                      className="mr-2 mt-1"
                      style={{ cursor: 'pointer', color: '#ff4d50' }}
                      onClick={removeCoupon}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col span={14}>
                <Row type="flex" justify="end">
                  <Col span={12} className="pt-1 ml-3" align="left">
                    <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                      <SpText
                        fontWeight="600"
                        color="#279dfe"
                        onClick={() => addNewCuponforPlan(true)}
                      >
                        Apply Coupon
                      </SpText>
                    </div>
                  </Col>
                  <Col span={8} className="pt-1 mr-3" align="right" />
                </Row>
              </Col>
            )}
          </Row>
          <Row type="flex" justify="end" className="ml-2">
            {
              allSelectedTaxrates.map((item, i) => (
                <Col span={14}>
                  <Row type="flex" justify="end">
                    <Col span={9} className="pt-1 ml-3" align="left">
                      <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                        <SpText>
                          {item.taxRates.displayName}
                        </SpText><br />
                        <SpText fontSize="12px">
                          {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                        </SpText>
                      </div>
                    </Col>
                    <Col span={3} className="pt-1" align="right">{item.taxRates.percentage} %</Col>
                    <Col span={6} className="pt-2 mr-3" align="right">
                      <SpText fontWeight="600">
                        {prefix} {getTaxAmount(item.taxRates, totalAmount, getAmountOff(updatedCoupon), selectedAccount)}
                      </SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right">
                      <Icon
                        type="minus-circle"
                        className="mr-2 mt-1"
                        style={{ cursor: 'pointer', color: '#ff4d50' }}
                        onClick={() => removeTax(i)}
                      />
                    </Col>
                  </Row>
                </Col>
              ))
            }
          </Row>
          <Row type="flex" justify="end" className="ml-2 mb-2">
            <Col span={14}>
              <Row type="flex" justify="end">
                <Col span={12} className="pt-1 ml-3" align="left">
                  <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => addNewTaxforPlan(true)}
                    >
                      {
                        allSelectedTaxrates.length !== 0 && (
                          <span>{'\u002B'}&nbsp;</span>
                        )
                      }
                      Add Tax Rates
                    </SpText>
                  </div>
                </Col>
                <Col span={8} className="pt-1 mr-3" align="right" />
              </Row>
            </Col>
          </Row>
          {/* ----------------------------------------------------------ADD SHIPPING FEE--------------------------------------------------------- */}
          <Row type="flex" justify="end" className="ml-2 mb-2">
            <Col span={14}>
              <Row type="flex" justify="end">
                <Col span={12} className="pt-1 ml-3" align="left">
                  <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                    <SpText
                      fontWeight="600"
                      color="#279dfe"
                      onClick={() => setSelectedShippingFeeModal(true)}
                    >
                      Add Shipping Fee
                    </SpText>
                  </div>
                </Col>
                {shippingAmount ? (
                  <Col span={8} className="pt-1 mr-3" align="right">
                    <SpText fontWeight="600">
                      {prefix} {shippingAmount}
                    </SpText>
                    <Icon
                      type="minus-circle"
                      className="mr-2 mt-1 ml-2 mb-1"
                      style={{ cursor: 'pointer', color: '#ff4d50' }}
                      onClick={removeShippingFee}
                    />
                  </Col>
                )
                  : <Col span={8} className="pt-1 mr-3" align="right" />}
              </Row>
            </Col>
          </Row>
          <hr />
          <Row type="flex" justify="end" className="mr-3 mb-1">
            <Col className="mt-1 pb-1 mr-3 pr-1">
              <SpText className="mr-5" fontWeight="500" fontSize="20px">
                Total Amount
              </SpText>
              <SpText fontWeight="600" fontSize="20px">
                {
                  (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                    `${prefix} 0.00`
                  ) : (
                    `${prefix} ${getAmountWithShippingFee(getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon))))}`
                  )
                }
              </SpText>
            </Col>
          </Row>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Billing Frequency</SpH5></label>
          <div className="d-flex align-items-center" style={{ width: '50%' }}>
            <span>Every</span>
            <div style={{ width: '18%', minWidth: '35px' }}>
              <Input
                className="ml-1"
                maxlength="3"
                value={period}
                disabled={disablePlanDetails}
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setPeriod(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setPeriod();
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div>
              <Select
                className="ml-3"
                style={{ width: '155%' }}
                value={frequency}
                disabled={disablePlanDetails}
                onChange={(e) => setFrequency(e)}
              >
                <Option key={1} value="DAY">Day(s)</Option>
                <Option key={2} value="WEEK">Week(s)</Option>
                <Option key={3} value="MONTH">Month(s)</Option>
                <Option key={4} value="YEAR">Year(s)</Option>
              </Select>
            </div>
          </div>
          {
            frequency === 'DAY' ? (
              <SpError>
                {simpleValidator.current.message('period', period, 'required|frequency')}
              </SpError>
            ) : (
              <SpError>
                {simpleValidator.current.message('period', period, 'required')}
              </SpError>
            )
          }
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Billing Amount</SpH5></label>
          <div>
            Customer will be charged&nbsp;
            <SpH5>
              {
                (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                  `${prefix} 0.00`
                ) : (
                  `${prefix} ${getAmountWithShippingFee(getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) || '0.00'}`
                )
              }
            </SpH5> every
            <SpH5> {`${period} ${getFrequency(frequency)}`}</SpH5>
          </div>
        </div>

        <div className="mb-3">
          <div className="d-flex">
            <div style={{ width: '50%' }}>
              <label htmlFor=""><SpH5>Subscription Start Date</SpH5></label>
              <div>
                <DatePicker
                  value={moment(dop)}
                  disabled={dateDisable}
                  format={dateFormat}
                  allowClear={false}
                  onChange={onDateChange}
                  disabledDate={current => current && current < moment(disableDate)}
                />
                <SpError>
                  {simpleValidator.current.message('Start Date', dop, 'required')}
                </SpError>
              </div>
            </div>
            <div className="ml-4 mt-3">
              <label htmlFor=""> </label>
              <div>
                <Checkbox
                  checked={startDate}
                  onChange={onCheckChange}
                /> Start Today, with the first payment
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3" style={{ width: '50%' }}>
          <label htmlFor=""><SpH5>Notify</SpH5></label>
          <Select
            className="w-100"
            value={notify}
            onChange={(e) => setNotify(e)}
          >
            <Option key={1} value="EMAIL">Email</Option>
            <Option key={2} value="SMS">SMS</Option>
            <Option key={4} value="ALL">Email and SMS</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('notify', notify, 'required')}
          </SpError>
        </div>

        <div className="mt-3" style={{ width: '50%' }}>
          <label htmlFor=""><SpH5>Total Count (Optional)</SpH5></label>
          <Input
            placeholder="No. of billing cycles to be charged"
            value={count}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              const { value } = e.currentTarget;
              if (regex.test(value) || value === '') {
                setCount(e.currentTarget.value);
              } else if (!regex.test(value)) {
                setCount(null);
              }
            }}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
          />
          <SpError>
            {simpleValidator.current.message('count', count, 'numeric')}
          </SpError>
        </div>
        {/* SELECT SHIPPINFEE MODAL */}
        {selectShippingFeeModal && (
          <SelectShippingFee
            visible={selectShippingFeeModal}
            submitting={submitting}
            close={() => setSelectedShippingFeeModal(false)}
            submit={updateShippingFee}
            prefix={prefix}
          />
        )}
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(PlanDetails);
