import React from 'react';
import { connect } from 'react-redux';
import {
  Card,
} from 'antd';

const AccountTransfers = () => (
  <Card>
    <h1>AccountTransfers</h1>
  </Card>
);

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
});

export default connect(mapStateToProps, null)(AccountTransfers);
