import { EXCHANGE_RATE_STATUSES } from 'appconstants';

export default value => {
  switch (value) {
    case 'ACTIVE':
      return EXCHANGE_RATE_STATUSES.ACTIVE;
    case 'EXPIRED':
      return EXCHANGE_RATE_STATUSES.EXPIRED;
    case 'INACTIVE':
      return EXCHANGE_RATE_STATUSES.INACTIVE;
    default:
      return undefined;
  }
};
