// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Input,
  Select,
  Switch,
} from 'antd';
import {
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';
import { SketchPicker } from 'react-color';
import {
  validators,
} from 'utils/validationMessages';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import { SpH5, SpError, SpTextMute } from 'components/DesignKit';
import {
  SpColor,
  SpCover,
  SpPopover,
  SpWatch,
} from 'components/PaymentWidgetDesignKit';

const { Option } = Select;

type Props = {
  submitting: boolean,
  currencyType: Object,
  selectedAccount: Object,
  basicsData: Object,
};

const Basics = (props: Props, ref) => {
  const {
    submitting,
    currencyType,
    selectedAccount,
    basicsData,
  } = props;
  const [, forceUpdate] = useState();
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const prefix = (selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix);
  const [name, setName] = useState(basicsData.name);
  const [alternateName, setAlternateName] = useState(basicsData.alternateName);
  const [code, setCode] = useState(basicsData.code);
  const [onlineName, setOnlineName] = useState(basicsData.onlineName);
  const [onlineEnabled, setOnlineEnabled] = useState(basicsData.onlineEnabled);
  const [sku, setSku] = useState(basicsData.sku);
  const [price, setPrice] = useState((basicsData && basicsData.price) ? formatAmount(basicsData.price, currencyType) : 0);
  const [cost, setCost] = useState((basicsData && basicsData.cost) ? formatAmount(basicsData.cost, currencyType) : 0);
  const [priceType, setPriceType] = useState(basicsData.priceType);
  const [unitName, setUnitName] = useState(basicsData.unitName);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [primaryColor, setColorCode] = useState(basicsData.primaryColor);
  const STRING_LENGTH = 1;
  const STRING_LENGTH_MAX = 127;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_ITEM_INR : MAX_AMOUNT.MESSAGE_ITEM_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      Validatecost: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_POSINR : MAX_AMOUNT.MESSAGE_POSUSD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minLimit: {
        message: 'Sort order must be valid',
        rule: val => val >= 0 && val < 999999,
      },
      stringLength: {
        message: validators.paymentLink.maxValidName,
        rule: val => val.length >= STRING_LENGTH && val.length <= STRING_LENGTH_MAX,
      },
      numericValidation: {
        message: 'Sort order must be valid',
        rule: (val) => (val ? !val.toString().includes('.') : true),
      },
    },
  }));

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (e) => {
    setColorCode(e.hex);
  };

  const updateColor = (e) => {
    setColorCode(e.currentTarget.value);
  };

  const isOnlineEnableChange = (value) => {
    setOnlineEnabled(value);
  };

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      name,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      price,
      cost,
      priceType,
      unitName,
      primaryColor,
      code,
    }),
  }));

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-4 mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            onChange={(e) => setName(e.currentTarget.value)}
            value={name}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required|stringLength')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Alternative Name</SpH5></label>
          <Input
            placeholder="Alternative Name"
            onChange={(e) => setAlternateName(e.currentTarget.value)}
            value={alternateName}
          />
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Online Name</SpH5></label>
          <Input
            placeholder="Online Name"
            onChange={(e) => setOnlineName(e.currentTarget.value)}
            value={onlineName}
          />
        </div>
        <div className="mb-3">
          <Switch
            className="mr-2"
            checked={onlineEnabled}
            onChange={isOnlineEnableChange}
          />
          <label htmlFor=""><SpH5>ONLINE</SpH5></label>

        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>SKU</SpH5></label>
          <Input
            placeholder="SKU"
            onChange={(e) => setSku(e.currentTarget.value)}
            value={sku}
          />
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Price</SpH5></label>
          <div>
            <Input
              value={price}
              prefix={prefix}
              placeholder="0.00"
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setPrice(e.currentTarget.value);
                } else if (!regex.test(value)) {
                  setPrice(0.00);
                }
              }}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const value = formatAmount(e.currentTarget.value, currencyType);
                  setPrice(value);
                }
              }}
            />
          </div>
          <SpError>
            {simpleValidator.current.message('price', price, 'Validatecost')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Cost</SpH5></label>
          <div>
            <Input
              value={cost}
              prefix={prefix}
              placeholder="Amount"
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setCost(e.currentTarget.value);
                } else if (!regex.test(value)) {
                  setCost(0.00);
                }
              }}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const value = formatAmount(e.currentTarget.value, currencyType);
                  setCost(value);
                }
              }}
            />
          </div>
          <SpError>
            {simpleValidator.current.message('cost', cost, 'Validatecost')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Pricing Type</SpH5></label>
          <div>
            <Select
              style={{ width: '100%' }}
              value={priceType || 'Select pricing type'}
              onChange={(e) => { setPriceType(e); e !== 'PER_UNIT' && setUnitName(''); }}
            >
              <Option key={1} value="FIXED">Fixed</Option>
              <Option key={2} value="VARIABLE">Variable</Option>
              <Option key={3} value="PER_UNIT">Per Unit</Option>
            </Select>
            <SpError>
              {simpleValidator.current.message('price type', priceType, 'required')}
            </SpError>
          </div>
        </div>
        {priceType === 'PER_UNIT' && (
          <div className="mb-3">
            <label htmlFor=""><SpH5>Unit Name</SpH5></label>
            <Input
              placeholder="Unit Name"
              onChange={(e) => setUnitName(e.currentTarget.value)}
              value={unitName}
            />
            <SpTextMute>oz,lb,kg...</SpTextMute>
            <SpError>
              {simpleValidator.current.message('Unit Name', unitName, 'required')}
            </SpError>
          </div>
        )}
        <div>
          <label htmlFor=""><SpH5>Item Color</SpH5></label>
          <div className="d-flex ml-1">
            <div>
              <SpWatch onClick={handleClick}>
                <SpColor className="color" style={{ backgroundColor: `${primaryColor}` }} />
              </SpWatch>
              {displayColorPicker ? (
                <>
                  <SpPopover
                    style={{
                      top: '350px !important',
                    }}
                  >
                    <SpCover onClick={handleClose} />
                    <SketchPicker color={primaryColor} onChange={handleChange} />
                  </SpPopover>
                </>
              ) : null}
            </div>
            <Input
              className="ml-3"
              value={primaryColor}
              placeholder="Item Color"
              onChange={updateColor}
            />
          </div>
          <SpError>
            {simpleValidator.current.message('color code', primaryColor, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Product Code</SpH5></label>
          <Input
            placeholder="Product Code"
            onChange={(e) => setCode(e.currentTarget.value)}
            value={code}
          />
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(Basics);
