// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Card,
  Row,
  Col,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  adminTerminalDetailsActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
  TERMINALS_STATUS,
} from 'appconstants';
import RBAC from 'components/rbac';
import NumberFormat from 'react-number-format';
import getBackground from 'utils/getBackground';
import getTerminalStatus from 'utils/getTerminalStatus';
import CONSTANTS from 'store/constants';
import getSettlementBackground from 'utils/getAutoSettlementBackground';

import DeleteTerminal from './components/deleteTerminal';
import EditTerminalPin from './components/EditTerminalPin';


type Props = {
  fetchAdminTerminalDetails: Function,
  adminTerminalDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  adminTerminalDetailsStatus: string,
  validator: boolean,
  deleteAdminTerminal: Function,
  updateTerminalPin: Function,
}

const AdminTerminalDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchAdminTerminalDetails,
    adminTerminalDetails,
    loading,
    submitting,
    history,
    selectedAccount,
    adminTerminalDetailsStatus,
    validator,
    deleteAdminTerminal,
    updateTerminalPin,
  } = props;
  const { gid } = props.match.params;
  const [deleteTerminalModal, setDeleteTerminalModal] = useState(false);
  const [editTerminalPin, setEditTerminalPin] = useState(false);
  const [phoneFormate, setPhoneFormate] = useState('');
  const [redirect, SetRedirect] = useState(false);

  const getData = () => {
    fetchAdminTerminalDetails(gid);
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.ADMIN_TERMINAL);
    }
    if (!validator) {
      setEditTerminalPin(false);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    if (adminTerminalDetails.gid) {
      const phone = adminTerminalDetails.contactNumber ? adminTerminalDetails.contactNumber : '';
      const cntryCode = adminTerminalDetails.contactNumber ? adminTerminalDetails.contactNumber.substring(0, 2) : '';
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (phone.length !== 14 && cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneFormate('+#-##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 14) {
        setPhoneFormate('+###-##########');
      }
    }
  }, [adminTerminalDetails]);

  const showDeleteTerminalModal = () => {
    setDeleteTerminalModal(true);
  };

  const deleteSelectedPaymentButton = async (terminalGid: string) => {
    deleteAdminTerminal(terminalGid);
    SetRedirect(true);
  };

  const editSelectedTerminalPin = async (terminalPin: Number) => {
    const params = {
      pin: terminalPin,
    };
    updateTerminalPin({
      params,
      gid,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        adminTerminalDetails.gid && adminTerminalDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {adminTerminalDetails.deviceName
                      ? adminTerminalDetails.deviceName
                      : <>&#8211;</>}
                  </SpText>
                </Col>
                {
                  // eslint-disable-next-line max-len
                  (adminTerminalDetails.status === TERMINALS_STATUS.ACTIVE || adminTerminalDetails.status === TERMINALS_STATUS.ACTIVATION_PENDING) && (
                    <>
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <Col>
                          <SpButton
                            type="secondary"
                            shape="round"
                            onClick={() => setEditTerminalPin(true)}
                          >
                            Update Pin
                          </SpButton>
                          <SpButton
                            type="danger"
                            shape="round"
                            className="ml-3"
                            onClick={showDeleteTerminalModal}
                          >
                            Delete Terminal
                          </SpButton>
                        </Col>
                      </RBAC>
                    </>
                  )
                }
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Device ID</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.deviceId
                        ? adminTerminalDetails.deviceId
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Device IP</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.deviceIp
                        ? adminTerminalDetails.deviceIp
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Status</span>
                  </Col>
                  <Col span={10}>
                    {adminTerminalDetails.status
                      ? (
                        <SpStatusTag style={{ backgroundColor: getBackground(adminTerminalDetails.status) }}>
                          {getTerminalStatus(adminTerminalDetails.status)}
                        </SpStatusTag>
                      )
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>KSN</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.ksn
                        ? adminTerminalDetails.ksn
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Pin</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {(adminTerminalDetails.terminalPins && adminTerminalDetails.terminalPins[0].pin)
                        ? adminTerminalDetails.terminalPins[0].pin
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Merchant No.</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.terminalMid
                        ? adminTerminalDetails.terminalMid
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Terminal No.</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.terminalTid
                        ? adminTerminalDetails.terminalTid
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Serial No.</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.serialNumber
                        ? adminTerminalDetails.serialNumber
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Contact Name</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.contactName
                        ? adminTerminalDetails.contactName
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Contact No.</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {
                        adminTerminalDetails.contactNumber ? (
                          <NumberFormat
                            className="numFormate"
                            value={adminTerminalDetails.contactNumber}
                            format={phoneFormate}
                            mask="_"
                            disabled
                          />
                        ) : <>&#8211;</>
                      }
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Bank Name</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.bankName
                        ? adminTerminalDetails.bankName
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Integration Type</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.integrationType
                        ? adminTerminalDetails.integrationType
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Auto Settlement</span>
                  </Col>
                  <Col span={10}>
                    <SpStatusTag style={{ backgroundColor: getSettlementBackground(adminTerminalDetails.autoSettlementEnabled) }}>
                      {adminTerminalDetails.autoSettlementEnabled ? 'Enabled' : 'Disabled'}
                    </SpStatusTag>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Shop</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails?.shop?.name ?? <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    TERMINAL POSITION
                  </SpText>
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Location</span>
                  </Col>
                  <Col span={10}>
                    {adminTerminalDetails.terminalPosition && adminTerminalDetails.terminalPosition.location
                      ? (
                        <SpH5>
                          {`${adminTerminalDetails.terminalPosition.location.street}, ${adminTerminalDetails.terminalPosition.location.city}, 
                          ${adminTerminalDetails.terminalPosition.location.state}, ${adminTerminalDetails.terminalPosition.location.postalCode}`}
                        </SpH5>
                      )
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Latitude</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.terminalPosition && adminTerminalDetails.terminalPosition.latitude
                        ? adminTerminalDetails.terminalPosition.latitude
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Longitude</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.terminalPosition && adminTerminalDetails.terminalPosition.longitude
                        ? adminTerminalDetails.terminalPosition.longitude
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Bearing</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.terminalPosition && adminTerminalDetails.terminalPosition.bearing
                        ? adminTerminalDetails.terminalPosition.bearing
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Accuracy</span>
                  </Col>
                  <Col span={10}>
                    <SpH5>
                      {adminTerminalDetails.terminalPosition && adminTerminalDetails.terminalPosition.accuracy
                        ? adminTerminalDetails.terminalPosition.accuracy
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={24}>
                    <Col span={3}>
                      <span>Velocity</span>
                    </Col>
                    <Col span={10}>
                      <SpH5>
                        {adminTerminalDetails.terminalPosition && adminTerminalDetails.terminalPosition.velocity
                          ? adminTerminalDetails.terminalPosition.velocity
                          : <>&#8211;</>}
                      </SpH5>
                    </Col>
                  </Col>
                  <Col span={24} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{adminTerminalDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              {/* EDIT TERMINAL PIN MODAL */}
              {editTerminalPin && (
                <EditTerminalPin
                  visible={editTerminalPin}
                  selectedTerminal={adminTerminalDetails}
                  submitting={submitting}
                  close={() => setEditTerminalPin(false)}
                  submit={editSelectedTerminalPin}
                />
              )}
              {/* DELETE TERMINAL MODAL */}
              {deleteTerminalModal && (
                <DeleteTerminal
                  visible={deleteTerminalModal}
                  adminTerminalDetails={adminTerminalDetails}
                  submitting={submitting}
                  close={() => setDeleteTerminalModal(false)}
                  submit={deleteSelectedPaymentButton}
                />
              )}
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  adminTerminalDetails: state.adminTerminalDetails,
  adminTerminalDetailsStatus: state.adminTerminalDetails.adminTerminalDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdminTerminalDetails: gid => dispatch(adminTerminalDetailsActions.fetchAdminTerminalDetails({
    payload: gid,
  })),
  deleteAdminTerminal: param => dispatch(adminTerminalDetailsActions.deleteAdminTerminal({
    payload: param,
  })),
  updateTerminalPin: param => dispatch(adminTerminalDetailsActions.updateTerminalPin({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AdminTerminalDetails);
