import { CUSTOMERSDETAILS } from '../actionTypes';

export const fetchCustomerDetails = (action) => ({
  type: CUSTOMERSDETAILS.FETCH,
  payload: action.payload,
});

export const fetchCardDetails = (action) => ({
  type: CUSTOMERSDETAILS.CARDS_FETCH,
  payload: action.payload,
});

export const fetchPaymentDetails = (action) => ({
  type: CUSTOMERSDETAILS.PAYMENTS_FETCH,
  payload: action.payload,
});

export const updateCustomers = (action) => ({
  type: CUSTOMERSDETAILS.UPDATE,
  payload: action.payload,
});
