// @flow
import React from 'react';
import {
  Card,
  Row,
  Col,
  Icon,
  Menu,
  Dropdown,
} from 'antd';

import {
  SpH5,
  SpStatusTag,
  SpText,
} from 'components/DesignKit';
import getExchangeRateStatus from 'utils/getExchangeRateStatus';
import getExRateStatusBg from 'utils/getExRateStatusBg';
import getExRateFontBg from 'utils/getExRateFontBg';
import moment from 'moment-timezone';

type Props = {
  exchangeRate: Object,
  handleEditExchange: Function,
  handleDeleteExchange: Function,
  selectedAccount: Object,
}

const CardView = (props: Props) => {
  const {
    exchangeRate, handleEditExchange, handleDeleteExchange, selectedAccount,
  } = props;
  const getCurrencySymbol = (value) => {
    switch (value) {
      case 'USD':
        return '$';
      case 'INR':
        return '₹';
      default:
        return '';
    }
  };

  const getSlabRange = (slab, fromCurrency) => {
    const minRange = ((slab?.minRange || 0) / 100).toFixed(2);
    const maxRange = ((slab?.maxRange || 0) / 100).toFixed(2);

    return maxRange ? (
      <>
        {minRange} {getCurrencySymbol(fromCurrency)}
        <span style={{ fontWeight: 'bold' }}> - </span>
        {maxRange} {getCurrencySymbol(fromCurrency)}
      </>
    )
      : `${minRange} ${getCurrencySymbol(fromCurrency)} & ABOVE`;
  };

  const menu = (
    <Menu>
      {
      exchangeRate?.status === 'ACTIVE' && (
        <Menu.Item key="1" onClick={() => { handleEditExchange(exchangeRate); }}>
          <Icon type="edit" /> Edit
        </Menu.Item>
        )
      }
      <Menu.Item key="2" onClick={() => { handleDeleteExchange(exchangeRate); }}>
        <Icon type="delete" /> Delete
      </Menu.Item>
    </Menu>
  );

  const formatDate = (data) => {
    if (data && selectedAccount && selectedAccount.timezone) {
      const tzDate = moment
        .utc(data)
        .format('MMM DD, YYYY, hh:mm a');
      return (<span>{tzDate}</span>);
    }
    return (
      <>&#8211;</>
    );
  };

  return (
    <Card
      className="exchange-rate-list"
      style={{
        borderRadius: '15px', height: '430px', overflowY: 'auto', boxShadow: '0 4px 8px 0 #d3d3d3, 0 6px 20px 0 rgb(203 203 203)',
      }}
    >
      <Row type="flex" justify="start" align="middle">
        <Col span={12}>
          <SpText className="text-uppercase" fontSize="20px">
            SLAB
          </SpText>
        </Col>
        <Col span={12} style={{ textAlign: 'end' }}>
          <SpStatusTag style={{ backgroundColor: getExRateStatusBg(exchangeRate?.status), color: getExRateFontBg(exchangeRate?.status) }}>
            {getExchangeRateStatus(exchangeRate?.status)}
          </SpStatusTag>
          <Dropdown overlay={menu} placement="bottomCenter">
            <Icon
              type="more"
              className="ml-2"
              style={{
                cursor: 'pointer', border: ' #6A6A6A 1px solid', borderRadius: '9px', padding: '2px',
              }}
            />
          </Dropdown>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={12} className="d-flex flex-column">
          <SpH5>From Currency</SpH5>
          <SpText fontSize="14px" fontWeight="400" className="mt-1" style={{ wordBreak: 'break-word' }}>{exchangeRate?.fromCurrency?.name}</SpText>
        </Col>
        <Col span={12} className="d-flex flex-column align-items-center">
          <SpH5>To Currency</SpH5>
          <SpText fontSize="14px" fontWeight="400" className="mt-1" style={{ wordBreak: 'break-word' }}>{exchangeRate?.toCurrency?.name}</SpText>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={12} className="d-flex flex-column">
          <SpH5>Limit</SpH5>
        </Col>
        <Col span={12} className="d-flex flex-column align-items-center">
          <SpH5>Rate</SpH5>
        </Col>
      </Row>
      {exchangeRate?.slabs.map((slab, index) => (
        <Row key={index} className="mt-1">
          <Col span={12} className="d-flex flex-column">
            <SpText fontSize="14px" fontWeight="400" className="mt-1" style={{ wordBreak: 'break-word' }}>
              {getSlabRange(slab, exchangeRate?.fromCurrency?.name)}
            </SpText>
          </Col>
          <Col span={12} className="d-flex flex-column align-items-center">
            <SpText fontSize="14px" fontWeight="400" className="mt-1" style={{ wordBreak: 'break-word' }}>
              {(slab?.rate || 0).toFixed(2)} {getCurrencySymbol(exchangeRate?.toCurrency?.name)}
            </SpText>
          </Col>
        </Row>
      ))}
      <Row className="mt-3">
        <Col span={12} className="d-flex flex-column">
          <SpH5>Low Volume Limit</SpH5>
          <SpText fontSize="14px" fontWeight="400" className="mt-1" style={{ wordBreak: 'break-word' }}>
            {`${(exchangeRate?.lowVolumeLimit / 100).toFixed(2)} $` || <>&#8211;</>}
          </SpText>
        </Col>
        <Col span={12} className="d-flex flex-column align-items-center">
          <SpH5>Low Volume Fee</SpH5>
          <SpText fontSize="14px" fontWeight="400" className="mt-1" style={{ wordBreak: 'break-word' }}>
            {exchangeRate?.lowVolumeFee
              ? `${(exchangeRate?.lowVolumeFee / 100).toFixed(2)}${exchangeRate?.lowVolumeFeeType === 'PERCENTAGE' ? '%' : ''}`
              : <>&#8211;</>}
          </SpText>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={12} className="d-flex flex-column">
          <SpH5>Start Date</SpH5>
          <SpText fontSize="14px" fontWeight="400" className="mt-1" style={{ wordBreak: 'break-word' }}>
            {formatDate(exchangeRate?.effectiveFrom)}
          </SpText>
        </Col>
        <Col span={12} className="d-flex flex-column align-items-center" style={{ textAlign: 'end' }}>
          <SpH5>End Date</SpH5>
          <SpText fontSize="14px" fontWeight="400" className="mt-1" style={{ wordBreak: 'break-word' }}>
            {formatDate(exchangeRate?.effectiveTo)}
          </SpText>
        </Col>
      </Row>
    </Card>
  );
};

export default CardView;
