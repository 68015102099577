import { call, fork, put } from 'redux-saga/effects';
import moment from 'moment-timezone';
import _get from 'lodash.get';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';
import { DASHBOARD } from '../actionTypes';
import CONSTANTS from '../constants';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const f = (x) => moment(x).utc().format(MOMENT_FORMAT);

// API
const getAccountSummary = async (startDate, endDate, currencyCode, summaryType) => {
  let endPoint = API_END_POINTS.ACCOUNT_SUMMARY;
  if (summaryType === 'Transfers') {
    endPoint = API_END_POINTS.TRANSFERS_ACCOUNT_SUMMARY;
  }
  return axiosAuthInstance
    .get(`${endPoint}?startDate=${f(startDate)}&endDate=${f(endDate)}&currencyCode=${currencyCode}`);
};

const getDailySummary = async (startDate, endDate, currencyCode, summaryType, duration) => {
  let endPoint = API_END_POINTS.DASHBOARD_DAILY;
  let format = 'YYYY-MM-DD';
  const monthSummary = [
    CONSTANTS.DASHBOARD.LAST_3_MONTHS,
    CONSTANTS.DASHBOARD.LAST_12_MONTHS,
    CONSTANTS.DASHBOARD.YEAR_TO_DATE,
  ];
  if (monthSummary.includes(duration)) {
    endPoint = summaryType === 'Transfers' ? API_END_POINTS.TRANSFERS_MONTHLY : API_END_POINTS.DASHBOARD_MONTHLY;
    format = 'YYYYMM';
  }
  const end = moment(endDate).utc().format(format);
  const start = moment(startDate).utc().format(format);
  const typeOfTransfer = summaryType === 'Transfers' ? 'transferDate' : 'paymentDate';
  if (summaryType === 'Transfers' && !monthSummary.includes(duration)) {
    endPoint = API_END_POINTS.TRANSFERS_DAILY;
  }
  return axiosAuthInstance
    .get(`${endPoint}?${typeOfTransfer}.AF=${start}&${typeOfTransfer}.BF=${end}&size=1000`);
};

const getHourlySummary = async (startDate, endDate, currencyCode, summaryType) => {
  let endPoint = API_END_POINTS.DASHBOARD_HOURLY;
  let typeOfTransfer = 'paymentDate';
  const end = moment(endDate).utc().format('YYYY-MM-DD');
  if (summaryType === 'Transfers') {
    endPoint = API_END_POINTS.TRANSFERS_HOURLY;
    typeOfTransfer = 'transferDate';
  }
  return axiosAuthInstance
    .get(`${endPoint}?size=1000&${typeOfTransfer}.EQ=${end}`);
};

const getStatusSummary = async (startDate, endDate, currencyCode, summaryType) => {
  let endPoint = API_END_POINTS.STATUS_SUMMARY;
  if (summaryType === 'Transfers') {
    endPoint = API_END_POINTS.TRANSFERS_STATUS_SUMMARY;
  }
  return axiosAuthInstance
    .get(`${endPoint}?startDate=${f(startDate)}&endDate=${f(endDate)}&currencyCode=${currencyCode}`);
};

// Finding Top Customers
const getCustomersSummary = async (startDate, endDate, currencyCode, summaryType) => {
  let endPoint = API_END_POINTS.CUSTOMERS_SUMMARY;
  if (summaryType === 'Transfers') {
    endPoint = API_END_POINTS.TRANSFERS_CUSTOMERS_SUMMARY;
  }
  return axiosAuthInstance
    .get(`${endPoint}?startDate=${f(startDate)}&endDate=${f(endDate)}&size=5&currencyCode=${currencyCode}`);
};

const getTopPayments = async (startDate, endDate, currencyCode, summaryType) => {
  let endPoint = API_END_POINTS.TOP_PAYMENTS;
  let typeOfSummary = 'paymentDate';
  if (summaryType === 'Transfers') {
    endPoint = API_END_POINTS.TRANSFERS_TOP_PAYMENTS;
    typeOfSummary = 'transferDate';
  }
  return axiosAuthInstance
  // eslint-disable-next-line
    .get(`${endPoint}?${typeOfSummary}.AF=${f(startDate)}&${typeOfSummary}.BF=${f(endDate)}&sortBy=amount&direction=DESC&size=5&currency.name.EQ=${currencyCode}`);
};

const getCustomers = async (startDate, endDate, summaryType) => {
  let endPoint = API_END_POINTS.CUSTOMERS;
  if (summaryType === 'Transfers') {
    endPoint = API_END_POINTS.GET_CONTACTS;
  }
  return axiosAuthInstance.get(`${endPoint}?createdAt.AF=${f(startDate)}&createdAt.BF=${f(endDate)}&direction=DESC&size=5`);
};

export function* getAccountSummarySaga(action) {
  try {
    const {
      payload: {
        currentStartDate,
        currentEndDate,
        prevStartDate,
        prevEndDate,
        currencyCode,
        summaryType,
      },
    } = action;
    const { data: { entity: current } } = yield call(
      getAccountSummary, currentStartDate, currentEndDate, currencyCode, summaryType,
    );
    const { data: { entity: previous } } = yield call(
      getAccountSummary, prevStartDate, prevEndDate, currencyCode, summaryType,
    );
    yield put({
      type: DASHBOARD.FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY_SUCCESS,
      payload: {
        current,
        previous,
      },
    });
  } catch (e) {
    yield put({
      type: DASHBOARD.FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY_FAILED,
      payload: _get(e, 'response.data.message', MESSAGES.API_ERROR),
    });
  }
}

export function* getDailySummarySaga(action) {
  try {
    const {
      payload: {
        currentStartDate,
        currentEndDate,
        prevStartDate,
        prevEndDate,
        currencyCode,
        summaryType,
        duration,
      },
    } = action;
    if (CONSTANTS.DASHBOARD.LAST_1_DAYS !== duration) {
      const { data: { entity: current } } = yield call(
        getDailySummary, currentStartDate, currentEndDate, currencyCode, summaryType, duration,
      );
      const { data: { entity: previous } } = yield call(
        getDailySummary, prevStartDate, prevEndDate, currencyCode, summaryType, duration,
      );
      yield put({
        type: DASHBOARD.FETCH_PAYMENT_SESSION_DAILY_SUMMARY_SUCCESS,
        payload: {
          current,
          previous,
        },
      });
    }
  } catch (e) {
    yield put({
      type: DASHBOARD.FETCH_PAYMENT_SESSION_DAILY_SUMMARY_FAILED,
      payload: _get(e, 'response.data.message', MESSAGES.API_ERROR),
    });
  }
}

export function* getHourlySummarySaga(action) {
  try {
    const {
      payload: {
        currentStartDate,
        currentEndDate,
        prevStartDate,
        prevEndDate,
        currencyCode,
        summaryType,
        duration,
      },
    } = action;
    if (CONSTANTS.DASHBOARD.LAST_1_DAYS === duration) {
      const { data: { entity: current } } = yield call(
        getHourlySummary, currentStartDate, currentEndDate, currencyCode, summaryType,
      );
      const { data: { entity: previous } } = yield call(
        getHourlySummary, prevStartDate, prevEndDate, currencyCode, summaryType,
      );
      yield put({
        type: DASHBOARD.FETCH_PAYMENT_SESSION_HOURLY_SUMMARY_SUCCESS,
        payload: {
          current,
          previous,
        },
      });
    }
  } catch (e) {
    yield put({
      type: DASHBOARD.FETCH_PAYMENT_SESSION_HOURLY_SUMMARY_FAILED,
      payload: _get(e, 'response.data.message', MESSAGES.API_ERROR),
    });
  }
}

export function* getStatusSummarySaga(action) {
  try {
    const {
      payload: {
        currentStartDate,
        currentEndDate,
        prevStartDate,
        prevEndDate,
        currencyCode,
        summaryType,
      },
    } = action;
    const { data: { entity: current } } = yield call(
      getStatusSummary, currentStartDate, currentEndDate, currencyCode, summaryType,
    );
    const { data: { entity: previous } } = yield call(
      getStatusSummary, prevStartDate, prevEndDate, currencyCode, summaryType,
    );
    yield put({
      type: DASHBOARD.FETCH_PAYMENT_SESSION_STATUS_SUMMARY_SUCCESS,
      payload: {
        current,
        previous,
      },
    });
  } catch (e) {
    yield put({
      type: DASHBOARD.FETCH_PAYMENT_SESSION_STATUS_SUMMARY_FAILED,
      payload: _get(e, 'response.data.message', MESSAGES.API_ERROR),
    });
  }
}

export function* getCustomersSummarySaga(action) {
  try {
    const {
      payload: {
        currentStartDate, currentEndDate, currencyCode, summaryType,
      },
    } = action;
    const { data: { entity } } = yield call(
      getCustomersSummary, currentStartDate, currentEndDate, currencyCode, summaryType,
    );
    yield put({
      type: DASHBOARD.FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY_SUCCESS,
      payload: entity,
    });
  } catch (e) {
    yield put({
      type: DASHBOARD.FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY_FAILED,
      payload: _get(e, 'response.data.message', MESSAGES.API_ERROR),
    });
  }
}

export function* getTopPaymentsSaga(action) {
  try {
    const {
      payload: {
        currentStartDate, currentEndDate, currencyCode, summaryType,
      },
    } = action;
    const { data: { entity } } = yield call(getTopPayments, currentStartDate, currentEndDate, currencyCode, summaryType);
    yield put({
      type: DASHBOARD.FETCH_TOP_PAYMENTS_SUCCESS,
      payload: entity,
    });
  } catch (e) {
    yield put({
      type: DASHBOARD.FETCH_TOP_PAYMENTS_FAILED,
      payload: _get(e, 'response.data.message', MESSAGES.API_ERROR),
    });
  }
}

export function* getCustomersSaga(action) {
  try {
    const {
      payload: {
        currentStartDate,
        currentEndDate,
        prevStartDate,
        prevEndDate,
        summaryType,
      },
    } = action;
    const { data: { entity: current } } = yield call(
      getCustomers, currentStartDate, currentEndDate, summaryType,
    );
    const { data: { entity: previous } } = yield call(
      getCustomers, prevStartDate, prevEndDate, summaryType,
    );
    yield put({
      type: DASHBOARD.FETCH_CUSTOMERS_SUCCESS,
      payload: {
        current,
        previous,
      },
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: DASHBOARD.FETCH_CUSTOMERS_FAILED,
      payload: _get(e, 'response.data.message', MESSAGES.API_ERROR),
    });
  }
}

export function* setDurationSaga(action) {
  yield put({
    type: DASHBOARD.FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY,
  });
  yield put({
    type: DASHBOARD.FETCH_PAYMENT_SESSION_DAILY_SUMMARY,
  });
  yield put({
    type: DASHBOARD.FETCH_PAYMENT_SESSION_HOURLY_SUMMARY,
  });
  yield put({
    type: DASHBOARD.FETCH_PAYMENT_SESSION_STATUS_SUMMARY,
  });
  yield put({
    type: DASHBOARD.FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY,
  });
  yield put({
    type: DASHBOARD.FETCH_TOP_PAYMENTS,
  });
  yield put({
    type: DASHBOARD.FETCH_CUSTOMERS,
  });
  const {
    payload: {
      duration, tz, currencyCode, summaryType,
    },
  } = action;
  let currentEndDate = moment()
    .tz(tz)
    .subtract(15, 'minutes');
  let currentStartDate = moment(currentEndDate)
    .tz(tz)
    .startOf('day');
  let prevEndDate = moment(currentStartDate)
    .tz(tz)
    .subtract(15, 'minutes');
  let prevStartDate = moment(prevEndDate)
    .tz(tz)
    .startOf('day');
  switch (duration) {
    case (CONSTANTS.DASHBOARD.LAST_1_DAYS): {
      currentEndDate = moment()
        .tz(tz);
      currentStartDate = moment(currentEndDate)
        .tz(tz)
        .startOf('day');
      prevEndDate = moment(currentStartDate)
        .tz(tz)
        .subtract(15, 'minutes');
      prevStartDate = moment(prevEndDate)
        .tz(tz)
        .startOf('day');
      break;
    }
    case (CONSTANTS.DASHBOARD.LAST_7_DAYS): {
      currentEndDate = moment()
        .tz(tz);
      currentStartDate = moment(currentEndDate)
        .tz(tz)
        .subtract(6, 'day')
        .startOf('day');
      prevEndDate = moment(currentStartDate)
        .tz(tz)
        .subtract(15, 'minutes');
      prevStartDate = moment(prevEndDate)
        .tz(tz)
        .subtract(6, 'day')
        .startOf('day');
      break;
    }
    case (CONSTANTS.DASHBOARD.LAST_30_DAYS): {
      currentEndDate = moment()
        .tz(tz);
      currentStartDate = moment(currentEndDate)
        .tz(tz)
        .subtract(29, 'day')
        .startOf('day');
      prevEndDate = moment(currentStartDate)
        .tz(tz)
        .subtract(15, 'minutes');
      prevStartDate = moment(prevEndDate)
        .tz(tz)
        .subtract(29, 'day')
        .startOf('day');
      break;
    }
    case (CONSTANTS.DASHBOARD.LAST_1_MONTHS): {
      currentEndDate = moment()
        .tz(tz);
      currentStartDate = moment(currentEndDate)
        .tz(tz)
        .subtract(1, 'month')
        .startOf('day');
      prevEndDate = moment(currentStartDate)
        .tz(tz)
        .subtract(15, 'minutes');
      prevStartDate = moment(prevEndDate)
        .tz(tz)
        .subtract(1, 'month')
        .startOf('day');
      break;
    }
    case (CONSTANTS.DASHBOARD.LAST_3_MONTHS): {
      currentEndDate = moment()
        .tz(tz);
      currentStartDate = moment(currentEndDate)
        .tz(tz)
        .subtract(3, 'month')
        .startOf('day');
      prevEndDate = moment(currentStartDate)
        .tz(tz)
        .subtract(15, 'minutes');
      prevStartDate = moment(prevEndDate)
        .tz(tz)
        .subtract(3, 'month')
        .startOf('day');
      break;
    }
    case (CONSTANTS.DASHBOARD.LAST_12_MONTHS): {
      currentEndDate = moment()
        .tz(tz);
      currentStartDate = moment(currentEndDate)
        .tz(tz)
        .subtract(12, 'month')
        .startOf('day');
      prevEndDate = moment(currentStartDate)
        .tz(tz)
        .subtract(15, 'minutes');
      prevStartDate = moment(prevEndDate)
        .tz(tz)
        .subtract(12, 'month');
      break;
    }
    case (CONSTANTS.DASHBOARD.YEAR_TO_DATE): {
      currentEndDate = moment()
        .tz(tz);
      currentStartDate = moment()
        .tz(tz)
        .startOf('year');
      prevEndDate = moment()
        .tz(tz)
        .subtract(1, 'year');
      prevStartDate = moment()
        .tz(tz)
        .subtract(1, 'year')
        .startOf('year');
      break;
    }
    default:
  }
  // Fork all the sagas
  yield fork(
    getAccountSummarySaga,
    ({
      payload: {
        currentStartDate, currentEndDate, prevStartDate, prevEndDate, currencyCode, summaryType,
      },
    }),
  );
  yield fork(
    getDailySummarySaga,
    ({
      payload: {
        currentStartDate, currentEndDate, prevStartDate, prevEndDate, currencyCode, summaryType, duration,
      },
    }),
  );
  yield fork(
    getHourlySummarySaga,
    ({
      payload: {
        currentStartDate, currentEndDate, prevStartDate, prevEndDate, currencyCode, summaryType, duration,
      },
    }),
  );
  yield fork(
    getStatusSummarySaga,
    ({
      payload: {
        currentStartDate, currentEndDate, prevStartDate, prevEndDate, currencyCode, summaryType,
      },
    }),
  );
  yield fork(
    getCustomersSummarySaga,
    ({
      payload: {
        currentStartDate, currentEndDate, prevStartDate, prevEndDate, currencyCode, summaryType,
      },
    }),
  );
  yield fork(
    getTopPaymentsSaga,
    ({
      payload: {
        currentStartDate, currentEndDate, prevStartDate, prevEndDate, currencyCode, summaryType,
      },
    }),
  );
  yield fork(
    getCustomersSaga,
    ({
      payload: {
        currentStartDate, currentEndDate, prevStartDate, prevEndDate, summaryType,
      },
    }),
  );
}
