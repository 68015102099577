// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Switch,
} from 'antd';

import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError } from 'components/DesignKit';

import {
  invoiceDetailsAction,
} from 'store/actions';

type Props = {
  submitting: boolean,
  stockDetails: Object,
};

const EditStocks = forwardRef((props: Props, ref) => {
  const {
    submitting,
    stockDetails,
  } = props;

  const [, forceUpdate] = useState();
  const [enableStock, setEnableStock] = useState((stockDetails && stockDetails.enableStock) || false);
  const [enableDynamicAvailability, setEnableDynamicAvailability] = useState((stockDetails && stockDetails.enableDynamicAvailability) || false);
  const [stockQuantity, setStockQuantity] = useState((stockDetails && stockDetails.stockQuantity) || 0);
  const [onlineAvailability, setOnlineAvailability] = useState((stockDetails && stockDetails.onlineAvailability) || 0);
  const [posAvailability, setPosAvailability] = useState((stockDetails && stockDetails.posAvailability) || 0);


  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      number: {
        message: 'The input must be a number',
        rule: (val) => /^\d*$/.test(val),
      },

    },
  }));

  // useEffect(() => {
  //   getItemCouponsList();
  // }, []);
  const handleOnlineAvailabilityChange = (value) => {
    const newValue = Number(value);
    if (value === '') {
      setOnlineAvailability(0);
      setPosAvailability(100);
    } else if (newValue) {
      const remaining = 100 - newValue;
      setOnlineAvailability(newValue);
      setPosAvailability(remaining >= 0 ? remaining : 0);
    }
  };

  const handlePosAvailabilityChange = (value) => {
    const newValue = Number(value);
    if (value === '') {
      setPosAvailability(0);
      setOnlineAvailability(100);
    } else if (newValue) {
      const remaining = 100 - newValue;
      setPosAvailability(newValue);
      setOnlineAvailability(remaining >= 0 ? remaining : 0);
    }
  };


  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      enableStock,
      enableDynamicAvailability,
      stockQuantity,
      onlineAvailability,
      posAvailability,
    }),
  }));


  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3 mt-2">
          <Switch
            className="mr-2"
            checked={enableStock}
            onChange={setEnableStock}
          /> Enable Stocks
        </div>


        <div>
          <label htmlFor=""><SpH5>Quantity</SpH5></label>
          <Input
            placeholder="Quantity"
            type="number"
            min={0}
            disabled={!enableStock}
            onChange={(e) => setStockQuantity(e.currentTarget.value)}
            value={stockQuantity}
          />
        </div>
        <SpError>
          {simpleValidator.current.message('stockQuantity', stockQuantity, 'number')}
        </SpError>

        <div className="mb-3 mt-3">

          <Switch
            className="mr-2"
            disabled={!enableStock}
            checked={enableDynamicAvailability}
            onChange={setEnableDynamicAvailability}
          /> Enable Dynamic Stocks
        </div>
        <div>
          <label htmlFor=""><SpH5>On Pos</SpH5></label>

          <Input
            placeholder="On Pos"
            suffix="%"
            type="number"
            min={0}
            max={100}
            disabled={!enableDynamicAvailability}
            onChange={(e) => handlePosAvailabilityChange(e.currentTarget.value)}
            value={posAvailability}
          />
        </div>
        <SpError>
          {simpleValidator.current.message('posAvailability', posAvailability, 'number')}
        </SpError>

      </div>
      <div className="mt-2">
        <label htmlFor=""><SpH5>On Online</SpH5></label>


        <Input
          placeholder="On Online"
          suffix="%"
          type="number"
          min={0}
          max={100}
          disabled={!enableDynamicAvailability}
          onChange={(e) => handleOnlineAvailabilityChange(e.currentTarget.value)}
          value={onlineAvailability}
        />
      </div>
      <SpError>
        {simpleValidator.current.message('onlineAvailability', onlineAvailability, 'number')}
      </SpError>


    </>
  );
});

const mapStateToProps = (state) => ({
  couponsList: state.invoiceDetails.couponsItemList,
});

const mapDispatchToProps = (dispatch) => ({
  getItemCouponsList: param => dispatch(invoiceDetailsAction.getItemCouponsList({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(EditStocks);
