export default status => {
  if (status === 'CANCELLED') {
    return '#e2e2e2';
  } if (status === 'SUCCEEDED') {
    return '#ececff';
  } if (status === 'SUCCESS') {
    return '#ececff';
  } if (status === 'REFUNDED') {
    return '#e2e2e2';
  } if (status === 'UNCAPTURED') {
    return '#e2e2e2';
  } if (status === 'PARTIAL_REFUNDED') {
    return '#e2e2e2';
  } if (status === 'REQUIRE_CAPTURE') {
    return '#e2e2e2';
  } if (status === 'DISPUTED_NEEDS_RESPONSE') {
    return '#ffe0b1';
  } if (status === 'DISPUTE_UNDER_REVIEW') {
    return '#ffe0b1';
  } if (status === 'DISPUTED') {
    return '#ffe0b1';
  } if (status === 'FAILED') {
    return '#ffe0b1';
  } if (status === 'DISPUTE_WON') {
    return '#ffe0b1';
  } if (status === 'DISPUTE_LOST') {
    return '#ffe0b1';
  } if (status === 'DISPUTE_PENDING') {
    return '#ffe0b1';
  } if (status === 'DISPUTE_REVIEWING') {
    return '#ffe0b1';
  } if (status === 'Passed') {
    return '#ececff';
  } if (status === 'Provided') {
    return '#ececff';
  } if (status === 'REVERSED') {
    return '#ececff';
  } if (status === 'VOID') {
    return '#ffe0b1';
  } return '#e2e2e2';
};
