export default status => {
  switch (status) {
    case 'EXPIRED':
      return '#FFE9E9';
    case 'ACTIVE':
      return '#099075';
    case 'INACTIVE':
      return '#EB464B';
    default:
      return '';
  }
};
