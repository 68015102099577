// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Menu,
  Pagination,
} from 'antd';
import {
  SpButton,
  SpTab,
} from 'components/DesignKit';

import Loading from 'components/Loading';

import {
  ratesActions,
  issuerrateActions,
  exchangeRateActions,
  issuerActions,
  filterParamsActions,
  notificationActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import { API_END_POINTS, axiosAuthInstance } from 'api';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import RBAC from 'components/rbac';
import AddExchangeRate from './components/AddExchangeRate';
import DeleteExchangeRateModal from './components/DeleteExchangeModal';
import EditExchangeRate from './components/EditExchangeRate';
import CardView from './components/CardView';

type Props = {
  getExchangeRatesList: Function,
  deleteExchangeRate: Function,
  addExchangeRate: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  setFilterData: Function,
  preserveFilters: Object,
  exchangeRate: Object,
  history: {
    push: Function,
  },
};

const ExchangeRatesList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    loading,
    addExchangeRate,
    getExchangeRatesList,
    deleteExchangeRate,
    totalElements,
    submitting,
    validator,
    test,
    history,
    setFilterData,
    preserveFilters,
    exchangeRate,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [addRateModal, setAddRateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editExchangeModal, setEditExchangeModal] = useState(false);
  const [selectedExchangeRateGid, setSelectedExchangeRateGid] = useState('');
  const [selectedExchangeRate, setSelectedExchangeRate] = useState('');
  const { pathname } = location;

  const [current, setCurrent] = useState('exchangerate');
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterRates: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });

  const debouncedfetchCatalogs = debounce(getExchangeRatesList, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedfetchCatalogs({ currentPage, sortParams, filterParams });
  };
  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddRateModal(false);
      setDeleteModal(false);
      setEditExchangeModal(false);
    }
  }, [validator]);

  const addNewExchangeRate = (exchangeRateDetails: Object) => {
    const {
      fromCurrencyCode,
      toCurrencyCode,
      slabs,
      lowVolumeLimit,
      lowVolumeFeeType,
      lowVolumeFee,
      apiStartDate,
      apiEndDate,
    } = exchangeRateDetails;

    const formattedSlabs = slabs.map((slab) => ({
      minRange: (slab.minRange * 100),
      maxRange: (slab.maxRange * 100),
      rate: slab.rate,
    }));

    const params = {
      fromCurrencyCode,
      toCurrencyCode,
      slabs: formattedSlabs,
      lowVolumeLimit: (lowVolumeLimit * 100),
      lowVolumeFeeType,
      lowVolumeFee: (lowVolumeFee * 100),
      effectiveFrom: apiStartDate,
      effectiveTo: apiEndDate,

    };
    addExchangeRate(
      params,
    );
  };
  const editExchangeRate = async (exchangeRateValues: Object) => {
    const {
      fromCurrencyCode,
      toCurrencyCode,
      slabs,
      lowVolumeLimit,
      lowVolumeFeeType,
      lowVolumeFee,
      effectiveFrom,
      effectiveTo,
      gid,
    } = exchangeRateValues;

    const formattedSlabs = slabs.map((slab) => ({
      minRange: (slab?.minRange * 100),
      maxRange: (slab?.maxRange * 100),
      rate: slab?.rate,
    }));
    const params = {
      fromCurrencyCode,
      toCurrencyCode,
      slabs: formattedSlabs,
      lowVolumeLimit,
      lowVolumeFeeType,
      lowVolumeFee,
      effectiveFrom,
      effectiveTo,
    };
    try {
      const { data: { responseCode } } = await axiosAuthInstance.patch(`${API_END_POINTS.EXCHANGE_RATE}/${gid}/inactive`);
      if (responseCode === 200) {
        addExchangeRate(
          params,
        );
      }
    } catch (reason) {
      console.error(reason);
    }
  };
  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page;
    setCurrentPage(page);
    let tempParams = '';
    if (filters && filters.order === 'ascend') {
      tempParams = '&sortBy=createdAt&direction=ASC';
    } else if (filters && filters.order === 'descend') {
      tempParams = '&sortBy=createdAt&direction=DESC';
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.EXCHANGE_RATE,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.EXCHANGE_RATE,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };


  const handleDeleteExchange = (record) => {
    setSelectedExchangeRateGid(record?.gid);
    setDeleteModal(true);
  };
  const handleEditExchange = (record) => {
    setSelectedExchangeRate(record);
    setEditExchangeModal(true);
  };

  const deleteSelectedExchangeRate = (fundingSourceGid: Object) => {
    deleteExchangeRate(fundingSourceGid);
    // history.push(`${PAGES.EXCHANGE_RATE}`);
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
        <Menu.Item key="acquirer">
          <SpTab
            to="/rate"
            selected={current === 'acquirer'}
          >
            Acquirer Rates
          </SpTab>
        </Menu.Item>
        <Menu.Item key="issuerrate">
          <SpTab
            to="/rate/issuerrate"
            selected={current === 'issuerrate'}
          >
            Issuer Rates
          </SpTab>
        </Menu.Item>
        <Menu.Item key="exchangerate">
          <SpTab
            to="/rate/exchangerate"
            selected={current === 'exchangerate'}
          >
            Exchange Rates
          </SpTab>
        </Menu.Item>
      </Menu>
      <Card>
        {exchangeRate && exchangeRate.length > 0 && (
        <Row className="my-2">
          <div className="d-flex justify-content-end">
            <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
              <Col>
                <SpButton
                  type="secondary"
                  shape="round"
                  ghost
                  className="mr-3"
                  onClick={() => setAddRateModal(true)}
                >
                  {'\u002B'}&nbsp;Add Exchange Rate
                </SpButton>
              </Col>
            </RBAC>
          </div>
        </Row>
        )}

        {/* ADD RATE MODAL */}
        {addRateModal && (
          <AddExchangeRate
            visible={addRateModal}
            submitting={submitting}
            selectedAccount={selectedAccount}
            close={() => setAddRateModal(false)}
            submit={addNewExchangeRate}
          />
        )}

        {/* EDIT RATE MODAL */}
        {editExchangeModal && (
          <EditExchangeRate
            visible={editExchangeModal}
            submitting={submitting}
            exchangeRate={selectedExchangeRate}
            close={() => setEditExchangeModal(false)}
            submit={editExchangeRate}
          />
        )}

        {/* Delete RATE MODAL */}
        {deleteModal && (
          <DeleteExchangeRateModal
            visible={deleteModal}
            exchangeRateGid={selectedExchangeRateGid}
            close={() => setDeleteModal(false)}
            submit={deleteSelectedExchangeRate}
          />
        )}
        <div>
          <Row className="exchange-rate-list">
            {
              exchangeRate && exchangeRate.length !== 0 && (
                <>
                  {
                    exchangeRate.map((item) => (
                      <Col span={8}>
                        <div className="py-2 pl-2 pr-2">
                          {CardView({
                            exchangeRate: item, handleDeleteExchange, handleEditExchange, selectedAccount,
                          })}
                        </div>
                      </Col>
                    ))
                  }
                </>
              )
            }
          </Row>
          <Row type="flex" justify="end">
            <Col className="mr-2">
              <div
                style={{
                  paddingTop: '6px',
                }}
              >
                {`Total ${totalElements} items`}
              </div>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                pageSize={10}
                total={totalElements}
                onChange={handleTableChange}
                // showSizeChanger
              />
            </Col>
          </Row>
        </div>

      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  rates: state.issuerrate.content,
  issuer: state.issuer.content,
  category: state.rates.category,
  transferType: state.issuerrate.transferType.content,
  totalElements: state.exchangeRate.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  exchangeRate: state.exchangeRate.content,
});

const mapDispatchToProps = (dispatch) => ({
  getExchangeRatesList: param => dispatch(exchangeRateActions.getExchangeRatesList({
    payload: param,
  })),
  deleteExchangeRate: param => dispatch(exchangeRateActions.deleteExchangeRate({
    payload: param,
  })),
  addExchangeRate: param => dispatch(exchangeRateActions.addExchangeRate({
    payload: param,
  })),
  fetchIssuer: param => dispatch(issuerActions.fetchIssuer({
    payload: param,
  })),
  fetchRateCategory: param => dispatch(ratesActions.fetchRateCategory({
    payload: param,
  })),
  fetchTransferType: param => dispatch(issuerrateActions.fetchTransferType({
    payload: param,
  })),
  addIssuerRate: param => dispatch(issuerrateActions.addIssuerRate({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRatesList);
