// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  DatePicker,
  Radio,
  Icon,
} from 'antd';
import {
  LOWVOLUME_FEE_TYPES,
} from 'appconstants';

import {
  exchangeRateActions,
  notificationActions,
} from 'store/actions';

import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpError, SpButton,
  SpText,
} from 'components/DesignKit';
import { validators } from 'utils/validationMessages';
import moment from 'moment-timezone';
import { NOTIFICATION } from 'store/actionTypes';


const { Option } = Select;
const dateformat = 'MM-DD-YYYY HH:mm';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  exchangeRate: Object,
  submit: Function,
  setNotification: Function,
};

const formatPayload = (payload) => {
  const formattedSlabs = payload.map((slab) => ({
    minRange: (slab.minRange / 100).toFixed(2),
    maxRange: (slab.maxRange / 100).toFixed(2),
    rate: slab.rate.toFixed(2),
  }));

  return formattedSlabs;
};

const EditExchangeRate = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    selectedAccount,
    submit,
    exchangeRate,
    setNotification,
  } = props;

  const [, forceUpdate] = useState();
  //   const [currency, setCurrency] = useState();
  const [lowVolumeLimit, setLowVolumeLimit] = useState((exchangeRate?.lowVolumeLimit / 100).toFixed(2) || 1);
  const [lowVolumeFee, setLowVolumeFee] = useState((exchangeRate?.lowVolumeFee / 100).toFixed(2) || 1);
  const [slabs, setSlabs] = useState(exchangeRate?.slabs ? formatPayload(exchangeRate?.slabs) : [{ minRange: '', maxRange: '', rate: '' }]);
  const [fromCurrencyCode, setFromCurrencyCode] = useState(exchangeRate?.fromCurrency?.name || '');
  const [toCurrencyCode, setToCurrencyCode] = useState(exchangeRate?.toCurrency?.name || '');
  const [lowVolumeFeeType, setLowVolumeFeeType] = useState(exchangeRate?.lowVolumeFeeType || '');
  const [disableButton, setDisableButton] = useState(true);
  const [deleteSlab, setDeleteSlab] = useState({ open: false, deleteIndex: null, deleteItem: null });
  const nameRegex = /^[ ]*([a-zA-Z'-.]{1,}( [a-zA-z'-. ]{1,})?)$/;
  const [apiStartDate, setApiStartDate] = useState(exchangeRate?.effectiveFrom);
  const [apiEndDate, setApiEndDate] = useState(exchangeRate?.effectiveTo);
  const [dates, setDates] = useState([
    moment.utc(exchangeRate?.effectiveFrom),
    moment.utc(exchangeRate?.effectiveTo),
  ]);
  const gid = exchangeRate && exchangeRate.gid;
  const { prefix } = selectedAccount && selectedAccount.currency;

  const { RangePicker } = DatePicker;
  const RadioGroup = Radio.Group;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      name: {
        message: validators.integrators.validName,
        rule: val => nameRegex.test(val),
      },
      positiveNumber: {
        message: 'Value must be a positive number or zero',
        rule: val => val >= 0,
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

  const hasChanges = () => (
    lowVolumeLimit !== (exchangeRate?.lowVolumeLimit / 100).toFixed(2)
      || lowVolumeFee !== (exchangeRate?.lowVolumeFee / 100).toFixed(2)
      || fromCurrencyCode !== (exchangeRate?.fromCurrency?.name || '')
      || toCurrencyCode !== (exchangeRate?.toCurrency?.name || '')
      || lowVolumeFeeType !== (exchangeRate?.lowVolumeFeeType || '')
      || apiStartDate !== exchangeRate?.effectiveFrom
      || apiEndDate !== exchangeRate?.effectiveTo
      || !isEqual(slabs, formatPayload(exchangeRate?.slabs || []))
  );

  useEffect(() => {
    setDisableButton(!hasChanges());
  }, [lowVolumeLimit, lowVolumeFee, slabs, fromCurrencyCode, toCurrencyCode, lowVolumeFeeType, apiStartDate, apiEndDate]);

  //   useEffect(() => {
  //     const countryId = (selectedAccount && selectedAccount.country && selectedAccount.country.id);
  //     if (countryId === 1) {
  //       setCurrency('USD');
  //     } else if (countryId === 2) {
  //       setCurrency('INR');
  //     }
  //   }, []);
  // const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';


  const customFunction = (date) => {
    if (date && date.length === 2) {
      const [start, end] = date;
      const utcStartDate = moment(start).format(MOMENT_FORMAT);
      const utcEndDate = moment(end).format(MOMENT_FORMAT);
      setApiStartDate(utcStartDate);
      setApiEndDate(utcEndDate);
    } else {
      setApiStartDate(null);
      setApiEndDate(null);
    }
  };
  const customStartDateReset = (open) => {
    if (!open && dates.length === 1) {
      setDates([]);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    let tooEarly = dates[0] && dates[0].diff(current, 'days') > 7;
    if (dates[1]) tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return tooEarly || tooLate;
  };
  const onRadioChange = (e) => {
    setLowVolumeFeeType(e.target.value);
  };
  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    if (fromCurrencyCode === toCurrencyCode) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'From Currency and To Currency cannot be the same',
      });
      setDisableButton(false);
      forceUpdate(1);
      return;
    }
    if (apiStartDate === apiEndDate) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Start and End date cannot be the same with the same time',
      });
      setDisableButton(false);
      forceUpdate(1);
      return;
    }
    submit({
      gid,
      fromCurrencyCode,
      toCurrencyCode,
      slabs,
      lowVolumeLimit: (lowVolumeLimit * 100),
      lowVolumeFeeType,
      lowVolumeFee: (lowVolumeFee * 100),
      effectiveFrom: apiStartDate,
      effectiveTo: apiEndDate,
    });
  };

  const onChangeRate = (key, value, index) => {
    setSlabs((prevRates) => prevRates?.map((item, idx) => (idx === index ? { ...item, [key]: value } : item)));
  };

  const addItem = () => {
    setSlabs((prevRates) => [...prevRates, { minRange: '', maxRange: '', rate: '' }]);
  };

  const handleDeleteSlab = (deleteIndex) => {
    const alteredData = slabs.filter((_, index) => index !== deleteIndex);
    setSlabs(alteredData);
    setDeleteSlab({ open: false, deleteIndex: null, deleteItem: null });
  };

  const getPrefix = (value) => {
    switch (value) {
      case 'INR':
        return '₹';
      case 'USD':
        return '$';
      default:
        return '';
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Edit Exchange Rate"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>From currency</SpH5></label>
          <Select
            className="w-100"
            defaultValue="Select Type"
            value={fromCurrencyCode}
            onChange={(e) => setFromCurrencyCode(e)}
          >
            <Option key={1} value="USD">USD</Option>
            <Option key={2} value="INR">INR</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('Currency Code', fromCurrencyCode, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>To currency</SpH5></label>
          <Select
            className="w-100"
            defaultValue="Select Type"
            value={toCurrencyCode}
            onChange={(e) => setToCurrencyCode(e)}
          >
            <Option key={1} value="USD">USD</Option>
            <Option key={2} value="INR">INR</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('CurrencyCode', toCurrencyCode, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Exchange Rate (1 {fromCurrencyCode} = [?] {toCurrencyCode})</SpH5></label>
          {slabs.map((item, index) => (
            <div key={index} className="d-flex align-items-baseline p-2 bg-white rounded">
              <div className="d-flex flex-column w-100">
                <Input
                  placeholder="0.00"
                  value={item.minRange}
                  min={1}
                  prefix={getPrefix(fromCurrencyCode)}
                  onChange={(e) => onChangeRate('minRange', e.target.value, index)}
                />
                <SpError>
                  {simpleValidator.current.message(`minRange${index}`, item?.minRange, 'required|positiveNumber')}
                </SpError>
              </div>
              <label htmlFor="" className="mr-3 ml-3"><SpH5>To</SpH5></label>
              <div className="d-flex flex-column w-100">
                <Input
                  placeholder="0.00"
                  value={item.maxRange}
                  min={1}
                  onChange={(e) => onChangeRate('maxRange', e.target.value, index)}
                  prefix={getPrefix(fromCurrencyCode)}
                />
                <SpError>
                  {simpleValidator.current.message(`maxRange${index}`, item.maxRange, 'required|positiveNumber')}
                </SpError>
              </div>
              <label htmlFor="" className="mr-1 ml-3"><SpH5>Rate</SpH5></label>
              <div className="d-flex flex-column w-100">
                <Input
                  placeholder="0.00"
                  value={item.rate}
                  min={1}
                  onChange={(e) => onChangeRate('rate', e.target.value, index)}
                  prefix={getPrefix(toCurrencyCode)}
                />
                <SpError>
                  {simpleValidator.current.message(`rate${index}`, item.rate, 'required|positiveNumber')}
                </SpError>
              </div>
              {index !== 0 ? (
                <div className="ml-2">
                  <Icon
                    type="delete"
                    style={{ cursor: 'pointer', color: '#FA6060' }}
                    onClick={(e) => { e.stopPropagation(); setDeleteSlab({ open: true, deleteIndex: index, deleteItem: item }); }}
                  />
                </div>
              ) : <Icon style={{ margin: '10px' }} />}
            </div>
          ))}
        </div>
        <div>
          <SpText
            color="#1890ff"
            fontWeight="600"
            style={{ cursor: 'pointer' }}
            onClick={() => addItem()}
          >
            Add Item
          </SpText>
        </div>
        <div className="mb-3">
          <div className="d-flex">
            <div className="w-50 mr-3" style={{ marginTop: '29px' }}>
              <label className="d-block"><SpH5>Low Volume Limit</SpH5></label>
              <Input
                placeholder="Quantity"
                prefix={prefix}
                value={lowVolumeLimit}
                onChange={(e) => setLowVolumeLimit(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('lowVolumeLimit', lowVolumeLimit, 'required|positiveNumber')}
              </SpError>
            </div>

            <div className="w-50">
              <label className="d-block"><SpH5>Low Volume Fee</SpH5></label>
              <RadioGroup style={{ marginBottom: '8px' }} onChange={onRadioChange} value={lowVolumeFeeType}>
                {LOWVOLUME_FEE_TYPES.map((option) => (
                  <Radio key={option.id} value={option.value}>
                    {option.display}
                  </Radio>
                ))}
              </RadioGroup>
              <Input
                placeholder="Quantity"
                prefix={lowVolumeFeeType === 'PERCENTAGE' ? '%' : prefix}
                value={lowVolumeFee}
                onChange={(e) => setLowVolumeFee(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('lowVolumeFee', lowVolumeFee, 'required|positiveNumber')}
              </SpError>
            </div>
          </div>

        </div>
        <div>
          <label style={{ display: 'block' }} htmlFor=""><SpH5>Select Dates</SpH5></label>
          <RangePicker
            allowClear={false}
            onChange={(value) => {
              setDates(value);
              customFunction(value);
            }}
            // disabledDate={disabledDate}
            onCalendarChange={value => {
              setDates(value);
            }}
            showTime={{ format: 'HH:mm' }}
            value={dates}
            onOpenChange={customStartDateReset}
            format={dateformat}
          />
          <span style={{ display: 'block' }}>Note: UTC format</span>
          <SpError>
            {simpleValidator.current.message('Date', apiStartDate, 'required')}
          </SpError>
        </div>
      </div>
      {/* ----------------------------------------DELETE MODAL----------------------------------------------- */}
      <Modal
        centered
        closable={false}
        visible={deleteSlab?.open}
        footer={[
          <SpButton onClick={() => setDeleteSlab({ open: false, deleteIndex: null, deleteItem: null })} type="secondary">Cancel</SpButton>,
          <SpButton onClick={() => handleDeleteSlab(deleteSlab?.deleteIndex)} type="danger">Delete</SpButton>,
        ]}
        title="Confirm Deletion"
      >
        <div className="px-4">
          <div className="mt-1 mb-2">Are you sure you want to delete this slab rate?</div>
          <SpH5 className="pb-3">
            <div>
              {deleteSlab?.deleteItem ? (
                <>
                  [{deleteSlab.deleteItem.minRange || '-'}{getPrefix(fromCurrencyCode)}] -
                  [{deleteSlab.deleteItem.maxRange || '-'}{getPrefix(fromCurrencyCode)}] <>&nbsp;&nbsp;</>
                  [Rate {deleteSlab.deleteItem.rate || '-'}{getPrefix(toCurrencyCode)}]
                </>
              ) : (
                <>&#8211;</>
              )}
            </div>
          </SpH5>
        </div>
      </Modal>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,

});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  addExchangeRate: param => dispatch(exchangeRateActions.addExchangeRate({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(EditExchangeRate);
