import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  TRANSFERS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchTransfers = async (payload) => {
  let parm = '';
  const queryParam = payload.payload.searchParam;
  if (queryParam && queryParam !== 'ALL_TRANSFERS') {
    parm = `status.IN=${payload.payload.searchParam}`;
  } else if (queryParam && queryParam === 'ALL_TRANSFERS') {
    parm = '';
  }
  const query = generateQuery(payload.payload.currentPage);
  const sorting = payload.payload.sortParams ? payload.payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (parm && !payload.payload.filterParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}${query}&${parm}${sorting}`);
  }
  if (payload.payload.filterParams && !parm && !payload.payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}${query}&${payload.payload.filterParams}`);
  }
  if (payload.payload.filterParams && parm && payload.payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}${query}&${parm}&${payload.payload.filterParams}${sorting}`);
  }
  if (payload.payload.filterParams && !parm && payload.payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}${query}&${payload.payload.filterParams}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_TRANSFERS}${query}${sorting}`);
};

const postTransfer = async payload => axiosAuthInstance.post(API_END_POINTS.GET_TRANSFERS, payload);

const patchTransfer = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_TRANSFERS}/${gid}/confirm`, params);

const cancelTransfer = async ({ gid }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_TRANSFERS}/${gid}/cancel`, {});

const cancelSelectedTransfer = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INTERNALTRANSFERS}/${gid}/cancel`, params);

const completeTransfer = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INTERNALTRANSFERS}/${gid}/complete`, params);

const crossBorder = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INTERNALTRANSFERS}/${gid}/cross-border`, params);

const getContactList = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}?page=1&size=100&name.LIKE=${payload}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}?page=1&size=100`);
};

const searchFundingSource = async (payload) => {
  if (payload) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}?${payload}`);
  } return axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}?page=1&size=100`);
};

const fetchFundingSource = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_FUNDINGSOURCE}?page=1&size=100`);

export function* getTransfers(action) {
  try {
    const { data: { entity } } = yield call(fetchTransfers, action.payload);
    yield put({ type: TRANSFERS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: TRANSFERS.FAILED, payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* fetchContactList(action) {
  try {
    const { data: { entity } } = yield call(getContactList, action.payload);
    yield put({ type: TRANSFERS.CONTACT_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: TRANSFERS.CONTACT_FAILED, payload: e.response.data });
  }
}

export function* searchFundingSourceList(action) {
  try {
    const { data: { entity } } = yield call(searchFundingSource, action.payload);
    yield put({ type: TRANSFERS.SEARCH_FUNDING_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: TRANSFERS.SEARCH_FUNDING_FAILED, payload: e.response.data });
  }
}

export function* issueTransfer(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(postTransfer, action.payload);
    if (entity?.errorDescription) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: entity?.errorDescription,
      });
    }
    yield put({ type: TRANSFERS.ISSUETRGID, payload: entity });
    if (entity?.errorDescription === null) {
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.TRANSFERS.ADD_SUCCESS,
      });
    }
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* getFundingSource(action) {
  try {
    const { data: { entity } } = yield call(fetchFundingSource, action.payload);
    yield put({ type: TRANSFERS.FUNDING_SUCCESS, payload: entity.content });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: TRANSFERS.FUNDING_FAILED, payload: e.response.data });
  }
}

export function* confirmTransferOtp(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchTransfer, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TRANSFERS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* cancelTransferInDetails(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(cancelTransfer, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TRANSFERS.CANCEL_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}


export function* updateTransfers(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(completeTransfer, action.payload.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TRANSFERS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* cancelTransfers(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(cancelSelectedTransfer, action.payload.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Cancelled selected transfer',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateCrossBorder(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(crossBorder, action.payload.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.TRANSFERS.CROSS_BORDER_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
