import React, { useEffect, useState } from 'react';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';

export const { format: formatPrice } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
});

type Props = {
    eventDetails : Object,
    eventPageProduct : Object,
    mobileView : Boolean,
    updateQuantity : Function,
    setFullScreen : Function,
}
const ChooseSeats = (props: Props) => {
  const {
    eventDetails,
    eventPageProduct,
    mobileView,
    updateQuantity,
    setFullScreen,
  } = props;

  const productCopy = eventPageProduct;
  const [publicKey, setPublicKey] = useState();
  const [eventKey, setEventKey] = useState();
  const [seatInfo, setSeatInfo] = useState();
  const [tooltipData, setTooltipData] = useState();
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(true);

  useEffect(() => {
    if (!timer) {
      setTimeout(() => {
        const newCount = count - 1;
        setCount(newCount >= 0 ? newCount : 0);
        if (newCount <= 0) {
          // chartRef.current.clearSelection();
          // expiredToken();
        }
      }, 1000);
    }
  }, [count]);

  useEffect(() => {
    if (eventDetails.seatData && productCopy.eventPageProductType === 'TICKET') {
      const encodeQuery = atob(eventDetails.seatData);
      const parseData = JSON.parse(encodeQuery);
      const publicId = atob(eventDetails.seatPublicKey);
      const eventId = atob(eventDetails.seatEventId);
      setPublicKey(publicId);
      setEventKey(eventId);
      setSeatInfo(parseData.pricingData);
      setTooltipData(parseData.tooltipData);
      if (timer) {
        const seconds = 900;
        if (timer) {
          setCount(Math.trunc(seconds));
          setTimer(false);
        }
      }
    }
  }, []);

  const tooltipFormat = (obj) => {
    const keyId = obj.category.key;
    const tooltipLabel = tooltipData && tooltipData.filter((data) => data.category.id === keyId);
    if (tooltipLabel && tooltipLabel.length !== 0) {
      return `${tooltipLabel[0].category.tooltip}`;
    }
    return '';
  };

  const onObjectSelected = async (item) => {
    const selectedGid = item.selectedTicketType;
    const customItem = eventPageProduct?.customItems?.find((data) => data.gid === selectedGid);
    updateQuantity(customItem, null, null, item.label, 1, 0);
  };

  const onObjectDeselected = async (item, price) => {
    const selectedGid = price && price.ticketType;
    const customItem = eventPageProduct?.customItems?.find((data) => data.gid === selectedGid);
    updateQuantity(customItem, null, null, item.label, -1, 0);
  };

  const onFullScreenOpened = () => {
    setFullScreen(true);
  };

  const onFullScreenClosed = () => {
    setFullScreen(false);
  };

  return (
    <>
      {eventPageProduct && (
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className={mobileView ? 'mt-2 mb-2' : 'mt-4 mb-4'}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ fontSize: mobileView ? '14px' : '20px', fontWeight: '550' }}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Please select your tickets and click on 'Selected Seats' to proceed.
          </p>
        </div>
        <SeatsioSeatingChart
          workspaceKey={publicKey}
          pricing={seatInfo}
          priceFormatter={(price) => formatPrice(price)}
          openDraftDrawing="true"
          event={eventKey}
          showFullScreenButton
          region="na"
          onObjectSelected={onObjectSelected}
          onObjectDeselected={(item, price) => onObjectDeselected(item, price)}
          holdOnSelect
          holdToken={sessionStorage.getItem('seatsio') ? JSON.parse(sessionStorage.getItem('seatsio')).holdToken : undefined}
          tooltipInfo={(object) => tooltipFormat(object)}
          onFullScreenOpened={onFullScreenOpened}
          onFullScreenClosed={onFullScreenClosed}
          deselectObject={['B-17', 'B-18']}
        />
      </div>
      )}
    </>
  );
};

export default ChooseSeats;
