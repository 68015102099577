// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Select,
  Input,
  DatePicker,
} from 'antd';

import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError } from 'components/DesignKit';
import {
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';
import formatAmount from 'utils/formatAmount';

const { Option } = Select;

type Props = {
  submitting: boolean,
  transferDetailsData: Object,
  selectedAccount: Object,
  currencyType: Object,
  transferSettings: Object,
  supportsSchedule: boolean,
  issuerName: string,
};

const TransferDetails = (props: Props, ref) => {
  const {
    submitting,
    transferDetailsData,
    selectedAccount,
    currencyType,
    supportsSchedule,
    issuerName,
    transferSettings,
  } = props;
  const [, forceUpdate] = useState();
  const { prefix } = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [transferAmount, setTransferAmount] = useState((transferDetailsData && transferDetailsData.transferAmount)
    ? formatAmount(transferDetailsData.transferAmount, currencyType)
    : '');
  const [description, setDescription] = useState(transferDetailsData ? transferDetailsData.description : '');
  const [transferType, setTransferType] = useState(transferDetailsData ? transferDetailsData.transferType : '');
  const [transferDate, setTransferDate] = useState(transferDetailsData ? transferDetailsData.transferDate : '');
  const [transferApiDate, setTransferApiDate] = useState(transferDetailsData.transferApiDate);
  // eslint-disable-next-line no-unused-vars
  const [transferTypeLIst, setTransferTypeList] = useState(selectedAccount?.supportedTransferTypes);
  const disableDate = transferDetailsData.transferDate;
  const minLimit = (transferSettings && transferSettings.minLimit / 100);
  const maxLimit = (transferSettings && transferSettings.maxLimit / 100);

  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      imps: {
        message: MAX_AMOUNT.MESSAGE_IMPS,
        rule: (val) => (
          MAX_AMOUNT.IMPS_MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.IMPS_MAX_AMOUNT),
      },
      rtgs: {
        message: MAX_AMOUNT.MESSAGE_RTGS,
        rule: (val) => (
          MAX_AMOUNT.RTGS_MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.RTGS_MAX_AMOUNT),
      },
      neft: {
        message: MAX_AMOUNT.MESSAGE_NEFT,
        rule: (val) => (
          MAX_AMOUNT.NEFT_MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.NEFT_MAX_AMOUNT),
      },
      minmaxAmount: {
        message: `Amount should be between ${prefix}${minLimit} - ${prefix}${maxLimit}`,
        rule: (val) => (
          minLimit <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= maxLimit),
      },
    },
  }));

  const onDateChange = (date, dateString) => {
    setTransferDate(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setTransferApiDate(utcDate);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      transferAmount,
      description,
      transferApiDate,
      transferDate,
      transferType,
    }),
  }));

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-3">
          <div className="d-flex">
            <div style={{ width: '50%' }}>
              <label htmlFor=""><SpH5>Amount</SpH5></label>
              <div>
                <Input
                  value={transferAmount}
                  prefix={prefix}
                  placeholder="0.00"
                  onChange={(e) => {
                    const regex = /^\d*\.?\d*$/;
                    const { value } = e.currentTarget;
                    if (regex.test(value) || value === '') {
                      setTransferAmount(e.currentTarget.value);
                    } else if (!regex.test(value)) {
                      setTransferAmount(0.00);
                    }
                  }}
                  onKeyPress={e => {
                    const keyCode = e.charCode || e.which;
                    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                      e.preventDefault();
                    }
                  }}
                  onBlur={e => {
                    if (e.currentTarget.value) {
                      const value = formatAmount(e.currentTarget.value, currencyType);
                      setTransferAmount(value);
                    }
                  }}
                />
                {
                  transferSettings && Object.keys(transferSettings).length ? (
                    <SpError>
                      {simpleValidator.current.message('amount', transferAmount, 'required|minmaxAmount')}
                    </SpError>
                  ) : issuerName === 'ICICIBank' ? (
                    <SpError>
                      {
                        transferType === 'IMPS' ? (
                          <>
                            {simpleValidator.current.message('amount', transferAmount, 'required|imps')}
                          </>
                        ) : transferType === 'RTGS' ? (
                          <>
                            {simpleValidator.current.message('amount', transferAmount, 'required|rtgs')}
                          </>
                        ) : (
                          <>
                            {simpleValidator.current.message('amount', transferAmount, 'required|neft')}
                          </>
                        )
                      }
                    </SpError>
                  ) : (
                    <SpError>
                      {simpleValidator.current.message('amount', transferAmount, 'required|amount')}
                    </SpError>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        {
          supportsSchedule && (
            <div className="d-flex mb-3">
              <div style={{ width: '50%' }}>
                <label htmlFor=""><SpH5>Schedule Transfer Date</SpH5></label>
                <div>
                  <DatePicker
                    value={moment(transferDate)}
                    format={dateFormat}
                    allowClear={false}
                    onChange={onDateChange}
                    disabledDate={current => current && current < moment(disableDate)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Transfer Date', transferDate, 'required')}
                  </SpError>
                </div>
              </div>
            </div>
          )
        }
        <div className="mb-3">
          <label htmlFor=""><SpH5>Transfer Type</SpH5></label>
          {/* {
            (isIndia && (issuerName === 'Cashfree')) ? (
              <Select
                className="w-100"
                showSearch
                value={transferType}
                onChange={(e) => setTransferType(e)}
              >
                {
                  CASHFREE_TRANSFER_TYPES.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))
                }
              </Select>
            ) : isIndia ? (
              <Select
                className="w-100"
                showSearch
                value={transferType}
                onChange={(e) => setTransferType(e)}
              >
                {
                  TRANSFER_TYPES.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))
                }
              </Select>
            ) : (
              <Select
                className="w-100"
                showSearch
                value={transferType}
                onChange={(e) => setTransferType(e)}
              >
                <Option key={1} value="ACH">ACH</Option>
                <Option key={2} value="RTP">RTP</Option>
              </Select>
            )
          } */}
          <Select
            className="w-100"
            showSearch
            value={transferType}
            onChange={(e) => setTransferType(e)}
          >
            {
              transferTypeLIst.map((item) => (
                <Option key={1} value={item}>{item.replace(/_/g, ' ')}</Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('transfer Type', transferType, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <Input
            placeholder="Refund, Vendor Bill, Salary, etc."
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('description', description, 'required')}
          </SpError>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(TransferDetails);
