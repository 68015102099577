/* eslint-disable no-unused-vars */
// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
  Drawer,
  List,
  Spin,
  Switch,
  Modal,
  Input,
} from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpDragInput,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';

import {
  inventoryCategoriesActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import ItemClose from 'components/NavIcons/itemClose';
import DragIcon from 'components/NavIcons/dragIcon';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import SortAscending from 'imgs/SortAscending.png';
import SortDescending from 'imgs/SortDescending.png';
import online from 'imgs/online.svg';
import AddCategory from './components/AddCategory';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  inventoryCategories: Array<Object>,
  sortCategoryList: Array<Object>,
  fetchInventoryCategories: Function,
  addInventoryCategory: Function,
  fetchInventorySortCategory: Function,
  updateInventorySortCategory: Function,
  fetchCategoryOperatingHours:Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  history: {
    push: Function,
  },
  setFilterData: Function,
  preserveFilters: Object,
  submitting: Boolean,
  validator: Boolean,
  menuData:Object,
  fetchSearchCategories: Function,
  searchTypes: String,
  updateBulkCategories: Function,
};

const CategoriesList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    inventoryCategories,
    loading,
    fetchInventoryCategories,
    fetchCategoryOperatingHours,
    addInventoryCategory,
    sortCategoryList,
    fetchInventorySortCategory,
    updateInventorySortCategory,
    totalElements,
    test,
    history,
    preserveFilters,
    menuData,
    setFilterData,
    submitting,
    validator,
    updateBulkCategories,
    fetchSearchCategories,
    searchTypes,
  } = props;

  const [pagination, setPagination] = useState({});
  const { pathname } = location;
  const myRef = useRef();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const PAYMENTLINKS_FILTERS_TYPES = FILTERS_TYPES.INVENTORY_CATEGORY;
  const [filterTypes, setFilterTypes] = useState(FILTERS_TYPES.INVENTORY_CATEGORY);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [filterPayments, setFilterPayments] = useState((preserveFilters && preserveFilters.filterPayments)
    ? (preserveFilters && preserveFilters.filterPayments)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayments: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [dragData, setDragData] = useState(sortCategoryList);
  const [payloadObject, setPayloadObject] = useState({});
  const [onSortUp, setOnSortUp] = useState(true);
  const [sortOrderFetch, setSortOrderFetch] = useState(true);
  const [spiner, setSpinner] = useState(false);
  const [prevPageNum, setPrevPageNum] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [availableSpace, setAvailableSpace] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [categoryDropDown, setcategoryDropDown] = useState([]);

  const kdsRole = localStorage.getItem('kdsRole');
  const [sorting, setSorting] = useState({
    field: 'name',
    order: 'ascend',
  });
  const onClose = () => {
    setOpen1(false);
    setSpinner(false);
  };

  const exportData = inventoryCategories ? inventoryCategories.map((values) => ({
    name: values.name || '',
    color: values.colorCode || '',
    order: values.sortOrder || 0,
  })) : '';

  const exportHeaders = [
    { label: 'NAME', key: 'name' },
    { label: 'COLOR CODE', key: 'color' },
    { label: 'SORT ORDER', key: 'order' },
  ];

  const debouncedFetchInventoryCategories = debounce(fetchInventoryCategories, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const sortParam = '&sortBy=name&direction=ASC';
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    const nameSearch = searchText ? (searchText === '' ? null : `&name.LIKE=${searchText}`) : null;
    debouncedFetchInventoryCategories({
      currentPage, sortParams, filterParams, nameSearch,
    });
    fetchInventorySortCategory({ sortParam });
    // setcategoryDropDown(fetchInventorySortCategory({ sortParam }));
    // alert(JSON.stringify(fetchInventorySortCategory({ sortParam })));
  };

  useEffect(() => {
    if (searchTypes) {
      setSearchText(searchTypes);
      fetchSearchCategories('');
      const sortParams = '&sortBy=name&direction=ASC';
      const currentPage = 1;
      const filterParams = (preserveParam ? preserveParam.filterParams : '');
      setTimeout(() => {
        debouncedFetchInventoryCategories({
          currentPage, sortParams, filterParams, nameSearch: searchTypes ? (searchTypes === '' ? null : `&name.LIKE=${searchTypes}`) : null,
        });
      }, 10);
    }
  }, [searchTypes]);

  useEffect(() => {
    getData(location.search);
    fetchCategoryOperatingHours('&sortBy=sortOrder&direction=ASC');
  }, [location, test, selectedAccount]);

  useEffect(() => {
    setDragData(sortCategoryList);
  }, [sortCategoryList]);

  useEffect(() => {
    if (!validator && updateStatus) {
      setAddCategoryModal(false);
      setOpen1(false);
      setOnSortUp(true);
      setSelectedRowKeys([]);
      setSelectedKeys([]);
      setDisableSwitch(false);
      setModalVisible(false);
      setSearchText('');
      // fetchInventoryCategories('&sortBy=sortOrder&direction=ASC');
    }
  }, [validator]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [inventoryCategories]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addSelectedItem = (customer: Object) => {
    const {
      name,
      onlineName,
      primaryColor,
      order,
      description,
      operatingHours,
    } = customer;

    const params = {
      name,
      onlineName,
      description,
      sortOrder: Number(order),
      colorCode: primaryColor,
      // hoursOfOperations: operatingHours,gid
      // enableHoursOfOperation: enableOperationHours,
      operationalHoursMasterGid: operatingHours,
    };
    addInventoryCategory({
      params,
    });
    setAddCategoryModal(false);
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    setPrevPageNum(Number(page.current));
    const tempPage = page.current;
    let tempParams = '';
    // if (sorting.order === 'ascend') {
    //   setSorting({
    //     field: 'sortOrder',
    //     order: 'descend',
    //   });
    // } else {
    //   setSorting({
    //     field: 'sortOrder',
    //     order: 'ascend',
    //   });
    // }
    setSorting({
      field: filters.field,
      order: filters.order,
    });
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.INVENTORY_CATEGORIES,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.INVENTORY_CATEGORIES,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterPayments];
    if (filterPayments.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = PAYMENTLINKS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterPayments(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterPayments];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'applicationFee') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = PAYMENTLINKS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterPayments(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterPayments(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterPayments(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPayments[index].filterName === 'createdAt') {
      if (filterPayments[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterPayments[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterPayments[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterPayments[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPayments];
    list[index].filterValue = selectedValue;
    setFilterPayments(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPayments];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPayments[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterPayments(list);
  };

  const handleAddClick = () => {
    const list = [...filterPayments];
    setFilterPayments([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(FILTERS_TYPES.INVENTORY_CATEGORY);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.INVENTORY_CATEGORIES);
  };

  const applyFilters = () => {
    const paymentsFilters = filterPayments.filter((item) => item.checked);
    for (let i = 0; i < paymentsFilters.length; i += 1) {
      if (!paymentsFilters[i].filterName || !paymentsFilters[i].filterOperator || !paymentsFilters[i].filterValue) {
        return;
      }
    }
    if (paymentsFilters[0].filterValue || (paymentsFilters[0].filterValueFrom && paymentsFilters[0].filterValueTo)) {
      setCount(paymentsFilters && paymentsFilters.length);
      for (let i = 0; i < paymentsFilters.length; i += 1) {
        if (paymentsFilters[i].checked) {
          if (paymentsFilters[i].filterName === 'createdAt') {
            paymentsFilters[i] = paymentsFilters[i].filterValue;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'phoneNumber') {
            const value = paymentsFilters[i].filterValue.replace('+', '%2B');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'applicationFee' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'applicationFee' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'email') {
            const email = encodeURIComponent(paymentsFilters[i].filterValue);
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${email}`;
          } else {
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${paymentsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.INVENTORY_CATEGORIES,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.INVENTORY_CATEGORIES,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const selectedRecord = (record) => {
    const itemGid = record.gid;
    fetchSearchCategories(searchText);
    history.push({
      pathname: `${PAGES.INVENTORY_CATEGORIES}/${itemGid}`,
      state: location.search,
    });
  };


  const handleLineItemAvailableSpaceToggle = (record, value) => {
    const params = [];
    const param = {
      gid: record.gid,
      enableHoursOfOperation: value,
    };
    params.push(param);
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    updateBulkCategories({
      params,
      payload: {
        currentPage, sortParams, filterParams,
      },
    });
    setUpdateStatus(true);
  };

  const handleAvailableSpaceToggle = (value) => {
    setAvailableSpace(value);
    const params = [];
    // eslint-disable-next-line
    const updatedItemPayload = selectedRowKeys.map((record) => {
      const param = {
        gid: record.gid,
        enableHoursOfOperation: !value,
      };
      params.push(param);
    });
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    updateBulkCategories({
      params,
      payload: {
        currentPage, sortParams, filterParams,
      },
    });
    setUpdateStatus(true);
    setDisableSwitch(true);
  };

  const handleModalToggle = () => {
    const filterdValues = selectedRowKeys && selectedRowKeys.filter(data => data.enableHoursOfOperation);
    // eslint-disable-next-line
    const avail = (filterdValues.length === 0) ? true : false;
    setAvailableSpace(avail);
    setModalVisible(!modalVisible);
  };


  const columns = [
    {
      title: 'CATEGORY',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'COLOR CODE',
      dataIndex: 'colorCode',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (colorCode) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {colorCode || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'OPERTAING HOURS',
      dataIndex: 'operatingHoursMaster',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { operatingHoursMaster } = record;
        return (
          <span style={{ cursor: 'pointer' }}>
            {(operatingHoursMaster && operatingHoursMaster.name) || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'SORT ORDER',
      dataIndex: 'sortOrder',
      width: '10%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (sortOrder) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {sortOrder || 0}
          </span>
        </Tooltip>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>ONLINE</div>
          {
            (selectedRowKeys && selectedRowKeys.length) > 0 ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleModalToggle();
                  setDisableSwitch(false);
                }}
              >
                <img
                  src={online}
                  alt="Online Icon"
                  style={{ width: 5, marginLeft: 15, cursor: 'pointer' }}
                />
              </div>
            ) : (
              <div>
                <img
                  src={online}
                  alt="Online Icon"
                  style={{ width: 5, marginLeft: 15, cursor: 'not-allowed' }}
                />
              </div>
            )
          }
        </div>
      ),
      dataIndex: 'enableHoursOfOperation',
      className: 'no-break-word',
      width: '5%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (enableHoursOfOperation, record) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleLineItemAvailableSpaceToggle(record, !enableHoursOfOperation);
          }}
        >
          <Switch
            checked={!enableHoursOfOperation}
          />
        </div>
      ),
    },
    //   {
    //     title: (
    //       <div style={{display:"flex"}}>
    //         <div>

    //         ONLINE
    //         </div>
    //         <Popover
    //           placement="bottom"
    //           className='mt-1 cursor-pointer'
    //           content={
    //             <div>
    //               <span className='m-1'>Available</span>
    //               <Switch
    //                 // checked={allAvailable}
    //                 // onChange={(checked) => updateItems(selectedRows , checked)}
    //                 // onChange={() =>{setAll(!all); updateAvailability(!all , value);}}
    //               />
    //             </div>
    //           }
    //           trigger="click"
    //         >
    //           <BsThreeDotsVertical />
    //         </Popover>
    //       </div>
    //     ),
    //     dataIndex: 'enableHoursOfOperation',
    //     className: 'no-break-word',
    //   width: '10%',
    //   align: 'left',
    //   sorter: false,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //     render: (enableHoursOfOperation) => {
    //       let value ='';
    //       if (!enableHoursOfOperation) {
    //         value =active;
    //       } else {
    //         value =deactive;
    //       }
    //       return <img  src={value} height="20px" alt={''}/>;
    //     },
    // },

  ];

  const arrayMove = (array, fromIndex, toIndex) => {
    const newArray = [...array];
    const [movedItem] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, movedItem);
    return newArray;
  };
  const SortableItem = SortableElement(({ value }) => (
    <>
      <List.Item
        style={{
          cursor: 'pointer',
          border: 'none',
        }}
      >
        <Row>
          <Col>
            <SpDragInput style={{ width: '410px' }}>
              <DragIcon /> &nbsp;&nbsp; <div style={{ marginLeft: '10px' }}>{value.name}</div>
            </SpDragInput>
          </Col>
        </Row>
      </List.Item>
    </>
  ));
  const SortableList = SortableContainer(() => (
    <List
      dataSource={dragData}
      renderItem={(item, index) => (
        <SortableItem key={item.gid} index={index} value={item} />
      )}
    />
  ));
  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setSortOrderFetch(false);
    const reorderedData = arrayMove(dragData, oldIndex, newIndex);
    setDragData(reorderedData);
    const outputObject = {};
    reorderedData.forEach((item, index) => {
      outputObject[item.gid] = index;
    });
    setPayloadObject(outputObject);
  };
  const saveChanges = () => {
    let params;
    if (sortOrderFetch === true) {
      const outputObject = {};
      dragData.forEach((item, index) => {
        outputObject[item.gid] = index;
      });
      params = outputObject;
    } else {
      params = payloadObject;
    }
    setSpinner(false);
    updateInventorySortCategory({
      params,
    });
  };
  const onArrowUpChange = () => {
    setOnSortUp(false);
    setSortOrderFetch(true);
    const sortParam = '&sortBy=name&direction=DESC';
    setSpinner(true);
    fetchInventorySortCategory({ sortParam });
  };
  const onArrowDownChange = () => {
    setOnSortUp(true);
    setSortOrderFetch(true);
    setSpinner(true);
    const sortParam = '&sortBy=name&direction=ASC';
    fetchInventorySortCategory({ sortParam });
  };

  const onSelectChange = (selectedKey, selectedItems) => {
    setSelectedRowKeys(selectedItems);
    setSelectedKeys(selectedKey);
  };

  const rowSelection = {
    type: 'checkbox',
    selectedKeys,
    onChange: onSelectChange,
  };
  const handleSearch = (value) => {
    setSearchText(value);
    fetchSearchCategories(value);
    const sortParams = '&sortBy=name&direction=ASC';
    const currentPage = 1;
    const filterParams = (preserveParam ? preserveParam.filterParams : '');
    debouncedFetchInventoryCategories({
      currentPage, sortParams, filterParams, nameSearch: value ? (value === '' ? null : `&name.LIKE=${value}`) : null,
    });
  };

  if (loading && !spiner) {
    return <Loading />;
  }


  // console.log(selectedKeys , "selectedKey");
  // console.log(selectedRowKeys , "selectedItems")


  return (
    <Card>
      <Row className="mt-4 mb-4">
        <Col>
          <Input.Search
            value={searchText}
            placeholder="Name"
            onChange={(e) => handleSearch(e.target.value)}
            enterButton
          />
        </Col>
      </Row>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC
            permit={
              [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS,
                ROLES.SUPERUSER, ROLES.KDSUSER1, ROLES.KDSADMIN1]
            }
          >
            <Col>
              <Drawer
                title={(
                  <>
                    <div style={{ marginTop: '5px', color: '#000', fontSize: '20px' }}>
                      <Row type="flex" justify="space-between">
                        <Col>
                          Arrange Categories
                        </Col>
                        <Col>
                          <span style={{ cursor: 'pointer' }} onClick={() => setOpen1(false)}>
                            <ItemClose />
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={open1}
                width={500}
                footer={null}
              >
                {
                  loading ? (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <Spin size="small" />
                    </div>
                  ) : (
                    <>
                      <Row className="d-flex justify-content-end mt-2 mb-2 mr-4">
                        {
                          onSortUp ? (
                            <div
                              onClick={onArrowUpChange}
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={SortDescending} alt="" />
                            </div>
                          ) : (
                            <div
                              onClick={onArrowDownChange}
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={SortAscending} alt="" />
                            </div>
                          )
                        }
                      </Row>
                      <div
                        style={{ marginBottom: '50px' }}
                      >
                        <SortableList
                          items={dragData}
                          onSortEnd={handleSortEnd}
                        />
                      </div>
                      <div>
                        <Row
                          className="d-flex space-between mt-2"
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '450px',
                            padding: '20px',
                            backgroundColor: '#fff',
                            textAlign: 'center',
                            marginLeft: '-15px',
                          }}
                        >
                          <Col
                            style={{
                              marginLeft: '15px',
                            }}
                          >
                            <SpButton
                              type="secondary"
                              style={{ marginRight: '8px', width: '175px' }}
                              onClick={onClose}
                            >
                              Cancel
                            </SpButton>
                          </Col>
                          <Col>
                            <SpButton
                              type="primary"
                              style={{ width: '175px' }}
                              onClick={saveChanges}
                            >
                              Save
                            </SpButton>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )
                }
              </Drawer>
              {
                kdsRole !== 'KDS-USER' && (
                  <>
                    {
                      dragData && dragData.length > 1 ? (
                        <SpButton
                          type="secondary"
                          shape="round"
                          ghost
                          className="mr-3"
                          onClick={() => {
                            setOpen1(true);
                            setSpinner(true);
                          }}
                        >
                          {'\u002B'}&nbsp;Change Layout
                        </SpButton>
                      ) : null
                    }
                    <SpButton
                      type="secondary"
                      shape="round"
                      ghost
                      className="mr-3"
                      onClick={() => setAddCategoryModal(true)}
                    >
                      {'\u002B'}&nbsp;Add Category
                    </SpButton>
                  </>
                )
              }
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Categories">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Categories Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Categories">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '46px' }}
                  >
                    <Filters
                      filtersGrid={filterPayments}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterPayments.length < PAYMENTLINKS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>
      {/* ADD CATEGORY MODAL */}
      {addCategoryModal && (
        <AddCategory
          visible={addCategoryModal}
          submitting={submitting}
          selectedAccount={selectedAccount}
          menuData={menuData}
          close={() => setAddCategoryModal(false)}
          submit={addSelectedItem}
        />
      )}
      <Modal
        visible={modalVisible}
        closable={false}
        footer={null}
        onCancel={handleModalToggle}
        width={200}
        style={{
          top: '180px',
          left: '400px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <div>
            <Switch
              checked={availableSpace}
              disabled={disableSwitch}
              onChange={handleAvailableSpaceToggle}
            />
            &nbsp; &nbsp;Availablity
          </div>
        </div>
      </Modal>
      <RBAC
        fallback={(
          <Table
            rowSelection={rowSelection}
            className="custom-table"
            columns={columns.map(column => {
              if (column.dataIndex === sorting.field) {
                return {
                  ...column,
                  sortOrder: sorting.order,
                };
              }
              return column;
            })}
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            rowKey={(record) => record.gid}
            dataSource={inventoryCategories}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
      >
        <Table
          rowSelection={rowSelection}
          className="custom-table"
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          columns={columns.map(column => {
            if (column.dataIndex === sorting.field) {
              return {
                ...column,
                sortOrder: sorting.order,
              };
            }
            return column;
          })}
          rowKey={(record) => record.gid}
          dataSource={inventoryCategories}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};
const mapStateToProps = (state) => ({
  inventoryCategories: state.inventoryCategories.content,
  totalElements: state.inventoryCategories.totalElements,
  sortCategoryList: state.inventoryCategories.sortCategoryList.content,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  menuData: state.inventoryCategories.operatingHoursCategory,
  searchTypes: state.inventoryCategories.searchTypes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryCategories: param => dispatch(inventoryCategoriesActions.fetchInventoryCategories({
    payload: param,
  })),
  fetchSearchCategories: param => dispatch(inventoryCategoriesActions.fetchSearchCategories({
    payload: param,
  })),
  addInventoryCategory: param => dispatch(inventoryCategoriesActions.addInventoryCategory({
    payload: param,
  })),
  fetchInventorySortCategory: param => dispatch(inventoryCategoriesActions.fetchInventorySortCategory({
    payload: param,
  })),
  updateInventorySortCategory: param => dispatch(inventoryCategoriesActions.updateInventorySortCategory({
    payload: param,
  })),
  updateBulkCategories: param => dispatch(inventoryCategoriesActions.updateBulkCategories({
    payload: param,
  })),
  fetchCategoryOperatingHours: param => dispatch(inventoryCategoriesActions.fetchCategoryOperatingHours({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
