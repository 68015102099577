// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Menu,
  Switch,
  TimePicker,
  Icon,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpTab,
} from 'components/DesignKit';

import {
  SpColor,
} from 'components/PaymentWidgetDesignKit';

import Loading from 'components/Loading';
import {
  inventoryCategorieDetailsActions,
  inventoryCategoriesActions,
  inventoryItemsActions,
  notificationActions,
} from 'store/actions';
import {
  PAGES,
} from 'appconstants';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import EditItems from './components/EditItems';
import DeleteCategory from './components/DeleteCategory';
import AddRemoveItems from './components/AddRemoveItems';

type Props = {
  fetchAllShop: Function,
  fetchInventoryCategorieDetails: Function,
  fetchCategoryOperatingHours: Function,
  fetchItemByCategory: Function,
  fetchInventorySortItems: Function,
  addItemCategory: Function,
  inventoryCategorieDetails: Object,
  categoryItemsList: Array,
  inventoryItems: Array,
  loading: boolean,
  selectedAccount: Object,
  updateInventoryCategory: Function,
  deleteInventoryCategory: Function,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  submitting: Boolean,
  validator: Boolean,
  totalElements: Number,
  menuData: Array,
  getshopList: Array,
  updateInventoryItems: Function,
  updateBulkItems: Function,
}

const CategorieDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchAllShop,
    fetchInventoryCategorieDetails,
    fetchCategoryOperatingHours,
    fetchItemByCategory,
    addItemCategory,
    fetchInventorySortItems,
    inventoryCategorieDetails,
    updateInventoryCategory,
    deleteInventoryCategory,
    categoryItemsList,
    inventoryItems,
    loading,
    history,
    submitting,
    validator,
    selectedAccount,
    totalElements,
    menuData,
    getshopList,
    updateInventoryItems,
    updateBulkItems,
  } = props;
  const { gid } = props.match.params;
  const path = props.match.url;
  const gidPath = gid.split('-')[1];
  const [addItemsModal, setAddItemsModal] = useState(false);
  const [editItemsModal, setEditItemsModal] = useState(false);
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [pagination, setPagination] = useState({});
  const currencyType = selectedAccount && selectedAccount.currency;
  const kdsRole = localStorage.getItem('kdsRole');
  const [current, setCurrent] = useState('itemsTab');
  const [operatingHours, setOperatingHours] = useState([]);
  const [enableOperationHours, setEnableOperationHours] = useState(false);
  const [editHoursModal, setEditHoursModal] = useState(false);
  const [fetchFirstTime, setFetchFirstTime] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [updateStatuses, setUpdateStatus] = useState(false);
  const [availableSpace, setAvailableSpace] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState(false);
  // eslint-disable-next-line
  const handleSelectChange = (selectedRowKeys, selectedItems) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedKeys(selectedItems);
  };

  // This useEffect is used to update setAvailableSpace in  the data at onLoad
  useEffect(() => {
    const allAvailable = categoryItemsList.every(item => item?.available === true);
    const allUnAvailable = categoryItemsList.every(item => item?.available === false);
    if (categoryItemsList.length > 0 && allAvailable) {
      setAvailableSpace(true);
    } else if (categoryItemsList.length > 0 && allUnAvailable) {
      setAvailableSpace(false);
    }
  }, [categoryItemsList]);

  useEffect(() => {
    // eslint-disable-next-line
    setRowSelection((prevRowSelection) => ({
      ...prevRowSelection,
      selectedRowKeys,
    }));
  }, [selectedRowKeys]);

  const [rowSelection, setRowSelection] = useState({
    type: 'checkbox',
    selectedRowKeys,
    onChange: handleSelectChange,
  });

  const handleAvailableSpaceToggle = async (value) => {
    setAvailableSpace(value);
    const params = [];
    // eslint-disable-next-line
    const updatedItemPayload = selectedKeys.map((record) => {
      const shopIds = (record && record.shops && record.shops.length !== 0) ? record.shops.map((option) => option.gid) : [];
      const tagIds = (record && record.tags && record.tags.length !== 0) ? record.tags.map((option) => option.gid) : [];
      const categoryIds = (record && record.categories && record.categories.length !== 0) ? record.categories.map((option) => option.gid) : [];
      // eslint-disable-next-line max-len
      const modifierIds = (record && record.modifierGroups && record.modifierGroups.length !== 0) ? record.modifierGroups.map((option) => option.gid) : [];
      const param = {
        name: record.name,
        gid: record.gid,
        alternateName: record.alternateName,
        sku: record.sku,
        price: (record && record.price) || 0,
        hidden: record.hidden,
        priceType: record.priceType,
        sortOrder: record.sortOrder,
        colorCode: record.colorCode,
        categoryGids: categoryIds || [],
        modifierGroupGids: modifierIds || [],
        available: value,
        cost: (record && record.cost) || 0,
        imageUrl: record.imageUrl,
        description: record.description,
        shopGids: shopIds || [],
        tagGids: tagIds || [],
      };
      params.push(param);
    });
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    await updateBulkItems({
      params,
      payload: {
        currentPage, sortParams, filterParam,
      },
    });
    setUpdateStatus(true);
    setDisableSwitch(true);
  };

  const updateItems = (record, info, updateType) => {
    const shopIds = (record && record.shops && record.shops.length !== 0) ? record.shops.map((option) => option.gid) : [];
    const tagIds = (record && record.tags && record.tags.length !== 0) ? record.tags.map((option) => option.gid) : [];
    const categoryIds = (record && record.categories && record.categories.length !== 0) ? record.categories.map((option) => option.gid) : [];
    // eslint-disable-next-line max-len
    const modifierIds = (record && record.modifierGroups && record.modifierGroups.length !== 0) ? record.modifierGroups.map((option) => option.gid) : [];
    const params = {
      name: record.name,
      alternateName: record.alternateName,
      sku: record.sku,
      price: (record && record.price) || 0,
      hidden: record.hidden,
      priceType: record.priceType,
      sortOrder: record.sortOrder,
      colorCode: record.colorCode,
      categoryGids: categoryIds || [],
      modifierGroupGids: modifierIds || [],
      available: updateType === 'available' ? info : record.available,
      onlineEnabled: updateType === 'onlineEnabled' ? info : record.onlineEnabled,
      cost: (record && record.cost) || 0,
      imageUrl: record.imageUrl,
      description: record.description,
      shopGids: shopIds || [],
      tagGids: tagIds || [],
    };
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    updateInventoryItems({
      gid: record.gid,
      params,
      payload: {
        currentPage, sortParams, filterParam,
      },
    });
    setUpdateStatus(true);
  };

  const getData = (query) => {
    fetchInventoryCategorieDetails(gid);
    fetchAllShop(gid);
    fetchItemByCategory(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'sortOrder';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    fetchInventorySortItems({ currentPage, sortParams, filterParams });
    fetchCategoryOperatingHours();
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    if (fetchFirstTime) {
      getData();

      setFetchFirstTime(false);
    }
  }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.INVENTORY_CATEGORIES);
    }
    if (current !== 'hoursOfOperation') {
      setEditHoursModal(false);
    }
  }, [current]);

  useEffect(() => {
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      showTotal: total => `Total ${total} items`,
    }));
  }, [categoryItemsList]);

  useEffect(() => {
    if (!validator && updateStatuses) {
      setSelectedRowKeys([]);
      setSelectedKeys([]);
      setDisableSwitch(false);
      getData();
    }
  }, [validator]);

  // /**
  //  * Get data when selected account changes
  //  */
  // useEffect(() => {
  //   getData(location.search);
  // }, [location, selectedAccount]);

  const isAvailableChange = (value) => {
    setEnableOperationHours(value);
  };

  useEffect(() => {
    setEnableOperationHours(!(inventoryCategorieDetails && inventoryCategorieDetails.enableHoursOfOperation === true));
    if (inventoryCategorieDetails && inventoryCategorieDetails.hoursOfOperations && inventoryCategorieDetails.hoursOfOperations.length !== 0) {
      setOperatingHours([...(inventoryCategorieDetails && inventoryCategorieDetails.hoursOfOperations)]);
    } else {
      const list = [
        {
          dayOfWeek: 'MONDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'TUESDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'WEDNESDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'THURSDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'FRIDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'SATURDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'SUNDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
      ];
      setOperatingHours([...list]);
    }
  }, [inventoryCategorieDetails]);

  const getOperatingTime = (time) => {
    console.log(time, 'time');
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 12) {
        return '12:00 PM';
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  const selectedRecordCategory = (record) => {
    // alert(PAGES.INVENTORY_CATEGORIES)
    // alert( record.gid)

    const itemGid = record.gid;
    history.push({
      pathname: `${PAGES.SHOPS}/${itemGid}`,
      state: location.search,
    });
  };

  const selectOperatingHours = (e, type, i, j) => {
    const list = [...operatingHours];
    const time = e.format('hh:mm a');
    const timeStamp = time.split(' ')[1];
    const actualTime = time.split(' ')[0];
    const a = actualTime.split(':');
    let minutes;
    if (a[0] === '12' && timeStamp === 'am') {
      minutes = +a[1];
    } else if (a[0] === '12' && timeStamp === 'pm' && type === 'From') {
      minutes = +a[0] * 60;
    } else {
      minutes = (+a[0] + (timeStamp === 'pm' ? 12 : 0)) * 60 + (+a[1]);
    }
    if (type === 'From') {
      list[i].operatingHours[j].openingTime = minutes;
    } else if (type === 'To') {
      list[i].operatingHours[j].closingTime = minutes;
    }
    setOperatingHours([...list]);
  };

  const updateStatus = (val, i, j) => {
    const list = [...operatingHours];
    list[i].operatingHours[j].open = val;
    setOperatingHours([...list]);
  };

  const getTimeVal = (id, index, type) => {
    if (type === 'From') {
      const fromTime = operatingHours[id].operatingHours[index].openingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          return moment(`${hours}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    } if (type === 'To') {
      const fromTime = operatingHours[id].operatingHours[index].closingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          const hr = 0;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    }
    return null;
  };


  const addTimeSlot = (dayIndex) => {
    const newTimeSlot = {
      openingTime: '',
      closingTime: '',
      open: false,
    };
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.push(newTimeSlot);
      return updatedData;
    });
  };

  const removeTimeSlot = (dayIndex, slotIndex) => {
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.splice(slotIndex, 1);
      return updatedData;
    });
  };

  useEffect(() => {
    if (redirect && !submitting) {
      setDeleteItemModal(false);
      history.push({
        pathname: PAGES.INVENTORY_CATEGORIES,
      });
    }
  }, [submitting]);

  useEffect(() => {
    if (redirect && !validator) {
      setAddItemsModal(false);
      setEditItemsModal(false);
      getData(location.search);
    }
  }, [validator]);

  const editSelectedItem = (customer: Object) => {
    const {
      name,
      onlineName,
      order,
      primaryColor,
      description,
      // eslint-disable-next-line no-shadow
      operatingHours,
    } = customer;
    // const updatedHours = (enableOperationHours === true) ? operatingHours.map(item => ({ ...item, open: false })) : operatingHours;
    const params = {
      name,
      onlineName,
      description,
      sortOrder: Number(order),
      colorCode: primaryColor,
      // enableHoursOfOperation: !enableOperationHours,
      operationalHoursMasterGid: operatingHours,
      // hoursOfOperations: updatedHours,
    };
    updateInventoryCategory({
      gid,
      params,
    });
    setRedirect(true);
  };

  // const editHoursSelectedItem = () => {
  //   // const updatedHours = (enableOperationHours === true) ? operatingHours.map(item => ({ ...item, open: false })) : operatingHours;
  //   const params = {
  //     name: inventoryCategorieDetails && inventoryCategorieDetails.name,
  //     hoursOfOperations: operatingHours,
  //   };
  //   updateInventoryCategory({
  //     gid,
  //     params,
  //   });
  //   setRedirect(true);
  // };

  const deleteSelectedItem = (customerGid: Object) => {
    deleteInventoryCategory({
      gid: customerGid,
    });
    setRedirect(true);
  };

  const selectedRecord = (record) => {
    const itemGid = record.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_ITEMS}/${itemGid}`,
      state: location.search,
    });
  };

  const addSelectedItem = (customer: Object) => {
    const {
      categories,
    } = customer;

    const listOfItems = (categoryItemsList && categoryItemsList.length !== 0) ? categoryItemsList.map((option) => option.gid) : null;
    const removedValues = (listOfItems && listOfItems.length !== 0) ? listOfItems.filter(item => !categories.includes(item)) : null;
    let params;
    if (removedValues && removedValues.length !== 0) {
      params = {
        ADDED: categories,
        REMOVED: removedValues,
      };
    } else {
      params = {
        ADDED: categories,
      };
    }
    addItemCategory({
      gid,
      params,
    });
    setRedirect(true);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    // {
    //   title: 'ONLINE NAME',
    //   dataIndex: 'onlineName',
    //   width: '10%',
    //   align: 'left',
    //   sorter: false,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   render: (onlineName) => (
    //     <Tooltip placement="top" title="">
    //       <span style={{ cursor: 'pointer' }}>
    //         {onlineName || <>&#8211;</>}
    //       </span>
    //     </Tooltip>
    //   ),
    // },
    {
      title: 'PRICE',
      dataIndex: 'price',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (price) => {
        const total = (price / 100).toFixed(2);
        return (
          <span> {`${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'COST',
      dataIndex: 'cost',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (cost) => {
        const total = (cost / 100).toFixed(2);
        return (
          <span> {`${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'SORT ORDER',
      dataIndex: 'sortOrder',
      width: '9%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (sortOrder) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {sortOrder || 0}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ON POS',
      dataIndex: 'hidden',
      width: '9%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (hidden) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {hidden ? 'No' : 'Yes'}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ONLINE',
      dataIndex: 'onlineEnabled',
      className: 'no-break-word',
      width: '10%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (onlineEnabled, record) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            updateItems(record, !onlineEnabled, 'onlineEnabled');
          }}
        >
          <Switch
            checked={onlineEnabled}
          />
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>AVAILABLE</div>
        </div>
      ),
      dataIndex: 'available',
      className: 'no-break-word',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (available, record) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            updateItems(record, !available, 'available');
          }}
        >
          <Switch
            checked={available}
          />
        </div>
      ),
    },
  ];


  const shopsColumns = [
    {
      title: 'SHOP NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <span>
          {
            name || <>&#8211;</>
          }
        </span>
      ),
    },
    {
      title: 'ORDER TYPE',
      dataIndex: 'orderMode',
      width: '15%',
      align: 'left',
      render: (orderMode) => (<span>{[orderMode]}</span>),
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: '15%',
      align: 'left',
      render: (address) => (<span>{`${address.street},${address.city},${address.state},${address.postalCode}`}</span>),
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        inventoryCategorieDetails.gid && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-3" fontWeight="600" fontSize="20px">
                    {(inventoryCategorieDetails && inventoryCategorieDetails.name) || <>&#8211;</>}
                  </SpText>
                </Col>
                {
                  kdsRole !== 'KDS-USER' && (
                    <Col>
                      <SpButton
                        shape="round"
                        className="mr-2"
                        type="danger"
                        onClick={() => setDeleteItemModal(true)}
                      >
                        Delete Category
                      </SpButton>
                      <SpButton
                        shape="round"
                        type="secondary"
                        onClick={() => setEditItemsModal(true)}
                      >
                        Edit Category
                      </SpButton>
                    </Col>
                  )
                }
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Sort Order</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryCategorieDetails && inventoryCategorieDetails.sortOrder) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Category Color</span>
                  </Col>
                  <Col span={6}>
                    {
                      inventoryCategorieDetails && inventoryCategorieDetails.colorCode ? (
                        <div className="d-flex">
                          <SpColor className="color" style={{ backgroundColor: `${inventoryCategorieDetails.colorCode}` }} />
                          <SpH5 className="ml-1">
                            {(inventoryCategorieDetails && inventoryCategorieDetails.colorCode) || <>&#8211;</>}
                          </SpH5>
                        </div>
                      ) : (
                        <SpH5 className="ml-1">&#8211;</SpH5>
                      )
                    }
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Description</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryCategorieDetails && inventoryCategorieDetails.description) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Operating Hours</span>
                  </Col>
                  <Col span={6}>
                    <SpH5 className="ml-1">
                      {(inventoryCategorieDetails
                        && inventoryCategorieDetails.operatingHoursMaster
                        && inventoryCategorieDetails.operatingHoursMaster.name) || <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <Row>
                <Col span={24} className="d-flex justify-content-end">
                  <SpH5 color="#7f7f7f">{inventoryCategorieDetails.gid}</SpH5>
                </Col>
              </Row>
            </Card>
            <Card className="pl-2">
              <Menu
                onClick={e => { setCurrent(e.key); }}
                selectedKeys={[current]}
                mode="horizontal"
                style={{ fontSize: '20px' }}
              >
                <Menu.Item key="itemsTab">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'itemsTab'}
                  >
                    Items
                  </SpTab>
                </Menu.Item>
                {/* <Menu.Item key="hoursOfOperation">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'hoursOfOperation'}
                  >
                    Operating Hours
                  </SpTab>
                </Menu.Item> */}
                <Menu.Item key="shops">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'shops'}
                  >
                    SHOPS
                  </SpTab>
                </Menu.Item>
              </Menu>
              {deleteItemModal && (
                <DeleteCategory
                  visible={deleteItemModal}
                  submitting={submitting}
                  inventoryItemDetails={inventoryCategorieDetails}
                  selectedAccount={selectedAccount}
                  close={() => setDeleteItemModal(false)}
                  submit={deleteSelectedItem}
                />
              )}
              {/* UPDATE ITEMS MODAL */}
              {editItemsModal && (
                <EditItems
                  menuData={menuData}
                  visible={editItemsModal}
                  submitting={submitting}
                  selectedItemList={inventoryCategorieDetails}
                  selectedAccount={selectedAccount}
                  close={() => setEditItemsModal(false)}
                  submit={editSelectedItem}
                />
              )}
              {
                current === 'itemsTab' ? (
                  <>
                    <Row type="flex" justify="space-between" className="my-2">
                      <Col span={10}>
                        <SpText className="text-uppercase mb-3" fontSize="20px">ITEMS</SpText>
                      </Col>
                      {
                        kdsRole !== 'KDS-USER' && (
                          <>
                            {
                              (categoryItemsList && categoryItemsList.length !== 0) && (
                                <>
                                  <Col span={9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <SpButton
                                      type="secondary"
                                      shape="round"
                                      ghost
                                      onClick={() => setAddItemsModal(true)}
                                    >
                                      {'\u002B'}&nbsp;Add or Remove Items
                                    </SpButton>
                                    {
                                      (selectedRowKeys && selectedRowKeys.length) > 0 ? (
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setDisableSwitch(false);
                                          }}
                                          className="ml-3"
                                        >
                                          <div className="mt-2">
                                            <Switch
                                              checked={availableSpace}
                                              disabled={disableSwitch}
                                              onChange={handleAvailableSpaceToggle}
                                            />
                                            <span className="ml-2">
                                              {availableSpace ? 'Make UnAvailable' : 'Make Available'}
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="ml-3">
                                          <div className="mt-2">
                                            <Switch
                                              checked={availableSpace}
                                              disabled
                                            />
                                            <span className="ml-2">
                                              {availableSpace ? 'Make UnAvailable' : 'Make Available'}
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    }
                                  </Col>
                                </>
                              )
                            }
                          </>
                        )
                      }
                    </Row>
                    {/* DELETE ITEM MODAL */}
                    {/* deleteItemModal && (
                      <DeleteCategory
                        visible={deleteItemModal}
                        submitting={submitting}
                        inventoryItemDetails={inventoryCategorieDetails}
                        selectedAccount={selectedAccount}
                        close={() => setDeleteItemModal(false)}
                        submit={deleteSelectedItem}
                      />
                    ) */}
                    {/* UPDATE ITEMS MODAL */}
                    {/* editItemsModal && (
                      <EditItems
                        menuData={menuData}
                        visible={editItemsModal}
                        submitting={submitting}
                        selectedItemList={inventoryCategorieDetails}
                        selectedAccount={selectedAccount}
                        close={() => setEditItemsModal(false)}
                        submit={editSelectedItem}
                      />
                    ) */}
                    {/* Add ITEMS MODAL */}
                    {addItemsModal && (
                      <AddRemoveItems
                        visible={addItemsModal}
                        submitting={submitting}
                        selectedItemList={inventoryCategorieDetails}
                        itemsList={inventoryItems}
                        printerLabelItemsList={categoryItemsList}
                        selectedAccount={selectedAccount}
                        close={() => setAddItemsModal(false)}
                        submit={addSelectedItem}
                      />
                    )}
                    {
                      categoryItemsList && categoryItemsList.length !== 0 ? (
                        <>
                          <Table
                            rowClassName={() => 'ant-table-clickable-row'}
                            columns={columns}
                            onRow={
                              (record) => ({
                                onClick: () => {
                                  selectedRecord(record);
                                },
                              })
                            }
                            rowKey={(record) => record.gid}
                            className="custom-table"
                            dataSource={categoryItemsList}
                            pagination={pagination}
                            rowSelection={rowSelection}
                          />
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              border: '1px solid #CBCBCB',
                              minHeight: '253px',
                              width: '1014 px',
                              marginTop: '10px',
                              marginBottom: '25px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <div>
                              <SpH5 color="#333333">
                                No items assigned yet
                              </SpH5>
                              <div
                                style={{
                                  marginTop: '15px',
                                }}
                              >
                                <SpButton
                                  type="secondary"
                                  onClick={() => setAddItemsModal(true)}
                                >
                                  {'\u002B'}&nbsp;Add Items
                                </SpButton>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </>
                ) : current === 'shops' ? (
                  <>
                    {console.log(getshopList)}
                    <Table
                      rowClassName={() => 'ant-table-clickable-row'}
                      columns={shopsColumns}
                      onRow={
                        (record) => ({
                          onClick: () => {
                            selectedRecordCategory(record);
                          },
                        })
                      }
                      rowKey={(record) => record.gid}
                      className="custom-table"
                      dataSource={getshopList.entity}
                      pagination={pagination}
                    />
                  </>

                ) : (
                  editHoursModal ? (
                    <>
                      <Row className="mt-4">
                        <Col>
                          <Row type="flex" justify="start">
                            <Col span={5}>
                              <SpText fontSize="20px">Open 24/7</SpText><br />
                              <SpText fontSize="12px">
                                You can use the Open 24/7 toggle to select if your shop is open 24 hours a day, 7 days a week.
                              </SpText>
                            </Col>
                            <Col className="mt-4 ml-4">
                              <Switch
                                className="mr-2"
                                defaultChecked={!inventoryCategorieDetails.enableHoursOfOperation || enableOperationHours}
                                onChange={isAvailableChange}
                              /> {enableOperationHours ? 'Enabled' : 'Disabled'}

                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {
                        !enableOperationHours && (
                          <>
                            <Row className="my-2 mt-4 mb-3" type="flex" justify="start">
                              <Col>
                                <SpText className="text-uppercase" fontSize="20px">
                                  OPERATING HOURS
                                </SpText>
                              </Col>
                            </Row>
                            <Row className="my-2 mb-3" type="flex" justify="start">
                              <Col span={3}>
                                <span className="text-muted">Days</span>
                              </Col>
                              <Col span={3}>
                                <span className="text-muted">From</span>
                              </Col>
                              <Col span={3}>
                                <span className="text-muted">To</span>
                              </Col>
                            </Row>
                            {operatingHours.map((day, dayIndex) => (
                              <div key={dayIndex}>
                                {day.operatingHours.map((timeSlot, slotIndex) => (
                                  <div key={slotIndex}>
                                    <Row className="my-2 mb-3" type="flex" justify="start">
                                      <Col span={3}>
                                        {slotIndex === 0 && <span>{day.dayOfWeek}</span>}
                                      </Col>
                                      <Col span={3}>
                                        <TimePicker
                                          allowClear={false}
                                          use12Hours
                                          format="h:mm a"
                                          defaultValue={getTimeVal(dayIndex, slotIndex, 'From')}
                                          onChange={(e) => selectOperatingHours(e, 'From', dayIndex, slotIndex)}
                                        />
                                      </Col>
                                      <Col span={3}>
                                        <TimePicker
                                          allowClear={false}
                                          use12Hours
                                          format="h:mm a"
                                          defaultValue={getTimeVal(dayIndex, slotIndex, 'To')}
                                          onChange={(e) => selectOperatingHours(e, 'To', dayIndex, slotIndex)}
                                        />
                                      </Col>
                                      <Col span={3}>
                                        <Switch
                                          className="mx-3"
                                          defaultChecked={timeSlot.open}
                                          onChange={val => updateStatus(val, dayIndex, slotIndex)}
                                        /> Open
                                      </Col>
                                      <Col span={1}>
                                        {slotIndex === 0 ? (
                                          <Icon
                                            type="plus-circle"
                                            style={{
                                              cursor: 'pointer', color: '#3E8BFF',
                                            }}
                                            onClick={() => addTimeSlot(dayIndex)}
                                          />
                                        )
                                          : (
                                            <Icon
                                              type="minus-circle"
                                              style={{
                                                cursor: 'pointer', color: '#FF4B51',
                                              }}
                                              onClick={() => removeTimeSlot(dayIndex, slotIndex)}
                                            />
                                          )}
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </>
                        )
                      }
                      <Row>
                        <Col span={24} className="d-flex justify-content-end">
                          <SpButton className="mr-3" onClick={() => setEditHoursModal(false)} type="secondary">
                            Cancel
                          </SpButton>
                          <SpButton onClick={editSelectedItem}>Save</SpButton>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row type="flex" justify="space-between" className="my-2 mt-4">
                        <Col className="d-flex align-items-center" />
                        <Col>
                          {
                            kdsRole !== 'KDS-USER' && (
                              <SpButton
                                shape="round"
                                type="secondary"
                                onClick={() => setEditHoursModal(true)}
                              >
                                Edit Operating Hours
                              </SpButton>
                            )
                          }
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Row type="flex" justify="start">
                            <Col span={5}>
                              <SpText fontSize="20px">Open 24/7</SpText><br />
                              <SpText fontSize="12px">
                                You can use the Open 24/7 toggle to select if your shop is open 24 hours a day, 7 days a week.
                              </SpText>
                            </Col>
                            <Col className="mt-4 ml-4">
                              <Switch
                                className="mr-2"
                                checked={!inventoryCategorieDetails.enableHoursOfOperation || enableOperationHours}
                                disabled
                              /> {!inventoryCategorieDetails.enableHoursOfOperation ? 'Enabled' : 'Disabled'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {
                        // eslint-disable-next-line
                        inventoryCategorieDetails && inventoryCategorieDetails.hoursOfOperations && inventoryCategorieDetails.hoursOfOperations.length !== 0 ? (
                          <>
                            {
                              inventoryCategorieDetails.enableHoursOfOperation && (
                                <>
                                  <Row className="my-2 mb-3" type="flex" justify="start">
                                    <Col>
                                      <SpText className="text-uppercase" fontSize="20px">
                                        OPERATING HOURS
                                      </SpText>
                                    </Col>
                                  </Row>
                                  <Row className="my-2 mb-3" type="flex" justify="start">
                                    <Col span={3}>
                                      <span className="text-muted">Days</span>
                                    </Col>
                                    <Col span={3}>
                                      <span className="text-muted">From</span>
                                    </Col>
                                    <Col span={3}>
                                      <span className="text-muted">To</span>
                                    </Col>
                                  </Row>
                                  {inventoryCategorieDetails.hoursOfOperations.map((day, dayIndex) => (
                                    <div key={dayIndex}>
                                      {day.operatingHours.map((timeSlot, slotIndex) => (
                                        <div key={slotIndex}>
                                          <Row className="my-2 mb-3" type="flex" justify="start">
                                            <Col span={3}>
                                              {slotIndex === 0 && <span>{day.dayOfWeek}</span>}
                                            </Col>
                                            <Col span={3}>
                                              {(timeSlot.openingTime) ? getOperatingTime(timeSlot.openingTime) : <>12:00 AM</>}
                                            </Col>
                                            <Col span={3}>
                                              {(timeSlot.closingTime) ? getOperatingTime(timeSlot.closingTime) : <>12:00 AM</>}
                                            </Col>
                                            <Col span={3}>
                                              <Switch
                                                className="mx-3"
                                                disabled
                                                defaultChecked={timeSlot.open}
                                              /> Open
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </>
                              )
                            }

                          </>
                        ) : (
                          <></>
                        )
                      }
                    </>
                  )
                )
              }
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  inventoryCategorieDetails: state.inventoryCategorieDetails,
  inventoryItems: state.inventoryItems.sortItemsList.content,
  categoryItemsList: state.inventoryCategorieDetails.categoryItemsList,
  totalElements: state.inventoryCategorieDetails.categoryItemsList ? state.inventoryCategorieDetails.categoryItemsList.length : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  profile: state.subscription.profile,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  menuData: state.inventoryCategories.operatingHoursCategory,
  getshopList: state.inventoryCategorieDetails.shopList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllShop: gid => dispatch(inventoryCategorieDetailsActions.fetchAllShop({
    payload: gid,
  })),
  fetchInventoryCategorieDetails: gid => dispatch(inventoryCategorieDetailsActions.fetchInventoryCategorieDetails({
    payload: gid,
  })),
  fetchCategoryOperatingHours: param => dispatch(inventoryCategoriesActions.fetchCategoryOperatingHours({
    payload: param,
  })),
  fetchItemByCategory: gid => dispatch(inventoryCategorieDetailsActions.fetchItemByCategory({
    payload: gid,
  })),
  addItemCategory: gid => dispatch(inventoryCategorieDetailsActions.addItemCategory({
    payload: gid,
  })),
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
  updateInventoryCategory: param => dispatch(inventoryCategoriesActions.updateInventoryCategory({
    payload: param,
  })),
  deleteInventoryCategory: param => dispatch(inventoryCategoriesActions.deleteInventoryCategory({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  updateInventoryItems: param => dispatch(inventoryItemsActions.updateInventoryItems({
    payload: param,
  })),
  updateBulkItems: param => dispatch(inventoryItemsActions.updateBulkItems({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CategorieDetails);
