// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getPaymentPageBackground from 'utils/getPaymentPageBackground';
import getPaymentPageStatus from 'utils/getPaymentPageStatus';
import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';
import {
  Card,
  Table,
  Row,
  Col,
  Select,
  Tooltip,
  Menu,
  Icon,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
  SpTab,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  donationPageDetailsActions,
  accountSlugActions,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  PAYMENTPAGE_STATUSES,
} from 'appconstants';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import RBAC from 'components/rbac';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import Clipboard from 'components/NavIcons/copyclipboard';
import CONSTANTS from 'store/constants';
import ResendMail from 'imgs/ResendMail.svg';

import NotifyDonationPage from './components/NotifyPaymentPage';
import SoldOutDonationPage from './components/soldOutPaymentPage';
import DeleteDonationPage from './components/deletePaymentPage';
import OfflineDonation from './components/offlineDonation';

const { Option } = Select;

type Props = {
  donationPageDetails: Object,
  donationPageDetailsStatus: Boolean,
  fetchDonationPageDetails: Function,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  selectedAccount: Object,
  setNotification: Function,
  linkAccountSlug: Function,
  unLinkAccountSlug: Function,
  fetchAccountSlug: Function,
  accountSlug: Array<Object>,
  fetchDonationPageSlug: Function,
  donationSlug: Array,
  submitting: Boolean,
  validator: Boolean,
  notifyDonationPage: Function,
  soldOutDonationPage: Function,
  resumeSaleDonationPage: Function,
  DeactiveDonationPage: Function,
  offlineDonation: Function,
  fetchDonationPagesEntries: Function,
  donationPagesEntries: Array<Object>,
  entriesTotal: Number,
  fetchDonations: Function,
  payments: Array<Object>,
  paymentsTotal: Number,
  loading: Boolean,
}

const DonationPagesDetails = (props: Props) => {
  const location = useLocation();
  const {
    history,
    donationPageDetails,
    donationPageDetailsStatus,
    fetchDonationPageDetails,
    selectedAccount,
    setNotification,
    linkAccountSlug,
    unLinkAccountSlug,
    fetchAccountSlug,
    accountSlug,
    fetchDonationPageSlug,
    donationSlug,
    submitting,
    validator,
    notifyDonationPage,
    soldOutDonationPage,
    resumeSaleDonationPage,
    DeactiveDonationPage,
    offlineDonation,
    fetchDonationPagesEntries,
    donationPagesEntries,
    entriesTotal,
    fetchDonations,
    payments,
    paymentsTotal,
    loading,
  } = props;
  const { gid } = props.match.params;
  const path = props.match.url;
  const env = process.env.REACT_APP_ENV;
  const currencyType = selectedAccount?.currency;
  const { prefix } = selectedAccount?.currency;
  const { id } = selectedAccount?.currency;
  const [enableUnlink, setEnableUnlink] = useState(false);
  const [slugLink, setSlugLink] = useState();
  const [slugGid, setSlugGid] = useState();
  const [redirect, setRedirect] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [pagination, setPagination] = useState({});
  const [pagination1, setPagination1] = useState({});
  const [current, setCurrent] = useState('loadBalance');
  const [notifyDonationPageModal, setNotifyDonationPageModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [soldOutDonationPageModal, setSoldOutDonationPageModal] = useState(false);
  const [deleteDonationPageModal, setDeleteDonationPageModal] = useState(false);
  const [offlineModal, setOfflineModal] = useState(false);

  const getData = (query) => {
    let sortBy;
    const parsed = queryString.parse(query);
    if (current === 'loadBalance') {
      sortBy = 'paymentDate';
    } else {
      sortBy = parsed.sortBy || 'createdAt';
    }
    const currentPage = parsed.pageNum || 1;
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const params = `&sortBy=${parsed.sortBy || 'createdAt'}&direction=${direction}`;
    const size = 1000;
    if (!isSearch) {
      fetchDonationPageSlug(gid);
    }
    if (current === 'loadBalance') {
      fetchDonationPagesEntries({ currentPage, sortParams, gid });
    }
    if (current === 'downloadingReport') {
      fetchDonations({ currentPage, sortParams, gid });
    }
    fetchAccountSlug({ currentPage, params, size });
  };

  useEffect(() => {
    fetchDonationPageDetails(gid);
  }, [gid]);

  useEffect(() => {
    getData(location.search);
    if (selectedAccount?.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [location, selectedAccount]);

  useEffect(() => {
    if (donationSlug && donationSlug.length > 0) {
      setEnableUnlink(true);
      setSlugLink(donationSlug[0].slugName);
      setSlugGid(donationSlug[0].gid);
    } else {
      setEnableUnlink(false);
      setSlugLink('');
      setSlugGid('');
    }
  }, [donationSlug]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.DONATIONPAGES);
    } else if (!validator) {
      setNotifyDonationPageModal(false);
      // setCheqModal(false);
      // setDeleteOfflineModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (!submitting) {
      // setCheqModal(false);
      // setDeleteOfflineModal(false);
    }
  }, [submitting]);

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGES.SUCCESS,
    });
  };

  const notifyDonation = (customer: Object) => {
    const {
      emailId,
      phoneNumber,
      countryCode,
      subject,
      message,
      type,
    } = customer;
    const payload = {
      phoneNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
      emailId,
      subject,
      message,
      type,
    };
    console.log(payload);
    // notifyDonationPage({ gid, payload });
    setRedirect(true);
  };

  const soldoutSelectedDonationPage = async (data: string) => {
    const {
      status,
    } = data;
    if (status === 'SOLD_OUT') {
      soldOutDonationPage(gid);
    } else {
      resumeSaleDonationPage(gid);
    }
    setRedirect(true);
  };

  const deleteSelectedDonationPage = async (paymentPageGid: string) => {
    DeactiveDonationPage(paymentPageGid);
    setRedirect(true);
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: paymentsTotal,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination1(existingElements => ({
      ...existingElements,
      total: entriesTotal,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [donationPagesEntries]);

  const handleTableChange = (page, sorter, filters) => {
    setIsSearch(true);
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.DONATIONPAGES}/${gid}`,
      search: `?status.IN=DOWNLOADREPORT&pageNum=${tempPage}${tempParams}`,
    });
  };

  const handleTableChange1 = (page, sorter, filters) => {
    setIsSearch(true);
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.DONATIONPAGES}/${gid}`,
      search: `?status.IN=PAYMENTS&pageNum=${tempPage}${tempParams}`,
    });
  };

  const linkSlug = () => {
    const params = {
      type: 'DONATION_PAGE',
      spObjectGid: gid,
      forceSet: false,
    };
    linkAccountSlug({ slugGid, params });
    setRedirect(true);
  };

  const unlinkSlug = async () => {
    unLinkAccountSlug(slugGid);
    setRedirect(true);
  };

  const updateDonationPage = () => {
    const paymentPageGid = gid;
    history.push(`${PAGES.UPDATE_DONATION_PAGE}/${paymentPageGid}`);
  };

  const clonePaymentPage = () => {
    const paymentPageGid = gid;
    history.push(`${PAGES.ADDDONATIONPAGE}/${paymentPageGid}`);
  };

  const selectedNotify = (paymentDetails: object) => {
    const isPaymentPage = paymentDetails?.gid;
    notifyDonationPage(isPaymentPage);
  };

  const copyReceiptCode = (record) => {
    let selectedPath;
    if (env === 'Staging') {
      selectedPath = 'https://staging.swirepay.com';
    }
    if (env === 'Production') {
      selectedPath = 'https://dashboard.swirepay.com';
    }
    const el = `${selectedPath}${PAGES.PAGE_RECIPT}/${record}`;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGES.SUCCESS,
    });
  };

  const downloadReports = async (record) => {
    const idx = record?.gid;
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.GET_REPORTS}/${idx}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const downloadReport = async () => {
    try {
      const { data: { entity } } = await axiosAuthInstance
        .post(`${API_END_POINTS.DONATIONPAGES_API}/${gid}/generate-report`, { type: 'DONATION_PAGE' });
      console.log(entity, 'entity');
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: ((e.response && e.response.data && e.response.data.messageCode) === '5002')
          ? MESSAGES.REPORT.NODATA : MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const OfflinePayment = (paymentDatils: Object) => {
    const params = paymentDatils;
    const paymentLinkGid = donationPageDetails.donationPageLinks[0].gid;
    offlineDonation({
      gid: paymentLinkGid,
      params,
    });
    setRedirect(true);
  };

  const reportColumns = [
    {
      title: 'REPORT TYPE',
      dataIndex: 'type',
      width: '25%',
      align: 'left',
    },
    {
      title: 'REQUESTED ON',
      dataIndex: 'generatedAt',
      width: '25%',
      align: 'left',
      render: (generatedAt) => {
        if (generatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(generatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
    },
    {
      title: 'DOWNLOAD',
      dataIndex: 'actions',
      width: '25%',
      align: 'center',
      render: (text, record) => (
        <>
          {
            record.status === 'GENERATED' ? (
              <Icon
                type="download"
                style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
                onClick={() => downloadReports(record)}
              />
            ) : (
              <span> {record.status ? record.status.replace('_', ' ') : '-'}</span>
            )
          }
        </>
      ),
    },
  ];

  const donationColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '8%',
      align: 'left',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {record?.customer?.name}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '10%',
      align: 'left',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {record?.customer?.email}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '8%',
      align: 'left',
      render: (text, record) => {
        const createdAt = record?.createdAt;
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '8%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount) => {
        const total = (amount / 100).toFixed(2);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'PAYMENT MODE',
      dataIndex: 'donationType',
      width: '8%',
      align: 'left',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {record?.donationType}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DONATION CYCLE',
      dataIndex: 'donationType',
      width: '10%',
      align: 'left',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {record?.interval}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'NEXT DONATION ON',
      dataIndex: 'createdAt',
      width: '10%',
      align: 'left',
      render: (text, record) => {
        const createdAt = record?.nextPaymentDate;
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '8%',
      align: 'left',
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground(record?.status) }}>
              {getPaymentStatus(record?.status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'RECEIPT',
      dataIndex: 'receiptNumber',
      width: '10%',
      align: 'left',
      render: (receiptNumber) => {
        if (receiptNumber) {
          return (
            <span>
              <SpButton
                type="secondary"
                borderRadius="50px"
                style={{ cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); copyReceiptCode(receiptNumber); }}
              >
                <Clipboard />
              </SpButton>
            </span>
          );
        }
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              disabled
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '10%',
      align: 'left',
      render: (text, record) => (
        <>
          <Tooltip placement="top" title="Notify">
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); selectedNotify(record); }}
            >
              <img
                src={ResendMail}
                alt="notify"
                style={{
                  height: '50px',
                  marginTop: '5px',
                }}
              />
            </span>
          </Tooltip>
        </>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        donationPageDetails.gid && donationPageDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {donationPageDetails.title
                      ? `${donationPageDetails.title}`
                      : <>&#8211;</>}
                  </SpText>
                  {donationPageDetails.status
                    ? (
                      <SpStatusTag style={{ backgroundColor: getPaymentPageBackground(donationPageDetails.status) }}>
                        {getPaymentPageStatus(donationPageDetails.status)}
                      </SpStatusTag>
                    )
                    : <>&#8211;</>}
                </Col>
              </Row>
              <Row type="flex" justify="end" className="my-3 mb-3">
                <Col className="d-flex justify-content-end">
                  {/* <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <SpButton
                      className="mr-2"
                      type="secondary"
                      shape="round"
                      onClick={() => setOfflineModal(true)}
                      ghost
                    >
                      Offline Payment
                    </SpButton>
                  </RBAC> */}
                  {
                    donationPageDetails?.supportOfflinePayment && donationPageDetails.status !== 'DEACTIVATED' && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          className="mr-2"
                          type="secondary"
                          shape="round"
                          onClick={() => setOfflineModal(true)}
                          ghost
                        >
                          Offline Payment
                        </SpButton>
                      </RBAC>
                    )
                  }
                  <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <SpButton
                      className="mr-2"
                      type="secondary"
                      shape="round"
                      onClick={() => clonePaymentPage()}
                      ghost
                    >
                      Clone Donation Page
                    </SpButton>
                  </RBAC>
                  {
                    (donationPageDetails.status === PAYMENTPAGE_STATUSES.ACTIVATED) && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          onClick={() => updateDonationPage()}
                          ghost
                        >
                          Edit
                        </SpButton>
                      </RBAC>
                    )
                  }

                  {/* <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <SpButton
                      className="ml-2"
                      type="secondary"
                      shape="round"
                      onClick={() => setNotifyDonationPageModal(true)}
                      ghost
                    >
                      Notify
                    </SpButton>
                  </RBAC> */}
                  {/* {
                    (donationPageDetails.status !== PAYMENTPAGE_STATUSES.ACTIVEDEACTIVATED)
                    && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          className="ml-2"
                          type="secondary"
                          shape="round"
                          onClick={() => setSoldOutDonationPageModal(true)}
                          ghost
                          disabled
                        >
                          {
                            donationPageDetails.status === PAYMENTPAGE_STATUSES.ACTIVATED ? 'Sold Out' : 'Resume Sales'
                          }
                        </SpButton>
                      </RBAC>
                    )
                  } */}
                  {
                    (donationPageDetails.status !== PAYMENTPAGE_STATUSES.ACTIVECANCEL)
                    && (donationPageDetails.status !== PAYMENTPAGE_STATUSES.ACTIVEDEACTIVATED) && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          className="ml-2"
                          type="danger"
                          shape="round"
                          onClick={() => setDeleteDonationPageModal(true)}
                        >
                          Deactivate
                        </SpButton>
                      </RBAC>
                    )
                  }
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Created On</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {donationPageDetails.createdAt
                        ? (
                          moment
                            .utc(donationPageDetails.createdAt)
                            .tz(selectedAccount?.timezone)
                            .format('MMM DD, YYYY')
                        )
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Link</span>
                  </Col>
                  {
                    (donationPageDetails.donationPageLinks) && (donationPageDetails.donationPageLinks.length > 0) ? (
                      <Col span={12}>
                        {donationPageDetails.donationPageLinks[0].link && donationPageDetails.status !== PAYMENTPAGE_STATUSES.ACTIVEDEACTIVATED
                          && donationPageDetails.status !== PAYMENTPAGE_STATUSES.DELETED
                          ? (
                            <SpH5>{donationPageDetails.donationPageLinks[0].link}
                              <SpButton
                                type="secondary"
                                className={donationPageDetails.donationPageLinks[0].link ? 'ml-2' : ''}
                                borderRadius="50px"
                                onClick={() => { copyCode(donationPageDetails.donationPageLinks[0].link); }}
                              >
                                <Clipboard />
                              </SpButton>
                            </SpH5>
                          )
                          : <>&#8211;</>}
                      </Col>
                    ) : (<Col><>&#8211;</></Col>)
                  }
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Total Online Payments</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>{donationPageDetails.totalOnlineDonations >= 0
                      ? `${donationPageDetails.totalOnlineDonations}`
                      : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={24}>
                    <Col span={4}>
                      <span>Total Online Revenue</span>
                    </Col>
                    <Col span={10}>
                      <SpH5>
                        {`${prefix}
                      ${formatNumber((donationPageDetails?.totalOnlineRevenue / 100).toFixed(2).replace(/-/g, ''), currencyType)}`}
                      </SpH5>
                    </Col>
                  </Col>
                  <Col span={24} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{donationPageDetails?.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                {
                  (accountSlug.length > 0) && (
                    <>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={6}>
                          <SpText className="mr-3" fontSize="14px" fontWeight="600">
                            Link Account Slug
                          </SpText>
                          <Select
                            className="mt-2 w-100"
                            showSearch
                            // disabled
                            disabled={enableUnlink}
                            value={slugLink || 'Select slug'}
                            onChange={(value) => {
                              setSlugGid(value[0]);
                              setSlugLink(value[1]);
                            }}
                          >
                            {
                              accountSlug.map(slug => (
                                slug.status === 'UNLINKED' && (
                                  <Option
                                    key={1}
                                    value={[slug.gid, slug.slugName]}
                                  >
                                    {slug.slugName}
                                  </Option>
                                )
                              ))
                            }
                          </Select>
                        </Col>
                      </Row>
                      <Row className="mt-4" type="flex" justify="start" align="middle">
                        <Col span={3}>
                          {
                            enableUnlink ? (
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={unlinkSlug}
                              >
                                Unlink
                              </SpButton>
                            ) : slugLink ? (
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={linkSlug}
                              >
                                Link
                              </SpButton>
                            ) : (
                              <SpButton
                                type="primary"
                                shape="round"
                                disabled
                              >
                                Link
                              </SpButton>
                            )
                          }

                        </Col>
                      </Row>
                    </>
                  )
                }
              </SpForm>
              {/* NOTIFY PAYMENTPAGE MODAL */}
              {notifyDonationPageModal && (
                <NotifyDonationPage
                  visible={notifyDonationPageModal}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  selectedAccount={selectedAccount}
                  id={id}
                  donationPageDetails={donationPageDetails}
                  close={() => setNotifyDonationPageModal(false)}
                  submit={notifyDonation}
                />
              )}
              {/* DELETE PAGE MODAL */}
              {deleteDonationPageModal && (
                <DeleteDonationPage
                  visible={deleteDonationPageModal}
                  donationPageDetails={donationPageDetails}
                  submitting={submitting}
                  close={() => setDeleteDonationPageModal(false)}
                  submit={deleteSelectedDonationPage}
                />
              )}
              {/* SOLD OUT PAGE MODAL */}
              {soldOutDonationPageModal && (
                <SoldOutDonationPage
                  visible={soldOutDonationPageModal}
                  donationPageDetails={donationPageDetails}
                  status={donationPageDetails?.status}
                  submitting={submitting}
                  close={() => setSoldOutDonationPageModal(false)}
                  submit={soldoutSelectedDonationPage}
                />
              )}
              {/* OFFLINE DONATION MODAL */}
              {offlineModal && (
                <OfflineDonation
                  visible={offlineModal}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  selectedAccount={selectedAccount}
                  id={id}
                  paymentPagesDetails={donationPageDetails}
                  offlineNameDetails={donationPageDetails}
                  close={() => setOfflineModal(false)}
                  submit={OfflinePayment}
                />
              )}
            </Card>
            <Card className="pl-2">
              <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
                <Menu.Item key="loadBalance">
                  <SpTab
                    to={`${path}?status.IN=PAYMENTS`}
                    selected={current === 'loadBalance'}
                  >
                    DONATIONS
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="downloadingReport">
                  <SpTab
                    to={`${path}?status.IN=DOWNLOADREPORT`}
                    selected={current === 'downloadingReport'}
                  >
                    DOWNLOAD HISTORY
                  </SpTab>
                </Menu.Item>
              </Menu>
              {
                current === 'loadBalance' ? (
                  <>
                    <Row className="my-2">
                      <Col span={24} className="d-flex justify-content-end">
                        <SpButton
                          type="secondary"
                          shape="round"
                          onClick={() => downloadReport()}
                          ghost
                        >
                          Download Report
                        </SpButton>
                      </Col>
                    </Row>
                    <Table
                      columns={donationColumns}
                      rowKey={(record) => record.gid}
                      // onRow={(record) => ({
                      //   onClick: () => {
                      //     selectedRecordPaymentPageEntries(record);
                      //   },
                      // })}
                      dataSource={donationPagesEntries}
                      pagination={pagination1}
                      onChange={handleTableChange1}
                    />
                  </>
                ) : current === 'downloadingReport' ? (
                  <>
                    <Row type="flex" justify="space-between" className="my-2">
                      <Col span={24} className="d-flex justify-content-end">
                              &nbsp;
                      </Col>
                    </Row>
                    <Table
                      columns={reportColumns}
                      rowKey={(record) => record.gid}
                      dataSource={payments}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />
                  </>
                ) : (
                  <></>
                )
              }
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  donationPageDetails: state.donationpageDetails,
  donationPageDetailsStatus: state.donationpageDetails.donationPageDetailsStatus,
  selectedAccount: state.account.selectedAccount,
  accountSlug: state.accountSlug.content,
  donationSlug: state.donationpageDetails.donationSlug,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  loading: state.loading.loading,
  donationPagesEntries: state.donationpageDetails.donationPagesEntries?.content ? state.donationpageDetails.donationPagesEntries?.content : [],
  entriesTotal: state.donationpageDetails.donationPagesEntries?.totalElements ? state.donationpageDetails.donationPagesEntries?.totalElements : 0,
  payments: state.donationpageDetails.payments?.content ? state.donationpageDetails.payments?.content : [],
  paymentsTotal: state.donationpageDetails.payments?.totalElements ? state.donationpageDetails.payments?.totalElements : 0,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDonationPageDetails: param => dispatch(donationPageDetailsActions.fetchDonationPageDetails({
    payload: param,
  })),
  fetchDonationPageSlug: param => dispatch(donationPageDetailsActions.fetchDonationPageSlug({
    payload: param,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  linkAccountSlug: param => dispatch(accountSlugActions.linkAccountSlug({
    payload: param,
  })),
  unLinkAccountSlug: param => dispatch(accountSlugActions.unLinkAccountSlug({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  notifyDonationPage: param => dispatch(donationPageDetailsActions.notifyDonationPage({
    payload: param,
  })),
  soldOutDonationPage: param => dispatch(donationPageDetailsActions.soldOutDonationPage({
    payload: param,
  })),
  resumeSaleDonationPage: param => dispatch(donationPageDetailsActions.resumeSaleDonationPage({
    payload: param,
  })),
  DeactiveDonationPage: param => dispatch(donationPageDetailsActions.DeactiveDonationPage({
    payload: param,
  })),
  offlineDonation: param => dispatch(donationPageDetailsActions.offlineDonation({
    payload: param,
  })),
  fetchDonationPagesEntries: param => dispatch(donationPageDetailsActions.fetchDonationPagesEntries({
    payload: param,
  })),
  fetchDonations: param => dispatch(donationPageDetailsActions.fetchDonations({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(DonationPagesDetails);
