export default status => {
  if (status === 'PAID' || status === 'PARTIALLY_PAID') {
    return '#ececff';
  } if (status === 'UN_PAID') {
    return '#ffe0b1';
  } if (status === 'REFUND_PROCESSING') {
    return '#e2e2e2';
  } if (status === 'PARTIALLY_REFUNDED') {
    return '#e2e2e2';
  } if (status === 'REFUNDED') {
    return '#e2e2e2';
  } if (status === 'VOID') {
    return '#ffe0b1';
  } return '#e2e2e2';
};
