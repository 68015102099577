// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

const { TextArea } = Input;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  selectedTransfer: Object,
  submitting: boolean,
};

const CancelTransfer = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedTransfer,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const gid = selectedTransfer && selectedTransfer.gid;
  const [remarks, setRemarks] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());


  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      gid,
      remarks,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Submit</SpButton>,
      ]}
      title="Cancel Transfer"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div>
          <label><SpH5>Remarks</SpH5></label>
          <TextArea
            rows={5}
            placeholder="Remarks"
            value={remarks}
            maxlength="255"
            onChange={(e) => setRemarks(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('remarks', remarks, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(CancelTransfer);
