import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  VENDORINVOICES,
  NOTIFICATION,
} from '../actionTypes';

const fetchVendorInvoices = async (payload) => {
  let param = '';
  const queryParam = payload?.payload?.searchParam;
  const invoiceActive = payload?.payload?.showActiveInvoice;
  if (queryParam && queryParam !== 'ALL') {
    param = `status.IN=${payload.payload.searchParam}`;
  } else if (queryParam && queryParam === 'ALL') {
    param = invoiceActive ? '' : 'status.NE=ACTIVE';
  }
  const query = generateQuery(payload.payload.currentPage);
  const sorting = payload.payload.sortParams ? payload.payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (param && !payload.payload.filterParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.VENDORINVOICE_API}${query}&${param}${sorting}`);
  }
  if (payload.payload.filterParams && !param && !payload.payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.VENDORINVOICE_API}${query}&${payload.payload.filterParams}`);
  }
  if (payload.payload.filterParams && param && payload.payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.VENDORINVOICE_API}${query}&${param}&${payload.payload.filterParams}${sorting}`);
  }
  if (payload.payload.filterParams && !param && payload.payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.VENDORINVOICE_API}${query}&${payload.payload.filterParams}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.VENDORINVOICE_API}${query}${sorting}`);
};

export function* getVendorInvoices(action) {
  try {
    const { data: { entity } } = yield call(fetchVendorInvoices, action.payload);
    yield put({ type: VENDORINVOICES.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: VENDORINVOICES.FAILED, payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
