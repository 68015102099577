/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Select,
} from 'antd';
import {
  SpText,
} from 'components/DesignKit';

import Loading from 'components/Loading';

import {
  notificationActions,
  customerSupportActions,
  inventoryFeedbackActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';


const { Option } = Select;

type Props = {
  inventoryOrderFeedback:Array<Object>,
  inventoryFeedback: Array,
  selectedAccount: Object,
  totalInventoryOrderFeedbackElements: Number,
  test: boolean,
  loading: boolean,
  history: {
    push: Function,
  },
  validator: Boolean,
  fetchShops: Function,
  shops: Array<objects>,
  getInventoryOrderFeedack: Function,
  getInventoryFeedack: Function,

};

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});
const OrderFeedback = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    inventoryOrderFeedback,
    inventoryFeedback,
    getInventoryFeedack,
    totalInventoryOrderFeedbackElements,
    loading,
    test,
    history,
    validator,
    fetchShops,
    shops,
    getInventoryOrderFeedack,
  } = props;

  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [shopsList, setShopsList] = useState([]);
  const [selectedShop, setSelectedShop] = useState({});
  const todayDate = moment.utc(new Date()).tz(selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a');
  const [selectedStatusType, setSelectedStatusType] = useState({});
  const statusTypes = [
    {
      id: 1,
      name: 'All',
      value: 'ALL',
    },
    {
      id: 2,
      name: 'Created',
      value: 'CREATED',
    },
    {
      id: 3,
      name: 'Moved',
      value: 'MOVED',
    },
    {
      id: 4,
      name: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      id: 5,
      name: 'Expired',
      value: 'EXPIRED',
    },
  ];

  const debouncedFetchShops = debounce(fetchShops, 2000, { leading: true });
  const debounceFetchOrderFeedback = debounce(getInventoryOrderFeedack, 2000, { leading: true });
  const debounceFetchInventoryFeedback = debounce(getInventoryFeedack, 2000, { leading: true });

  useEffect(() => {
    if (shops && shops.length > 0) {
      setShopsList(shops);
      setSelectedShop(shops[0]);
      // setSelectedShop('All');
      const payload = {
        shopGid: shops[0].gid,
        enabled: true,
      };
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      const filterParams = '';
      debounceFetchOrderFeedback(payload);
      debounceFetchInventoryFeedback(payload);
    }
  }, [shops]);


  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = '';
    debouncedFetchShops({ currentPage, sortParams, filterParams });
  };


  useEffect(() => {
    getData(location.search);
  }, [selectedAccount, test]);


  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      const currentPage = parsed.page || 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const shopName = parsed.shopName || '';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      const status = parsed.status || '';
      const typeStatus = statusTypes.filter((item) => item.value === status);
      if (typeStatus && typeStatus.length !== 0) {
        setSelectedStatusType(typeStatus[0]);
      }
      const shopSelcted = shops.filter((item) => item.name === shopName);
      if (shopSelcted && shopSelcted.length !== 0) {
        setSelectedShop(shopSelcted[0]);
      }
      const filterParams = `?status.EQ=${status}&shopName.EQ=${(shopName)}&page=${currentPage}`;
    }
  }, [location]);
  useEffect(() => {
    if (!validator) {
      const parsed = queryString.parse(location.search);
      const currentPage = parsed.page || 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const shopName = parsed.shopName || '';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      const status = parsed.status || '';
      // eslint-disable-next-line max-len
      const filterParams = `?status.EQ=${status}&shopName.EQ=${(shopName)}&page=${currentPage}`;
    }
  }, [validator]);
  const updateSelectedShop = (shop) => {
    // if (shop === 'All') {
    //   setSelectedShop('All');
    // } else {
    //   (setSelectedShop(shop[1]));
    // }
    // const parsed = queryString.parse(location.search);
    // const currentPage = 1;
    // const status = parsed.status || '';
    // history.push({
    //   pathname: PAGES.PRE_ORDERS,
    //   // eslint-disable-next-line max-len
    //   search: `?status=${status}&shopName=${encodeURIComponent(shop[1].name)}&page=${currentPage}`,
    // });
    // setSelectedStatusType({});
    setSelectedShop(shop[1]);
    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = '';
    const payload = {
      shopGid: shop[0],
      enabled: true,
    };
    debounceFetchInventoryFeedback(payload);
    debounceFetchOrderFeedback(payload);
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalInventoryOrderFeedbackElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [inventoryOrderFeedback]);

  // eslint-disable-next-line no-unused-vars, no-shadow
  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: PAGES.FEEDBACK,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };
    // const handleTableChange = (page, sorter, filters) => {
    //   const parsed = queryString.parse(location.search);
    //   const currentPage = page.current;
    //   setPage(currentPage);
    //   const status = parsed.status || '';
    //   history.push({
    //     pathname: PAGES.FEEDBACK,
    //     // eslint-disable-next-line max-len
    //     search: `?&shopName.EQ=${encodeURIComponent(selectedShop.gid)}&page=${currentPage}`,
    //   });
    // };

  const selectedTableRecord = (record) => {
    const orderGid = record && record.inventoryOrder && record.inventoryOrder.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_ORDERS}/${orderGid}`,
      state: location.search,
    });
  };
  const generateColumns = (inventoryOrderFeedback) => {
    const columns = [
      {
        title: 'S.NO',
        key: 'sno',
        width: '13%',
        align: 'left',
        sorter: false,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, object, index) => (page - 1) * 10 + (index + 1),
      },
      {
        title: 'ORDER NO',
        dataIndex: 'inventoryOrderFeedback',
        width: '13%',
        align: 'left',
        sorter: false,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record) => (
          <Tooltip placement="top" title="">
            <span>
              {(record && record.inventoryOrder && record.inventoryOrder.orderNumber) || <>&#8211;</>}
            </span>
          </Tooltip>
        ),
      },
      {
        title: 'CLOVER ORDER ID',
        dataIndex: 'inventoryOrderFeedback',
        width: '14%',
        align: 'left',
        sorter: false,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record) => (
          <span
            style={{ cursor: 'pointer', color: '#3E8BFF', textDecoration: 'underline' }}
            onClick={(e) => { e.stopPropagation(); selectedTableRecord(record); }}
          >
            {(record && record.inventoryOrder && record.inventoryOrder.cloverPosOrderId) || <>&#8211;</>}
          </span>
        ),
      },
      {
        title: 'CUSTOMER INFO',
        dataIndex: 'inventoryOrderFeedback',
        width: '15%',
        align: 'left',
        sorter: false,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record) => (
          <span>

            <div>
              {(record && record.inventoryOrder && record.inventoryOrder.customer && record.inventoryOrder.customer.name) || <>&#8211;</>}
            </div>
            <div>
              {(record && record.inventoryOrder && record.inventoryOrder.customer && record.inventoryOrder.customer.phoneNumber) || <>&#8211;</>}
            </div>
          </span>
        ),
      },

    ];

    if (inventoryFeedback && inventoryFeedback.length > 0) {
      inventoryFeedback.forEach((Feedback) => {
        columns.push({
          title: Feedback.label,
          key: Feedback.gid,
          render: (text, record) => {
            const feedbackEntry = record.orderFeedbackEntries.find(
              (e) => e.shopFeedbackQuestionGid === Feedback.gid,
            );
            return <span>{feedbackEntry ? feedbackEntry.value : '-'}</span>;
          },
        });
      });

      columns.push({
        title: 'COMMENTS',
        dataIndex: 'invetoryFeedback',
        width: '13%',
        align: 'left',
        sorter: false,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record) => (
          <Tooltip placement="top" title="">
            <span style={{ cursor: 'pointer' }}>
              {(record && record.comments) || <>&#8211;</>}
            </span>
          </Tooltip>
        ),
      });
    }

    return columns;
  };


  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 pb-2" align="middle">
        <Col>
          <Select
            className="mr-4 mt-3"
            value={selectedShop.name || 'All'}
            style={{ width: '350px' }}
            bordered
            onChange={(value) => updateSelectedShop(value)}
          >
            {/* <Option key={1} value="All">All</Option> */}

            {
                  shopsList.map((option) => (
                    <Option
                      key={option.gid}
                      value={[option.gid, option]}
                    >
                      {option.name}
                    </Option>
                  ))
                }
          </Select>

        </Col>
      </Row>
      <div style={{ border: '1px solid #959595', padding: '10px', borderRadius: '10px' }} className="mb-3">
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <SpText fontSize="22px" fontWeight="600" color="#292929">{selectedShop.name || 'All Shops'}</SpText>
          </Col>
          <Col>
            <SpText fontSize="16px" fontWeight="500" color="#292929">Date: {todayDate}</SpText>
          </Col>
        </Row>
      </div>
      <RBAC
        fallback={(
          <Table
            columns={generateColumns(inventoryOrderFeedback)}
            rowKey={(record) => record.gid}
            dataSource={inventoryOrderFeedback}
            onChange={handleTableChange}
            pagination={pagination}
          />
            )}
        permit={
              [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS,
                ROLES.SUPERUSER, ROLES.KDSUSER1, ROLES.KDSADMIN1]
            }
      >
        <Table
          rowClassName={() => 'ant-table-clickable-row'}
          columns={generateColumns(inventoryOrderFeedback)}
          rowKey={(record) => record.gid}
          dataSource={inventoryOrderFeedback}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </RBAC>
    </Card>
  );
};


const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  shops: state.customerSupport.shops,
  inventoryOrderFeedback: state.inventoyFeedback.orderFeedback.content,
  inventoryFeedback: state.inventoyFeedback.content,
  totalInventoryOrderFeedbackElements: state.inventoyFeedback.orderFeedback ? state.inventoyFeedback.orderFeedback.totalElements : '',

});

const mapDispatchToProps = (dispatch) => ({


  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchShops: param => dispatch(customerSupportActions.fetchShops({
    payload: param,
  })),
  getInventoryOrderFeedack: (param) => dispatch(inventoryFeedbackActions.getInventoryOrderFeedack({
    payload: param,
  })),
  getInventoryFeedack: param => dispatch(inventoryFeedbackActions.fetchInventoryFeedback({
    payload: param,
  })),

});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OrderFeedback);
