import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Icon,
  Select,
} from 'antd';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import queryString from 'query-string';

import {
  SpButton,
  SpFormText,
} from 'components/DesignKit';

import {
  PAGES,
  COUNTRY_CODES,
  // TOKENS,
  TIME_ZONES,
  ALIAS_TIMEZONES,
} from 'appconstants';
import {
  validators,
  validationRegex,
} from 'utils/validationMessages';

import {
  accountActions,
} from 'store/actions';
import swireLogo from 'imgs/swirepay.png';
import qpayments from 'imgs/Qpayments.jpeg';

const { Option } = Select;

// $FlowFixMe
const Wrapper = styled.div`
  background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '24px',
  paddingBottom: '24px',
  alignItems: 'center',
  width: '511px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = {
  updateUserAccount: Function,
  history: {
    replace: Function,
  },
  validator: boolean,
  user: Object,
  loader: boolean,
  selectedAccount: Object,
  location: {
    pathname: string,
    search: string,
  },
  isMobileView: Boolean,
};

const Account = (props: Props) => {
  const {
    updateUserAccount,
    validator,
    user,
    loader,
    history,
    selectedAccount,
    location,
    isMobileView,
  } = props;

  const [, forceUpdate] = useState();
  const [accountName, setAccountName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeZone, setTimeZone] = useState('');
  const parsed = queryString.parse(location.search);
  const referralCode = parsed ? parsed.referral_code : '';
  const affiliateCode = parsed ? parsed.affiliate_code : '';
  const staticQrCode = localStorage.getItem('dynamicStaticQr');
  const qrJson = staticQrCode ? atob(staticQrCode) : null;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      validname: {
        message: validators.account.validAccountName,
        rule: val => (new RegExp(validationRegex.accountName).exec(val) !== null),
      },
    },
  }));

  useEffect(() => {
    const defaultTz = moment.tz.guess();
    const aliasTz = ALIAS_TIMEZONES[defaultTz];
    let tz = aliasTz || defaultTz;
    if (!(TIME_ZONES && TIME_ZONES.includes(tz))) {
      tz = undefined;
    }
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    if (!validator) {
      // localStorage.removeItem(TOKENS.ACCESS_TOKEN);
      // localStorage.removeItem(TOKENS.REFERRAL_CODE);
      // localStorage.removeItem('dynamicStaticQr');
      // localStorage.removeItem('fcmToken');
      history.replace(PAGES.ACCOUNT_SETUP);
    }
  }, [validator]);

  useEffect(() => {
    if (!loader) {
      setLoading(false);
    }
  }, [loader]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.gid) {
      history.replace(PAGES.NEW_ACCOUNT);
    }
  }, []);

  const createNewAccount = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setLoading(true);

    const qrCode = qrJson ? JSON.parse(qrJson) : null;

    const param = {
      contactEmail: user.email,
      contactFamilyName: user.familyName,
      contactGivenName: user.givenName,
      contactNumber: user.phoneNumber,
      largeLogo: '',
      name: accountName.trim(),
      smallLogo: '',
      countryCode,
      timezone: timeZone,
      shouldNotify: false,
      referralCode,
      affiliateCode,
      staticQRCode: (qrCode && qrCode.code) || null,
    };

    updateUserAccount(param);
  };

  return (
    <Wrapper>
      <div>
        <FormWrapper
          onSubmit={createNewAccount}
          style={{
            width: isMobileView ? '900px' : '',
            height: isMobileView ? '900px' : '',
            paddingTop: isMobileView ? '0px' : '',
            paddingBottom: isMobileView ? '0px' : '',
          }}
        >
          <img
            src={window.location.host.includes('qpaymentz')
              ? qpayments : swireLogo}
            alt=""
            className="align-self-center"
            style={{ width: isMobileView ? '420px' : '270px' }}
          />
          <div style={{ maxWidth: isMobileView ? '800px' : '350px' }}>
            <SpFormText fontSize={isMobileView ? '26px' : '18px'}>New Account</SpFormText>
            <div className="LOGIN">
              <div className="my-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Account Name</label>
                </div>
                <Input
                  placeholder="Account Name"
                  value={accountName}
                  onChange={(e) => setAccountName(e.currentTarget.value)}
                  prefix={<Icon type="bank" style={{ color: 'rgba(0,0,0,.5)' }} />}
                  style={{ width: isMobileView ? '600px' : '', height: isMobileView ? '50px' : '' }}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('name', accountName, 'required|validname',
                    { messages: { required: validators.account.accountName } })}
                </div>
              </div>
              <div className="pb-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Country</label>
                </div>
                <div className="d-flex">
                  <div style={{ width: '15%' }}>
                    <Input
                      disabled
                      prefix={<Icon type="flag" style={{ color: 'rgba(0,0,0,.5)' }} />}
                    />
                  </div>
                  <div style={{ width: '85%', height: '40px' }}>
                    <Select
                      className="w-100"
                      style={{ height: '40px' }}
                      defaultValue={countryCode || 'Select a country'}
                      onChange={(e) => setCountryCode(e)}
                    >
                      {COUNTRY_CODES.map((item) => (
                        <Option key={item.ID} value={item.ALPHA2}>
                          {item.NAME}
                        </Option>
                      ))}
                    </Select>
                    <div className="text-danger">
                      {simpleValidator.current.message('country code', countryCode, 'required',
                        { messages: { required: validators.account.country } })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Timezone</label>
                </div>
                <Select
                  showSearch
                  className="w-100"
                  value={timeZone}
                  style={{ height: '40px' }}
                  onChange={e => setTimeZone(e)}
                  placeholder="Select a time zone"
                >
                  {TIME_ZONES.map((item) => (
                    <Option key={item} value={item}>
                      {item.replace(/_/g, ' ')}
                    </Option>
                  ))}
                </Select>
                <div className="text-danger">
                  {simpleValidator.current.message('timezone', timeZone, 'required')}
                </div>
              </div>
              {
                referralCode && (
                  <div className="mb-3">
                    <div>
                      <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Referral Code</label>
                    </div>
                    <Input
                      value={referralCode}
                      readOnly
                      prefix={<Icon type="code" style={{ color: 'rgba(0,0,0,.5)' }} />}
                      style={{ width: isMobileView ? '600px' : '', height: isMobileView ? '50px' : '' }}
                    />
                  </div>
                )
              }
              {
                affiliateCode && (
                  <div className="mb-3">
                    <div>
                      <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Affiliate Code</label>
                    </div>
                    <Input
                      value={affiliateCode}
                      readOnly
                      prefix={<Icon type="code" style={{ color: 'rgba(0,0,0,.5)' }} />}
                      style={{ width: isMobileView ? '600px' : '', height: isMobileView ? '50px' : '' }}
                    />
                  </div>
                )
              }
              <div className="d-flex justify-content-center mt-3">
                <SpButton
                  className="mr-2"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  loading={loading}
                  onClick={createNewAccount}
                  style={{ fontSize: isMobileView ? '18px' : '' }}
                >
                  Create
                </SpButton>
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  user: state.user.user,
  loader: state.loading.loading,
  selectedAccount: state.account.selectedAccount,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserAccount: param => dispatch(accountActions.updateUserAccount({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Account);
