import React, {
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';

import {
  Row,
  Col,
} from 'antd';
import styled from 'styled-components';
import {
  SpText,
  SpH5,
  SpHead,
  SpH6,
  Line,
} from 'components/DesignKit';

import {
  eventPageActions,
} from 'store/actions';
import Loading from 'components/Loading';
import moment from 'moment-timezone';
import formatAmount from 'utils/formatAmount';

// $FlowFixMe
const Wrapper = styled.div`
      background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
      width: 100%;
      min-width: 100vw;
      min-height: 100vh;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    `;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '560px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

// $FlowFixMe
const FormWrapper1 = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '560px',
  minHeight: '120px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = ContextRouter & {
  fetchEventReceipts: Function,
  loading: boolean,
  receipts: Object,
  submitting: Boolean,
  history: {
    replace: Function,
  },
};

const EventPageOrderReceipt = (props: Props) => {
  const {
    fetchEventReceipts,
    receipts,
    loading,
    location: { search },
  } = props;

  const receiptID = new URLSearchParams(search).get('orderGid') || new URLSearchParams(search).get('sp-event-page-order');

  useEffect(() => {
    if (receiptID) {
      fetchEventReceipts(receiptID);
    }
  }, [receiptID]);

  const formatDate = (date, timezone) => {
    if (date && timezone) {
      return moment.utc(date).tz(timezone).format('MMM DD, YYYY');
    }
    return date && moment.utc(date).format('MMM DD, YYYY');
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        receipts && receipts.account && receipts.account.gid ? (
          <Wrapper>
            <FormWrapper>
              <Row>
                <Col type="flex" align="middle">
                  <SpHead className="text-uppercase" fontWeight="600">Receipt For {receipts.account.name}</SpHead>
                </Col>
              </Row>
              {
                (
                  <Row>
                    <Col type="flex" align="middle" className="mt-3"><SpH6 fontWeight="600">Receipt # {receipts?.receiptNumber}</SpH6></Col>
                  </Row>
                )
              }
              {receipts && (receipts.eventName || receipts.venueAddress || receipts.qrUrl) && (
              <Row style={{ display: 'flex' }} gutter={16} align="middle" justify="space-between">
                {/* Left Side: Event Name & Venue Details */}
                <Col flex="1">
                  {receipts.eventName && (
                  <>
                    <Row>
                      <Col className="mt-2">
                        <SpText fontWeight="400" fontSize="14px">Event Name</SpText>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-1">
                        <SpH6 fontWeight="600">{receipts.eventName}</SpH6>
                      </Col>
                    </Row>
                  </>
                  )}

                  {receipts.venueAddress && (
                  <>
                    <Row>
                      <Col className="mt-2">
                        <SpText fontWeight="400" fontSize="14px">Venue Details</SpText>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-1">
                        <SpText fontSize="16px" fontWeight="600">{receipts.venueAddress}</SpText>
                      </Col>
                    </Row>
                  </>
                  )}
                </Col>

                {receipts.qrUrl && (
                <Col flex="none">
                  <img
                    src={receipts.qrUrl}
                    alt="QR Code"
                    height={125}
                    width={125}
                    style={{
                      backgroundColor: 'transparent', display: 'block', lineHeight: 0, fontSize: 0, border: 0,
                    }}
                  />
                </Col>
                )}
              </Row>
              )}
              {
                receipts.customerName && receipts.customerEmail ? (
                  <Row type="flex" justify="space-between" className="mt-4 mb-4">
                    <Col span={24} align="middle">
                      <Line style={{ border: 'solid 1px rgb(0 0 0)' }} />
                    </Col>
                    <Col span={24} align="middle">
                      <div className="pt-1 pb-1">
                        <SpText fontSize="16px" fontWeight="500">Name: </SpText>
                        <SpText fontSize="16px" fontWeight="600">{receipts.customerName}</SpText>
                      </div>
                    </Col>
                    <Col span={24} align="middle">
                      <div className="pt-1 pb-1">
                        <SpText fontSize="16px" fontWeight="500">Email: </SpText>
                        <SpText fontSize="16px" fontWeight="600">{receipts.customerEmail}</SpText>
                      </div>
                    </Col>
                    <Col span={24} align="middle">
                      <Line style={{ border: 'solid 1px rgb(0 0 0)' }} />
                    </Col>
                  </Row>
                ) : (
                  <></>
                )
              }
              {
                (receipts) && (
                  <Row type="flex" justify="center" className="mb-4">
                    {
                      ((receipts && receipts.total) === 0) ? ''
                        : ((receipts && receipts.total)) && ((receipts && receipts.total) > 0) && (
                          <Col span={8} style={{ paddingLeft: '25px' }}>
                            <div className="pt-1 pb-1"><SpText color="#434343">Amount Paid</SpText></div>
                            <div className="pt-1 pb-1">
                              <SpH6 fontWeight="600">
                                <SpH6 fontWeight="600">
                                  {receipts.account.currency.prefix} {formatAmount(receipts.total, receipts.account.currency)}
                                </SpH6>
                              </SpH6>
                            </div>
                          </Col>
                        )
                    }
                    <Col span={8} style={{ paddingLeft: '25px' }}>
                      <div className="pt-1 pb-1"><SpText color="#434343">Payment Date</SpText></div>
                      <div className="pt-1 pb-1">
                        <SpH6 fontWeight="600">
                          {
                            (receipts.paymentDate
                              && formatDate(receipts.paymentDate, receipts.account.timezone)) || <>&#8211;</>
                          }
                        </SpH6>
                      </div>
                    </Col>
                    <Col span={8} style={{ paddingLeft: '25px' }}>
                      <div className="pt-1 pb-1"><SpText color="#434343">Payment Method</SpText></div>
                      <div className="pt-1 pb-1">
                        <SpH6 fontWeight="600">{receipts.paymentData}</SpH6>
                      </div>
                    </Col>
                  </Row>
                )
              }
              {
                (receipts?.lineItemEmailDtos.length !== 0) && receipts.lineItemEmailDtos.map((lineItem, index) => (
                  <>
                    <>
                      <Line style={{ border: index === 0 ? 'solid 1px rgb(0 0 0)' : '' }} />
                      <>
                        <Row type="flex" className="pt-1 pb-1">
                          <Col span={12}>
                            <div>
                              {lineItem.name}
                            </div>
                          </Col>
                          <Col span={6}>
                            <div>
                              {lineItem.quantity}
                            </div>
                          </Col>

                          <Col span={6} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                            <div>
                              {(receipts.account && receipts.account.currency.prefix)} {formatAmount(lineItem.amount, receipts.account.currency)}
                            </div>
                          </Col>
                        </Row>
                        {lineItem.seats?.length > 0 && (
                        <Row type="flex" className="pb-1" key={lineItem.id}>
                          <Col span={12}>
                            <div>
                              <p className="additional-info">Seats: [{lineItem.seats.join(', ')}]</p>
                            </div>
                          </Col>
                        </Row>
                        )}
                        {lineItem?.lineItemModifierEmailDtos?.length > 0
                            && lineItem.lineItemModifierEmailDtos.map((lineItemModifier) => (
                              <Row type="flex" className="pb-1 pl-2" key={lineItemModifier.id}>
                                <Col span={12}>
                                  <div>
                                    {lineItemModifier.modifierData}
                                  </div>
                                </Col>
                                <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                                  <div>
                                    {(receipts.account?.currency?.prefix || '')}
                                    {formatAmount(lineItemModifier.modifierAmount, receipts.account?.currency)}
                                  </div>
                                </Col>
                              </Row>
                            ))}
                        {lineItem?.lineItemDiscountEmailDtos?.length > 0
                            && lineItem.lineItemDiscountEmailDtos.map((lineItemDiscount) => (
                              <Row type="flex" className="pb-1 pl-2" key={lineItemDiscount.id}>
                                <Col span={12}>
                                  <div>
                                    {lineItemDiscount.discountName}
                                  </div>
                                </Col>

                                <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                                  <div>
                                    - {(receipts.account?.currency?.prefix || '')}
                                    {formatAmount(lineItemDiscount.discountAmount, receipts.account?.currency)}
                                  </div>
                                </Col>
                              </Row>
                            ))}
                        {lineItem?.lineItemItemEmailDtos?.length > 0
                            && lineItem.lineItemItemEmailDtos.map((lineItemItem) => (
                              <>
                                <Row type="flex" className="pb-1 pl-2">
                                  <Col span={12}>
                                    <div>
                                      {lineItemItem.name}
                                    </div>
                                  </Col>

                                  <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                                    <div>
                                      {/* eslint-disable-next-line max-len */}
                                      {(receipts.account && receipts.account.currency.prefix)} {formatAmount(lineItemItem.amount, receipts.account.currency)}
                                    </div>
                                  </Col>
                                </Row>
                                {lineItemItem?.lineItemModifierEmailDtos?.length > 0
                                    && lineItemItem.lineItemModifierEmailDtos.map((lineItemModifier) => (
                                      <Row type="flex" className="pb-1 pl-4" key={lineItemModifier.id}>
                                        <Col span={12}>
                                          <div>
                                            {lineItemModifier.modifierData}
                                          </div>
                                        </Col>

                                        <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                                          <div>
                                            {(receipts.account?.currency?.prefix || '')}
                                            {formatAmount(lineItemModifier.modifierAmount, receipts.account?.currency)}
                                          </div>
                                        </Col>
                                      </Row>
                                    ))}
                                {lineItemItem?.lineItemDiscountEmailDtos?.length > 0
                                    && lineItemItem.lineItemDiscountEmailDtos.map((lineItemDiscount) => (
                                      <Row type="flex" className="pb-1 pl-4" key={lineItemDiscount.id}>
                                        <Col span={12}>
                                          <div>
                                            {lineItemDiscount.discountName}
                                          </div>
                                        </Col>

                                        <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                                          <div>
                                            - {(receipts.account?.currency?.prefix || '')}
                                            {formatAmount(lineItemDiscount.discountAmount, receipts.account?.currency)}
                                          </div>
                                        </Col>
                                      </Row>
                                    ))}
                              </>
                            ))}
                      </>
                    </>
                  </>
                ))
              }
              <Line style={{ border: 'solid 1px rgb(0 0 0)' }} />
              {
                receipts && receipts.subTotal && (
                  <Row type="flex" className="pt-1 pb-1">
                    <Col span={12}>
                      <div>
                        <SpH5>Sub Total</SpH5>
                      </div>
                    </Col>
                    {
                      (receipts && receipts.subTotal) ? (
                        <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                          <div>
                            <SpH5>
                              {/* eslint-disable-next-line max-len */}
                              {(receipts.account && receipts.account.currency.prefix)} {formatAmount(receipts.subTotal, receipts.account.currency)}
                            </SpH5>
                          </div>
                        </Col>
                      ) : <>&#8211;</>
                    }
                  </Row>
                )
}
              {
                receipts && receipts.discountAmount && (
                <Row type="flex" className="pt-1 pb-1">
                  <Col span={12}>
                    <div>
                      Discount
                    </div>
                  </Col>
                  {
                    (receipts && receipts.discountAmount) ? (
                      <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                        <div>
                          {/* eslint-disable-next-line max-len */}
                          - {(receipts.account && receipts.account.currency.prefix)} {formatAmount(receipts.discountAmount, receipts.account.currency)}
                        </div>
                      </Col>
                    ) : <>&#8211;</>
                  }
                </Row>
                )
              }
              {
                receipts && receipts.tax && (
                <Row type="flex" className="pt-1 pb-1">
                  <Col span={12}>
                    <div>
                      Tax
                    </div>
                  </Col>
                  {
                    (receipts && receipts.tax) ? (
                      <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                        <div>
                          {/* eslint-disable-next-line max-len */}
                          {(receipts.account && receipts.account.currency.prefix)} {formatAmount(receipts.tax, receipts.account.currency)}
                        </div>
                      </Col>
                    ) : <>&#8211;</>
                  }
                </Row>
                )
              }
              {
                receipts && receipts.serviceFee && (
                  <Row type="flex" className="pt-1 pb-1">
                    <Col span={12}>
                      <div>
                        Service Fee
                      </div>
                    </Col>
                    {
                      (receipts && receipts.serviceFee) ? (
                        <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                          <div>
                            {/* eslint-disable-next-line max-len */}
                            {(receipts.account && receipts.account.currency.prefix)} {formatAmount(receipts.serviceFee, receipts.account.currency)}
                          </div>
                        </Col>
                      ) : <>&#8211;</>
                    }
                  </Row>
                )
              }
              {
                receipts && receipts.tip && (
                  <Row type="flex" className="pt-1 pb-1">
                    <Col span={12}>
                      <div>
                        Tip
                      </div>
                    </Col>
                    {
                      (receipts && receipts.tip) ? (
                        <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                          <div>
                            {/* eslint-disable-next-line max-len */}
                            {(receipts.account && receipts.account.currency.prefix)} {formatAmount(receipts.tip, receipts.account.currency)}
                          </div>
                        </Col>
                      ) : <>&#8211;</>
                    }
                  </Row>
                )
              }
              <Line style={{ border: 'solid 1px rgb(0 0 0)' }} />
              <Row type="flex" className="pt-1 pb-1">
                <Col span={12}>
                  <div>
                    <SpH5>Total</SpH5>
                  </div>
                </Col>
                {
                    (receipts && receipts.total) ? (
                      <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                        <div>
                          <SpH5>
                            {/* eslint-disable-next-line max-len */}
                            {(receipts.account && receipts.account.currency.prefix)} {formatAmount(receipts.total, receipts.account.currency)}
                          </SpH5>
                        </div>
                      </Col>
                    ) : <>&#8211;</>
                  }
              </Row>
              <Line style={{ border: 'solid 1px rgb(0 0 0)' }} />
              { receipts && receipts.amountRefunded && (
                <Row type="flex" className="pt-1 pb-1">
                  <Col span={12}>
                    <div>
                      Amount Refunded
                    </div>
                  </Col>
                    {
                      (receipts && receipts.amountRefunded) ? (
                        <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: '50px' }}>
                          <div>
                            {/* eslint-disable-next-line max-len */}
                            - {(receipts.account && receipts.account.currency.prefix)} {formatAmount(receipts.amountRefunded, receipts.account.currency)}
                          </div>
                        </Col>
                      ) : <>&#8211;</>
                    }
                </Row>
              )}
            </FormWrapper>
          </Wrapper>
        ) : (
          <Wrapper>
            <FormWrapper1>
              <Row>
                <Col type="flex" align="middle">
                  <SpHead className="text-uppercase" fontWeight="600">The entry may deleted.</SpHead>
                </Col>
              </Row>

            </FormWrapper1>
          </Wrapper>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  receipts: state.eventPages.eventReceipt,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventReceipts: gid => dispatch(eventPageActions.fetchEventPageReceipts({
    payload: gid,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventPageOrderReceipt);
