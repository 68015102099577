// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Select,
  Radio,
  Switch,
  Input,
} from 'antd';
import queryString from 'query-string';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import {
  notificationActions,
  inventoryItemsActions,
} from 'store/actions';
import {
  SpH5,
  SpButton,
  SpError,
  SpText,
} from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  promosList: Array,
  categoryList: Array,
  shopPromosList: Array,
  selectedPromoCode: Object,
  fetchInventorySortItems: Function,
  inventoryItems: Array,
  selectedAccount: Object,
  shopPromo: Boolean,
};

const EditPromo = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    promosList,
    categoryList,
    shopPromosList,
    selectedPromoCode,
    fetchInventorySortItems,
    inventoryItems,
    selectedAccount,
    shopPromo,
  } = props;

  const [, forceUpdate] = useState();
  const gid = selectedPromoCode ? selectedPromoCode.gid : '';
  const [disableButton, setDisableButton] = useState(false);
  const [promoName, setPromoName] = useState(selectedPromoCode && selectedPromoCode.promotionalCodes
    ? selectedPromoCode.promotionalCodes.code
    : '');
  const [promocode, setPromocode] = useState(selectedPromoCode && selectedPromoCode.promotionalCodes
    ? selectedPromoCode.promotionalCodes.gid
    : '');
  const [item, setItem] = useState(selectedPromoCode && selectedPromoCode.items
    ? selectedPromoCode.items.map((opt) => opt.gid)
    : []);

  const [category, setCategory] = useState(selectedPromoCode && selectedPromoCode.inventoryCategories
    ? selectedPromoCode.inventoryCategories.map((option) => option.gid)
    : []);


  const [type, setType] = useState(selectedPromoCode && selectedPromoCode.items.length > 0
    ? 'item' : selectedPromoCode && selectedPromoCode.inventoryCategories.length > 0 ? 'category'
      : selectedPromoCode && selectedPromoCode.offer ? 'offer' : 'all');
  const [offerExist, setOfferExist] = useState(selectedPromoCode && selectedPromoCode.promotionalCodes && selectedPromoCode.promotionalCodes.offer);

  const [pausePromo, setPausePromo] = useState((selectedPromoCode && selectedPromoCode.paused) || false);
  const [maximumRedemption, setMaximumRedemption] = useState(((selectedPromoCode?.maximumRedemption / 100).toFixed(2)) || '');
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: 'Min limit should be greater than zero',
        rule: val => val > 0,
      },
    },
  }));
  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const [promoArray, setPromoArray] = useState([]);

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortParams = '&sortBy=sortOrder&direction=ASC';
    fetchInventorySortItems({ currentPage, sortParams });
  };

  useEffect(() => {
    if (!inventoryItems) {
      getData();
    }
    if (!submitting) {
      setDisableButton(false);
    }
    function notInShopPromosList(element) {
      const gid1 = element.gid;
      // Check if gid1 is not found in any element of shopPromosList
      return !shopPromosList.some(info => info.promotionalCodes.gid === gid1);
    }
    const listOfPromos = promosList.filter(notInShopPromosList);
    setPromoArray(listOfPromos);
  }, [promocode, submitting]);

  const onTypeChange = (e) => {
    setType(e.target.value);
  };

  useEffect(() => {
    if (type === 'all') {
      setCategory([]);
      setItem([]);
    }
    if (type === 'item') {
      setCategory([]);
      setItem(selectedPromoCode && selectedPromoCode.items
        ? selectedPromoCode.items.map((opt) => opt.gid)
        : []);
    } else if (type === 'category') {
      setItem([]);
      setCategory(selectedPromoCode && selectedPromoCode.inventoryCategories
        ? selectedPromoCode.inventoryCategories.map((option) => option.gid)
        : []);
    }
  }, [type]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      shoppromoGid: gid,
      promoName,
      promocode,
      item,
      category,
      pausePromo,
      maximumRedemption,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="650px"
      style={{
        top: '50px',
      }}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Promo Code"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-4">
          <label htmlFor=""><SpH5>Select Promo Code</SpH5></label>
          <Select
            className="w-100"
            showSearch
            disabled
            defaultValue={promoName || 'Select'}
            onChange={value => {
              setPromoName(value[0]);
              setPromocode(value[1]);
              setOfferExist(value[2]);
            }}
          >
            {promoArray.map((items) => (
              <Option key={items.gid} value={[items.code, items.gid, items.offer]}>
                {items.code}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('promocode', promocode, 'required')}
          </SpError>
        </div>
        <div className="mb-4">
          <Radio.Group onChange={onTypeChange} value={offerExist ? 'Offer' : type}>
            {promocode ? (
              offerExist ? (
                <Radio value="Offer">Offers</Radio>
              ) : (
                <>
                  <Radio value="all">All</Radio>
                  <Radio value="item">Item</Radio>
                  <Radio value="category">Categories</Radio>
                </>
              )
            ) : (
              <>
                <Radio value="all">All</Radio>
                <Radio value="item">Item</Radio>
                <Radio value="category">Categories</Radio>
                <Radio value="Offer">Offers</Radio>
              </>
            )}
          </Radio.Group>
        </div>
        {
          type === 'item' ? (
            <div className="mb-4">
              <label htmlFor="">
                <SpH5>Select Item</SpH5>
              </label>
              <Select
                className="w-100"
                showSearch
                allowClear
                mode="multiple"
                placeholder="Select Items"
                optionFilterProp="children"
                value={item}
                onChange={value => {
                  setItem(value);
                }}
              >
                {inventoryItems && inventoryItems.map((itemx) => (
                  <Option key={itemx.gid} value={itemx.gid}>
                    {itemx.name}
                  </Option>
                ))}
              </Select>
            </div>
          ) : type === 'category' ? (
            <div className="mb-4">
              <label htmlFor="">
                <SpH5>Select Category</SpH5>
              </label>
              <Select
                className="w-100"
                mode="multiple"
                placeholder="Select categories"
                showSearch
                optionFilterProp="children"
                allowClear
                value={category}
                onChange={value => {
                  setCategory(value);
                }}
              >
                {categoryList.map((itemy) => (
                  <Option key={itemy.gid} value={itemy.gid}>
                    {itemy.name}
                  </Option>
                ))}
              </Select>
            </div>
          ) : ''
        }
        {offerExist || !shopPromo ? '' : (
          <>
            <div className="mb-2">
              <SpText>Max. Limit for Shop Promo</SpText>
            </div>
            <div className="w-50 pb-3">
              <Input
                prefix={prefix}
                placeholder="0.00"
                value={maximumRedemption}
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setMaximumRedemption(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setMaximumRedemption(null);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setMaximumRedemption(value);
                  }
                }}
              />
              <SpError>
                {maximumRedemption && simpleValidator.current.message('Max. Limit', maximumRedemption, 'required|amount')}
              </SpError>
            </div>
          </>
        )}
        <div>
          <SpH5>Pause/Resume Promos</SpH5>
          <Switch
            className="ml-4"
            checked={pausePromo}
            onChange={val => setPausePromo(val)}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  inventoryItems: state.inventoryItems.sortItemsList.content,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(EditPromo);
