// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  Select,
} from 'antd';
import { SketchPicker } from 'react-color';
import SimpleReactValidator from 'simple-react-validator';
import {
  MAX_AMOUNT,
  COUNTRIES,
} from 'appconstants';
import {
  validators,
} from 'utils/validationMessages';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import {
  SpColor,
  SpCover,
  SpPopover,
  SpWatch,
} from 'components/PaymentWidgetDesignKit';
import moment from 'moment-timezone';

const { TextArea } = Input;
const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
};

const AddCategory = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    selectedAccount,
    menuData,
  } = props;

  const [, forceUpdate] = useState();
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [name, setName] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [primaryColor, setColorCode] = useState('#007bff');
  const [description, setDescription] = useState('');
  const STRING_LENGTH = 1;
  const [order, setOrder] = useState('');
  const [operatingHours, setOperatingHours] = useState(null);
  const [enableOperationHours, setEnableOperationHours] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minLimit: {
        message: 'Sort order must be valid',
        rule: val => val >= 0 && val < 999999,
      },
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      numericValidation: {
        message: 'Sort order must be valid',
        rule: (val) => (val ? !val.toString().includes('.') : true),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  /* useEffect(() => {
    const list = [
      {
        dayOfWeek: 'MONDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'TUESDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'WEDNESDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'THURSDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'FRIDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'SATURDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
      {
        dayOfWeek: 'SUNDAY',
        operatingHours: [
          {
            openingTime: '',
            closingTime: '',
            open: false,
          },
        ],
      },
    ];
    setOperatingHours([...list]);
  }, []); */

  // eslint-disable-next-line no-unused-vars
  const selectOperatingHours = (e, type, i, j) => {
    const list = [...operatingHours];
    const time = e.format('hh:mm a');
    const timeStamp = time.split(' ')[1];
    const actualTime = time.split(' ')[0];
    const a = actualTime.split(':');
    let minutes;
    if (a[0] === '12' && timeStamp === 'am') {
      minutes = +a[1];
    } else if (a[0] === '12' && timeStamp === 'pm' && type === 'From') {
      minutes = +a[0] * 60;
    } else {
      minutes = (+a[0] + (timeStamp === 'pm' ? 12 : 0)) * 60 + (+a[1]);
    }
    if (type === 'From') {
      list[i].operatingHours[j].openingTime = minutes;
    } else if (type === 'To') {
      list[i].operatingHours[j].closingTime = minutes;
    }
    setOperatingHours([...list]);
  };

  // eslint-disable-next-line no-unused-vars
  const updateStatus = (val, i, j) => {
    const list = [...operatingHours];
    list[i].operatingHours[j].open = val;
    setOperatingHours([...list]);
  };

  // eslint-disable-next-line no-unused-vars
  const getTimeVal = (id, index, type) => {
    if (type === 'From') {
      const fromTime = operatingHours[id].operatingHours[index].openingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          return moment(`${hours}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    } if (type === 'To') {
      const fromTime = operatingHours[id].operatingHours[index].closingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          const hr = 0;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    }
    return null;
  };

  // eslint-disable-next-line no-unused-vars
  const isAvailableChange = (value) => {
    setEnableOperationHours(!value);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };


  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (e) => {
    setColorCode(e.hex);
  };

  const updateColor = (e) => {
    setColorCode(e.currentTarget.value);
  };

  // eslint-disable-next-line no-unused-vars
  const addTimeSlot = (dayIndex) => {
    const newTimeSlot = {
      openingTime: '',
      closingTime: '',
      open: false,
    };
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.push(newTimeSlot);
      return updatedData;
    });
  };

  // eslint-disable-next-line no-unused-vars
  const removeTimeSlot = (dayIndex, slotIndex) => {
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.splice(slotIndex, 1);
      return updatedData;
    });
  };


  const handleSelectChangeHours = (values) => {
    setOperatingHours(values);
  };


  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      order,
      primaryColor,
      description,
      operatingHours,
      // operationalHoursMasterGid,
      enableOperationHours,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="630px"
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add Category</SpButton>,
      ]}
      title="Add Category"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            onChange={(e) => setName(e.currentTarget.value)}
            value={name}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required|stringLength')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Category Color</SpH5></label>
          <div className="d-flex ml-1">
            <div>
              <SpWatch onClick={handleClick}>
                <SpColor className="color" style={{ backgroundColor: `${primaryColor}` }} />
              </SpWatch>
              {displayColorPicker ? (
                <>
                  <SpPopover>
                    <SpCover onClick={handleClose} />
                    <SketchPicker color={primaryColor} onChange={handleChange} />
                  </SpPopover>
                </>
              ) : null}
            </div>
            <Input
              className="ml-3"
              value={primaryColor}
              onChange={updateColor}
            />
          </div>
          <SpError>
            {simpleValidator.current.message('color code', primaryColor, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <TextArea
            maxLength={255}
            rows={5}
            placeholder="Description"
            className="resize"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Sort Order</SpH5></label>
          <Input
            placeholder="Enter the order"
            type="number"
            onChange={(e) => setOrder(e.currentTarget.value)}
            value={order}
          />
          <SpError>
            {simpleValidator.current.message('order', order, 'numeric|numericValidation|minLimit')}
          </SpError>
        </div>
        {/* <Row type="flex" className={enableOperationHours ?? "mb-4"} justify="start">
          <Col span={9}>
            <SpText fontSize="20px">Open 24/7</SpText><br />
            <SpText fontSize="12px">
              You can use the Open 24/7 toggle to select if your shop is open 24 hours a day, 7 days a week.
            </SpText>
          </Col>
          <Col className="mt-4 ml-4">
            <Switch
              className="mr-2"
              defaultChecked={!enableOperationHours}
              onChange={isAvailableChange}
            />  {!enableOperationHours ? 'Enabled' : 'Disabled'}
          </Col>
            </Row> */}
        {enableOperationHours


        && (
        <div className="mt-4">
          <label htmlFor=""><SpH5>Operating Hours</SpH5></label>

          <Select
            className="w-100"
            allowClear
            placeholder="Select item"
            onChange={handleSelectChangeHours}
            value={operatingHours}
            showSearch
            // optionFilterProp="name"
            style={{ width: '100%' }}
          >
            {menuData.content.map((item) => (
              <Option key={item.gid} name={item.name} value={item.gid}>
                {item.name}
              </Option>
            ))}
          </Select>
          {/* <SpError>
            {simpleValidator.current.message('operating hours', operatingHours, 'required')}
          </SpError> */}

          {/* <div className="ml-3">
            <Row className="my-2 mb-3" type="flex" justify="start">
              <Col span={5}>
                <span className="text-muted">Days</span>
              </Col>
              <Col span={6}>
                <span className="text-muted">From</span>
              </Col>
              <Col span={6}>
                <span className="text-muted">To</span>
              </Col>
            </Row>
            {operatingHours.map((day, dayIndex) => (
              <div key={dayIndex}>
                {day.operatingHours.map((timeSlot, slotIndex) => (
                  <div key={slotIndex}>
                    <Row className="my-2 mb-3" type="flex" justify="start">
                      <Col span={5}>
                        {slotIndex === 0 && <span>{day.dayOfWeek}</span>}
                      </Col>
                      <Col span={6}>
                        <TimePicker
                          allowClear={false}
                          use12Hours
                          format="h:mm a"
                          defaultValue={getTimeVal(dayIndex, slotIndex, 'From')}
                          onChange={(e) => selectOperatingHours(e, 'From', dayIndex, slotIndex)}
                        />
                      </Col>
                      <Col span={6}>
                        <TimePicker
                          allowClear={false}
                          use12Hours
                          format="h:mm a"
                          defaultValue={getTimeVal(dayIndex, slotIndex, 'To')}
                          onChange={(e) => selectOperatingHours(e, 'To', dayIndex, slotIndex)}
                        />
                      </Col>
                      <Col span={6}>
                        <Switch
                          className="mx-3"
                          defaultChecked={timeSlot.open}
                          onChange={val => updateStatus(val, dayIndex, slotIndex)}
                        /> Open
                      </Col>
                      <Col span={1}>
                        {slotIndex === 0 ? (
                          <Icon
                            type="plus-circle"
                            style={{
                              cursor: 'pointer', color: '#3E8BFF',
                            }}
                            onClick={() => addTimeSlot(dayIndex)}
                          />
                        )
                          : (
                            <Icon
                              type="minus-circle"
                              style={{
                                cursor: 'pointer', color: '#FF4B51',
                              }}
                              onClick={() => removeTimeSlot(dayIndex, slotIndex)}
                            />
                          )}
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            ))}
            {/* {
              operatingHours[0] && (
                <>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Monday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(0, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 0)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(0, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 0)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[0].open}
                        onChange={val => updateStatus(val, 0)}
                      /> Open
                    </Col>
                  </Row>

                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Tuesday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(1, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 1)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(1, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 1)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[1].open}
                        onChange={val => updateStatus(val, 1)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Wednesday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(2, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 2)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(2, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 2)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[2].open}
                        onChange={val => updateStatus(val, 2)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Thursday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(3, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 3)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(3, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 3)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[3].open}
                        onChange={val => updateStatus(val, 3)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Friday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(4, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 4)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(4, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 4)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[4].open}
                        onChange={val => updateStatus(val, 4)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2 mb-3" type="flex" justify="start">
                    <Col span={4}>
                      <span>Saturday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(5, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 5)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(5, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 5)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[5].open}
                        onChange={val => updateStatus(val, 5)}
                      /> Open
                    </Col>
                  </Row>
                  <Row className="my-2" type="flex" justify="start">
                    <Col span={4}>
                      <span>Sunday</span>
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(6, 'From')}
                        onChange={(e) => selectOperatingHours(e, 'From', 6)}
                      />
                    </Col>
                    <Col span={6}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm a"
                        defaultValue={getTimeVal(6, 'To')}
                        onChange={(e) => selectOperatingHours(e, 'To', 6)}
                      />
                    </Col>
                    <Col span={6}>
                      <Switch
                        className="mx-3"
                        defaultChecked={operatingHours[6].open}
                        onChange={val => updateStatus(val, 6)}
                      /> Open
                    </Col>
                  </Row>
                </>
              )
            } }
          </div> */
          }
        </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddCategory);
